import React from 'react';
import './Progress.scss';

interface ProgressProps {
  progress?: number;
  progress_status_message?: string;
  solid?: boolean;
}

const Progress: React.FC<ProgressProps> = ({ progress = 100, progress_status_message = '', solid = false }) => (
  <div className="flex flex-col flex-1">
    <div className="relative adapter-progress-wrapper">
      <div className={`adapter-progress origin-left ${solid && 'solid'}`} style={{ width: `${progress}%` }} />
    </div>
    <div className="text-sm text-gray-600">{progress_status_message}</div>
  </div>
);

export default Progress;