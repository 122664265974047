import React from 'react';
import styled from 'styled-components';

interface ThemeToggleProps {
  darkMode: boolean;
  onToggle: () => void;
}

const ToggleButton = styled.button<{ $darkMode: boolean }>`
  background-color: ${props => props.$darkMode ? '#334155' : '#F1F5F9'};
  color: ${props => props.$darkMode ? '#CBD5E1' : '#334155'};
  border: 1px solid ${props => props.$darkMode ? '#475569' : '#CBD5E1'};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  transition: all 0.2s ease;
  flex: 1;
  
  &:hover {
    background-color: ${props => props.$darkMode ? '#475569' : '#E2E8F0'};
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
  }
  
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const ThemeToggle: React.FC<ThemeToggleProps> = ({ darkMode, onToggle }) => {
  return (
    <ToggleButton 
      onClick={onToggle}
      $darkMode={darkMode}
      aria-label={darkMode ? 'Switch to light mode' : 'Switch to dark mode'}
      title={darkMode ? 'Switch to light mode' : 'Switch to dark mode'}
    >
      {darkMode ? (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
        </svg>
      )}
    </ToggleButton>
  );
};
