import React from "react";

import Modal from "components/common/Modal";
import AccountDisplay from "./AccountDisplay";
import { CloseIcon } from "components/common/Icon";
import { H4Text } from "components/common/Typography";

import "./ConnectAccountPopup.scss";

type ConnectAccountPopupProps = {
    UserStore?: any;
    onClose: () => void;
};

class ConnectAccountPopup extends React.Component<ConnectAccountPopupProps, any> {
    render() {
        const { onClose } = this.props;
        
        return (
            <Modal onClose={onClose}>
                <div style={{ position: 'relative', width: '100%' }}>
                    <CloseIcon
                        onClick={onClose}
                        style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
                    />
                </div>

                <H4Text>Connect an account</H4Text>

                <AccountDisplay />
            </Modal>
        );
    }
}

export default ConnectAccountPopup;