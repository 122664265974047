import React from 'react';
import { inject, observer } from 'mobx-react';

import { Message } from "components/common/Chat/Message";
import Progress from '../Progress';
import InputBar from 'pages/intentions/common/InputBar';
import NLPInputBar from 'pages/intentions/common/NLPInputBar';
import PromptToggle from 'pages/dashboard/prompt/PromptToggle';
import { AdapterChatMessage } from 'models/Chat';
import { Job } from 'models/Job';

import PromptComprehension from '../PromptComprehension';
import TextOption from 'pages/intentions/options/TextOption';

import InvalidParameters from 'pages/intentions/options/errors/InvalidParameters';
import JobFailedOption from 'pages/intentions/options/errors/JobFailedOption';
import RequestFailedOption from 'pages/intentions/options/errors/RequestFailedOption';
import RequestNotUnderstood from 'pages/intentions/options/errors/RequestNotUnderstood';
import UnsupportedOption from 'pages/intentions/options/errors/UnsupportedOption';
import RequestTimeout from 'pages/intentions/options/errors/RequestTimeout';

import QueryOption from 'pages/intentions/options/QueryOption';
import FlightResponse from 'pages/intentions/options/flight/FlightResponse';
import HotelResponse from 'pages/intentions/options/hotel/HotelResponse';
import RestaurantResponse from 'pages/intentions/options/restaurant/RestaurantResponse';

import ErrorBoundary from '../ErrorBoundary';
import { Text } from '../Typography';
import Card from '../Card';

import "./AdapterChat.scss";

const RESPONSE_CMP = {
    'unsupported': UnsupportedOption,
    'undefined': UnsupportedOption,
    'flight_booking': FlightResponse,
    'hotel_booking': HotelResponse,
    'dining_reservation': RestaurantResponse
};

const PROMPT_RESPONSE_ERRORS = {
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    PUT_EVENT_ERROR: 'PUT_EVENT_ERROR',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    NOT_UNDERSTOOD: 'NOT_UNDERSTOOD',
    UNSUPPORTED: 'UNSUPPORTED'
};

type AdapterChatProps = {
    NLPStore?: any;
    PromptStore?: any;
}

type AdapterChatState = {}

const AdapterLoadingMessage = ({ progress, progress_status_message }) => (
    <Card>
        <Text>Working on your request...</Text>
        <Progress progress={progress} progress_status_message={progress_status_message}/>
    </Card>
);

class AdapterChat extends React.Component<AdapterChatProps, AdapterChatState> {
    formatAgentResponse = ({ results, type, job_status, error, progress, progress_status_message, job_id }: Job) => {
        const { PromptStore } = this.props;

        const CMP = RESPONSE_CMP[type] || RESPONSE_CMP.unsupported;

        if (!type || !job_status || type === 'unsupported') {
            return (<UnsupportedOption />);
        }

        if (job_status === 'failed') {
            return (<JobFailedOption text={error} />);
        }

        if (job_status === 'pending' || job_status === 'in_progress') {
            return <AdapterLoadingMessage progress={progress} progress_status_message={progress_status_message} />;
        }

        // @ts-ignore
        return (<CMP results={results} jobId={job_id} />);
    }

    renderMessages = () => {
        const { PromptStore } = this.props;

        const allMsg = [];

        for (let i = 0; i < PromptStore.messages.length; i++) {
            const { name, time, content, loading, type }: AdapterChatMessage = PromptStore.messages[i];
            let cmp;

            switch (type) {
                case 'timeout':
                    cmp = <RequestTimeout />;
                    break;

                case 'comprehension':
                case 'memory':
                    cmp = (
                        <ErrorBoundary fallback={<Text>An error occurred. If you continue to see this message, please contact Adapter.</Text>}>
                            <PromptComprehension comprehension={content} />
                        </ErrorBoundary>
                    );
                    break;

                case 'prompt':
                case 'system_message':
                    cmp = <TextOption text={content} />;
                    break;

                case 'agent_response':
                    cmp = this.formatAgentResponse(content)
                    break;

                case 'query_response':
                    cmp = <QueryOption text={content} />
                    break;

                case 'error':
                case PROMPT_RESPONSE_ERRORS.INTERNAL_SERVER_ERROR:
                case PROMPT_RESPONSE_ERRORS.PUT_EVENT_ERROR:
                    cmp = <RequestFailedOption />;
                    break;

                case PROMPT_RESPONSE_ERRORS.VALIDATION_ERROR:
                    cmp = <InvalidParameters message={content} />;
                    //cmp = <ParametersNotUnderstood />;
                    break;

                case PROMPT_RESPONSE_ERRORS.NOT_UNDERSTOOD:
                    cmp = <RequestNotUnderstood message={content} />;
                    break;

                case PROMPT_RESPONSE_ERRORS.UNSUPPORTED:
                default:
                    cmp = <UnsupportedOption />;
                    break;
            }

            if (loading) {
                // Get progress info from job content if it exists
                const progressData = content?.progress;
                const statusMessage = content?.progress_status_message;
                
                allMsg.push(
                  <Message
                    key={i}
                    name={name} 
                    time={time}
                    content={<AdapterLoadingMessage 
                      progress={progressData} 
                      progress_status_message={statusMessage} 
                    />}
                  />
                );
               } else {
                allMsg.push(
                  <Message
                    key={i}
                    name={name}
                    time={time} 
                    content={cmp}
                  />
                );
               }
        }

        return allMsg;
    }

    componentDidUpdate(prevProps: Readonly<AdapterChatProps>, prevState: Readonly<AdapterChatState>, snapshot?: any): void {
        // find the newest message; scroll the top of it into view appropriately
        const messages = document.querySelectorAll('.adapter-chat-message');
        const domEl = messages?.length && messages[messages.length - 1];

        if (domEl) {
            domEl.scrollIntoView(true);
        }
    }

    render() {
        const { NLPStore } = this.props;
        const messages = this.renderMessages();

        return (
            <>
                <div className="prompt-toggle-wrapper">
                    <PromptToggle />
                </div>

                <div className="adapter-chat-title">
                </div>

                <div className="adapter-chat-wrapper">
                    {messages}
                </div>

                {NLPStore.isCurrentTab
                    ? <NLPInputBar cls="persist" placeholder="Tell me about my data..." />
                    : <InputBar cls="persist" placeholder="Book me a reservation..." />
                }
            </>
        );
    }
}

export default inject("PromptStore", "NLPStore")(observer(AdapterChat));