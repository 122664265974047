// /frontend/src/components/NetworkCard.tsx
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import NetworkCohesionAnalysis from './NetworkCohesionAnalysis';
import { useFullScreen } from '../context/FullScreenContext';
import { Node as NetworkNode } from '../types/network-types';


// Styled Components
const Card = styled(motion.div)<{ isFullScreen: boolean }>`
  position: ${props => props.isFullScreen ? 'fixed' : 'relative'};
  top: ${props => props.isFullScreen ? '0' : 'auto'};
  left: ${props => props.isFullScreen ? '0' : 'auto'};
  right: ${props => props.isFullScreen ? '0' : 'auto'};
  bottom: ${props => props.isFullScreen ? '0' : 'auto'};
  width: ${props => props.isFullScreen ? '100vw' : 'auto'};
  height: ${props => props.isFullScreen ? '100vh' : '700px'};
  margin: ${props => props.isFullScreen ? '0' : '24px'};
  z-index: ${props => props.isFullScreen ? '1000' : '1'};
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  border-radius: ${props => props.isFullScreen ? '0' : '12px'};
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const Title = styled.h2`
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 24px;
    color: #333;
    display: flex;
    align-items: center;
    gap: 12px;

    &::before {
        content: '🔄';
        font-size: 24px;
    }
`;

const ErrorMessage = styled.div`
    padding: 20px;
    background-color: #fff1f0;
    border: 1px solid #ffccc7;
    border-radius: 8px;
    color: #cf1322;
    margin: 16px 0;
    display: flex;
    align-items: center;
    gap: 12px;

    &::before {
        content: '⚠️';
    }
`;

const LoadingContainer = styled.div`
    padding: 40px;
    text-align: center;
    color: #666;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

const LoadingSpinner = styled(motion.div)`
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
`;

const FullScreenButton = styled(motion.button)`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  svg {
    width: 20px;
    height: 20px;
    fill: #333;
  }

  &:hover {
    background: rgba(255, 255, 255, 1);
  }
`;


// Interfaces
interface NetworkData {
    connections: Array<{
        email1: string;
        email2: string;
        shared_meetings: number;
        connection_type: string;
        interaction_score: number;
    }>;
    statistics: {
        total_connections: number;
        average_shared_meetings: number;
        strong_threshold: number;
        regular_threshold: number;
        network_density: number;
        max_shared_meetings: number;
        min_shared_meetings: number;
    };
}

interface NetworkCardProps {
    data: NetworkData | null;
    title: string;
    isLoading?: boolean;
    error?: string | null;
    onRetry?: () => void;
    onNodeClick?: (node: NetworkNode) => void;
}

// Animation variants
const cardVariants = {
    hidden: { 
        opacity: 0, 
        y: 20,
        scale: 0.95
    },
    visible: { 
        opacity: 1, 
        y: 0,
        scale: 1,
        transition: {
            duration: 0.3,
            ease: "easeOut"
        }
    },
    exit: { 
        opacity: 0, 
        y: -20,
        scale: 0.95,
        transition: {
            duration: 0.2,
            ease: "easeIn"
        }
    }
};

const spinnerVariants = {
    animate: {
        rotate: 360,
        transition: {
            duration: 1,
            ease: "linear",
            repeat: Infinity
        }
    }
};

export const NetworkCard: React.FC<NetworkCardProps> = ({ 
    data, 
    title, 
    isLoading = false,
    error = null,
    onRetry,
    onNodeClick
}) => {
    const { isFullScreen, toggleFullScreen } = useFullScreen();
    const containerRef = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    // Add this useEffect to measure container dimensions
    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setDimensions({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight
                });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        // Update dimensions when fullscreen changes
        if (isFullScreen) {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        return () => window.removeEventListener('resize', updateDimensions);
    }, [isFullScreen]);

    const renderContent = () => {
        if (isLoading) {
            return <NetworkCardSkeleton />;
        }

        if (error) {
            return <NetworkCardError message={error} onRetry={onRetry} />;
        }

        if (!data) {
            return <NetworkCardError message="No network data available" />;
        }

        return (
            <NetworkCohesionAnalysis 
                data={data} 
                onNodeClick={onNodeClick}
                width={dimensions.width}
                height={isFullScreen ? dimensions.height - 100 : dimensions.height - 150} // Adjust for header and padding
            />
        );
    };

    return (
        <Card
            ref={containerRef}
            isFullScreen={isFullScreen}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            layout
        >
            <FullScreenButton
                onClick={toggleFullScreen}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
            >
                {isFullScreen ? (
                    <svg viewBox="0 0 24 24">
                        <path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"/>
                    </svg>
                ) : (
                    <svg viewBox="0 0 24 24">
                        <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/>
                    </svg>
                )}
            </FullScreenButton>
            {dimensions.width > 0 && dimensions.height > 0 && renderContent()}
        </Card>
    );
};

// Helper components for external use
export const NetworkCardSkeleton: React.FC = () => (
    <Card
        isFullScreen={false}  // Add this line
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
            background: 'rgba(255, 255, 255, 0.5)'
        }}
    >
        <LoadingContainer>
            <LoadingSpinner
                variants={spinnerVariants}
                animate="animate"
            />
            <p>Preparing network analysis...</p>
        </LoadingContainer>
    </Card>
);

export const NetworkCardError: React.FC<{ 
    message: string;
    onRetry?: () => void;
}> = ({ message, onRetry }) => (
    <Card
        isFullScreen={false}  // Add this line
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        <Title>Network Analysis</Title>
        <ErrorMessage>
            {message}
            {onRetry && (
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onRetry}
                    style={{
                        marginLeft: 'auto',
                        padding: '8px 16px',
                        border: 'none',
                        borderRadius: '4px',
                        backgroundColor: '#ff4d4f',
                        color: 'white',
                        cursor: 'pointer'
                    }}
                >
                    Retry
                </motion.button>
            )}
        </ErrorMessage>
    </Card>
);

// Default export
export default NetworkCard;