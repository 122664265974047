// src/components/common/TabSystem.tsx
import React, { useState } from 'react';
import styled from 'styled-components';

interface TabProps {
  label: string;
  children: React.ReactNode;
}

interface TabsProps {
  children: React.ReactElement<TabProps>[];
  defaultTab?: string;
  darkMode: boolean;
}

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TabList = styled.div<{ $darkMode: boolean }>`
  display: flex;
  border-bottom: 1px solid ${props => props.$darkMode ? '#334155' : '#E2E8F0'};
  margin-bottom: 16px;
`;

const TabButton = styled.button<{ $active: boolean }>`
  background: none;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: ${props => props.$active ? '600' : '400'};
  color: ${props => props.$active ? props.theme.primaryColor : props.theme.secondaryColor};
  border-bottom: 2px solid ${props => props.$active ? props.theme.primaryColor : 'transparent'};
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    color: ${props => props.$active ? props.theme.primaryColor : props.theme.textColor};
  }
`;

const TabContent = styled.div`
  padding: 8px 0;
`;

// Define the Tab component
const TabComponent: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>;
};

// Define the Tabs component
const TabsComponent: React.FC<TabsProps> = ({ children, defaultTab = 'All', darkMode }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  
  return (
    <TabsContainer>
      <TabList $darkMode={darkMode}>
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) return null;
          
          const label = child.props.label;
          
          return (
            <TabButton 
              $active={activeTab === label} 
              onClick={() => setActiveTab(label)}
            >
              {label}
            </TabButton>
          );
        })}
      </TabList>
      <TabContent>
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child) || activeTab !== child.props.label) return null;
          return child.props.children;
        })}
      </TabContent>
    </TabsContainer>
  );
};

// Export both components with clear names
export const Tab = TabComponent;
export const Tabs = TabsComponent;