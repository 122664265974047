import { useState } from 'react';

import withRouter from 'hooks/withRouter';
import Button from "components/common/Button";
import OnboardingWrapper from '../index';
import { Text, H3Text, UL, LI, LINK } from 'components/common/Typography';
import Card from 'components/common/Card';
import StepHeader from './StepHeader';

const Trust = ({ router }) => {
    const [hasAcceptedPP, setHasAcceptedPP] = useState(false);
    const [hasAcceptedTOS, setHasAcceptedTOS] = useState(false);

    return (
        <OnboardingWrapper>
            <StepHeader
                progress={20}
                text='Our Promise'
                onClick={() => router.navigate('/onboarding/')}
            />

            <Card>
                <div className="adapter-two-col">
                    <div className="adapter-two-col--title">
                        <div className="flex-1"></div>

                        <H3Text cls='adapter-font-color-primary-900'>
                            It Starts with Trust and Transparency
                        </H3Text>

                        <div className="flex-1"></div>
                    </div>

                    <div className="adapter-two-col--content">
                        <div className="flex-1"></div>

                        <Text>
                            Adapter securely transforms your digital activity data into actionable knowledge to serve you better.
                            We don&apos;t take this responsibility lightly.
                        </Text>

                        <UL>
                            <LI><Text>You decide what data we can access.</Text></LI>
                            <LI><Text>Your information only serves you.</Text></LI>
                            <LI><Text>We will never sell your data. </Text></LI>
                            <LI><Text>Privacy protection is built in from the ground up. </Text></LI>
                        </UL>


                        <div className='flex flex-row gap-4 items-center'>
                            <input
                                type="checkbox"
                                className=''
                                onChange={v => setHasAcceptedPP(v.target.checked)}
                                style={{ cursor: 'pointer' }}
                                aria-label="I agree to Adapter's Privacy Policy"
                            />

                            <Text cls='flex-1'>
                                I agree to Adapter&apos;s <LINK href="https://adapter.com/privacy-policy" target="_blank">Privacy Policy</LINK>
                            </Text>
                        </div>

                        <div className='flex flex-row gap-4 items-center'>
                            <input
                                type="checkbox"
                                className=''
                                onChange={v => setHasAcceptedTOS(v.target.checked)}
                                style={{ cursor: 'pointer' }}
                                aria-label="I agree to Adapter's Privacy Policy"
                            />

                            <Text cls='flex-1'>
                                I agree to Adapter&apos;s <LINK href="https://adapter.com/terms-of-service" target="_blank">Terms of Service</LINK>
                            </Text>
                        </div>

                        <div className="flex-1"></div>
                    </div>
                </div>
            </Card>

            <Button
                onClick={() => { router.navigate('/onboarding/details'); }}
                size="2xl"
                disabled={!hasAcceptedPP || !hasAcceptedTOS}
                style={{ alignSelf: 'center' }}
            >
                Continue &rarr;
            </Button>
        </OnboardingWrapper>
    );
}

export default withRouter(Trust);