interface Theme {
  bgColor: string;
  cardBgColor: string;
  textColor: string;
  secondaryTextColor: string;
  primaryColor: string;
  secondaryColor: string;
  buttonBgColor: string;
  buttonHoverBgColor: string;
  buttonTextColor: string;
  nodeBgColor: string;
  nodeHighlightColor: string;
  nodeBorderColor: string;
  nodeTextColor: string;
  edgeColor: string;
  edgeHighlightColor: string;
  borderColor: string;
  shadowColor: string;
  errorColor: string;
  successColor: string;
  warningColor: string;
  infoColor: string;
}

// Colors from the brand style guide
// Each color has varying shades from 50 (lightest) to 950 (darkest)
const colors = {
  light: {
    50: '#FFFFFF',
    100: '#F8F9FA',
    200: '#E9ECEF',
    300: '#DEE2E6',
    400: '#CED4DA',
    500: '#ADB5BD',
    600: '#6C757D',
    700: '#495057',
    800: '#343A40',
    900: '#212529',
    950: '#000000'
  },
  dark: {
    50: '#000000',
    100: '#212529',
    200: '#343A40',
    300: '#495057',
    400: '#6C757D',
    500: '#ADB5BD',
    600: '#CED4DA',
    700: '#DEE2E6',
    800: '#E9ECEF',
    900: '#F8F9FA',
    950: '#FFFFFF'
  },
  rose: {
    50: '#FFF1F2',
    100: '#FFE4E6',
    200: '#FECDD3',
    300: '#FDA4AF',
    400: '#FB7185',
    500: '#F43F5E',
    600: '#E11D48',
    700: '#BE123C',
    800: '#9F1239',
    900: '#881337',
    950: '#4C0519'
  },
  sand: {
    50: '#FEFCE8',
    100: '#FEF9C3',
    200: '#FEF08A',
    300: '#FDE047',
    400: '#FACC15',
    500: '#EAB308',
    600: '#CA8A04',
    700: '#A16207',
    800: '#854D0E',
    900: '#713F12',
    950: '#422006'
  },
  pear: {
    50: '#F7FEE7',
    100: '#ECFCCB',
    200: '#D9F99D',
    300: '#BEF264',
    400: '#A3E635',
    500: '#84CC16',
    600: '#65A30D',
    700: '#4D7C0F',
    800: '#3F6212',
    900: '#365314',
    950: '#1A2E05'
  },
  mint: {
    50: '#F0FDF4',
    100: '#DCFCE7',
    200: '#BBF7D0',
    300: '#86EFAC',
    400: '#4ADE80',
    500: '#22C55E',
    600: '#16A34A',
    700: '#15803D',
    800: '#166534',
    900: '#14532D',
    950: '#052E16'
  },
  tide: {
    50: '#ECFEFF',
    100: '#CFFAFE',
    200: '#A5F3FC',
    300: '#67E8F9',
    400: '#22D3EE',
    500: '#06B6D4',
    600: '#0891B2',
    700: '#0E7490',
    800: '#155E75',
    900: '#164E63',
    950: '#083344'
  },
  echo: {
    50: '#F5F3FF',
    100: '#EDE9FE',
    200: '#DDD6FE',
    300: '#C4B5FD',
    400: '#A78BFA',
    500: '#8B5CF6',
    600: '#7C3AED',
    700: '#6D28D9',
    800: '#5B21B6',
    900: '#4C1D95',
    950: '#2E1065'
  }
};

// Brand theme using the style guide colors
const lightTheme: Theme = {
  // Base colors
  bgColor: colors.light[100],
  cardBgColor: colors.light[50],
  textColor: colors.light[900],
  secondaryTextColor: colors.light[700],
  primaryColor: colors.echo[600],
  secondaryColor: colors.tide[500],
  
  // Interactive elements
  buttonBgColor: colors.echo[600],
  buttonHoverBgColor: colors.echo[700],
  buttonTextColor: colors.light[50],
  
  // Node styling
  nodeBgColor: colors.light[50],
  nodeHighlightColor: colors.echo[600],
  nodeBorderColor: colors.light[300],
  nodeTextColor: colors.light[900],
  
  // Connection elements
  edgeColor: colors.light[400],
  edgeHighlightColor: colors.echo[500],
  borderColor: colors.light[300],
  shadowColor: 'rgba(0, 0, 0, 0.08)',
  
  // Semantic colors
  errorColor: colors.rose[600],
  successColor: colors.mint[600],
  warningColor: colors.sand[500],
  infoColor: colors.tide[600],
};

const darkTheme: Theme = {
  // Base colors
  bgColor: '#0A0A0A', // Pure dark background
  cardBgColor: '#121212', // Dark card background
  textColor: colors.dark[900],
  secondaryTextColor: colors.dark[700],
  primaryColor: colors.tide[300], // Updated to tide from echo for dark mode
  secondaryColor: colors.tide[400],
  
  // Interactive elements
  buttonBgColor: colors.tide[300], // Updated to tide from echo for dark mode
  buttonHoverBgColor: colors.tide[400], // Updated to tide from echo for dark mode 
  buttonTextColor: colors.dark[950],
  
  // Node styling
  nodeBgColor: '#121212',
  nodeHighlightColor: colors.tide[300], // Updated to tide from echo for dark mode
  nodeBorderColor: '#2C2C2C',
  nodeTextColor: colors.dark[900],
  
  // Connection elements
  edgeColor: '#494949',
  edgeHighlightColor: colors.tide[300], // Updated to tide from echo for dark mode
  borderColor: '#2C2C2C',
  shadowColor: 'rgba(0, 0, 0, 0.35)',
  
  // Semantic colors
  errorColor: colors.rose[500],
  successColor: colors.mint[500],
  warningColor: colors.sand[400],
  infoColor: colors.tide[400],
};

export const getTheme = (isDarkMode: boolean): any => {
  // Return a theme object in the format expected by the original KnowledgeGraphExplorer
  const theme = isDarkMode ? darkTheme : lightTheme;
  return {
    darkMode: isDarkMode,
    bgColor: theme.bgColor,
    cardBg: theme.cardBgColor,
    textColor: theme.textColor,
    borderColor: theme.borderColor,
    primaryColor: theme.primaryColor,
    secondaryColor: theme.secondaryColor,
    colors: {
      selected: isDarkMode ? colors.tide[300] : colors.rose[600],     // Tide for dark mode selection based on screenshot
      highlighted: isDarkMode ? colors.tide[400] : colors.sand[500],  // Tide for dark mode highlights based on screenshot
      userNode: isDarkMode ? colors.tide[300] : colors.tide[500]      // Tide from style guide for user nodes
    }
  };
};

export default {
  getTheme,
  lightTheme,
  darkTheme
};