import React from 'react';
import { inject, observer } from 'mobx-react';
import withRouter from 'hooks/withRouter';
import withPostHog from 'hooks/withPosthog';

import './PromptToggle.scss';

type PromptToggleProps = {
    userEvents?: any;
    NLPStore?: any;
    PromptStore?: any;
    router: any;
};

class PromptToggle extends React.Component<PromptToggleProps, {}> {
    handleToggleClick = (isNLP: boolean = false) => {
        const { NLPStore, PromptStore, router } = this.props;
        NLPStore.setCurrentTab(isNLP);

        if (isNLP) {
            NLPStore.setRawPrompt(null);
            NLPStore.clearResponse();
        } else {
            PromptStore.setRawPrompt(null);
            PromptStore.clearComprehensionResponse();
        }

        PromptStore.clearMessages();
        router.navigate('/dashboard');
    }

    render() {
        const { NLPStore, userEvents } = this.props;
        if (userEvents?.posthog.isFeatureEnabled('question-slider')) {
            return (
                <div className="toggle-switch-container">
                    <div
                        className={`toggle-switch ${!NLPStore.isCurrentTab ? 'active' : ''}`}
                        onClick={() => this.handleToggleClick(false)}
                    >
                        <span>Start a Task</span>
                    </div>
                    <div
                        className={`toggle-switch ${NLPStore.isCurrentTab ? 'active' : ''}`}
                        onClick={() => this.handleToggleClick(true)}
                    >
                        <span>Ask a Question</span>
                    </div>
                </div>
            );
        }
    }
}

export default withPostHog(withRouter(inject("NLPStore", "PromptStore")(observer(PromptToggle))));