import React from 'react';
import { inject, observer } from 'mobx-react';
import StepHeader from './StepHeader';
import OnboardingProgress from 'components/stats/OnboardingProgress';
import OnboardingWrapper from '../index';
import Button from 'components/common/Button';
import { H3Text, Text } from 'components/common/Typography';
import Card from 'components/common/Card';
import Feedback from 'components/common/Feedback/Feedback';
import ImportingData from 'components/stats/ImportingData';

type AccountIngestProps = {
    AuthStore?: any;
    OnboardingStore?: any;
    UserStore?: any;
}

class AccountIngest extends React.Component<AccountIngestProps, {}> {
    state = {
        firstRender: true,
        connected: false,
        error: null
    }

    componentDidMount() {
        const { AuthStore } = this.props;
        const { firstRender } = this.state;

        if (firstRender) {
            const searchParams = new URL(window.location.href).searchParams;
            const code = searchParams.get('code');
            const error = searchParams.get('error');

            if (code || error) {
                this.setState({ firstRender: false }, () => {
                    AuthStore.oauth({ code, error })
                        .then(() => this.setState({ connected: true }))
                        .catch((err) => {
                            this.setState({ error: err?.message ? `OAuth error: ${err?.message}` : 'OAuth permission error' });
                            // toast.error(`OAuth Error: ${err?.message || 'Authentication failed'}`, { icon: false });
                        });
                });
            } else {
                this.setState({
                    firstRender: false,
                    connected: true
                });
            }
        }
    }

    onClick = () => {
        const { OnboardingStore } = this.props;
        OnboardingStore.stopPollingForStats();
        OnboardingStore.completeOnboarding()
            .then(() => {
                window.location.href = '/dashboard'
            });
    }

    onCancelClick = () => {
        const { AuthStore } = this.props;
        AuthStore.logout().then(() => {
            window.location.href = '/'
        });
    }

    renderPendingContent = () => {
        return (
            <>
                <H3Text cls='adapter-font-color-primary-900'>
                    Importing account data
                </H3Text>

                <Text>
                    Retrieving, categorizing, and processing your data for events, insights, and preferences.
                </Text>

                <Text>
                    This may take a few minutes...
                </Text>
            </>
        );
    }

    renderCompleteContent = () => {
        return (
            <>
                <H3Text cls='adapter-font-color-primary-900'>
                    Learning complete!
                </H3Text>

                <Text>
                    All set to start using Adapter and putting your data to work for you.

                </Text>

                <Text>
                    Adapter has learned useful things about you based on your past data, but there may be things we got wrong.
                    You will be able to update what we&apos;ve learned.
                </Text>
            </>
        );
    }

    renderErrorContent = () => {
        return (
            <>
                <H3Text cls='adapter-font-color-primary-900'>
                    Unable to connect account.
                </H3Text>

                <Text>
                    Please try again. This can happen when Adapter is not given enough permissions.
                </Text>
            </>
        );
    }

    render() {
        const { UserStore, OnboardingStore } = this.props;
        const { connected, error } = this.state;
        const onboardingCompleted = OnboardingStore.onboardingCompleted;

        let content, visual;

        if (error) {
            content = this.renderErrorContent();
            visual = (
                <>
                    <div className="flex-1"></div>

                    <div className='flex flex-col items-center'>
                        <div className="landing-page--inner-bottom-buttons flex gap-4">
                            <Button size="2xl" onClick={this.onCancelClick}>Cancel</Button>

                            <Button size="2xl" onClick={() => window.location.href = "/onboarding/connect"}>Retry</Button>
                        </div>
                    </div>

                    <div className="flex-1"></div>
                </>
            );
        } else {
            if (onboardingCompleted) {
                content = this.renderCompleteContent();
            } else {
                content = this.renderPendingContent();
            }

            visual = connected ? <OnboardingProgress /> : (
                <>
                    <div className="flex-1" />
                    <ImportingData />
                    <div className="flex-1" />
                </>
            );
        }

        return (
            <OnboardingWrapper>
                {error ? (
                    <StepHeader progress={60} text='Connect' onClick={() => {
                        window.location.href = "/onboarding/connect";
                    }} />
                ) : (
                    <StepHeader progress={100} text='Ingest' onClick={() => {
                        window.location.href = "/onboarding/controls"
                    }} />
                )}

                <Card>
                    <div className="adapter-two-col">

                        <div className="adapter-two-col--title">
                            <div className="flex-1"></div>

                            {content}

                            <div className="flex-1"></div>
                        </div>

                        <div className="adapter-two-col--content" style={{ alignItems: 'center' }}>
                            {visual}
                        </div>

                    </div>
                </Card>

                <Feedback
                    style={{
                        borderRadius: '4px',
                        alignSelf: 'stretch',
                        justifyContent: 'center'
                    }}
                    text="How was your overall onboarding experience?"
                    responseKey={'onboarding::ingest::userId'}
                    metadata={{ userId: UserStore?.user?.id }}
                    ux="faces"
                />

                {!error && (
                    <Button
                        size="2xl"
                        style={{ alignSelf: 'center', minWidth: '250px' }}
                        onClick={this.onClick}
                    >
                        {onboardingCompleted ? 'Go' : 'Skip'} to Tutorial&nbsp;&rarr;
                    </Button>
                )}
            </OnboardingWrapper>
        );
    }
}

export default inject("AuthStore", "OnboardingStore", "UserStore")(observer(AccountIngest));