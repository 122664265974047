export const sampleData = {
    categories: [
        {
            id: '1',
            type: 'Flights',
            name: 'Flights',
            icon: '✈️',
            description: 'Travel Preferences',
            tileTypes: [
                { id: 'airline', name: 'Airlines', icon: '✈️' },
                { id: 'price', name: 'Price & Budget', icon: '💰' },
                { id: 'timing', name: 'Time & Schedule', icon: '⏰' },
                { id: 'location', name: 'Airports & Routes', icon: '📍' },
                { id: 'comfort', name: 'Comfort & Class', icon: '💺' },
                { id: 'loyalty', name: 'Loyalty Programs', icon: '🎯' }
            ]
        },
        {
            id: '2',
            type: 'Dining',
            name: 'Dining',
            icon: '🍽️',
            description: 'Dining Preferences',
            tileTypes: [
                { id: 'cuisine', name: 'Cuisine Types', icon: '🍳' },
                { id: 'price', name: 'Price Range', icon: '💰' },
                { id: 'dietary', name: 'Dietary Needs', icon: '🥗' },
                { id: 'atmosphere', name: 'Atmosphere', icon: '🌟' },
                { id: 'location', name: 'Location & Distance', icon: '📍' },
                { id: 'timing', name: 'Dining Times', icon: '⏰' }
            ]
        },
        {
            id: '3',
            type: 'Lodging',
            name: 'Lodging',
            icon: '🏨',
            description: 'Accommodation Preferences',
            tileTypes: [
                { id: 'accommodation', name: 'Property Types', icon: '🏨' },
                { id: 'comfort', name: 'Amenities', icon: '🛏️' },
                { id: 'services', name: 'Services', icon: '🛎️' },
                { id: 'location', name: 'Location & Area', icon: '📍' },
                { id: 'price', name: 'Rates & Budget', icon: '💰' },
                { id: 'loyalty', name: 'Hotel Programs', icon: '🎯' },
                { id: 'timing', name: 'Stay Patterns', icon: '⏰' },
                { id: 'social', name: 'Group & Events', icon: '👥' }
            ]
        },
        {
            id: '4',
            type: 'Purchases',
            name: 'Purchases',
            icon: '🛍️',
            description: 'Shopping Patterns',
            tileTypes: [
                { id: 'merchants', name: 'Top Merchants', icon: '🏪' },
                { id: 'categories', name: 'Shopping Categories', icon: '📋' },
                { id: 'spending', name: 'Spending Patterns', icon: '💰' },
                { id: 'timing', name: 'Shopping Times', icon: '⏰' },
                { id: 'subscriptions', name: 'Subscriptions', icon: '🔄' },
                { id: 'frequency', name: 'Purchase Frequency', icon: '📊' },
                { id: 'seasonal', name: 'Seasonal Trends', icon: '📅' },
                { id: 'digital', name: 'Digital vs Physical', icon: '💻' }
            ]
        },
        {
            id: '5',
            type: 'Memories',
            name: 'Memories',
            icon: '🧠',
            description: 'Your Personal Memory',
            tileTypes: [
                { id: 'TRAVEL', name: 'Travel', icon: '✈️' },
                { id: 'SOCIAL', name: 'Social', icon: '👥' },
                { id: 'PERSONAL', name: 'Personal', icon: '👤' },
                { id: 'FLIGHTS', name: 'Flights', icon: '🛩️' },
                { id: 'DINING', name: 'Dining', icon: '🍽️' },
                { id: 'LODGING', name: 'Lodging', icon: '🏨' }
            ]
        },
        {
            id: '6',
            type: 'Dossier',
            name: 'Dossier',
            icon: '📊',
            description: 'Personal Analysis Report',
            tileTypes: [
                { id: 'analysis', name: 'Full Analysis', icon: '📋' }
            ]
        }
    ],
    preferences: [
        // Travel Preferences
        {
            id: 'travel-1',
            categoryId: '1',
            categoryType: 'Travel',
            tileType: 'airline',
            name: 'Primary Airline',
            value: 'Delta Airlines',
            location: { code: 'JFK' },
            metadata: { membership: 'Diamond Medallion' }
        },
        {
            id: 'travel-2',
            categoryId: '1',
            categoryType: 'Travel',
            tileType: 'airline',
            name: 'Secondary Airline',
            value: 'United Airlines',
            location: { code: 'AUS' },
            metadata: { membership: 'Gold' }
        },
        {
            id: 'travel-3',
            categoryId: '1',
            categoryType: 'Travel',
            tileType: 'price',
            name: 'Average Ticket Budget',
            value: '$750',
            metadata: { scope: 'Domestic' }
        },
        {
            id: 'travel-4',
            categoryId: '1',
            categoryType: 'Travel',
            tileType: 'timing',
            name: 'Preferred Departure',
            value: '8am - 11am',
            metadata: { days: 'Weekday' }
        },
        {
            id: 'travel-5',
            categoryId: '1',
            categoryType: 'Travel',
            tileType: 'comfort',
            name: 'Seat Preference',
            value: 'Window',
            metadata: { position: 'Forward Cabin' }
        },

        // Dining Preferences
        {
            id: 'food-1',
            categoryId: '3',
            categoryType: 'Food',
            tileType: 'cuisine',
            name: 'Favorite Cuisine',
            value: 'Italian',
            metadata: { specifics: 'Northern Italian' }
        },
        {
            id: 'food-2',
            categoryId: '3',
            categoryType: 'Food',
            tileType: 'price',
            name: 'Dining Budget',
            value: '$$$',
            metadata: { mealType: 'Dinner' }
        },
        {
            id: 'food-3',
            categoryId: '3',
            categoryType: 'Food',
            tileType: 'dietary',
            name: 'Dietary Restriction',
            value: 'Gluten-Free',
            metadata: { severity: 'Strict' }
        },
    ]
}; 