import React from 'react';
import { formatDate_ShortHuman, formatTime_AmPm } from 'util/Time';

import { PlaneIcon } from 'components/common/Icon';
import ResultCard from '../ResultCardBase';

import './FlightOption.scss';
import { Text } from 'components/common/Typography';

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3
});

const formatPrice = (price: number | undefined): string => {
    return price ? USDollar.format(price) : 'Price not available';
};

export type OneWayFlight = {
    flight_type: 'one-way'
    airline_logo: string[];
    airlines: string[];
    airplanes: string[];
    arrival_airport: string;
    arrival_time: string;
    booking_link: string;
    booking_url?: string;
    booking_urls?: string[];
    departure_airport: string;
    departure_time: string;
    durations: number[];
    total_duration: number;
    flight_numbers: string[];
    layovers: any[];
    legroom: string[];
    overnight: boolean[];
    price: number;
    travel_class: string[];
    ranking_reasoning?: string;
    utility_costs?: UtilityCosts;
};

export type FlightLayover = {
    airport_name: string;
    airport_code: string;
    duration_minutes: number;
}

export type FlightLegOption = {
    arrival_airport_name?: string;
    arrival_time: string;
    arrival_date: string;
    arrival_airport: string;
    departure_date: string;
    airplane?: string;
    flight_number: string;
    price?: number;
    travel_class: string;
    duration_minutes: number;
    airline: string;
    airlines?: string[];
    flight_numbers?: string[];
    layovers?: FlightLayover[];
    departure_airport_name: string;
    departure_airport: string;
    departure_time: string;
};

export type RoundTripFlight = {
    flight_type: 'roundtrip'
    price?: number;
    booking_url?: string;
    booking_urls?: string[];
    departure_option: OneWayFlight;
    return_option: OneWayFlight;
    utility_costs?: UtilityCosts;
    ranking_reasoning?: string;
};

export type MultiCityFlight = {
    flight_type: 'multi-city'
    price: number;
    booking_url?: string;
    booking_urls?: string[];
    legs: FlightLegOption[];
    ranking_reasoning?: string; // NOT IMPLEMENTED
};

export type UtilityCosts = {
    time_cost_before: number;
    time_cost_after: number;
    stops_preference_cost: number;
    total_costs: number;
    time_cost: number;
};

type FlightOptionProps = {
    option: OneWayFlight | RoundTripFlight | MultiCityFlight;
    index: number;
    allOptions: (OneWayFlight | RoundTripFlight | MultiCityFlight)[];
    ranking_reasoning?: string;
    score?: number;
    allScores?: number[];
    numPassengers?: number;
};

class FlightOption extends React.Component<FlightOptionProps, {}> {
    getFlightType = () => {
        const { option } = this.props;
        return option.flight_type || this.getOldFlightType();
    }

    isRoundTrip = () => {
        const { option } = this.props;
        return 'departure_option' in option;
    }

    isMultiCity = () => {
        const { option } = this.props;
        return option && typeof option === 'object' && option.hasOwnProperty('legs');
    };

    getTravelClass = () => {
        const { option } = this.props;
        if (this.isRoundTrip()) {
            const roundTripOption = option as RoundTripFlight;
            return roundTripOption.departure_option.travel_class[0];
        }
        if (this.isMultiCity()) {
            const multiCityOption = option as MultiCityFlight;
            return multiCityOption.legs[0].travel_class;
        }
        return (option as OneWayFlight).travel_class[0];
    }

    // Deprecate
    getOldFlightType = () => {
        if (this.isRoundTrip()) return 'round trip';
        if (this.isMultiCity()) return 'multi-city';
        return 'one-way';
    }

    getPrice = () => {
        const { option } = this.props;

        if (this.isRoundTrip()) {
            const roundTripOption = option as RoundTripFlight;
            const price = roundTripOption.price ?? roundTripOption.return_option.price ?? roundTripOption.departure_option.price;
            return formatPrice(price);
        }

        return formatPrice(option.price);
    };

    sortLegs = (legs: FlightLegOption[]): FlightLegOption[] => {
        return legs.sort((a, b) => {
            const dateA = new Date(`${a.departure_date}T${a.departure_time}`);
            const dateB = new Date(`${b.departure_date}T${b.departure_time}`);
            return dateA.getTime() - dateB.getTime();
        });
    }

    renderFlightLeg = (leg: OneWayFlight | FlightLegOption, index: number, label?: string) => {
        if (!leg) return <></>;

        const isOneWayOrRoundTrip = 'total_duration' in leg;

        const departureTime = isOneWayOrRoundTrip ? leg.departure_time : leg.departure_date + 'T' + leg.departure_time;
        const arrivalTime = isOneWayOrRoundTrip ? leg.arrival_time : leg.arrival_date + 'T' + leg.arrival_time;
        const duration = isOneWayOrRoundTrip ? leg.total_duration : leg.duration_minutes;
        const airline = isOneWayOrRoundTrip
            ? leg.airlines[0]
            : this.getAirlinesFromLeg(leg as FlightLegOption)[0];
        const airlineLogo = isOneWayOrRoundTrip ? leg.airline_logo[0] : undefined;

        const layovers = [];
        for (let i=0; i<leg.layovers?.length || 0; i++) {
            layovers.push(<div key={i} />)
        }

        return (
            <div key={index} className="flex-col justify-start items-start gap-4 inline-flex">
                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                    <div className="w-8 h-8 relative  overflow-hidden">
                        {airlineLogo
                            ? <img src={airlineLogo} alt={airline} className="airline-logo" />
                            : <PlaneIcon />
                        }
                    </div>

                    <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                        <div className="flex-col justify-start items-start gap-2 inline-flex">
                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                <div className="leading-normal">
                                    <Text>{formatDate_ShortHuman(departureTime)}</Text>
                                </div>
                            </div>
                        </div>
                        
                        <div className="leading-[14px]">
                            <Text>•</Text>
                        </div>
                        
                        <div className="flex-col justify-start items-start gap-2 inline-flex">
                            <div className="justify-start items-start gap-4 inline-flex">
                                <div className="leading-normal">
                                    <Text>{airline} {label ? ` (${label})` : ''}</Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                    <div className="self-stretch justify-between items-center inline-flex">
                        <div className="leading-tight">
                            <Text fontSize='s'>{formatTime_AmPm(departureTime)}</Text>
                        </div>

                        <div className="visual">
                            {layovers}
                        </div>

                        <div className="leading-tight">
                            <Text fontSize='s'>{formatTime_AmPm(arrivalTime)}</Text>
                        </div>
                    </div>

                    <div className="self-stretch px-3 justify-between items-start inline-flex">
                        <div className="leading-tight">
                            <Text fontSize='xs'>
                                {isOneWayOrRoundTrip ? leg.departure_airport : leg.departure_airport_name}
                            </Text>
                        </div>
                        <div className="text-center">
                            <span className="leading-[6px]">
                                <Text fontSize='xs'>
                                    {`${Math.floor(duration / 60)}h ${duration % 60}m`}
                                </Text>
                            </span>
                            <span className="text-[#957bf4] text-xs font-medium font-['Rand'] leading-[18px]"></span>
                            <span className="leading-[14px]">
                                <Text fontSize='xs'>
                                    {isOneWayOrRoundTrip
                                        ? (leg.layovers && leg.layovers.length > 0
                                            ? `${leg.layovers.length} stop(s): ${leg.layovers.join(', ')}`
                                            : 'nonstop')
                                        : ((leg as FlightLegOption).layovers?.length
                                            ? `${(leg as FlightLegOption).layovers.length} stop(s): ${(leg as FlightLegOption).layovers.map(l => l.airport_code).join(', ')}`
                                            : 'nonstop')
                                    }
                                </Text>
                            </span>
                        </div>
                        <div className="leading-tight">
                            <Text fontSize='xs'>{isOneWayOrRoundTrip ? leg.arrival_airport : leg.arrival_airport_name}</Text>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderFlightDetails = () => {
        const { option } = this.props;
        const flightType = this.getFlightType();

        switch (flightType) {
            case 'roundtrip':
                const { departure_option, return_option } = option as RoundTripFlight;
                return (
                    <>
                        {this.renderFlightLeg(departure_option, 0, 'Outbound Flight')}
                        {this.renderFlightLeg(return_option, 1, 'Return Flight')}
                    </>
                );
            case 'multi-city':
                const { legs } = option as MultiCityFlight;
                const sortedLegs = this.sortLegs(legs);
                return sortedLegs.map((leg, index) => this.renderFlightLeg(leg, index));
            default:
                return this.renderFlightLeg(option as OneWayFlight, 0);
        }
    }

    getAirlinesFromLeg = (leg: FlightLegOption): string[] => {
        if (leg.airlines) return leg.airlines;
        if (leg.airline) return [leg.airline];
        return [];
    }

    getCardTitle = () => {
        const { option } = this.props;
        const flightType = this.getFlightType();

        switch (flightType) {
            case 'roundtrip': {
                const { departure_option } = option as RoundTripFlight;
                const { departure_airport, arrival_airport } = departure_option;
                return `${departure_airport} <> ${arrival_airport}`;
            }
            case 'multi-city': {
                const { legs } = option as MultiCityFlight;
                const sortedLegs = this.sortLegs(legs);
                return sortedLegs.map(leg => leg.departure_airport).join(' > ');
            }
            default: { //one-way
                const { departure_airport, arrival_airport } = option as OneWayFlight;
                return `${departure_airport} > ${arrival_airport}`;
            }
        }
    }

    getCardSubTitle = () => {
        const { option } = this.props;
        const flightType = this.getFlightType();

        switch (flightType) {
            case 'roundtrip': {
                const { departure_option } = option as RoundTripFlight;
                const airlines = new Set([...departure_option.airlines]);
                return Array.from(airlines).join(' / ');
            }
            case 'multi-city': {
                const { legs } = option as MultiCityFlight;
                const airlines = new Set(legs.flatMap(leg => this.getAirlinesFromLeg(leg)));
                return Array.from(airlines).join(' / ');
            }
            default: {
                const airlines = new Set((option as OneWayFlight).airlines);
                return Array.from(airlines).join(' / ');
            }
        }
    }

    getLegroom = () => {
        const { option } = this.props;
        if (this.isRoundTrip()) {
            const roundTripOption = option as RoundTripFlight;
            return roundTripOption.departure_option.legroom[0]; // Taking first leg's legroom
        }
        if (this.isMultiCity()) {
            return ""; // Handle multi-city case if needed
        }
        return (option as OneWayFlight).legroom[0];
    }

    getLayoversFromLeg = (leg: FlightLegOption): string[] => {
        if (!leg.layovers) return [];
        return leg.layovers.map(l => l.airport_code);
    }

    render() {
        const {
            option,
            index,
            ranking_reasoning,
            numPassengers,
            allOptions,
            allScores,
            score
        } = this.props;

        const flightType = this.getFlightType();
        const totalPrice = this.getPrice();
        const rawPrice = this.isRoundTrip()
            ? ((option as RoundTripFlight).price ?? (option as RoundTripFlight).return_option.price ?? (option as RoundTripFlight).departure_option.price)
            : option.price;
        const perPersonPrice = rawPrice ? formatPrice(rawPrice / numPassengers) : 'Price not available';

        return (
            <ResultCard
                info={{
                    index,
                    numPeople: numPassengers
                }}

                ranking={flightType !== 'multi-city' ? { 
                    allOptions: allScores, 
                    score, 
                    ranking_reasoning 
                } : undefined}

                title={{
                    title: this.getCardTitle(),
                    subTitle: this.getCardSubTitle()
                }}

                flightLegs={this.renderFlightDetails()}

                cta={{
                    formatPrice: false,
                    ctaPrice: totalPrice,
                    ctaSub: numPassengers > 1 ? `${perPersonPrice}/person` : '',
                    booking_url: option.booking_url || option.booking_urls
                }}

                features={[
                    this.getTravelClass(),
                    `${this.getLegroom()} legroom`
                ]}
            />
        );
    }
}

export default FlightOption;