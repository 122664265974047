import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import API from 'util/API';

interface AnalysisData {
    opening_insight: string;
    pattern_weaving: { [key: string]: string[] };
    behavioral_signatures: string[];
    key_insight: string;
    illuminating_moments: string;
    adaptive_patterns: string;
    preference_architecture: string;
}

export const useAnalysis = () => {
    const [analysisData, setAnalysisData] = useState<AnalysisData | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const parseAnalysisText = useCallback((analysisData: any) => {
        if (!analysisData || !analysisData.user_analysis) {
            console.warn('Invalid analysis data structure received');
            return {
                opening_insight: '',
                pattern_weaving: {},
                behavioral_signatures: [],
                key_insight: '',
                illuminating_moments: '',
                adaptive_patterns: '',
                preference_architecture: ''
            };
        }

        const {
            opening_insight,
            pattern_weaving,
            behavioral_signatures,
            key_insight,
            illuminating_moments,
            adaptive_patterns,
            preference_architecture
        } = analysisData.user_analysis;

        const transformedPatternWeaving = {
            'Travel & Lifestyle': [pattern_weaving || ''],
            'Behavioral Insights': [behavioral_signatures || ''],
        };

        return {
            opening_insight: opening_insight || '',
            pattern_weaving: transformedPatternWeaving,
            behavioral_signatures: behavioral_signatures || [],
            key_insight: key_insight || '',
            illuminating_moments: illuminating_moments || '',
            adaptive_patterns: adaptive_patterns || '',
            preference_architecture: preference_architecture || ''
        };
    }, []);

    const fetchAnalysis = useCallback(async (forceUpdate: boolean = true) => {
        try {
            setIsLoading(true);
            const response = await API.get(`/api/prompt/analysis?force_update=${forceUpdate}`);
            const data = await response.json();
            const parsedData = parseAnalysisText(data);
            setAnalysisData(parsedData);
            return parsedData;
        } catch (error) {
            console.error('Failed to fetch analysis:', error);
            toast.error('Failed to load analysis data');
            throw error;
        } finally {
            setIsLoading(false);
        }
    }, [parseAnalysisText]);

    const refreshAnalysis = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await API.get('/api/prompt/analysis?force_update=false');
            const data = await response.json();
            const parsedData = parseAnalysisText(data);
            setAnalysisData(parsedData);
            toast.success('Analysis refreshed successfully');
        } catch (error) {
            console.error('Failed to refresh analysis:', error);
            toast.error('Failed to refresh analysis');
        } finally {
            setIsLoading(false);
        }
    }, [parseAnalysisText]);

    return {
        analysisData,
        isLoading,
        fetchAnalysis,
        refreshAnalysis
    };
}; 