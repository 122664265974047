import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Tabs, Tab } from '../common/TabSystem';
import { useGraphContext } from '../context/GraphContext';

interface QuerySuggestion {
  title: string;
  description: string;
  query: string;
  filters: {
    nodeTypes?: string[];
    edgeTypes?: string[];
    properties?: Record<string, any>;
  };
  importance: number;
  tags?: string[];
}

interface EnhancedExplorationPanelProps {
  darkMode: boolean;
  onApplyFilter: (filters: any) => void;
  onSearch: (query: string) => void;
}

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const SectionTitle = styled.h3<{ $darkMode: boolean }>`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: ${props => props.$darkMode ? '#F1F5F9' : '#0F172A'};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SuggestionCard = styled.div<{ $darkMode: boolean; $importance: number }>`
  background-color: ${props => props.$darkMode ? '#334155' : '#F1F5F9'};
  border-left: 4px solid ${props => {
    const colorIntensity = Math.min(100, Math.round(props.$importance * 100));
    return props.$darkMode 
      ? `hsl(220, 60%, ${50 + colorIntensity * 0.2}%)` 
      : `hsl(220, 80%, ${40 - colorIntensity * 0.2}%)`;
  }};
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${props => props.$darkMode ? '#475569' : '#E2E8F0'};
    transform: translateY(-1px);
  }
`;

const SuggestionTitle = styled.h4<{ $darkMode: boolean }>`
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 6px 0;
  color: ${props => props.$darkMode ? '#F1F5F9' : '#0F172A'};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SuggestionDescription = styled.p<{ $darkMode: boolean }>`
  font-size: 13px;
  color: ${props => props.$darkMode ? '#CBD5E1' : '#334155'};
  margin: 0 0 8px 0;
`;

const QueryText = styled.div<{ $darkMode: boolean }>`
  background-color: ${props => props.$darkMode ? '#1E293B' : '#E2E8F0'};
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 13px;
  color: ${props => props.$darkMode ? '#94A3B8' : '#475569'};
  font-family: monospace;
`;

const FiltersPreview = styled.div<{ $darkMode: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;
`;

const FilterTag = styled.div<{ $darkMode: boolean; $type: string }>`
  background-color: ${props => {
    const baseColor = props.$type === 'node' 
      ? props.$darkMode ? '#3B82F6' : '#60A5FA'
      : props.$darkMode ? '#8B5CF6' : '#A78BFA';
    return props.$darkMode ? baseColor : baseColor;
  }};
  color: ${props => props.$darkMode ? '#FFFFFF' : '#FFFFFF'};
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
`;

const EmptyState = styled.div<{ $darkMode: boolean }>`
  text-align: center;
  padding: 24px 0;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  font-size: 14px;
`;

const LoadingSpinner = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;
  display: inline-block;
  margin-left: 8px;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

// Icon components
const ExplorationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="10"></circle>
    <path d="M12 16v-4"></path>
    <path d="M12 8h.01"></path>
  </svg>
);

const RelationshipIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="7" y1="17" x2="17" y2="7"></line>
    <polyline points="7 7 7 17 17 17"></polyline>
  </svg>
);

const PatternIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="3" y1="9" x2="21" y2="9"></line>
    <line x1="9" y1="21" x2="9" y2="9"></line>
  </svg>
);

const AnomalyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
    <line x1="12" y1="8" x2="12" y2="12"></line>
    <line x1="12" y1="16" x2="12.01" y2="16"></line>
  </svg>
);

const CommunityIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
  </svg>
);

export const EnhancedExplorationPanel: React.FC<EnhancedExplorationPanelProps> = ({
  darkMode,
  onApplyFilter,
  onSearch
}) => {
  const { suggestedQueries, isLoadingSuggestions } = useGraphContext();

  // Add tags to suggestions if they don't have them
  const taggedSuggestions = useMemo(() => {
    if (!Array.isArray(suggestedQueries)) return [];

    return suggestedQueries.map(suggestion => {
      if (suggestion.tags) return suggestion;
      
      // Infer tags from content if not present
      const tags: string[] = [];
      const lowerTitle = suggestion.title.toLowerCase();
      const lowerDesc = suggestion.description.toLowerCase();
      
      if (lowerTitle.includes('relation') || lowerDesc.includes('relation') || 
          lowerTitle.includes('connect') || lowerDesc.includes('connect')) {
        tags.push('relationships');
      }
      
      if (lowerTitle.includes('pattern') || lowerDesc.includes('pattern') || 
          lowerTitle.includes('common') || lowerDesc.includes('common')) {
        tags.push('patterns');
      }
      
      if (lowerTitle.includes('unusual') || lowerDesc.includes('unusual') || 
          lowerTitle.includes('anomal') || lowerDesc.includes('anomal') ||
          lowerTitle.includes('outlier') || lowerDesc.includes('outlier')) {
        tags.push('anomalies');
      }
      
      if (lowerTitle.includes('communit') || lowerDesc.includes('communit') || 
          lowerTitle.includes('group') || lowerDesc.includes('group')) {
        tags.push('communities');
      }
      
      // Default tag if nothing matched
      if (tags.length === 0) {
        tags.push('general');
      }
      
      return { ...suggestion, tags };
    });
  }, [suggestedQueries]);
  
  // Group suggestions by category
  const categorizedSuggestions = useMemo(() => {
    return {
      all: taggedSuggestions,
      relationships: taggedSuggestions.filter(q => q.tags?.includes('relationships')),
      patterns: taggedSuggestions.filter(q => q.tags?.includes('patterns')),
      anomalies: taggedSuggestions.filter(q => q.tags?.includes('anomalies')),
      communities: taggedSuggestions.filter(q => q.tags?.includes('communities'))
    };
  }, [taggedSuggestions]);
  
  const handleSuggestionClick = (suggestion: QuerySuggestion) => {
    // Apply the suggested filters
    onApplyFilter({
      nodeTypes: suggestion.filters?.nodeTypes || [],
      edgeTypes: suggestion.filters?.edgeTypes || [],
      propertyFilters: suggestion.filters?.properties || {}
    });
    
    // If there's a query, run it as a search
    if (suggestion.query) {
      onSearch(suggestion.query);
    }
  };
  
  // Helper to render a list of suggestions
  const renderSuggestionList = (suggestions: QuerySuggestion[], icon?: React.ReactNode) => {
    if (suggestions.length === 0) {
      return (
        <EmptyState $darkMode={darkMode}>
          No suggestions in this category
        </EmptyState>
      );
    }
    
    return suggestions.map((suggestion, index) => (
      <SuggestionCard 
        key={index} 
        $darkMode={darkMode} 
        $importance={suggestion.importance || 0.5}
        onClick={() => handleSuggestionClick(suggestion)}
      >
        <SuggestionTitle $darkMode={darkMode}>
          {icon}
          {suggestion.title}
        </SuggestionTitle>
        <SuggestionDescription $darkMode={darkMode}>
          {suggestion.description}
        </SuggestionDescription>
        <QueryText $darkMode={darkMode}>
          {suggestion.query}
        </QueryText>
        <FiltersPreview $darkMode={darkMode}>
          {Array.isArray(suggestion.filters?.nodeTypes) && 
            suggestion.filters.nodeTypes.map(type => (
              <FilterTag key={`node-${type}`} $darkMode={darkMode} $type="node">
                Node: {type}
              </FilterTag>
            ))
          }
          {Array.isArray(suggestion.filters?.edgeTypes) && 
            suggestion.filters.edgeTypes.map(type => (
              <FilterTag key={`edge-${type}`} $darkMode={darkMode} $type="edge">
                Edge: {type}
              </FilterTag>
            ))
          }
        </FiltersPreview>
      </SuggestionCard>
    ));
  };
  
  return (
    <Panel>
      <SectionTitle $darkMode={darkMode}>
        <ExplorationIcon />
        AI-Suggested Exploration Paths
        {isLoadingSuggestions && <LoadingSpinner />}
      </SectionTitle>
      
      {taggedSuggestions.length > 0 ? (
        <Tabs darkMode={darkMode}>
          <Tab label="All">
            {renderSuggestionList(categorizedSuggestions.all)}
          </Tab>
          <Tab label="Relationships">
            {renderSuggestionList(
              categorizedSuggestions.relationships,
              <RelationshipIcon />
            )}
          </Tab>
          <Tab label="Patterns">
            {renderSuggestionList(
              categorizedSuggestions.patterns,
              <PatternIcon />
            )}
          </Tab>
          <Tab label="Anomalies">
            {renderSuggestionList(
              categorizedSuggestions.anomalies,
              <AnomalyIcon />
            )}
          </Tab>
          <Tab label="Communities">
            {renderSuggestionList(
              categorizedSuggestions.communities,
              <CommunityIcon />
            )}
          </Tab>
        </Tabs>
      ) : (
        <EmptyState $darkMode={darkMode}>
          {isLoadingSuggestions 
            ? 'Generating suggestions...' 
            : 'No suggestions available yet. Try exploring the graph first.'}
        </EmptyState>
      )}
    </Panel>
  );
};