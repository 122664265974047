import React, { useState } from "react";
import LeftNav from "../LeftNav";
import Footer from "../Footer";

export default function BasePage({ children, footer = false, innerCls = '' }: any) {
  const [isNavOpen, setIsNavOpen] = useState(true);

  return (
    <main className="adapter-basepage">
      <LeftNav onToggle={(open) => setIsNavOpen(open)} />

      <div className={`adapter-basepage-inner ${innerCls}`} tabIndex={0}>
        {children}
        <Footer copy={footer} />
      </div>
    </main>
  );
}