import { createGlobalStyle, keyframes, styled } from 'styled-components';

// Keyframes for overlay animation - no blur, just opacity
export const blurIn = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

export const blurOut = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.7);
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
`;

// Container for the entire knowledge graph explorer
export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${props => props.theme.bgColor};
  color: ${props => props.theme.textColor};
  transition: background-color 0.3s ease, color 0.3s ease;
  overflow: hidden;
  touch-action: none; /* Prevent default touch behaviors in iOS Safari */
`;

export const FullscreenCanvas = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

// Add CSP metadata style to allow data URIs and backend URLs for audio
export const CSPMetadataStyle = createGlobalStyle`
  meta[http-equiv="Content-Security-Policy"] {
    content: "default-src 'self'; media-src 'self' data: http://localhost:* http://127.0.0.1:* http://0.0.0.0:*; connect-src 'self' http: https:;";
  }
`;

export const FPSMonitor = styled.div<{$darkMode: boolean}>`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: ${props => props.$darkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)'};
  color: ${props => props.$darkMode ? '#fff' : '#000'};
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 1000;
  pointer-events: none;
`;

// Elegant overlay for voice processing - no blur effects that might cause flickering
export const BlurOverlay = styled.div<{ $active: boolean; $darkMode: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5; // Above the graph but below the sidebars
  pointer-events: ${props => props.$active ? 'all' : 'none'};
  animation: ${props => props.$active ? blurIn : blurOut} 0.8s ease-in-out forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => props.$active ? 1 : 0};
  
  /* Add a subtle radial gradient around the flexagon using the cyan/tide color from screenshot */
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 400px;
    background: ${props => props.$darkMode ? 
      'radial-gradient(circle, rgba(67, 232, 249, 0.15) 0%, rgba(67, 232, 249, 0) 70%)' : 
      'radial-gradient(circle, rgba(124, 58, 237, 0.1) 0%, rgba(124, 58, 237, 0) 70%)'};
    z-index: -1;
  }
`;

export const ProcessingText = styled.div<{ $darkMode: boolean }>`
  font-size: 28px;
  font-weight: 600;
  color: ${props => props.$darkMode ? '#67E8F9' : '#7C3AED'}; /* Tide 300 for dark mode from brand guide */
  text-align: center;
  letter-spacing: 0.3px;
  margin-top: 35px;
  background: ${props => props.$darkMode ? 'rgba(33, 37, 41, 0.7)' : 'rgba(255, 255, 255, 0.7)'};
  backdrop-filter: blur(5px);
  padding: 10px 20px;
  border-radius: 100px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-shadow: ${props => props.$darkMode ? '0 1px 2px rgba(0, 0, 0, 0.2)' : '0 1px 1px rgba(255, 255, 255, 0.5)'};
  border: 1px solid ${props => props.$darkMode ? 'rgba(67, 232, 249, 0.4)' : 'rgba(196, 181, 253, 0.6)'};
`;

// Sidebar interfaces
export interface SidebarProps {
  $collapsed: boolean;
}

export interface RightSidebarProps extends SidebarProps {
  $hasSelectedNode: boolean;
}

export const LeftSidebar = styled.div<SidebarProps>`
  position: absolute;
  top: 16px;
  left: 12px;
  width: ${props => props.$collapsed ? '60px' : '320px'};
  height: calc(100% - 32px); /* Equal 16px margin on top and bottom */
  padding: ${props => props.$collapsed ? '12px 8px 16px 8px' : '16px 16px 16px 16px'};
  overflow-y: auto;
  background-color: ${props => props.theme.bgColor}; /* Solid background */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  z-index: 2;
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  
  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.borderColor}40;
    border-radius: 4px;
    border: 2px solid transparent;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${props => props.theme.borderColor}80;
  }
`;

export const RightSidebarContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 12px;
  z-index: 2;
`;

// Define a new interface to help with transition state
export interface RightSidebarProps extends SidebarProps {
  $hasSelectedNode: boolean;
  $isTransitioning?: boolean;
}

export const RightSidebar = styled.div<RightSidebarProps>`
  position: relative;
  width: ${props => props.$collapsed ? '240px' : '360px'};
  height: ${props => props.$collapsed ? 'auto' : 'calc(100vh - 32px)'}; /* Equal 16px margin on top and bottom */
  min-height: ${props => props.$collapsed ? '150px' : 'auto'};
  max-height: ${props => props.$collapsed ? '320px' : 'calc(100vh - 32px)'}; /* Equal 16px margin on top and bottom */
  padding: ${props => props.$collapsed ? '16px 16px 16px 16px' : '16px'};
  overflow-y: auto;
  background-color: ${props => props.theme.bgColor}; /* Solid background */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  
  @media (max-width: 768px) {
    /* Mobile styling */
    position: fixed;
    height: auto;
    min-height: auto;
    max-height: none;
    width: auto;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    background: none;
    box-shadow: none;
    overflow: visible;
    
    /* Hide all content except the detail panel when collapsed */
    ${props => props.$collapsed ? `
      & > *:not(div.detail-panel) {
        display: none;
      }
    ` : ''}
  }
  transition: all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  border-radius: 16px;
  opacity: ${props => !props.$collapsed || props.$hasSelectedNode ? 1 : 0.8};
  
  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.borderColor}40;
    border-radius: 4px;
    border: 2px solid transparent;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${props => props.theme.borderColor}80;
  }

  /* Only apply hover effects when not transitioning */
  ${props => !props.$isTransitioning && `
    &:hover {
      opacity: 1;
      ${props.$collapsed && props.$hasSelectedNode ? 'box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);' : ''}
    }
  `}
`;

export const PanelCollapseButton = styled.button<{$darkMode: boolean}>`
  position: absolute;
  background: ${props => props.$darkMode ? 'rgba(33, 37, 41, 0.95)' : 'rgba(255, 255, 255, 0.95)'};
  color: ${props => props.$darkMode ? '#ADB5BD' : '#6C757D'};
  border: none;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 5;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;
  
  &:hover {
    background: ${props => props.$darkMode ? 'rgba(52, 58, 64, 1)' : 'rgba(233, 236, 239, 1)'};
    color: ${props => props.$darkMode ? '#DEE2E6' : '#343A40'};
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.$darkMode ? 'rgba(139, 92, 246, 0.5)' : 'rgba(124, 58, 237, 0.5)'};
  }
  
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const LeftPanelCollapseButton = styled(PanelCollapseButton)<{$isCollapsed: boolean}>`
  bottom: 16px;
  ${props => props.$isCollapsed ? 'left: 50%; transform: translateX(-50%);' : 'right: 8px;'}
  
  &:hover {
    ${props => props.$isCollapsed ? 'transform: translateX(-50%) scale(1.1);' : 'transform: scale(1.1);'}
  }
`;

export const RightPanelCollapseButton = styled(PanelCollapseButton)`
  position: absolute;
  top: 50%;
  left: -17px; /* Position it just outside the panel */
  z-index: 10; /* Ensure it's above other elements */
  transform: translateY(-50%); /* Center vertically */
  
  @media (max-width: 768px) {
    display: none; /* Hide on mobile */
  }
`;

export const CollapsedPanelControls = styled.div<{$darkMode: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: ${props => props.$darkMode ? 'rgba(52, 58, 64, 0.5)' : 'rgba(233, 236, 239, 0.8)'};
    }
  }
  
  svg {
    width: 24px;
    height: 24px;
    color: ${props => props.$darkMode ? '#ADB5BD' : '#6C757D'};
    cursor: pointer;
    transition: color 0.2s ease;
    
    &:hover {
      color: ${props => props.$darkMode ? '#DEE2E6' : '#343A40'};
    }
  }
`;

export const CondensedNodeInfo = styled.div<{$darkMode: boolean}>`
  padding: 8px 0;
  
  h4 {
    font-size: 15px;
    margin: 0 0 6px 0;
    color: ${props => props.$darkMode ? '#F8F9FA' : '#212529'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
  }
  
  p {
    font-size: 13px;
    margin: 0;
    color: ${props => props.$darkMode ? '#ADB5BD' : '#6C757D'};
    display: flex;
    align-items: center;
    
    &::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 6px;
      border-radius: 50%;
      background-color: ${props => props.$darkMode ? '#67E8F9' : '#7C3AED'}; /* Tide-300 for dark mode, Echo-600 for light mode */
    }
  }
  
  small {
    display: block;
    font-size: 12px;
    margin-top: 6px;
    color: ${props => props.$darkMode ? '#6C757D' : '#ADB5BD'};
    font-style: italic;
  }
`;

export const NodeConnectionCount = styled.div<{$darkMode: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding-top: 8px;
  border-top: 1px solid ${props => props.$darkMode ? 'rgba(173, 181, 189, 0.2)' : 'rgba(222, 226, 230, 0.5)'};
  font-size: 12px;
  color: ${props => props.$darkMode ? '#ADB5BD' : '#6C757D'};
  
  span {
    display: flex;
    align-items: center;
    
    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      margin-right: 6px;
      border-radius: 50%;
      background-color: ${props => props.$darkMode ? '#67E8F9' : '#22C55E'}; /* Tide-300 for dark mode, Mint brand colors for light */
    }
  }
  
  button {
    background: none;
    border: none;
    padding: 0;
    font-size: 12px;
    color: ${props => props.$darkMode ? '#67E8F9' : '#06B6D4'}; /* Tide-300 for dark mode, Tide-500 for light */
    cursor: pointer;
    transition: opacity 0.2s;
    
    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
`;

export const ExpandPanelButton = styled.button<{$darkMode: boolean}>`
  width: 100%;
  padding: 10px;
  margin-top: 12px;
  background: ${props => props.$darkMode ? 'rgba(67, 232, 249, 0.15)' : 'rgba(124, 58, 237, 0.1)'};
  color: ${props => props.$darkMode ? '#67E8F9' : '#7C3AED'}; /* Tide-300 for dark mode, Echo-600 for light */
  border: none;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &::before {
    content: '';
    display: inline-block;
    width: 0; 
    height: 0; 
    margin-right: 6px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid currentColor;
    transform: rotate(-90deg);
  }
  
  &:hover {
    background: ${props => props.$darkMode ? 'rgba(67, 232, 249, 0.25)' : 'rgba(124, 58, 237, 0.15)'};
    transform: translateY(-1px);
  }
  
  @media (max-width: 768px) {
    display: none; /* Hide on mobile */
  }
`;

export const MainContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const TabControl = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.borderColor}40;
  margin-bottom: 16px;
  padding-bottom: 4px;
`;

export const TabButton = styled.button<{ $active: boolean }>`
  background: none;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: ${props => props.$active ? '600' : '500'};
  color: ${props => props.$active ? props.theme.primaryColor : props.theme.secondaryColor};
  border-bottom: 2px solid ${props => props.$active ? props.theme.primaryColor : 'transparent'};
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.2, 0.8, 0.2, 1);
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background: ${props => props.$active ? props.theme.primaryColor : 'transparent'};
    border-radius: 1px;
    opacity: ${props => props.$active ? 1 : 0};
    transform: scaleX(${props => props.$active ? 1 : 0.5});
    transform-origin: center;
    transition: all 0.25s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  
  &:hover {
    color: ${props => props.$active ? props.theme.primaryColor : props.theme.textColor};
    
    &:after {
      opacity: 0.7;
      transform: scaleX(0.8);
      background: ${props => props.$active ? props.theme.primaryColor : props.theme.secondaryColor};
    }
  }
`;

export const GraphArea = styled.div`
  flex: 1;
  position: relative;
`;

// Additional styles for custom tabs
export const CustomTabsStyle = createGlobalStyle`
  .custom-tabs {
    margin-bottom: 1rem;
    
    .tab-buttons {
      display: flex;
      border-bottom: 1px solid ${props => props.theme.borderColor}40;
      
      button {
        background: none;
        border: none;
        padding: 8px 16px;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 6px;
        color: ${props => props.theme.secondaryColor};
        border-bottom: 2px solid transparent;
        transition: all 0.2s ease;
        
        &:hover {
          color: ${props => props.theme.textColor};
        }
        
        &.active {
          color: ${props => props.theme.primaryColor};
          font-weight: 600;
          border-bottom: 2px solid ${props => props.theme.primaryColor};
        }
        
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;