import { NodeData, EdgeData } from '../types/types';
import Logger from 'util/Logger';

/**
 * Normalizes edge source and target fields to ensure they are string IDs
 */
export function normalizeEdgeReferencesLegacy(edges: EdgeData[], nodes: NodeData[]): EdgeData[] {
  // Create a map of node IDs for quick lookup
  const nodeMap = new Map<string, boolean>();
  nodes.forEach(node => nodeMap.set(node.id, true));
  
  return edges.map(edge => {
    const newEdge = { ...edge };
    
    // Handle source reference
    if (typeof newEdge.source === 'object' && newEdge.source !== null) {
      const sourceId = (newEdge.source as any).id;
      if (sourceId && nodeMap.has(sourceId)) {
        newEdge.source = sourceId;
      }
    }
    
    // Handle target reference
    if (typeof newEdge.target === 'object' && newEdge.target !== null) {
      const targetId = (newEdge.target as any).id;
      if (targetId && nodeMap.has(targetId)) {
        newEdge.target = targetId;
      }
    }
    
    return newEdge;
  });
}

/**
 * Validates that all edges have valid source and target node references
 */
export function validateEdges(edges: EdgeData[], nodes: NodeData[]): boolean {
  const nodeIds = new Set(nodes.map(node => node.id));
  
  for (const edge of edges) {
    const sourceId = typeof edge.source === 'string' ? edge.source : (edge.source as any)?.id;
    const targetId = typeof edge.target === 'string' ? edge.target : (edge.target as any)?.id;
    
    if (!sourceId || !targetId || !nodeIds.has(sourceId) || !nodeIds.has(targetId)) {
      Logger.error(`Invalid edge: ${edge.id} with source ${sourceId} and target ${targetId}`);
      return false;
    }
  }
  
  return true;
}

/**
 * English stop words that can be removed from search queries
 */
export const STOP_WORDS = new Set([
  'a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'on', 'at', 'to', 'by', 
  'is', 'are', 'was', 'were', 'be', 'been', 'being', 'have', 'has', 'had', 'do', 
  'does', 'did', 'will', 'would', 'shall', 'should', 'can', 'could', 'may', 'might', 
  'must', 'ought', 'i', 'you', 'he', 'she', 'it', 'we', 'they', 'me', 'him', 'her', 
  'us', 'them', 'my', 'your', 'his', 'its', 'our', 'their', 'mine', 'yours', 'hers', 
  'ours', 'theirs', 'this', 'that', 'these', 'those', 'what', 'which', 'who', 'whom', 
  'whose', 'where', 'when', 'why', 'how', 'all', 'any', 'both', 'each', 'few', 'more', 
  'most', 'some', 'such', 'no', 'nor', 'not', 'only', 'own', 'same', 'so', 'than', 
  'too', 'very', 'just', 'from', 'about', 'with', 'of', 'in', 'as'
]);

/**
 * Removes stop words from a search query
 * @param query The query string to process
 * @returns The query with stop words removed
 */
export function removeStopWords(query: string): string {
  const words = query.toLowerCase().split(/\s+/);
  const filteredWords = words.filter(word => !STOP_WORDS.has(word));
  return filteredWords.join(' ');
}

/**
 * Determines if a field should be excluded from search based on the excludeFields array
 * @param fieldPath The path to the field (e.g., 'label', 'properties.name')
 * @param excludeFields Array of field paths to exclude
 * @returns True if the field should be excluded, false otherwise
 */
function shouldExcludeField(fieldPath: string, excludeFields: string[] | undefined): boolean {
  if (!excludeFields || excludeFields.length === 0) {
    return false;
  }
  
  return excludeFields.some(excludePath => {
    // Direct match
    if (fieldPath === excludePath) {
      return true;
    }
    
    // Check if it's a prefix match for nested properties
    // e.g., 'properties' should exclude 'properties.name', 'properties.email', etc.
    if (fieldPath.startsWith(excludePath + '.')) {
      return true;
    }
    
    // Check if a more specific exclusion matches this field path
    // e.g., 'properties.name' should match when the field path is 'properties'
    if (excludePath.startsWith(fieldPath + '.')) {
      return true;
    }
    
    return false;
  });
}

/**
 * Filters nodes based on a search query, with support for field exclusion
 * 
 * @param nodes Array of nodes to filter
 * @param query The search query to match against
 * @param excludeFields Optional array of field paths to exclude from matching
 * @returns Array of nodes that match the query
 * 
 * @example
 * // Filter nodes without excluding any fields
 * const results = filterNodesByQuery(nodes, "John");
 * 
 * @example
 * // Filter nodes but exclude label field from matching
 * const results = filterNodesByQuery(nodes, "John", ["label"]);
 * 
 * @example
 * // Filter nodes but exclude specific property fields
 * const results = filterNodesByQuery(nodes, "John", ["properties.email", "properties.phone"]);
 * 
 * @example
 * // Filter nodes but exclude all property fields
 * const results = filterNodesByQuery(nodes, "John", ["properties"]);
 * 
 * @example
 * // Filter nodes but exclude nested property fields
 * const results = filterNodesByQuery(nodes, "John", ["properties.contact.email"]);
 * 
 * The excludeFields parameter supports:
 * - Top-level fields: 'label', 'id', 'type'
 * - All properties: 'properties'
 * - Specific properties: 'properties.name', 'properties.email'
 * - Nested properties: 'properties.contact.email', 'properties.metadata.tags'
 */
export function filterNodesByQueryLegacy(
  nodes: NodeData[], 
  edges: EdgeData[], // Now required for relationship expansion
  query: string, 
  excludeFields?: string[],
  entityTypes?: string[],
  dateRange?: { start?: Date; end?: Date },
  relationshipTypes?: string[] // Specific relationships to follow
): { nodes: NodeData[], edges: EdgeData[] } {
  // Helper function to detect email/domain matches in a node, respecting exclusions
  function containsEmailMatches(node: NodeData, searchTerms: string[], excludePaths?: string[]): boolean {
    if (!node.properties) return false;
    
    // SPECIAL CASE: Direct check for calendar_event attendees (most common case)
    if (node.type === 'calendar_event' && 
        node.properties.attendees && 
        Array.isArray(node.properties.attendees) && 
        !shouldExcludeField('properties.attendees', excludePaths)) {
      
      for (let i = 0; i < node.properties.attendees.length; i++) {
        const attendee = node.properties.attendees[i];
        const attendeePath = `properties.attendees[${i}]`;
        
        // Skip if this specific attendee should be excluded
        if (shouldExcludeField(attendeePath, excludePaths)) continue;
        
        // Check attendee email field
        if (attendee && 
            typeof attendee === 'object' && 
            attendee.email && 
            !shouldExcludeField(`${attendeePath}.email`, excludePaths)) {
          
          const email = attendee.email.toLowerCase();
          
          // Skip if not a valid email format
          if (!email.includes('@')) continue;
          
          // Extract domain part and check against search terms
          const domainPart = email.split('@')[1];
          if (domainPart) {
            for (const term of searchTerms) {
              const lowerTerm = term.toLowerCase();
              
              // Check if domain contains the term (e.g., "google" in "google.com")
              if (domainPart.includes(lowerTerm)) {
                Logger.log(`Email match found in ${node.type} (${node.id}): attendee email = ${email}, term = ${lowerTerm}`);
                return true;
              }
            }
          }
        }
      }
    }
    
    // General recursive search for any other email patterns
    function deepSearch(obj: any, currentPath: string = 'properties'): boolean {
      // Base case: not an object
      if (!obj || typeof obj !== 'object') return false;
      
      // Check if current path should be excluded
      if (shouldExcludeField(currentPath, excludePaths)) {
        return false;
      }
      
      // Handle arrays
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          if (deepSearch(obj[i], `${currentPath}[${i}]`)) {
            return true;
          }
        }
        return false;
      }
      
      // Search all properties
      for (const key in obj) {
        const propertyPath = `${currentPath}.${key}`;
        
        // Skip this property if it's specifically excluded
        if (shouldExcludeField(propertyPath, excludePaths)) {
          continue;
        }
        
        const value = obj[key];
        
        // Check string values that could be emails or contain domains
        if (typeof value === 'string') {
          const lowerValue = value.toLowerCase();
          
          // Email checking - look for @ symbol in string
          if (lowerValue.includes('@')) {
            const domainPart = lowerValue.split('@')[1];
            if (domainPart) {
              for (const term of searchTerms) {
                const lowerTerm = term.toLowerCase();
                
                // Check if domain contains the term (e.g., "google" in "google.com")
                if (domainPart.includes(lowerTerm)) {
                  Logger.log(`Email match found in ${node.type} (${node.id}): ${propertyPath} = ${value}, term = ${lowerTerm}`);
                  return true;
                }
              }
            }
          }
        }
        
        // Recurse into nested objects
        if (typeof value === 'object' && value !== null) {
          if (deepSearch(value, propertyPath)) {
            return true;
          }
        }
      }
      
      return false;
    }
    
    // First check special cases, then do general search
    return deepSearch(node.properties);
  }
  
  // Helper function to check if a person node matches name-related fields
  function hasNameMatches(node: NodeData, searchTerms: string[], excludePaths?: string[]): boolean {
    if (node.type !== 'person' || !node.properties) return false;
    
    const props = node.properties;
    
    // Check common name-related fields that might exist in person nodes
    const nameFields = ['display_name', 'name', 'given_name', 'family_name', 'first_name', 'last_name'];
    
    return nameFields.some(field => {
      const propertyPath = `properties.${field}`;
      
      // Skip this field if it's specifically excluded
      if (shouldExcludeField(propertyPath, excludePaths)) {
        return false;
      }
      
      if (props[field] && typeof props[field] === 'string') {
        return searchTerms.some(term => props[field].toLowerCase().includes(term));
      }
      return false;
    });
  }
  
  // Process search terms for matching
  let terms: string[] = [];
  if (query && query.trim() !== '') {
    // Remove stop words from the query
    const processedQuery = removeStopWords(query);
    
    if (processedQuery.trim() !== '') {
      // Split processed query into individual terms
      terms = processedQuery.toLowerCase().split(/\s+/).filter(term => term.length > 0);
    }
  }
  
  // Log excluded fields if any
  if (excludeFields && excludeFields.length > 0) {
    Logger.log(`Filtering nodes with excluded fields: ${excludeFields.join(', ')}`);
  }
  
  // STEP 1: COLLECT EMAIL/NAME MATCHES FROM ALL NODES (respecting exclusions)
  // These nodes will bypass date filtering regardless of other criteria
  const emailMatchingNodes = terms.length > 0 ? nodes.filter(node => 
    (node.type === 'person' && hasNameMatches(node, terms, excludeFields)) || 
    containsEmailMatches(node, terms, excludeFields)
  ) : [];
  
  // Get IDs of email matching nodes for efficient lookup
  const emailMatchingNodeIds = new Set(emailMatchingNodes.map(node => node.id));
  
  // STEP 2: REGULAR TERM FILTERING (excluding already matched email nodes)
  let regularFilteredNodes = nodes.filter(node => !emailMatchingNodeIds.has(node.id));
  
  if (terms.length > 0) {
    regularFilteredNodes = regularFilteredNodes.filter(node => {
      // Check node label (if not excluded)
      if (
        node.label && 
        !shouldExcludeField('label', excludeFields) && 
        terms.some(term => node.label.toLowerCase().includes(term))
      ) {
        return true;
      }

      // Check node type (if not excluded)
      if (
        node.type && 
        !shouldExcludeField('type', excludeFields) && 
        terms.some(term => node.type.toLowerCase().includes(term))
      ) {
        return true;
      }
      
      // Check node ID (if not excluded)
      if (
        node.id && 
        !shouldExcludeField('id', excludeFields) && 
        terms.some(term => node.id.toLowerCase().includes(term))
      ) {
        return true;
      }

      // Check node properties for any matching term
      if (node.properties) {
        // Skip properties completely if the entire properties object is excluded
        if (shouldExcludeField('properties', excludeFields)) {
          return false;
        }
        
        for (const key in node.properties) {
          // Skip this property if it's specifically excluded
          if (shouldExcludeField(`properties.${key}`, excludeFields)) {
            continue;
          }
          
          const value = node.properties[key];
          
          // Skip null or undefined values
          if (value == null) continue;
          
          // Handle string properties
          if (typeof value === 'string' && terms.some(term => value.toLowerCase().includes(term))) {
            return true;
          }
          
          // Handle numeric properties (convert to string for matching)
          if (typeof value === 'number' && terms.some(term => String(value).includes(term))) {
            return true;
          }
          
          // Handle date properties
          if (value instanceof Date && terms.some(term => value.toISOString().toLowerCase().includes(term))) {
            return true;
          }
          
          // Handle array properties
          if (Array.isArray(value)) {
            // Skip this array if it's specifically excluded
            if (shouldExcludeField(`properties.${key}`, excludeFields)) {
              continue;
            }
            
            for (const item of value) {
              // Handle string items in the array
              if (typeof item === 'string' && terms.some(term => item.toLowerCase().includes(term))) {
                return true;
              }
              
              // Handle object items in the array (like attendees with email addresses)
              if (typeof item === 'object' && item !== null) {
                for (const itemKey in item) {
                  // Skip this nested property if it's specifically excluded
                  if (shouldExcludeField(`properties.${key}.${itemKey}`, excludeFields)) {
                    continue;
                  }
                  
                  const itemValue = item[itemKey];
                  if (typeof itemValue === 'string' && terms.some(term => itemValue.toLowerCase().includes(term))) {
                    return true;
                  }
                }
              }
            }
          }
          
          // Handle nested object properties
          if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            for (const nestedKey in value) {
              // Skip this nested property if it's specifically excluded
              if (shouldExcludeField(`properties.${key}.${nestedKey}`, excludeFields)) {
                continue;
              }
              
              const nestedValue = value[nestedKey];
              if (typeof nestedValue === 'string' && terms.some(term => nestedValue.toLowerCase().includes(term))) {
                return true;
              }
            }
          }
        }
      }
      
      return false;
    });
  }
  
  // STEP 3: ENTITY TYPE FILTERING (only for regular filtered nodes)
  if (entityTypes && entityTypes.length > 0) {
    regularFilteredNodes = regularFilteredNodes.filter(node => 
      entityTypes.includes(node.type)
    );
  }
  
  // STEP 4: DATE RANGE FILTERING (only for regular filtered nodes)
  // Set default date range (one year in past to one year in future)
  const defaultStart = new Date();
  defaultStart.setFullYear(defaultStart.getFullYear() - 1);
  
  const defaultEnd = new Date();
  defaultEnd.setFullYear(defaultEnd.getFullYear() + 1);
  
  const startDate = dateRange?.start || defaultStart;
  const endDate = dateRange?.end || defaultEnd;
  
  // Helper function to check if a date is within the specified range
  function isDateInRange(dateValue: any): boolean {
    if (!dateValue) return false;
    
    let date: Date;
    if (dateValue instanceof Date) {
      date = dateValue;
    } else if (typeof dateValue === 'string') {
      date = new Date(dateValue);
      if (isNaN(date.getTime())) return false; // Invalid date string
    } else {
      return false; // Not a date or date string
    }
    
    return date >= startDate && date <= endDate;
  }
  
  // Apply date filtering to regular filtered nodes
  const dateFilteredNodes = regularFilteredNodes.filter(node => {
    // If no properties, node doesn't have date fields to check
    if (!node.properties) return false;
    
    // Check date fields directly in properties
    if (isDateInRange(node.properties.start_datetime)) return true;
    if (isDateInRange(node.properties.end_datetime)) return true;
    if (isDateInRange(node.properties.start_date)) return true;
    if (isDateInRange(node.properties.end_date)) return true;
    
    // Check for date fields in nested properties
    for (const key in node.properties) {
      const prop = node.properties[key];
      
      // Check for date objects in nested properties
      if (prop && typeof prop === 'object' && !Array.isArray(prop)) {
        if (isDateInRange(prop.start_datetime)) return true;
        if (isDateInRange(prop.end_datetime)) return true;  
        if (isDateInRange(prop.start_date)) return true;
        if (isDateInRange(prop.end_date)) return true;
      }
    }
    
    // No date fields in range found
    return false;
  });
  
  // STEP 5: ENTITY TYPE FILTERING FOR EMAIL MATCHING NODES
  // We still want to respect entity type filters for email matching nodes
  let filteredEmailMatchingNodes = emailMatchingNodes;
  if (entityTypes && entityTypes.length > 0) {
    filteredEmailMatchingNodes = emailMatchingNodes.filter(node => 
      entityTypes.includes(node.type)
    );
  }
  
   // STEP 6: COMBINE INITIAL RESULTS
  const initialFilteredNodes = [...filteredEmailMatchingNodes, ...dateFilteredNodes];
  const initialFilteredNodeIds = new Set(initialFilteredNodes.map(node => node.id));
  
  // STEP 7: BLOOM OUT ONE HOP WITH ENHANCED INTELLIGENCE
  // Setup node and edge tracking
  const nodeMap = new Map<string, NodeData>();
  nodes.forEach(node => nodeMap.set(node.id, node));
  
  const edgeMap = new Map<string, EdgeData>();
  edges.forEach(edge => edgeMap.set(edge.id, edge));
  
  // Track nodes and edges to add
  const additionalNodeIds = new Set<string>();
  const additionalEdgeIds = new Set<string>();
  
  // 7A: STANDARD ONE-HOP EXPANSION
  edges.forEach(edge => {
    const sourceId = typeof edge.source === 'string' ? edge.source : (edge.source as any)?.id;
    const targetId = typeof edge.target === 'string' ? edge.target : (edge.target as any)?.id;
    
    if (!sourceId || !targetId) return; // Skip invalid edges
    
    // Check if this is a valid relationship type to follow
    const isSpecifiedRelationship = relationshipTypes && relationshipTypes.includes(edge.type);
    const isMemoryWithRelationship = edge.type === 'memory_with';
    
    if (!isSpecifiedRelationship && !isMemoryWithRelationship) return;
    
    // SOURCE → TARGET: If source is in our filtered set, check if target should be included
    if (initialFilteredNodeIds.has(sourceId)) {
      const targetNode = nodeMap.get(targetId);
      if (targetNode) {
        const isValidEntityType = entityTypes && entityTypes.includes(targetNode.type);
        const isMemoryNode = targetNode.type === 'memory';
        
        if (isValidEntityType || (isMemoryWithRelationship && isMemoryNode)) {
          additionalNodeIds.add(targetId);
          additionalEdgeIds.add(edge.id);
        }
      }
    }
    
    // TARGET → SOURCE: If target is in our filtered set, check if source should be included
    if (initialFilteredNodeIds.has(targetId)) {
      const sourceNode = nodeMap.get(sourceId);
      if (sourceNode) {
        const isValidEntityType = entityTypes && entityTypes.includes(sourceNode.type);
        const isMemoryNode = sourceNode.type === 'memory';
        
        if (isValidEntityType || (isMemoryWithRelationship && isMemoryNode)) {
          additionalNodeIds.add(sourceId);
          additionalEdgeIds.add(edge.id);
        }
      }
    }
  });
  
  // First bloom set (including first-hop connections)
  const firstBloomNodeIds = new Set([...initialFilteredNodeIds, ...additionalNodeIds]);
  
  // 7B: IDENTIFY PEOPLE IN RESULTS
  const personNodeIds = new Set<string>();
  nodes.forEach(node => {
    if (node.type === 'person' && (firstBloomNodeIds.has(node.id))) {
      personNodeIds.add(node.id);
    }
  });
  
  // 7C: FIND DIRECT PERSON-TO-PERSON CONNECTIONS (communicates_with)
  const directlyConnectedPersonIds = new Set<string>();
  
  edges.forEach(edge => {
    if (edge.type === 'communicates_with') {
      const sourceId = typeof edge.source === 'string' ? edge.source : (edge.source as any)?.id;
      const targetId = typeof edge.target === 'string' ? edge.target : (edge.target as any)?.id;
      
      if (!sourceId || !targetId) return;
      
      // Check if exactly one endpoint is a person in our set
      const sourceIsPerson = personNodeIds.has(sourceId);
      const targetIsPerson = personNodeIds.has(targetId);
      
      if (sourceIsPerson && !firstBloomNodeIds.has(targetId)) {
        const targetNode = nodeMap.get(targetId);
        if (targetNode && targetNode.type === 'person') {
          directlyConnectedPersonIds.add(targetId);
          additionalEdgeIds.add(edge.id);
        }
      }
      else if (targetIsPerson && !firstBloomNodeIds.has(sourceId)) {
        const sourceNode = nodeMap.get(sourceId);
        if (sourceNode && sourceNode.type === 'person') {
          directlyConnectedPersonIds.add(sourceId);
          additionalEdgeIds.add(edge.id);
        }
      }
    }
  });
  
  // 7D: BUILD EVENT-PERSON INDEX
  // Map from events to participating people
  const eventToPersonsMap = new Map<string, Set<string>>();
  // Map from persons to their events
  const personToEventsMap = new Map<string, Set<string>>();
  // Track the participation edges
  const participationEdgesMap = new Map<string, Set<string>>();
  
  edges.forEach(edge => {
    if (edge.type === 'participates_in') {
      const sourceId = typeof edge.source === 'string' ? edge.source : (edge.source as any)?.id;
      const targetId = typeof edge.target === 'string' ? edge.target : (edge.target as any)?.id;
      
      if (!sourceId || !targetId) return;
      
      const sourceNode = nodeMap.get(sourceId);
      const targetNode = nodeMap.get(targetId);
      
      if (!sourceNode || !targetNode) return;
      
      // Make sure source is person and target is event
      if (sourceNode.type === 'person' && targetNode.type === 'calendar_event') {
        // Add to person -> events map
        if (!personToEventsMap.has(sourceId)) {
          personToEventsMap.set(sourceId, new Set<string>());
          participationEdgesMap.set(sourceId, new Set<string>());
        }
        personToEventsMap.get(sourceId)!.add(targetId);
        participationEdgesMap.get(sourceId)!.add(edge.id);
        
        // Add to event -> persons map
        if (!eventToPersonsMap.has(targetId)) {
          eventToPersonsMap.set(targetId, new Set<string>());
        }
        eventToPersonsMap.get(targetId)!.add(sourceId);
      }
    }
  });
  
  // 7E: FIND CALENDAR EVENT CONNECTED PEOPLE
  const eventConnectedPersonIds = new Set<string>();
  const connectingEventIds = new Set<string>();
  
  // For each person already in our result set
  personNodeIds.forEach(personId => {
    // Find all events this person participates in
    const personEvents = personToEventsMap.get(personId);
    if (!personEvents) return;
    
    // For each event
    personEvents.forEach(eventId => {
      // Find all participants of this event
      const eventParticipants = eventToPersonsMap.get(eventId);
      if (!eventParticipants) return;
      
      let hasConnection = false;
      
      // Check each participant
      eventParticipants.forEach(participantId => {
        // Add if participant is not already in our sets
        if (participantId !== personId && 
            !personNodeIds.has(participantId) && 
            !directlyConnectedPersonIds.has(participantId) &&
            !eventConnectedPersonIds.has(participantId)) {
          
          const participantNode = nodeMap.get(participantId);
          if (participantNode && participantNode.type === 'person') {
            eventConnectedPersonIds.add(participantId);
            hasConnection = true;
            
            // Add edges connecting this person to event
            const personEdges = participationEdgesMap.get(participantId);
            if (personEdges) {
              personEdges.forEach(edgeId => additionalEdgeIds.add(edgeId));
            }
          }
        }
      });
      
      // If we found connections through this event, include the event too
      if (hasConnection) {
        connectingEventIds.add(eventId);
        
        // Add edges connecting the original person to this event
        const originalPersonEdges = participationEdgesMap.get(personId);
        if (originalPersonEdges) {
          originalPersonEdges.forEach(edgeId => {
            // Check if edge connects to this specific event
            const edge = edgeMap.get(edgeId);
            if (edge) {
              const targetId = typeof edge.target === 'string' ? edge.target : (edge.target as any)?.id;
              if (targetId === eventId) {
                additionalEdgeIds.add(edgeId);
              }
            }
          });
        }
      }
    });
  });
  
  // 7F: COMBINE ALL ADDITIONAL NODES
  const finalAdditionalNodeIds = new Set([
    ...additionalNodeIds,
    ...directlyConnectedPersonIds,
    ...eventConnectedPersonIds,
    ...connectingEventIds
  ]);
  
  Logger.log(`Found ${directlyConnectedPersonIds.size} additional people via direct communication`);
  Logger.log(`Found ${eventConnectedPersonIds.size} additional people via shared events`);
  Logger.log(`Added ${connectingEventIds.size} connecting calendar events`);
  
  // Build final node and edge sets
  const allIncludedNodeIds = new Set([...initialFilteredNodeIds, ...finalAdditionalNodeIds]);
  const additionalNodes = Array.from(finalAdditionalNodeIds)
    .map(id => nodeMap.get(id))
    .filter(Boolean) as NodeData[];
  
  const additionalEdges = Array.from(additionalEdgeIds)
    .map(id => edgeMap.get(id))
    .filter(Boolean) as EdgeData[];
  
  // STEP 8: RETURN COMBINED RESULTS
  return {
    nodes: [...initialFilteredNodes, ...additionalNodes],
    edges: additionalEdges
  };
}

/**
 * Finds the most likely person nodes matching a given name using fuzzy matching and advanced name comparison techniques
 * Also returns all edges connected to the matched person nodes
 * 
 * @param nodes Array of nodes to search through
 * @param edges Array of edges to search through
 * @param name The name or partial name to search for
 * @param maxResults Optional maximum number of results to return (default: 10)
 * @returns Object containing matched person nodes and their connected edges
 * 
 * @example
 * // Find nodes and edges for "John Smith"
 * const results = filterNodesByPerson(nodes, edges, "John Smith");
 * 
 * @example
 * // Find nodes and edges for partial name "Johnson"
 * const results = filterNodesByPerson(nodes, edges, "Johnson");
 * 
 * @example
 * // Find nodes and edges for "J Smith" (partial first name, full last name)
 * const results = filterNodesByPerson(nodes, edges, "J Smith"); 
 */
export function filterNodesByPerson(
  nodes: NodeData[],
  edges: EdgeData[],
  name: string,
  maxResults: number = 10
): { nodes: NodeData[], edges: EdgeData[] } {
  if (!name || name.trim() === '') {
    return { nodes: [], edges: [] };
  }

  // Normalize and split the search name
  const searchName = name.trim().toLowerCase();
  const searchTokens = searchName.split(/\s+/);
  
  // Determine if search is likely an email address
  const isEmailSearch = searchName.includes('@');
  
  // Calculate score for a node based on how well it matches the search name
  function calculateScore(node: NodeData): number {
    if (node.type !== 'person') {
      return 0;
    }
    
    let score = 0;
    const props = node.properties || {};
    
    // Get all potential name and email fields
    const displayName = props.display_name?.toLowerCase() || '';
    const label = node.label?.toLowerCase() || '';
    const firstName = (props.given_name || props.first_name)?.toLowerCase() || '';
    const lastName = (props.family_name || props.last_name)?.toLowerCase() || '';
    const primaryEmail = props.primary_email_address?.toLowerCase() || '';
    
    // Email-based matching (handles both email searches and email-as-name cases)
    if (isEmailSearch) {
      // Exact email match gets highest score
      if (primaryEmail === searchName || label === searchName) {
        score += 100;
      } 
      // Partial email match
      else if (primaryEmail.includes(searchName) || label.includes(searchName)) {
        score += 75;
      }
      // Domain match (if searching for @company.com)
      else if (searchName.startsWith('@') && 
               (primaryEmail.endsWith(searchName) || label.endsWith(searchName))) {
        score += 80;
      }
      return score;
    }
    
    // Extract name parts from email addresses when proper name fields are missing
    let extractedFirstName = '';
    let extractedLastName = '';
    let emailNameParts: string[] = [];
    
    // Function to extract name parts from an email address
    function extractNameFromEmail(email: string): string[] {
      if (!email || !email.includes('@')) return [];
      
      // Get the local part (before the @)
      const localPart = email.split('@')[0].toLowerCase();
      
      // Split by common separators
      let parts = localPart.split(/[._-]/);
      
      // Filter out very short parts that are likely not name components
      parts = parts.filter(part => part.length > 1);
      
      return parts;
    }
    
    // Try to extract name from email in label or primary_email_address
    if ((!firstName || !lastName) && label.includes('@')) {
      emailNameParts = extractNameFromEmail(label);
    } else if ((!firstName || !lastName) && primaryEmail) {
      emailNameParts = extractNameFromEmail(primaryEmail);
    }
    
    // Use the first two parts as potential first/last name if we don't have better options
    if (emailNameParts.length >= 1 && !firstName) extractedFirstName = emailNameParts[0];
    if (emailNameParts.length >= 2 && !lastName) extractedLastName = emailNameParts[1];
    
    // Consolidated first/last name that uses extracted values as fallback
    const effectiveFirstName = firstName || extractedFirstName;
    const effectiveLastName = lastName || extractedLastName;
    
    // Check for exact full name matches (highest priority)
    if (displayName === searchName || label === searchName) {
      score += 100;
    } 
    
    // Check for exact first/last name matches
    const hasExactFirstName = effectiveFirstName && searchTokens.some(token => token === effectiveFirstName);
    const hasExactLastName = effectiveLastName && searchTokens.some(token => token === effectiveLastName);
    
    if (hasExactFirstName && hasExactLastName) {
      score += 95; // Both names match exactly
    } else if (hasExactFirstName) {
      score += 80; // Just first name matches exactly
    } else if (hasExactLastName) {
      score += 75; // Just last name matches exactly
    }
    
    // Check for partial matches on display name or label
    if (displayName.includes(searchName) || label.includes(searchName)) {
      score += 70;
    }
    
    // Handle partial name matches (e.g., "J" for "John")
    searchTokens.forEach(token => {
      // Check for first initial matches (e.g., "J" matching "John")
      if (token.length === 1 && effectiveFirstName.startsWith(token)) {
        score += 50;
      }
      
      // Check for partial first/last name matches
      if (effectiveFirstName.includes(token) && token.length > 1) {
        score += 40 + (token.length * 2); // Longer matches score higher
      }
      
      if (effectiveLastName.includes(token) && token.length > 1) {
        score += 35 + (token.length * 2);
      }
      
      // Check if token appears anywhere in the email parts
      if (emailNameParts.length > 0) {
        emailNameParts.forEach(part => {
          if (part === token) {
            score += 45; // Exact match of name part in email
          } else if (part.includes(token) && token.length > 1) {
            score += 30; // Partial match of name in email
          }
        });
      }
    });
    
    // Handle middle names or initials if available
    const fullNameParts = displayName ? displayName.split(/\s+/) : [];
    if (fullNameParts.length > 2) {
      for (let i = 1; i < fullNameParts.length - 1; i++) {
        const middlePart = fullNameParts[i];
        searchTokens.forEach(token => {
          if (middlePart === token) {
            score += 30; // Exact middle name match
          } else if (middlePart.length === 1 && token.length === 1 && middlePart === token) {
            score += 25; // Middle initial match
          }
        });
      }
    }
    
    // Enhanced email-to-name matching for cases like "blake@byerscap.com" → "blake byers"
    if (primaryEmail || (label && label.includes('@'))) {
      const emailToCheck = primaryEmail || label;
      const emailUser = emailToCheck.split('@')[0]; // e.g., "blake"
      const emailDomain = emailToCheck.split('@')[1]; // e.g., "byerscap.com"
      
      if (emailUser && emailDomain) {
        // Check if search tokens match email username
        searchTokens.forEach(token => {
          if (emailUser === token) {
            score += 60; // Exact match for email username
          } else if (emailUser.includes(token) && token.length > 1) {
            score += 40; // Partial match for email username
          }
          
          // Check if domain contains the last name or company name
          // Extract domain without TLD (.com, .org, etc.)
          const domainParts = emailDomain.split('.');
          if (domainParts.length >= 2) {
            const domainName = domainParts[0]; // e.g., "byerscap"
            
            // Check for last name or parts of last name in domain
            if (domainName === token) {
              score += 55; // Exact match for domain name
            } else if (domainName.includes(token) && token.length > 2) {
              score += 35; // Partial match for domain name
            }
          }
        });
      }
    }
    
    // If we have multiple search tokens and they match both parts of a name in an email
    if (searchTokens.length >= 2 && emailNameParts.length >= 2) {
      let firstTokenMatched = false;
      let secondTokenMatched = false;
      
      // Check if first search token matches first email part
      if (searchTokens[0] === emailNameParts[0] || emailNameParts[0].includes(searchTokens[0])) {
        firstTokenMatched = true;
      }
      
      // Check if second search token matches second email part
      if (searchTokens[1] === emailNameParts[1] || emailNameParts[1].includes(searchTokens[1])) {
        secondTokenMatched = true;
      }
      
      // If both parts matched, give a bonus
      if (firstTokenMatched && secondTokenMatched) {
        score += 70; // Strong indication this email belongs to the searched person
        Logger.log(`Strong email pattern match: "${searchTokens[0]} ${searchTokens[1]}" matches email parts "${emailNameParts[0]}.${emailNameParts[1]}@..."`);
      }
    }
    
    return score;
  }
  
  // Calculate scores for all person nodes
  const scoredNodes = nodes
    .filter(node => node.type === 'person')
    .map(node => {
      const score = calculateScore(node);
      
      // Log high-scoring matches for debugging
      if (score > 40) {
        const name = node.properties?.display_name || node.label || node.id;
        const email = node.properties?.primary_email_address || 
                     (node.label?.includes('@') ? node.label : '');
        Logger.log(`Match: "${name}" (${email}) scored ${score} for search "${searchName}"`);
      }
      
      return {
        node,
        score
      };
    })
    .filter(item => item.score > 0) // Only keep nodes with a positive score
    .sort((a, b) => b.score - a.score); // Sort by score (highest first)
  
  // Take the top matching nodes
  const matchedNodes = scoredNodes.slice(0, maxResults).map(item => item.node);
  
  // Check if we need to handle domain matching (company/organization search)
  let isDomainSearch = false;
  let searchedDomain = '';
  
  // If search looks like it might be a company/domain name and we have fewer than 5 matches
  if (scoredNodes.length < 5 && searchTokens.length === 1 && searchTokens[0].length > 3) {
    // Check if our top matches have emails with matching domains
    for (const match of scoredNodes.slice(0, 3)) {
      const node = match.node;
      const email = node.properties?.primary_email_address || 
                  (node.label?.includes('@') ? node.label : '');
      
      if (email && email.includes('@')) {
        const domain = email.split('@')[1];
        if (domain && domain.includes(searchTokens[0])) {
          isDomainSearch = true;
          searchedDomain = domain;
          Logger.log(`Detected possible domain/company search for "${searchTokens[0]}" matching domain "${domain}"`);
          break;
        }
      }
    }
    
    // If we detected a domain search, look for more people with the same domain
    if (isDomainSearch && searchedDomain) {
      Logger.log(`Expanding search to find more people with domain "${searchedDomain}"`);
      
      // Find additional nodes with matching domain
      const domainMatches = nodes
        .filter(node => {
          if (node.type !== 'person') return false;
          
          // Skip nodes we already matched
          if (matchedNodes.some(n => n.id === node.id)) return false;
          
          const email = node.properties?.primary_email_address || 
                      (node.label?.includes('@') ? node.label : '');
          
          return email && email.includes('@') && email.endsWith(searchedDomain);
        })
        .slice(0, maxResults - matchedNodes.length); // Only take what we need to fill maxResults
      
      if (domainMatches.length > 0) {
        Logger.log(`Found ${domainMatches.length} additional people with domain "${searchedDomain}"`);
        matchedNodes.push(...domainMatches);
      }
    }
  }
  
  const matchedNodeIds = new Set(matchedNodes.map(node => node.id));
  
  // Create a node map for efficient lookups
  const nodeMap = new Map<string, NodeData>();
  nodes.forEach(node => nodeMap.set(node.id, node));
  
  // Create an edge map for efficient lookups
  const edgeMap = new Map<string, EdgeData>();
  edges.forEach(edge => {
    if (edge.id) {
      edgeMap.set(edge.id, edge);
    }
  });
  
  // Helper to extract node ID from source/target reference
  function getNodeId(nodeRef: string | any): string | null {
    return typeof nodeRef === 'string' ? nodeRef : nodeRef?.id || null;
  }
  
  // Helper function to determine if a node is a user node
  function isUserNode(node: NodeData): boolean {
    if (!node) return false;
    
    // Check for explicit user flag
    if (node.user === true) {
      return true;
    }
    
    // Check for user properties
    if (node.properties) {
      if (
        node.properties.is_primary_user === true || 
        node.properties.primary_user === true ||
        node.properties.is_user === true ||
        node.properties.user === true
      ) {
        return true;
      }
    }
    
    return false;
  }
  
  // Step 1: Find all directly connected person nodes (one-hop connections) and related emails
  const oneHopPersonNodeIds = new Set<string>();
  const oneHopEmailNodeIds = new Set<string>();
  const oneHopEdges = new Set<EdgeData>();
  
  // Process all edges to find one-hop connections
  // Focus specifically on "multi_relation", "communicates_with", and "sent" edge types
  edges.forEach(edge => {
    // Only consider key relationship types for direct person connections
    const isDirectConnectionEdge = edge.type === 'multi_relation' || 
                                  edge.type === 'communicates_with';
    
    // Check if this is a person-to-email "sent" edge
    const isEmailSentEdge = edge.type === 'sent';
    
    const sourceId = getNodeId(edge.source);
    const targetId = getNodeId(edge.target);
    
    if (!sourceId || !targetId) return;
    
    // Check if one end is in our matched set
    const sourceIsMatch = matchedNodeIds.has(sourceId);
    const targetIsMatch = matchedNodeIds.has(targetId);
    
    if (!sourceIsMatch && !targetIsMatch) return; // Skip if neither end is a match
    
    // Get the node objects
    const sourceNode = nodeMap.get(sourceId);
    const targetNode = nodeMap.get(targetId);
    
    if (!sourceNode || !targetNode) return;
    
    // Skip user nodes
    if (isUserNode(sourceNode) || isUserNode(targetNode)) return;
    
    // For direct person connections, focus on multi_relation and communicates_with edges
    if (isDirectConnectionEdge) {
      // If one end is in our matched set and the other is a person, add the person
      if (sourceIsMatch && targetNode.type === 'person' && !matchedNodeIds.has(targetId)) {
        oneHopPersonNodeIds.add(targetId);
        oneHopEdges.add(edge);
        Logger.log(`Found direct '${edge.type}' connection: ${sourceNode.label} → ${targetNode.label}`);
      } 
      else if (targetIsMatch && sourceNode.type === 'person' && !matchedNodeIds.has(sourceId)) {
        oneHopPersonNodeIds.add(sourceId);
        oneHopEdges.add(edge);
        Logger.log(`Found direct '${edge.type}' connection: ${targetNode.label} → ${sourceNode.label}`);
      }
    }
    
    // For "sent" edges where person is the source and email is the target
    if (isEmailSentEdge) {
      // Check if the source is a matched person and target is an email
      if (sourceIsMatch && sourceNode.type === 'person' && targetNode.type === 'email') {
        oneHopEmailNodeIds.add(targetId);
        oneHopEdges.add(edge);
        Logger.log(`Found email sent by person: ${sourceNode.label} → ${targetNode.label || targetId}`);
      }
      // Also handle the case where we're finding emails connected to persons in our one-hop set
      else if (oneHopPersonNodeIds.has(sourceId) && sourceNode.type === 'person' && targetNode.type === 'email') {
        oneHopEmailNodeIds.add(targetId);
        oneHopEdges.add(edge);
        Logger.log(`Found email sent by one-hop person: ${sourceNode.label} → ${targetNode.label || targetId}`);
      }
    }
  });
  
  Logger.log(`Found ${oneHopPersonNodeIds.size} direct one-hop person connections and ${oneHopEmailNodeIds.size} email nodes`);
  
  // Step 2: Find shared calendar event connections (two-hop connections)
  // Build maps of calendar events to participants
  const eventToParticipantsMap = new Map<string, Set<string>>();
  const personToEventsMap = new Map<string, Set<string>>();
  const eventParticipationEdges = new Map<string, Set<EdgeData>>(); // Event-person edges
  
  // First identify all calendar events and their participants
  edges.forEach(edge => {
    // We're only interested in participates_in edges for calendar events
    if (edge.type !== 'participates_in') return;
    
    const sourceId = getNodeId(edge.source);
    const targetId = getNodeId(edge.target);
    
    if (!sourceId || !targetId) return;
    
    const sourceNode = nodeMap.get(sourceId);
    const targetNode = nodeMap.get(targetId);
    
    if (!sourceNode || !targetNode) return;
    
    // For participates_in, source should be person, target should be event
    if (sourceNode.type === 'person' && targetNode.type === 'calendar_event') {
      // Skip user nodes
      if (isUserNode(sourceNode)) return;
      
      // Add to person → events map
      if (!personToEventsMap.has(sourceId)) {
        personToEventsMap.set(sourceId, new Set<string>());
      }
      personToEventsMap.get(sourceId)!.add(targetId);
      
      // Add to event → participants map
      if (!eventToParticipantsMap.has(targetId)) {
        eventToParticipantsMap.set(targetId, new Set<string>());
        eventParticipationEdges.set(targetId, new Set<EdgeData>());
      }
      eventToParticipantsMap.get(targetId)!.add(sourceId);
      eventParticipationEdges.get(targetId)!.add(edge);
      
      // Log for debugging
      if (matchedNodeIds.has(sourceId)) {
        const eventTitle = targetNode.label || targetNode.id;
        Logger.log(`Found matched person ${sourceNode.label} participating in event: ${eventTitle}`);
      }
    }
  });
  
  // For each directly matched person, get their events and then other participants
  const eventConnectedPersonNodeIds = new Set<string>();
  const eventConnectedEventNodeIds = new Set<string>();
  const eventConnectedEdges = new Set<EdgeData>();
  
  // Process directly matched nodes first
  matchedNodeIds.forEach(personId => {
    const personNode = nodeMap.get(personId);
    if (!personNode) return;
    
    // Find all events this person participates in
    const personEvents = personToEventsMap.get(personId);
    if (!personEvents) {
      Logger.log(`No calendar events found for matched person: ${personNode.label}`);
      return;
    }
    
    Logger.log(`Found ${personEvents.size} calendar events for matched person: ${personNode.label}`);
    
    // For each event, find other participants
    personEvents.forEach(eventId => {
      const eventNode = nodeMap.get(eventId);
      if (!eventNode) return;
      
      const eventParticipants = eventToParticipantsMap.get(eventId);
      if (!eventParticipants) return;
      
      // Log event participants for debugging
      Logger.log(`Event ${eventNode.label || eventId} has ${eventParticipants.size} participants`);
      
      let hasNewConnection = false;
      
      // For each participant, check if they're not already in our sets
      eventParticipants.forEach(participantId => {
        // Skip the original person and already included people
        if (participantId === personId || 
            matchedNodeIds.has(participantId) || 
            oneHopPersonNodeIds.has(participantId) ||
            eventConnectedPersonNodeIds.has(participantId)) {
          return;
        }
        
        const participant = nodeMap.get(participantId);
        if (!participant || isUserNode(participant)) return;
        
        eventConnectedPersonNodeIds.add(participantId);
        hasNewConnection = true;
        
        Logger.log(`Found calendar-connected person: ${participant.label} (via event: ${eventNode.label || eventId})`);
      });
      
      // If we found new connections via this event, include the event and all edges
      if (hasNewConnection) {
        eventConnectedEventNodeIds.add(eventId);
        Logger.log(`Including connecting event: ${eventNode.label || eventId}`);
        
        // Add all participation edges for this event
        const eventEdges = eventParticipationEdges.get(eventId);
        if (eventEdges) {
          const edgeCount = eventEdges.size;
          eventEdges.forEach(edge => eventConnectedEdges.add(edge));
          Logger.log(`Added ${edgeCount} participation edges for event: ${eventNode.label || eventId}`);
        }
      }
    });
  });
  
  // Now also process one-hop person connections to find their event connections
  oneHopPersonNodeIds.forEach(personId => {
    const personNode = nodeMap.get(personId);
    if (!personNode) return;
    
    // Find all events this person participates in
    const personEvents = personToEventsMap.get(personId);
    if (!personEvents) {
      Logger.log(`No calendar events found for one-hop person: ${personNode.label}`);
      return;
    }
    
    Logger.log(`Found ${personEvents.size} calendar events for one-hop person: ${personNode.label}`);
    
    // For each event, find other participants
    personEvents.forEach(eventId => {
      const eventNode = nodeMap.get(eventId);
      if (!eventNode) return;
      
      const eventParticipants = eventToParticipantsMap.get(eventId);
      if (!eventParticipants) return;
      
      let hasNewConnection = false;
      
      // For each participant, check if they're not already in our sets
      eventParticipants.forEach(participantId => {
        // Skip already included people
        if (participantId === personId || 
            matchedNodeIds.has(participantId) || 
            oneHopPersonNodeIds.has(participantId) ||
            eventConnectedPersonNodeIds.has(participantId)) {
          return;
        }
        
        const participant = nodeMap.get(participantId);
        if (!participant || isUserNode(participant)) return;
        
        eventConnectedPersonNodeIds.add(participantId);
        hasNewConnection = true;
        
        Logger.log(`Found two-hop calendar-connected person: ${participant.label} (via one-hop person: ${personNode.label}, event: ${eventNode.label || eventId})`);
      });
      
      // If we found new connections via this event, include the event and all edges
      if (hasNewConnection) {
        eventConnectedEventNodeIds.add(eventId);
        Logger.log(`Including two-hop connecting event: ${eventNode.label || eventId} (via one-hop person: ${personNode.label})`);
        
        // Add all participation edges for this event
        const eventEdges = eventParticipationEdges.get(eventId);
        if (eventEdges) {
          const edgeCount = eventEdges.size;
          eventEdges.forEach(edge => eventConnectedEdges.add(edge));
          Logger.log(`Added ${edgeCount} participation edges for two-hop event: ${eventNode.label || eventId}`);
        }
      }
    });
  });
  
  Logger.log(`Found ${eventConnectedPersonNodeIds.size} persons connected via ${eventConnectedEventNodeIds.size} shared calendar events`);
  
  // Combine all the nodes and edges
  const allPersonNodeIds = new Set([
    ...matchedNodeIds,
    ...oneHopPersonNodeIds,
    ...eventConnectedPersonNodeIds
  ]);
  
  const allNodeIds = new Set([
    ...allPersonNodeIds,
    ...eventConnectedEventNodeIds,
    ...oneHopEmailNodeIds
  ]);
  
  // Build the final node and edge sets
  const finalNodes: NodeData[] = [];
  const finalEdges: EdgeData[] = [];
  
  // Add matched nodes first (they were directly matched by name)
  matchedNodes.forEach(node => finalNodes.push(node));
  
  // Add one-hop person connections
  oneHopPersonNodeIds.forEach(nodeId => {
    const node = nodeMap.get(nodeId);
    if (node) finalNodes.push(node);
  });
  
  // Add event-connected people
  eventConnectedPersonNodeIds.forEach(nodeId => {
    const node = nodeMap.get(nodeId);
    if (node) finalNodes.push(node);
  });
  
  // Add connecting calendar events
  eventConnectedEventNodeIds.forEach(nodeId => {
    const node = nodeMap.get(nodeId);
    if (node) finalNodes.push(node);
  });
  
  // Add email nodes
  oneHopEmailNodeIds.forEach(nodeId => {
    const node = nodeMap.get(nodeId);
    if (node) finalNodes.push(node);
  });
  
  // Add all edges among the included nodes
  
  // First add the directly identified edges
  oneHopEdges.forEach(edge => finalEdges.push(edge));
  eventConnectedEdges.forEach(edge => finalEdges.push(edge));
  
  // Then add any remaining edges between the included nodes
  // Particularly focusing on "multi_relation", "communicates_with", and "participates_in" edges
  edges.forEach(edge => {
    const sourceId = getNodeId(edge.source);
    const targetId = getNodeId(edge.target);
    
    if (!sourceId || !targetId) return;
    
    // Only include edges where both endpoints are in our node set
    if (allNodeIds.has(sourceId) && allNodeIds.has(targetId)) {
      // Check if it's already included
      const isAlreadyIncluded = finalEdges.some(e => {
        const eSourceId = getNodeId(e.source);
        const eTargetId = getNodeId(e.target);
        return eSourceId === sourceId && eTargetId === targetId && e.type === edge.type;
      });
      
      if (!isAlreadyIncluded) {
        // Give priority to specific relationship types
        const isKeyEdgeType = edge.type === 'multi_relation' || 
                             edge.type === 'communicates_with' || 
                             edge.type === 'participates_in';
        
        finalEdges.push(edge);
        
        // Log key relationship additions
        if (isKeyEdgeType) {
          const sourceNode = nodeMap.get(sourceId);
          const targetNode = nodeMap.get(targetId);
          
          if (sourceNode && targetNode) {
            Logger.log(`Added additional ${edge.type} edge: ${sourceNode.label} → ${targetNode.label}`);
          }
        }
      }
    }
  });
  
  // Log the results
  Logger.log(`Enhanced person search for "${name}" found:`);
  Logger.log(`- ${matchedNodes.length} direct name matches`);
  Logger.log(`- ${oneHopPersonNodeIds.size} one-hop person connections`);
  Logger.log(`- ${oneHopEmailNodeIds.size} email nodes sent by persons`);
  Logger.log(`- ${eventConnectedPersonNodeIds.size} people connected via calendar events`);
  Logger.log(`- ${eventConnectedEventNodeIds.size} connecting calendar events`);
  Logger.log(`- ${finalEdges.length} total edges connecting all nodes`);
  
  // Return the final enhanced set of nodes and edges
  return {
    nodes: finalNodes,
    edges: finalEdges
  };
}

