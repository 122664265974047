import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { NodeData, EdgeData, AIInsight } from '../types/types';
import { AIService } from '../services/AIService';
import { useMediaQuery } from '../hooks/useMediaQuery';
import Logger from 'util/Logger';

// Utility functions for contact information detection
const isEmailAddress = (value: string): boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
};

const isPhoneNumber = (value: string): boolean => {
  // Match various phone formats
  return /^(\+\d{1,3}[- ]?)?\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(value);
};

const isURL = (value: string): boolean => {
  try {
    // Check if it looks like a URL
    return /^(https?:\/\/|www\.)[^\s]+\.[^\s]{2,}/.test(value);
  } catch {
    return false;
  }
};

// Function to extract email from contact_info object
const extractEmailFromContactInfo = (contactInfo: any): string | null => {
  if (!contactInfo) return null;

  try {
    // If it's a string that looks like JSON, parse it
    if (typeof contactInfo === 'string' && (contactInfo.startsWith('{') || contactInfo.startsWith('['))) {
      try {
        contactInfo = JSON.parse(contactInfo);
      } catch {
        // If parsing fails, continue with the original string
      }
    }

    // Handle object format
    if (typeof contactInfo === 'object') {
      // Look for common email fields
      const emailFields = ['email', 'primary_email', 'work_email', 'personal_email', 'contact_email', 'email_address'];
      for (const field of emailFields) {
        if (contactInfo[field] && isEmailAddress(contactInfo[field])) {
          return contactInfo[field];
        }
      }
      
      // Try to find any field that contains an email
      for (const [key, value] of Object.entries(contactInfo)) {
        if (typeof value === 'string' && isEmailAddress(value)) {
          return value;
        }
      }
    }
    
    // If contactInfo is a string and is an email itself
    if (typeof contactInfo === 'string' && isEmailAddress(contactInfo)) {
      return contactInfo;
    }
    
    return null;
  } catch (error) {
    Logger.error('Error extracting email from contact_info:', error);
    return null;
  }
};

interface DetailPanelProps {
  selectedNode: NodeData | null;
  connectedNodes: NodeData[];
  connectedEdges: EdgeData[];
  onExpandNode: (node: NodeData) => void;
  onHideNode: (nodeId: string) => void;
  onNodeClick?: (node: NodeData) => void;
  aiService: AIService;
  darkMode: boolean;
  community?: string | null;
  communityInsights?: Record<string, AIInsight[]>;
  communityDetails?: {
    names: Record<string, string>;
    nodeCounts: Record<string, number>;
    communityMap: Record<string, number>;
  };
  expanded?: boolean;
  onToggleExpand?: () => void;
}

const Panel = styled.div<{ $darkMode: boolean; $expanded?: boolean; $isMobile?: boolean }>`
  background-color: ${props => props.$darkMode ? '#161B28' : '#FFFFFF'};
  color: ${props => props.$darkMode ? '#F8FAFC' : '#0F172A'};
  border-radius: 16px;
  box-shadow: ${props => props.$darkMode ? 
    '0 8px 16px rgba(0, 0, 0, 0.4), 0 0 0 1px rgba(255, 255, 255, 0.05)' : 
    '0 8px 16px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.05)'};
  padding: ${props => props.$isMobile && !props.$expanded ? '12px' : '24px'};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, Inter, sans-serif;
  letter-spacing: 0.01em;
  position: relative;
  z-index: 100;
  
  @media (max-width: 768px) {
    ${props => props.$expanded ? `
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      z-index: 1000;
      padding: 16px;
      padding-bottom: 80px; /* Space for the floating hide button */
      overflow-y: auto;
    ` : `
      max-height: auto;
      min-height: auto;
      height: auto;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: calc(100% - 24px); /* Account for margins */
      margin: 0 12px;
      position: absolute;
      bottom: 12px;
      left: 0;
      right: 0;
    `}
  }
`;

const EmptyState = styled.div<{ $darkMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 32px;
  opacity: 0.8;
  animation: fadeIn 0.5s ease-in-out;
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 0.8; transform: translateY(0); }
  }
  
  svg {
    margin-bottom: 20px;
    color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
    width: 48px;
    height: 48px;
    opacity: 0.6;
  }
  
  p {
    color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
    font-size: 15px;
    line-height: 1.5;
    max-width: 80%;
    text-align: center;
  }
`;

const Header = styled.div`
  margin-bottom: 24px;
`;

const EntityIcon = styled.div<{ type: string; $darkMode: boolean }>`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
  background-color: ${props => getNodeColor(props.type, props.$darkMode)};
  color: white;
  box-shadow: ${props => props.$darkMode ? 
    '0 8px 16px rgba(0, 0, 0, 0.4), 0 0 0 1px rgba(255, 255, 255, 0.1)' : 
    '0 8px 16px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.03)'};
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  animation: fadeInScaleUp 0.4s ease-out;
  
  @keyframes fadeInScaleUp {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
  }
  
  &:hover {
    transform: translateY(-2px) scale(1.05);
    box-shadow: ${props => props.$darkMode ? 
      '0 12px 20px rgba(0, 0, 0, 0.5), 0 0 0 1px rgba(255, 255, 255, 0.15)' : 
      '0 12px 20px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.05)'};
  }
  
  svg {
    width: 28px;
    height: 28px;
  }
`;

const EntityTitle = styled.h2<{ $darkMode: boolean }>`
  font-size: 28px;
  font-weight: 700;
  margin: 0 0 6px 0;
  color: ${props => props.$darkMode ? '#F8FAFC' : '#0F172A'};
  cursor: default;
  letter-spacing: -0.02em;
  text-shadow: ${props => props.$darkMode ? '0 1px 2px rgba(0, 0, 0, 0.3)' : 'none'};
  animation: fadeIn 0.5s ease-out;
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(5px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;

const EntityType = styled.div<{ $darkMode: boolean }>`
  font-size: 15px;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  text-transform: capitalize;
  margin-bottom: 16px;
  display: inline-flex;
  align-items: center;
  background-color: ${props => props.$darkMode ? 'rgba(148, 163, 184, 0.1)' : 'rgba(100, 116, 139, 0.08)'};
  padding: 4px 10px;
  border-radius: 6px;
  font-weight: 500;
  animation: fadeIn 0.6s ease-out;
  
  svg {
    margin-right: 6px;
    width: 14px;
    height: 14px;
    opacity: 0.7;
  }
`;

const Section = styled.div`
  margin-bottom: 32px;
  animation: fadeInUp 0.5s ease-out forwards;
  opacity: 0;
  
  @keyframes fadeInUp {
    from { 
      opacity: 0;
      transform: translateY(10px);
    }
    to { 
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  &:nth-child(2) { animation-delay: 0.1s; }
  &:nth-child(3) { animation-delay: 0.2s; }
  &:nth-child(4) { animation-delay: 0.3s; }
  &:nth-child(5) { animation-delay: 0.4s; }
`;

const SectionTitle = styled.h3<{ $darkMode: boolean }>`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${props => props.$darkMode ? '#E2E8F0' : '#334155'};
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid ${props => props.$darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.08)'};
  letter-spacing: 0.01em;
  
  svg {
    margin-right: 10px;
    color: ${props => props.$darkMode ? '#60A5FA' : '#3B82F6'};
    opacity: 0.9;
  }
`;

const PropertyList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 12px;
  animation: fadeIn 0.3s ease-out;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Property = styled.div`
  overflow: hidden;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)'};
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0, 0, 0, 0.04)'};
  }
`;

const PropertyLabel = styled.div<{ $darkMode: boolean }>`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  margin-bottom: 4px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
`;

const PropertyValue = styled.div<{ 
  $darkMode: boolean; 
  $clickable?: boolean;
  $isEmail?: boolean;
  $isPhone?: boolean;
  $isUrl?: boolean;
  $isContactInfo?: boolean;
}>`
  font-size: 14px;
  color: ${props => {
    if (props.$isEmail) return props.$darkMode ? '#60A5FA' : '#3B82F6';
    if (props.$isPhone) return props.$darkMode ? '#34D399' : '#10B981';
    if (props.$isUrl) return props.$darkMode ? '#F59E0B' : '#F59E0B';
    return props.$darkMode ? '#F1F5F9' : '#1E293B';
  }};
  font-weight: ${props => props.$isEmail || props.$isPhone || props.$isUrl || props.$isContactInfo ? '500' : 'normal'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${props => props.$clickable ? 'pointer' : 'default'};
  transition: all 0.2s ease;
  
  &:hover {
    color: ${props => {
      if (props.$isEmail) return props.$darkMode ? '#93C5FD' : '#2563EB';
      if (props.$isPhone) return props.$darkMode ? '#6EE7B7' : '#059669';
      if (props.$isUrl) return props.$darkMode ? '#FBBF24' : '#D97706';
      if (props.$clickable) return props.$darkMode ? '#CBD5E1' : '#0F172A';
      return props.$darkMode ? '#F1F5F9' : '#1E293B';
    }};
  }
  transition: all 0.2s ease;
  
  ${props => props.$clickable && `
    cursor: pointer;
    
    &:hover {
      color: ${props.$darkMode ? '#60A5FA' : '#3B82F6'};
    }
  `}
  
  /* Email styling */
  ${props => props.$isEmail && `
    color: ${props.$darkMode ? '#64D2FF' : '#0A84FF'};
    display: inline-flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
      opacity: 0.9;
    }
    
    &:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%230A84FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z'%3E%3C/path%3E%3Cpolyline points='22,6 12,13 2,6'%3E%3C/polyline%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
    }
  `}
  
  /* Phone styling */
  ${props => props.$isPhone && `
    color: ${props.$darkMode ? '#32D74B' : '#34C759'};
    display: inline-flex;
    align-items: center;
    gap: 4px;
    
    &:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%2334C759' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72c.127.96.362 1.903.7 2.81a2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45c.907.339 1.85.574 2.81.7A2 2 0 0 1 22 16.92z'%3E%3C/path%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
    }
  `}
  
  /* URL styling */
  ${props => props.$isUrl && `
    color: ${props.$darkMode ? '#BF5AF2' : '#AF52DE'};
    display: inline-flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
      opacity: 0.9;
    }
    
    &:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23AF52DE' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71'%3E%3C/path%3E%3Cpath d='M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71'%3E%3C/path%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
    }
  `}
  
  /* Contact info styling */
  ${props => props.$isContactInfo && `
    background-color: ${props.$darkMode ? '#1A2234' : '#F8FAFC'};
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid ${props.$darkMode ? '#2D3748' : '#E2E8F0'};
    display: inline-block;
    max-width: 100%;
  `}
`;

const ConnectionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ConnectionItem = styled.div<{ $darkMode: boolean }>`
  padding: 10px;
  border-radius: 8px;
  background-color: ${props => props.$darkMode ? '#334155' : '#F1F5F9'};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ConnectionInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-width: 0;
`;

const ConnectionDot = styled.div<{ type: string; $darkMode: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => getNodeColor(props.type, props.$darkMode)};
  flex-shrink: 0;
`;

const ConnectionLabel = styled.div<{ $darkMode: boolean; $clickable?: boolean }>`
  font-size: 14px;
  color: ${props => props.$darkMode ? '#F1F5F9' : '#1E293B'};
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.2s ease;
  
  ${props => props.$clickable && `
    &:hover {
      color: ${props.$darkMode ? '#60A5FA' : '#3B82F6'};
      text-decoration: none;
    }
  `}
`;

const ConnectionType = styled.div<{ $darkMode: boolean }>`
  font-size: 12px;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  margin-left: 4px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 6px;
`;

const ActionButton = styled.button<{ $darkMode: boolean; variant?: 'primary' | 'secondary' | 'danger' }>`
  border: none;
  background: none;
  width: 28px;
  height: 28px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  
  background-color: ${props => {
    if (props.variant === 'primary') return props.$darkMode ? '#3B82F6' : '#2563EB';
    if (props.variant === 'danger') return props.$darkMode ? '#EF4444' : '#DC2626';
    return props.$darkMode ? '#475569' : '#E2E8F0';
  }};
  
  color: ${props => {
    if (props.variant === 'primary' || props.variant === 'danger') return '#FFFFFF';
    return props.$darkMode ? '#CBD5E1' : '#334155';
  }};
  
  &:hover {
    background-color: ${props => {
      if (props.variant === 'primary') return props.$darkMode ? '#2563EB' : '#1D4ED8';
      if (props.variant === 'danger') return props.$darkMode ? '#DC2626' : '#B91C1C';
      return props.$darkMode ? '#64748B' : '#CBD5E1';
    }};
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
  }
  
  svg {
    width: 16px;
    height: 16px;
  }
`;

const ExpandButton = styled.button<{ $darkMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  color: ${props => props.$darkMode ? '#CBD5E1' : '#334155'};
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${props => props.$darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
  }
  
  svg {
    width: 24px;
    height: 24px;
  }
  
  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileDetailHeader = styled.div<{ $darkMode: boolean }>`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.$darkMode ? '#161B28' : '#FFFFFF'};
  padding: 16px 0;
  margin: -16px 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  border-bottom: 1px solid ${props => props.$darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'};

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }

  button {
    background: none;
    border: none;
    padding: 8px;
    color: ${props => props.$darkMode ? '#CBD5E1' : '#334155'};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: ${props => props.$darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const FloatingHideButton = styled.button<{ $darkMode: boolean }>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${props => props.$darkMode ? '#3B82F6' : '#2563EB'};
  color: white;
  border: none;
  border-radius: 30px;
  padding: 16px 24px;
  font-weight: 600;
  font-size: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
  z-index: 1001;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${props => props.$darkMode ? '#2563EB' : '#1D4ED8'};
    transform: translateX(-50%) translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
  
  &:active {
    transform: translateX(-50%) translateY(0);
  }
  
  svg {
    width: 20px;
    height: 20px;
  }
`;

const CollapsedDetailIcon = styled.div<{ $darkMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  svg {
    width: 28px;
    height: 28px;
    color: ${props => props.$darkMode ? '#60A5FA' : '#3B82F6'};
    margin-bottom: 4px;
  }
  
  span {
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  }
`;

const InsightCard = styled.div<{ $darkMode: boolean; insightType: string }>`
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  background-color: ${props => props.$darkMode ? '#334155' : '#F1F5F9'};
  border-left: 4px solid ${props => getInsightColor(props.insightType, props.$darkMode)};
`;

const InsightTitle = styled.h4<{ $darkMode: boolean }>`
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 8px 0;
  color: ${props => props.$darkMode ? '#F1F5F9' : '#0F172A'};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const InsightDescription = styled.p<{ $darkMode: boolean }>`
  font-size: 14px;
  color: ${props => props.$darkMode ? '#CBD5E1' : '#334155'};
  margin: 0;
  line-height: 1.5;
`;

const InsightMeta = styled.div<{ $darkMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 12px;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
`;

const InsightTag = styled.span<{ $darkMode: boolean }>`
  background-color: ${props => props.$darkMode ? '#1E293B' : '#E2E8F0'};
  padding: 2px 6px;
  border-radius: 4px;
`;

const LoadingSpinner = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;
  margin-right: 8px;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

// Helper function to determine node color based on type
function getNodeColor(type: string, darkMode: boolean): string {
  const colors = {
    person: darkMode ? '#6366F1' : '#4F46E5', // Indigo
    email: darkMode ? '#F59E0B' : '#D97706',  // Amber
    event: darkMode ? '#10B981' : '#059669',  // Emerald
    flight: darkMode ? '#3B82F6' : '#2563EB', // Blue
    hotel: darkMode ? '#EC4899' : '#DB2777',  // Pink
    reservation: darkMode ? '#8B5CF6' : '#7C3AED', // Violet
  };
  
  return colors[type as keyof typeof colors] || (darkMode ? '#94A3B8' : '#64748B'); // Default: Slate
}

// Helper function to get insight color
function getInsightColor(type: string, darkMode: boolean): string {
  const colors = {
    pattern: darkMode ? '#10B981' : '#059669',  // Emerald
    anomaly: darkMode ? '#F59E0B' : '#D97706',  // Amber
    suggestion: darkMode ? '#3B82F6' : '#2563EB', // Blue
    summary: darkMode ? '#8B5CF6' : '#7C3AED', // Violet
  };
  
  return colors[type as keyof typeof colors] || (darkMode ? '#94A3B8' : '#64748B'); // Default: Slate
}

export const DetailPanel: React.FC<DetailPanelProps> = ({
  selectedNode,
  connectedNodes,
  connectedEdges,
  onExpandNode,
  onHideNode,
  onNodeClick,
  aiService,
  darkMode,
  community,
  communityInsights,
  communityDetails,
  expanded = false,
  onToggleExpand,
}) => {
  // Detect mobile screen size
  const isMobile = useMediaQuery('(max-width: 768px)');
  const panelRef = useRef<HTMLDivElement>(null);
  
  // Track the currently active filter so we can toggle it
  const [activeEmailFilter, setActiveEmailFilter] = useState<string | null>(null);
  const [isEnriching, setIsEnriching] = useState(false);
  const [insights, setInsights] = useState<AIInsight[]>([]);
  const [isLoadingInsights, setIsLoadingInsights] = useState(false);
  
  // Scroll to top when panel expands on mobile
  useEffect(() => {
    if (isMobile && expanded && panelRef.current) {
      panelRef.current.scrollTop = 0;
    }
  }, [isMobile, expanded]);
  
  // Prevent body scrolling when panel is expanded on mobile
  useEffect(() => {
    if (isMobile && expanded) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMobile, expanded]);
  
  // Handler to filter the graph based on an email address
  const handleEmailFilter = useCallback((email: string) => {
    // Toggle the filter if it's already active
    const isAlreadyFiltered = activeEmailFilter === email;
    
    // Create a custom event that our App component can listen for
    const filterEvent = new CustomEvent('filter:byEmail', { 
      detail: { 
        email,
        // When already filtered, second click removes filter
        removeFilter: isAlreadyFiltered
      } 
    });
    window.dispatchEvent(filterEvent);
    
    // Update active filter state
    setActiveEmailFilter(isAlreadyFiltered ? null : email);
    
    // Log action but don't show popup
    Logger.log(isAlreadyFiltered 
      ? `Removing filter for email: ${email}` 
      : `Filtering graph to show nodes connected to email: ${email}`
    );
  }, [activeEmailFilter]);
  
  // Request AI insights when node is selected
  useEffect(() => {
    if (!selectedNode || !aiService) return;
    
    const fetchInsights = async () => {
      setIsLoadingInsights(true);
      try {
        // Create a subgraph with the selected node and its connections
        const subgraphNodes = [selectedNode, ...connectedNodes];
        const subgraphEdges = [...connectedEdges];
        
        // Get insights from AI
        const newInsights = await aiService.generateInsights(subgraphNodes, subgraphEdges);
        setInsights(newInsights);
      } catch (error) {
        Logger.error('Error generating insights:', error);
      } finally {
        setIsLoadingInsights(false);
      }
    };
    
    fetchInsights();
  }, [selectedNode, connectedNodes, connectedEdges, aiService]);
  
  // If no node is selected, show empty state
  if (!selectedNode) {
    return (
      <Panel 
        $darkMode={darkMode} 
        $expanded={expanded} 
        $isMobile={isMobile}
        ref={panelRef}
      >
        <EmptyState $darkMode={darkMode}>
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="12"></line>
            <line x1="12" y1="16" x2="12" y2="16"></line>
          </svg>
          <p>Select a node to view details</p>
        </EmptyState>
      </Panel>
    );
  }
  
  // For mobile collapsed state, show a compact version with the entity name and type
  if (isMobile && !expanded && selectedNode) {
    return (
      <Panel 
        $darkMode={darkMode} 
        $expanded={expanded} 
        $isMobile={isMobile} 
        onClick={onToggleExpand}
        style={{ cursor: 'pointer', padding: '12px', maxHeight: 'none' }}
      >
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          width: '100%'
        }}>
          <div style={{ 
            display: 'flex', 
            alignItems: 'center',
            overflow: 'hidden',
            flex: 1
          }}>
            <EntityIcon type={selectedNode.type} $darkMode={darkMode} style={{ 
              width: '36px', 
              height: '36px', 
              marginBottom: 0, 
              marginRight: '12px',
              flexShrink: 0
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
            </EntityIcon>
            <div style={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
              <div style={{ 
                fontWeight: 600, 
                fontSize: '14px',
                color: darkMode ? '#F8FAFC' : '#0F172A',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}>
                {selectedNode.label}
              </div>
              <div style={{ 
                fontSize: '12px',
                color: darkMode ? '#94A3B8' : '#64748B',
                textTransform: 'capitalize'
              }}>
                {selectedNode.type}
              </div>
            </div>
          </div>
          
          <ExpandButton $darkMode={darkMode} onClick={onToggleExpand} style={{ display: 'flex' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polyline points="15 3 21 3 21 9"></polyline>
              <polyline points="9 21 3 21 3 15"></polyline>
              <line x1="21" y1="3" x2="14" y2="10"></line>
              <line x1="3" y1="21" x2="10" y2="14"></line>
            </svg>
          </ExpandButton>
        </div>
      </Panel>
    );
  }
  
  // For mobile collapsed state with no selected node
  if (isMobile && !expanded && !selectedNode) {
    return (
      <Panel 
        $darkMode={darkMode} 
        $expanded={expanded} 
        $isMobile={isMobile} 
        style={{ padding: '12px', maxHeight: 'none' }}
      >
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          width: '100%',
          color: darkMode ? '#94A3B8' : '#64748B',
          fontSize: '13px'
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" style={{ marginRight: '8px' }}>
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="12"></line>
            <line x1="12" y1="16" x2="12" y2="16"></line>
          </svg>
          Select a node to view details
        </div>
      </Panel>
    );
  }
  
  // Handle enrichment button click
  const handleEnrichNode = async () => {
    if (!selectedNode || isEnriching) return;
    
    setIsEnriching(true);
    try {
      // Enrich the node with AI
      const enrichedNode = await aiService.enrichNode(selectedNode);
      // This would update the node in the parent component
      Logger.log('Enriched node:', enrichedNode);
    } catch (error) {
      Logger.error('Error enriching node:', error);
    } finally {
      setIsEnriching(false);
    }
  };
  
  // Filter and organize properties to display (exclude internal or system properties)
  const organizeProperties = () => {
    const rawProperties = Object.entries(selectedNode.properties || {}).filter(
      ([key]) => !['x', 'y', 'vx', 'vy', 'index', 'id', 'expanded'].includes(key)
    );
    
    // Organize properties by category
    const priorityProps: [string, any][] = [];
    const contactProps: [string, any][] = [];
    const timeProps: [string, any][] = [];
    const detailProps: [string, any][] = [];
    const otherProps: [string, any][] = [];
    
    // Node type-specific organization
    rawProperties.forEach(prop => {
      const [key, value] = prop;
      
      // Priority properties based on node type
      if (selectedNode.type === 'person') {
        if (['display_name', 'given_name', 'family_name', 'middle_name', 'job_title', 'organization'].includes(key)) {
          priorityProps.push(prop);
          return;
        }
        if (['primary_email_address', 'other_email_addresses', 'phone_numbers'].includes(key)) {
          contactProps.push(prop);
          return;
        }
        if (['first_seen_at', 'last_seen_at', 'last_activity'].includes(key)) {
          timeProps.push(prop);
          return;
        }
        if (['facts', 'is_user'].includes(key)) {
          detailProps.push(prop);
          return;
        }
      } else if (selectedNode.type === 'flight') {
        if (['record_locator', 'airline', 'flight_number', 'origin', 'destination', 'price', 'currency'].includes(key)) {
          priorityProps.push(prop);
          return;
        }
        if (['departure_datetime', 'arrival_datetime', 'created_at'].includes(key)) {
          timeProps.push(prop);
          return;
        }
        if (['legs', 'passenger_names', 'seats', 'leg_count'].includes(key)) {
          detailProps.push(prop);
          return;
        }
      } else if (selectedNode.type === 'hotel_stay') {
        if (['lodging_name', 'reservation_code', 'lodging_address', 'price', 'currency'].includes(key)) {
          priorityProps.push(prop);
          return;
        }
        if (['checkin_datetime', 'checkout_datetime', 'created_at'].includes(key)) {
          timeProps.push(prop);
          return;
        }
        if (['beds', 'bathrooms', 'info_type'].includes(key)) {
          detailProps.push(prop);
          return;
        }
        if (['lodging_phone_number'].includes(key)) {
          contactProps.push(prop);
          return;
        }
      } else if (selectedNode.type === 'dining') {
        if (['name', 'cuisine', 'party_size', 'address', 'price_tier'].includes(key)) {
          priorityProps.push(prop);
          return;
        }
        if (['start_datetime', 'end_datetime', 'created_at'].includes(key)) {
          timeProps.push(prop);
          return;
        }
        if (['rating', 'reservation_code', 'info_type'].includes(key)) {
          detailProps.push(prop);
          return;
        }
        if (['phone_number'].includes(key)) {
          contactProps.push(prop);
          return;
        }
      } else if (selectedNode.type === 'email') {
        if (['sender', 'subject', 'recipients'].includes(key)) {
          priorityProps.push(prop);
          return;
        }
        if (['created_at', 'sent_at', 'received_at'].includes(key)) {
          timeProps.push(prop);
          return;
        }
      }
      
      // General categories for all node types
      if (key.includes('email') || key.includes('phone') || key.includes('contact')) {
        contactProps.push(prop);
      } else if (key.includes('time') || key.includes('date') || key.includes('created') || key.includes('updated')) {
        timeProps.push(prop);
      } else {
        otherProps.push(prop);
      }
    });
    
    // Return organized properties with their section titles
    return [
      { title: "Primary Information", props: priorityProps },
      { title: "Contact Details", props: contactProps },
      { title: "Dates & Times", props: timeProps },
      { title: "Additional Details", props: detailProps },
      { title: "Other Properties", props: otherProps }
    ].filter(section => section.props.length > 0);
  };
  
  const organizedProperties = organizeProperties();
  
  return (
    <Panel 
      $darkMode={darkMode} 
      $expanded={expanded} 
      $isMobile={isMobile}
      ref={panelRef}
    >
      {/* Mobile header for expanded view */}
      {isMobile && expanded && (
        <MobileDetailHeader $darkMode={darkMode}>
          <h2>{selectedNode.label}</h2>
          <button onClick={onToggleExpand}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </MobileDetailHeader>
      )}
      
      {/* Main Content */}
      {(!isMobile || !expanded) && (
        <Header>
          <EntityIcon type={selectedNode.type} $darkMode={darkMode}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
          </EntityIcon>
          <EntityTitle $darkMode={darkMode}>{selectedNode.label}</EntityTitle>
          <EntityType $darkMode={darkMode}>{selectedNode.type}</EntityType>
        </Header>
      )}
      
      {/* Community Section - New */}
      {community && (
        <Section>
          <SectionTitle $darkMode={darkMode}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
            </svg>
            Community: {community}
          </SectionTitle>
        </Section>
      )}
      
      {/* Node metrics section - New */}
      {(selectedNode.importance || selectedNode.centrality) && (
        <Section>
          <SectionTitle $darkMode={darkMode}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
            </svg>
            Metrics
          </SectionTitle>
          <PropertyList>
            {selectedNode.importance && (
              <Property>
                <PropertyLabel $darkMode={darkMode}>Importance</PropertyLabel>
                <PropertyValue $darkMode={darkMode}>
                  {selectedNode.importance.toFixed(1)}
                </PropertyValue>
              </Property>
            )}
            {selectedNode.centrality?.degree && (
              <Property>
                <PropertyLabel $darkMode={darkMode}>Connection Strength</PropertyLabel>
                <PropertyValue $darkMode={darkMode}>
                  {Math.round(selectedNode.centrality.degree * 100)}%
                </PropertyValue>
              </Property>
            )}
            {selectedNode.centrality?.eigenvector && (
              <Property>
                <PropertyLabel $darkMode={darkMode}>Influence</PropertyLabel>
                <PropertyValue $darkMode={darkMode}>
                  {Math.round(selectedNode.centrality.eigenvector * 100)}%
                </PropertyValue>
              </Property>
            )}
          </PropertyList>
        </Section>
      )}
      
      {/* Organized property sections */}
      {organizedProperties.length > 0 && organizedProperties.map((section, index) => (
        <Section key={index}>
          <SectionTitle $darkMode={darkMode}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
              <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
              <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
              <line x1="12" y1="22.08" x2="12" y2="12"></line>
            </svg>
            {section.title}
          </SectionTitle>
          <PropertyList>
            {section.props.map(([key, value]) => {
              let displayValue = value;
              let hasSpecialFormatting = false;
              
              // Handle arrays for better display
              if (Array.isArray(value) && value.length > 0) {
                if (typeof value[0] === 'string') {
                  displayValue = value.join(', ');
                } else if (typeof value[0] === 'object') {
                  // Keep as is to be handled below
                }
              }
              
              // Detect contact_info object and extract email if present
              if (key === 'contact_info' && value) {
                const email = extractEmailFromContactInfo(value);
                if (email) {
                  displayValue = email;
                  hasSpecialFormatting = true;
                }
              }
              
              // Format dates nicely if we detect a date string
              if (typeof value === 'string' && 
                 (value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/) || 
                  key.includes('date') || key.includes('time') || key.includes('created_at') || key.includes('updated_at'))) {
                try {
                  const date = new Date(value);
                  if (!isNaN(date.getTime())) {
                    displayValue = date.toLocaleString();
                  }
                } catch (e) {
                  // Keep original value if date parsing fails
                }
              }
              
              // Convert to string for further processing
              const valueAsString = typeof displayValue === 'object' 
                ? JSON.stringify(displayValue) 
                : String(displayValue);
              
              // Detect various types of data
              const isEmail = isEmailAddress(valueAsString);
              const isPhone = isPhoneNumber(valueAsString);
              const isWebUrl = isURL(valueAsString);
              
              // Determine if this property might reference another entity
              const isPersonReference = typeof value === 'string' && 
                (key === 'relationship' || key === 'company' || key === 'contact' || key === 'manager' || key === 'author');
                
              // Look for a node with a matching label for clickable properties
              const matchingNode = isPersonReference ? 
                connectedNodes.find(node => node.label.toLowerCase() === String(value).toLowerCase()) : null;
              
              return (
                <Property key={key}>
                  <PropertyLabel $darkMode={darkMode}>{key.replace(/_/g, ' ')}</PropertyLabel>
                  
                  {/* Person reference */}
                  {matchingNode ? (
                    <PropertyValue 
                      $darkMode={darkMode} 
                      $clickable={true}
                      onClick={() => onNodeClick && onNodeClick(matchingNode)}
                    >
                      {String(value)}
                    </PropertyValue>
                  ) : isEmail || (hasSpecialFormatting && isEmailAddress(valueAsString)) ? (
                    /* Email address */
                    <PropertyValue 
                      $darkMode={darkMode} 
                      $isEmail={true}
                      onClick={() => handleEmailFilter(valueAsString)}
                      title={activeEmailFilter === valueAsString 
                        ? "Click again to remove filter" 
                        : "Click to filter graph by this email"
                      }
                      style={{
                        backgroundColor: activeEmailFilter === valueAsString 
                          ? (darkMode ? 'rgba(10, 132, 255, 0.2)' : 'rgba(10, 132, 255, 0.1)') 
                          : 'transparent',
                        borderRadius: activeEmailFilter === valueAsString ? '4px' : '0',
                        padding: activeEmailFilter === valueAsString ? '2px 6px' : '0'
                      }}
                    >
                      {valueAsString}
                      {activeEmailFilter === valueAsString && (
                        <span style={{ 
                          marginLeft: '6px', 
                          fontSize: '10px',
                          opacity: 0.8
                        }}>
                          (filtered)
                        </span>
                      )}
                    </PropertyValue>
                  ) : isPhone ? (
                    /* Phone number */
                    <PropertyValue 
                      $darkMode={darkMode} 
                      $isPhone={true}
                    >
                      {valueAsString}
                    </PropertyValue>
                  ) : isWebUrl ? (
                    /* Website URL */
                    <PropertyValue 
                      $darkMode={darkMode} 
                      $isUrl={true}
                      onClick={() => window.open(valueAsString.startsWith('http') ? valueAsString : `https://${valueAsString}`, '_blank')}
                    >
                      {valueAsString}
                    </PropertyValue>
                  ) : key === 'contact_info' ? (
                    /* Contact info object */
                    <PropertyValue 
                      $darkMode={darkMode}
                      $isContactInfo={true}
                    >
                      {typeof value === 'object' ? JSON.stringify(value, null, 2) : String(value)}
                    </PropertyValue>
                  ) : key === 'facts' && Array.isArray(value) ? (
                    /* Facts array as tags */
                    <PropertyValue $darkMode={darkMode}>
                      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                        {value.map((fact, i) => (
                          <span key={i} style={{
                            backgroundColor: darkMode ? '#334155' : '#F1F5F9',
                            color: darkMode ? '#F1F5F9' : '#1E293B',
                            padding: '2px 6px',
                            borderRadius: '4px',
                            fontSize: '12px'
                          }}>
                            {fact}
                          </span>
                        ))}
                      </div>
                    </PropertyValue>
                  ) : key === 'legs' && Array.isArray(value) ? (
                    /* Flight legs */
                    <PropertyValue $darkMode={darkMode}>
                      <div style={{ fontSize: '13px' }}>
                        {value.map((leg, i) => (
                          <div key={i} style={{
                            backgroundColor: darkMode ? '#1A2234' : '#F8FAFC',
                            padding: '6px',
                            borderRadius: '4px',
                            marginBottom: i < value.length - 1 ? '4px' : 0,
                            border: `1px solid ${darkMode ? '#2D3748' : '#E2E8F0'}`
                          }}>
                            <div><strong>{leg.airline || 'Flight'} {leg.flight_number}</strong></div>
                            <div>{leg.departure_airport} → {leg.arrival_airport}</div>
                            {leg.departure_datetime && leg.arrival_datetime && (
                              <div style={{ fontSize: '12px', opacity: 0.8 }}>
                                {new Date(leg.departure_datetime).toLocaleString()} - {new Date(leg.arrival_datetime).toLocaleString()}
                              </div>
                            )}
                            {leg.seats && <div style={{ fontSize: '12px' }}>Seat: {leg.seats}</div>}
                          </div>
                        ))}
                      </div>
                    </PropertyValue>
                  ) : (
                    /* Default value display */
                    <PropertyValue $darkMode={darkMode}>
                      {typeof value === 'object' ? JSON.stringify(value) : String(value)}
                    </PropertyValue>
                  )}
                </Property>
              );
            })}
          </PropertyList>
        </Section>
      ))}
      
      {/* Enrich button */}
      <div style={{ marginTop: '12px', marginBottom: '24px' }}>
        <ActionButton 
          $darkMode={darkMode} 
          variant="primary"
          onClick={handleEnrichNode}
          disabled={isEnriching}
          style={{ width: 'auto', padding: '6px 12px', display: 'flex', gap: '6px' }}
        >
          {isEnriching ? (
            <>
              <LoadingSpinner />
              Enriching...
            </>
          ) : (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M9.9,4.24A8.08,8.08,0,0,1,12,4a8,8,0,0,1,0,16,7.77,7.77,0,0,1-2.3-.34"></path>
                <path d="M14.83,9l-2.83,2.83L10.17,10"></path>
                <path d="M4.93,19.07A10,10,0,0,1,2,12A10,10,0,0,1,12,2a10,10,0,0,1,4.84,1.25"></path>
              </svg>
              Enrich with Adapter
            </>
          )}
        </ActionButton>
      </div>
      
      {connectedNodes.length > 0 && (
        <Section>
          <SectionTitle $darkMode={darkMode}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
            </svg>
            Connections ({connectedNodes.length})
          </SectionTitle>
          <ConnectionList>
            {connectedNodes.map(node => {
              const edge = connectedEdges.find(
                e => (e.source === selectedNode.id && e.target === node.id) || 
                     (e.source === node.id && e.target === selectedNode.id)
              );
              
              return (
                <ConnectionItem key={node.id} $darkMode={darkMode}>
                  <ConnectionInfo onClick={() => onNodeClick && onNodeClick(node)} style={{ cursor: 'pointer' }}>
                    <ConnectionDot type={node.type} $darkMode={darkMode} />
                    <ConnectionLabel $darkMode={darkMode} $clickable={true}>{node.label}</ConnectionLabel>
                    {edge && (
                      <ConnectionType $darkMode={darkMode}>
                        ({edge.type})
                      </ConnectionType>
                    )}
                  </ConnectionInfo>
                  <ButtonGroup>
                    <ActionButton 
                      $darkMode={darkMode} 
                      onClick={() => onExpandNode(node)}
                      title="Expand"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        <line x1="11" y1="8" x2="11" y2="14"></line>
                        <line x1="8" y1="11" x2="14" y2="11"></line>
                      </svg>
                    </ActionButton>
                    <ActionButton 
                      $darkMode={darkMode}
                      variant="danger"
                      onClick={() => onHideNode(node.id)}
                      title="Hide"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                        <line x1="1" y1="1" x2="23" y2="23"></line>
                      </svg>
                    </ActionButton>
                  </ButtonGroup>
                </ConnectionItem>
              );
            })}
          </ConnectionList>
        </Section>
      )}
      
      {/* Node-specific insights */}
      <Section>
        <SectionTitle $darkMode={darkMode}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
            <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
          </svg>
          Adapted Insights
        </SectionTitle>
        
        {isLoadingInsights ? (
          <div style={{ display: 'flex', alignItems: 'center', margin: '16px 0' }}>
            <LoadingSpinner />
            <span style={{ marginLeft: '8px' }}>Generating insights...</span>
          </div>
        ) : insights.length > 0 ? (
          insights.map(insight => (
            <InsightCard 
              key={insight.id} 
              $darkMode={darkMode} 
              insightType={insight.type}
            >
              <InsightTitle $darkMode={darkMode}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                  <path d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                </svg>
                {insight.title}
              </InsightTitle>
              <InsightDescription $darkMode={darkMode}>
                {insight.description}
              </InsightDescription>
              <InsightMeta $darkMode={darkMode}>
                <InsightTag $darkMode={darkMode}>
                  {insight.type.charAt(0).toUpperCase() + insight.type.slice(1)}
                </InsightTag>
                <span>Confidence: {Math.round(insight.confidence * 100)}%</span>
              </InsightMeta>
            </InsightCard>
          ))
        ) : (
          <div style={{ textAlign: 'center', padding: '20px', color: darkMode ? '#94A3B8' : '#64748B' }}>
            No insights available yet
          </div>
        )}
      </Section>

      {/* Community section - Updated */}
      {community && communityDetails && selectedNode && (
        <Section>
          <SectionTitle $darkMode={darkMode}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
              <polyline points="3.29 7 12 12 20.71 7"></polyline>
              <line x1="12" y1="22" x2="12" y2="12"></line>
            </svg>
            Community Information
          </SectionTitle>
          
          <div style={{ padding: '10px' }}>
            {/* Find the community ID for this node */}
            {(() => {
              const communityId = Object.entries(communityDetails.names).find(
                ([id, name]) => name === community
              )?.[0];
              
              if (!communityId) {
                return (
                  <div style={{ textAlign: 'center', padding: '10px', color: darkMode ? '#94A3B8' : '#64748B' }}>
                    No community information available
                  </div>
                );
              }
              
              // Show the number of nodes in this community
              const nodeCount = communityDetails.nodeCounts[communityId] || 0;
              
              return (
                <div style={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  gap: '10px',
                  color: darkMode ? '#E2E8F0' : '#334155'
                }}>
                  <div>
                    <strong>Community:</strong> {community}
                  </div>
                  <div>
                    <strong>Members:</strong> {nodeCount} node{nodeCount !== 1 ? 's' : ''}
                  </div>
                </div>
              );
            })()}
          </div>
        </Section>
      )}
      
      {/* Floating Hide button for mobile expanded view */}
      {isMobile && expanded && onToggleExpand && (
        <FloatingHideButton $darkMode={darkMode} onClick={onToggleExpand}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
          Hide Details
        </FloatingHideButton>
      )}
    </Panel>
  );
};