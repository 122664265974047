import React, { useCallback, useMemo, useRef } from 'react';
import { MainContent, FullscreenCanvas, BlurOverlay, ProcessingText, FPSMonitor } from '../styles/KnowledgeGraphExplorerStyles';
import ForceGraphWASM from '../graph/ForceGraphWASM';
import Flexagon from '../common/Flexagon';
import { NodeData, EdgeData } from '../types/types';
import Logger from '../../../util/Logger';

interface GraphDisplayProps {
  nodes: NodeData[];
  edges: EdgeData[];
  filteredNodes: NodeData[];
  filteredEdges: EdgeData[];
  selectedNode: NodeData | null;
  onNodeClick: (node: NodeData) => void;
  darkMode: boolean;
  fps: number;
  lastQueryTime: number;
  isProcessingVoice: boolean;
  getNodeCommunityColor: (nodeId: string, options?: { searchResults?: boolean }) => string;
  communities: Record<string, string>;
  isLoadingData?: boolean;
}

const GraphDisplay: React.FC<GraphDisplayProps> = ({
  nodes,
  edges,
  filteredNodes,
  filteredEdges,
  selectedNode,
  onNodeClick,
  darkMode,
  fps,
  lastQueryTime,
  isProcessingVoice,
  getNodeCommunityColor,
  communities,
  isLoadingData = false
}) => {
  // Track previous props to avoid unnecessary rerenders
  const prevPropsRef = useRef({
    filteredNodesLength: filteredNodes?.length || 0,
    filteredEdgesLength: filteredEdges?.length || 0,
    darkMode
  });

  // Create stable callbacks using useCallback
  const handleNodeHover = useCallback((node: NodeData | null) => {
    // Implementation handled internally in ForceGraphWASM
  }, []);

  const handleNodeExpand = useCallback((node: NodeData) => {
    Logger.log('Expanding node:', node.id);
    // Internal expansion logic is handled by ForceGraphWASM
  }, []);

  // Create a stable version of the getNodeCommunityColor function
  const stableGetNodeCommunityColor = useCallback((nodeId: string, options?: { searchResults?: boolean }) => {
    return getNodeCommunityColor(nodeId, options);
  }, [getNodeCommunityColor]);

  // Validate edges to make sure they only reference existing nodes
  const validateData = useCallback((nodes: NodeData[], edges: EdgeData[]) => {
    if (!nodes || !edges) return { validNodes: nodes || [], validEdges: edges || [] };
    
    // Create a Set of node IDs for fast lookup
    const nodeIds = new Set(nodes.map(node => node.id));
    
    // Filter out edges that reference non-existent nodes
    const validEdges = edges.filter(edge => {
      const sourceId = typeof edge.source === 'object' && edge.source !== null 
        ? (edge.source as { id: string }).id 
        : edge.source as string;
        
      const targetId = typeof edge.target === 'object' && edge.target !== null 
        ? (edge.target as { id: string }).id 
        : edge.target as string;
      
      return nodeIds.has(sourceId) && nodeIds.has(targetId);
    });
    
    if (validEdges.length !== edges.length) {
      Logger.log(`Removed ${edges.length - validEdges.length} invalid edges that referenced non-existent nodes`);
    }
    
    return { validNodes: nodes, validEdges };
  }, []);
  
  // Memoize the graph to prevent unnecessary rerenders while preserving layout
  const memoizedGraph = useMemo(() => {
    // Check if any meaningful props have changed
    const hasNodesChanged = filteredNodes?.length !== prevPropsRef.current.filteredNodesLength;
    const hasEdgesChanged = filteredEdges?.length !== prevPropsRef.current.filteredEdgesLength;
    const hasDarkModeChanged = darkMode !== prevPropsRef.current.darkMode;
    
    // Only log when props actually change
    if (hasNodesChanged || hasEdgesChanged || hasDarkModeChanged) {
      Logger.log(`Graph data updated: ${filteredNodes?.length} nodes, ${filteredEdges?.length} edges (dark mode: ${darkMode})`);
      
      // Update our reference
      prevPropsRef.current = {
        filteredNodesLength: filteredNodes?.length || 0,
        filteredEdgesLength: filteredEdges?.length || 0,
        darkMode
      };
    }
    
    // Validate nodes and edges to prevent "node not found" errors
    const { validNodes, validEdges } = validateData(filteredNodes, filteredEdges);
    
    return (
      <ForceGraphWASM
        nodes={validNodes}
        edges={validEdges}
        onNodeClick={onNodeClick}
        onNodeHover={handleNodeHover}
        onNodeExpand={handleNodeExpand}
        width={window.innerWidth}
        height={window.innerHeight}
        darkMode={darkMode}
        getNodeCommunityColor={stableGetNodeCommunityColor}
        communities={communities}
      />
    );
  }, [
    // Only include essential props in the dependency array
    // Use length checks for arrays to prevent rerendering when only reference changes
    filteredNodes && filteredNodes.length,
    filteredEdges && filteredEdges.length,
    onNodeClick,
    handleNodeHover,
    handleNodeExpand,
    darkMode,
    stableGetNodeCommunityColor,
    communities,
    validateData // Include the validation function
  ]);
  
  return (
    <MainContent>
      <FullscreenCanvas>
        {memoizedGraph}
      </FullscreenCanvas>
      
      {/* Voice processing overlay */}
      <BlurOverlay 
        $active={isProcessingVoice} 
        $darkMode={darkMode}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
          <div style={{ width: '300px', height: '300px' }}>
            <Flexagon />
          </div>
          <ProcessingText $darkMode={darkMode}>
            Adapting...
          </ProcessingText>
        </div>
      </BlurOverlay>
      
      {/* Performance monitoring indicators */}
      <FPSMonitor $darkMode={darkMode}>
        FPS: {fps} | Query: {lastQueryTime > 0 
          ? lastQueryTime >= 1000 
            ? `${(lastQueryTime / 1000).toFixed(2)}s` 
            : `${lastQueryTime.toFixed(0)}ms`
          : 'N/A'}
      </FPSMonitor>
      
      {/* Loading overlay with Flexagon */}
      <BlurOverlay $active={isLoadingData} $darkMode={darkMode}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
          <div style={{ width: '300px', height: '300px' }}>
            <Flexagon />
          </div>
          <ProcessingText $darkMode={darkMode}>
            Adapting...
          </ProcessingText>
        </div>
      </BlurOverlay>
    </MainContent>
  );
};

export default GraphDisplay;