import { GraphData } from '../types/types';
import Logger from 'util/Logger';

export class GraphService {
  private backendUrl: string;
  private static instance: GraphService;
  
  constructor(backendUrl?: string) {
    // Import service config here to ensure it's evaluated at runtime
    const serviceConfig = require('./ServiceConfig').default;
    
    this.backendUrl = backendUrl || serviceConfig.graphApiUrl;
    Logger.log(`GraphService initialized with backend URL: ${this.backendUrl}`);
  }
  
  /**
   * Singleton pattern to ensure consistent instance usage
   */
  public static getInstance(backendUrl?: string): GraphService {
    if (!GraphService.instance) {
      GraphService.instance = new GraphService(backendUrl);
    }
    return GraphService.instance;
  }
  
  /**
   * Fetch graph data from the backend API
   */
  async fetchGraphData(): Promise<GraphData> {
    try {
      Logger.log(`Fetching graph data from API: ${this.backendUrl}/data`);
      
      const response = await fetch(`${this.backendUrl}/data`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        // Add cache control to prevent caching issues
        cache: 'no-cache'
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        Logger.error(`Backend API error response: ${errorText}`);
        throw new Error(`Backend API error: ${response.status} - ${response.statusText}`);
      }
      
      const data = await response.json();
      Logger.log(`Received graph data with ${data.nodes.length} nodes and ${data.edges.length} edges`);
      
      return {
        nodes: data.nodes,
        edges: data.edges
      };
    } catch (error: unknown) {
      Logger.error('Error fetching graph data:', error);
      throw error;
    }
  }
  
  /**
   * Save updated graph data to backend API
   */
  async saveGraphData(graphData: GraphData): Promise<boolean> {
    try {
      Logger.log(`Saving graph data with ${graphData.nodes.length} nodes and ${graphData.edges.length} edges`);
      Logger.log(`Using API endpoint: ${this.backendUrl}/data`);
      
      const response = await fetch(`${this.backendUrl}/data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nodes: graphData.nodes,
          edges: graphData.edges
        }),
        // Add cache control to prevent caching issues
        cache: 'no-cache'
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        Logger.error(`Backend API error response: ${errorText}`);
        throw new Error(`Backend API error: ${response.status} - ${response.statusText}`);
      }
      
      const result = await response.json();
      Logger.log('Graph data saved successfully:', result);
      
      return true;
    } catch (error: unknown) {
      Logger.error('Error saving graph data:', error);
      return false;
    }
  }
}

// Create a singleton instance for use throughout the app
export const graphService = GraphService.getInstance();