import { Provider } from "mobx-react";

import actionLogStore from "stores/ActionLogStore";
import atlasStore from "stores/AtlasStore";
import authStore from "stores/AuthStore";
import onboardingStore from "stores/OnboardingStore";
import nlpStore from "stores/NLPStore";
import promptStore from "stores/PromptStore";
import userStore from "stores/UserStore";
import webSocketStore from "stores/WebSocketStore";
import telemetryStore from "stores/TelemetryStore";

import ADAPTER from 'adapter'

const global_wrapper = {
  ActionLogStore: actionLogStore,
  AtlasStore: atlasStore,
  AuthStore: authStore,
  OnboardingStore: onboardingStore,
  NLPStore: nlpStore,
  PromptStore: promptStore,
  UserStore: userStore,
  WebSocketStore: webSocketStore,
  TelemetryStore: telemetryStore
};

const StoreWrapper = ({ children }: any) => {
  return (
    <Provider
      {...global_wrapper}
    >
      {children}
    </Provider>
  );
};

export default StoreWrapper;
ADAPTER.createNamespace('ADAPTER.stores', global_wrapper)