// /frontend/src/util/layoutAlgorithms.ts
import * as d3 from 'd3';
import { Node, 
  Link, 
  LayoutOptions, 
  LayoutConstraint, 
  ClusterData, 
  TopicWord, 
  CommunityWordCloud,
  CommunityAnalysis  } from '../types/network-types';

export interface LayoutResult {
  nodes: Node[];
  links: Link[];
  clusters?: ClusterData[];
}

interface LayoutGroup {
  id: string;
  nodes: Node[];
  links: Link[];
  center: { x: number; y: number };
  radius: number;
  color?: string;
}

export class WordCloudAnalyzer {
  // /frontend/src/util/layoutAlgorithms.ts

// In the WordCloudAnalyzer class, modify the analyzeCommunities method:
static analyzeCommunities(nodes: Node[], links: Link[], USER_EMAILS: string[]): CommunityAnalysis[] {
  console.log('Analyzing communities with user emails:', USER_EMAILS); // Debug log

  const communities = new Map<number, Set<string>>();
  
  // Group nodes by community
  links.forEach(link => {
    if (link.in_same_community && link.communities) {
      link.communities.forEach(communityId => {
        if (!communities.has(communityId)) {
          communities.set(communityId, new Set());
        }
        const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
        const targetId = typeof link.target === 'string' ? link.target : link.target.id;
        
        // Only add nodes that aren't user emails
        if (!USER_EMAILS.includes(sourceId)) {
          communities.get(communityId)!.add(sourceId);
        }
        if (!USER_EMAILS.includes(targetId)) {
          communities.get(communityId)!.add(targetId);
        }
      });
    }
  });

  // Create analysis for each community
  return Array.from(communities.entries()).map(([id, nodeIds]) => {
    // Filter out user emails from community nodes
    const communityNodes = nodes.filter(node => 
      nodeIds.has(node.id) && !USER_EMAILS.includes(node.id)
    );

    // Filter community links to exclude connections involving user emails
    const communityLinks = links.filter(link => {
      const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
      const targetId = typeof link.target === 'string' ? link.target : link.target.id;
      
      return nodeIds.has(sourceId) && 
             nodeIds.has(targetId) && 
             link.communities?.includes(id) && 
             link.in_same_community &&
             !USER_EMAILS.includes(sourceId) &&
             !USER_EMAILS.includes(targetId);
    });

    const wordCloud = this.mergeCommunityWordClouds(communityLinks, id, USER_EMAILS);
    
    return {
      id,
      nodes: communityNodes,
      links: communityLinks,
      wordCloud,
      cohesionScore: this.calculateCohesionScore(communityLinks, id, USER_EMAILS),
      density: this.calculateDensity(communityNodes.length, communityLinks, id, USER_EMAILS)
    };
  });
}

// Modify mergeCommunityWordClouds to work with filtered links
static mergeCommunityWordClouds(links: Link[], communityId: number, USER_EMAILS: string[]): TopicWord[] {
  // Links should already be filtered for user emails
  const wordMap = new Map<string, TopicWord>();

  links.forEach(link => {
    link.topics?.forEach(topic => {
      if (!topic.word || typeof topic.word !== 'string') {
        return;
      }

      // Skip words that contain user emails
      if (USER_EMAILS.some(email => topic.word.toLowerCase().includes(email.toLowerCase()))) {
        return;
      }

      const existing = wordMap.get(topic.word);
      if (existing) {
        existing.count += topic.count;
        existing.score = Math.max(existing.score, topic.score);
      } else {
        wordMap.set(topic.word, { ...topic });
      }
    });
  });

  return Array.from(wordMap.values())
    .sort((a, b) => b.count !== a.count ? b.count - a.count : b.score - a.score)
    .slice(0, 50);
}

  private static calculateCohesionScore(links: Link[], communityId: number, USER_EMAILS: string[]): number {
    const communityLinks = links.filter(link => {
      const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
      const targetId = typeof link.target === 'string' ? link.target : link.target.id;
      
      // Skip user email links when calculating cohesion
      if (USER_EMAILS.includes(sourceId) || USER_EMAILS.includes(targetId)) {
        return false;
      }
      
      return link.communities?.includes(communityId) && link.in_same_community;
    });
    
    if (communityLinks.length === 0) return 0;
    
    return communityLinks.reduce((score, link) => {
      const topicScore = link.topics?.reduce((sum, topic) => sum + topic.score, 0) || 0;
      return score + (link.value * (1 + topicScore / 100));
    }, 0) / communityLinks.length;
  }

  private static calculateDensity(
    nodeCount: number, 
    links: Link[], 
    communityId: number,
    USER_EMAILS: string[]
  ): number {
    if (nodeCount <= 1) return 0;
    
    const maxPossibleConnections = (nodeCount * (nodeCount - 1)) / 2;
    const actualConnections = links.filter(link => {
      const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
      const targetId = typeof link.target === 'string' ? link.target : link.target.id;
      
      // Skip user email links when calculating density
      if (USER_EMAILS.includes(sourceId) || USER_EMAILS.includes(targetId)) {
        return false;
      }
      
      return link.communities?.includes(communityId) && link.in_same_community;
    }).length;
    
    return actualConnections / maxPossibleConnections;
  }
}

export class LayoutEngine {
  private width: number;
  private height: number;
  private simulation: d3.Simulation<Node, Link> | null;
  private horizontalLayout: boolean;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
    this.simulation = null;
    this.horizontalLayout = true;  // Default to horizontal layout
  }

  public setHorizontalLayout(horizontal: boolean): void {
    this.horizontalLayout = horizontal;
  }

  public isHorizontalLayout(): boolean {
    return this.horizontalLayout;
  }


  public applyLayout(
    nodes: Node[], 
    links: Link[], 
    options: LayoutOptions,
    constraints?: LayoutConstraint[]
  ): Promise<LayoutResult> {
    switch (options.type) {
      case 'force':
        return this.forceDirectedLayout(nodes, links, options.settings);
      case 'radial':
        return this.radialLayout(nodes, links, options.settings);
      case 'hierarchical':
        return this.hierarchicalLayout(nodes, links, options.settings);
      case 'cluster':
        return this.clusterLayout(nodes, links, options.settings);
      case 'temporal':
        return this.temporalLayout(nodes, links, options.settings);
      default:
        return this.hierarchicalLayout(nodes, links, options.settings);
    }
  }

  public organizeFilteredLayout(
    filteredNodes: Node[],
    filteredLinks: Link[],
    allNodes: Node[],
    allLinks: Link[],
    settings: LayoutOptions['settings']
  ): Promise<LayoutResult> {
    // Create active and inactive groups
    const activeGroup: LayoutGroup = {
      id: 'active',
      nodes: filteredNodes,
      links: filteredLinks,
      center: { x: this.width * 0.4, y: this.height / 2 },
      radius: Math.min(this.width, this.height) * 0.35,
      color: '#34C759'
    };

    const inactiveNodes = allNodes.filter(
      node => !filteredNodes.some(fn => fn.id === node.id)
    );

    const inactiveLinks = allLinks.filter(link => {
      const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
      const targetId = typeof link.target === 'string' ? link.target : link.target.id;
      return inactiveNodes.some(n => n.id === sourceId || n.id === targetId);
    });

    const inactiveGroup: LayoutGroup = {
      id: 'inactive',
      nodes: inactiveNodes,
      links: inactiveLinks,
      center: { x: this.width * 0.8, y: this.height / 2 },
      radius: Math.min(this.width, this.height) * 0.25,
      color: '#8E8E93'
    };

    return this.layoutGroups([activeGroup, inactiveGroup], settings);
  }

  private async forceDirectedLayout(
    nodes: Node[], 
    links: Link[], 
    settings: LayoutOptions['settings']
  ): Promise<LayoutResult> {
    return new Promise((resolve) => {
      this.simulation = d3.forceSimulation(nodes)
        .force('link', d3.forceLink<Node, Link>(links)
          .id(d => d.id)
          .distance(settings.nodeSpacing))
        .force('charge', d3.forceManyBody()
          .strength(-settings.gravitationalForce))
        .force('center', d3.forceCenter(this.width / 2, this.height / 2)
          .strength(settings.centeringForce))
        .force('collision', d3.forceCollide()
          .radius(settings.nodeSpacing / 2))
        .force('x', d3.forceX(this.width / 2).strength(0.1))
        .force('y', d3.forceY(this.height / 2).strength(0.1));

      this.simulation.on('end', () => {
        this.simulation?.stop();
        resolve({ nodes, links });
      });
    });
  }

  private async radialLayout(
    nodes: Node[], 
    links: Link[], 
    settings: LayoutOptions['settings']
  ): Promise<LayoutResult> {
    const radius = Math.min(this.width, this.height) / 3;
    const angleStep = (2 * Math.PI) / nodes.length;

    nodes.forEach((node, i) => {
      node.x = this.width / 2 + radius * Math.cos(i * angleStep);
      node.y = this.height / 2 + radius * Math.sin(i * angleStep);
    });

    return this.stabilizeLayout(nodes, links, settings);
  }

  private async hierarchicalLayout(
    nodes: Node[],
    links: Link[],
    settings: LayoutOptions['settings']
  ): Promise<LayoutResult> {
    const hierarchy = d3.stratify<Node>()
      .id(d => d.id)
      .parentId(d => this.findParent(d, links))(nodes);

    const treeLayout = d3.tree<Node>()
      .size(this.horizontalLayout ? 
        [this.height - 100, this.width - 100] : 
        [this.width - 100, this.height - 100])
      .nodeSize([settings.nodeSpacing, settings.nodeSpacing * 2]);

    const root = treeLayout(hierarchy);

    root.each(d => {
      const node = nodes.find(n => n.id === d.id);
      if (node) {
        if (this.horizontalLayout) {
          node.x = d.y + 500;
          node.y = d.x + this.height / 2;
        } else {
          node.x = d.x + this.width / 2;
          node.y = d.y + 500;
        }
      }
    });

    return { nodes, links };
  }

  private async clusterLayout(
    nodes: Node[], 
    links: Link[], 
    settings: LayoutOptions['settings']
  ): Promise<LayoutResult> {
    const clusters = d3.group(nodes, d => d.cluster);
    const clusterCenters = new Map<number, { x: number; y: number }>();

    clusters.forEach((clusterNodes, clusterId) => {
      const angle = (Number(clusterId) * 2 * Math.PI) / clusters.size;
      const radius = Math.min(this.width, this.height) / 4;
      clusterCenters.set(Number(clusterId), {
        x: this.width / 2 + radius * Math.cos(angle),
        y: this.height / 2 + radius * Math.sin(angle)
      });
    });

    const clusteringForce = (alpha: number) => {
      nodes.forEach(node => {
        if (typeof node.cluster === 'number') {
          const center = clusterCenters.get(node.cluster);
          if (center) {
            node.vx = (node.vx || 0) + (center.x - (node.x || 0)) * alpha * settings.gravitationalForce;
            node.vy = (node.vy || 0) + (center.y - (node.y || 0)) * alpha * settings.gravitationalForce;
          }
        }
      });
    };

    return new Promise((resolve) => {
      this.simulation = d3.forceSimulation(nodes)
        .force('link', d3.forceLink<Node, Link>(links).id(d => d.id))
        .force('charge', d3.forceManyBody().strength(-settings.gravitationalForce))
        .force('collision', d3.forceCollide().radius(settings.nodeSpacing))
        .force('clustering', clusteringForce)
        .force('center', d3.forceCenter(this.width / 2, this.height / 2));

      this.simulation.on('end', () => {
        this.simulation?.stop();
        const clusterResults: ClusterData[] = Array.from(clusters.entries()).map(([id, nodes]) => ({
          id: `cluster-${id}`,
          nodes,
          center: clusterCenters.get(Number(id)) || { x: 0, y: 0 },
          radius: Math.min(this.width, this.height) * 0.15,
          color: d3.schemeCategory10[Number(id) % 10]
        }));
        resolve({ nodes, links, clusters: clusterResults });
      });
    });
  }

  private async temporalLayout(
    nodes: Node[], 
    links: Link[], 
    settings: LayoutOptions['settings']
  ): Promise<LayoutResult> {
    const sortedNodes = [...nodes].sort((a, b) => {
      const timeA = a.timestamp ? new Date(a.timestamp).getTime() : 0;
      const timeB = b.timestamp ? new Date(b.timestamp).getTime() : 0;
      return timeA - timeB;
    });

    const timeScale = d3.scaleTime()
      .domain(d3.extent(sortedNodes, d => d.timestamp ? new Date(d.timestamp) : new Date()) as [Date, Date])
      .range([100, this.width - 100]);

    sortedNodes.forEach(node => {
      if (node.timestamp) {
        node.x = timeScale(new Date(node.timestamp));
        node.y = this.height / 2 + (Math.random() - 0.5) * 100;
      }
    });

    return this.stabilizeLayout(sortedNodes, links, settings);
  }

  private async layoutGroups(
    groups: LayoutGroup[],
    settings: LayoutOptions['settings']
  ): Promise<LayoutResult> {
    return new Promise((resolve) => {
      const allNodes = groups.flatMap(g => g.nodes);
      const allLinks = groups.flatMap(g => g.links);

      this.simulation = d3.forceSimulation(allNodes)
        .force('link', d3.forceLink<Node, Link>(allLinks).id(d => d.id))
        .force('charge', d3.forceManyBody().strength(-settings.gravitationalForce))
        .force('collision', d3.forceCollide().radius(settings.nodeSpacing))
        .force('group', alpha => {
          groups.forEach(group => {
            group.nodes.forEach(node => {
              const dx = (group.center.x - (node.x || 0));
              const dy = (group.center.y - (node.y || 0));
              const distance = Math.sqrt(dx * dx + dy * dy);
              
              if (distance > group.radius) {
                const k = (alpha * settings.centeringForce);
                node.vx = (node.vx || 0) + dx * k;
                node.vy = (node.vy || 0) + dy * k;
              }
            });
          });
        });

      this.simulation.on('end', () => {
        this.simulation?.stop();
        const clusters: ClusterData[] = groups.map(group => ({
          id: group.id,
          nodes: group.nodes,
          center: group.center,
          radius: group.radius,
          color: group.color || d3.schemeCategory10[0]
        }));
        resolve({ nodes: allNodes, links: allLinks, clusters });
      });
    });
  }

  private async stabilizeLayout(
    nodes: Node[], 
    links: Link[], 
    settings: LayoutOptions['settings']
  ): Promise<LayoutResult> {
    return new Promise((resolve) => {
      this.simulation = d3.forceSimulation(nodes)
        .force('link', d3.forceLink<Node, Link>(links).id(d => d.id))
        .force('charge', d3.forceManyBody().strength(-settings.gravitationalForce))
        .force('collision', d3.forceCollide().radius(settings.nodeSpacing))
        .alpha(0.3)
        .alphaDecay(0.02);

      this.simulation.on('end', () => {
        this.simulation?.stop();
        resolve({ nodes, links });
      });
    });
  }

  private findParent(node: Node, links: Link[]): string | null {
    const incomingLinks = links.filter(link => 
      (typeof link.target === 'string' ? link.target : link.target.id) === node.id
    );
    
    if (incomingLinks.length === 0) return null;

    const strongestLink = incomingLinks.reduce((prev, curr) => 
      curr.value > prev.value ? curr : prev
    );

    return typeof strongestLink.source === 'string' 
      ? strongestLink.source 
      : strongestLink.source.id;
  }

  public cleanup(): void {
    if (this.simulation) {
      this.simulation.stop();
      this.simulation = null;
    }
  }
}