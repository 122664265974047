import React from "react";
import { inject, observer } from "mobx-react";

import AuthenticatedPage from "components/common/AuthenticatedPage";
import Footer from "components/common/Footer";

import "../../brand/index.scss";
import "./Onboarding.scss";

interface OnboardingPageProps {
    OnboardingStore?: any;
    children: any;
}

class OnboardingPage extends React.Component<OnboardingPageProps, {}> {
    render() {
        const { children } = this.props;

        return (
            <AuthenticatedPage>
                <main className="adapter-basepage">
                    <div className="adapter-basepage-inner">
                        <div className="adapter-fullscreen-content">
                            
                            <div className="flex-1" />

                            <div className="adapter-onboarding">
                                {children}
                            </div>

                            <div className="flex-1" />

                            <Footer style={{ right: 32 }} />
                        </div>
                    </div>
                </main>
            </AuthenticatedPage>
        );
    }
}

export default inject("OnboardingStore")(observer(OnboardingPage));
