// /frontend/src/components/NetworkCohesionAnalysis.tsx
import React, { useMemo, useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Progress from 'components/common/Progress';
import SafeForceGraph from './SafeForceGraph';
import { Node, Link, NetworkData, TopicWord, FilterState } from '../types/network-types';
import StatisticsView from './StatisticsView';
import { useFullScreen } from '../context/FullScreenContext';
import { WordCloudAnalyzer } from '../util/layoutAlgorithms';
import { anthropicService } from 'util/AnthropicService';

const AnalysisContainer = styled.div<{ isFullScreen: boolean }>`
    position: relative;
    width: ${props => props.isFullScreen ? '100vw' : '100%'};
    height: ${props => props.isFullScreen ? '100vh' : '100%'};
    background: #f5f5f7;
    border-radius: ${props => props.isFullScreen ? '0' : '20px'};
    overflow: hidden;
    display: flex;
`;

const MainView = styled.div`
    flex: 1;
    position: relative;
`;

const SidePanel = styled(motion.div)`
    width: 320px;
    background: white;
    height: 100%;
    overflow-y: auto;
    border-left: 1px solid #e5e5e7;
    padding: 40px 20px;
    scroll-behavior: smooth;
    padding-bottom: 40px;
    position: relative;
    
    /* Create a flex container for ordering */
    > div {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        position: relative;
        z-index: 1;
    }

    /* Ensure proper stacking of cards */
    > div > * {
        position: relative;
        will-change: transform, opacity;
    }
    
    /* Add smooth scrolling behavior */
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    
    /* Scrollbar styling */
    &::-webkit-scrollbar {
        width: 8px;
    }
    
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        
        &:hover {
            background: rgba(0, 0, 0, 0.3);
        }
    }
    
    /* Gradient fade at the top when scrolling */
    &::before {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        width: 320px;
        height: 20px;
        background: linear-gradient(
            to bottom, 
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        pointer-events: none;
        z-index: 3;
    }

    /* Gradient fade at the bottom */
    &::after {
        content: '';
        position: fixed;
        bottom: 0;
        right: 0;
        width: 320px;
        height: 20px;
        background: linear-gradient(
            to top, 
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        pointer-events: none;
        z-index: 3;
    }

    /* Optimize for GPU acceleration */
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;

    /* Handle mobile devices */
    @media (max-width: 768px) {
        width: 100%;
        border-left: none;
        padding: 20px 10px;
        
        &::before,
        &::after {
            width: 100%;
        }
    }

    /* Ensure proper spacing between cards */
    > div > * + * {
        margin-top: 16px;
    }

    /* Handle animations */
    .layout-transition {
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    /* Ensure proper stacking context for animations */
    isolation: isolate;

    /* Improve performance for fixed elements */
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
`;

const CommunityMembers = styled(motion.div)`
  padding: 16px;
  background: white;
  border-top: 1px solid #eee;
`;

const MemberList = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin-top: 8px;
`;

const MemberItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 4px;
  background: #f8f8f8;
  
  &:hover {
    background: #f0f0f0;
  }
`;

const MemberEmail = styled.div`
  font-size: 14px;
  color: #333;
  flex: 1;
`;

const MemberGroup = styled.div`
  font-size: 12px;
  color: #666;
  padding: 2px 8px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid #eee;
`;

const CommunityCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 0;
    
    > * {
        order: 0;
        transition: order 0.3s ease;
    }
`;

const CommunityCard = styled.div<{ isHighlighted?: boolean }>`
    margin-top: 16px;
    background: white;
    border-radius: 12px;
    box-shadow: ${props => props.isHighlighted 
        ? '0 4px 20px rgba(0, 113, 227, 0.3)' 
        : '0 2px 8px rgba(0, 0, 0, 0.1)'};
    margin-bottom: 16px;
    overflow: hidden;
    transform: ${props => props.isHighlighted ? 'scale(1.02)' : 'scale(1)'};
    transition: all 0.3s ease;
    border: ${props => props.isHighlighted ? '2px solid #0071e3' : '1px solid #eee'};
`;

const CommunityHeader = styled.div`
    padding: 28px;
    background: #f8f8f8;
    border-bottom: 1px solid #eee;

    h3 {
        margin: 0;
        font-size: 16px;
        color: #333;
    }
`;

const MetricsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 16px;
    background: #fafafa;
`;

const MetricBox = styled.div`
    background: white;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #eee;

    .label {
        font-size: 12px;
        color: #666;
        margin-bottom: 4px;
    }

    .value {
        font-size: 16px;
        font-weight: 600;
        color: #333;
    }
`;

const WordCloudContainer = styled.div`
    padding: 16px;
    background: white;
`;

const AnalysisToolbar = styled(motion.div)`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 12px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    padding: 8px;
    border-radius: 12px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    z-index: 10;
`;

const ToolbarButton = styled(motion.button)<{ active?: boolean }>`
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    background: ${props => props.active ? '#0071e3' : 'transparent'};
    color: ${props => props.active ? 'white' : '#1d1d1f'};
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        background: ${props => props.active ? '#0077ED' : 'rgba(0, 0, 0, 0.05)'};
    }
`;



const CategoryTag = styled.span`
  display: inline-block;
  padding: 4px 12px;
  background: #F4F3FF;
  border-radius: 12px;
  font-size: 12px;
  color: #666;
  font-family: "Rand Medium", sans-serif;
`;

const ConfidenceScore = styled.div<{ score: number }>`
  display: inline-block;
  padding: 4px 8px;
  background: ${props => props.score >= 0.8 ? '#E6FFE6' : '#FFF3E6'};
  color: ${props => props.score >= 0.8 ? '#28A745' : '#FD7E14'};
  border-radius: 4px;
  font-size: 12px;
  margin-top: 8px;
`;

const defaultFilters: FilterState = {
    layout: 'force',
    minConnections: 0,
    connectionTypes: ['Strong', 'Regular', 'Weak'],
    searchTerm: '',
    dateRange: [null, null]
};

interface CommunityAnalysisResult {
    label: string;
    summary: {
        overview: string;
        interests: string[];
        geography: string;
        collaboration: string[];
    };
    confidence: number;
}

interface CommunityCache {
  [communityId: string]: {
    analysis: CommunityAnalysisResult;
    timestamp: number;
    expiresAt: number;
  };
}

interface AnalysisApiOptions {
    maxRetries?: number;
    retryDelay?: number;
    timeout?: number;
}

const CommunityWordCloud: React.FC<{ 
    words: TopicWord[], 
    maxCount: number 
}> = ({ words, maxCount }) => {
    const fontSize = (count: number) => {
        return Math.max(12, Math.min(32, (count / maxCount) * 32));
    };

    return (
        <WordCloudContainer>
            {words.map((word, i) => (
                <motion.span
                    key={i}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: i * 0.02 }}
                    style={{
                        fontSize: `${fontSize(word.count)}px`,
                        color: `rgba(0, 0, 0, ${0.3 + word.score * 0.7})`,
                        fontWeight: word.score > 0.7 ? 'bold' : 'normal',
                        margin: '4px',
                        display: 'inline-block',
                        cursor: 'default'
                    }}
                    whileHover={{
                        scale: 1.1,
                        transition: { duration: 0.2 }
                    }}
                >
                    {word.word}
                </motion.span>
            ))}
        </WordCloudContainer>
    );
};

interface NetworkCohesionAnalysisProps {
    data: NetworkData;
    onNodeSelect?: (node: Node) => void;
    onNodeClick?: (node: Node) => void;
    width: number;
    height: number;
    selectedNode?: Node | null;
    filters?: FilterState;
    UserStore?: any;
}

interface NetworkAnalysisHelper {
    calculateCohesionScore: (links: Link[]) => number;
    calculateDensity: (nodeCount: number, links: Link[]) => number;
}

// Convert NetworkAnalysis to a factory function that takes userEmails
const createNetworkAnalysis = (userEmails: string[]): NetworkAnalysisHelper => ({
    calculateCohesionScore(links: Link[]): number {
        if (links.length === 0) return 0;

        const validLinks = links.filter(link => {
            const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
            const targetId = typeof link.target === 'string' ? link.target : link.target.id;
            return !userEmails.includes(sourceId) && !userEmails.includes(targetId);
        });

        return validLinks.reduce((score, link) => {
            const topicScore = link.topics?.reduce((sum, topic) => sum + topic.score, 0) || 0;
            return score + (link.value * (1 + topicScore / 100));
        }, 0) / validLinks.length;
    },

    calculateDensity(nodeCount: number, links: Link[]): number {
        if (nodeCount <= 1) return 0;

        const validLinks = links.filter(link => {
            const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
            const targetId = typeof link.target === 'string' ? link.target : link.target.id;
            return !userEmails.includes(sourceId) && !userEmails.includes(targetId);
        });

        const maxPossibleConnections = (nodeCount * (nodeCount - 1)) / 2;
        return validLinks.length / maxPossibleConnections;
    }
});

const NetworkCohesionAnalysis: React.FC<NetworkCohesionAnalysisProps> = ({
    data,
    onNodeSelect,
    onNodeClick,
    width,
    height,
    UserStore
}) => {
    const { isFullScreen, toggleFullScreen } = useFullScreen();
    const [showMetrics, setShowMetrics] = useState(true);
    const [selectedCommunity, setSelectedCommunity] = useState<string | number | null>(null);
    const [displayMode, setDisplayMode] = useState<'graph' | 'statistics'>('graph');
    const [selectedNode, setSelectedNode] = useState<Node | null>(null);
    const [communityAnalyses, setCommunityAnalyses] = useState<{[key: string]: CommunityAnalysisResult}>({});
    const [analyzingCommunity, setAnalyzingCommunity] = useState<string | number | null>(null);
    const [selectedConnectionTypes, setSelectedConnectionTypes] = useState<string[]>(['Strong', 'Regular', 'Weak']);
    const communityCache: CommunityCache = {};

    const userEmails = UserStore?.userEmails;

    useEffect(() => {
        console.log('Current User Emails:', userEmails);
    }, [userEmails]);

    const COMMUNITY_CACHE_DURATION = 24 * 60 * 60 * 1000;
    const [searchUpdateTrigger, setSearchUpdateTrigger] = useState(0);
    const [sortUpdateTrigger, setSortUpdateTrigger] = useState(0);

    const [filteredNodes, setFilteredNodes] = useState<Node[]>([]);
    const [filteredLinks, setFilteredLinks] = useState<Link[]>([]);
    const [activeFilters, setActiveFilters] = useState<FilterState>({
      layout: 'force',
      minConnections: 0,
      connectionTypes: ['Strong', 'Regular', 'Weak'],
      searchTerm: '',
      dateRange: [null, null]
    });
    const [showMembers, setShowMembers] = useState<boolean>(false);


    const NetworkAnalysis = useMemo(() => createNetworkAnalysis(userEmails), [userEmails]);


     useEffect(() => {
        if (activeFilters) {
            setActiveFilters(activeFilters);
        }
    }, [activeFilters]);


    useEffect(() => {
        return () => {
            setSelectedNode(null);
            setSelectedCommunity(null);
            setShowMembers(false);
            setFilteredNodes([]);
            setFilteredLinks([]);
        };
    }, []);

    useEffect(() => {
        // Increment the trigger to force a re-render of the side panel
        setSearchUpdateTrigger(prev => prev + 1);
    }, [activeFilters.searchTerm]);

    useEffect(() => {
      setSortUpdateTrigger(prev => prev + 1);
  }, [activeFilters.searchTerm]);

    useLayoutEffect(() => {
      if (activeFilters.searchTerm) {
          const container = document.querySelector('.community-cards-container') as HTMLElement;
          if (container) {
              container.style.display = 'none';
              void container.offsetHeight; // Trigger reflow
              container.style.display = 'flex';
          }
      }
  }, [activeFilters.searchTerm]);

    const { nodes, links } = useMemo(() => {
        const nodesMap = new Map<string, Node>();
        const linksArray: Link[] = [];

        data.connections.forEach(conn => {
            if (!nodesMap.has(conn.email1)) {
                nodesMap.set(conn.email1, {
                    id: conn.email1,
                    value: 1,
                    group: conn.department1 || 'Unknown'
                });
            }
            if (!nodesMap.has(conn.email2)) {
                nodesMap.set(conn.email2, {
                    id: conn.email2,
                    value: 1,
                    group: conn.department2 || 'Unknown'
                });
            }

            const node1 = nodesMap.get(conn.email1)!;
            const node2 = nodesMap.get(conn.email2)!;
            node1.value += conn.interaction_score;
            node2.value += conn.interaction_score;

            linksArray.push({
                source: conn.email1,
                target: conn.email2,
                value: conn.shared_meetings,
                type: getConnectionType(conn.interaction_score),
                communities: conn.communities || [],
                in_same_community: conn.in_same_community || false,
                topics: conn.topics || []
            });
        });

        return {
            nodes: Array.from(nodesMap.values()),
            links: linksArray
        };
    }, [data]);



    const analyzeCommunity = async (
        communityId: string | number,
        wordCloud: TopicWord[],
        communityNodes: Node[],
        communityLinks: Link[]
    ) => {
        try {
            const cacheKey = `community-${communityId}`;
            const isFocusedView = typeof communityId === 'string' && String(communityId).startsWith('focused-');

            // Check cache first (skip for focused views)
            if (!isFocusedView) {
                const cachedData = localStorage.getItem(cacheKey);
                if (cachedData) {
                    const cached = JSON.parse(cachedData) as {
                        analysis: CommunityAnalysisResult;
                        timestamp: number;
                        expiresAt: number;
                    };

                    if (Date.now() < cached.expiresAt) {
                        setCommunityAnalyses(prev => ({
                            ...prev,
                            [communityId]: cached.analysis
                        }));
                        return;
                    }
                }
            }

            setAnalyzingCommunity(communityId);

            // Filter out user emails and prepare word cloud data
            const wordCloudData = wordCloud
                .filter(w => !userEmails.some(email =>
                    w.word.toLowerCase().includes(email.toLowerCase())
                ))
                .sort((a, b) => b.score - a.score)
                .slice(0, 50)
                .map(w => ({
                    word: w.word,
                    count: w.count,
                    score: Number(w.score.toFixed(2))
                }));

            // Prepare node data (excluding user emails)
            const nodeData = communityNodes
                .filter(node => !userEmails.includes(node.id))
                .map(node => ({
                    id: node.id,
                    group: node.group,
                    value: Number(node.value.toFixed(2))
                }));

            // Prepare link data (excluding connections to user emails)
            const linkData = communityLinks
                .filter(link => {
                    const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
                    const targetId = typeof link.target === 'string' ? link.target : link.target.id;
                    return !userEmails.includes(sourceId) && !userEmails.includes(targetId);
                })
                .map(link => ({
                    source: typeof link.source === 'string' ? link.source : link.source.id,
                    target: typeof link.target === 'string' ? link.target : link.target.id,
                    type: link.type,
                    value: link.value
                }));

            // Construct the analysis prompt
            const prompt = `Please analyze this ${isFocusedView ? 'focused network community' : 'social network community'} and provide a structured analysis from calendar events off of the user (these are the users email addresses: ${userEmails}) their calendar.

    Based on the data, please provide:
    1. A concise, descriptive label describing the community (max eight words - include a primary topic where applicable). If there is a primary organization or central topic included it. Do not include more than one proper name in the community label.
    2. A detailed analysis including:
       - Overview of the community's focus and purpose which can range from personal to professional. Consider people, places, events, gatherings, areas of interest in the discussions.
       - Key interests and themes both personal and professional. Sometimes it may be a community with only one or the other or a mix of both. List the highest utility interest ensuring novel entries across the spectrum.
       - Geographic patterns if apparent
       - Core areas of collaboration and focus

    Community Data:
    Word Cloud (Top Terms): ${JSON.stringify(wordCloudData, null, 2)}

    Members (${nodeData.length}): ${JSON.stringify(nodeData, null, 2)}

    Provide the response as a JSON object with this exact structure:
    {
      "label": string,
      "summary": {
        "overview": string,
        "interests": string[],
        "geography": string,
        "collaboration": string[]
      },
      "confidence": number (0-1)
    }`;

            // Call Anthropic API with retry logic
            const response = await anthropicService.sendMessage(prompt);

            if (!response?.content) {
                throw new Error('No response content received from Anthropic API');
            }

            // Parse and validate the response
            let analysis: CommunityAnalysisResult;
            try {
                analysis = JSON.parse(response.content) as CommunityAnalysisResult;

                // Validate the response structure
                if (!analysis.label ||
                    !analysis.summary?.overview ||
                    !Array.isArray(analysis.summary?.interests) ||
                    !analysis.summary?.geography ||
                    !Array.isArray(analysis.summary?.collaboration) ||
                    typeof analysis.confidence !== 'number') {
                    throw new Error('Invalid response structure');
                }

                // Normalize confidence score
                analysis.confidence = Math.max(0, Math.min(1, analysis.confidence));

            } catch (error) {
                console.error('Error parsing analysis response:', error);
                throw new Error('Failed to parse analysis response');
            }

            // Cache the result (skip for focused views)
            if (!isFocusedView) {
                const cacheData = {
                    analysis,
                    timestamp: Date.now(),
                    expiresAt: Date.now() + COMMUNITY_CACHE_DURATION
                };

                try {
                    localStorage.setItem(cacheKey, JSON.stringify(cacheData));
                } catch (error) {
                    console.warn('Failed to cache community analysis:', error);
                    // Continue without caching
                }
            }

            // Update state with new analysis
            setCommunityAnalyses(prev => ({
                ...prev,
                [communityId]: analysis
            }));

        } catch (error) {
            console.error('Error analyzing community:', error);
            // Optionally show user-facing error
            // toast.error('Failed to analyze community. Please try again later.');
        } finally {
            setAnalyzingCommunity(null);
        }
    };

    const handleFilterChange = useCallback((newFilters: FilterState) => {
      setActiveFilters(newFilters);
      // Force re-render of community cards
      setSortUpdateTrigger(prev => prev + 1);
    }, []);

    const createFocusedNodeCommunity = useCallback((node: Node) => {
  // Get first-hop connections that match the current filters
  const nodeConnections = links.filter(link => {
    const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
    const targetId = typeof link.target === 'string' ? link.target : link.target.id;
    
    // Check if the link connects to our node AND matches active filters
    return (sourceId === node.id || targetId === node.id) && 
           activeFilters.connectionTypes.includes(link.type);
  });

  // Get connected nodes (only those with selected connection types)
  const connectedNodes = new Set<Node>();
  connectedNodes.add(node); // Always include the selected node

  nodeConnections.forEach(link => {
    const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
    const targetId = typeof link.target === 'string' ? link.target : link.target.id;
    
    const connectedId = sourceId === node.id ? targetId : sourceId;
    const connectedNode = nodes.find(n => n.id === connectedId);
    
    if (connectedNode && !userEmails.includes(connectedNode.id)) {
      connectedNodes.add(connectedNode);
    }
  });

  // Calculate metrics based on filtered connections
  const density = NetworkAnalysis.calculateDensity(
    Array.from(connectedNodes).length,
    nodeConnections.filter(link => {
      const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
      const targetId = typeof link.target === 'string' ? link.target : link.target.id;
      return !userEmails.includes(sourceId) && !userEmails.includes(targetId);
    })
  );

  // Generate word cloud from filtered connection topics
  const wordCloud = nodeConnections
    .filter(link => {
      const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
      const targetId = typeof link.target === 'string' ? link.target : link.target.id;
      return !userEmails.includes(sourceId) && !userEmails.includes(targetId);
    })
    .flatMap(link => link.topics || [])
    .reduce((acc, topic) => {
      const existing = acc.find(t => t.word === topic.word);
      if (existing) {
        existing.count += topic.count;
        existing.score = Math.max(existing.score, topic.score);
      } else {
        acc.push({ ...topic });
      }
      return acc;
    }, [] as TopicWord[])
    .sort((a, b) => b.count - a.count);

  return {
    id: `focused-${node.id}`,
    nodes: Array.from(connectedNodes),
    links: nodeConnections,
    wordCloud,
    density,
    cohesionScore: NetworkAnalysis.calculateCohesionScore(nodeConnections)
  };
}, [nodes, links, userEmails, activeFilters.connectionTypes]);


    const communityAnalysis = useMemo(() => {
        return WordCloudAnalyzer.analyzeCommunities(nodes, links, userEmails);
    }, [nodes, links]);

    const handleNodeClick = useCallback((node: Node) => {
    setSelectedNode(node);
    onNodeClick?.(node);

    const focusedCommunity = createFocusedNodeCommunity(node);
    const communityId = `focused-${node.id}`;

    // Get the current active filters
    const currentFilters = activeFilters || defaultFilters;
    
    // Filter nodes based on their connections and current filter settings
    const filteredFocusedNodes = focusedCommunity.nodes.filter(n => {
        // Don't filter out the selected node
        if (n.id === node.id) return true;

        // Check if the node has any connections that match current filters
        const hasValidConnection = focusedCommunity.links.some(link => {
            const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
            const targetId = typeof link.target === 'string' ? link.target : link.target.id;
            
            // Check if this link connects to our node and matches filter criteria
            const isConnectedToNode = (sourceId === n.id || targetId === n.id);
            const matchesConnectionType = currentFilters.connectionTypes.includes(link.type);
            
            return isConnectedToNode && matchesConnectionType;
        });

        return hasValidConnection;
    });

    // Filter links based on current connection type filters
    const filteredFocusedLinks = focusedCommunity.links.filter(link => {
        const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
        const targetId = typeof link.target === 'string' ? link.target : link.target.id;
        
        // Only keep links where both nodes are in our filtered set
        const nodesExist = filteredFocusedNodes.some(n => n.id === sourceId) &&
                          filteredFocusedNodes.some(n => n.id === targetId);
        
        // Check if link type matches current filters
        const matchesConnectionType = currentFilters.connectionTypes.includes(link.type);
        
        return nodesExist && matchesConnectionType;
    });

    // Update state with filtered data
    setFilteredNodes(filteredFocusedNodes);
    setFilteredLinks(filteredFocusedLinks);
    setSelectedCommunity(communityId);
    setShowMembers(true);

    // Generate word cloud from filtered connections only
    const filteredWordCloud = filteredFocusedLinks
        .filter(link => {
            const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
            const targetId = typeof link.target === 'string' ? link.target : link.target.id;
            return !userEmails.includes(sourceId) && !userEmails.includes(targetId);
        })
        .flatMap(link => link.topics || [])
        .reduce((acc: TopicWord[], topic) => {
            const existing = acc.find(t => t.word === topic.word);
            if (existing) {
                existing.count += topic.count;
                existing.score = Math.max(existing.score, topic.score);
            } else {
                acc.push({ ...topic });
            }
            return acc;
        }, [])
        .sort((a, b) => b.count - a.count);

    // Only trigger analysis if needed and not already in progress
    if (!communityAnalyses[communityId] && analyzingCommunity !== communityId) {
        analyzeCommunity(
            communityId,
            filteredWordCloud,
            filteredFocusedNodes,
            filteredFocusedLinks
        );
    }

    // Scroll the community card into view
    requestAnimationFrame(() => {
        const card = document.querySelector(`[data-community-id="${communityId}"]`);
        if (card) {
            card.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'center' 
            });
        }
    });

}, [
    onNodeClick,
    createFocusedNodeCommunity,
    communityAnalyses,
    analyzingCommunity,
    analyzeCommunity,
    activeFilters,
    userEmails
]);

    useEffect(() => {
        if (selectedNode) {
            const focusedCommunity = createFocusedNodeCommunity(selectedNode);
            const communityId = focusedCommunity.id;
            
            if (!communityAnalyses[communityId] && analyzingCommunity !== communityId) {
                analyzeCommunity(
                    communityId,
                    focusedCommunity.wordCloud,
                    focusedCommunity.nodes,
                    focusedCommunity.links
                );
            }
        }
    }, [selectedNode, createFocusedNodeCommunity, communityAnalyses, analyzingCommunity]);

    useEffect(() => {
    if (selectedNode) {
        const communityId = `focused-${selectedNode.id}`;
        const card = document.querySelector(`[data-community-id="${communityId}"]`);
        if (card) {
            setTimeout(() => {
                card.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 100);
        }
    }
}, [selectedNode]);


    const calculateClusteringCoefficient = (nodes: Node[], links: Link[]): number => {
        return nodes.reduce((acc, node) => {
            const neighbors = links.filter(l =>
                (typeof l.source === 'string' ? l.source : l.source.id) === node.id ||
                (typeof l.target === 'string' ? l.target : l.target.id) === node.id
            );

            if (neighbors.length < 2) return acc;

            const possibleTriangles = (neighbors.length * (neighbors.length - 1)) / 2;
            const actualTriangles = links.filter(l => {
                const sourceId = typeof l.source === 'string' ? l.source : l.source.id;
                const targetId = typeof l.target === 'string' ? l.target : l.target.id;
                return neighbors.some(n =>
                    (typeof n.source === 'string' ? n.source : n.source.id) === sourceId ||
                    (typeof n.source === 'string' ? n.source : n.source.id) === targetId
                ) && neighbors.some(n =>
                    (typeof n.target === 'string' ? n.target : n.target.id) === sourceId ||
                    (typeof n.target === 'string' ? n.target : n.target.id) === targetId
                );
            }).length;

            return acc + (actualTriangles / possibleTriangles);
        }, 0) / nodes.length;
    };


    const handleCommunitySelect = useCallback((communityId: string | number) => {
    // Toggle selection if already selected
    if (communityId === selectedCommunity) {
        setSelectedCommunity(null);
        setShowMembers(false);
        setFilteredNodes(nodes);
        setFilteredLinks(links);
        return;
    }

    setSelectedCommunity(communityId);
    setShowMembers(true);

    // Find the community data
    const community = communityAnalysis.find(c => String(c.id) === String(communityId));
    
    if (community) {
        // Filter nodes and links for the selected community
        const communityNodes = nodes.filter(n =>
            links.some(l => {
                const sourceId = typeof l.source === 'string' ? l.source : l.source.id;
                const targetId = typeof l.target === 'string' ? l.target : l.target.id;
                return (sourceId === n.id || targetId === n.id) &&
                    l.communities?.includes(Number(communityId));
            })
        );

        const communityLinks = links.filter(l =>
            l.communities?.includes(Number(communityId))
        );

        setFilteredNodes(communityNodes);
        setFilteredLinks(communityLinks);

        // Always trigger analysis for non-focused communities if not already analyzed
        if (!communityAnalyses[communityId] && analyzingCommunity !== communityId) {
            analyzeCommunity(
                communityId,
                community.wordCloud,
                communityNodes,
                communityLinks
            );
        }
    } else if (typeof communityId === 'string' && communityId.startsWith('focused-')) {
        // Handle focused community
        const nodeId = communityId.replace('focused-', '');
        const focusedNode = nodes.find(n => n.id === nodeId);
        if (focusedNode) {
            const focusedCommunity = createFocusedNodeCommunity(focusedNode);
            
            setFilteredNodes(focusedCommunity.nodes);
            setFilteredLinks(focusedCommunity.links);

            if (!communityAnalyses[communityId] && analyzingCommunity !== communityId) {
                analyzeCommunity(
                    communityId,
                    focusedCommunity.wordCloud,
                    focusedCommunity.nodes,
                    focusedCommunity.links
                );
            }
        }
    }

    // Scroll the card into view
    requestAnimationFrame(() => {
        const card = document.querySelector(`[data-community-id="${communityId}"]`);
        if (card) {
            card.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'center' 
            });
        }
    });
}, [
    selectedCommunity,
    nodes,
    links,
    communityAnalysis,
    communityAnalyses,
    analyzingCommunity,
    createFocusedNodeCommunity,
    analyzeCommunity
]);

    interface FocusedCommunity {
          id: string;
          nodes: Node[];
          links: Link[];
          wordCloud: TopicWord[];
          density: number;
      }

    const allCommunities = useMemo(() => {
      const communities = [...communityAnalysis];
      
      if (selectedNode) {
          const focusedCommunity = createFocusedNodeCommunity(selectedNode);
          // Ensure we don't add duplicate focused communities
          if (!communities.some(c => c.id === focusedCommunity.id)) {
              communities.push(focusedCommunity);
          }
      }
      
      return communities;
  }, [communityAnalysis, selectedNode, createFocusedNodeCommunity]);

    const calculateCommunityRelevance = useCallback((community: any, searchTerm: string) => {
        if (!searchTerm) return 0;
        
        const normalizedSearch = searchTerm.toLowerCase();
        let score = 0;

        // Check community analysis
        const analysis = communityAnalyses[community.id];
        if (analysis) {
          // Check label match
          if (analysis.label.toLowerCase().includes(normalizedSearch)) {
            score += 10;
          }

          // Check interests matches
          analysis.summary.interests.forEach(interest => {
            if (interest.toLowerCase().includes(normalizedSearch)) {
              score += 5;
            }
          });

          // Check overview match
          if (analysis.summary.overview.toLowerCase().includes(normalizedSearch)) {
            score += 3;
          }
        }

        // Check word cloud matches
        community.wordCloud.forEach((word: TopicWord) => {
          if (word.word.toLowerCase().includes(normalizedSearch)) {
            score += word.score * 2;
          }
        });

        // Check node matches
        community.nodes.forEach((node: Node) => {
          if (node.id.toLowerCase().includes(normalizedSearch)) {
            score += 4;
          }
        });

        return score;
      }, [communityAnalyses]);

     useEffect(() => {
        if (activeFilters.searchTerm) {
            // Force immediate re-render of container
            const container = document.querySelector('.community-cards-container');
            if (container) {
                // Remove and reattach the container to force a fresh layout
                const parent = container.parentElement;
                const nextSibling = container.nextSibling;
                parent?.removeChild(container);
                requestAnimationFrame(() => {
                    if (nextSibling) {
                        parent?.insertBefore(container, nextSibling);
                    } else {
                        parent?.appendChild(container);
                    }
                    // Force reflow
                   
                    // Update sort trigger
                    setSortUpdateTrigger(Date.now());
                });
            }
        }
    }, [activeFilters.searchTerm]);

    const sortedCommunities = useMemo(() => {
        if (!activeFilters.searchTerm) {
            return allCommunities;
        }

        const sorted = [...allCommunities].sort((a, b) => {
            const aRelevance = calculateCommunityRelevance(a, activeFilters.searchTerm);
            const bRelevance = calculateCommunityRelevance(b, activeFilters.searchTerm);
            
            if (bRelevance === aRelevance) {
                return String(a.id).localeCompare(String(b.id));
            }
            return bRelevance - aRelevance;
        });

        // Force new array reference
        return sorted.map(community => ({
            ...community,
            sortKey: Date.now() // Add a changing key to force re-render
        }));
    }, [
        allCommunities, 
        activeFilters.searchTerm, 
        calculateCommunityRelevance,
        communityAnalyses,
        sortUpdateTrigger
    ]);

    const renderCommunityAnalysis = useCallback(() => {
      return (
        <SidePanel
            initial={{ x: 320 }}
            animate={{ x: 0 }}
            exit={{ x: 320 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
            <CommunityCardsContainer className="community-cards-container">
                <AnimatePresence mode="popLayout">
                    {sortedCommunities.map((community, index) => {

                        const isHighlighted = selectedCommunity === community.id;
                        const analysis = communityAnalyses[community.id];
                        const maxWordCount = Math.max(...community.wordCloud
                            .filter(w => !userEmails.some(email =>
                                w.word.toLowerCase().includes(email.toLowerCase())
                            ))
                            .map(w => w.count));
                        const isFocusedView = typeof community.id === 'string' &&
                            String(community.id).startsWith('focused-');
                        const relevanceScore = calculateCommunityRelevance(community, activeFilters.searchTerm);
                        const hasSearchMatch = relevanceScore > 0;

                        return (
                            <motion.div
                                key={`${community.id}-${sortUpdateTrigger}`}
                                layout
                                layoutId={`community-${community.id}`}
                                data-community-id={community.id}
                                data-relevance-score={relevanceScore}
                                initial={false}
                                animate={isHighlighted ? {
                                    y: -20,
                                    zIndex: 1,
                                    scale: 1.02,
                                    transition: {
                                        type: "spring",
                                        stiffness: 300,
                                        damping: 30
                                    }
                                } : {
                                    y: 0,
                                    zIndex: 0,
                                    scale: 1,
                                    transition: {
                                        type: "spring",
                                        stiffness: 300,
                                        damping: 30
                                    }
                                }}
                                style={{
                                    cursor: 'pointer',
                                    position: 'relative',
                                    marginBottom: '20px',
                                    opacity: activeFilters.searchTerm && !hasSearchMatch ? 0.5 : 1,
                                    order: activeFilters.searchTerm ? 
                                        -Math.round(relevanceScore * 1000) : // Multiply for better precision
                                        index,
                                    transform: `translateY(${isHighlighted ? -20 : 0}px)`,
                                    zIndex: isHighlighted ? 2 : 1,
                                }}
                                onClick={() => handleCommunitySelect(community.id)}
                            >
                                <CommunityCard
                                    isHighlighted={isHighlighted || hasSearchMatch}
                                    style={{
                                        position: 'relative',
                                        zIndex: isHighlighted ? 2 : 1,
                                        border: hasSearchMatch ? '2px solid #0071e3' : 
                                               isFocusedView ? '2px solid #6366f1' : undefined,
                                        background: hasSearchMatch ? '#f0f7ff' : 'white'
                                    }}
                                >
                                    {hasSearchMatch && activeFilters.searchTerm && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: -10,
                                                right: -10,
                                                background: '#0071e3',
                                                color: 'white',
                                                borderRadius: '50%',
                                                width: 24,
                                                height: 24,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontSize: '12px',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {Math.round(relevanceScore)}
                                        </div>
                                    )}

                                    <CommunityHeader>
                                        <motion.div
                                            animate={{
                                                color: isHighlighted ? '#0071e3' : '#333'
                                            }}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <AnimatePresence mode="wait">
                                                {analyzingCommunity === community.id ? (
                                                    <motion.div
                                                        key="loading"
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '8px',
                                                            fontSize: '14px',
                                                            color: '#666'
                                                        }}
                                                    >
                                                        <Progress progress={100} />
                                                        <span>
                                                            {isFocusedView ?
                                                                'Analyzing Focused Community...' :
                                                                `Analyzing Community ${community.id}...`}
                                                        </span>
                                                    </motion.div>
                                                ) : (
                                                    <motion.h3
                                                        key="title"
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                        style={{
                                                            margin: 0,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '8px'
                                                        }}
                                                    >
                                                        {analysis ? (
                                                            <motion.span
                                                                initial={{ opacity: 0 }}
                                                                animate={{ opacity: 1 }}
                                                                transition={{ duration: 0.3 }}
                                                            >
                                                                {analysis.label}
                                                            </motion.span>
                                                        ) : (
                                                            isFocusedView ?
                                                                `Connections for ${selectedNode?.id}` :
                                                                `Community ${community.id}`
                                                        )}
                                                    </motion.h3>
                                                )}
                                            </AnimatePresence>
                                        </motion.div>
                                    </CommunityHeader>

                                    <AnimatePresence mode="wait">
                                        {analysis && (
                                            <motion.div
                                                initial={{ opacity: 0, height: 0 }}
                                                animate={{ opacity: 1, height: 'auto' }}
                                                exit={{ opacity: 0, height: 0 }}
                                                transition={{ duration: 0.3 }}
                                                style={{ padding: '16px' }}
                                            >
                                                <motion.div
                                                    initial={{ y: 20, opacity: 0 }}
                                                    animate={{ y: 0, opacity: 1 }}
                                                    transition={{ delay: 0.1 }}
                                                    style={{
                                                        marginBottom: '16px',
                                                        lineHeight: '1.6',
                                                        color: '#333'
                                                    }}
                                                >
                                                    {analysis.summary.overview}
                                                </motion.div>

                                                {analysis.summary.interests.length > 0 && (
                                                    <motion.div
                                                        initial={{ y: 20, opacity: 0 }}
                                                        animate={{ y: 0, opacity: 1 }}
                                                        transition={{ delay: 0.2 }}
                                                        style={{ marginBottom: '16px' }}
                                                    >
                                                        <strong style={{
                                                            display: 'block',
                                                            marginBottom: '8px',
                                                            color: '#444'
                                                        }}>
                                                            Key Interests
                                                        </strong>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            gap: '8px'
                                                        }}>
                                                            {analysis.summary.interests.map((interest, i) => (
                                                                <CategoryTag
                                                                    key={i}
                                                                    style={{
                                                                        background: isHighlighted ?
                                                                            (isFocusedView ? '#E0E7FF' : '#E3F2FD') :
                                                                            '#F4F3FF'
                                                                    }}
                                                                >
                                                                    {interest}
                                                                </CategoryTag>
                                                            ))}
                                                        </div>
                                                    </motion.div>
                                                )}

                                                {analysis.summary.geography && (
                                                    <motion.div
                                                        initial={{ y: 20, opacity: 0 }}
                                                        animate={{ y: 0, opacity: 1 }}
                                                        transition={{ delay: 0.3 }}
                                                        style={{ marginBottom: '16px' }}
                                                    >
                                                        <strong style={{
                                                            display: 'block',
                                                            marginBottom: '8px',
                                                            color: '#444'
                                                        }}>
                                                            Geographic Focus
                                                        </strong>
                                                        <div style={{
                                                            color: '#666',
                                                            lineHeight: '1.5'
                                                        }}>
                                                            {analysis.summary.geography}
                                                        </div>
                                                    </motion.div>
                                                )}

                                                {analysis.summary.collaboration.length > 0 && (
                                                    <motion.div
                                                        initial={{ y: 20, opacity: 0 }}
                                                        animate={{ y: 0, opacity: 1 }}
                                                        transition={{ delay: 0.4 }}
                                                        style={{ marginBottom: '16px' }}
                                                    >
                                                        <strong style={{
                                                            display: 'block',
                                                            marginBottom: '8px',
                                                            color: '#444'
                                                        }}>
                                                            Collaboration Areas
                                                        </strong>
                                                        <ul style={{
                                                            margin: '8px 0',
                                                            paddingLeft: '20px',
                                                            color: '#666'
                                                        }}>
                                                            {analysis.summary.collaboration.map((area, i) => (
                                                                <li key={i} style={{ marginBottom: '4px' }}>
                                                                    {area}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </motion.div>
                                                )}

                                                <motion.div
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    transition={{ delay: 0.5 }}
                                                >
                                                    <ConfidenceScore score={analysis.confidence}>
                                                        Analysis Confidence: {(analysis.confidence * 100).toFixed(1)}%
                                                    </ConfidenceScore>
                                                </motion.div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>

                                    <MetricsGrid>
                                        <MetricBox>
                                            <div className="label">Members</div>
                                            <div className="value">
                                                {community.nodes.filter(n =>
                                                    !userEmails.includes(n.id)
                                                ).length}
                                            </div>
                                        </MetricBox>
                                        <MetricBox>
                                            <div className="label">Connections</div>
                                            <div className="value">
                                                {community.links.filter(l => {
                                                    const sourceId = typeof l.source === 'string' ? l.source : l.source.id;
                                                    const targetId = typeof l.target === 'string' ? l.target : l.target.id;
                                                    return !userEmails.includes(sourceId) &&
                                                        !userEmails.includes(targetId);
                                                }).length}
                                            </div>
                                        </MetricBox>
                                        <MetricBox>
                                            <div className="label">Density</div>
                                            <div className="value">
                                                {(community.density * 100).toFixed(1)}%
                                            </div>
                                        </MetricBox>
                                    </MetricsGrid>

                                    <AnimatePresence>
                                        {showMembers && isHighlighted && (
                                            <CommunityMembers
                                                initial={{ opacity: 0, height: 0 }}
                                                animate={{ opacity: 1, height: 'auto' }}
                                                exit={{ opacity: 0, height: 0 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <strong style={{
                                                    display: 'block',
                                                    marginBottom: '8px',
                                                    color: '#444'
                                                }}>
                                                    Community Members ({community.nodes.filter(n =>
                                                        !userEmails.includes(n.id)
                                                    ).length})
                                                </strong>
                                                <MemberList>
                                                    {community.nodes
                                                        .filter(node => !userEmails.includes(node.id))
                                                        .map((node, index) => (
                                                            <MemberItem
                                                                key={node.id}
                                                                as={motion.div}
                                                                initial={{ opacity: 0, y: 10 }}
                                                                animate={{ opacity: 1, y: 0 }}
                                                                transition={{ delay: index * 0.05 }}
                                                            >
                                                                <MemberEmail>{node.id}</MemberEmail>
                                                                <MemberGroup>{node.group}</MemberGroup>
                                                            </MemberItem>
                                                        ))}
                                                </MemberList>
                                            </CommunityMembers>
                                        )}
                                    </AnimatePresence>

                                    <AnimatePresence>
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                        >
                                            <CommunityWordCloud
                                                words={community.wordCloud.filter(w =>
                                                    !userEmails.some(email =>
                                                        w.word.toLowerCase().includes(email.toLowerCase())
                                                    )
                                                )}
                                                maxCount={maxWordCount}
                                            />
                                        </motion.div>
                                    </AnimatePresence>
                                </CommunityCard>
                            </motion.div>
                        );
                    })}
                </AnimatePresence>
            </CommunityCardsContainer>
                {allCommunities.length === 0 && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        style={{
                            padding: '20px',
                            textAlign: 'center',
                            color: '#666'
                        }}
                    >
                        No communities found in the current network.
                    </motion.div>
                )}
            </SidePanel>
        );
    }, [
        allCommunities,
        analyzingCommunity,
        communityAnalyses,
        showMembers,
        selectedCommunity,
        selectedNode,
        activeFilters.searchTerm,
        calculateCommunityRelevance,
        handleCommunitySelect,
        userEmails,
        sortUpdateTrigger
    ]);


    return (
        <AnalysisContainer isFullScreen={isFullScreen}>
            <MainView>
                <AnalysisToolbar>
                    <ToolbarButton
                        onClick={() => setDisplayMode('graph')}
                        active={displayMode === 'graph'}
                    >
                        Graph View
                    </ToolbarButton>
                    <ToolbarButton
                        onClick={() => setDisplayMode('statistics')}
                        active={displayMode === 'statistics'}
                    >
                        Statistics
                    </ToolbarButton>
                    <ToolbarButton
                        onClick={() => setShowMetrics(!showMetrics)}
                    >
                        {showMetrics ? 'Hide Analysis' : 'Show Analysis'}
                    </ToolbarButton>
                </AnalysisToolbar>

                {displayMode === 'graph' ? (
                    <SafeForceGraph
                        nodes={selectedCommunity ? filteredNodes : nodes}
                        links={selectedCommunity ? filteredLinks : links}
                        width={width}
                        height={height}
                        onNodeClick={handleNodeClick}
                        filters={activeFilters}
                        onFilterChange={handleFilterChange}
                      />
                ) : (
                    <StatisticsView metrics={{
                        totalNodes: nodes.length,
                        totalLinks: links.length,
                        averageDegree: (2 * links.length) / nodes.length,
                        density: (2 * links.length) / (nodes.length * (nodes.length - 1)),
                        clustering: calculateClusteringCoefficient(nodes, links),
                        communities: communityAnalysis.length
                    }} />
                )}
            </MainView>

            <AnimatePresence>
                {showMetrics && renderCommunityAnalysis()}
            </AnimatePresence>
        </AnalysisContainer>
    );
};

function getConnectionType(interactionScore: number): string {
    if (interactionScore >= 0.1) return 'Strong';
    if (interactionScore >= 0.002) return 'Regular';
    return 'Weak';
}

export default inject("UserStore")(observer(NetworkCohesionAnalysis));