import { NodeData, EdgeData } from '../types/types';
import Logger from 'util/Logger';

// Add declaration for global window properties used in this service
declare global {
  interface Window {
    __selectedNodeTypes?: string[];
    __selectedEdgeTypes?: string[];
    __activeSearchQuery?: string;
    __searchMatchedNodeIds?: string[];
    __maxHops?: number;
    __previousMaxHops?: number;
    __savedHopLevelHistory?: Map<number, Set<string>>;
    __rootNodesByHopLevel?: any;
  }
}

export interface FilterState {
  // Node type filters
  selectedNodeTypes: string[];
  // Edge type filters
  selectedEdgeTypes: string[];
  // Search query
  searchQuery: string;
  // Maximum number of hops from user nodes
  maxHops: number;
  // Additional filters can be added here
}

export class FilterService {
  private originalNodes: NodeData[] = [];
  private originalEdges: EdgeData[] = [];
  private filterState: FilterState = {
    selectedNodeTypes: [],
    selectedEdgeTypes: [],
    searchQuery: '',
    maxHops: 3, // Default to 3 hops
  };
  
  // Track nodes at each hop level - needed for proper maxHops reduction
  private nodesByHopLevel: Map<number, Set<string>> = new Map();

  /**
   * Initialize the filter service with the original data
   */
  initialize(nodes: NodeData[], edges: EdgeData[]): void {
    Logger.log("FilterService: Initializing with nodes:", nodes.length, "edges:", edges.length);
    
    // Store a deep copy of the original data to prevent mutations
    this.originalNodes = nodes.map(node => ({...node}));
    this.originalEdges = edges.map(edge => ({...edge}));
    
    // Initialize selected types with all available types
    this.filterState.selectedNodeTypes = [...new Set(nodes.map(node => node.type))];
    this.filterState.selectedEdgeTypes = [...new Set(edges.map(edge => edge.type))];
    this.filterState.searchQuery = '';
    this.filterState.maxHops = 2; // Default to 2 hops for better connection visibility
    
    // Store these globally so components can access them
    (window as any).__selectedNodeTypes = this.filterState.selectedNodeTypes;
    (window as any).__selectedEdgeTypes = this.filterState.selectedEdgeTypes;
    (window as any).__maxHops = this.filterState.maxHops;
    
    Logger.log("FilterService: Initialized with node types:", this.filterState.selectedNodeTypes);
    Logger.log("FilterService: Initialized with edge types:", this.filterState.selectedEdgeTypes);
  }

  /**
   * Set the node type filters
   */
  setNodeTypeFilters(nodeTypes: string[]): void {
    Logger.log(`FilterService: Setting node type filters from [${this.filterState.selectedNodeTypes.join(', ')}] to [${nodeTypes.join(', ')}]`);
    this.filterState.selectedNodeTypes = nodeTypes;
    // Update global variable
    (window as any).__selectedNodeTypes = nodeTypes;
  }

  /**
   * Set the edge type filters
   */
  setEdgeTypeFilters(edgeTypes: string[]): void {
    Logger.log(`FilterService: Setting edge type filters from [${this.filterState.selectedEdgeTypes.join(', ')}] to [${edgeTypes.join(', ')}]`);
    this.filterState.selectedEdgeTypes = edgeTypes;
    // Update global variable
    (window as any).__selectedEdgeTypes = edgeTypes;
  }

  /**
   * Set the search query
   */
  setSearchQuery(query: string): void {
    Logger.log(`FilterService: Setting search query from "${this.filterState.searchQuery}" to "${query}"`);
    
    // Only reset hop history if this is actually a new search, not just setting the same query again
    if (this.filterState.searchQuery !== query) {
      Logger.log("FilterService: New search query - resetting hop history");
      this.resetHopHistory();
    }
    
    this.filterState.searchQuery = query;
    
    // Update global search query for node label display
    window.__activeSearchQuery = query;
    Logger.log('FilterService: Setting global __activeSearchQuery =', query);
    
    // If query is empty, also clear matched node IDs
    if (!query || query.trim() === '') {
      window.__searchMatchedNodeIds = [];
      Logger.log('FilterService: Clearing global __searchMatchedNodeIds');
    }
  }
  
  /**
   * Set the maximum number of hops from user nodes
   */
  setMaxHops(hops: number): void {
    // Store the previous value for potential hop reduction detection
    const previousMaxHops = this.filterState.maxHops;
    Logger.log(`FilterService: Setting max hops from ${previousMaxHops} to ${hops}`);
    
    // Update global variable to track change direction (increasing or decreasing)
    (window as any).__previousMaxHops = previousMaxHops;
    
    // Set the new maxHops value
    this.filterState.maxHops = hops;
    
    // Update global variable with new value
    (window as any).__maxHops = hops;
    
    // Save the current hop history to window if changing hops
    if (this.nodesByHopLevel.size > 0 && previousMaxHops !== hops) {
      // Deep clone the hop history to prevent it from being modified
      const historyClone = new Map();
      
      this.nodesByHopLevel.forEach((nodeSet, hopLevel) => {
        // Clone each Set to keep them isolated
        historyClone.set(hopLevel, new Set(nodeSet));
      });
      
      (window as any).__savedHopLevelHistory = historyClone;
      Logger.log(`FilterService: Saved hop history with ${historyClone.size} levels for rollback support`);
    }
    
    // Log if we're reducing or expanding
    if (previousMaxHops > hops) {
      Logger.log(`FilterService: REDUCING maxHops from ${previousMaxHops} to ${hops}`);
    } else if (previousMaxHops < hops) {
      Logger.log(`FilterService: EXPANDING maxHops from ${previousMaxHops} to ${hops}`);
    }
  }

  /**
   * Reset all filters to their default state
   */
  resetFilters(): void {
    Logger.log("FilterService: Resetting all filters");
    
    // Reset to all available types
    this.filterState.selectedNodeTypes = [
      ...new Set(this.originalNodes.map(node => node.type))
    ];
    this.filterState.selectedEdgeTypes = [
      ...new Set(this.originalEdges.map(edge => edge.type))
    ];
    
    // Update global variables
    (window as any).__selectedNodeTypes = this.filterState.selectedNodeTypes;
    (window as any).__selectedEdgeTypes = this.filterState.selectedEdgeTypes;
    
    // Clear search query
    this.filterState.searchQuery = '';
    
    // Clear search-related global variables
    window.__activeSearchQuery = '';
    window.__searchMatchedNodeIds = [];
    
    // Reset max hops to default
    this.filterState.maxHops = 3;
    
    // Reset hop level history
    this.resetHopHistory();
    
    Logger.log("FilterService: Filters reset to:", this.filterState);
  }
  
  /**
   * Reset the hop history when starting a new query or resetting filters
   * This should only be called when the user performs a search, voice query,
   * or explicitly resets filters
   */
  resetHopHistory(): void {
    Logger.log("FilterService: Resetting hop history");
    this.nodesByHopLevel.clear();
    
    // Also clear any global hop history
    delete (window as any).__rootNodesByHopLevel;
  }

  /**
   * Get the current filter state
   */
  getFilterState(): FilterState {
    return { ...this.filterState };
  }

  /**
   * Apply all filters and return the filtered data
   */
  applyFilters(): { nodes: NodeData[]; edges: EdgeData[] } {
    Logger.log("FilterService: applyFilters called with state:", this.filterState);
    Logger.log("FilterService: original data counts - nodes:", this.originalNodes.length, "edges:", this.originalEdges.length);
    
    // Extract user nodes that should always be preserved
    const userNodes = this.originalNodes.filter(node => node.user === true);
    const userNodeIds = new Set(userNodes.map(n => n.id));
    
    // Check for empty node or edge type filters which would result in no data
    if (this.filterState.selectedNodeTypes.length === 0 || this.filterState.selectedEdgeTypes.length === 0) {
      Logger.log("FilterService: No node or edge types selected, returning only user nodes if any");
      // Instead of returning empty results, return at least user nodes
      return { 
        nodes: userNodes, 
        edges: [] 
      };
    }
    
    let filteredNodes: NodeData[] = [];
    let searchMatchedNodeIds = new Set<string>();
    
    // If we have a search query, apply it first to the whole data set
    if (this.filterState.searchQuery && this.filterState.searchQuery.trim() !== '') {
      Logger.log(`FilterService: Applying search query "${this.filterState.searchQuery}" to original nodes`);
      // Search all nodes first
      const searchFilteredNodes = this.applySearchFilter(this.originalNodes);
      Logger.log("FilterService: after search filtering - nodes:", searchFilteredNodes.length);
      
      // Store IDs of nodes that matched the search before we apply node type filters
      searchMatchedNodeIds = new Set(searchFilteredNodes
        .filter(node => !node.user) // Exclude user nodes
        .map(node => node.id));
      
      // Store search matched node IDs globally for label display
      window.__activeSearchQuery = this.filterState.searchQuery;
      window.__searchMatchedNodeIds = Array.from(searchMatchedNodeIds);
      Logger.log(`FilterService: Updated global __searchMatchedNodeIds with ${window.__searchMatchedNodeIds.length} matched nodes for query "${window.__activeSearchQuery}"`);
      
      // Debug: Log a few node IDs for reference
      if (window.__searchMatchedNodeIds.length > 0) {
        Logger.log('FilterService: Sample matched node IDs:', window.__searchMatchedNodeIds.slice(0, 5));
      }
      
      Logger.log(`FilterService: Tracking ${searchMatchedNodeIds.size} nodes that matched search criteria`);
      
      // Then apply node type filter to search results
      filteredNodes = this.applyNodeTypeFilters(searchFilteredNodes);
      Logger.log("FilterService: after node type filtering on search results - nodes:", filteredNodes.length);
    } else {
      // No search query, just apply node type filtering
      filteredNodes = this.applyNodeTypeFilters(this.originalNodes);
      Logger.log("FilterService: after node type filtering - nodes:", filteredNodes.length);
    }
    
    // Get a set of all filtered node IDs for edge filtering
    const filteredNodeIds = new Set(filteredNodes.map(n => n.id));
    Logger.log("FilterService: filtered node IDs set size:", filteredNodeIds.size);
    
    // Apply edge type filters
    let filteredEdges = this.applyEdgeTypeFilters(this.originalEdges);
    Logger.log("FilterService: after edge type filtering - edges:", filteredEdges.length);
    
    // Keep only edges that connect nodes that survived both filters
    const beforeConnectionFilterCount = filteredEdges.length;
    filteredEdges = filteredEdges.filter(edge => {
      const sourceId = typeof edge.source === 'object' && edge.source !== null 
        ? (edge.source as { id: string }).id 
        : edge.source as string;
        
      const targetId = typeof edge.target === 'object' && edge.target !== null 
        ? (edge.target as { id: string }).id 
        : edge.target as string;
        
      return filteredNodeIds.has(sourceId) && filteredNodeIds.has(targetId);
    });
    Logger.log("FilterService: after connection filtering - edges:", filteredEdges.length, "(from", beforeConnectionFilterCount, ")");
    
    // User nodes were extracted earlier, log them now
    Logger.log("FilterService: user nodes count:", userNodes.length);
    
    // Check if we have an active search filter
    const hasActiveSearchQuery = this.filterState.searchQuery && this.filterState.searchQuery.trim() !== '';
    
    // Track matching communities for enhanced search
    const matchingCommunities = new Set<number>();
    if (hasActiveSearchQuery) {
      for (const node of filteredNodes) {
        if (node.properties && node.properties.community_topics) {
          for (const communityTopic of node.properties.community_topics) {
            if (communityTopic.community_id && 
                this.communityTopicMatchesTerm(communityTopic, this.filterState.searchQuery)) {
              matchingCommunities.add(communityTopic.community_id);
            }
          }
        }
      }
      if (matchingCommunities.size > 0) {
        Logger.log(`FilterService: Found ${matchingCommunities.size} communities matching search criteria`);
      }
    }
    
    if (userNodes.length > 0) {
      const userNodeIds = new Set(userNodes.map(n => n.id));
      
      // For searches, be more selective about including user nodes
      if (hasActiveSearchQuery) {
        Logger.log("FilterService: search mode - limiting user node handling");
        
        // Only add the user node if it's not already included
        const missingUserNodes = userNodes.filter(
          node => !filteredNodes.some(n => n.id === node.id)
        );
        
        if (missingUserNodes.length > 0) {
          Logger.log("FilterService: adding missing user nodes in search mode:", missingUserNodes.length);
          filteredNodes = [...filteredNodes, ...missingUserNodes];
        }
      } else {
        // In normal filtering mode, always include user nodes
        const missingUserNodes = userNodes.filter(
          node => !filteredNodes.some(n => n.id === node.id)
        );
        
        if (missingUserNodes.length > 0) {
          Logger.log("FilterService: adding missing user nodes:", missingUserNodes.length);
          filteredNodes = [...filteredNodes, ...missingUserNodes];
        }
      }
      
      // Find edges connected to user nodes that should also be kept
      const userConnectedEdges = this.originalEdges.filter(edge => {
        // First check that this edge passes the edge type filter
        if (!this.filterState.selectedEdgeTypes.includes(edge.type)) {
          return false;
        }
        
        const sourceId = typeof edge.source === 'object' && edge.source !== null 
          ? (edge.source as { id: string }).id 
          : edge.source as string;
          
        const targetId = typeof edge.target === 'object' && edge.target !== null 
          ? (edge.target as { id: string }).id 
          : edge.target as string;
        
        // Keep edges connected to user nodes
        const isUserEdge = userNodeIds.has(sourceId) || userNodeIds.has(targetId);
        
        // Don't duplicate edges
        const isDuplicate = filteredEdges.some(e => e.id === edge.id);
        
        // If we're doing a search, we need to be more strict  
        if (hasActiveSearchQuery) {
          // When searching, keep edges:
          // 1. Between nodes in the filtered set
          // 2. From a user node to a node in the filtered set
          // 3. Between nodes that are in a community that matched the search
          const sourceNode = userNodeIds.has(sourceId) ? true : filteredNodeIds.has(sourceId);
          const targetNode = userNodeIds.has(targetId) ? true : filteredNodeIds.has(targetId);
          
          // If we have matching communities, check if these nodes belong to any of them
          const hasMatchingCommunities = matchingCommunities && matchingCommunities.size > 0;
          const isInMatchingCommunity = () => {
            // If we don't have matching communities, this doesn't apply
            if (!hasMatchingCommunities) return false;
            
            // Find the source and target nodes
            const sourceNode = this.originalNodes.find(n => n.id === sourceId);
            const targetNode = this.originalNodes.find(n => n.id === targetId);
            
            // Check if either node has a community property that matches any of our matching communities
            if (sourceNode && sourceNode.community !== undefined && 
                matchingCommunities.has(Number(sourceNode.community))) {
              return true;
            }
            
            if (targetNode && targetNode.community !== undefined && 
                matchingCommunities.has(Number(targetNode.community))) {
              return true;
            }
            
            return false;
          };
          
          // For search, keep edge if it's a user edge not already included AND:
          // 1. At least one end is in the filtered set OR
          // 2. It's between nodes in a community that matched the search
          return isUserEdge && !isDuplicate && 
                 ((sourceNode || targetNode) || isInMatchingCommunity());
        }
        
        return isUserEdge && !isDuplicate;
      });
      
      // Add user-connected edges
      if (userConnectedEdges.length > 0) {
        Logger.log("FilterService: adding user-connected edges:", userConnectedEdges.length);
        filteredEdges = [...filteredEdges, ...userConnectedEdges];
      }
      
      // Add nodes from matching communities if we're searching
      if (hasActiveSearchQuery && matchingCommunities.size > 0) {
        const communityNodeIds = new Set(filteredNodes.map(n => n.id));
        const communityNodes = this.originalNodes.filter(node => 
          // Node isn't already included
          !communityNodeIds.has(node.id) &&
          // Node belongs to a matching community
          node.community !== undefined && 
          matchingCommunities.has(Number(node.community))
        );
        
        if (communityNodes.length > 0) {
          Logger.log(`FilterService: Adding ${communityNodes.length} additional nodes from matching communities`);
          filteredNodes = [...filteredNodes, ...communityNodes];
          
          // Update the filtered node IDs set
          const newFilteredNodeIds = new Set(filteredNodes.map(node => node.id));
          
          // Find edges connecting these community nodes
          const communityEdges = this.originalEdges.filter(edge => {
            // Skip edges that don't pass edge type filter
            if (!this.filterState.selectedEdgeTypes.includes(edge.type)) {
              return false;
            }
            
            const sourceId = typeof edge.source === 'object' && edge.source !== null 
              ? (edge.source as { id: string }).id 
              : edge.source as string;
              
            const targetId = typeof edge.target === 'object' && edge.target !== null 
              ? (edge.target as { id: string }).id 
              : edge.target as string;
            
            // Check if both nodes are in the filtered set and edge isn't already included
            const sourceInFiltered = newFilteredNodeIds.has(sourceId);
            const targetInFiltered = newFilteredNodeIds.has(targetId);
            const isDuplicate = filteredEdges.some(e => e.id === edge.id);
            
            return sourceInFiltered && targetInFiltered && !isDuplicate;
          });
          
          if (communityEdges.length > 0) {
            Logger.log(`FilterService: Adding ${communityEdges.length} edges connecting community nodes`);
            filteredEdges = [...filteredEdges, ...communityEdges];
          }
        }
      }
      
      // Add nodes connected to user nodes through these edges
      const userConnectedNodeIds = new Set<string>();
      
      userConnectedEdges.forEach(edge => {
        const sourceId = typeof edge.source === 'object' && edge.source !== null 
          ? (edge.source as { id: string }).id 
          : edge.source as string;
          
        const targetId = typeof edge.target === 'object' && edge.target !== null 
          ? (edge.target as { id: string }).id 
          : edge.target as string;
        
        if (userNodeIds.has(sourceId)) {
          userConnectedNodeIds.add(targetId);
        } else if (userNodeIds.has(targetId)) {
          userConnectedNodeIds.add(sourceId);
        }
      });
      
      // Add missing connected nodes, but only if they match the node type filter
      let missingConnectedNodes: NodeData[] = [];
      
      if (hasActiveSearchQuery) {
        // When searching, only match nodes that satisfy both the search filter AND are connected to user nodes
        const searchQuery = this.filterState.searchQuery.trim().toLowerCase();
        
        missingConnectedNodes = this.originalNodes.filter(node => {
          // Check if the node is connected to the user node
          if (!userConnectedNodeIds.has(node.id) || !this.filterState.selectedNodeTypes.includes(node.type)) {
            return false;
          }
          
          // Check if the node matches the search filter
          if (node.user) return true;
          
          // Check label first (most common match)
          if (node.label.toLowerCase().includes(searchQuery)) return true;
          
          // Check node type
          if (node.type.toLowerCase().includes(searchQuery)) return true;
          
          // Simplified property search - convert to string and search
          if (node.properties) {
            const propertiesString = JSON.stringify(node.properties).toLowerCase();
            if (propertiesString.includes(searchQuery)) return true;
          }
          
          // Check node ID
          if (node.id && node.id.toString().toLowerCase().includes(searchQuery)) return true;
          
          // Check centrality
          if (node.centrality) {
            const centralityString = JSON.stringify(node.centrality).toLowerCase();
            if (centralityString.includes(searchQuery)) return true;
          }
          
          // Check importance
          if (node.importance !== undefined && 
              String(node.importance).toLowerCase().includes(searchQuery)) {
            return true;
          }
          
          // Check community
          if (node.community !== undefined && 
              String(node.community).toLowerCase().includes(searchQuery)) {
            return true;
          }
          
          // Only include the node if it passes any of the search filters
          return false;
        });
        
        Logger.log("FilterService: searching connected nodes, found matching:", missingConnectedNodes.length);
      } else {
        // For regular filtering, include all connected nodes that match the node type filter
        missingConnectedNodes = this.originalNodes.filter(
          node => userConnectedNodeIds.has(node.id) && 
                !filteredNodes.some(n => n.id === node.id) && 
                this.filterState.selectedNodeTypes.includes(node.type)
        );
      }
      
      // For search mode, we need to be stricter about which nodes we add back
      if (missingConnectedNodes.length > 0) {
        if (hasActiveSearchQuery) {
          Logger.log("FilterService: search mode - checking missing connected nodes:", missingConnectedNodes.length);
          
          // In search mode, only add nodes that match the search - use the same logic as in applySearchFilter
          const searchQuery = this.filterState.searchQuery.trim().toLowerCase();
          const matchingConnectedNodes = missingConnectedNodes.filter(node => {
            // Always include user nodes
            if (node.user) return true;
            
            // Check if node label matches search query
            if (node.label.toLowerCase().includes(searchQuery)) return true;
            
            // Check node type
            if (node.type.toLowerCase().includes(searchQuery)) return true;
            
            // Simplified property search - convert to string and search
            if (node.properties) {
              const propertiesString = JSON.stringify(node.properties).toLowerCase();
              if (propertiesString.includes(searchQuery)) return true;
            }
            
            // Check node ID
            if (node.id && node.id.toString().toLowerCase().includes(searchQuery)) return true;
            
            // Check centrality
            if (node.centrality) {
              const centralityString = JSON.stringify(node.centrality).toLowerCase();
              if (centralityString.includes(searchQuery)) return true;
            }
            
            // Check importance
            if (node.importance !== undefined && 
                String(node.importance).toLowerCase().includes(searchQuery)) {
              return true;
            }
            
            // Check community
            if (node.community !== undefined && 
                String(node.community).toLowerCase().includes(searchQuery)) {
              return true;
            }
            
            return false;
          });
          
          Logger.log("FilterService: adding matching connected nodes in search mode:", matchingConnectedNodes.length);
          filteredNodes = [...filteredNodes, ...matchingConnectedNodes];
        } else {
          // In normal filter mode, add all connected nodes
          Logger.log("FilterService: adding missing connected nodes:", missingConnectedNodes.length);
          filteredNodes = [...filteredNodes, ...missingConnectedNodes];
        }
      }
    }
    
    Logger.log("FilterService: filtered data before hop filtering - nodes:", filteredNodes.length, "edges:", filteredEdges.length);
    
    // Check if we're expanding from a specific set of nodes (like after a voice query)
    const currentFilteredNodes = (window as any).__currentFilteredNodes as NodeData[] | undefined;
    const isExpandingExistingView = currentFilteredNodes && currentFilteredNodes.length > 0;
    
    // Apply hop filtering - if we have current filtered nodes, we'll use them as our starting point
    if (this.filterState.maxHops > 0) {
      // CRITICAL: Make sure we have the correct node and edge type selections
      // Sometimes these are stored in window object during transitions
      if ((window as any).__selectedNodeTypes && (window as any).__selectedNodeTypes.length > 0) {
        this.filterState.selectedNodeTypes = [...(window as any).__selectedNodeTypes];
        Logger.log(`FilterService: Using globally stored node types for filtering: ${this.filterState.selectedNodeTypes.join(', ')}`);
      }
      
      if ((window as any).__selectedEdgeTypes && (window as any).__selectedEdgeTypes.length > 0) {
        this.filterState.selectedEdgeTypes = [...(window as any).__selectedEdgeTypes];
        Logger.log(`FilterService: Using globally stored edge types for filtering: ${this.filterState.selectedEdgeTypes.join(', ')}`);
      }
      
      // Log detailed information about the current state
      Logger.log(`FilterService: Applying hop filtering with maxHops=${this.filterState.maxHops}`);
      Logger.log(`FilterService: isExpandingExistingView=${isExpandingExistingView}`);
      Logger.log(`FilterService: Current filtered nodes count=${filteredNodes.length}`);
      Logger.log(`FilterService: Selected node types=${this.filterState.selectedNodeTypes.join(', ')}`);
      Logger.log(`FilterService: Selected edge types=${this.filterState.selectedEdgeTypes.join(', ')}`);
      
      // When using the current filtered view as a starting point, we want to:
      // 1. Use the current filtered nodes as the starting points
      // 2. But expand into the entire graph (so we can find new nodes at higher hop distances)
      // 3. While respecting the selected node and edge types
      // This is critical to maintain context between filter changes
      const hopFiltered = this.applyHopFilters(filteredNodes, filteredEdges);
      
      // The applyHopFilters method now respects node and edge type filters internally
      filteredNodes = hopFiltered.nodes;
      filteredEdges = hopFiltered.edges;
      
      Logger.log(`FilterService: After hop filtering (max: ${this.filterState.maxHops}) - nodes: ${filteredNodes.length}, edges: ${filteredEdges.length}`);
      
      // After we're done, clear the current filtered nodes so we don't accidentally use them again
      // unless explicitly set
      if (isExpandingExistingView) {
        delete (window as any).__currentFilteredNodes;
      }
    }
    
    // If we have search-matched nodes and search is active, include their one-hop neighbors
    if (hasActiveSearchQuery && searchMatchedNodeIds.size > 0) {
      Logger.log("FilterService: Adding one-hop neighbors of search-matched nodes");
      
      // Create a map of node connections for quick lookup
      const nodeConnections = new Map<string, Set<string>>();
      
      // Build adjacency list from all edges of selected types
      this.originalEdges.forEach(edge => {
        if (!this.filterState.selectedEdgeTypes.includes(edge.type)) {
          return; // Skip edges that don't pass the edge type filter
        }
        
        const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source as string;
        const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target as string;
        
        // Skip edges involving user nodes as we're excluding those
        if (userNodeIds.has(sourceId) || userNodeIds.has(targetId)) {
          return;
        }
        
        // Add source -> target
        if (!nodeConnections.has(sourceId)) {
          nodeConnections.set(sourceId, new Set());
        }
        nodeConnections.get(sourceId)!.add(targetId);
        
        // Add target -> source (graph is undirected)
        if (!nodeConnections.has(targetId)) {
          nodeConnections.set(targetId, new Set());
        }
        nodeConnections.get(targetId)!.add(sourceId);
      });
      
      // Find all one-hop neighbors of search-matched nodes
      const oneHopNeighborIds = new Set<string>();
      
      searchMatchedNodeIds.forEach(nodeId => {
        const neighbors = nodeConnections.get(nodeId);
        if (neighbors) {
          neighbors.forEach(neighborId => {
            // Don't include user nodes in the one-hop expansion
            if (!userNodeIds.has(neighborId)) {
              oneHopNeighborIds.add(neighborId);
            }
          });
        }
      });
      
      // Remove nodes that are already in the filtered set
      const newNeighborIds = new Set<string>();
      oneHopNeighborIds.forEach(id => {
        if (!filteredNodeIds.has(id)) {
          newNeighborIds.add(id);
        }
      });
      
      if (newNeighborIds.size > 0) {
        Logger.log(`FilterService: Found ${newNeighborIds.size} additional one-hop neighbors to include`);
        
        // Find the corresponding node objects
        const oneHopNeighborNodes = this.originalNodes.filter(node => 
          newNeighborIds.has(node.id) && 
          this.filterState.selectedNodeTypes.includes(node.type)
        );
        
        if (oneHopNeighborNodes.length > 0) {
          Logger.log(`FilterService: Adding ${oneHopNeighborNodes.length} one-hop neighbors to filtered nodes`);
          filteredNodes = [...filteredNodes, ...oneHopNeighborNodes];
          
          // Update filtered node IDs set
          const updatedFilteredNodeIds = new Set(filteredNodes.map(node => node.id));
          
          // Find edges that connect to these new nodes
          const additionalEdges = this.originalEdges.filter(edge => {
            // Skip edges that don't pass the edge type filter
            if (!this.filterState.selectedEdgeTypes.includes(edge.type)) {
              return false;
            }
            
            const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source as string;
            const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target as string;
            
            // Include edges where both nodes are in the updated filtered set and edge isn't already included
            const isNewEdge = !filteredEdges.some(e => {
              const eSourceId = typeof e.source === 'object' ? (e.source as any).id : e.source as string;
              const eTargetId = typeof e.target === 'object' ? (e.target as any).id : e.target as string;
              return (eSourceId === sourceId && eTargetId === targetId) || (eSourceId === targetId && eTargetId === sourceId);
            });
            
            return isNewEdge && updatedFilteredNodeIds.has(sourceId) && updatedFilteredNodeIds.has(targetId);
          });
          
          if (additionalEdges.length > 0) {
            Logger.log(`FilterService: Adding ${additionalEdges.length} edges connecting one-hop neighbors`);
            filteredEdges = [...filteredEdges, ...additionalEdges];
          }
        }
      }
    }

    // Extra detailed logging for debugging
    if (this.filterState.searchQuery && this.filterState.searchQuery.trim() !== '') {
      const searchQuery = this.filterState.searchQuery.trim();
      Logger.log(`FilterService: Search results for "${searchQuery}":`);
      Logger.log("FilterService: filtered nodes:", filteredNodes.map(n => n.label).join(", "));
      Logger.log("FilterService: filtered node IDs:", filteredNodes.map(n => n.id));
      
      // Check which nodes were excluded by search
      const excludedNodes = this.originalNodes.filter(node => 
        !filteredNodes.some(n => n.id === node.id)
      );
      
      if (excludedNodes.length > 0) {
        Logger.log("FilterService: excluded nodes:", excludedNodes.map(n => n.label).join(", "));
      }
    }
    
    return { nodes: filteredNodes, edges: filteredEdges };
  }

  /**
   * Apply node type filters to the node data
   */
  private applyNodeTypeFilters(nodes: NodeData[]): NodeData[] {
    // Check if we're filtering search results
    const isFilteringSearchResults = this.filterState.searchQuery && this.filterState.searchQuery.trim() !== '';
    
    // Extract user nodes which are always preserved - check in both locations
    const userNodes = nodes.filter(node => 
      node.user === true || (node.properties && node.properties.user === true)
    );
    Logger.log(`FilterService: Found ${userNodes.length} user nodes to always preserve`);
    
    if (this.filterState.selectedNodeTypes.length === 0) {
      // If no node types are selected, return only user nodes
      Logger.log(`FilterService: No node types selected, returning only user nodes`);
      return userNodes;
    }
    
    // Use exactly what the user selected for filtering
    const selectedTypes = this.filterState.selectedNodeTypes;
    Logger.log(`FilterService: Using selected types:`, selectedTypes);
    
    // Extract search matched nodes if we're filtering search results
    // These will be preserved regardless of type
    const searchMatchedNodes: NodeData[] = [];
    let searchPreservedTypes: Record<string, number> = {};
    
    if (isFilteringSearchResults) {
      // For search results, we want to preserve matches regardless of type
      const specialTypes = ['memory', 'email']; // Prioritize content-rich node types
      
      // Get nodes that match search criteria but aren't in selected types
      const searchMatches = nodes.filter(node => 
        // Skip user nodes (already preserved)
        !(node.user === true || (node.properties && node.properties.user === true)) &&
        // Keep nodes that don't match selected types but are of special types
        !selectedTypes.includes(node.type) && 
        specialTypes.includes(node.type)
      );
      
      if (searchMatches.length > 0) {
        searchMatchedNodes.push(...searchMatches);
        
        // Track what types we're preserving for logging
        searchMatches.forEach(node => {
          searchPreservedTypes[node.type] = (searchPreservedTypes[node.type] || 0) + 1;
        });
        
        Logger.log(`FilterService: Preserving ${searchMatches.length} search-matched special type nodes regardless of type filtering`);
        Logger.log(`FilterService: Search-preserved node types:`, JSON.stringify(searchPreservedTypes));
      }
    }
    
    // Filter non-user nodes by selected types
    const filteredNonUserNodes = nodes.filter(node => 
      // Skip user nodes (we'll add them separately regardless of their type)
      !(node.user === true || (node.properties && node.properties.user === true)) && 
      // Skip nodes already included from search preservation
      !searchMatchedNodes.some(n => n.id === node.id) &&
      // Node type must be in the selected types list
      selectedTypes.includes(node.type)
    );
    
    // Log special handling for user nodes with details
    Logger.log(`FilterService: Preserving user nodes regardless of type filtering`);
    // Log actual user nodes to debug
    if (userNodes.length > 0) {
      Logger.log(`FilterService: User nodes being preserved:`, 
        userNodes.map(n => `${n.id} (${n.label}) - user flag in ${n.user ? 'node' : 'properties'}`)
      );
    }
    
    Logger.log(`FilterService: Node type filtering kept ${filteredNonUserNodes.length} non-user nodes`);
    
    // Combine user nodes, search-matched nodes, and filtered non-user nodes, removing duplicates
    const nodeIds = new Set();
    const result = [...userNodes, ...searchMatchedNodes, ...filteredNonUserNodes].filter(node => {
      if (nodeIds.has(node.id)) return false;
      nodeIds.add(node.id);
      return true;
    });
    
    // Log the result for debugging
    Logger.log(`FilterService: Node type filtering result: ${result.length} nodes total`);
    Logger.log(`- ${userNodes.length} user nodes (always preserved)`);
    if (searchMatchedNodes.length > 0) {
      Logger.log(`- ${searchMatchedNodes.length} search-matched special type nodes (preserved regardless of type)`);
    }
    Logger.log(`- ${filteredNonUserNodes.length} non-user nodes (matching selected types)`);
    
    // More detailed debug info for user nodes
    const userNodeTypes: Record<string, number> = {}; 
    userNodes.forEach(node => {
      userNodeTypes[node.type] = (userNodeTypes[node.type] || 0) + 1;
    });
    Logger.log(`FilterService: User node types: ${JSON.stringify(userNodeTypes)}`);
    
    // Log search-preserved node types if we have any
    if (Object.keys(searchPreservedTypes).length > 0) {
      Logger.log(`FilterService: Search-preserved node types: ${JSON.stringify(searchPreservedTypes)}`);
    }
    
    Logger.log(`FilterService: Selected node types: ${JSON.stringify(this.filterState.selectedNodeTypes)}`);
    
    return result;
  }

  /**
   * Apply edge type filters to the edge data
   */
  private applyEdgeTypeFilters(edges: EdgeData[]): EdgeData[] {
    if (this.filterState.selectedEdgeTypes.length === 0) {
      Logger.log("FilterService: No edge types selected, returning empty edge list");
      return [];
    }
    
    // Store the selected edge types globally so other components can access them
    (window as any).__selectedEdgeTypes = this.filterState.selectedEdgeTypes;
    
    // Filter edges by selected types
    const filteredEdges = edges.filter(edge => this.filterState.selectedEdgeTypes.includes(edge.type));
    Logger.log(`FilterService: Edge type filtering kept ${filteredEdges.length} edges out of ${edges.length}`);
    
    return filteredEdges;
  }
  
  /**
   * Filter nodes and edges based on maxHops from user nodes and highlighted nodes
   * This applies maximum distance filtering from both user nodes and any highlighted nodes
   * to ensure we keep the current context when adjusting hop distance
   */
  private applyHopFilters(nodes: NodeData[], edges: EdgeData[]): { nodes: NodeData[]; edges: EdgeData[] } {
    // If maxHops is not set or is set to a high value, no filtering needed
    if (!this.filterState.maxHops || this.filterState.maxHops >= 10) {
      return { nodes, edges };
    }
    
    // Check if we have currently filtered nodes from a voice query or previous filter
    // If so, we'll use these as our base, rather than the entire graph
    const currentFilteredNodes = (window as any).__currentFilteredNodes as NodeData[] | undefined;
    const isExpandingExistingView = currentFilteredNodes && currentFilteredNodes.length > 0;
    
    // Check if we're reducing maxHops (going from a higher value to a lower value)
    const previousMaxHops = (window as any).__previousMaxHops as number | undefined;
    const currentMaxHops = this.filterState.maxHops;
    const isReducingHops = previousMaxHops !== undefined && previousMaxHops > currentMaxHops;
    
    Logger.log(`FilterService: Selected node types: ${this.filterState.selectedNodeTypes.join(', ')}`);
    Logger.log(`FilterService: Selected edge types: ${this.filterState.selectedEdgeTypes.join(', ')}`);
    Logger.log(`FilterService: Previous maxHops: ${previousMaxHops}, Current maxHops: ${currentMaxHops}, isReducingHops: ${isReducingHops}`);
    
    // CRITICAL: Make sure we have the correct node and edge type selections
    // Sometimes these are stored in window object during transitions
    if ((window as any).__selectedNodeTypes && (window as any).__selectedNodeTypes.length > 0) {
      Logger.log(`FilterService: Using globally stored node types: ${(window as any).__selectedNodeTypes.join(', ')}`);
      // Only override if the global value exists and has entries
      if (this.filterState.selectedNodeTypes.length === 0) {
        this.filterState.selectedNodeTypes = [...(window as any).__selectedNodeTypes];
      }
    }
    
    if ((window as any).__selectedEdgeTypes && (window as any).__selectedEdgeTypes.length > 0) {
      Logger.log(`FilterService: Using globally stored edge types: ${(window as any).__selectedEdgeTypes.join(', ')}`);
      // Only override if the global value exists and has entries
      if (this.filterState.selectedEdgeTypes.length === 0) {
        this.filterState.selectedEdgeTypes = [...(window as any).__selectedEdgeTypes];
      }
    }
    
    // Log hop-related information for debugging
    Logger.log(`FilterService: Hop filtering - previousMaxHops=${previousMaxHops}, currentMaxHops=${currentMaxHops}`);
    Logger.log(`FilterService: isExpandingExistingView=${isExpandingExistingView}, isReducingHops=${isReducingHops}`);
    
    // For reduction, try to restore hop history from any of the available storage methods
    if (isReducingHops && this.nodesByHopLevel.size === 0) {
      let storedHistory = null;
      
      // First try the saved hop level history (most accurate)
      if ((window as any).__savedHopLevelHistory) {
        storedHistory = (window as any).__savedHopLevelHistory;
        Logger.log("FilterService: Using saved hop level history for reduction");
      }
      // Then try the root nodes history
      else if ((window as any).__rootNodesByHopLevel) {
        storedHistory = (window as any).__rootNodesByHopLevel;
        Logger.log("FilterService: Using root nodes history for reduction");
      }
      
      // Restore whichever history we found
      if (storedHistory && (storedHistory instanceof Map || typeof storedHistory === 'object')) {
        // If it's a Map, we can use it directly
        if (storedHistory instanceof Map) {
          this.nodesByHopLevel = new Map(storedHistory);
        } 
        // Otherwise we need to convert it back to a Map
        else {
          // Convert object format back to Map<number, Set<string>>
          this.nodesByHopLevel.clear();
          
          for (const [hopLevel, nodeArray] of Object.entries(storedHistory)) {
            const hopNumber = parseInt(hopLevel, 10);
            if (!isNaN(hopNumber) && Array.isArray(nodeArray)) {
              this.nodesByHopLevel.set(hopNumber, new Set(nodeArray));
            }
          }
        }
        
        // Log the restored history for debugging
        Logger.log(`FilterService: Restored hop history with ${this.nodesByHopLevel.size} levels`);
        this.nodesByHopLevel.forEach((nodes, hop) => {
          Logger.log(`  Hop ${hop}: ${nodes.size} nodes`);
        });
      }
    }
    
    // If we're reducing hops and have history, use it to filter rather than recalculating
    if (isReducingHops && this.nodesByHopLevel.size > 0) {
      Logger.log(`FilterService: Reducing maxHops from ${previousMaxHops} to ${currentMaxHops}, using stored hop history`);
      
      // Log summary of hop history for debugging
      Logger.log(`FilterService: Current hop history has ${this.nodesByHopLevel.size} levels`);
      this.nodesByHopLevel.forEach((nodes, hop) => {
        Logger.log(`  Hop ${hop}: ${nodes.size} nodes`);
      });
      
      // Get all node IDs within the current maxHops level
      const nodeIdsWithinCurrentMaxHops = new Set<string>();
      
      // Include all nodes from hop 0 up to current maxHops
      for (let hop = 0; hop <= currentMaxHops; hop++) {
        const nodesAtHop = this.nodesByHopLevel.get(hop);
        if (nodesAtHop) {
          Logger.log(`  Adding ${nodesAtHop.size} nodes from hop ${hop}`);
          nodesAtHop.forEach(nodeId => nodeIdsWithinCurrentMaxHops.add(nodeId));
        }
      }
      
      // IMPORTANT: Keep hop levels that are higher than our current maxHops in the history
      // so when we expand again, we can use them rather than recalculating
      // Just remove them from the current filtered set
      Logger.log(`FilterService: Preserving hop levels ${currentMaxHops+1} to ${previousMaxHops} in history for future expansions`);
      
      Logger.log(`FilterService: Found ${nodeIdsWithinCurrentMaxHops.size} nodes within ${currentMaxHops} hops using stored history`);
      
      // Filter to include only nodes within the current maxHops
      const nodesWithinHopDistance = this.originalNodes.filter(node => 
        nodeIdsWithinCurrentMaxHops.has(node.id)
      );
      
      // Apply node type filtering
      const filteredNodes = nodesWithinHopDistance.filter(node => {
        // Always include user nodes
        if (node.user === true || (node.properties && node.properties.user === true)) {
          return true;
        }
        
        // Always include highlighted nodes
        if ((node as any).highlighted === true || (node.properties && node.properties.highlighted === true)) {
          return true;
        }
        
        // Only include nodes of selected types
        return this.filterState.selectedNodeTypes.includes(node.type);
      });
      
      // Keep track of all filteredNode IDs for edge filtering
      const filteredNodeIds = new Set(filteredNodes.map(node => node.id));
      
      // Filter edges to include only those between filtered nodes
      const filteredEdges = this.originalEdges.filter(edge => {
        // Must match selected edge type
        if (!this.filterState.selectedEdgeTypes.includes(edge.type)) {
          return false;
        }
        
        const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source as string;
        const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target as string;
        
        // Both source and target must be within filtered nodes
        return filteredNodeIds.has(sourceId) && filteredNodeIds.has(targetId);
      });
      
      Logger.log(`FilterService: Hop reduction kept ${filteredNodes.length}/${this.originalNodes.length} nodes and ${filteredEdges.length}/${this.originalEdges.length} edges`);
      
      // Update the previous maxHops value for next filter operation
      (window as any).__previousMaxHops = currentMaxHops;
      
      return { nodes: filteredNodes, edges: filteredEdges };
    }
    
    // Store the current maxHops value for next filter operation
    (window as any).__previousMaxHops = currentMaxHops;
    
    // Determine which set of nodes to use as base for expansion
    const baseNodes = isExpandingExistingView ? currentFilteredNodes : nodes;
    Logger.log(`FilterService: Using ${isExpandingExistingView ? 'current filtered view' : 'all nodes'} as base for hop expansion`);
    
    // Find user nodes and highlighted nodes as starting points
    // If we're expanding from the current view, look for user/highlighted nodes in currentFilteredNodes
    // Otherwise look in the entire universe
    const userNodes = (isExpandingExistingView ? baseNodes : this.originalNodes.filter(
      // Only consider user nodes that match the selected node types
      node => this.filterState.selectedNodeTypes.includes(node.type)
    )).filter(node => 
      node.user === true || (node.properties && node.properties.user === true)
    );
    
    // Also check for explicitly highlighted nodes - but only those visible in current view
    // We want to expand from highlighted nodes that are currently visible
    const highlightedNodes = baseNodes.filter(node => 
      (node as any).highlighted === true || (node.properties && node.properties.highlighted === true)
    );
    
    // If we're expanding from the current view, and there are no highlighted nodes,
    // use all nodes in the current view as starting points
    let startingNodes: NodeData[] = [];
    
    if (isExpandingExistingView && highlightedNodes.length === 0 && userNodes.length === 0) {
      Logger.log(`FilterService: No special nodes in current view, using all ${baseNodes.length} filtered nodes as starting points`);
      startingNodes = [...baseNodes];
    } else if (isExpandingExistingView) {
      // When expanding existing view, prefer to start from highlighted nodes or user nodes that are visible
      // but fallback to all currently visible nodes if no specific starting points
      startingNodes = [...userNodes, ...highlightedNodes];
      
      // Remove duplicates by ID
      const uniqueNodeIds = new Set<string>();
      startingNodes = startingNodes.filter(node => {
        if (uniqueNodeIds.has(node.id)) return false;
        uniqueNodeIds.add(node.id);
        return true;
      });
      
      // If still no starting nodes but we're expanding existing view, use all current nodes
      if (startingNodes.length === 0) {
        Logger.log(`FilterService: Using all current filtered nodes as starting points`);
        startingNodes = [...baseNodes];
      }
    } else {
      // Not expanding from existing view - use user nodes and highlighted nodes from the full dataset
      // but only those matching selected node types
      const fullUserNodes = this.originalNodes
        .filter(node => this.filterState.selectedNodeTypes.includes(node.type))
        .filter(node => node.user === true || (node.properties && node.properties.user === true));
      
      startingNodes = [...fullUserNodes];
      
      // Only add highlighted nodes that aren't already user nodes
      const userNodeIds = new Set(fullUserNodes.map(node => node.id));
      highlightedNodes.forEach(node => {
        if (!userNodeIds.has(node.id)) {
          startingNodes.push(node);
        }
      });
    }
    
    // If no starting points, skip hop filtering
    if (startingNodes.length === 0) {
      Logger.log("FilterService: No starting nodes found, hop filtering skipped");
      return { nodes, edges };
    }
    
    Logger.log(`FilterService: Applying hop filter with max ${this.filterState.maxHops} hops from ${startingNodes.length} starting nodes (${userNodes.length} user, ${highlightedNodes.length} highlighted)`);
    
    // Get all starting node IDs
    const startingNodeIds = new Set(startingNodes.map(node => node.id));
    
    // CRITICAL: For properly handling both expansion and reduction cases
    const isExpandingHops = previousMaxHops !== undefined && currentMaxHops > previousMaxHops;
    
    Logger.log(`FilterService: maxHops: ${currentMaxHops}, previousMaxHops: ${previousMaxHops}, isExpandingHops: ${isExpandingHops}, isReducingHops: ${isReducingHops}`);
    
    // The key difference in logic between expansion and reduction:
    // - For expansion, we want to start from the current graph, so always use currentFilteredNodes
    // - For reduction, we want to use our hop history, but ONLY reduce - never re-expand
    
    if (isReducingHops && this.nodesByHopLevel.size > 0) {
      // For reduction, we keep the existing hop level history
      Logger.log("FilterService: REDUCTION - preserving hop level history");
      // Just log what we have for debugging
      this.nodesByHopLevel.forEach((nodes, hop) => {
        Logger.log(`  Hop ${hop}: ${nodes.size} nodes`);
      });
    } else if (isExpandingExistingView) {
      // For expansion from current view - start BFS from current nodes
      Logger.log("FilterService: EXPANSION from current filtered nodes");
      this.nodesByHopLevel.clear();
    } else {
      // For new searches, reset everything
      Logger.log("FilterService: NEW CALCULATION - starting fresh");
      this.nodesByHopLevel.clear();
    }
    
    // Set the hop 0 nodes (starting nodes)
    this.nodesByHopLevel.set(0, new Set(startingNodeIds));
    
    // Initialize set to track all nodes within max hops
    const nodesWithinMaxHops = new Set(startingNodeIds);
    
    // Build an adjacency list for faster lookup
    const adjacencyList: Map<string, Set<string>> = new Map();
    
    // When expanding from current view, we need the full edge set to find potential connections
    // that might be currently hidden but within hop distance, but only of the selected types
    const edgeSet = (isExpandingExistingView ? this.originalEdges : edges).filter(edge => 
      // CRITICAL: Only consider edges of selected types for hop calculation
      this.filterState.selectedEdgeTypes.includes(edge.type)
    );
    
    Logger.log(`FilterService: Using ${edgeSet.length} edges of selected types for hop calculation`);
    
    // Build adjacency list from edges
    edgeSet.forEach(edge => {
      const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source as string;
      const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target as string;
      
      // Add source -> target
      if (!adjacencyList.has(sourceId)) {
        adjacencyList.set(sourceId, new Set());
      }
      adjacencyList.get(sourceId)!.add(targetId);
      
      // Add target -> source (graph is undirected)
      if (!adjacencyList.has(targetId)) {
        adjacencyList.set(targetId, new Set());
      }
      adjacencyList.get(targetId)!.add(sourceId);
    });
    
    // Key difference in algorithm:
    // - For expansion/new search: perform BFS to calculate next hop levels
    // - For reduction: simply use the existing hop levels, but only up to currentMaxHops
    
    if (isReducingHops && this.nodesByHopLevel.size > 0) {
      Logger.log("FilterService: REDUCTION CASE - using existing hop levels:");
      
      // Reset nodesWithinMaxHops to only include nodes up to the current max hops level
      nodesWithinMaxHops.clear();
      
      // Add back nodes from hop 0 through currentMaxHops
      for (let hop = 0; hop <= currentMaxHops; hop++) {
        const nodesAtHop = this.nodesByHopLevel.get(hop);
        if (nodesAtHop) {
          const nodeCount = nodesAtHop.size;
          Logger.log(`  Including ${nodeCount} nodes from hop ${hop}`);
          
          // Add these nodes to our filtered set
          nodesAtHop.forEach(id => nodesWithinMaxHops.add(id));
        }
      }
      
      // Keep higher hop levels in the history, but don't include them in the current filter
      for (let hop = currentMaxHops + 1; hop <= (previousMaxHops || 5); hop++) {
        const nodesAtHop = this.nodesByHopLevel.get(hop);
        if (nodesAtHop && nodesAtHop.size > 0) {
          Logger.log(`  Preserving ${nodesAtHop.size} nodes at hop ${hop} for future expansion`);
        }
      }
    } else {
      // For expansion and new searches, calculate hop levels using BFS
      Logger.log("FilterService: EXPANSION/NEW SEARCH CASE - calculating hop levels:");
      
      for (let hop = 1; hop <= currentMaxHops; hop++) {
        // Get nodes from previous hop level
        const prevHopNodes = this.nodesByHopLevel.get(hop - 1) || new Set();
        // Create a set for current hop level
        const currentHopNodes = new Set<string>();
        
        // For each node in the previous hop
        prevHopNodes.forEach(nodeId => {
          // Get all neighbors
          const neighbors = adjacencyList.get(nodeId) || new Set();
          
          // Add neighbors to current hop if not already discovered
          neighbors.forEach(neighborId => {
            if (!nodesWithinMaxHops.has(neighborId)) {
              currentHopNodes.add(neighborId);
              nodesWithinMaxHops.add(neighborId);
            }
          });
        });
        
        // Store nodes at current hop level 
        this.nodesByHopLevel.set(hop, currentHopNodes);
        
        Logger.log(`  Hop ${hop}: added ${currentHopNodes.size} nodes`);
      }
    }
    
    // Extra verification: print out all hop levels after calculation
    Logger.log("FilterService: Final hop levels after calculation:");
    for (let i = 0; i <= currentMaxHops; i++) {
      const nodesAtHop = this.nodesByHopLevel.get(i);
      Logger.log(`  Hop ${i}: ${nodesAtHop ? nodesAtHop.size : 0} nodes`);
    }
    
    // Store the hop level history in the window object for debugging and backup
    (window as any).__rootNodesByHopLevel = this.nodesByHopLevel;
    
    // Log summary of hop levels
    Logger.log(`FilterService: Stored hop history with ${this.nodesByHopLevel.size} levels`);
    this.nodesByHopLevel.forEach((nodes, hop) => {
      Logger.log(`  Hop ${hop}: ${nodes.size} nodes`);
    });
    
    // First get all nodes within hop distance
    const nodesWithinHopDistance = this.originalNodes.filter(node => nodesWithinMaxHops.has(node.id));
    
    Logger.log(`FilterService: Found ${nodesWithinHopDistance.length} nodes within hop distance`);
    
    // Check if we're filtering search results
    const isFilteringSearchResults = this.filterState.searchQuery && this.filterState.searchQuery.trim() !== '';
    
    // For search results, we also want to include special type nodes that match the search
    // regardless of hop distance
    let additionalSearchMatches: NodeData[] = [];
    if (isFilteringSearchResults) {
      const specialTypes = ['memory', 'email']; // Content-rich node types to preserve in search
      
      // Find special type nodes that match the search query but are outside hop distance
      additionalSearchMatches = this.originalNodes.filter(node => 
        // Not already in hop distance
        !nodesWithinMaxHops.has(node.id) && 
        // Is a special type
        specialTypes.includes(node.type) &&
        // Matches search query
        this.nodeMatchesSearchTerm(node, this.filterState.searchQuery)
      );
      
      if (additionalSearchMatches.length > 0) {
        Logger.log(`FilterService: Found ${additionalSearchMatches.length} additional search matches outside hop distance`);
        
        // Count by type
        const typeCount: Record<string, number> = {};
        additionalSearchMatches.forEach(node => {
          typeCount[node.type] = (typeCount[node.type] || 0) + 1;
        });
        Logger.log(`FilterService: Additional search matches by type:`, JSON.stringify(typeCount));
      }
    }
    
    // Combine nodes within hop distance with additional search matches
    const combinedNodes = [...nodesWithinHopDistance, ...additionalSearchMatches];
    
    // CRITICAL: only include nodes of selected types in the final result
    // If a node is a user node, highlighted, or a search-matched special type, we'll include it regardless of type
    
    // Track search-matched special type nodes for reporting
    const searchMatchedSpecialNodes: NodeData[] = [];
    const specialTypes = ['memory', 'email']; // Content-rich node types to preserve in search
    
    const filteredNodes = combinedNodes.filter(node => {
      // Always include user nodes
      if (node.user === true || (node.properties && node.properties.user === true)) {
        return true;
      }
      
      // Always include highlighted nodes
      if ((node as any).highlighted === true || (node.properties && node.properties.highlighted === true)) {
        return true;
      }
      
      // For search results, also include special type nodes that matched the search
      // regardless of hop distance
      if (isFilteringSearchResults && specialTypes.includes(node.type)) {
        // Check if this node matched the search criteria
        if (this.nodeMatchesSearchTerm(node, this.filterState.searchQuery)) {
          searchMatchedSpecialNodes.push(node);
          return true;
        }
      }
      
      // Only include nodes of selected types
      return this.filterState.selectedNodeTypes.includes(node.type);
    });
    
    // Log special types preserved due to search matching
    if (searchMatchedSpecialNodes.length > 0) {
      const specialTypeCount: Record<string, number> = {};
      searchMatchedSpecialNodes.forEach(node => {
        specialTypeCount[node.type] = (specialTypeCount[node.type] || 0) + 1;
      });
      
      Logger.log(`FilterService: Preserved ${searchMatchedSpecialNodes.length} search-matched special nodes regardless of hop distance`);
      Logger.log(`FilterService: Search-preserved node types:`, JSON.stringify(specialTypeCount));
    }
    
    // Keep track of all filteredNode IDs for edge filtering
    const filteredNodeIds = new Set(filteredNodes.map(node => node.id));
    
    Logger.log(`FilterService: After filtering by node types: ${filteredNodes.length} nodes (from ${nodesWithinHopDistance.length} nodes within hop distance)`);
    
    // CRITICAL: Only include edges that connect nodes in our filtered result
    // and that match the selected edge types
    const filteredEdges = this.originalEdges.filter(edge => {
      // Must match selected edge type
      if (!this.filterState.selectedEdgeTypes.includes(edge.type)) {
        return false;
      }
      
      const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source as string;
      const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target as string;
      
      // Both source and target must be within filtered nodes
      // This ensures we only include edges between nodes that passed both hop distance and type filtering
      return filteredNodeIds.has(sourceId) && filteredNodeIds.has(targetId);
    });
    
    Logger.log(`FilterService: Hop filtering kept ${filteredNodes.length}/${this.originalNodes.length} nodes and ${filteredEdges.length}/${this.originalEdges.length} edges`);
    
    // CRITICAL: Detailed logging of node and edge types after filtering
    const nodeTypeDistribution: Record<string, number> = {};
    filteredNodes.forEach(node => {
      nodeTypeDistribution[node.type] = (nodeTypeDistribution[node.type] || 0) + 1; 
    });
    
    const edgeTypeDistribution: Record<string, number> = {};
    filteredEdges.forEach(edge => {
      edgeTypeDistribution[edge.type] = (edgeTypeDistribution[edge.type] || 0) + 1;
    });
    
    Logger.log('FilterService: Node types in final hop filtered result:', nodeTypeDistribution);
    Logger.log('FilterService: Edge types in final hop filtered result:', edgeTypeDistribution);
    
    return { nodes: filteredNodes, edges: filteredEdges };
  }

  /**
   * Apply search filter to the node data with support for multiple search terms and operators
   */
  private applySearchFilter(nodes: NodeData[]): NodeData[] {
    const rawQuery = this.filterState.searchQuery.trim();
    
    if (!rawQuery) {
      return nodes;
    }
    
    Logger.log("FilterService: Applying search filter with query:", rawQuery);
    Logger.log("FilterService: Nodes before search filter:", nodes.length);
    
    // Parse the query for AND/OR operators
    const searchTerms = this.parseSearchQuery(rawQuery);
    Logger.log("FilterService: Parsed search terms:", searchTerms);
    
    // Log enhanced search mode details
    if (rawQuery.includes(" ")) {
      Logger.log(
        "FilterService: Using enhanced search mode - " +
        "all words must be present in entity or community data.\n" +
        "Including entity labels, properties, community topics, and specialized content in emails and memories."
      );
      Logger.log(`FilterService: Search terms: ${rawQuery.split(/\s+/).join(', ')}`);
    }
    
    // First, extract user nodes which are ALWAYS preserved regardless of search
    const userNodes = nodes.filter(node => 
      // Check both locations of the user flag for backward compatibility
      node.user === true || (node.properties && node.properties.user === true)
    );
    Logger.log(`FilterService: Found ${userNodes.length} user nodes to always preserve`);
    
    // For multiple search terms with complex logic
    if (searchTerms.operator) {
      const searchResults = this.applyComplexSearch(nodes, searchTerms);
      // Make sure all user nodes are included
      return this.ensureUserNodesIncluded(searchResults, userNodes);
    }
    
    // For simple single term searches, use the standard approach
    const query = rawQuery.toLowerCase();
    
    // DEBUG: Print the first few nodes to see their structure
    const sampleNodes = nodes.slice(0, 3);
    Logger.log("FilterService: Sample nodes for debugging:", JSON.stringify(sampleNodes, null, 2));
    
    // Filter nodes by label or properties matching query and track matching communities
    const matchingNodes = [];
    const matchingCommunities = new Set<number>();
    
    for (const node of nodes) {
      // Skip user nodes here - we'll add them separately to ensure they're preserved
      if (node.user === true || (node.properties && node.properties.user === true)) {
        continue;
      }
      
      // Check if the node itself matches the search term
      const isNodeMatch = this.nodeMatchesSearchTerm(node, query);
      
      // Check if the node has community_topics that match
      let communityMatch = false;
      let matchedCommunityIds: number[] = [];
      
      if (node.properties && node.properties.community_topics) {
        // Scan each community topic
        for (const communityTopic of node.properties.community_topics) {
          if (communityTopic.community_id && this.communityTopicMatchesTerm(communityTopic, query)) {
            // Found a match in this community
            communityMatch = true;
            matchingCommunities.add(communityTopic.community_id);
            matchedCommunityIds.push(communityTopic.community_id);
          }
        }
      }
      
      // Add node if it matches directly or via a community topic
      if (isNodeMatch || communityMatch) {
        matchingNodes.push(node);
        
        // Debug output for community matches
        if (communityMatch) {
          Logger.log(`FilterService: Node "${node.label}" matched via community topics: ${matchedCommunityIds.join(', ')}`);
        }
      }
    }
    
    // Now get all other nodes that belong to any of the matching communities
    const communityNodes = [];
    
    if (matchingCommunities.size > 0) {
      Logger.log(`FilterService: Found matches in ${matchingCommunities.size} communities, including all nodes from these communities`);
      
      for (const node of nodes) {
        // Skip nodes we've already added and user nodes
        if (matchingNodes.some(n => n.id === node.id) || 
            node.user === true || (node.properties && node.properties.user === true)) {
          continue;
        }
        
        // Check if node belongs to any matching community
        const nodeCommunity = node.community;
        if (nodeCommunity !== undefined && matchingCommunities.has(Number(nodeCommunity))) {
          communityNodes.push(node);
          Logger.log(`FilterService: Including node "${node.label}" because it belongs to matching community ${nodeCommunity}`);
        }
      }
    }
    
    // Combine matching nodes with community nodes and user nodes, ensuring no duplicates
    const allMatchingNodes = [...matchingNodes, ...communityNodes];
    const allNodeIds = new Set(allMatchingNodes.map(node => node.id));
    const missingUserNodes = userNodes.filter(node => !allNodeIds.has(node.id));
    
    if (missingUserNodes.length > 0) {
      Logger.log(`FilterService: Adding ${missingUserNodes.length} user nodes to search results`);
      missingUserNodes.forEach(node => {
        Logger.log(`FilterService: Preserving user node regardless of search: ${node.label}`);
      });
    }
    
    const filteredNodes = [...allMatchingNodes, ...missingUserNodes];
    
    Logger.log("FilterService: Nodes after search filter:", filteredNodes.length);
    Logger.log("FilterService: Filtered nodes:", filteredNodes.map(n => n.label).join(", "));
    
    // If all nodes were filtered out (except maybe user nodes), show a warning
    if (matchingNodes.length === 0) {
      Logger.warn(`FilterService: Search for "${query}" returned no matching nodes (excluding user nodes)!`);
    }
    
    return filteredNodes;
  }
  
  /**
   * Ensures all user nodes are included in the results
   */
  private ensureUserNodesIncluded(filteredNodes: NodeData[], userNodes: NodeData[]): NodeData[] {
    const filteredNodeIds = new Set(filteredNodes.map(node => node.id));
    const missingUserNodes = userNodes.filter(node => !filteredNodeIds.has(node.id));
    
    if (missingUserNodes.length > 0) {
      Logger.log(`FilterService: Adding ${missingUserNodes.length} missing user nodes to filtered results`);
      return [...filteredNodes, ...missingUserNodes];
    }
    
    return filteredNodes;
  }
  
  /**
   * Parse a search query into terms and operators
   */
  private parseSearchQuery(query: string): any {
    // Check for exact phrase search in quotes (e.g., "this exact phrase")
    if (query.startsWith('"') && query.endsWith('"')) {
      // Exact phrase search - remove quotes and treat as a single term
      const exactPhrase = query.substring(1, query.length - 1).toLowerCase();
      return { term: exactPhrase, exact: true };
    }
    
    // Check for explicit AND or OR operators
    if (query.includes(' AND ') || query.includes(' OR ')) {
      // Split on operators, preserving them
      const parts: string[] = [];
      let currentPart = '';
      let i = 0;
      
      // Handle quoted sections as a single part
      let inQuotes = false;
      
      while (i < query.length) {
        // Handle quoted sections
        if (query[i] === '"') {
          inQuotes = !inQuotes;
          currentPart += query[i];
          i++;
          continue;
        }
        
        // If we're inside quotes, just add character and continue
        if (inQuotes) {
          currentPart += query[i];
          i++;
          continue;
        }
        
        // Check for AND operator (when not in quotes)
        if (query.substring(i, i + 5) === ' AND ' && !inQuotes) {
          parts.push(currentPart.trim());
          parts.push('AND');
          currentPart = '';
          i += 5;
        }
        // Check for OR operator (when not in quotes)
        else if (query.substring(i, i + 4) === ' OR ' && !inQuotes) {
          parts.push(currentPart.trim());
          parts.push('OR');
          currentPart = '';
          i += 4;
        }
        else {
          currentPart += query[i];
          i++;
        }
      }
      
      if (currentPart.trim()) {
        parts.push(currentPart.trim());
      }
      
      // Process the parts into a structured format
      return this.buildSearchTree(parts);
    }
    
    // For multiple term searches without explicit operators (now implied AND)
    const terms = query.split(/\s+/).filter(term => term.length > 0);
    if (terms.length > 1) {
      return {
        operator: 'AND',  // Changed from OR to AND for multi-word queries
        terms: terms.map(term => term.toLowerCase())
      };
    }
    
    // For single term searches
    return { term: query.toLowerCase() };
  }
  
  /**
   * Build a search tree from parsed query parts
   */
  private buildSearchTree(parts: string[]): any {
    if (parts.length === 1) {
      return { term: parts[0].toLowerCase() };
    }
    
    // Handle single operator
    if (parts.length === 3 && (parts[1] === 'AND' || parts[1] === 'OR')) {
      return {
        operator: parts[1],
        terms: [parts[0].toLowerCase(), parts[2].toLowerCase()]
      };
    }
    
    // For more complex expressions, build a tree preserving operator precedence
    // AND has higher precedence than OR
    
    // First, group AND expressions
    const andGroups: any[] = [];
    let currentAndGroup: string[] = [];
    
    for (let i = 0; i < parts.length; i++) {
      if (parts[i] === 'OR') {
        // Close current AND group if it contains anything
        if (currentAndGroup.length > 0) {
          andGroups.push(currentAndGroup);
          currentAndGroup = [];
        }
        andGroups.push('OR');
      } 
      else if (parts[i] === 'AND') {
        // Keep 'AND' in the current group
        currentAndGroup.push('AND');
      }
      else {
        // Add term to current AND group
        currentAndGroup.push(parts[i]);
      }
    }
    
    // Add the final AND group if not empty
    if (currentAndGroup.length > 0) {
      andGroups.push(currentAndGroup);
    }
    
    // Process AND groups
    const processedGroups = andGroups.map(group => {
      if (typeof group === 'string') {
        return group; // This will be "OR"
      }
      
      // Process AND group
      if (group.length === 1) {
        return { term: group[0].toLowerCase() };
      }
      
      // Build AND expression
      const terms: string[] = [];
      for (let i = 0; i < group.length; i++) {
        if (group[i] !== 'AND') {
          terms.push(group[i].toLowerCase());
        }
      }
      
      if (terms.length === 1) {
        return { term: terms[0] };
      }
      
      return {
        operator: 'AND',
        terms
      };
    });
    
    // Now process OR groups
    if (processedGroups.length === 1) {
      return processedGroups[0];
    }
    
    // Combine with OR
    const orTerms: any[] = [];
    for (let i = 0; i < processedGroups.length; i++) {
      if (processedGroups[i] !== 'OR') {
        orTerms.push(processedGroups[i]);
      }
    }
    
    return {
      operator: 'OR',
      terms: orTerms
    };
  }
  
  /**
   * Apply complex search with AND/OR logic
   */
  private applyComplexSearch(nodes: NodeData[], searchExpr: any): NodeData[] {
    // Filter based on complex search expression, but don't process user nodes here
    const filteredNodes = nodes.filter(node => {
      // Skip user nodes - we'll handle them separately to ensure they're all preserved
      if (node.user === true || (node.properties && node.properties.user === true)) {
        return false;
      }
      
      // Evaluate the search expression for this node
      return this.evaluateSearchExpr(node, searchExpr);
    });
    
    // The method caller (applySearchFilter) will add user nodes to the result
    return filteredNodes;
  }
  
  /**
   * Evaluate a search expression against a node
   */
  private evaluateSearchExpr(node: NodeData, expr: any): boolean {
    // Simple term, with possible exact flag
    if (expr.term) {
      return this.nodeMatchesSearchTerm(node, expr.term, expr.exact === true);
    }
    
    // Complex expression with operator
    if (expr.operator === 'AND') {
      // All terms must match (AND logic)
      return expr.terms.every((term: any) => {
        if (typeof term === 'string') {
          return this.nodeMatchesSearchTerm(node, term);
        }
        return this.evaluateSearchExpr(node, term);
      });
    }
    
    if (expr.operator === 'OR') {
      // At least one term must match (OR logic)
      return expr.terms.some((term: any) => {
        if (typeof term === 'string') {
          return this.nodeMatchesSearchTerm(node, term);
        }
        return this.evaluateSearchExpr(node, term);
      });
    }
    
    // Fallback - shouldn't reach here if expression is valid
    Logger.warn('Invalid search expression:', expr);
    return false;
  }
  
  /**
   * Check if a node matches a specific search term
   * Enhanced to search through all node properties and subproperties
   * @param node The node to check
   * @param term The search term
   * @param exactMatch If true, requires an exact match rather than substring inclusion
   */
  private nodeMatchesSearchTerm(node: NodeData, rawTerm: string, exactMatch: boolean = false): boolean {
    // Split search query into individual words for multi-word matching
    const searchTerms = rawTerm.toLowerCase().split(/\s+/).filter(term => term.length > 0);
    
    // If no valid terms after splitting, return false
    if (searchTerms.length === 0) {
      return false;
    }
    
    // All search terms must be present (logical AND between words)
    return searchTerms.every(term => this.nodeSingleTermMatch(node, term, exactMatch));
  }
  
  /**
   * Check if a community topic object matches a search term
   * This examines all properties of a community topic, including nested ones
   */
  private communityTopicMatchesTerm(communityTopic: any, rawTerm: string): boolean {
    // Split search query into individual words for multi-word matching
    const searchTerms = rawTerm.toLowerCase().split(/\s+/).filter(term => term.length > 0);
    
    // If no valid terms after splitting, return false
    if (searchTerms.length === 0) {
      return false;
    }
    
    // All search terms must be present somewhere in the community topic (logical AND)
    return searchTerms.every(term => this.singleTermMatchesCommunityTopic(communityTopic, term));
  }
  
  /**
   * Check if a single term matches any part of a community topic
   */
  private singleTermMatchesCommunityTopic(communityTopic: any, term: string): boolean {
    // Check key_phrases
    if (communityTopic.key_phrases && Array.isArray(communityTopic.key_phrases)) {
      for (const phrase of communityTopic.key_phrases) {
        if (typeof phrase === 'string' && phrase.toLowerCase().includes(term)) {
          return true;
        }
      }
    }
    
    // Check topics array
    if (communityTopic.topics && Array.isArray(communityTopic.topics)) {
      for (const topic of communityTopic.topics) {
        // Check topic word
        if (topic.word && topic.word.toLowerCase().includes(term)) {
          return true;
        }
        
        // Check topic phrases
        if (topic.phrases && Array.isArray(topic.phrases)) {
          for (const phrase of topic.phrases) {
            if (typeof phrase === 'string' && phrase.toLowerCase().includes(term)) {
              return true;
            }
          }
        }
        
        // Check topic categories
        if (topic.categories && Array.isArray(topic.categories)) {
          for (const category of topic.categories) {
            if (typeof category === 'string' && category.toLowerCase().includes(term)) {
              return true;
            }
          }
        }
      }
    }
    
    // Check temporal_topics
    if (communityTopic.temporal_topics) {
      for (const [timeframe, topics] of Object.entries(communityTopic.temporal_topics)) {
        if (Array.isArray(topics)) {
          for (const topicText of topics) {
            if (typeof topicText === 'string' && topicText.toLowerCase().includes(term)) {
              return true;
            }
          }
        }
      }
    }
    
    // Check topic_categories
    if (communityTopic.topic_categories) {
      const categoriesStr = JSON.stringify(communityTopic.topic_categories).toLowerCase();
      if (categoriesStr.includes(term)) {
        return true;
      }
    }
    
    // If we've checked everything and found no match, return false
    return false;
  }
  
  /**
   * Check if a node matches a single search term
   * This method contains all the original search logic for a single term
   */
  private nodeSingleTermMatch(node: NodeData, term: string, exactMatch: boolean = false): boolean {
    // Special handling for memory entities - thorough search through content
    if (node.type === 'memory') {
      // Memory entities often contain rich text in their labels and properties
      if (node.label) {
        // Memory labels contain the actual memory content in many cases
        if (exactMatch) {
          if (node.label.toLowerCase() === term) {
            Logger.log(`Exact match found in memory content: "${node.label.substring(0, 50)}..."`);
            return true;
          }
        } else if (node.label.toLowerCase().includes(term)) {
          Logger.log(`Match found in memory content: "${node.label.substring(0, 50)}..."`);
          return true;
        }
      }
      
      // Check memory metadata in properties
      if (node.properties) {
        // Look for memory-specific fields that might contain searchable content
        const memoryFields = [
          'content', 'text', 'transcript', 'description', 'notes', 
          'summary', 'tags', 'context', 'source'
        ];
        
        for (const field of memoryFields) {
          if (node.properties[field]) {
            const fieldValue = String(node.properties[field]).toLowerCase();
            if (exactMatch) {
              if (fieldValue === term) {
                Logger.log(`Exact match found in memory ${field}: "${fieldValue.substring(0, 50)}..."`);
                return true;
              }
            } else if (fieldValue.includes(term)) {
              Logger.log(`Match found in memory ${field}: "${fieldValue.substring(0, 50)}..."`);
              return true;
            }
          }
        }
        
        // Search memory tags if present
        if (node.properties.tags && Array.isArray(node.properties.tags)) {
          for (const tag of node.properties.tags) {
            if (exactMatch) {
              if (String(tag).toLowerCase() === term) {
                Logger.log(`Exact match found in memory tag: ${tag}`);
                return true;
              }
            } else if (String(tag).toLowerCase().includes(term)) {
              Logger.log(`Match found in memory tag: ${tag}`);
              return true;
            }
          }
        }
      }
    }
    
    // Special handling for flight nodes - extract airport codes from label
    if (node.type === 'flight') {
      // Flight labels often have format like "Delta Air Lines 3168: SAV to AUS"
      // Check label explicitly for airport codes which are typically 3 uppercase letters
      if (node.label) {
        // Extract all 3-letter airport codes from the label
        const airportCodeRegex = /\b[A-Z]{3}\b/g;
        const airportCodes = node.label.match(airportCodeRegex) || [];
        
        // Check if any extracted code matches the search term (case insensitive)
        for (const code of airportCodes) {
          if (code.toLowerCase() === term.toLowerCase()) {
            Logger.log(`Match found in flight airport code: ${code}`);
            return true;
          }
        }
        
        // Also check if any part of the airline name matches
        const airlinePart = node.label.split(':')[0];
        if (airlinePart && airlinePart.toLowerCase().includes(term)) {
          Logger.log(`Match found in airline name: ${airlinePart}`);
          return true;
        }
        
        // Check for flight number matches
        const flightNumberMatch = node.label.match(/\d+/);
        if (flightNumberMatch && flightNumberMatch[0].includes(term)) {
          Logger.log(`Match found in flight number: ${flightNumberMatch[0]}`);
          return true;
        }
      }
    }
    
    // Special handling for dining nodes
    if (node.type === 'dining') {
      // Restaurant names are typically at the start of the label before brackets or parentheses
      if (node.label) {
        const restaurantName = node.label.split(/[\(\[]/)[0].trim();
        if (restaurantName.toLowerCase().includes(term)) {
          Logger.log(`Match found in restaurant name: ${restaurantName}`);
          return true;
        }
        
        // Check for cuisine type which is often in parentheses
        const cuisineMatch = node.label.match(/\(([^)]+)\)/);
        if (cuisineMatch && cuisineMatch[1].toLowerCase().includes(term)) {
          Logger.log(`Match found in cuisine type: ${cuisineMatch[1]}`);
          return true;
        }
      }
    }
    
    // Special handling for hotel_stay nodes
    if (node.type === 'hotel_stay') {
      // Hotel names are typically at the start of the label
      if (node.label) {
        const hotelName = node.label.split(/[\(\[]/)[0].trim().replace(/Stay at /i, '');
        if (hotelName.toLowerCase().includes(term)) {
          Logger.log(`Match found in hotel name: ${hotelName}`);
          return true;
        }
        
        // Check for location info which might be in the label
        // This would catch searches for cities or locations
        const locationCheck = node.label.toLowerCase();
        if (locationCheck.includes(term)) {
          Logger.log(`Match found in hotel location: ${node.label}`);
          return true;
        }
      }
    }
    
    // Special handling for email nodes
    if (node.type === 'email') {
      // Email nodes contain rich text information that should be searchable
      
      // Check standard email fields
      if (node.properties) {
        // Search common email fields
        const emailFields = ['subject', 'body', 'from', 'to', 'cc', 'bcc', 'sender', 'recipients'];
        
        for (const field of emailFields) {
          if (node.properties[field]) {
            const fieldValue = String(node.properties[field]).toLowerCase();
            if (exactMatch) {
              if (fieldValue === term) {
                Logger.log(`Exact match found in email ${field}: "${fieldValue.substring(0, 50)}..."`);
                return true;
              }
            } else if (fieldValue.includes(term)) {
              Logger.log(`Match found in email ${field}: "${fieldValue.substring(0, 50)}..."`);
              return true;
            }
          }
        }
        
        // Check email content specifically (might be nested)
        if (node.properties.content) {
          const content = typeof node.properties.content === 'string'
            ? node.properties.content
            : JSON.stringify(node.properties.content);
            
          if (content.toLowerCase().includes(term)) {
            Logger.log(`Match found in email content: "${content.substring(0, 50)}..."`);
            return true;
          }
        }
        
        // Check attachments
        if (node.properties.attachments && Array.isArray(node.properties.attachments)) {
          for (const attachment of node.properties.attachments) {
            const attachmentStr = typeof attachment === 'string'
              ? attachment
              : JSON.stringify(attachment);
              
            if (attachmentStr.toLowerCase().includes(term)) {
              Logger.log(`Match found in email attachment: ${attachmentStr.substring(0, 50)}...`);
              return true;
            }
          }
        }
      }
      
      // Check email label which often contains subject
      if (node.label && node.label.toLowerCase().includes(term)) {
        Logger.log(`Match found in email label: "${node.label.substring(0, 50)}..."`);
        return true;
      }
    }
    
    // Special handling for calendar_event nodes
    if (node.type === 'calendar_event') {
      if (node.label) {
        // Calendar event labels often have format like "SXSW Live Demo Sessions on Mar 9 at 11:00 AM"
        // Extract event title (part before "on" or other temporal markers)
        const titleMatch = node.label.match(/^(.*?)(?:\s+on\s+|\s+at\s+|$)/i);
        if (titleMatch && titleMatch[1] && titleMatch[1].toLowerCase().includes(term)) {
          Logger.log(`Match found in calendar event title: ${titleMatch[1]}`);
          return true;
        }
        
        // Look for dates in the label
        const dateMatch = node.label.match(/on\s+(.*?)(?:\s+at\s+|$)/i);
        if (dateMatch && dateMatch[1] && dateMatch[1].toLowerCase().includes(term)) {
          Logger.log(`Match found in calendar event date: ${dateMatch[1]}`);
          return true;
        }
        
        // Look for times in the label
        const timeMatch = node.label.match(/at\s+(.*?)$/i);
        if (timeMatch && timeMatch[1] && timeMatch[1].toLowerCase().includes(term)) {
          Logger.log(`Match found in calendar event time: ${timeMatch[1]}`);
          return true;
        }
      }
      
      // Check for specific calendar event properties
      if (node.properties) {
        // Special handling for attendees (which is an array of objects)
        if (node.properties.attendees && Array.isArray(node.properties.attendees)) {
          for (const attendee of node.properties.attendees) {
            if (attendee.email && attendee.email.toLowerCase().includes(term)) {
              Logger.log(`Match found in calendar event attendee email: ${attendee.email}`);
              return true;
            }
          }
        }
        
        // Check location explicitly
        if (node.properties.location && node.properties.location.toLowerCase().includes(term)) {
          Logger.log(`Match found in calendar event location: ${node.properties.location}`);
          return true;
        }
        
        // Check title explicitly
        if (node.properties.title && node.properties.title.toLowerCase().includes(term)) {
          Logger.log(`Match found in calendar event title property: ${node.properties.title}`);
          return true;
        }
      }
    }
    
    // Check if node label matches search term (general case)
    if (exactMatch) {
      // For exact match, the term must match the entire label, not just be a part of it
      if (node.label.toLowerCase() === term) {
        return true;
      }
    } else if (node.label.toLowerCase().includes(term)) {
      return true;
    }
    
    // Check node type
    if (exactMatch) {
      if (node.type.toLowerCase() === term) {
        return true;
      }
    } else if (node.type.toLowerCase().includes(term)) {
      return true;
    }
    
    // Check node ID
    if (exactMatch) {
      if (node.id && node.id.toString().toLowerCase() === term) {
        return true;
      }
    } else if (node.id && node.id.toString().toLowerCase().includes(term)) {
      return true;
    }
    
    // Check importance
    if (exactMatch) {
      if (node.importance !== undefined && String(node.importance).toLowerCase() === term) {
        return true;
      }
    } else if (node.importance !== undefined && String(node.importance).toLowerCase().includes(term)) {
      return true;
    }
    
    // Check community
    if (exactMatch) {
      if (node.community !== undefined && String(node.community).toLowerCase() === term) {
        return true;
      }
    } else if (node.community !== undefined && String(node.community).toLowerCase().includes(term)) {
      return true;
    }
    
    // Check centrality
    if (node.centrality) {
      const centralityString = JSON.stringify(node.centrality).toLowerCase();
      if (exactMatch) {
        // Exact match is less useful for centrality objects, but we'll check for key match
        if (Object.keys(node.centrality).some(key => key.toLowerCase() === term)) {
          return true;
        }
      } else if (centralityString.includes(term)) {
        return true;
      }
    }
    
    // Check community topics (new feature)
    if (node.properties && node.properties.community_topics) {
      const communityTopics = node.properties.community_topics;
      
      // Check each community's topics
      for (const community of communityTopics) {
        // Check key phrases
        if (community.key_phrases) {
          for (const phrase of community.key_phrases) {
            if (phrase.toLowerCase().includes(term)) {
              Logger.log(`Match found in community key phrase: ${phrase}`);
              return true;
            }
          }
        }
        
        // Check topics array
        if (community.topics) {
          for (const topic of community.topics) {
            // Check the word itself
            if (topic.word && topic.word.toLowerCase().includes(term)) {
              Logger.log(`Match found in community topic word: ${topic.word}`);
              return true;
            }
            
            // Check topic phrases
            if (topic.phrases) {
              for (const phrase of topic.phrases) {
                if (phrase.toLowerCase().includes(term)) {
                  Logger.log(`Match found in community topic phrase: ${phrase}`);
                  return true;
                }
              }
            }
            
            // Check topic categories
            if (topic.categories) {
              for (const category of topic.categories) {
                if (category.toLowerCase().includes(term)) {
                  Logger.log(`Match found in community topic category: ${category}`);
                  return true;
                }
              }
            }
          }
        }
        
        // Check temporal topics
        if (community.temporal_topics) {
          for (const [timeframe, topics] of Object.entries(community.temporal_topics)) {
            if (Array.isArray(topics)) {
              for (const topicText of topics) {
                if (topicText.toLowerCase().includes(term)) {
                  Logger.log(`Match found in community temporal topic: ${topicText}`);
                  return true;
                }
              }
            }
          }
        }
      }
    }
    
    // Search through all properties and subproperties recursively
    if (node.properties) {
      return this.searchObjectDeep(node.properties, term, exactMatch);
    }
    
    return false;
  }
  
  /**
   * Recursively search through an object and its nested properties
   * Enhanced to better handle travel-related data like airport codes and locations
   * @param obj The object to search through
   * @param term The search term
   * @param exactMatch If true, requires an exact match rather than substring inclusion
   */
  private searchObjectDeep(obj: any, term: string, exactMatch: boolean = false): boolean {
    // Base case: if obj is a primitive type
    if (obj === null || obj === undefined) {
      return false;
    }
    
    // Handle string, number, boolean directly
    if (typeof obj !== 'object') {
      // For exact match, the entire value must match the term
      if (exactMatch) {
        if (String(obj).toLowerCase() === term) {
          return true;
        }
      } 
      // For regular match, check if term is included in the value
      else if (String(obj).toLowerCase().includes(term)) {
        return true;
      }
      
      // Special handling for airport codes - if the term is a potential 3-letter code
      // and the value is a string that might contain airport references
      if (term.length === 3 && typeof obj === 'string') {
        // Look for 3-letter airport codes in the string
        const airportCodeRegex = /\b[A-Z]{3}\b/g;
        const airportCodes = String(obj).match(airportCodeRegex) || [];
        
        // Check if any extracted code matches the search term (case insensitive)
        for (const code of airportCodes) {
          if (code.toLowerCase() === term.toLowerCase()) {
            Logger.log(`Deep match found in airport code: ${code} in ${String(obj).substring(0, 50)}`);
            return true;
          }
        }
      }
      
      return false;
    }
    
    // Handle arrays with special consideration for travel-related data
    if (Array.isArray(obj)) {
      // Check each array item recursively
      for (let i = 0; i < obj.length; i++) {
        const item = obj[i];
        
        // For primitive array items
        if (typeof item !== 'object') {
          // For exact match, the entire item must match the term
          if (exactMatch) {
            if (String(item).toLowerCase() === term) {
              Logger.log(`Exact match found in array item: ${String(item).substring(0, 50)}`);
              return true;
            }
          } 
          // For regular match, check if term is included in the item
          else if (String(item).toLowerCase().includes(term)) {
            Logger.log(`Match found in array item: ${String(item).substring(0, 50)}`);
            return true;
          }
          
          // Special handling for airport codes in array elements
          if (term.length === 3 && typeof item === 'string') {
            const airportCodeRegex = /\b[A-Z]{3}\b/g;
            const airportCodes = String(item).match(airportCodeRegex) || [];
            
            for (const code of airportCodes) {
              if (code.toLowerCase() === term.toLowerCase()) {
                Logger.log(`Match found in array item airport code: ${code}`);
                return true;
              }
            }
          }
        }
        // For object array items, recurse with the same exactMatch setting
        else if (this.searchObjectDeep(item, term, exactMatch)) {
          return true;
        }
      }
      return false;
    }
    
    // Enhanced special property handling for flight data
    // Look specifically for flight-related properties that might contain airport codes
    const flightProperties = [
      'origin', 'destination', 'departure_airport', 'arrival_airport', 
      'outbound_source_airport', 'outbound_destination_airport'
    ];
    
    for (const prop of flightProperties) {
      if (obj[prop] && typeof obj[prop] === 'string') {
        // Direct match
        if (obj[prop].toLowerCase().includes(term)) {
          Logger.log(`Match found in flight property ${prop}: ${obj[prop]}`);
          return true;
        }
        
        // Special handling for 3-letter airport codes
        if (term.length === 3 && obj[prop].length === 3) {
          if (obj[prop].toLowerCase() === term.toLowerCase()) {
            Logger.log(`Exact match on airport code in ${prop}: ${obj[prop]}`);
            return true;
          }
        }
      }
    }
    
    // Enhanced special property handling for location data
    const locationProperties = [
      'address', 'lodging_address', 'location', 'city', 'state', 'country',
      'lodging_name', 'name', 'cuisine'
    ];
    
    for (const prop of locationProperties) {
      if (obj[prop] && typeof obj[prop] === 'string') {
        if (exactMatch) {
          if (obj[prop].toLowerCase() === term) {
            Logger.log(`Exact match found in location property ${prop}: ${obj[prop]}`);
            return true;
          }
        } else if (obj[prop].toLowerCase().includes(term)) {
          Logger.log(`Match found in location property ${prop}: ${obj[prop]}`);
          return true;
        }
      }
    }
    
    // Handle objects by recursively checking each property
    for (const key in obj) {
      // Check if the key itself matches
      if (exactMatch) {
        if (key.toLowerCase() === term) {
          //Logger.log(`Exact match found in object key: ${key}`);
          return true;
        }
      } else if (key.toLowerCase().includes(term)) {
        //Logger.log(`Match found in object key: ${key}`);
        return true;
      }
      
      // Check if the value matches
      const value = obj[key];
      
      // Directly check primitive values
      if (typeof value !== 'object') {
        if (exactMatch) {
          if (String(value).toLowerCase() === term) {
            //Logger.log(`Exact match found in property value: ${key}=${String(value).substring(0, 50)}`);
            return true;
          }
        } else if (String(value).toLowerCase().includes(term)) {
          //Logger.log(`Match found in property value: ${key}=${String(value).substring(0, 50)}`);
          return true;
        }
      } 
      // Recursively check objects and arrays with the same exactMatch setting
      else if (this.searchObjectDeep(value, term, exactMatch)) {
        return true;
      }
    }
    
    return false;
  }
}

// Create a singleton instance
export const filterService = new FilterService();

// This function is for testing the search against our reference test data
export function testSearchWithReferenceData() {
  Logger.log("TESTING SEARCH WITH REFERENCE DATA");
  
  // Create a test instance with our reference data
  const testFilterService = new FilterService();
  
  // Reference data from the example
  const testData = {
    "nodes": [
      {
        "id": "person_123",
        "type": "person",
        "label": "John Smith (CEO, Example Corp)",
        "properties": {
          "primary_email_address": "john@example.com",
          "other_email_addresses": ["johnsmith@personal.com"],
          "display_name": "John Smith",
          "given_name": "John",
          "family_name": "Smith",
          "middle_name": "Robert",
          "phone_numbers": ["+15555551234"],
          "organization": "Example Corp",
          "job_title": "CEO",
          "facts": ["Tennis player", "Investor"],
          "first_seen_at": "2023-01-15T12:00:00Z",
          "last_seen_at": "2023-06-30T15:22:43Z",
          "last_activity": "June 30, 2023",
          "user": true
        },
        "importance": 3.0,
        "centrality": {
          "degree": 0.85,
          "eigenvector": 0.92
        },
        "community": 1
      },
      {
        "id": "person_456",
        "type": "person",
        "label": "Jane Doe (CTO, Example Corp)",
        "properties": {
          "primary_email_address": "jane@example.com",
          "display_name": "Jane Doe",
          "given_name": "Jane",
          "family_name": "Doe",
          "organization": "Example Corp",
          "job_title": "CTO",
          "first_seen_at": "2023-02-10T09:30:00Z",
          "last_seen_at": "2023-06-29T11:15:22Z",
          "last_activity": "June 29, 2023"
        },
        "importance": 2.0,
        "centrality": {
          "degree": 0.65,
          "eigenvector": 0.78
        },
        "community": 1
      },
      {
        "id": "flight_789",
        "type": "flight",
        "label": "Delta 1234: JFK to SFO",
        "properties": {
          "id": 789,
          "created_at": "2023-05-15T10:00:00Z",
          "record_locator": "ABC123",
          "ticket_number": "0123456789",
          "price": 599.99,
          "currency": "USD",
          "info_type": "CONFIRMED",
          "departure_datetime": "2023-06-01T08:00:00Z",
          "arrival_datetime": "2023-06-01T11:30:00Z",
          "outbound_source_airport": "JFK",
          "outbound_destination_airport": "SFO",
          "origin": "JFK",
          "destination": "SFO",
          "departure_time": "2023-06-01T08:00:00Z",
          "arrival_time": "2023-06-01T11:30:00Z",
          "legs": [
            {
              "flight_number": "DL1234",
              "departure_airport": "JFK",
              "arrival_airport": "SFO",
              "departure_datetime": "2023-06-01T08:00:00Z",
              "arrival_datetime": "2023-06-01T11:30:00Z",
              "airline_iata_code": "DL",
              "airline": "Delta",
              "seats": "12A",
              "passenger_names": "John Smith"
            }
          ],
          "leg_count": 1
        },
        "importance": 2.0,
        "community": 1
      }
    ],
    "edges": []
  };
  
  // Initialize the test service with our reference data
  testFilterService.initialize(testData.nodes, testData.edges);
  
  // Test search queries
  const testQueries = [
    "john", 
    "smith",
    "jane",
    "doe",
    "cto",
    "ceo",
    "example",
    "corp",
    "jfk",
    "sfo",
    "delta",
    "tennis",
    "john@example.com"
  ];
  
  // Run each test search
  for (const query of testQueries) {
    Logger.log(`\n----- TEST SEARCH: "${query}" -----`);
    testFilterService.setSearchQuery(query);
    const results = testFilterService.applyFilters();
    
    Logger.log(`Results count: ${results.nodes.length}`);
    Logger.log(`Matching nodes: ${results.nodes.map(n => n.label).join(', ')}`);
  }
}