import React from 'react';

import MatchComparisonBar from '../bar/MatchComparisonBar';
import ResultCardBase from '../ResultCardBase';
import {
    StarIcon,
    LocationIcon,
    MartiniIcon
} from 'components/common/Icon';
import { Text, H4Text } from 'components/common/Typography';
import Button from 'components/common/Button';

class RestaurantResultCard extends ResultCardBase {
    renderDetails = () => {
        const {
            startLabel = 'Pick-up',
            startTime,
            startLocation,
            endTime,
        } = this.props?.details;

        if (!startTime && !endTime) {
            return <></>;
        }

        return (
            <div className={`adapter-result-card--content--details reservation-time`}>
                <div className="flex items-center gap-3 w-full">
                    <MartiniIcon title="Reservation" color="#7F56D9" />
                    <div>
                        <Text fontSize='s' style={{ color: '#73788c' }}>{startLabel}</Text>
                        <Text style={{ color: '#111827', fontWeight: 500 }}>{startTime}</Text>
                        {startLocation && (
                            <Text fontSize='xs' style={{ color: '#6B7280' }}>{startLocation}</Text>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    renderCTA = () => {
        const {
            ctaPrice,
            ctaSub,
            ctaText = 'Book Now',
            booking_url,
            formatPrice = true
        } = this.props?.cta;

        let price = ctaPrice;

        if (formatPrice) {
            // @ts-ignore
            price = USDollar.format(ctaPrice);
        }

        return (
            <div className="adapter-result-card--content--CTA px-4 py-3 bg-[#f1f1f4] rounded-md justify-start items-center gap-6 inline-flex">
                <div className="flex-col justify-center items-center inline-flex">
                    <div className="leading-loose">
                        <H4Text
                            fontSize='xs'
                            // @ts-ignore
                            cls={`text-nowrap whitespace-nowrap price-level-${ctaPrice.length}`}
                        >
                            {price}
                        </H4Text>
                    </div>
                    <div className="leading-[18px]">
                        <Text fontSize='xs' style={{ color: '#73788c' }}>{ctaSub}</Text>
                    </div>
                </div>

                {!Array.isArray(booking_url) && (
                    <Button
                        size="2xl"
                        onClick={() => window.open(booking_url, '_blank')}
                        style={{ width: '100%' }}
                    >
                        {ctaText}
                    </Button>
                )}

                {Array.isArray(booking_url) && (
                    <div className="flex flex-col w-full gap-4">
                        {booking_url.map((url, index) => (
                            <Button
                                key={index}
                                size="2xl"
                                disabled={!url}
                                onClick={() => window.open(url, '_blank')}
                                style={{ width: '100%' }}
                            >
                                {booking_url.length > 1 ? `${ctaText} ${index + 1}` : ctaText}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    renderRestaurantSpecificElements = () => {
        const { title, hero, features = [] } = this.props;
        if (!title) {
            return null;
        }

        // Create a placeholder image URL with restaurant name for variety
        const placeholderUrl = `https://source.unsplash.com/featured/?restaurant,${encodeURIComponent(title.title)}`;

        // Get top amenities for feature tags (limit to 4)
        const topFeatures = features.slice(0, 4);

        return (
            <>
                <div className="restaurant-header">
                    <img
                        src={placeholderUrl}
                        alt={title.title}
                        className="restaurant-image"
                        onError={(e) => {
                            e.currentTarget.src = 'https://source.unsplash.com/featured/?food,dining';
                        }}
                    />
                    {title.subTitle && (
                        <div className="cuisine-badge">{title.subTitle}</div>
                    )}
                </div>

                <div className="restaurant-info">
                    <h2 className="restaurant-name">{title.title}</h2>

                    {hero?.rating && (
                        <div className="restaurant-rating">
                            <div className="stars">
                                {[1, 2, 3, 4, 5].map(i => (
                                    <StarIcon
                                        key={i}
                                        title="Star Rating"
                                        color={i <= Math.floor(hero.rating) ? "#F59E0B" : "#D1D5DB"}
                                    />
                                ))}
                            </div>
                            <span className="rating-text">
                                {hero.rating} {hero.reviews && <>({hero.reviews} reviews)</>}
                            </span>
                        </div>
                    )}

                    {title.location && (
                        <div className="restaurant-location">
                            <LocationIcon title="Location" color="#6B7280" />
                            <span>{title.location}</span>
                        </div>
                    )}

                    {topFeatures.length > 0 && (
                        <div className="restaurant-tags">
                            {topFeatures.map((feature, i) => (
                                <span key={i} className="tag">{feature}</span>
                            ))}
                        </div>
                    )}
                </div>
            </>
        );
    }

    render() {
        const { details, cta, ranking, gps_coordinates, renderMapOnly } = this.props;

        if (renderMapOnly && gps_coordinates) {
            return (
                <div className="map-container" ref={this.mapRef} style={{ height: '100%', width: '100%' }}></div>
            );
        }

        return (
            <div className={`adapter-result-card restaurant-card`}>
                <div className='adapter-result-card--content'>

                    {this.renderRestaurantSpecificElements()}
                    {details && this.renderDetails()}
                    {gps_coordinates && (
                        <div className="restaurant-map-container">
                            <div ref={this.mapRef} style={{ height: '100%', width: '100%' }}></div>
                        </div>
                    )}
                    {cta && this.renderCTA()}

                    <div className="h-[0px] border border-[#c8c9d0]"></div>

                    {ranking?.allOptions && ranking?.allOptions?.length > 1 && ranking?.score && (
                        <MatchComparisonBar
                            score={ranking?.score}
                            allScores={ranking?.allOptions}
                            ranking_reasoning={ranking?.ranking_reasoning}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default RestaurantResultCard;