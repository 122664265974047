import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import dayjs from 'dayjs';

TimeAgo.addDefaultLocale(en);
export const timeAgo = new TimeAgo('en-US');

export const convertTo12Hour = (time24) => {
    if (!time24 || typeof time24 !== 'string' || !time24.includes(':')) {
        return time24; // Return original value if it's not a valid time string
    }

    const [hours, minutes] = time24.split(':');
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; // Convert 0 to 12 for midnight

    return `${hour12}:${minutes} ${ampm}`;
};

// "Thu, Aug 8"
export const formatDate_ShortHuman = value => dayjs(value).format('ddd, MMM D');

// "08/08/2024"
export const formatDate_MMDDYYY = value => dayjs(value).format('MM/DD/YYYY');

// "8:52am"
export const formatTime_AmPm = value => dayjs(value).format('h:mma');

export const isValidOnboardingDate = (dateString) => {
    // Regular expression to match MM-DD-YYYY format
    const regex = /^\d{2}-\d{2}-\d{4}$/;
  
    if (!regex.test(dateString)) {
      return false; // Doesn't match the basic format
    }
  
    const parts = dateString.split('-');
    const month = parseInt(parts[0], 10);
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
  
    if (isNaN(month) || isNaN(day) || isNaN(year)) {
      return false; // Not valid numbers
    }
  
    if (month < 1 || month > 12) {
      return false; // Month out of range
    }
  
    const daysInMonth = new Date(year, month, 0).getDate(); // Get days in that month/year
  
    if (day < 1 || day > daysInMonth) {
      return false; // Day out of range
    }

    if (year < 1930 || year > new Date().getFullYear() - 18) {
        return false; // Can't be so old you're dead or too young for TOS/PP
    }
  
    return true; // All checks passed
  }