import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { anthropicService } from 'util/AnthropicService';
import Progress from 'components/common/Progress';
import API from "util/API";
import { inject, observer } from "mobx-react";

interface AnalysisCache {
  [key: string]: {
    analysis: AnalysisResult;
    timestamp: number;
    expiresAt: number;
  };
}

const AnalysisContainer = styled.div`
  padding: 24px;
  background: #F8F9FF;
  border-radius: 12px;
  margin-top: 16px;
`;

const AnalysisTitle = styled.h3`
  font-size: 16px;
  font-family: "Rand Medium", sans-serif;
  color: #171717;
  margin-bottom: 16px;
`;

const AnalysisSection = styled.div`
  margin-bottom: 20px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
`;

const IntentionSection = styled(AnalysisSection)`
  background: #F5F8FF;
`;

const SectionTitle = styled.h4`
  font-size: 14px;
  font-family: "Rand Medium", sans-serif;
  color: #444;
  margin-bottom: 12px;
`;

const InsightList = styled.ul`
  margin: 0;
  padding-left: 20px;
  
  li {
    margin-bottom: 8px;
    font-size: 14px;
    color: #555;
  }
`;

const ErrorContainer = styled.div`
  padding: 16px;
  background: #FFF2F2;
  border-radius: 8px;
  color: #DC3545;
  font-size: 14px;
  margin-top: 12px;
`;

const RefreshButton = styled.button`
  background: #F0F0F0;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 12px;
  color: #444;
  cursor: pointer;
  margin-left: 12px;
  transition: background 0.2s;

  &:hover {
    background: #E0E0E0;
  }
`;

const RelationshipCard = styled.div`
  padding: 12px;
  background: #FAFAFA;
  border-radius: 6px;
  margin-bottom: 8px;
  font-size: 13px;
`;

const DateRange = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  color: #666;
  margin-bottom: 8px;
`;

const ConfidenceScore = styled.div<{ score: number }>`
  display: inline-block;
  padding: 4px 8px;
  background: ${props => props.score >= 0.8 ? '#E6FFE6' : '#FFF3E6'};
  color: ${props => props.score >= 0.8 ? '#28A745' : '#FD7E14'};
  border-radius: 4px;
  font-size: 12px;
  margin-top: 8px;
`;

const CategoryTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
`;

const CategoryTag = styled.span`
  display: inline-block;
  padding: 4px 12px;
  background: #F4F3FF;
  border-radius: 12px;
  font-size: 12px;
  color: #666;
  font-family: "Rand Medium", sans-serif;
`;

const IntentionBox = styled.div`
  background: #FFFFFF;
  border-left: 4px solid #4A90E2;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const PrimaryIntention = styled.div`
  font-size: 16px;
  color: #2C5282;
  font-family: "Rand Medium", sans-serif;
  line-height: 1.5;
  margin-bottom: 8px;
`;

const SubIntentionsList = styled.ul`
  margin: 0;
  padding-left: 20px;
  
  li {
    color: #4A5568;
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 1.4;
    
    &:before {
      content: "•";
      color: #4A90E2;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
`;

const IntentionLabel = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: #718096;
  margin-bottom: 8px;
  letter-spacing: 0.05em;
  font-family: "Rand Medium", sans-serif;
`;

interface Memory {
    id: string;
    content: string;
    created_at: string;
    categories: string[];
    last_referenced?: string;
    updated_at?: string;
}


interface Person {
  id: string;
  properties: {
    full_name: string;
    nickname?: string;
    age?: number;
    occupation?: string;
  };
}

interface Location {
  id: string;
  properties: {
    name: string;
    type: string;
    address: {
      street?: string;
      city?: string;
      state?: string;
      country?: string;
      postal_code?: string;
    };
  };
}

interface Event {
  id: string;
  properties: {
    name: string;
    type: string;
    description?: string;
    date?: string;
    location?: string;
  };
}

interface Organization {
  id: string;
  properties: {
    name: string;
    type: string;
    industry?: string;
    description?: string;
  };
}

interface Relationship {
  source_id: string;
  target_id: string;
  type: string;
  properties: {
    details: string;
    start_date?: string;
    end_date?: string;
  };
}

interface AnalysisResult {
  intentions?: {
    primary_intention?: string;
    sub_intentions?: string[];
  };
  entities?: {
    persons?: Person[];
    locations?: Location[];
    events?: Event[];
    organizations?: Organization[];
  };
  relationships?: Relationship[];
  temporal_context?: {
    timestamp?: string;
    date_ranges?: {
      start_date?: string;
      end_date?: string;
      description?: string;
    }[];
    recurring_patterns?: {
      frequency: "daily" | "weekly" | "monthly" | "yearly";
      interval: number;
      description: string;
    }[];
  };
  metadata?: {
    categories?: string[];
    confidence_score?: number;
    created_at?: string;
    updated_at?: string;
    last_referenced?: string;
  };
}

enum EntityType {
  Person = 'person',
  Location = 'location',
  Event = 'event',
  Organization = 'organization'
}

interface MemoryAnalysisProps {
    memoryContent: string;
    categories?: string[];
    timestamp?: string;
    location?: string;
    UserStore?: any;
}

// Create a cache singleton outside the component
  const analysisCache: AnalysisCache = {};
  const CACHE_DURATION = 30 * 24 * 60 * 60 * 1000;


  // Add these utility functions before the component
  const generateMemoryKey = (content: string, contextMemories: Memory[]): string => {
    return btoa(JSON.stringify({
      content,
      contextHashes: contextMemories.map(m => m.id).sort()
    }));
  };

  const getCachedAnalysis = (key: string): AnalysisResult | null => {
    const cached = analysisCache[key];
    if (!cached) return null;
    
    if (Date.now() > cached.expiresAt) {
      delete analysisCache[key];
      return null;
    }
    
    return cached.analysis;
  };

  const loadCacheFromStorage = () => {
  const stored = localStorage.getItem('analysisCache');
  if (stored) {
    Object.assign(analysisCache, JSON.parse(stored));
  }
};

const saveCacheToStorage = () => {
  localStorage.setItem('analysisCache', JSON.stringify(analysisCache));
};

// Call loadCacheFromStorage on module init
loadCacheFromStorage();

// Update setCachedAnalysis
const setCachedAnalysis = (key: string, analysis: AnalysisResult): void => {
  analysisCache[key] = {
    analysis,
    timestamp: Date.now(),
    expiresAt: Date.now() + CACHE_DURATION
  };
  saveCacheToStorage();
};

const MemoryAnalysis: React.FC<MemoryAnalysisProps> = inject("UserStore")(observer(({
    memoryContent,
    categories,
    timestamp,
    location,
    UserStore
}) => {
    const [analysis, setAnalysis] = useState<AnalysisResult | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [contextMemories, setContextMemories] = useState<Memory[]>([]);
    const [isLoadingMemories, setIsLoadingMemories] = useState(false);

  const analyzeMemory = async () => {
    try {
        setLoading(true);
        setError(null);

        // Generate cache key based on memory content and context
        const cacheKey = generateMemoryKey(memoryContent, contextMemories);
        
        // Check cache first
        const cachedResult = getCachedAnalysis(cacheKey);
        if (cachedResult) {
          console.log('Serving from cache');
          setAnalysis(cachedResult);
          setLoading(false);
          return;
        }

        // Fetch memories and user profile
        const [memoriesResponse, user] = await Promise.all([
            API.get('/api/prompt/memories').then(r => r.json()),
            UserStore.loadUser()
        ]);

        // Update state for UI purposes
        setContextMemories(memoriesResponse.memories);

        // TODO: create a JSON structure representing this user data, and move the construction of the prompt to the backend
        // Generate prompt using the fetched data directly
        let prompt = `Please analyze this memory and provide structured information.\n\n`;

        // Add user details
        if (user) {
            prompt += `[User Details]\n`;
            prompt += `Name: ${user.first_name} ${user.last_name}\n`;
            if (user.metadata.dateOfBirth) {
                prompt += `Date of Birth: ${user.metadata.dateOfBirth}\n`;
            }
            if (user.metadata.homeAddress) {
                prompt += `Home Address: ${user.metadata.homeAddress}\n`;
            }
            prompt += `[/User Details]\n\n`;
        }

        prompt += `[Specific memory to analyze]\n${memoryContent}\n[/Specific memory to analyze]\n\n`;

        // Add context memories
        if (memoriesResponse.memories && memoriesResponse.memories.length > 0) {
            prompt += `[Context memories]\n`;
            
            const recentMemories = memoriesResponse.memories
                .filter(memory => memory.content !== memoryContent)
                .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                .slice(0, 100);

            recentMemories.forEach((memory, index) => {
                prompt += `Memory ${index + 1} (ID: ${memory.id}) [${new Date(memory.created_at).toLocaleDateString()}]:\n`;
                prompt += `Content: ${memory.content}\n`;
                if (memory.categories?.length) {
                    prompt += `Categories: ${memory.categories.join(', ')}\n`;
                }
                prompt += '\n';
            });
            
            prompt += `[/Context memories]\n\n`;
        }

        // Add metadata
        if (categories?.length) {
            prompt += `Categories: ${categories.join(', ')}\n`;
        }
        if (timestamp) {
            prompt += `Timestamp: ${timestamp}\n`;
        }
        if (location) {
            prompt += `Location: ${location}\n`;
        }

        const response = await anthropicService.sendMessage(prompt);

        const analysisResult = JSON.parse(response.content) as AnalysisResult;
        setCachedAnalysis(cacheKey, analysisResult);
        setAnalysis(analysisResult);
    } catch (err) {
        setError('Failed to analyze memory. Please try again later.');
        console.error('Memory analysis error:', err);
    } finally {
        setLoading(false);
    }
};

 useEffect(() => {
        if (memoryContent) {
            analyzeMemory();
        }
        return () => {
            setContextMemories([]);
        };
    }, [memoryContent]);

const invalidateCache = (key: string): void => {
  // Remove from memory cache
  if (analysisCache[key]) {
    delete analysisCache[key];
  }
  
  // Update localStorage
  const stored = localStorage.getItem('analysisCache');
  if (stored) {
    const storedCache = JSON.parse(stored);
    delete storedCache[key];
    localStorage.setItem('analysisCache', JSON.stringify(storedCache));
  }
};


const handleRefresh = async () => {
  try {
    // Generate the cache key
    const cacheKey = generateMemoryKey(memoryContent, contextMemories);
    
    // Invalidate the cache for this memory
    invalidateCache(cacheKey);
    
    // Reset states
    setAnalysis(null);
    setError(null);
    
    // Re-analyze the memory
    await analyzeMemory();
  } catch (err) {
    setError('Failed to refresh analysis. Please try again.');
    console.error('Refresh analysis error:', err);
  }
};


  const renderPersons = (persons?: Person[]) => {
    if (!persons?.length) {
      return null;
    }
    
    return (
      <div>
        <SectionTitle>People Involved</SectionTitle>
        <InsightList>
          {persons.map((person) => (
            <li key={person.id}>
              {person.properties?.full_name || 'X'}
              {person.properties?.nickname && ` (${person.properties.nickname})`}
              {person.properties?.age && person.properties.age > 0 && ` - ${person.properties.age} years old`}
            </li>
          ))}
        </InsightList>
      </div>
    );
  };

  const getEntityType = (id: string, entities: AnalysisResult['entities']): EntityType | null => {
    if (entities?.persons?.find(p => p.id === id)) return EntityType.Person;
    if (entities?.locations?.find(l => l.id === id)) return EntityType.Location;
    if (entities?.events?.find(e => e.id === id)) return EntityType.Event;
    if (entities?.organizations?.find(o => o.id === id)) return EntityType.Organization;
    return null;
  };

  const getEntityName = (id: string, entities: AnalysisResult['entities']): string => {
    const type = getEntityType(id, entities);
    
    switch (type) {
      case EntityType.Person: {
        const person = entities?.persons?.find(p => p.id === id);
        return person ? `${person.properties.full_name}${person.properties.nickname ? ` (${person.properties.nickname})` : ''}` : 'Unknown Person';
      }
      case EntityType.Location: {
        const location = entities?.locations?.find(l => l.id === id);
        return location ? `${location.properties.name}${location.properties.address?.street ? ` (${location.properties.address.street})` : ''}` : 'Unknown Location';
      }
      case EntityType.Event: {
        const event = entities?.events?.find(e => e.id === id);
        return event ? event.properties.name : 'Unknown Event';
      }
      case EntityType.Organization: {
        const org = entities?.organizations?.find(o => o.id === id);
        return org ? org.properties.name : 'Unknown Organization';
      }
      default:
        return 'Unknown Entity';
    }
  };

  const getRelationshipIcon = (sourceType: EntityType | null, targetType: EntityType | null): string => {
  const iconMap = {
    [`${EntityType.Person}-${EntityType.Person}`]: '👥',
    [`${EntityType.Person}-${EntityType.Location}`]: '📍',
    [`${EntityType.Person}-${EntityType.Event}`]: '📅',
    [`${EntityType.Person}-${EntityType.Organization}`]: '🏢',
    [`${EntityType.Organization}-${EntityType.Location}`]: '🏛️',
    [`${EntityType.Event}-${EntityType.Location}`]: '📌',
  };

  return iconMap[`${sourceType}-${targetType}`] || '↔️';
};

  const renderRelationships = (relationships?: Relationship[], entities?: AnalysisResult['entities']) => {
  if (!relationships?.length || !entities) {
    return null;
  }

  // Group relationships by type for better organization
  const groupedRelationships = relationships.reduce((acc, rel) => {
    const sourceType = getEntityType(rel.source_id, entities);
    const targetType = getEntityType(rel.target_id, entities);
    const key = `${sourceType}-${targetType}`;
    if (!acc[key]) acc[key] = [];
    acc[key].push(rel);
    return acc;
  }, {} as Record<string, Relationship[]>);

  return (
    <div>
      <SectionTitle>Relationships & Connections</SectionTitle>
      {Object.entries(groupedRelationships).map(([groupKey, groupRelationships]) => (
        <div key={groupKey}>
          {groupRelationships.map((rel, index) => {
            const sourceType = getEntityType(rel.source_id, entities);
            const targetType = getEntityType(rel.target_id, entities);
            const icon = getRelationshipIcon(sourceType, targetType);
            
            return (
              <RelationshipCard key={`${groupKey}-${index}`}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <span>{icon}</span>
                  <div>
                    <strong>{getEntityName(rel.source_id, entities)}</strong>
                    {' → '}
                    <strong>{getEntityName(rel.target_id, entities)}</strong>
                  </div>
                </div>
                <div style={{ color: '#666', marginTop: '4px' }}>
                  {rel.properties?.details}
                  {(rel.properties?.start_date || rel.properties?.end_date) && (
                    <div style={{ fontSize: '12px', color: '#888', marginTop: '4px' }}>
                      {rel.properties.start_date && `From: ${new Date(rel.properties.start_date).toLocaleDateString()}`}
                      {rel.properties.end_date && ` To: ${new Date(rel.properties.end_date).toLocaleDateString()}`}
                    </div>
                  )}
                </div>
              </RelationshipCard>
            );
          })}
        </div>
      ))}
    </div>
  );
};

  if (error) {
    return (
      <AnalysisContainer>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <AnalysisTitle>Atlas Analysis</AnalysisTitle>
          <RefreshButton onClick={handleRefresh}>Try Again</RefreshButton>
        </div>
        <ErrorContainer>{error}</ErrorContainer>
      </AnalysisContainer>
    );
  }

  return (
    <AnalysisContainer>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <AnalysisTitle>Atlas Analysis</AnalysisTitle>
            {!loading && !isLoadingMemories && (
                <RefreshButton onClick={handleRefresh}>Refresh Analysis</RefreshButton>
            )}
        </div>

        {(loading || isLoadingMemories) ? (
            <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px' }}>
                <Progress />
                <span style={{ color: '#666', fontSize: '14px' }}>
                    {isLoadingMemories ? 'Loading context...' : 'Analyzing memory...'}
                </span>
            </div>
        ) : analysis && (
        <>
          <IntentionSection>
            <SectionTitle>Intentions</SectionTitle>
            <IntentionBox>
              <IntentionLabel>Primary Intent</IntentionLabel>
              <PrimaryIntention>
                {analysis.intentions?.primary_intention || 'No primary intention identified'}
              </PrimaryIntention>
              
              {analysis.intentions?.sub_intentions?.length > 0 && (
                <>
                  <IntentionLabel style={{ marginTop: '16px' }}>Supporting Intentions</IntentionLabel>
                  <SubIntentionsList>
                    {analysis.intentions.sub_intentions.map((intention, index) => (
                      <li key={index}>{intention}</li>
                    ))}
                  </SubIntentionsList>
                </>
              )}
            </IntentionBox>
          </IntentionSection>

          {analysis.entities?.persons && (
            <AnalysisSection>
              {renderPersons(analysis.entities.persons)}
            </AnalysisSection>
          )}

            {analysis.relationships && (
              <AnalysisSection>
                {renderRelationships(analysis.relationships, analysis.entities)}
              </AnalysisSection>
            )}

          {analysis.entities?.events?.length > 0 && (
            <AnalysisSection>
              <SectionTitle>Events</SectionTitle>
              {analysis.entities.events.map((event) => (
                <div key={event.id} style={{ marginBottom: '16px' }}>
                  <strong>{event.properties?.name || 'Unnamed Event'}</strong>
                  <div style={{ color: '#666' }}>
                    {event.properties?.description || 'No description available'}
                  </div>
                </div>
              ))}
            </AnalysisSection>
          )}

          {analysis.temporal_context?.date_ranges?.length > 0 && (
            <AnalysisSection>
              <SectionTitle>Temporal Context</SectionTitle>
              {analysis.temporal_context.date_ranges.map((range, index) => (
                <DateRange key={index}>
                  <span>📅</span>
                  <span>
                    {range.start_date ? new Date(range.start_date).toLocaleDateString() : 'Unknown'} - 
                    {range.end_date ? new Date(range.end_date).toLocaleDateString() : 'Unknown'}
                  </span>
                  <span style={{ color: '#999' }}>•</span>
                  <span>{range.description || 'No description available'}</span>
                </DateRange>
              ))}
            </AnalysisSection>
          )}

          {analysis.metadata && (
            <AnalysisSection>
              <SectionTitle>Categories</SectionTitle>
              <CategoryTags>
                {analysis.metadata.categories?.map((category, index) => (
                  <CategoryTag key={index}>{category}</CategoryTag>
                )) || <CategoryTag>Uncategorized</CategoryTag>}
              </CategoryTags>
              {analysis.metadata.confidence_score !== undefined && (
                <ConfidenceScore score={analysis.metadata.confidence_score}>
                  Confidence: {(analysis.metadata.confidence_score * 100).toFixed(1)}%
                </ConfidenceScore>
              )}
            </AnalysisSection>
          )}
        </>
      )}
    </AnalysisContainer>
  );
}));

export default MemoryAnalysis;
