import React, { FormEvent, ChangeEvent } from "react";
import { inject, observer } from "mobx-react";

import { Account } from './ConnectedAccounts';
import { GoogleIcon, TrashIcon } from 'components/common/Icon';
import { Text } from "components/common/Typography";
import DisconnectAccountModal from "./DisconnectAccountModal";
import Button from "components/common/Button";

import {
    PERSONAL,
    PURCHASES,
    SOCIAL,
    TRAVEL,
    ALL_TIME,
    SIX_MONTHS,
    ONE_YEAR,
    THREE_YEARS,
} from "constants/Scopes";

import './AccountDisplay.scss';

const categoryOptions = [PERSONAL, TRAVEL, PURCHASES, SOCIAL];
const timeOptions = [ ALL_TIME, SIX_MONTHS, ONE_YEAR, THREE_YEARS ];

interface AccountDisplayProps {
    hideIcon?: boolean;
    account?: Account;
    AuthStore?: any;
    UserStore?: any;
    OnboardingStore?: any;
    buttonText?: string;

    beforeSubmit?: (fn: () => void) => void;
}

class AccountDisplay extends React.Component<AccountDisplayProps, any> {
    state = {
        gmailCategoryState: categoryOptions,
        gmailTimeState: ALL_TIME,
        accountEmail: undefined,
        hasChanged: false,
        showDisconnect: false
    };

    constructor(props: AccountDisplayProps) {
        super(props);

        const { account } = props;

        if (account) {
            const { timeRange, labels } = account.configuration;
            let timeUnit = timeRange.unit;
            if (timeUnit === 'years' && timeRange.value === 1) {
                // @ts-ignore
                timeUnit = 'year';
            }

            const timeString = `${timeRange.value} ${timeUnit.charAt(0).toUpperCase() + timeUnit.slice(1)}`;
            const timeState = timeOptions.indexOf(timeString);
            const defaultChoice = timeState > -1 ? timeOptions[timeState] : ALL_TIME;

            this.state = {
                gmailCategoryState: [...labels],
                gmailTimeState: defaultChoice,
                accountEmail: account.accountEmail,
                hasChanged: false,
                showDisconnect: false
            };
        }
    }

    onAuthorizeHandler = (event: FormEvent<HTMLFormElement>) => {
        const { OnboardingStore, beforeSubmit } = this.props;
        const { gmailCategoryState, gmailTimeState, accountEmail } = this.state;

        event.preventDefault();

        const openGoogleFlow = () => {
            OnboardingStore.handleGoogleAuth(
                gmailCategoryState,
                gmailTimeState,
                accountEmail
            );
        }

        if (beforeSubmit) {
            beforeSubmit(openGoogleFlow);
        } else {
            openGoogleFlow();
        }
    };

    onCheckboxHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const { gmailCategoryState } = this.state;
        const { name, value } = event.target;
        let newValues;

        if (!gmailCategoryState.includes(value)) {
            newValues = [...gmailCategoryState, value];
        } else {
            newValues = [...gmailCategoryState];
            const index = newValues.indexOf(value);
            if (index > -1) {
                newValues.splice(index, 1);
            }
        }

        // @ts-ignore
        this.setState({
            [name]: newValues,
            hasChanged: true
        });
    };

    onRadioHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        this.setState({
            gmailTimeState: value,
            hasChanged: true
        });
    };

    isEmailAddressConnected = (account: Account) => {
        return account?.connected ? true : false;
    }

    emailAddressValid = (accountEmail: string) => {
        return accountEmail !== undefined && accountEmail?.length === 0 ? "Email address is required" : undefined
    }

    onDisconnectCancel = () => this.setState({ showDisconnect: false });

    onDisconnectSubmit = () => {
        const { AuthStore, account } = this.props;

        AuthStore.disconnectAccount(account.accountEmail, account.accountVendor)
            .then(() => window.location.reload());
    }

    onDisconnect = () => this.setState({ showDisconnect: true });

    renderDataCategories = () => {
        const { gmailCategoryState } = this.state;
        return (
            <div className="flex flex-col justify-start items-start gap-2">
                <div className="text-[#515150] text-sm font-['Saans Saans'] leading-[18.20px]">Data categories*</div>
                <div className="self-stretch h-[58px] flex-col justify-start items-start gap-4 flex">
                    <div className="self-stretch justify-start items-start gap-[17px] inline-flex">
                        <div className="grow shrink basis-0 h-[21px] justify-start items-center gap-3 flex">
                            <input
                                type="checkbox"
                                onChange={this.onCheckboxHandler}
                                name="gmailCategoryState"
                                value={SOCIAL}
                                checked={gmailCategoryState.includes(SOCIAL)}
                            />
                            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                <div className="self-stretch text-[#3f3f3f] text-base font-['Saans Saans'] leading-tight">Social</div>
                            </div>
                        </div>
                        <div className="grow shrink basis-0 h-[21px] justify-start items-center gap-3 flex">
                            <input
                                type="checkbox"
                                onChange={this.onCheckboxHandler}
                                name="gmailCategoryState"
                                value={PERSONAL}
                                checked={gmailCategoryState.includes(PERSONAL)}
                            />
                            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                <div className="text-[#3f3f3f] text-base font-['Saans Saans'] leading-tight">Personal</div>
                            </div>
                        </div>
                    </div>
                    <div className="self-stretch justify-start items-start gap-[17px] inline-flex">
                        <div className="grow shrink basis-0 h-[21px] justify-start items-center gap-3 flex">
                            <input
                                type="checkbox"
                                onChange={this.onCheckboxHandler}
                                name="gmailCategoryState"
                                value={TRAVEL}
                                checked={gmailCategoryState.includes(TRAVEL)}
                            />
                            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                <div className="self-stretch text-[#3f3f3f] text-base font-['Saans Saans'] leading-tight">Travel</div>
                            </div>
                        </div>
                        <div className="grow shrink basis-0 h-[21px] justify-start items-center gap-3 flex">
                            <input
                                type="checkbox"
                                onChange={this.onCheckboxHandler}
                                name="gmailCategoryState"
                                value={PURCHASES}
                                checked={gmailCategoryState.includes(PURCHASES)}
                            />
                            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                <div className="text-[#3f3f3f] text-base font-['Saans Saans'] leading-tight">Purchases</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDataHistory = () => {
        const { gmailTimeState, accountEmail } = this.state;

        return (
            <div className="flex flex-col justify-start items-start gap-2">
                <div className="text-[#515150] text-sm font-['Saans Saans'] leading-[18.20px]">Account history*</div>
                <div className="self-stretch h-[58px] flex-col justify-start items-start gap-4 flex">
                    <div className="self-stretch justify-start items-start gap-[17px] inline-flex">
                        <div className="grow shrink basis-0 h-[21px] justify-start items-center gap-3 flex">
                            <input
                                type="radio"
                                onChange={this.onRadioHandler}
                                name={`gmailTimeState-${accountEmail}`}
                                value={ALL_TIME}
                                checked={gmailTimeState === ALL_TIME}
                            />
                            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                <div className="self-stretch text-[#3f3f3f] text-base font-['Saans Saans'] leading-tight">All time</div>
                            </div>
                        </div>
                        <div className="grow shrink basis-0 h-[21px] justify-start items-center gap-3 flex">
                            <input
                                type="radio"
                                onChange={this.onRadioHandler}
                                name={`gmailTimeState-${accountEmail}`}
                                value={ONE_YEAR}
                                checked={gmailTimeState === ONE_YEAR}
                            />
                            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                <div className="text-[#3f3f3f] text-base font-['Saans Saans'] leading-tight">1 year</div>
                            </div>
                        </div>
                    </div>
                    <div className="self-stretch justify-start items-start gap-[17px] inline-flex">
                        <div className="grow shrink basis-0 h-[21px] justify-start items-center gap-3 flex">
                            <input
                                type="radio"
                                onChange={this.onRadioHandler}
                                name={`gmailTimeState-${accountEmail}`}
                                value={THREE_YEARS}
                                checked={gmailTimeState === THREE_YEARS}
                            />
                            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                <div className="self-stretch text-[#3f3f3f] text-base font-['Saans Saans'] leading-tight">3 years</div>
                            </div>
                        </div>
                        <div className="grow shrink basis-0 h-[21px] justify-start items-center gap-3 flex">
                            <input
                                type="radio"
                                onChange={this.onRadioHandler}
                                name={`gmailTimeState-${accountEmail}`}
                                value={SIX_MONTHS}
                                checked={gmailTimeState === SIX_MONTHS}
                            />
                            <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                <div className="text-[#3f3f3f] text-base font-['Saans Saans'] leading-tight">6 months</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { account, hideIcon = false, buttonText = 'Connect' } = this.props;
        const { accountEmail, hasChanged, showDisconnect } = this.state;

        return (
            <div className="adapter-account-display">
                {account && (
                    <div className="disconnect">
                        <TrashIcon
                            onClick={this.onDisconnect}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                )}

                {!hideIcon && (
                    <div className="account-icon">
                        <GoogleIcon title={account?.accountVendor.toUpperCase()} />
                    </div>
                )}

                <div className="flex flex-col gap-[32px]">
                    {!account && <Text>What activity data would you like Adapter to import from this account?</Text>}
                    {account && <Text>{accountEmail}</Text>}
                    {this.renderDataCategories()}
                    {this.renderDataHistory()}

                    <Button
                        size="2xl"
                        style={account && !this.isEmailAddressConnected(account)
                            ? { backgroundColor: '#FAA7E0', borderColor: '#FAA7E0' }
                            : undefined}
                        disabled={account ? this.isEmailAddressConnected(account) && !hasChanged : false}
                        // @ts-ignore
                        onClick={this.onAuthorizeHandler}
                    >
                        {account && !this.isEmailAddressConnected(account)
                            ? 'Reconnect account'
                            : buttonText}
                    </Button>
                </div>

                {showDisconnect && (
                    <DisconnectAccountModal
                        onCancel={this.onDisconnectCancel}
                        onDisconnect={this.onDisconnectSubmit}
                    />
                )}
            </div>
        );
    }
}

export default inject("AuthStore", "UserStore", "OnboardingStore")(observer(AccountDisplay));