import React from 'react';
import { inject, observer } from 'mobx-react';

import NLPInputBar from 'pages/intentions/common/NLPInputBar';
import InputBar from 'pages/intentions/common/InputBar';
import AdapterChat from 'components/common/Chat/AdapterChat';
import PromptToggle from './PromptToggle';
import IntentionList from 'components/common/Intention/IntentionList';
import { H4Text } from 'components/common/Typography';

import './PromptFlow.scss';

type PromptFlowProps = {
    NLPStore?: any;
    PromptStore?: any;
}

class PromptFlow extends React.Component<PromptFlowProps, {}> {
    render() {
        const { NLPStore, PromptStore } = this.props;
        const hasPrompted = PromptStore.loadingParseInput || PromptStore.messages.length > 0;

        return (
            <>
                {!hasPrompted && (
                    <div className="adapter-fullscreen-content adapter-bg-base-100">
                        <div className='flex-1' />

                        <PromptToggle />

                        <H4Text>What would you like to delegate?</H4Text>

                        {NLPStore.isCurrentTab
                            ? <NLPInputBar alwaysVisible placeholder="Tell me about my data..." />
                            : <InputBar alwaysVisible placeholder="Start a new request..." />
                        }

                        <IntentionList />

                        <div className='flex-1' />
                    </div>
                 )}

                {hasPrompted && (
                    <AdapterChat />
                )}
            </>
        );
    }
}

export default inject("NLPStore", "PromptStore")(observer(PromptFlow));