// This is a TypeScript wrapper for the Rust/WASM graph physics module
import type { NodeData, EdgeData } from '../types/types';
import Logger from 'util/Logger';

// Types matching the Rust structs
interface WasmNode {
  id: string;
  x: number;
  y: number;
  z: number;
  vx: number;
  vy: number;
  vz: number;
  mass: number;
  fx?: number | null;
  fy?: number | null;
  fz?: number | null;
}

interface WasmEdge {
  source: string;
  target: string;
  strength: number;
}

interface ForceSimulationParams {
  alpha?: number;
  alpha_min?: number;
  alpha_decay?: number;
  alpha_target?: number;
  center_strength?: number;
  repulsion_strength?: number;
  link_strength?: number;
  gravity?: number;
  angular_damping?: number; // Damping factor applied to angular momentum (rotation)
}

// Forward declarations for WASM imports
// These types will be provided by the generated wasm-pack output
interface GraphPhysicsModule {
  GraphPhysics: {
    new(params?: ForceSimulationParams): GraphPhysics;
  };
  // The default export can either be an initializer function or a module with properties
  default?: (() => Promise<void>) & {
    // Allow GraphPhysics to be on the default export too (common WASM module pattern)
    GraphPhysics?: {
      new(params?: ForceSimulationParams): GraphPhysics;
    }
  };
  // Add other exports that may be present
  [key: string]: any;
}

interface GraphPhysics {
  set_nodes(nodes: WasmNode[]): void;
  set_edges(edges: WasmEdge[]): void;
  tick(): boolean;
  get_positions(): Array<[string, number, number, number]>;
  set_parameters?(params: ForceSimulationParams): void;
  get_parameters?(): ForceSimulationParams;
}

class WasmForceGraph {
  private wasmModule: GraphPhysics | null = null;
  private isInitialized = false;
  private isInitializing = false;
  private nodes: Map<string, WasmNode> = new Map();
  private edges: WasmEdge[] = [];
  private params: ForceSimulationParams;
  private onTickCallback: (() => void) | null = null;
  // Use a path that will resolve correctly in various deployment environments
  private modulePath: string;
  
  // Try several potential paths to maximize compatibility
  private getPotentialWasmPaths(): string[] {
    const baseUrl = process.env.PUBLIC_URL || '';
    return [
      `${baseUrl}/wasm/graph_physics.js`,
      `${window.location.origin}/wasm/graph_physics.js`,
    ];
  }
  
  constructor(params: ForceSimulationParams = {}) {
    this.params = params;
    // Default to the first path - will try others if it fails
    this.modulePath = this.getPotentialWasmPaths()[0];
  }
  
  async initialize(): Promise<void> {
    if (this.isInitialized || this.isInitializing) return;
    
    this.isInitializing = true;
    
    // Try all potential paths one by one until one works
    const potentialPaths = this.getPotentialWasmPaths();
    let lastError: Error | null = null;
    
    for (const path of potentialPaths) {
      try {
        Logger.log(`Attempting to load WASM module from: ${path}`);
        this.modulePath = path;
        
        // Try multiple approaches to load the WASM module
        let wasmModule: GraphPhysicsModule | null = null;
        
        // First check if path is accessible
        const scriptResponse = await fetch(path);
        if (!scriptResponse.ok) {
          Logger.warn(`Path ${path} returned status ${scriptResponse.status}, trying next path...`);
          continue; // Try next path
        }
        
        // Script is accessible, try loading it
        Logger.log(`Path ${path} is accessible, loading WASM module...`);
        
        // Approach 1: Try direct script loading
        try {
          const scriptText = await scriptResponse.text();
          const blob = new Blob([scriptText], { type: 'text/javascript;charset=utf-8' });
          const scriptURL = URL.createObjectURL(blob);
          
          // Load the script via a script tag with type="module" to handle ES modules
          await new Promise<void>((resolve, reject) => {
            const script = document.createElement('script');
            script.src = scriptURL;
            script.type = "module"; // Add type="module" to support ES modules syntax
            script.onload = () => {
              // @ts-ignore - access the global variable defined by the script
              if (window.GraphPhysics) {
                Logger.log('WASM module loaded via script tag');
                // @ts-ignore
                wasmModule = window.GraphPhysics;
                resolve();
              } else {
                reject(new Error('WASM module loaded but GraphPhysics not found in global scope'));
              }
            };
            script.onerror = (e) => {
              reject(new Error(`Script load error: ${e}`));
            };
            document.head.appendChild(script);
          });
        } catch (scriptError) {
          Logger.warn('Failed to load WASM via script tag:', scriptError);
          
          // Approach 2: Try dynamic import as fallback
          try {
            Logger.log('Attempting dynamic import as fallback');
            // Tell webpack not to try to parse this import
            // @ts-ignore
            wasmModule = await import(/* @vite-ignore */ path) as GraphPhysicsModule;
            Logger.log('WASM module loaded via dynamic import');
          } catch (importError) {
            Logger.error('Dynamic import also failed:', importError);
            lastError = new Error(`All WASM loading approaches failed for path ${path}`);
            continue; // Try next path
          }
        }
        
        if (!wasmModule) {
          Logger.warn(`Failed to get valid WASM module from ${path}`);
          continue; // Try next path
        }
        
        // Initialize the WASM module if needed
        if (typeof wasmModule.default === 'function') {
          try {
            await wasmModule.default();
            Logger.log('WASM module initialized');
          } catch (initError) {
            Logger.warn('Error during WASM initialization:', initError);
            // Continue anyway - initialization might still work
          }
        }
        
        // Create a new instance of the force graph physics
        try {
          // Determine which constructor to use - handle various module patterns
          let GraphPhysicsConstructor;
          
          // Log available properties to help debug the module structure
          Logger.log('WASM module properties:', Object.keys(wasmModule));
          if (wasmModule.default) {
            Logger.log('Default export properties:', Object.keys(wasmModule.default));
          }
          
          // Try different patterns where the constructor might be located
          if (wasmModule.GraphPhysics && typeof wasmModule.GraphPhysics === 'function') {
            // Direct export pattern
            GraphPhysicsConstructor = wasmModule.GraphPhysics;
            Logger.log('Using directly exported GraphPhysics constructor');
          } else if (wasmModule.default && typeof wasmModule.default.GraphPhysics === 'function') {
            // Nested under default export pattern
            GraphPhysicsConstructor = wasmModule.default.GraphPhysics;
            Logger.log('Using GraphPhysics constructor from default export');
          } else if (typeof wasmModule === 'function') {
            // Module itself is the constructor
            GraphPhysicsConstructor = wasmModule;
            Logger.log('Using module as constructor directly');
          } else if (wasmModule.default && typeof wasmModule.default === 'function' && 
                    !wasmModule.default.prototype?.tick) {
            // Default is a factory function, not the constructor or initializer
            // If it doesn't have a tick method on prototype, assume it's a factory
            const factory = wasmModule.default;
            GraphPhysicsConstructor = factory();
            Logger.log('Using factory function result as constructor');
          } else {
            // Try window global as last resort
            // @ts-ignore
            if (window.GraphPhysics && typeof window.GraphPhysics === 'function') {
              // @ts-ignore
              GraphPhysicsConstructor = window.GraphPhysics;
              Logger.log('Using GraphPhysics constructor from window global');
            } else {
              throw new Error('GraphPhysics constructor not found in any expected location');
            }
          }
          
          // Create the instance using the constructor we found
          this.wasmModule = new GraphPhysicsConstructor(this.params);
          Logger.log('GraphPhysics instance created successfully');
          
          // Success! Update flags and return
          this.isInitialized = true;
          this.isInitializing = false;
          Logger.log(`WASM force graph successfully initialized from path: ${path}`);
          return;
        } catch (constructorError) {
          Logger.error('Failed to create GraphPhysics instance:', constructorError);
          lastError = constructorError;
          continue; // Try next path
        }
      } catch (pathError) {
        // Handle any unexpected errors for this path
        Logger.warn(`Error trying path ${path}:`, pathError);
        lastError = pathError;
        // Continue to the next path
      }
    }
    
    // If we get here, all paths failed
    this.isInitializing = false;
    Logger.error('Failed to initialize WASM module from all potential paths:', lastError);
    throw new Error(`Failed to load WASM physics module from any path. Last error: ${lastError?.message}`);
  }
  
  setNodes(nodes: NodeData[]): void {
    if (!this.isInitialized || !this.wasmModule) {
      throw new Error('WASM module not initialized');
    }
    
    // Convert from app NodeData to WASM node format
    const wasmNodes: WasmNode[] = nodes.map(node => ({
      id: node.id,
      x: node.x ?? Math.random() * 100 - 50,
      y: node.y ?? Math.random() * 100 - 50,
      z: 0, // Default to 2D for now
      vx: node.vx ?? 0,
      vy: node.vy ?? 0,
      vz: 0,
      mass: node.importance ?? 1,
      fx: node.fx ?? null,
      fy: node.fy ?? null,
      fz: null
    }));
    
    // Update local cache
    this.nodes.clear();
    for (const node of wasmNodes) {
      this.nodes.set(node.id, node);
    }
    
    // Send to WASM
    try {
      this.wasmModule.set_nodes(wasmNodes);
    } catch (error) {
      Logger.error('Error setting nodes in WASM:', error);
    }
  }
  
  setEdges(edges: EdgeData[]): void {
    if (!this.isInitialized || !this.wasmModule) {
      throw new Error('WASM module not initialized');
    }
    
    // Convert from app EdgeData to WASM edge format
    const wasmEdges: WasmEdge[] = edges.map(edge => ({
      source: edge.source,
      target: edge.target,
      strength: edge.weight ?? 1.0
    }));
    
    // Update local cache
    this.edges = wasmEdges;
    
    // Send to WASM
    try {
      this.wasmModule.set_edges(wasmEdges);
    } catch (error) {
      Logger.error('Error setting edges in WASM:', error);
    }
  }
  
  tick(): boolean {
    if (!this.isInitialized || !this.wasmModule) {
      return false;
    }
    
    try {
      const didUpdate = this.wasmModule.tick();
      
      if (didUpdate) {
        this.updatePositions();
        if (this.onTickCallback) {
          this.onTickCallback();
        }
      }
      
      return didUpdate;
    } catch (error) {
      Logger.error('Error during physics tick:', error);
      return false;
    }
  }
  
  private updatePositions(): void {
    if (!this.wasmModule) return;
    
    try {
      const positions = this.wasmModule.get_positions();
      
      // Update local node cache with new positions
      for (const [id, x, y, z] of positions) {
        const node = this.nodes.get(id);
        if (node) {
          node.x = x;
          node.y = y;
          node.z = z;
        }
      }
    } catch (error) {
      Logger.error('Error updating positions from WASM:', error);
    }
  }
  
  getNodePositions(): Map<string, { x: number, y: number, z: number }> {
    const positions = new Map<string, { x: number, y: number, z: number }>();
    
    for (const [id, node] of this.nodes.entries()) {
      positions.set(id, {
        x: node.x,
        y: node.y,
        z: node.z
      });
    }
    
    return positions;
  }
  
  /**
   * Check if the graph is still actively moving/changing.
   * Returns true if significant movement is occurring, false if stable.
   */
  isActive(): boolean {
    if (!this.isInitialized || !this.wasmModule) {
      return false;
    }
    
    let totalMovement = 0;
    let nodeCount = 0;
    
    // Check velocity magnitudes of nodes
    for (const node of this.nodes.values()) {
      if (node.fx !== undefined && node.fy !== undefined) {
        continue; // Skip fixed nodes
      }
      
      // Calculate squared velocity magnitude (avoid sqrt for performance)
      const velocitySquared = 
        (node.vx || 0) * (node.vx || 0) + 
        (node.vy || 0) * (node.vy || 0) + 
        (node.vz || 0) * (node.vz || 0);
        
      totalMovement += velocitySquared;
      nodeCount++;
    }
    
    // If no movable nodes, consider inactive
    if (nodeCount === 0) return false;
    
    // Calculate average movement (keep as squared for comparison)
    const avgMovement = totalMovement / nodeCount;
    
    // Very small threshold to determine "stable" (0.01 squared = 0.0001)
    return avgMovement > 0.0001;
  }
  
  onTick(callback: () => void): void {
    this.onTickCallback = callback;
  }
  
  dispose(): void {
    if (this.wasmModule) {
      // Let the WASM module be garbage collected
      this.wasmModule = null;
    }
    this.isInitialized = false;
    this.nodes.clear();
    this.edges = [];
  }
  
  /**
   * Get the current simulation parameters
   */
  getParameters(): ForceSimulationParams {
    // Return a copy of the current parameters
    return { ...this.params };
  }
  
  /**
   * Set new simulation parameters
   */
  setParameters(newParams: ForceSimulationParams): void {
    if (!this.isInitialized || !this.wasmModule) {
      throw new Error('WASM module not initialized');
    }
    
    // Update our local copy
    this.params = { ...this.params, ...newParams };
    
    // If WASM module supports parameter updates, use it
    if (this.wasmModule.set_parameters) {
      try {
        this.wasmModule.set_parameters(this.params);
      } catch (error) {
        Logger.error('Error setting parameters in WASM:', error);
      }
    } else {
      Logger.warn('WASM module does not support dynamic parameter updates');
      // We still update the local params for reference
    }
  }
}

export default WasmForceGraph;