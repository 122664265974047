// src/components/common/VoiceCommandButton.tsx
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Logger from 'util/Logger';
import { sttService } from '../services/STTService';

interface VoiceCommandButtonProps {
  onCommand: (command: string) => void;
  darkMode: boolean;
  isProcessing: boolean;
  isCollapsed?: boolean;
}

const VoiceButton = styled.button<{ $darkMode: boolean; $isListening: boolean; $isProcessing: boolean; $isCollapsed?: boolean }>`
  background-color: ${props => {
    if (props.$isListening) return props.$darkMode ? '#EF4444' : '#DC2626';
    return props.$darkMode ? '#334155' : '#F1F5F9';
  }};
  color: ${props => {
    if (props.$isListening) return '#FFFFFF';
    return props.$darkMode ? '#CBD5E1' : '#334155';
  }};
  border: 1px solid ${props => {
    if (props.$isListening) return props.$darkMode ? '#B91C1C' : '#B91C1C';
    return props.$darkMode ? '#475569' : '#CBD5E1';
  }};
  border-radius: 8px;
  cursor: ${props => props.$isProcessing ? 'wait' : 'pointer'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.$isCollapsed ? '8px' : '8px'};
  transition: all 0.2s ease;
  flex: ${props => props.$isCollapsed ? 'none' : '1'};
  position: relative;
  width: ${props => props.$isCollapsed ? '40px' : 'auto'};
  height: ${props => props.$isCollapsed ? '40px' : 'auto'};
  
  &:hover {
    background-color: ${props => {
      if (props.$isListening) return props.$darkMode ? '#DC2626' : '#B91C1C';
      if (props.$isProcessing) return props.$darkMode ? '#334155' : '#F1F5F9';
      return props.$darkMode ? '#475569' : '#E2E8F0';
    }};
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
  }
  
  svg {
    width: ${props => props.$isCollapsed ? '24px' : '18px'};
    height: ${props => props.$isCollapsed ? '24px' : '18px'};
  }
`;

const PulseEffect = styled.span<{ $darkMode: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  animation: pulse 1.5s infinite;
  background-color: ${props => props.$darkMode ? 'rgba(239, 68, 68, 0.5)' : 'rgba(220, 38, 38, 0.5)'};
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.3);
      opacity: 0;
    }
  }
`;

// Visual countdown indicator for silence detection
const SilenceIndicator = styled.div<{ $progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  width: ${props => props.$progress * 100}%;
  background-color: #10B981; /* Green */
  transition: width 0.3s ease;
`;

const ProcessingSpinner = styled.span`
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

// Updated implementation using ElevenLabs Speech-to-Text API
export const VoiceCommandButton: React.FC<VoiceCommandButtonProps> = ({ 
  onCommand, 
  darkMode, 
  isProcessing,
  isCollapsed = false
}) => {
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [silenceProgress, setSilenceProgress] = useState(0);
  
  // Use ref for posthog to avoid triggering rerenders
  const posthogRef = useRef<any>(null);
  // Track silence detection state
  const silenceDetectionRef = useRef<{
    timer: number | null;
    startTime: number | null;
    isSilent: boolean;
  }>({ timer: null, startTime: null, isSilent: false });
  
  // Disable analytics during speech recognition to prevent interference
  const disableAnalytics = () => {
    if (typeof window !== 'undefined' && (window as any).posthog && !posthogRef.current) {
      Logger.log('Temporarily disabling analytics during speech recognition');
      // Store original posthog
      posthogRef.current = (window as any).posthog;
      
      // Replace with dummy implementation
      const dummyFn = () => {};
      const posthogDummy = {
        capture: dummyFn,
        identify: dummyFn,
        alias: dummyFn,
        people: { set: dummyFn },
        register: dummyFn,
        unregister: dummyFn,
        // Keep other non-function properties
        ...Object.entries((window as any).posthog)
          .filter(([_, v]) => typeof v !== 'function')
          .reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {})
      };
      
      (window as any).posthog = posthogDummy;
    }
  };
  
  // Restore analytics after speech recognition
  const restoreAnalytics = () => {
    if (posthogRef.current) {
      Logger.log('Restoring analytics after speech recognition');
      (window as any).posthog = posthogRef.current;
      posthogRef.current = null;
    }
  };
  
  // Initialize audio for Mobile Safari
  const initAudioForMobileSafari = () => {
    if (typeof window !== 'undefined' && 'ttsService' in window) {
      const ttsService = (window as any).ttsService;
      if (ttsService && typeof ttsService.initAudioForMobileSafari === 'function') {
        ttsService.initAudioForMobileSafari();
      }
    }
  };
  
  // Set up external events to track silence detection progress
  useEffect(() => {
    if (isListening) {
      // Listen for silence events from STT service
      const handleSilenceStart = () => {
        silenceDetectionRef.current.isSilent = true;
        silenceDetectionRef.current.startTime = Date.now();
        startSilenceProgressTracking();
      };
      
      const handleSilenceEnd = () => {
        silenceDetectionRef.current.isSilent = false;
        silenceDetectionRef.current.startTime = null;
        stopSilenceProgressTracking();
        setSilenceProgress(0);
      };
      
      // Add event listeners to window to communicate with STT service
      window.addEventListener('silence:start', handleSilenceStart);
      window.addEventListener('silence:end', handleSilenceEnd);
      
      return () => {
        window.removeEventListener('silence:start', handleSilenceStart);
        window.removeEventListener('silence:end', handleSilenceEnd);
        stopSilenceProgressTracking();
      };
    }
  }, [isListening]);
  
  // Track silence progress
  const startSilenceProgressTracking = () => {
    // Stop any existing timer
    stopSilenceProgressTracking();
    
    // The silence duration threshold is 500ms in the STT service
    const SILENCE_DURATION = 500;
    
    // Start a new timer to update progress
    silenceDetectionRef.current.timer = window.setInterval(() => {
      if (silenceDetectionRef.current.isSilent && silenceDetectionRef.current.startTime) {
        const elapsed = Date.now() - silenceDetectionRef.current.startTime;
        const progress = Math.min(elapsed / SILENCE_DURATION, 1);
        setSilenceProgress(progress);
        
        // If we reach 100%, clear the timer
        if (progress >= 1) {
          stopSilenceProgressTracking();
        }
      }
    }, 50); // Update every 50ms for smooth animation
  };
  
  // Stop tracking silence progress
  const stopSilenceProgressTracking = () => {
    if (silenceDetectionRef.current.timer) {
      clearInterval(silenceDetectionRef.current.timer);
      silenceDetectionRef.current.timer = null;
    }
  };
  
  // Handle recognition success
  const handleRecognitionSuccess = (text: string) => {
    Logger.log(`Speech recognized: "${text}"`);
    setIsListening(false);
    setSilenceProgress(0);
    stopSilenceProgressTracking();
    
    // Process the transcript
    if (text.trim()) {
      onCommand(text.trim());
    } else {
      setError('No speech detected');
    }
    
    // Restore analytics
    setTimeout(restoreAnalytics, 500);
  };
  
  // Handle recognition error
  const handleRecognitionError = (error: Error) => {
    Logger.error('Speech recognition error:', error);
    setError(`Recognition error: ${error.message}`);
    setIsListening(false);
    setSilenceProgress(0);
    stopSilenceProgressTracking();
    
    // Restore analytics
    setTimeout(restoreAnalytics, 500);
  };
  
  // Function to start listening using ElevenLabs STT
  const startListening = async () => {
    if (isProcessing || isListening) return;
    
    setError(null);
    Logger.log('Starting speech recognition with ElevenLabs...');
    
    // Initialize audio for Mobile Safari
    initAudioForMobileSafari();
    
    // Disable analytics before starting speech recognition
    disableAnalytics();
    
    try {
      // Start recording using the STT service
      setIsListening(true);
      await sttService.startRecording(
        handleRecognitionSuccess,
        handleRecognitionError
      );
    } catch (error) {
      Logger.error('Failed to start recording:', error);
      setIsListening(false);
      setError('Failed to start recording');
      
      // Restore analytics
      setTimeout(restoreAnalytics, 500);
    }
  };
  
  // Function to stop listening
  const stopListening = async () => {
    if (!isListening) return;
    
    Logger.log('Stopping speech recognition...');
    
    try {
      // Stop recording using the STT service
      await sttService.stopRecording();
    } catch (error) {
      Logger.error('Error stopping recording:', error);
      handleRecognitionError(error instanceof Error 
        ? error 
        : new Error('Failed to stop recording')
      );
    }
  };
  
  // Toggle listening state
  const toggleListening = () => {
    if (isProcessing) return;
    
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };
  
  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (isListening) {
        stopListening();
      }
      restoreAnalytics();
    };
  }, [isListening]);
  
  return (
    <VoiceButton 
      onClick={toggleListening}
      $darkMode={darkMode}
      $isListening={isListening}
      $isProcessing={isProcessing}
      $isCollapsed={isCollapsed}
      disabled={isProcessing}
      aria-label={isListening ? "Stop listening" : "Start voice command"}
      title={error || (isListening ? "Recording... Will stop automatically after silence or tap to stop manually" : "Tap to speak a command")}
    >
      {isListening && <PulseEffect $darkMode={darkMode} />}
      {isListening && silenceProgress > 0 && <SilenceIndicator $progress={silenceProgress} />}
      
      {isProcessing ? (
        <ProcessingSpinner />
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
        </svg>
      )}
    </VoiceButton>
  );
};