import React from 'react';
import { inject, observer } from 'mobx-react';

import './Intention.scss';
import { Text } from '../Typography';
import { InfoIcon } from '../Icon';

type SuggestionCategory = "flight" | "dining" | "hotel";

export type PromptSuggestion = {
    category: SuggestionCategory;
    request: string;
    short_request: string;
    refs: string[];
}

const IntentionInfoIcon = ({ onClick }) => {
    return (
        <InfoIcon
            cls="info relative"
            color="#2B1859" 
            onClick={onClick}
        />)
    ;
}

type IntentionProps = {
    description: string;
    Icon: React.ComponentType<{ color: string }>;
    prompt: string;
    refs?: string[];
    onInfoTap?: () => void;
    NLPStore?: any;
    PromptStore?: any;
}

const Intention: React.FC<IntentionProps> = inject('NLPStore', 'PromptStore')(observer(({
    description = '',
    prompt = '',
    Icon,
    onInfoTap = () => {},
    NLPStore,
    PromptStore
}) => {
    const store = NLPStore.isCurrentTab ? NLPStore : PromptStore;
    const isSelected = store?.rawPrompt === prompt;

    return (
        <div
            className={`adapter-intention ${isSelected ? 'selected' : ''}`}
            onClick={() => store?.setRawPrompt(prompt)}
            // @ts-ignore
            tabIndex="0"
        >
            <Icon color="#2B1859" />
            <div className="adapter-intention-content">
                <Text fontSize='s' cls='adapter-text-echo-950'>{description}</Text>
            </div>

            <IntentionInfoIcon onClick={onInfoTap} />
        </div>
    );
}));

export default Intention;

export const IntentionLoading = () => (
    <div className="adapter-intention">
        <div className='icon-placeholder animated-background'></div>
        <div className="adapter-intention-content">
            <p className='animated-background'>&nbsp;</p>

            <p className='animated-background'>&nbsp;</p>
        </div>
    </div>
);