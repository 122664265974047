import React from 'react';
import { inject, observer } from 'mobx-react';
import Slider from "react-slick";

import { MartiniIcon, HotelIcon, PlaneIcon } from 'components/common/Icon';
import Intention, { IntentionLoading } from './index';
import IntentionTooltip from './IntentionTooltip';
import { Text, UL, LI } from '../Typography';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Intention.scss';

// Device Detection Utility
const DeviceDetection = {
    isMobileDevice(): boolean {
        // Check for touch capability
        const hasTouch = (
            'ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            // @ts-ignore
            navigator.msMaxTouchPoints > 0
        );

        // Check for mobile-specific browser properties
        const isMobileBrowser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );

        // Check screen dimensions
        const isSmallScreen = window.innerWidth <= 768;

        // Check orientation capability
        const hasOrientationSupport = typeof window.orientation !== 'undefined';

        // Check for mobile-specific display mode
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

        // Combine factors with weighted importance
        let mobileScore = 0;
        if (hasTouch) mobileScore += 2;
        if (isMobileBrowser) mobileScore += 3;
        if (isSmallScreen) mobileScore += 2;
        if (hasOrientationSupport) mobileScore += 1;
        if (isStandalone) mobileScore += 1;

        // Consider device as mobile if it meets enough criteria (threshold: 4)
        return mobileScore >= 4;
    },

    isTablet(): boolean {
        const isIPad = /iPad/i.test(navigator.userAgent);
        const isTabletBrowser = /Tablet|iPad/i.test(navigator.userAgent);
        const isLargeTouch = window.innerWidth <= 1024 && window.innerWidth > 768 && 'ontouchstart' in window;

        return isIPad || isTabletBrowser || isLargeTouch;
    },

    getDeviceType(): 'mobile' | 'tablet' | 'desktop' {
        if (this.isTablet()) return 'tablet';
        if (this.isMobileDevice()) return 'mobile';
        return 'desktop';
    }
};

interface IntentionListState {
    deviceType: 'mobile' | 'tablet' | 'desktop';
    orientation: 'portrait' | 'landscape';
    windowWidth: number;
    currentInfo?: any;
}

type SuggestionType = "flight" | "dining" | "lodging";

type PromptSuggestion = {
    type: SuggestionType;
    request: string;
    short_request: string;
    user_location: string;
    refs?: string[];
}

const ICONS = {
    'flight': PlaneIcon,
    'lodging': HotelIcon,
    'dining': MartiniIcon,
} as const;

class IntentionList extends React.Component<{ NLPStore?: any; PromptStore?: any }, IntentionListState> {
    private resizeTimeout: NodeJS.Timeout | null = null;
    private orientationTimeout: NodeJS.Timeout | null = null;

    state: IntentionListState = {
        deviceType: DeviceDetection.getDeviceType(),
        orientation: (window.innerHeight > window.innerWidth ? 'portrait' : 'landscape') as 'portrait' | 'landscape',
        windowWidth: window.innerWidth,
        currentInfo: null
    };

    componentDidMount(): void {
        const { PromptStore } = this.props;
        if (!PromptStore.loadingSuggestions) {
            PromptStore.getPromptSuggestions();
        }

        // Add event listeners
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('orientationchange', this.handleOrientationChange);
        // Media query listener for detecting display mode changes
        window.matchMedia('(display-mode: standalone)').addListener(this.handleDisplayModeChange);
    }

    componentWillUnmount(): void {
        // Clean up all listeners
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('orientationchange', this.handleOrientationChange);
        window.matchMedia('(display-mode: standalone)').removeListener(this.handleDisplayModeChange);

        if (this.resizeTimeout) { clearTimeout(this.resizeTimeout); }
        if (this.orientationTimeout) { clearTimeout(this.orientationTimeout); }
    }

    handleDisplayModeChange = (): void => {
        this.updateDeviceType();
    };

    handleOrientationChange = (): void => {
        if (this.orientationTimeout) {
            clearTimeout(this.orientationTimeout);
        }

        // Wait for orientation change to complete
        this.orientationTimeout = setTimeout(() => {
            this.setState({
                orientation: window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
            }, this.updateDeviceType);
        }, 150);
    };

    handleResize = (): void => {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }

        this.resizeTimeout = setTimeout(() => {
            this.updateDeviceType();
        }, 150);
    };

    updateDeviceType = (): void => {
        const newDeviceType = DeviceDetection.getDeviceType();
        const newWidth = window.innerWidth;
        if (
            newDeviceType !== this.state.deviceType ||
            Math.abs(this.state.windowWidth - newWidth) > 50
        ) {
            this.setState({
                deviceType: newDeviceType,
                windowWidth: newWidth
            });
        }
    };

    renderSuggestion = (suggestion: PromptSuggestion, i: number) => {
        const { NLPStore, PromptStore } = this.props;
        if (PromptStore.loadingSuggestions) {
            return <IntentionLoading key={i} />;
        }

        return (
            <Intention
                key={i}
                description={suggestion.short_request}
                prompt={suggestion.request}
                Icon={ICONS[suggestion.type]}
                // @ts-ignore
                onInfoTap={(e) => this.onInfoTap(e, suggestion.refs)}
            />
        );
    }

    onInfoTap = (e: Event, refs = []) => {
        e.stopPropagation();
        this.setIntentionInfo(refs);
    }

    setIntentionInfo = (refs = []) => {
        this.setState({ currentInfo: refs })
    }

    resetIntentionInfo = () => {
        this.setState({ currentInfo: null })
    }

    renderMobileView(allSuggestions: PromptSuggestion[]) {
        const { deviceType, orientation, currentInfo } = this.state;

        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '20px',
            arrows: false,
        };

        return (
            <div className={`adapter-intention-list mobile ${deviceType} ${orientation}`}>
                <Slider {...settings}>
                    {
                        allSuggestions.map((suggestion, index) => this.renderSuggestion(suggestion, index))
                    }
                </Slider>

                {currentInfo && (
                    <IntentionTooltip onClose={this.resetIntentionInfo}>
                        <UL>
                            {currentInfo.map((ref, index) => (
                                <LI key={index}><Text fontSize='s'>{ref}</Text></LI>
                            ))}
                        </UL>
                    </IntentionTooltip>
                )}
            </div>
        );
    }

    renderDesktopView(allSuggestions: PromptSuggestion[]) {
        const { currentInfo } = this.state;
        return (
            <>
                <div className="adapter-intention-list desktop">
                    {allSuggestions.map(this.renderSuggestion)}
                </div>

                {currentInfo && (
                    <IntentionTooltip onClose={this.resetIntentionInfo}>
                        <UL>
                            {currentInfo.map((ref, index) => (
                                <LI key={index}><Text fontSize='s'>{ref}</Text></LI>
                            ))}
                        </UL>
                    </IntentionTooltip>
                )}
            </>
        );
    }

    render() {
        const { PromptStore } = this.props;
        const { deviceType } = this.state;
        let allSuggestions: PromptSuggestion[] = [...PromptStore.promptSuggestions];

        if (PromptStore.loadingSuggestions) {
            allSuggestions.push({} as PromptSuggestion, {} as PromptSuggestion, {} as PromptSuggestion);
        } else if (!allSuggestions.length) {
            return null;
        }

        return (
            <React.Fragment>
                {deviceType !== 'desktop'
                    ? this.renderMobileView(allSuggestions)
                    : this.renderDesktopView(allSuggestions)
                }
            </React.Fragment>
        );
    }
}

export default inject('NLPStore', 'PromptStore')(observer(IntentionList));