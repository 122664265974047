import { filterService, FilterService } from '../services/FilterService';
import { graphService } from '../services/GraphService';
import { NodeData, EdgeData } from '../types/types';
import Logger from '../../../util/Logger';
import { TTSService } from '../services/TTSService';
import { filterNodesByQueryLegacy, filterNodesByPerson } from '../utils/dataProcessingLegacy';
import { GraphProcessor } from '../utils/dataprocessing';
import { subgraphExtractionService } from '../services/SubgraphExtractionService';
import { advancedSearchService } from '../services/AdvancedSearchService';

/**
 * Utility function to filter important nodes by removing user nodes
 * This ensures we don't include user nodes in the important nodes filtering
 */
function filterOutUserNodes(importantNodesById: string[], nodes: NodeData[]): string[] {
  // Filter out user nodes from the importantNodesById array
  const userNodeIds = new Set(nodes.filter(isUserNode).map(node => node.id));
  const filteredImportantNodesById = importantNodesById.filter(id => !userNodeIds.has(id));
  
  if (importantNodesById.length !== filteredImportantNodesById.length) {
    Logger.log(`Filtered out ${importantNodesById.length - filteredImportantNodesById.length} user nodes from importantNodesById`);
  }
  
  return filteredImportantNodesById;
}

/**
 * Helper function to check if a node is a user node
 * Handles all possible ways a node might be identified as a user:
 * - node.properties.user === true
 * - node.user === true
 * - node.properties.is_primary_user === true
 * - node.properties.primary_user === true
 * - node.properties.is_user === true
 */
function isUserNode(node: NodeData): boolean {
  // Check for explicit user flag on node
  if (node.user === true) {
    return true;
  }
  
  // Check for user property in node.properties
  if (node.properties) {
    if (
      node.properties.user === true || 
      node.properties.is_primary_user === true || 
      node.properties.primary_user === true ||
      node.properties.is_user === true
    ) {
      return true;
    }
  }
  
  return false;
}

/**
 * Helper function to find all user nodes in the graph
 */
function findUserNodes(nodes: NodeData[]): NodeData[] {
  return nodes.filter(isUserNode);
}


/**
 * Handles regular search
 */
// Common English stopwords including self-references
const STOPWORDS = new Set([
  // Original stopwords
  'a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'on', 'at', 'to', 'by', 'in',
  'of', 'from', 'with', 'about', 'against', 'between', 'into', 'through', 'during',
  'before', 'after', 'above', 'below', 'up', 'down', 'as', 'if', 'then', 'when',
  'than', 'because', 'while', 'where', 'how', 'all', 'any', 'both', 'each', 'few',
  'more', 'most', 'some', 'such', 'no', 'not', 'only', 'own', 'same', 'so', 'than',
  'too', 'very', 'can', 'will', 'just', 'should', 'now', 'this', 'that', 'these',
  'those', 'what', 'which', 'who', 'whom', 'whose', 'why', 'how', 'is', 'am', 'are',
  'was', 'were', 'be', 'been', 'being', 'have', 'has', 'had', 'do', 'does', 'did',
  'could', 'would', 'shall', 'may', 'might', 'must', 'me', 'my', 'mine', 'myself',
  'you', 'your', 'yours', 'yourself', 'yourselves', 'he', 'him', 'his', 'himself',
  'she', 'her', 'hers', 'herself', 'it', 'its', 'itself', 'we', 'us', 'our', 'ours',
  'ourselves', 'they', 'them', 'their', 'theirs', 'themselves', 'i',
  
  // Single characters
  'b', 'c', 'd', 'e', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',

  //numbers
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
  
  // Time-related terms (generic for calendar events)
  'day', 'days', 'week', 'weeks', 'month', 'months', 'year', 'years', 'time', 'date', 'schedule', 'calendar', 
  'event', 'events', 'morning', 'afternoon', 'evening', 'night', 'today', 'tomorrow', 'yesterday',
  'daily', 'weekly', 'monthly', 'yearly', 'hour', 'hours', 'minute', 'minutes', 'second', 'seconds',
  'early', 'late', 'soon', 'later', 'appointment', 'appointments', 'meeting', 'meetings', 'session', 'sessions',
  'begin', 'begins', 'beginning', 'end', 'ends', 'ending', 'start', 'starts', 'starting', 'finish', 'finishes',
  'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'weekday', 'weekend',
  'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december',
  'jan', 'feb', 'mar', 'apr', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec',
  'scheduled', 'upcoming', 'past', 'future', 'recurring', 'repeat', 'repeating',
  
  // Travel-related generic terms (for flights/hotels)
  'flight', 'flights', 'airplane', 'aircraft', 'airport', 'airways', 'airline', 'airlines',
  'hotel', 'hotels', 'motel', 'motels', 'stay', 'stays', 'room', 'rooms', 'suite', 'suites', 
  'reservation', 'reservations', 'booking', 'bookings', 'travel', 'trip', 'journey', 'vacation',
  'arrive', 'arrival', 'arriving', 'depart', 'departure', 'departing', 'domestic', 'international',
  'passenger', 'passengers', 'ticket', 'tickets', 'boarding', 'destination', 'destinations', 
  'location', 'locations', 'check-in', 'check-out', 'checkin', 'checkout', 'luggage', 'baggage',
  'car', 'cars', 'rental', 'rentals', 'transportation', 'transit', 'transfer', 'transfers',
  'round-trip', 'roundtrip', 'one-way', 'oneway', 'nonstop', 'direct', 'connecting', 'layover',
  'accommodation', 'accommodations', 'lodge', 'lodging', 'inn', 'resort', 'resorts', 'guest',
  'hospitality', 'stay', 'staying', 'stayed', 'night', 'nights', 'occupancy', 'single', 'double',
  
  // Dining-related generic terms
  'restaurant', 'restaurants', 'cafe', 'cafes', 'diner', 'diners', 'eatery', 'eateries',
  'meal', 'meals', 'food', 'foods', 'drink', 'drinks', 'breakfast', 'lunch', 'dinner', 'brunch',
  'table', 'tables', 'seat', 'seats', 'menu', 'menus', 'order', 'orders', 'dish', 'dishes',
  'cuisine', 'eating', 'dining', 'service', 'chef', 'waiter', 'waitress', 'appetizer', 'appetizers',
  'entree', 'entrees', 'dessert', 'desserts', 'beverage', 'beverages', 'bill', 'check', 'tip',
  'reservation', 'reserve', 'reserved', 'booking', 'booked', 'party', 'parties', 'serving', 'servings',
  
  // Numbers and measurements
  'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
  'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth',
  '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th',
  'hundred', 'thousand', 'million', 'billion', 'many', 'much', 'multiple', 'several',
  'half', 'quarter', 'portion', 'little', 'lot', 'lots', 'plenty', 'few',
  'number', 'numbers', 'quantity', 'quantities', 'amount', 'amounts',
  
  // Verbs and common actions
  'get', 'getting', 'got', 'gotten', 'make', 'making', 'made', 'take', 'taking', 'took', 'taken',
  'go', 'going', 'went', 'gone', 'come', 'coming', 'came', 'see', 'seeing', 'saw', 'seen',
  'know', 'knowing', 'knew', 'known', 'think', 'thinking', 'thought', 'find', 'finding', 'found',
  'want', 'wanting', 'wanted', 'give', 'giving', 'gave', 'given', 'use', 'using', 'used',
  'work', 'working', 'worked', 'look', 'looking', 'looked', 'like', 'liking', 'liked',
  'need', 'needing', 'needed', 'feel', 'feeling', 'felt', 'try', 'trying', 'tried',
  'call', 'calling', 'called', 'show', 'showing', 'showed', 'shown', 'put', 'putting',
  'bring', 'bringing', 'brought', 'ask', 'asking', 'asked', 'tell', 'telling', 'told',
  
  // Adjectives and descriptors
  'good', 'better', 'best', 'bad', 'worse', 'worst', 'big', 'bigger', 'biggest',
  'small', 'smaller', 'smallest', 'new', 'newer', 'newest', 'old', 'older', 'oldest',
  'high', 'higher', 'highest', 'low', 'lower', 'lowest', 'great', 'greater', 'greatest',
  'nice', 'nicer', 'nicest', 'fine', 'finer', 'finest', 'large', 'larger', 'largest',
  
  // Common conversational words
  'please', 'thank', 'thanks', 'yes', 'no', 'maybe', 'here', 'there', 'well', 'okay', 'ok',
  'sure', 'definitely', 'certainly', 'really', 'actually', 'basically', 'probably', 'possibly',
  'perhaps', 'anyway', 'anyhow', 'regardless', 'though', 'although', 'however', 'whatever',
  'whoever', 'whenever', 'wherever', 'whichever', 'anyway', 'anywhere', 'everywhere', 'somewhere',
  'someone', 'somebody', 'something', 'everyone', 'everybody', 'everything',
  
  // Additional query-specific terms
  'search', 'searching', 'searched', 'find', 'finding', 'found', 'look', 'looking', 'looked',
  'query', 'querying', 'queried', 'locate', 'locating', 'located', 'show', 'showing', 'showed',
  'display', 'displaying', 'displayed', 'list', 'listing', 'listed', 'tell', 'telling', 'told',
  'give', 'giving', 'gave', 'inform', 'informing', 'informed', 'information', 'details',
  'data', 'about', 'regarding', 'concerning', 'related', 'relevant', 'pertaining',
  
  // Business and service related
  'business', 'businesses', 'service', 'services', 'provider', 'providers', 'company', 'companies',
  'store', 'stores', 'shop', 'shops', 'venue', 'venues', 'place', 'places', 'location', 'locations',
  'address', 'addresses', 'contact', 'contacts', 'phone', 'email', 'website', 'site', 'open', 'closed',
  'hours', 'operating', 'available', 'unavailable', 'offer', 'offers', 'offering', 'offered',
  'price', 'prices', 'pricing', 'cost', 'costs', 'costing', 'rate', 'rates', 'rating', 'ratings'
]);

/**
 * Extract deep analysis nodes from the given nodes
 */
function extractDeepAnalysisNodes(nodes: NodeData[], highlightedNodes: string[]): string[] {
  // Find nodes with queryRelevance from deep analysis
  let relevantNodeIds: string[] = [];
  const directMatchNodes = nodes.filter(node => (node as any).matchType === 'direct').map(node => node.id);
  const triangularNodes = nodes.filter(node => (node as any).matchType === 'triangular').map(node => node.id);
  
  // If we have direct matches or triangular nodes from deep analysis, prioritize those
  if (directMatchNodes.length > 0 || triangularNodes.length > 0) {
    Logger.log(`Using deep analysis relevance scores for visualization:`);
    Logger.log(`- ${directMatchNodes.length} direct matches to prioritize`);
    Logger.log(`- ${triangularNodes.length} triangular relationship nodes to include`);
    
    // Add direct matches first (highest priority)
    relevantNodeIds = [...directMatchNodes];
    
    // Then add triangular relationship nodes
    relevantNodeIds.push(...triangularNodes);
    
    // Lastly, add any specifically highlighted nodes that aren't already included
    highlightedNodes.forEach(nodeId => {
      if (!relevantNodeIds.includes(nodeId)) {
        relevantNodeIds.push(nodeId);
      }
    });
  }
  
  return relevantNodeIds;
}

// Create the TTS service
const ttsService = new TTSService();

/**
 * Validates edges to ensure they only reference existing nodes
 */
const validateEdges = (edges: EdgeData[], nodes: NodeData[]): EdgeData[] => {
  if (!edges || !nodes || edges.length === 0 || nodes.length === 0) {
    return edges || [];
  }
  
  // Create a Set of node IDs for fast lookup
  const nodeIds = new Set(nodes.map(node => node.id));
  
  // Filter out edges that reference non-existent nodes
  return edges.filter(edge => {
    // Handle both object and string references
    const sourceId = typeof edge.source === 'object' && edge.source !== null 
      ? (edge.source as { id: string }).id 
      : edge.source as string;
      
    const targetId = typeof edge.target === 'object' && edge.target !== null 
      ? (edge.target as { id: string }).id 
      : edge.target as string;
    
    // Only keep edges where both source and target nodes exist
    return nodeIds.has(sourceId) && nodeIds.has(targetId);
  });
};

/**
 * Handles click on a node
 */
export const handleNodeClick = (
  node: NodeData,
  edges: EdgeData[],
  nodes: NodeData[],
  rightPanelCollapsed: boolean,
  setSelectedNode: React.Dispatch<React.SetStateAction<NodeData | null>>,
  setConnectedNodes: React.Dispatch<React.SetStateAction<NodeData[]>>,
  setConnectedEdges: React.Dispatch<React.SetStateAction<EdgeData[]>>,
  setRightPanelCollapsed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  Logger.log('Node clicked:', node);
  setSelectedNode(node);
  
  // Find connected nodes and edges
  const nodeEdges = edges.filter(edge => {
    const sourceId = typeof edge.source === 'object' && edge.source !== null 
      ? (edge.source as { id: string }).id 
      : edge.source as string;
      
    const targetId = typeof edge.target === 'object' && edge.target !== null 
      ? (edge.target as { id: string }).id 
      : edge.target as string;
      
    return sourceId === node.id || targetId === node.id;
  });
  
  const nodeNeighbors = nodes.filter(n => 
    nodeEdges.some(edge => {
      const sourceId = typeof edge.source === 'object' && edge.source !== null 
        ? (edge.source as { id: string }).id 
        : edge.source as string;
        
      const targetId = typeof edge.target === 'object' && edge.target !== null 
        ? (edge.target as { id: string }).id 
        : edge.target as string;
        
      return (sourceId === node.id && targetId === n.id) ||
              (targetId === node.id && sourceId === n.id);
    })
  );
  
  setConnectedNodes(nodeNeighbors);
  setConnectedEdges(nodeEdges);
  
  // Auto-expand right panel when node is selected
  if (rightPanelCollapsed) {
    setRightPanelCollapsed(false);
  }
};

/**
 * Handles expansion of a node
 */
export const handleNodeExpand = (
  node: NodeData,
  setNodes: React.Dispatch<React.SetStateAction<NodeData[]>>
) => {
  Logger.log('Expanding node:', node.id);
  // Mark the node as expanded
  setNodes(prevNodes => 
    prevNodes.map(n => 
      n.id === node.id ? { ...n, expanded: true } : n
    )
  );
};

/**
 * Handles hiding a node
 */
export const handleHideNode = (
  nodeId: string,
  selectedNode: NodeData | null,
  setFilteredNodes: React.Dispatch<React.SetStateAction<NodeData[]>>,
  setFilteredEdges: React.Dispatch<React.SetStateAction<EdgeData[]>>,
  setSelectedNode: React.Dispatch<React.SetStateAction<NodeData | null>>,
  setConnectedNodes: React.Dispatch<React.SetStateAction<NodeData[]>>,
  setConnectedEdges: React.Dispatch<React.SetStateAction<EdgeData[]>>
) => {
  // Just directly modify the filtered nodes/edges
  setFilteredNodes(prevNodes => prevNodes.filter(n => n.id !== nodeId));
  
  // Also remove any edges connected to this node
  setFilteredEdges(prevEdges => {
    return prevEdges.filter(edge => {
      const sourceId = typeof edge.source === 'object' && edge.source !== null 
        ? (edge.source as { id: string }).id 
        : edge.source as string;
        
      const targetId = typeof edge.target === 'object' && edge.target !== null 
        ? (edge.target as { id: string }).id 
        : edge.target as string;
        
      return sourceId !== nodeId && targetId !== nodeId;
    });
  });
  
  // If this was the selected node, clear selection
  if (selectedNode && selectedNode.id === nodeId) {
    setSelectedNode(null);
    setConnectedNodes([]);
    setConnectedEdges([]);
  }
};

/**
 * Handles regular search
 */
export const handleSearch = (
  query: string,
  nodes: NodeData[],
  edges: EdgeData[],
  filteredNodes: NodeData[],
  filteredEdges: EdgeData[],
  setFilteredNodes: React.Dispatch<React.SetStateAction<NodeData[]>>,
  setFilteredEdges: React.Dispatch<React.SetStateAction<EdgeData[]>>
) => {
  Logger.log('App: handleSearch called with:', query);

  // Remove stopwords from query
  var query = query
    .toLowerCase()
    .split(/\s+/)
    .filter(word => !STOPWORDS.has(word.replace(/[^\w]/g, '')))
    .join(' ')
    .trim();
  
  // Store the search query globally so ForceGraphGL can access it
  (window as any).__activeSearchQuery = query;
  
  // Use our enhanced search functionality to pre-filter nodes if needed
  if (query && query.trim() !== '') {
    // Pre-filter nodes using our improved search function before updating filter service
    const { nodes: preFilteredNodes, edges: connectingEdges }  = filterNodesByQueryLegacy(nodes, edges, query);
    const preFilteredNodeCount = preFilteredNodes.length;
    const totalNodeCount = nodes.length;
    
    Logger.log(`Pre-filtered nodes: ${preFilteredNodeCount} / ${totalNodeCount} nodes match the query`);
    
    // Store the pre-filtered nodes for visualization feedback
    (window as any).__searchMatchedNodeIds = preFilteredNodes.map(node => node.id);
  }
  
  // Update the search query in the filter service
  filterService.setSearchQuery(query);
  
  // Apply all filters and get the updated filtered data
  const filtered = filterService.applyFilters();
  
  // Log original vs filtered node labels to help debug
  const filteredNodeLabels = filtered.nodes.map(n => n.label);
  
  // Validate edges to ensure they only reference existing nodes
  const validatedEdges = validateEdges(filtered.edges, filtered.nodes);
  if (validatedEdges.length !== filtered.edges.length) {
    Logger.log(`Removed ${filtered.edges.length - validatedEdges.length} invalid edges that referenced non-existent nodes during search filtering`);
    filtered.edges = validatedEdges;
  }
  
  Logger.log('App: Search filtering result', { 
    nodesBefore: filteredNodes.length, 
    nodesAfter: filtered.nodes.length, 
    edgesBefore: filteredEdges.length, 
    edgesAfter: filtered.edges.length,
    validatedEdgesCount: validatedEdges.length,
    filteredNodeLabels
  });
  
  // Update the filtered nodes and edges
  setFilteredNodes(filtered.nodes);
  setFilteredEdges(filtered.edges);
  
  // Make sure the simulation is updated with the new filtered data
  if (typeof (window as any).__restartSimulation === 'function') {
    // Cancel any in-progress animations first
    if (typeof (window as any).__cancelPendingAnimations === 'function') {
      (window as any).__cancelPendingAnimations();
    }
    
    // Use a timeout for the restart to avoid multiple restarts
    setTimeout(() => {
      if ((window as any).__restartSimulation) {
        Logger.log("Triggering simulation restart for search with", filtered.nodes.length, "nodes");
        (window as any).__restartSimulation(filtered.nodes, filtered.edges);
      }
    }, 50);
  }
};

/**
 * Handles advanced search using AI pre-filtering, client-side subgraph search,
 * and optional final AI summary/highlighting.
 *
 * @param query The user's voice query (as text).
 * @param nodes The full set of nodes in the graph.
 * @param edges The full set of edges in the graph.
 * @param aiService Wrapper for backend AI service calls (prefilterVoiceQuery, processVoiceQuery).
 * @param handleSearch Fallback basic search function (currently unused on error, could be reinstated).
 * @param setIsProcessingVoice State setter for loading/processing indicator.
 * @param setFilteredNodes State setter for the nodes to be displayed.
 * @param setFilteredEdges State setter for the edges to be displayed.
 * @param setSelectedNodeTypes State setter for UI filters based on node types in results.
 * @param setSelectedEdgeTypes State setter for UI filters based on edge types in results.
 * @param setHumanSummary State setter for the text summary displayed/spoken to the user.
 * @param setSearchResultNodeCount State setter for the count of nodes in the result.
 * @param setSearchResultEdgeCount State setter for the count of edges in the result.
 * @param setSearchResultCommunityCount State setter for the count of distinct communities in the result.
 * @param setLastQueryTime State setter for tracking query duration.
 */
export const handleAdvancedSearch = async (
    query: string,
    nodes: NodeData[], // FULL graph nodes
    edges: EdgeData[], // FULL graph edges
    aiService: any,    // Backend API service wrapper
    handleSearch: (query: string) => void, // Fallback search (optional use)
    setIsProcessingVoice: React.Dispatch<React.SetStateAction<boolean>>,
    setFilteredNodes: React.Dispatch<React.SetStateAction<NodeData[]>>,
    setFilteredEdges: React.Dispatch<React.SetStateAction<EdgeData[]>>,
    setSelectedNodeTypes: React.Dispatch<React.SetStateAction<string[]>>,
    setSelectedEdgeTypes: React.Dispatch<React.SetStateAction<string[]>>,
    setHumanSummary?: React.Dispatch<React.SetStateAction<string>>,
    setSearchResultNodeCount?: React.Dispatch<React.SetStateAction<number | undefined>>,
    setSearchResultEdgeCount?: React.Dispatch<React.SetStateAction<number | undefined>>,
    setSearchResultCommunityCount?: React.Dispatch<React.SetStateAction<number | undefined>>,
    setLastQueryTime?: React.Dispatch<React.SetStateAction<number>>
) => {
    if (!query.trim()) {
        Logger.log('App: handleAdvancedSearch called with empty query, returning.');
        return;
    }

    const startTime = performance.now();
    Logger.log('App: handleAdvancedSearch started. Query:', query);
    setIsProcessingVoice(true);
    // Clear previous summary immediately
    if (setHumanSummary) setHumanSummary('');

    try {
        // --- Step 1: Call LLM Pre-filter Service ---
        // This LLM call analyzes the query and provides a structured plan for the client.
        Logger.log(`App: Calling prefilterVoiceQuery endpoint...`);
        
        // Create a node ID map for the full graph (needed later to ensure returned focus person IDs exist in the graph)
        const allNodeIds = new Set(nodes.map(node => node.id));
        
        // Filter nodes to only include person and memory entities before sending to the service
        const filteredNodesForPrefilter = nodes.filter(node => 
            node.type === 'person' || node.type === 'memory'
        );
        Logger.log(`App: Filtered ${nodes.length - filteredNodesForPrefilter.length} nodes, sending only ${filteredNodesForPrefilter.length} person and memory nodes`);
        
        // Pass only person and memory nodes to the backend endpoint
        const preFilterResult = await aiService.prefilterVoiceQuery(query, filteredNodesForPrefilter);
        Logger.log('App: Received preFilterResult:', preFilterResult);

        // Start timing the subgraph search
        const subgraphSearchStartTime = performance.now();
        Logger.log('App: Starting client-side subgraph search using AdvancedSearchService...');

        const { nodes: subgraphNodes, edges: subgraphEdges } = advancedSearchService.processAdvancedSearch(
            preFilterResult,
            nodes, // Pass the FULL original nodes
            edges  // Pass the FULL original edges
        );

        const subgraphSearchEndTime = performance.now();
        Logger.log(`App: Client-side subgraph search completed in ${(subgraphSearchEndTime - subgraphSearchStartTime).toFixed(2)}ms. Found ${subgraphNodes.length} nodes, ${subgraphEdges.length} edges.`);

        // --- Handle Empty Subgraph Case ---
        if (subgraphNodes.length === 0) {
            Logger.log("App: No relevant subgraph found by AdvancedSearchService.");
            if (setHumanSummary) setHumanSummary("I couldn't find specific information matching your request in the graph.");
            // Clear displayed graph and counts
            setFilteredNodes([]);
            setFilteredEdges([]);
            if (setSearchResultNodeCount) setSearchResultNodeCount(0);
            if (setSearchResultEdgeCount) setSearchResultEdgeCount(0);
            if (setSearchResultCommunityCount) setSearchResultCommunityCount(0);
            setSelectedNodeTypes([]);
            setSelectedEdgeTypes([]);

            const endTime = performance.now();
            if (setLastQueryTime) setLastQueryTime(endTime - startTime);
            setIsProcessingVoice(false);
            Logger.log(`App: Advanced search finished early due to empty subgraph. Total time: ${(endTime - startTime).toFixed(2)}ms.`);
            return; // Stop processing
        }

        // --- Step 3: (Optional) Final LLM Call for Summary/Highlighting ---
        // Sends the *identified subgraph* back to the LLM for a natural language summary and identification of key nodes.
        const processVoiceQueryStartTime = performance.now();
        Logger.log(`App: Calling processVoiceQuery endpoint with subgraph (${subgraphNodes.length} nodes, ${subgraphEdges.length} edges) for final summary/highlighting...`);

        // Pass the original query and the SUBGRAPH
        const finalResult = await aiService.processVoiceQuery(query, subgraphNodes, subgraphEdges);

        const processVoiceQueryEndTime = performance.now();
        const processVoiceQueryDuration = processVoiceQueryEndTime - processVoiceQueryStartTime;
        Logger.log(`App: processVoiceQuery (summary/highlight) completed in ${processVoiceQueryDuration.toFixed(2)}ms`);
        Logger.log('App: Final processing result:', finalResult);

        // --- Step 4: Process Final Result and Update UI ---
        // Extracts summary and important node IDs, updates state, and applies highlighting.

        // Use default empty object if filterSuggestions is missing
        const filterSuggestions = finalResult?.filterSuggestions || {};
        const {
            humanSummary = "Found relevant information.", // Provide a default summary
            importantNodesById = [] // Expecting this name based on LLMService prompt
        } = filterSuggestions;

        // Update and speak summary
        if (setHumanSummary) {
            setHumanSummary(humanSummary);
            // Start speech asynchronously without awaiting
            ttsService.speakText(humanSummary).catch(error => {
                Logger.error('Error completing TTS playback:', error);
            });
        }

        let focusedNodes = subgraphNodes;
        let focusedEdges = subgraphEdges;
        const subgraphNodeIds = new Set(subgraphNodes.map(n => n.id)); // Needed for highlighting logic

        // --- Apply Highlighting based on importantNodesById ---
        if (importantNodesById && importantNodesById.length > 0) {
            Logger.log(`App: Focusing view on ${importantNodesById.length} important nodes and their context within the subgraph.`);
            const importantNodeSet = new Set(importantNodesById);

            // Find user nodes in the original graph
            const userNodeIds = new Set(nodes.filter(isUserNode).map(n => n.id));
            
            // 1. Filter edges: Keep edges that connect between important nodes OR to user nodes
            const filteredHighlightEdges = subgraphEdges.filter(edge => {
                const sourceId = typeof edge.source === 'string' ? edge.source : (edge.source as any)?.id;
                const targetId = typeof edge.target === 'string' ? edge.target : (edge.target as any)?.id;
                
                // Include the edge if:
                // - It connects two important nodes
                // - It connects an important node to a user node
                return (importantNodeSet.has(sourceId) && importantNodeSet.has(targetId)) || 
                       (importantNodeSet.has(sourceId) && userNodeIds.has(targetId)) ||
                       (importantNodeSet.has(targetId) && userNodeIds.has(sourceId));
            });
            
            // 2. Determine final nodes: Include all important nodes, user nodes, and nodes connected by edges
            const finalNodeIds = new Set<string>([...importantNodesById]);
            
            // Add relevant user nodes
            filteredHighlightEdges.forEach(edge => {
                const sourceId = typeof edge.source === 'string' ? edge.source : (edge.source as any)?.id;
                const targetId = typeof edge.target === 'string' ? edge.target : (edge.target as any)?.id;
                finalNodeIds.add(sourceId);
                finalNodeIds.add(targetId);
            });

            // 3. Filter nodes: Keep only the nodes in the finalNodeIds set.
            const filteredHighlightNodes = subgraphNodes.filter(node => finalNodeIds.has(node.id));

            // 4. Enhance nodes: Mark important nodes and adjust size for rendering.
            const enhancedFilteredNodes = filteredHighlightNodes.map(node => ({
                ...node,
                important: importantNodeSet.has(node.id), // Mark for visualization
                // Increase size for emphasis, ensure size property exists or default to 1
                size: importantNodeSet.has(node.id) ? ((node.size ?? 1) * 1.5) : (node.size ?? 1)
            }));

            Logger.log(`App: Focused view contains ${enhancedFilteredNodes.length} nodes and ${filteredHighlightEdges.length} edges.`);

            // Use these focused collections for the final state update
            focusedNodes = enhancedFilteredNodes;
            focusedEdges = filteredHighlightEdges;
            (window as any).__importantNodeIds = importantNodesById; // Expose for debugging/rendering if needed
        } else {
             Logger.log(`App: No important nodes returned by final LLM call, showing the full subgraph found by AdvancedSearchService.`);
             // Ensure nodes don't have the 'important' flag if none were specified
             focusedNodes = focusedNodes.map(({important, ...node}) => node);
             if ((window as any).__importantNodeIds) delete (window as any).__importantNodeIds;
        }

        // --- Update Final State ---
        setFilteredNodes(focusedNodes);
        setFilteredEdges(focusedEdges);

        // Update counts based on the *final focused* subgraph
        if (setSearchResultNodeCount) setSearchResultNodeCount(focusedNodes.length);
        if (setSearchResultEdgeCount) setSearchResultEdgeCount(focusedEdges.length);

        // Calculate and set community count for the focused subgraph
        if (setSearchResultCommunityCount) {
            const communitySet = new Set<string | number>();
            focusedNodes.forEach(node => {
                // Check if community property exists and is valid
                if (node.community !== undefined && node.community !== null) {
                    communitySet.add(node.community);
                }
            });
            setSearchResultCommunityCount(communitySet.size);
            Logger.log(`App: Found ${communitySet.size} distinct communities in the focused subgraph.`);
        }

        // Update selected types for UI filters based on the focused subgraph
        setSelectedNodeTypes([...new Set(focusedNodes.map(n => n.type))]);
        setSelectedEdgeTypes([...new Set(focusedEdges.map(e => e.type))]);


        // --- Restart Simulation ---
        // Trigger graph visualization update if applicable
        if (typeof (window as any).__restartSimulation === 'function') {
            Logger.log('App: Requesting simulation restart.');
            if (typeof (window as any).__cancelPendingAnimations === 'function') {
                (window as any).__cancelPendingAnimations();
            }
            // Use a timeout to allow state updates to potentially propagate before restarting
            setTimeout(() => {
                if ((window as any).__restartSimulation) {
                    (window as any).__restartSimulation(focusedNodes, focusedEdges);
                    Logger.log('App: Simulation restart function called.');
                }
            }, 50); // Adjust delay if needed
        }

        // --- Final Timing and Cleanup ---
        const endTime = performance.now();
        const totalDuration = endTime - startTime;
        if (setLastQueryTime) setLastQueryTime(totalDuration);
        Logger.log(`App: Advanced search completed successfully in ${totalDuration.toFixed(2)}ms.`);

        // Short delay before hiding processing indicator for smoother UX
        setTimeout(() => setIsProcessingVoice(false), 300);

    } catch (error) {
        const errorEndTime = performance.now();
        const errorDuration = errorEndTime - startTime;
        Logger.error(`App: Error processing advanced search after ${errorDuration.toFixed(2)}ms:`, error);

        // Provide user feedback on error
        if (setHumanSummary) {
            const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred.';
            setHumanSummary(`Sorry, I encountered an error: ${errorMessage}`);
            // Optionally try to speak the error
             ttsService.speakText(`Sorry, there was an error processing your request.`).catch(ttsError => {
                 Logger.error('Error speaking error message:', ttsError);
             });
        }

        // Reset graph state on error to avoid showing potentially incorrect partial results
        setFilteredNodes([]);
        setFilteredEdges([]);
        if (setSearchResultNodeCount) setSearchResultNodeCount(0);
        if (setSearchResultEdgeCount) setSearchResultEdgeCount(0);
        if (setSearchResultCommunityCount) setSearchResultCommunityCount(0);
        setSelectedNodeTypes([]);
        setSelectedEdgeTypes([]);

        // Decide on fallback: currently just shows error and clears results.
        // handleSearch(query); // Uncomment to fallback to basic search

        setIsProcessingVoice(false); // Ensure processing indicator is turned off
    }
};


/**
 * Handles filter changes
 */
export const handleFilter = (
  filters: any,
  nodes: NodeData[],
  edges: EdgeData[],
  setSelectedNodeTypes: React.Dispatch<React.SetStateAction<string[]>>,
  setSelectedEdgeTypes: React.Dispatch<React.SetStateAction<string[]>>,
  setFilteredNodes: React.Dispatch<React.SetStateAction<NodeData[]>>,
  setFilteredEdges: React.Dispatch<React.SetStateAction<EdgeData[]>>
) => {
  Logger.log('App: handleFilter called with:', filters);
  
  // Clear the current filtered nodes when manually changing filters
  // This ensures we don't use voice query context for regular filtering
  if ((window as any).__currentFilteredNodes) {
    delete (window as any).__currentFilteredNodes;
    Logger.log('App: Cleared __currentFilteredNodes for manual filter change');
  }
  
  // Check if this is a filter reset (all node and edge types are selected)
  const allNodeTypes = [...new Set(nodes.map(node => node.type))];
  const allEdgeTypes = [...new Set(edges.map(edge => edge.type))];
  
  const isFilterReset = 
    // Check if all node types are selected (length match and all items included)
    (filters.nodeTypes.length === allNodeTypes.length && 
     allNodeTypes.every(type => filters.nodeTypes.includes(type))) &&
    // Check if all edge types are selected (length match and all items included)
    (filters.edgeTypes.length === allEdgeTypes.length && 
     allEdgeTypes.every(type => filters.edgeTypes.includes(type)));
  
  Logger.log('Is filter reset:', isFilterReset);
  
  // Preserve user nodes by finding their node types and ensuring they're included
  const userNodes = nodes.filter(node => node.user === true);
  const userNodeTypes = [...new Set(userNodes.map(node => node.type))];
  
  // Ensure user node types are included in filters
  const preservedNodeTypes = [...new Set([...filters.nodeTypes, ...userNodeTypes])];
  
  // Log any user types that were preserved
  const preservedTypes = userNodeTypes.filter(type => !filters.nodeTypes.includes(type));
  if (preservedTypes.length > 0) {
    Logger.log('App: Preserving user node types that were unchecked:', preservedTypes);
    
    // Expose the preserved types on the window for UI feedback
    (window as any).__preservedUserNodeTypes = preservedTypes;
  } else {
    // Clear the preserved types when none are being preserved
    (window as any).__preservedUserNodeTypes = [];
  }
  
  // Update filter service with new filter settings while preserving the search query
  // Pass preservedNodeTypes instead of filters.nodeTypes to ensure user node types are included
  filterService.setNodeTypeFilters(preservedNodeTypes);
  filterService.setEdgeTypeFilters(filters.edgeTypes);
  
  // Update local state for selected types
  setSelectedNodeTypes(preservedNodeTypes);
  setSelectedEdgeTypes(filters.edgeTypes);
  
  // Store selected node and edge types globally
  (window as any).__selectedNodeTypes = preservedNodeTypes;
  (window as any).__selectedEdgeTypes = filters.edgeTypes;
  
  // Apply all filters through the filter service
  const filtered = filterService.applyFilters();
  
  // Update filtered nodes and edges
  setFilteredNodes(filtered.nodes);
  setFilteredEdges(filtered.edges);
  
  // Make sure the simulation is updated with the new filtered data
  if (typeof (window as any).__restartSimulation === 'function') {
    Logger.log("Triggering simulation restart for filter changes with", filtered.nodes.length, "nodes");
    
    // Cancel any in-progress animations first
    if (typeof (window as any).__cancelPendingAnimations === 'function') {
      (window as any).__cancelPendingAnimations();
    }
    
    // Update right away with the filtered data
    (window as any).__restartSimulation(filtered.nodes, filtered.edges);
    
    // Use another timeout to ensure the update is applied
    setTimeout(() => {
      if ((window as any).__restartSimulation) {
        // Passing both nodes and edges forces a full restart
        (window as any).__restartSimulation(filtered.nodes, filtered.edges);
      }
    }, 200);
  }
  
  // Get a reference to fitViewToNodes if it exists in the window
  const fitViewFunc = (window as any).__fitViewToNodes;
  
  // If this is a filter reset, trigger the gentle animation
  if (isFilterReset && typeof fitViewFunc === 'function') {
    Logger.log('Using gentle animation for filter reset');
    // Use setTimeout to let the state update first
    setTimeout(() => {
      fitViewFunc(filtered.nodes, { isFilterReset: true });
    }, 50);
  }
};

/**
 * Handles maxHops change
 */
export const handleMaxHopsChange = (
  hops: number,
  maxHops: number,
  filteredNodes: NodeData[],
  setMaxHops: React.Dispatch<React.SetStateAction<number>>,
  setFilteredNodes: React.Dispatch<React.SetStateAction<NodeData[]>>,
  setFilteredEdges: React.Dispatch<React.SetStateAction<EdgeData[]>>
) => {
  Logger.log('App: handleMaxHopsChange called with:', hops);
  
  // Store the previous maxHops value for comparison
  const previousMaxHops = maxHops;
  Logger.log(`Previous maxHops: ${previousMaxHops}, New maxHops: ${hops}`);
  
  // Update maxHops state
  setMaxHops(hops);
  
  // Update maxHops in the filter service
  filterService.setMaxHops(hops);
  
  // Before applying filters, make sure we preserve any highlighted nodes
  // First check if we have any highlighted nodes in the current filteredNodes
  const currentHighlightedNodes = filteredNodes.filter(node => node.highlighted);
  
  // CRITICAL: Always store current filtered nodes as our base for expansion
  // This ensures we always expand from the current view
  Logger.log('Storing current filtered nodes for expansion/reduction');
  (window as any).__currentFilteredNodes = [...filteredNodes];
  
  // Store the previous maxHops value in a global variable for hop reduction tracking
  (window as any).__previousMaxHops = previousMaxHops;
  
  // Apply all filters with the new maxHops value - we'll expand from current state
  const filtered = filterService.applyFilters();
  
  // Preserve the highlighting on filtered nodes to maintain context
  let updatedFilteredNodes = filtered.nodes;
  
  if (currentHighlightedNodes.length > 0) {
    Logger.log(`Preserving ${currentHighlightedNodes.length} highlighted nodes for maxHops filtering`);
    
    // Create a map of highlighted node IDs for efficient lookup
    const highlightedNodeIds = new Set(currentHighlightedNodes.map(node => node.id));
    
    // Update filtered nodes to preserve highlighting
    updatedFilteredNodes = filtered.nodes.map(node => {
      if (highlightedNodeIds.has(node.id)) {
        return { ...node, highlighted: true };
      }
      return node;
    });
  }
  
  // Update the state with filtered data
  setFilteredNodes(updatedFilteredNodes);
  setFilteredEdges(filtered.edges);
  
  // Make sure the simulation is updated with the new filtered data
  if (typeof (window as any).__restartSimulation === 'function') {
    Logger.log("Triggering simulation restart for maxHops changes with", updatedFilteredNodes.length, "nodes");
    
    // Cancel any in-progress animations first
    if (typeof (window as any).__cancelPendingAnimations === 'function') {
      (window as any).__cancelPendingAnimations();
    }
    
    // Update right away with the filtered data
    (window as any).__restartSimulation(updatedFilteredNodes, filtered.edges);
    
    // Use another timeout to ensure the update is applied
    setTimeout(() => {
      if ((window as any).__restartSimulation) {
        (window as any).__restartSimulation(updatedFilteredNodes, filtered.edges);
      }
    }, 200);
  }
};


/**
 * Toggle the left panel
 */
export const toggleLeftPanel = (
  setLeftPanelCollapsed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLeftPanelCollapsed(prev => !prev);
};

/**
 * Toggle the right panel
 */
export const toggleRightPanel = (
  setRightPanelCollapsed: React.Dispatch<React.SetStateAction<boolean>>,
  setIsDetailPanelExpanded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setRightPanelCollapsed(prev => !prev);
  setIsDetailPanelExpanded(false);
};

/**
 * Handler for tour completion
 */
export const handleTourComplete = (
  setShowTour: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setShowTour(false);
  localStorage.setItem('tourCompleted', 'true');
};

/**
 * Handler for starting the tour
 */
export const handleStartTour = (
  setShowTour: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setShowTour(true);
};

/**
 * Handler for closing the welcome screen
 */
export const handleWelcomeClose = (
  setShowWelcome: React.Dispatch<React.SetStateAction<boolean>>,
  setViewMode: React.Dispatch<React.SetStateAction<'welcome' | 'focused' | 'full'>>
) => {
  setShowWelcome(false);
  setViewMode('full');
  localStorage.setItem('welcomeShown', 'true');
};

/**
 * Handler for highlighting nodes
 */
export const handleHighlightNodes = (
  nodeIds: string[],
  nodes: NodeData[],
  edges: EdgeData[],
  setFilteredNodes: React.Dispatch<React.SetStateAction<NodeData[]>>,
  setFilteredEdges: React.Dispatch<React.SetStateAction<EdgeData[]>>
) => {
  Logger.log('Highlighting specific nodes:', nodeIds);
  
  // Mark selected nodes as highlighted and exclude others
  const highlightedNodes = nodes.filter(node => 
    nodeIds.includes(node.id)
  ).map(node => ({
    ...node,
    highlighted: true
  }));
  
  // Find edges that connect these highlighted nodes
  const connectingEdges = edges.filter(edge => {
    const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source;
    const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target;
    
    return nodeIds.includes(sourceId) && nodeIds.includes(targetId);
  });
  
  // Apply the highlight filter
  setFilteredNodes(highlightedNodes);
  setFilteredEdges(connectingEdges);
  
  // Flag to indicate a highlight operation is active
  (window as any).__highlightActive = true;
  
  // Restart the simulation to reflect changes
  if (typeof (window as any).__restartSimulation === 'function') {
    setTimeout(() => {
      if ((window as any).__restartSimulation) {
        (window as any).__restartSimulation(highlightedNodes, connectingEdges);
      }
    }, 50);
  }
};

/**
 * Handler for highlighting a community
 */
export const handleHighlightCommunity = (
  communityId: string,
  communities: Record<string, string>,
  nodes: NodeData[],
  edges: EdgeData[],
  setFilteredNodes: React.Dispatch<React.SetStateAction<NodeData[]>>,
  setFilteredEdges: React.Dispatch<React.SetStateAction<EdgeData[]>>
) => {
  Logger.log('Highlighting community:', communityId);
  
  // Find all nodes in this community
  const communityNodeIds = Object.entries(communities)
    .filter(([_, comm]) => comm === communityId)
    .map(([nodeId]) => nodeId);
  
  // Mark community nodes as highlighted and exclude others
  const highlightedNodes = nodes.filter(node => 
    communityNodeIds.includes(node.id)
  ).map(node => ({
    ...node,
    highlighted: true
  }));
  
  // Find edges that connect nodes within this community
  const connectingEdges = edges.filter(edge => {
    const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source;
    const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target;
    
    return communityNodeIds.includes(sourceId) && communityNodeIds.includes(targetId);
  });
  
  // Apply the highlight filter
  setFilteredNodes(highlightedNodes);
  setFilteredEdges(connectingEdges);
  
  // Flag to indicate a highlight operation is active
  (window as any).__highlightActive = true;
  (window as any).__highlightedCommunity = communityId;
  
  // Restart the simulation to reflect changes
  if (typeof (window as any).__restartSimulation === 'function') {
    setTimeout(() => {
      if ((window as any).__restartSimulation) {
        (window as any).__restartSimulation(highlightedNodes, connectingEdges);
      }
    }, 50);
  }
};

/**
 * Save graph data
 */
export const saveGraphData = async (
  updatedNodes: NodeData[] | undefined, 
  updatedEdges: EdgeData[] | undefined,
  nodes: NodeData[],
  edges: EdgeData[]
) => {
  try {
    Logger.log('Saving graph data to API...');
    const graphData = { 
      nodes: updatedNodes || nodes, 
      edges: updatedEdges || edges 
    };
    
    const result = await graphService.saveGraphData(graphData);
    if (result) {
      Logger.log('Graph data saved successfully');
    } else {
      Logger.warn('Failed to save graph data');
    }
  } catch (error) {
    Logger.error('Error saving graph data:', error);
  }
};

/**
 * Get community color for a node
 */
export const getNodeCommunityColor = (
  nodeId: string, 
  communities: Record<string, string>,
  options?: { searchResults?: boolean }
): string => {
  // If searching (including voice or advanced search), don't use community colors
  const isSearchActive = (window as any).__activeSearchQuery || options?.searchResults;
  
  if (isSearchActive) {
    // Return empty string to fallback to entity type coloring in NodeComponent
    return '';
  }
  
  // Standard community coloring mode for non-search state
  const communityId = communities[nodeId];
  if (!communityId) return '';
  
  // Generate a consistent color based on the community ID
  const hash = parseInt(communityId, 10) || 
              communityId.split('').reduce(
                (hash, char) => (hash * 31 + char.charCodeAt(0)) & 0xFFFFFFFF, 0
              );
  
  // Use the hash to generate an HSL color with good saturation and lightness
  const hue = hash % 360;
  return `hsl(${hue}, 70%, 60%)`;
};