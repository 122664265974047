import React from 'react';
import { inject, observer } from 'mobx-react';

import withPostHog from 'hooks/withPosthog';
import AuthenticatedPage from 'components/common/AuthenticatedPage';
import BasePage from 'components/common/BasePage';
import Card from 'components/common/Card';

import '../../brand/index.scss';
import './index.scss';

type TelemetryProps = {
    TelemetryStore?: any;
    userEvents?: any;
}

type TelemetryState = {
    summary: string;
    selected: string;
}

const FLAG = 'experimental-ui-telemetry';

const JsonContentRenderer = ({ content }) => {
    if (!content || !Array.isArray(content)) {
        return <div>No content to display</div>;
    }

    const renderContent = (item) => {
        // Handle case where item is a string
        if (typeof item === 'string') {
            return item;
        }

        const { type, content } = item;

        // If content is an array, recursively render each item
        if (Array.isArray(content)) {
            const childElements = content.map((child, index) => (
                <React.Fragment key={index}>{renderContent(child)}</React.Fragment>
            ));

            // Dynamically create the element with the specified type and children
            return React.createElement(type, { key: `${type}-${Math.random()}` }, childElements);
        }

        // For simple content (type + text)
        return React.createElement(type, { key: `${type}-${Math.random()}` }, content);
    };

    return (
        <div className="json-content-container">
            {content.map((item, index) => (
                <React.Fragment key={index}>{renderContent(item)}</React.Fragment>
            ))}
        </div>
    );
};

class Telemetry extends React.Component<TelemetryProps, TelemetryState> {
    state = {
        selected: 'network',
        summary: ''
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { TelemetryStore, userEvents } = this.props;
        const { selected } = this.state;

        if (!userEvents?.posthog.isFeatureEnabled(FLAG)) {
            console.warn(`${FLAG} is not enabled for this user, aborting`);
            return;
        }

        const API = TelemetryStore[selected === 'network' ? 'getNetworkSummary' : 'getBatterySummary']
        API().then(response => {
            const summary = JSON.parse(response.content[0].text);
            this.setState({ summary })
        });
    }

    handleToggleClick = (selected: string) => {
        this.setState({ selected, summary: '' }, this.loadData);
    }

    renderContent = () => {
        const { TelemetryStore } = this.props;
        const { summary, selected } = this.state;

        return (
            <>
                <div className="toggle-switch-container">
                    <div
                        className={`toggle-switch ${selected === 'network' ? 'active' : ''}`}
                        onClick={() => this.handleToggleClick('network')}
                    >
                        <span>Network</span>
                    </div>
                    <div
                        className={`toggle-switch ${selected === 'battery' ? 'active' : ''}`}
                        onClick={() => this.handleToggleClick('battery')}
                    >
                        <span>Battery</span>
                    </div>
                </div>

                <div className="adapter-network-analysis">
                    <Card>
                        <JsonContentRenderer
                            content={
                                TelemetryStore?.loading
                                    ? [{ type: 'h2', content: 'Loading telemetry analysis...' }]
                                    : summary
                            }
                        />
                    </Card>
                </div>
            </>
        );
    }

    render() {
        const { userEvents } = this.props;

        let content = this.renderContent();

        if (!userEvents?.posthog.isFeatureEnabled(FLAG)) {
            content = (
                <div className="adapter-network-analysis">
                    <Card>
                        <h2>Access Denied</h2>

                        <p>This page has experimental features not enabled for your user.</p>
                    </Card>
                </div>
            );
        }

        return (
            <AuthenticatedPage>
                <BasePage innerCls="adapter-bg-base-100">
                    <div className="adapter-fullscreen-content">
                        <div className="flex-1" />

                        {content}

                        <div className="flex-1" />
                    </div>
                </BasePage>
            </AuthenticatedPage>
        );
    }
};

export default withPostHog(inject("TelemetryStore")(observer(Telemetry)));