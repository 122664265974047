import React from 'react';

const Close = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clipPath="url(#clip0_7022_5213)">
            <path
                d="M9.16669 3.33417H5.66669C4.26656 3.33417 3.56649 3.33417 3.03171 3.60666C2.56131 3.84634 2.17885 4.22879 1.93917 4.6992C1.66669 5.23398 1.66669 5.93404 1.66669 7.33417V14.3342C1.66669 15.7343 1.66669 16.4344 1.93917 16.9691C2.17885 17.4396 2.56131 17.822 3.03171 18.0617C3.56649 18.3342 4.26656 18.3342 5.66669 18.3342H12.6667C14.0668 18.3342 14.7669 18.3342 15.3017 18.0617C15.7721 17.822 16.1545 17.4396 16.3942 16.9691C16.6667 16.4344 16.6667 15.7343 16.6667 14.3342V10.8342M6.66666 13.3342H8.06212C8.46977 13.3342 8.67359 13.3342 8.86541 13.2881C9.03547 13.2473 9.19804 13.18 9.34716 13.0886C9.51536 12.9855 9.65948 12.8414 9.94773 12.5531L17.9167 4.58417C18.607 3.89382 18.607 2.77453 17.9167 2.08417C17.2263 1.39382 16.107 1.39382 15.4167 2.08417L7.44772 10.0531C7.15946 10.3414 7.01534 10.4855 6.91227 10.6537C6.82088 10.8028 6.75354 10.9654 6.71271 11.1355C6.66666 11.3273 6.66666 11.5311 6.66666 11.9387V13.3342Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_7022_5213">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default Close;

