// src/services/TTSService.ts
import Logger from 'util/Logger';
import API from 'util/API'

/**
 * Text-to-Speech service using ElevenLabs API via backend API
 * Falls back to Web Speech API if backend is unavailable
 */
export class TTSService {
  private speechSynthesis: SpeechSynthesis | null = null;
  private voices: SpeechSynthesisVoice[] = [];
  private isInitialized: boolean = false;
  private preferredVoice: SpeechSynthesisVoice | null = null;
  private audioQueue: string[] = [];
  private isPlaying: boolean = false;
  private audioContext: AudioContext | null = null;
  private currentUtterance: SpeechSynthesisUtterance | null = null;
  private audioElement: HTMLAudioElement | null = null;
  private hasUserInteracted: boolean = false;
  
  // Backend API settings
  private backendUrl: string;
  private useServerSideTTS: boolean = true;
  private audioCache: Map<string, string> = new Map();
  
  constructor() {
    // Configure backend URL with dynamic detection
    // Import service config here to ensure it's evaluated at runtime
    const serviceConfig = require('./ServiceConfig').default;
    
    this.backendUrl = serviceConfig.ttsApiUrl;
    Logger.log(`TTSService initialized with backend URL: ${this.backendUrl}`);
    
    // Initialize when in browser context
    if (typeof window !== 'undefined') {
      // Initialize Web Speech API as fallback
      this.speechSynthesis = window.speechSynthesis;
      this.loadVoices();
      
      // Create a reusable audio element for playing sounds
      this.audioElement = new Audio();
      
      // Set up user interaction listeners for Mobile Safari
      this.setupUserInteractionListeners();
      
      // Start initialization process
      this.initialize().catch(error => {
        Logger.error('Error initializing TTS service:', error);
      });
    } else {
      Logger.warn('TTSService initialized outside browser context, some features may not work');
    }
  }
  
  /**
   * Set up event listeners to track user interactions
   * This is important for Mobile Safari which requires user interaction before playing audio
   */
  private setupUserInteractionListeners(): void {
    if (typeof window === 'undefined') return;
    
    const userInteractionEvents = ['click', 'touchend', 'keydown'];
    
    const handleUserInteraction = () => {
      if (!this.hasUserInteracted) {
        this.hasUserInteracted = true;
        Logger.log('User interaction detected, initializing audio context');
        this.initAudioContext();
        this.unlockAudioForSafari();
      }
    };
    
    userInteractionEvents.forEach(eventType => {
      window.addEventListener(eventType, handleUserInteraction, { once: false });
    });
  }
  
  /**
   * Check if browser is Safari/iOS
   */
  private isMobileSafari(): boolean {
    if (typeof navigator === 'undefined') return false;
    
    const ua = navigator.userAgent;
    // MSStream is used in older IE mobile detection but it's not in standard TypeScript definitions
    // We can simply check for iOS and Safari patterns instead
    return (/iPhone|iPad|iPod/.test(ua) && /Safari/.test(ua) && !/Chrome/.test(ua));
  }
  
  /**
   * Unlock audio playback specifically for Safari/iOS
   * Mobile Safari requires playing a silent sound after user interaction
   * This version uses the Web Audio API to avoid CSP issues with data URIs
   */
  private unlockAudioForSafari(): void {
    // Use Web Audio API instead of direct data URI to avoid CSP restrictions
    if (!this.audioContext) {
      this.initAudioContext();
      if (!this.audioContext) return; // Still no audio context, can't unlock
    }
    
    try {
      // Create a short silent buffer
      const sampleRate = this.audioContext.sampleRate;
      const buffer = this.audioContext.createBuffer(1, sampleRate * 0.01, sampleRate);
      const source = this.audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(this.audioContext.destination);
      source.start(0);
      Logger.log('Audio playback unlocked for Safari using AudioContext');
    } catch (error) {
      Logger.warn('Could not unlock audio playback for Safari using AudioContext:', error);
      
      // Fallback to the original method with Audio element
      if (this.audioElement) {
        // Try using a server-side audio file instead of data URI
        try {
          // Assuming your app has a tiny silent MP3 file available at this path
          const silentSoundUrl = '/static/silent.mp3';
          
          this.audioElement.src = silentSoundUrl;
          this.audioElement.play().then(() => {
            Logger.log('Audio playback unlocked for Safari using audio file');
          }).catch(err => {
            Logger.warn('Could not unlock audio playback for Safari using audio file:', err);
          });
        } catch (err) {
          Logger.warn('Complete failure unlocking audio for Safari:', err);
        }
      }
    }
  }
  
  /**
   * Initialize the audio context (for playing audio from ElevenLabs)
   * Only initializes after user interaction to comply with autoplay policies
   */
  private initAudioContext(): void {
    try {
      const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
      if (AudioContext) {
        this.audioContext = new AudioContext();
        
        // Resume the audio context if it's in suspended state
        if (this.audioContext.state === 'suspended') {
          this.audioContext.resume().catch(error => {
            Logger.warn('Could not resume AudioContext:', error);
          });
        }
      }
    } catch (error) {
      Logger.error('Failed to initialize AudioContext:', error);
    }
  }
  
  /**
   * Public method to explicitly initialize audio for Mobile Safari
   * Can be called directly from user interaction handlers in components
   */
  public initAudioForMobileSafari(): void {
    if (!this.hasUserInteracted) {
      this.hasUserInteracted = true;
      this.initAudioContext();
      this.unlockAudioForSafari();
    }
  }
  
  /**
   * Load available voices and select a preferred voice
   */
  private async loadVoices(): Promise<void> {
    if (!this.speechSynthesis) return;
    
    // Get the available voices
    const getVoices = (): SpeechSynthesisVoice[] => {
      return this.speechSynthesis?.getVoices() || [];
    };
    
    // Try to get voices immediately
    this.voices = getVoices();
    
    // If no voices are available, wait for the voiceschanged event
    if (this.voices.length === 0) {
      await new Promise<void>((resolve) => {
        if (!this.speechSynthesis) {
          resolve();
          return;
        }
        
        this.speechSynthesis.onvoiceschanged = () => {
          this.voices = getVoices();
          resolve();
        };
        
        // Set a timeout in case the event never fires
        setTimeout(resolve, 1000);
      });
    }
    
    // Select a preferred voice (English, female if available)
    this.selectPreferredVoice();
    
    this.isInitialized = true;
    Logger.log(`TTS initialized with ${this.voices.length} voices available.`);
    if (this.preferredVoice) {
      Logger.log(`Selected voice: ${this.preferredVoice.name} (${this.preferredVoice.lang})`);
    }
  }
  
  /**
   * Select the preferred voice from available voices
   */
  private selectPreferredVoice(): void {
    if (this.voices.length === 0) return;
    
    // Try to find an English (US) female voice
    // Order of preference: en-US female > en-US male > en-GB female > en-GB male > any English > any
    
    // First try: en-US female
    const femaleUSVoice = this.voices.find(voice => 
      voice.lang.includes('en-US') && voice.name.includes('Female'));
    if (femaleUSVoice) {
      this.preferredVoice = femaleUSVoice;
      return;
    }
    
    // Second try: en-US male
    const maleUSVoice = this.voices.find(voice => 
      voice.lang.includes('en-US'));
    if (maleUSVoice) {
      this.preferredVoice = maleUSVoice;
      return;
    }
    
    // Third try: en-GB female
    const femaleGBVoice = this.voices.find(voice => 
      voice.lang.includes('en-GB') && voice.name.includes('Female'));
    if (femaleGBVoice) {
      this.preferredVoice = femaleGBVoice;
      return;
    }
    
    // Fourth try: en-GB male
    const maleGBVoice = this.voices.find(voice => 
      voice.lang.includes('en-GB'));
    if (maleGBVoice) {
      this.preferredVoice = maleGBVoice;
      return;
    }
    
    // Fifth try: any English
    const anyEnglishVoice = this.voices.find(voice => 
      voice.lang.includes('en'));
    if (anyEnglishVoice) {
      this.preferredVoice = anyEnglishVoice;
      return;
    }
    
    // Fallback: any voice
    if (this.voices.length > 0) {
      this.preferredVoice = this.voices[0];
    }
  }
  
  /**
   * Initialize the TTS service and check for backend availability
   */
  public async initialize(): Promise<void> {
    if (this.isInitialized) return;
    
    // Load Web Speech API voices as fallback
    await this.loadVoices();
    
    // Check if the backend TTS service is available
    try {
      Logger.log(`Checking TTS backend service availability at: ${this.backendUrl}/health`);
      this.useServerSideTTS = true;
    } catch (error) {
      Logger.warn('TTS backend service not available, falling back to Web Speech API');
      Logger.warn('Error details:', error);
      this.useServerSideTTS = true;
    }
    
    this.isInitialized = true;
    
    return Promise.resolve();
  }
  
  /**
   * Convert text to speech and play it
   * 
   * This method starts the speech playback immediately and returns a Promise
   * that resolves when speech is complete. However, the caller can choose
   * not to await this Promise for non-blocking operation.
   */
  public async speakText(text: string): Promise<void> {
    // Make sure service is initialized
    if (!this.isInitialized) {
      await this.initialize();
    }
    
    // Trim and limit the text length for better performance
    // Increased from 500 to 2000 chars to support longer responses
    const trimmedText = text.trim().substring(0, 3000); // Limit to 3000 chars for reasonable audio length
    if (trimmedText.length === 0) {
      Logger.warn('Empty text provided to TTS service');
      return Promise.resolve();
    }
    
    // Log the speech start
    Logger.log(`Speaking: "${trimmedText}"`);
    
    // Stop any current audio
    this.stopPlayback();
    
    // Try server-side TTS first if available
    if (true) {
      try {
        // We'll first check if this text is already in the cache
        const cacheCheckResponse = await API.post(`/api/tts/check-cache`, {
          text: trimmedText
        });
        
        if (cacheCheckResponse.ok) {
          const cacheData = await cacheCheckResponse.json();
          
          // If in cache, use the hash to get the audio file directly
          if (cacheData.inCache && cacheData.hash) {
            Logger.log('Found cached audio on server');
            return this.playAudioFromBackend(cacheData.hash);
          }
        }
        
        // If not in cache, generate new audio
        return await this.speakWithBackend(trimmedText);
      } catch (error) {
        Logger.error('Error using backend TTS service, falling back to Web Speech API:', error);
        // Fall back to Web Speech API if backend fails
      }
    }
    
    // Fallback to Web Speech API
    return this.speakWithWebSpeechAPI(trimmedText);
  }
  
  /**
   * Use backend TTS service to generate and play audio
   */
  private async speakWithBackend(text: string): Promise<void> {
    if (!this.audioElement) {
      throw new Error('Audio element not available');
    }
    
    try {
      // Check if we already have this text cached
      const cachedUrl = this.audioCache.get(text);
      if (cachedUrl) {
        Logger.log('Using cached audio URL');
        return this.playAudioUrl(cachedUrl);
      }
      
      Logger.log('Generating speech with ElevenLabs...');
      
      // Request speech generation from backend
      const response = await API.post(`/api/tts/generate`, {
        text
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Backend TTS error (${response.status}): ${errorText}`);
      }
      
      const data = await response.json();
      
      if (!data.success || !data.hash) {
        throw new Error('Invalid response from TTS backend');
      }
      
      Logger.log(`Audio generated ${data.cacheHit ? '(from cache)' : '(newly generated)'}`);
      
      // Play the audio using the hash
      return this.playAudioFromBackend(data.hash);
    } catch (error) {
      Logger.error('Error using backend TTS:', error);
      throw error;
    }
  }
  
  /**
   * Play audio from the backend using the hash
   */
  private async playAudioFromBackend(hash: string): Promise<void> {
    if (!this.audioElement) {
      throw new Error('Audio element not available');
    }
    
    // Construct the audio URL
    const audioUrl = `/api/tts/audio/${hash}`;
    Logger.log(`Playing audio from URL: ${audioUrl}`);
    
    // Add cache-busting parameter to prevent browser caching issues
    // This helps with CORS errors when a file is first cached incorrectly
    const cacheBuster = Date.now();
    const urlWithCacheBuster = `${audioUrl}?_=${cacheBuster}`;
    
    try {
      
      // Play the audio
      return this.playAudioUrl(urlWithCacheBuster);
    } catch (error) {
      Logger.error('Error preparing audio from backend:', error);
      throw error;
    }
  }
  
  /**
   * Play audio from a URL
   * Now with support for Web Audio API as a fallback for CSP restrictions on data URIs
   */
  private playAudioUrl(url: string): Promise<void> {
    if (!this.audioElement) {
      return Promise.reject(new Error('Audio element not available'));
    }
    
    // If URL is a data URI and we have AudioContext available, use Web Audio API instead
    if (url.startsWith('data:') && this.audioContext) {
      return this.playAudioWithWebAudio(url);
    }
    
    return new Promise((resolve, reject) => {
      // Reset the audio element to clear any previous errors
      this.audioElement!.removeAttribute('src');
      this.audioElement!.load();
      
      // Set crossOrigin to fix CORS issues with audio files
      this.audioElement!.crossOrigin = 'anonymous';
      
      // Set up event handlers
      this.audioElement!.onended = () => {
        Logger.log('Audio playback completed');
        resolve();
      };
      
      this.audioElement!.onerror = (event) => {
        Logger.error('Audio playback error:', event);
        
        // Try fetching the audio with fetch API as a fallback
        if (!url.startsWith('data:')) {
          Logger.log('Attempting to fetch audio and play as blob URL');
          
          // Fetch the audio and create an object URL
          API.get(url)
          .then(response => {
            if (!response.ok) {
              throw new Error(`Failed to fetch audio: ${response.status} ${response.statusText}`);
            }
            return response.blob();
          })
          .then(blob => {
            const blobUrl = URL.createObjectURL(blob);
            Logger.log('Created blob URL for audio:', blobUrl);
            
            // Set up new event handlers for the blob URL
            this.audioElement!.onended = () => {
              URL.revokeObjectURL(blobUrl); // Clean up
              resolve();
            };
            
            this.audioElement!.onerror = blobError => {
              Logger.error('Blob URL audio playback error:', blobError);
              URL.revokeObjectURL(blobUrl); // Clean up
              reject(new Error('Audio playback error with blob URL'));
            };
            
            // Set source and play
            this.audioElement!.src = blobUrl;
            return this.audioElement!.play();
          })
          .then(() => Logger.log('Playing audio from blob URL'))
          .catch(fetchError => {
            Logger.error('Error fetching audio or creating blob URL:', fetchError);
            reject(fetchError);
          });
        } else if (url.startsWith('data:') && this.audioContext) {
          // If this is a data URI, try Web Audio API
          Logger.log('Attempting to play data URI with Web Audio API as fallback');
          this.playAudioWithWebAudio(url).then(resolve).catch(reject);
        } else {
          reject(new Error('Audio playback error and no fallback available'));
        }
      };
      
      // Set source and play
      this.audioElement!.src = url;
      
      // Make sure we've unlocked audio for Safari if needed
      if (this.isMobileSafari() && !this.hasUserInteracted) {
        Logger.log('Attempting to unlock audio for Safari before playback');
        this.initAudioForMobileSafari();
      }
      
      // Play immediately (returns a promise)
      this.audioElement!.play().catch(error => {
        Logger.error('Error starting audio playback:', error);
        
        // If this is a CORS error (often shows up as NetworkError or AbortError)
        if (error.name === 'NetworkError' || error.name === 'AbortError' || 
            error.name === 'SecurityError' || error.message?.includes('CORS')) {
          Logger.log('Possible CORS error detected, trying fetch API fallback');
          
          // Try fetching the audio with fetch API
          API.get(url)
          .then(response => {
            if (!response.ok) {
              throw new Error(`Failed to fetch audio: ${response.status} ${response.statusText}`);
            }
            return response.blob();
          })
          .then(blob => {
            const blobUrl = URL.createObjectURL(blob);
            Logger.log('Created blob URL for audio after CORS error:', blobUrl);
            
            // Set up new event handlers for the blob URL
            this.audioElement!.onended = () => {
              URL.revokeObjectURL(blobUrl); // Clean up
              resolve();
            };
            
            this.audioElement!.onerror = blobError => {
              Logger.error('Blob URL audio playback error:', blobError);
              URL.revokeObjectURL(blobUrl); // Clean up
              reject(new Error('Audio playback error with blob URL'));
            };
            
            // Set source and play
            this.audioElement!.src = blobUrl;
            return this.audioElement!.play();
          })
          .then(() => Logger.log('Playing audio from blob URL after CORS error'))
          .catch(fetchError => {
            Logger.error('Error fetching audio after CORS error:', fetchError);
            
            // If it's a data URI, try Web Audio API as a last resort
            if (url.startsWith('data:') && this.audioContext) {
              this.playAudioWithWebAudio(url).then(resolve).catch(reject);
            } else {
              reject(fetchError);
            }
          });
          return;
        }
        
        // Check if this is an autoplay restriction error
        if (error.name === 'NotAllowedError' || 
            (error.message && error.message.includes('user interaction'))) {
          Logger.log('Autoplay restriction detected, waiting for user interaction');
          
          // Try to unlock audio for Safari again
          this.initAudioForMobileSafari();
          
          // For Mobile Safari, we need to retry after user interaction
          const retryTimeout = setTimeout(() => {
            // Try playing again
            if (this.hasUserInteracted && this.audioElement) {
              this.audioElement.play().then(() => {
                Logger.log('Audio playback successful after retry');
                clearTimeout(retryTimeout);
                resolve();
              }).catch(retryError => {
                Logger.error('Retry failed:', retryError);
                reject(retryError);
              });
            } else {
              reject(new Error('Could not play audio: user interaction required'));
            }
          }, 500);
        } else {
          reject(error);
        }
      });
      
      // Set a longer timeout as fallback (used to be 20s, now 2 minutes)
      // This handles cases where audio finishes but onended doesn't fire
      const timeout = 120000; // 2 minutes
      const timeoutId = setTimeout(() => {
        Logger.log('Audio timeout reached after 2 minutes, resolving promise');
        resolve();
      }, timeout);
      
      // Make sure we clear the timeout if the audio ends normally
      this.audioElement!.onended = () => {
        clearTimeout(timeoutId);
        Logger.log('Audio playback completed normally');
        resolve();
      };
    });
  }
  
  /**
   * Play audio using Web Audio API instead of Audio element
   * This method bypasses CSP restrictions for data URIs
   */
  private playAudioWithWebAudio(dataUri: string): Promise<void> {
    if (!this.audioContext) {
      this.initAudioContext();
      if (!this.audioContext) {
        return Promise.reject(new Error('Audio context not available'));
      }
    }
    
    return new Promise((resolve, reject) => {
      try {
        // Extract base64 data from the data URI
        const base64Data = dataUri.split(',')[1];
        if (!base64Data) {
          return reject(new Error('Invalid data URI format'));
        }
        
        // Convert base64 to array buffer
        const binaryString = window.atob(base64Data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        
        // Decode the audio data
        this.audioContext!.decodeAudioData(
          bytes.buffer,
          (buffer) => {
            // Create a source node
            const source = this.audioContext!.createBufferSource();
            source.buffer = buffer;
            
            // Connect to the audio context destination and play
            source.connect(this.audioContext!.destination);
            source.onended = () => {
              Logger.log('Web Audio API playback completed');
              resolve();
            };
            
            // Start playback
            source.start(0);
            
            // Fallback timeout in case onended doesn't fire (increased from 7s to 2 minutes)
            const timeoutId = setTimeout(() => {
              Logger.log('Web Audio API timeout reached after 2 minutes, resolving promise');
              resolve();
            }, 120000); // 2 minutes
            
            // Update the onended handler to clear the timeout
            source.onended = () => {
              clearTimeout(timeoutId);
              Logger.log('Web Audio API playback completed normally');
              resolve();
            };
          },
          (error) => {
            Logger.error('Error decoding audio data:', error);
            reject(error);
          }
        );
      } catch (error) {
        Logger.error('Error playing audio with Web Audio API:', error);
        reject(error);
      }
    });
  }
  
  /**
   * Fallback to Web Speech API for TTS
   */
  private async speakWithWebSpeechAPI(text: string): Promise<void> {
    if (!this.speechSynthesis) {
      Logger.error('Speech synthesis not available');
      return;
    }
    
    // For Safari, make sure we've unlocked audio first
    if (this.isMobileSafari()) {
      // Try to ensure we have user interaction
      if (!this.hasUserInteracted) {
        Logger.log('No user interaction detected before Web Speech API usage');
        // We'll still try, but it might fail on Mobile Safari
      }
      
      // Safari has a bug where speechSynthesis gets stuck in paused state
      // Force resume it before canceling
      this.speechSynthesis.resume();
    }
    
    // Cancel any ongoing speech
    this.speechSynthesis.cancel();
    
    // Create a new utterance
    const utterance = new SpeechSynthesisUtterance(text);
    
    // Store current utterance reference
    this.currentUtterance = utterance;
    
    // Set the voice if available
    if (this.preferredVoice) {
      utterance.voice = this.preferredVoice;
    }
    
    // Set properties for natural speech
    utterance.rate = 1.0; // Slightly slower than default
    utterance.pitch = 1.0;
    utterance.volume = 1.0;
    
    // Safari specific handling
    if (this.isMobileSafari()) {
      // Safari needs shorter utterances to work reliably
      // If text is long, we might need to split it, but for now we'll just 
      // ensure the audio context is ready
      if (this.audioContext && this.audioContext.state === 'suspended') {
        try {
          await this.audioContext.resume();
        } catch (error) {
          Logger.warn('Could not resume audio context:', error);
        }
      }
    }
    
    // Start speaking immediately
    this.speechSynthesis.speak(utterance);
    
    // Return a promise that resolves when speech is complete
    // Note: The caller can choose not to await this for non-blocking operation
    return new Promise((resolve) => {
      utterance.onend = () => {
        Logger.log('Speech completed');
        this.currentUtterance = null;
        resolve();
      };
      
      // Fallback in case onend doesn't fire
      utterance.onerror = (event) => {
        Logger.error('Speech error:', event);
        this.currentUtterance = null;
        resolve();
      };
      
      // Safari sometimes fails to fire onend events
      // Add extra handler for Safari
      if (this.isMobileSafari()) {
        const checkSpeaking = setInterval(() => {
          if (!this.speechSynthesis?.speaking) {
            Logger.log('Speech completed (detected via polling)');
            clearInterval(checkSpeaking);
            this.currentUtterance = null;
            resolve();
          }
        }, 250);
        
        // Clear interval after timeout to avoid memory leaks
        setTimeout(() => {
          clearInterval(checkSpeaking);
        }, Math.max(5000, text.length * 100));
      }
      
      // Resolve after a timeout based on text length (emergency fallback)
      // This ensures the promise eventually resolves even if events don't fire
      // Previously was text.length * 90ms with 2s minimum, now using longer timeout
      const timeout = Math.max(30000, text.length * 150); // ~150ms per character, minimum 30s
      const timeoutId = setTimeout(() => {
        Logger.log('Speech timeout reached, resolving promise');
        // Don't clear currentUtterance here as it might still be playing
        resolve();
      }, timeout);
      
      // Update the onend handler to clear the timeout
      utterance.onend = () => {
        clearTimeout(timeoutId);
        Logger.log('Speech completed normally');
        this.currentUtterance = null;
        resolve();
      };
    });
  }
  
  /**
   * Stop all audio playback
   */
  public stopPlayback(): void {
    // Stop Web Speech API if active
    if (this.speechSynthesis) {
      this.speechSynthesis.cancel();
      this.currentUtterance = null;
    }
    
    // Stop audio element if active
    if (this.audioElement) {
      this.audioElement.pause();
      this.audioElement.currentTime = 0;
    }
  }
  
  /**
   * Pause audio playback
   */
  public pausePlayback(): void {
    // Pause Web Speech API if active
    if (this.speechSynthesis && this.currentUtterance) {
      this.speechSynthesis.pause();
    }
    
    // Pause audio element if active
    if (this.audioElement && !this.audioElement.paused) {
      this.audioElement.pause();
    }
  }
  
  /**
   * Resume audio playback
   */
  public resumePlayback(): void {
    // Resume Web Speech API if active
    if (this.speechSynthesis && this.currentUtterance) {
      this.speechSynthesis.resume();
    }
    
    // Resume audio element if active and paused
    if (this.audioElement && this.audioElement.paused && this.audioElement.src) {
      this.audioElement.play().catch(error => {
        Logger.error('Error resuming audio playback:', error);
      });
    }
  }
  
  /**
   * Speak text after a specified delay (useful for coordinating with animations)
   * @param text Text to speak
   * @param delayMs Delay in milliseconds before starting speech
   * @returns Promise that resolves when speech completes
   */
  public speakTextAfterDelay(text: string, delayMs: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.speakText(text).then(resolve).catch(error => {
          Logger.error('Error in delayed speech:', error);
          resolve();
        });
      }, delayMs);
    });
  }
}

// Create a singleton instance
export const ttsService = new TTSService();

// Make the service available globally for components that need to access it directly
if (typeof window !== 'undefined') {
  (window as any).ttsService = ttsService;
}