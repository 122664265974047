import React from 'react';

import { Text } from 'components/common/Typography';
import ResultCard from '../ResultCardBase';
import Feedback from 'components/common/Feedback/Feedback';
import Card from 'components/common/Card';

const HotelResponse = ({ results, jobId }) => {
    const options = [];

    if (Array.isArray(results)) {
        for (let i = 0; i < results.length; i++) {
            options.push(
                <ResultCard
                    key={i} 

                    info={{
                        index: results[i].index,
                        numPeople: results[i].option.room_details?.num_guests
                    }}

                    ranking={{
                        allOptions: results.map(r => r.score),
                        score: results[i].score,
                        ranking_reasoning: results[i].ranking_reasoning
                    }}

                    title={{
                        title: results[i].option.name,
                        stars: results[i].option.hotel_class,
                        location: results[i].option.address,
                        website: results[i].option.website
                    }}

                    hero={{
                        companyName: '',
                        rating: results[i].option.rating,
                        reviews: results[i].option.reviews
                    }}

                    details={{
                        startLabel: 'Check-in',
                        startTime: results[i].option.check_in_time,

                        endLabel: 'Check-out',
                        endTime: results[i].option.check_out_time
                    }}

                    cta={{
                        ctaPrice: results[i].option.price,
                        ctaSub: 'Total rate',
                        booking_url: results[i].option.booking_url
                    }}

                    gps_coordinates={results[i].option.gps_coordinates}

                    features={results[i].option.amenities}
                />
            );
        }
    }

    if (options.length === 0) {
        return (
            <>
                <p>
                    Adapter could not find any results that match your request.
                    Please try adjusting your your request to find more options.
                </p>

                <p>
                    Helpful Tips:
                </p>

                <ul>
                    <li>- Expand the time window of your search</li>
                </ul>
            </>
        );
    }

    return (
        <>
            <Card>
                <Text>
                    Adapter found {options.length} option(s) and ranked them based on your needs and preferences:
                </Text>
            </Card>

            {/* <MapView options={results} type="hotel" /> */}

            {options}

            <Feedback
                style={{
                    backgroundColor: '#F9F1DE',
                    borderRadius: '4px',
                    alignSelf: 'stretch',
                    justifyContent: 'center'
                }}
                text="Were these results useful?"
                responseKey={'prompt::results::hotels::jobId'}
                metadata={{ jobId }}
            />
        </>
    );
};

export default HotelResponse;