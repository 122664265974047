// src/services/AdvancedSearchService.ts

import { 
  NodeData, 
  EdgeData, 
  PrefilterVoiceQueryResult, 
  CompactPrefilterResult,
  ENTITY_TYPE_REVERSE_MAP,
  ENTITY_TYPE_MAP,
  PROPERTY_KEY_REVERSE_MAP,
  PROPERTY_KEY_MAP,
  PATTERN_REVERSE_MAP,
  PATTERN_MAP
} from '../types';
import { parseISO, isWithinInterval, isValid, endOfDay } from 'date-fns';

// Define type for hints object for better type safety and documentation
type AdvancedSearchHints = {
    priorityFilterDate: boolean;
    constraintDateRequired: boolean;
    requireMatchStrictness: Map<string, 'EXACT' | 'EXACT_THEN_FUZZY'>;
    expandFrom: 'FOCUS_ONLY' | 'ALL_INITIAL';
    requireEntityPresent: Set<string>;
    // Extensible design for future hint types
};

// Using the PrefilterVoiceQueryResult interface from types.ts
type PreFilterResult = PrefilterVoiceQueryResult;

/**
 * Service responsible for taking a structured search plan (from an LLM pre-filter)
 * and executing an intelligent search on the client-side graph data to find
 * the most relevant subgraph, potentially merging context from multiple focus persons.
 */
class AdvancedSearchService {
    // Cache graph data for the duration of a single search
    private allNodesMap: Map<string, NodeData> = new Map();
    private allEdges: EdgeData[] = [];
    // Track user node IDs to exclude from expansion phases
    private userNodeIds: Set<string> = new Set();
    // Execution hints from the LLM plan
    private hints: AdvancedSearchHints = this.resetHints();

    // ========================================================================
    // Initialization and Hint Parsing
    // ========================================================================

    /**
     * Resets the internal hints state to default values.
     */
    private resetHints(): AdvancedSearchHints {
        return {
            priorityFilterDate: true, // Default: Apply date filter first if available
            constraintDateRequired: false, // Default: Date is not a hard requirement for keyword matching
            requireMatchStrictness: new Map<string, 'EXACT' | 'EXACT_THEN_FUZZY'>(), // Default: Allow fuzzy matches
            expandFrom: 'ALL_INITIAL', // Default: Expand from all initially found nodes
            requireEntityPresent: new Set<string>(), // Default: No specific entity types required
        };
    }

    /**
     * Parses machine-readable hints from the preFilterResult.
     * Enhanced to better handle edge cases and provide more consistent logging.
     */
    private parseHints(hintsArray?: string[]): void {
        this.hints = this.resetHints(); // Reset before parsing new hints
        if (!hintsArray || hintsArray.length === 0) return;

        hintsArray.forEach(hint => {
            try {
                const parts = hint.split(':');
                // Allow strategy hints with one part (e.g., STRATEGY:MERGE_FOCUS_CONTEXT)
                if (parts.length < 2 && !hint.startsWith('STRATEGY:')) {
                    console.warn(`AdvancedSearchService: Skipping invalid hint format "${hint}"`);
                    return;
                }
                const key = parts[0].trim();
                // Handle single-part strategy hints correctly
                const value = parts.length > 1 ? parts[1].trim() : 
                             (key === 'STRATEGY' ? hint.substring(key.length + 1).trim() : '');

                switch (key) {
                    case 'PRIORITY_FILTER':
                        if (value === 'DATE') this.hints.priorityFilterDate = true;
                        break;
                    case 'CONSTRAINT':
                        if (value === 'DATE_REQUIRED') this.hints.constraintDateRequired = true;
                        break;
                    case 'REQUIRE_MATCH':
                        if (parts.length === 3) {
                            const entityType = value;
                            const strictness = parts[2].trim();
                            if (strictness === 'EXACT' || strictness === 'EXACT_THEN_FUZZY') {
                                this.hints.requireMatchStrictness.set(entityType, strictness);
                            } else {
                                console.warn(`AdvancedSearchService: Invalid strictness value "${strictness}" in hint "${hint}"`);
                            }
                        } else {
                             console.warn(`AdvancedSearchService: Invalid REQUIRE_MATCH hint format "${hint}"`);
                        }
                        break;
                    case 'EXPAND_FROM':
                        if (value === 'FOCUS_ONLY') this.hints.expandFrom = 'FOCUS_ONLY';
                        else if (value === 'ALL_INITIAL') this.hints.expandFrom = 'ALL_INITIAL';
                        else {
                             console.warn(`AdvancedSearchService: Invalid EXPAND_FROM value "${value}" in hint "${hint}"`);
                        }
                        break;
                    case 'REQUIRE_ENTITY_PRESENT':
                        if (value) { // Ensure value exists
                            this.hints.requireEntityPresent.add(value);
                        } else {
                             console.warn(`AdvancedSearchService: Missing entity type in REQUIRE_ENTITY_PRESENT hint "${hint}"`);
                        }
                        break;
                    case 'STRATEGY': // Support for strategy hints
                         console.log(`AdvancedSearchService: Strategy hint recognized: ${value}`);
                         break;
                    default:
                         console.warn(`AdvancedSearchService: Unknown hint key "${key}" in hint "${hint}"`);
                         break;
                }
            } catch (e) {
                 console.error(`AdvancedSearchService: Error parsing hint "${hint}"`, e);
            }
        });
        console.log("AdvancedSearchService: Parsed Hints:", this.hints);
    }

    // Main Search Logic

    /**
     * Main method to process the pre-filter result and find the relevant subgraph.
     * Executes filtering and expansion steps based on the plan and hints.
     */
    public processAdvancedSearch(
        preFilterResultInput: PreFilterResult | CompactPrefilterResult,
        nodes: NodeData[],
        edges: EdgeData[]
    ): { nodes: NodeData[]; edges: EdgeData[] } {
        // Convert the input to verbose format if it's in compact format
        const preFilterResult: PreFilterResult = this.isCompactFormat(preFilterResultInput) 
            ? this.convertCompactToVerbose(preFilterResultInput) 
            : preFilterResultInput;
        
        console.log("AdvancedSearchService: Starting subgraph processing with hints", preFilterResult);

        // --- 0. Initialization ---
        this.allNodesMap = new Map(nodes.map(n => [n.id, n]));
        this.allEdges = edges;
        
        // Identify user nodes: specifically nodes of type 'person' with properties.user === true
        this.userNodeIds = new Set(
            nodes.filter(n => n.type === 'person' && n.properties?.user === true)
                .map(n => n.id)
        );
        console.log(`AdvancedSearchService: Identified ${this.userNodeIds.size} user nodes that will not be expanded from`);
        
        this.parseHints(preFilterResult.searchStrategyHints);

        // Sets to track nodes identified at different stages
        const initialMatchedNodeIds = new Set<string>(); // Nodes matched by date/keyword/base/focus
        const dateMatchedNodeIds = new Set<string>();    // Nodes specifically matched by date
        const keywordMatchedNodeIds = new Set<string>(); // Nodes specifically matched by keyword/property
        
        // Original focus/base nodes provided by LLM
        const focusPersonIds = new Set<string>(preFilterResult.focusPersonIds || []);
        const baseNodeIds = new Set<string>(preFilterResult.baseNodes || []);
        const focusAndBaseIds = new Set<string>([...focusPersonIds, ...baseNodeIds]);

        // --- 1. Date Range Filtering ---
        const dateRange = this.parseDateRange(preFilterResult.dateStart, preFilterResult.dateEnd);
        if (dateRange) {
            console.log(`AdvancedSearchService: Applying date range filter: ${preFilterResult.dateStart} to ${preFilterResult.dateEnd}`);
            // Optimize by using cached node map rather than iterating the original array
            this.allNodesMap.forEach((node) => {
                if (!this.userNodeIds.has(node.id) && this.isTemporalNode(node) && this.isNodeInDateRange(node, dateRange)) {
                    initialMatchedNodeIds.add(node.id);
                    dateMatchedNodeIds.add(node.id);
                }
            });
            console.log(`AdvancedSearchService: Found ${dateMatchedNodeIds.size} nodes within date range.`);
        }

        // --- 2. Initialize with Base Nodes & Focus Persons ---
        this.addFocusAndBaseNodes(focusAndBaseIds, initialMatchedNodeIds, dateMatchedNodeIds, dateRange);

        // --- 3. Entity & Keyword Matching ---
        this.findKeywordMatchingNodes(
            preFilterResult,
            initialMatchedNodeIds,
            keywordMatchedNodeIds,
            dateMatchedNodeIds,
            dateRange
        );
        
        console.log(`AdvancedSearchService: Nodes after initial filtering (Date, Focus/Base, Keywords): ${initialMatchedNodeIds.size}`);

        // --- 4. Focus Person 1-Hop & Calendar/Email Expansion ---
        const { currentSubgraphNodeIds, subgraphEdgeIds } = 
            this.performFocusPersonExpansion(initialMatchedNodeIds, focusPersonIds);

        // --- 5. Person Community Expansion ---
        this.performPersonCommunityExpansion(
            currentSubgraphNodeIds,
            subgraphEdgeIds,
            keywordMatchedNodeIds,
            focusPersonIds,
            preFilterResult
        );

        // --- 6. General Graph Traversal/Expansion ---
        this.performGeneralGraphExpansion(
            currentSubgraphNodeIds,
            subgraphEdgeIds,
            initialMatchedNodeIds,
            focusAndBaseIds
        );
        
        // --- 7. Person-Memory Connection Enhancement (Find all memories first) ---
        // Find and include all memories connected to non-user persons from the original full graph
        this.includeMemoriesForPersons(
            currentSubgraphNodeIds,
            subgraphEdgeIds
        );
        
        // --- 8. Memory-Person Connection Enhancement (Then find all persons connected to those memories) ---
        // Include all persons connected to memory nodes via 'memory_with' edges
        this.includePersonsConnectedToMemories(
            currentSubgraphNodeIds,
            subgraphEdgeIds
        );
        
        // --- 9. Person-Calendar Event Enhancement ---
        // Find and include all calendar events that non-user persons participate in
        this.includePersonCalendarEvents(
            currentSubgraphNodeIds, 
            subgraphEdgeIds
        );
        
        // --- 10. Calendar Event Attendee Enhancement ---
        // Include all persons that participate in calendar events via 'participates_in' edges
        this.includeCalendarEventAttendees(
            currentSubgraphNodeIds,
            subgraphEdgeIds
        );
        
        // --- 11-12. Edge Inclusion and User Node Re-integration ---
        const finalNodesWithUserIds = this.finalizeSubgraph(
            currentSubgraphNodeIds,
            subgraphEdgeIds
        );

        // --- 13. Check constraints ---
        const constraintsMet = this.checkConstraints(finalNodesWithUserIds);
        if (!constraintsMet) {
            console.log("AdvancedSearchService: Returning empty graph due to failed constraints.");
            return { nodes: [], edges: [] };
        }

        // --- 14. Apply final filters (date and entity type) ---
        const filteredData = this.applyFinalFilters(
            finalNodesWithUserIds,
            subgraphEdgeIds,
            preFilterResult
        );

        // Build and return the final result
        return this.buildFinalSubgraph(filteredData.nodeIds, filteredData.edgeIds);
    }

    /**
     * Add focus and base nodes to the initial matched set, respecting date constraints.
     * Extracted as a helper method for clarity.
     */
    private addFocusAndBaseNodes(
        focusAndBaseIds: Set<string>,
        initialMatchedNodeIds: Set<string>,
        dateMatchedNodeIds: Set<string>,
        dateRange: { start: Date; end: Date } | null
    ): void {
        focusAndBaseIds.forEach(nodeId => {
            const node = this.allNodesMap.get(nodeId);
            // Ensure node exists and is NOT a user node (critical to prevent over-expansion)
            if (node && !this.userNodeIds.has(nodeId)) {
                // Check date constraint
                const needsDateCheck = this.hints.constraintDateRequired && dateRange && this.isTemporalNode(node);
                const meetsDateConstraint = !needsDateCheck || dateMatchedNodeIds.has(nodeId);

                if (meetsDateConstraint) {
                     initialMatchedNodeIds.add(nodeId);
                     console.log(`AdvancedSearchService: Added focus/base node ${nodeId} (${node.type})`);
                } else {
                     console.log(`AdvancedSearchService: Skipped focus/base node ${nodeId} due to DATE_REQUIRED constraint.`);
                }
            } else if (this.userNodeIds.has(nodeId)) {
                console.log(`AdvancedSearchService: Skipped user node ${nodeId} from focus/base nodes to prevent over-expansion`);
            } else if (!this.allNodesMap.has(nodeId)) {
                // Log if an ID from the LLM plan doesn't exist in the graph data
                console.warn(`AdvancedSearchService: Focus/Base node ID ${nodeId} provided by LLM not found in graph data.`);
            }
        });
    }

    /**
     * Find nodes matching the keyword search criteria in the preFilterResult.
     * Extracted as a helper method for clarity and reuse.
     */
    private findKeywordMatchingNodes(
        preFilterResult: PreFilterResult,
        initialMatchedNodeIds: Set<string>,
        keywordMatchedNodeIds: Set<string>,
        dateMatchedNodeIds: Set<string>,
        dateRange: { start: Date; end: Date } | null
    ): void {
        console.log("AdvancedSearchService: Applying entity & keyword matching...");
        
        // Determine which nodes to check based on date constraints
        let nodesToCheckKeywords: NodeData[];
        if (this.hints.constraintDateRequired && dateRange) {
            // Only check date-matched or non-temporal nodes if date constraint is active
            nodesToCheckKeywords = Array.from(this.allNodesMap.values()).filter(node =>
                !this.userNodeIds.has(node.id) &&
                (dateMatchedNodeIds.has(node.id) || !this.isTemporalNode(node))
            );
        } else {
            // Check all non-user nodes
            nodesToCheckKeywords = Array.from(this.allNodesMap.values()).filter(node => 
                !this.userNodeIds.has(node.id)
            );
        }

        // Check each entity type for matches
        for (const entityType of preFilterResult.relevantEntityTypes || []) {
            const terms = preFilterResult.searchTerms?.[entityType];
            if (!terms) continue;

            const typeNodesToCheck = nodesToCheckKeywords.filter(n => n.type === entityType);
            const strictness = this.hints.requireMatchStrictness.get(entityType) || 'EXACT_THEN_FUZZY';

            for (const node of typeNodesToCheck) {
                if (initialMatchedNodeIds.has(node.id)) continue; // Already added

                let matchedExact = false;
                let matchedFuzzy = false;

                // Check for Exact & Property Matches (High Confidence)
                if (terms.exactMatch && terms.exactMatch.length > 0) {
                    // Check label first
                    if (terms.exactMatch.some(term => node.label === term)) {
                        matchedExact = true;
                    }
                    
                    // Check properties if label didn't match
                    if (!matchedExact && node.properties) {
                        // Filter out ical_uid property for calendar events
                        if (node.type === 'calendar_event') {
                            matchedExact = Object.entries(node.properties)
                                .filter(([key]) => key !== 'ical_uid') // Skip ical_uid
                                .some(([_, val]) => terms.exactMatch!.includes(String(val)));
                        } else {
                            matchedExact = Object.values(node.properties).some(propVal => 
                                terms.exactMatch!.includes(String(propVal))
                            );
                        }
                    }
                }
                
                // Check propertyMatch if no exact match found yet
                if (!matchedExact && terms.propertyMatch && node.properties && Object.keys(terms.propertyMatch).length > 0) {
                    for (const [propKey, targetValues] of Object.entries(terms.propertyMatch)) {
                        // Skip explicitly if the property is ical_uid
                        if (propKey === 'ical_uid') continue;
                        
                        if (!Array.isArray(targetValues) || targetValues.length === 0) continue;
                        const graphPropsToCheck = this.getGraphPropertiesForConcept(node.type, propKey);
                        
                        for (const graphProp of graphPropsToCheck) {
                            // Skip ical_uid explicitly
                            if (graphProp === 'ical_uid') continue;
                            
                            const propValue = this.getNodePropertyValue(node.properties, graphProp);
                            if (propValue !== undefined && this.checkValueMatch(propValue, targetValues, graphProp)) {
                                matchedExact = true;
                                break;
                            }
                        }
                        if (matchedExact) break;
                    }
                }

                // Check for Fuzzy Matches (Lower Confidence)
                if (!matchedExact && terms.fuzzyMatch && terms.fuzzyMatch.length > 0) {
                    // Check label first
                    if (terms.fuzzyMatch.some(term => this.fuzzyMatch(node.label, term))) {
                        matchedFuzzy = true;
                    }
                    
                    // Check properties if label didn't match
                    if (!matchedFuzzy && node.properties) {
                        // Create a clean properties object without ical_uid for calendar events
                        let propsToCheck = {...node.properties};
                        if (node.type === 'calendar_event' && propsToCheck.ical_uid) {
                            delete propsToCheck.ical_uid; // Remove ical_uid to prevent it from affecting matches
                        }
                        
                        const propValuesString = JSON.stringify(propsToCheck).toLowerCase();
                        if (terms.fuzzyMatch.some(term => term && propValuesString.includes(term.toLowerCase()))) {
                            matchedFuzzy = true;
                        }
                    }
                }

                // Add node based on match type and strictness hint
                if (matchedExact || (matchedFuzzy && strictness === 'EXACT_THEN_FUZZY')) {
                    initialMatchedNodeIds.add(node.id);
                    keywordMatchedNodeIds.add(node.id);
                }
            }
        }
    }

    /**
     * Perform focus person expansion and handle calendar event/email participant expansion.
     * Extracted for clarity and reuse.
     */
    private performFocusPersonExpansion(
        initialMatchedNodeIds: Set<string>,
        focusPersonIds: Set<string>
    ): { 
        currentSubgraphNodeIds: Set<string>; 
        subgraphEdgeIds: Set<string>;
    } {
        console.log("AdvancedSearchService: Performing Focus Person 1-Hop Expansion...");
        
        const currentSubgraphNodeIds = new Set<string>(initialMatchedNodeIds); // Copy initial nodes
        const subgraphEdgeIds = new Set<string>(); // Start tracking edges
        
        // Use focus persons that were actually found after initial filtering
        const focusPersonIdsInSubgraph = Array.from(focusPersonIds)
            .filter(id => currentSubgraphNodeIds.has(id));
        const calendarEventsExpanded = new Set<string>(); // Track events processed
        const emailsExpanded = new Set<string>(); // Track emails processed

        if (focusPersonIdsInSubgraph.length > 0) {
            console.log(`AdvancedSearchService: Expanding 1-hop from ${focusPersonIdsInSubgraph.length} focus person(s): [${focusPersonIdsInSubgraph.join(', ')}]`);

            // Filter out user nodes from focus person expansion
            const nonUserFocusPersonIds = focusPersonIdsInSubgraph
                .filter(id => !this.userNodeIds.has(id));
            
            // For each non-user focus person ID found in the subgraph
            for (const focusPersonId of nonUserFocusPersonIds) {
                const focusNode = this.allNodesMap.get(focusPersonId);
                console.log(`AdvancedSearchService: Expanding from focus person ${focusPersonId} - ${focusNode?.label || 'unknown'}`);
                
                // Find direct neighbors for THIS focus person
                for (const edge of this.allEdges) {
                    const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
                    const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
                    
                    if (!sourceId || !targetId) continue; // Skip malformed edges
                    
                    let neighborId: string | null = null;

                    // Identify neighbor if connected to this focus person
                    if (sourceId === focusPersonId && targetId !== focusPersonId) neighborId = targetId;
                    else if (targetId === focusPersonId && sourceId !== focusPersonId) neighborId = sourceId;

                    // Process valid, non-user neighbors
                    if (neighborId && !this.userNodeIds.has(neighborId)) {
                        const neighborNode = this.allNodesMap.get(neighborId);
                        if (!neighborNode) continue;
                        
                        // Add neighbor node and the connecting edge
                        currentSubgraphNodeIds.add(neighborId);
                        const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                        subgraphEdgeIds.add(edgeKey);

                        // If neighbor is a calendar event, expand participants
                        if (neighborNode.type === 'calendar_event' && !calendarEventsExpanded.has(neighborId)) {
                            calendarEventsExpanded.add(neighborId); // Mark as processed
                            
                            // Find all participants for this event
                            this.expandCalendarEventParticipants(
                                neighborId,
                                currentSubgraphNodeIds,
                                subgraphEdgeIds
                            );
                        }
                        
                        // If neighbor is an email, expand connections
                        if (neighborNode.type === 'email' && !emailsExpanded.has(neighborId)) {
                            emailsExpanded.add(neighborId); // Mark as processed
                            
                            // Find all senders and recipients for this email
                            this.expandEmailConnections(
                                neighborId,
                                currentSubgraphNodeIds,
                                subgraphEdgeIds
                            );
                        }
                    }
                }
            }
        } else {
            console.log("AdvancedSearchService: Skipping Focus Person 1-Hop Expansion as no valid focus persons were identified.");
        }
        
        console.log(`AdvancedSearchService: Nodes after Focus Person 1-Hop Expansion: ${currentSubgraphNodeIds.size}`);
        
        return { currentSubgraphNodeIds, subgraphEdgeIds };
    }

    /**
     * Expand participants from a calendar event.
     * Extracted as a helper method.
     */
    private expandCalendarEventParticipants(
        eventId: string,
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>
    ): void {
        for (const edge of this.allEdges) {
            // Only check participation edges
            if (edge.type !== 'participates_in') continue;

            const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
            const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
            
            if (!sourceId || !targetId) continue; // Skip malformed edges
            
            let participantId: string | null = null;

            // Identify the participant connected to this specific event
            if (sourceId === eventId && this.isPerson(targetId)) {
                participantId = targetId;
            } else if (targetId === eventId && this.isPerson(sourceId)) {
                participantId = sourceId;
            }

            // Add participant node (if valid and not user) and the participation edge
            if (participantId && !this.userNodeIds.has(participantId)) {
                currentSubgraphNodeIds.add(participantId);
                const participantEdgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                subgraphEdgeIds.add(participantEdgeKey);
            }
        }
    }
    
    /**
     * Expand connections from an email node via "sent" and "received_by" edges.
     * Similar to calendar event expansion but specifically for email nodes.
     */
    private expandEmailConnections(
        emailId: string,
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>
    ): void {
        // Define the edge types we're interested in for email connections
        const emailEdgeTypes = new Set(['sent', 'received_by', 'sent_to', 'sent_by']);
        
        for (const edge of this.allEdges) {
            // Only check email-related edges
            if (!emailEdgeTypes.has(edge.type || '')) continue;

            const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
            const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
            
            if (!sourceId || !targetId) continue; // Skip malformed edges
            
            // Check if this edge connects to our email
            if (sourceId === emailId || targetId === emailId) {
                // Identify the connected entity (sender or recipient)
                const connectedNodeId = sourceId === emailId ? targetId : sourceId;
                
                // Skip user nodes and already processed nodes
                if (this.userNodeIds.has(connectedNodeId) || 
                    !this.allNodesMap.has(connectedNodeId)) continue;
                
                // Add the connected node and edge to our subgraph
                currentSubgraphNodeIds.add(connectedNodeId);
                const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                subgraphEdgeIds.add(edgeKey);
                
                const connectedNode = this.allNodesMap.get(connectedNodeId);
                if (connectedNode) {
                    console.log(`AdvancedSearchService: Added email connection: ${emailId} <-[${edge.type}]-> ${connectedNodeId} (${connectedNode.label || 'unknown'})`);
                }
            }
        }
    }

    /**
     * Perform person community expansion to find relevant connected entities.
     */
    private performPersonCommunityExpansion(
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>,
        keywordMatchedNodeIds: Set<string>,
        focusPersonIds: Set<string>,
        preFilterResult: PreFilterResult
    ): void {
        console.log("AdvancedSearchService: Performing Person Community Expansion...");
        
        // Get all non-user person nodes for community expansion but ONLY focus persons or keyword matches
        // This prevents over-expansion to all persons in the graph
        const personNodeIds = Array.from(currentSubgraphNodeIds)
            .filter(id => {
                const node = this.allNodesMap.get(id);
                return node && 
                      node.type === 'person' && 
                      !this.userNodeIds.has(id) && 
                      // Only include explicitly matched person nodes or focus persons
                      (keywordMatchedNodeIds.has(id) || focusPersonIds.has(id));
            });

        if (personNodeIds.length > 0) {
            const newlyAddedCommunityNodes = this.expandPersonCommunity(
                personNodeIds,
                currentSubgraphNodeIds,
                subgraphEdgeIds,
                focusPersonIds
            );
            
            // Connect persons through calendar events (additional relationship discovery)
            this.connectPersonsThroughEvents(
                currentSubgraphNodeIds,
                subgraphEdgeIds,
                focusPersonIds,
                keywordMatchedNodeIds,
                preFilterResult
            );
            
            console.log(`AdvancedSearchService: Added ${newlyAddedCommunityNodes.size} total nodes during targeted person community expansion`);
        }
    }

    /**
     * Expand the community around person nodes to include relevant events, emails, and memories.
     * Returns the set of newly added nodes.
     */
    private expandPersonCommunity(
        personNodeIds: string[],
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>,
        focusPersonIds: Set<string>
    ): Set<string> {
        console.log(`AdvancedSearchService: Expanding community for ${personNodeIds.length} person nodes (focus persons or keyword matched)`);
        
        // Define node and edge types of interest
        const communityNodeTypes = new Set(['calendar_event', 'memory', 'email']);
        const communicationEdgeTypes = new Set(['communicates_with', 'multi_relation']);
        const memoryEdgeTypes = new Set(['memory_with', 'has_memory', 'mentioned_in']);
        const participationEdgeTypes = new Set(['participates_in', 'attends']);
        const newlyAddedCommunityNodes = new Set<string>();
        
        // For each person node, find all connected nodes of the target types
        personNodeIds.forEach(personId => {
            this.allEdges.forEach(edge => {
                const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
                const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
                
                if (!sourceId || !targetId) return; // Skip malformed edges
                
                // Check if this edge connects to our person node
                if (sourceId === personId || targetId === personId) {
                    // Identify the connected node (the one that's not our person)
                    const connectedNodeId = sourceId === personId ? targetId : sourceId;
                    const connectedNode = this.allNodesMap.get(connectedNodeId);
                    
                    // Skip user nodes, already included nodes, or missing nodes
                    if (!connectedNode || this.userNodeIds.has(connectedNodeId) || currentSubgraphNodeIds.has(connectedNodeId)) {
                        // If both nodes are already in our subgraph, just ensure the edge is included
                        if (connectedNode && currentSubgraphNodeIds.has(connectedNodeId)) {
                            const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                            subgraphEdgeIds.add(edgeKey);
                        }
                        return;
                    }
                    
                    // Determine if we should add this connected node
                    let shouldAdd = false;
                    const edgeType = edge.type || '';
                    const isFocusPerson = focusPersonIds.has(personId);
                    
                    // Non-person nodes: Add nodes of approved types
                    if (connectedNode.type !== 'person' && communityNodeTypes.has(connectedNode.type)) {
                        shouldAdd = true;
                    }
                    // For person nodes: be selective to avoid over-expansion
                    else if (connectedNode.type === 'person') {
                        // Only add if it's a high-priority communication edge
                        shouldAdd = communicationEdgeTypes.has(edgeType);
                    }
                    
                    // Special cases for focus persons
                    if (!shouldAdd && isFocusPerson) {
                        // For calendar events with participation edges, always add them
                        if (connectedNode.type === 'calendar_event' && participationEdgeTypes.has(edgeType)) {
                            shouldAdd = true;
                        }
                        // For memories with memory-related edges, add them
                        else if (connectedNode.type === 'memory' && memoryEdgeTypes.has(edgeType)) {
                            shouldAdd = true;
                        }
                    }
                    
                    // Add the node and edge if conditions are met
                    if (shouldAdd) {
                        currentSubgraphNodeIds.add(connectedNodeId);
                        newlyAddedCommunityNodes.add(connectedNodeId);
                        // Store the edge
                        const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                        subgraphEdgeIds.add(edgeKey);
                        
                        // Log additions by type for easier debugging
                        if (connectedNode.type === 'person') {
                            console.log(`AdvancedSearchService: Added person: ${personId} <-[${edgeType}]-> ${connectedNodeId} (${connectedNode.label || 'unknown'})`);
                        } else if (connectedNode.type === 'memory') {
                            console.log(`AdvancedSearchService: Added memory: ${personId} <-[${edgeType}]-> ${connectedNodeId} (${(connectedNode.label || '').substring(0, 50)}...)`);
                        } else if (connectedNode.type === 'calendar_event') {
                            console.log(`AdvancedSearchService: Added event: ${personId} <-[${edgeType}]-> ${connectedNodeId} (${connectedNode.label || 'unknown'})`);
                        }
                    }
                }
            });
        });
        
        return newlyAddedCommunityNodes;
    }

    /**
     * Find and include all calendar events that non-user persons in the subgraph participate in.
     * This ensures all relevant calendar events for included persons are displayed.
     */
    private includePersonCalendarEvents(
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>
    ): Set<string> {
        console.log(`AdvancedSearchService: Finding calendar events for non-user persons in the subgraph`);
        
        // Filter to find non-user person nodes from the current subgraph
        const personNodeIds = Array.from(currentSubgraphNodeIds).filter(nodeId => {
            const node = this.allNodesMap.get(nodeId);
            // Only include non-user person nodes
            return node && node.type === 'person' && !this.userNodeIds.has(nodeId);
        });
        
        const newlyAddedEventIds = new Set<string>();
        
        // For each person node, find connected calendar events
        personNodeIds.forEach(personId => {
            this.allEdges.forEach(edge => {
                const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
                const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
                
                if (!sourceId || !targetId) return; // Skip malformed edges
                
                // Check if this edge connects to our person node and is of type 'participates_in'
                if ((sourceId === personId || targetId === personId) && edge.type === 'participates_in') {
                    // Identify the connected event (for 'participates_in', event is typically the target)
                    const eventNodeId = sourceId === personId ? targetId : sourceId;
                    const eventNode = this.allNodesMap.get(eventNodeId);
                    
                    // Only process if it's a calendar_event node and not already included
                    if (eventNode && eventNode.type === 'calendar_event' && !currentSubgraphNodeIds.has(eventNodeId)) {
                        // Add the event node
                        currentSubgraphNodeIds.add(eventNodeId);
                        newlyAddedEventIds.add(eventNodeId);
                        
                        // Store the edge
                        const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                        subgraphEdgeIds.add(edgeKey);
                        
                        console.log(`AdvancedSearchService: Added calendar event for person: ${personId} <-[${edge.type}]-> ${eventNodeId} (${eventNode.label || 'unknown event'})`);
                        
                        // Also add edges to user nodes who are attending this event
                        // but don't add the user nodes themselves
                        this.storeEdgesToUserAttendees(eventNodeId, subgraphEdgeIds);
                    }
                }
            });
        });
        
        return newlyAddedEventIds;
    }

    /**
     * Find and include all attendees of calendar events in the subgraph.
     * This ensures that when a calendar event is included, all participating persons are also included.
     */
    private includeCalendarEventAttendees(
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>
    ): Set<string> {
        console.log(`AdvancedSearchService: Finding attendees of calendar events in the subgraph`);
        
        const eventNodeIds = Array.from(currentSubgraphNodeIds).filter(nodeId => {
            const node = this.allNodesMap.get(nodeId);
            return node && node.type === 'calendar_event';
        });
        
        const newlyAddedPersonIds = new Set<string>();
        
        // For each calendar event node, find all connected persons
        eventNodeIds.forEach(eventId => {
            this.allEdges.forEach(edge => {
                const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
                const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
                
                if (!sourceId || !targetId) return; // Skip malformed edges
                
                // Check if this edge connects to our event node and is of type 'participates_in'
                if ((sourceId === eventId || targetId === eventId) && edge.type === 'participates_in') {
                    // Identify the connected person (the one that's not our event)
                    const personNodeId = sourceId === eventId ? targetId : sourceId;
                    // For 'participates_in', person is typically the source and event is the target
                    const personNode = this.allNodesMap.get(personNodeId);
                    
                    // Check if it's a user node - if so, just store the edge but don't add the node
                    if (personNode && personNode.type === 'person' && this.userNodeIds.has(personNodeId)) {
                        // Store the edge but don't add the user node itself
                        // (User nodes will be added back in the final step)
                        const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                        subgraphEdgeIds.add(edgeKey);
                        console.log(`AdvancedSearchService: Stored edge to user node (will be added later): ${eventId} <-[${edge.type}]-> ${personNodeId}`);
                        return;
                    }
                    
                    // Only process if it's a person node and not already included
                    if (personNode && 
                        personNode.type === 'person' && 
                        !this.userNodeIds.has(personNodeId) && 
                        !currentSubgraphNodeIds.has(personNodeId)) {
                        
                        currentSubgraphNodeIds.add(personNodeId);
                        newlyAddedPersonIds.add(personNodeId);
                        
                        // Store the edge
                        const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                        subgraphEdgeIds.add(edgeKey);
                        
                        const role = edge.properties?.role || 'attendee';
                        console.log(`AdvancedSearchService: Added person attendee to event: ${eventId} <-[${edge.type}]-> ${personNodeId} (${personNode.label || 'unknown'}, role: ${role})`);
                    }
                }
            });
        });
        
        return newlyAddedPersonIds;
    }

    /**
     * Find and include all memories connected to non-user person nodes in the subgraph.
     * This searches the entire original graph (not just the subgraph) to ensure
     * we capture all relevant memories for persons, using memory_with edges.
     */
    private includeMemoriesForPersons(
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>
    ): Set<string> {
        console.log(`AdvancedSearchService: Finding all memories for non-user persons in the subgraph`);
        
        // Filter to find non-user person nodes from the current subgraph
        const personNodeIds = Array.from(currentSubgraphNodeIds).filter(nodeId => {
            const node = this.allNodesMap.get(nodeId);
            // Only include non-user person nodes
            return node && node.type === 'person' && !this.userNodeIds.has(nodeId);
        });
        
        const newlyAddedMemoryIds = new Set<string>();
        
        // For each non-user person node, scan the ENTIRE edge set to find all connected memories
        personNodeIds.forEach(personId => {
            this.allEdges.forEach(edge => {
                const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
                const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
                
                if (!sourceId || !targetId) return; // Skip malformed edges
                
                // Check if this edge connects the person to a memory via 'memory_with'
                if ((sourceId === personId || targetId === personId) && edge.type === 'memory_with') {
                    // Identify the connected memory node
                    const memoryNodeId = sourceId === personId ? targetId : sourceId;
                    const memoryNode = this.allNodesMap.get(memoryNodeId);
                    
                    // Only process if it's a memory node and not already included
                    if (memoryNode && 
                        memoryNode.type === 'memory' && 
                        !currentSubgraphNodeIds.has(memoryNodeId)) {
                        
                        // Add the memory node to the subgraph
                        currentSubgraphNodeIds.add(memoryNodeId);
                        newlyAddedMemoryIds.add(memoryNodeId);
                        
                        // Store the edge
                        const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                        subgraphEdgeIds.add(edgeKey);
                        
                        console.log(`AdvancedSearchService: Added memory for person: ${personId} <-[${edge.type}]-> ${memoryNodeId} (${memoryNode.label?.substring(0, 50) || 'unknown memory'}...)`);
                    }
                }
            });
        });
        
        console.log(`AdvancedSearchService: Added ${newlyAddedMemoryIds.size} memory nodes from person connections`);
        return newlyAddedMemoryIds;
    }

    /**
     * Store edges between a memory node and user nodes who are mentioned in it
     * without adding the user nodes themselves (which will be added in the final step).
     */
    private storeEdgesToUserMemories(
        memoryNodeId: string,
        subgraphEdgeIds: Set<string>
    ): void {
        // Find all edges connecting this memory to user nodes
        this.allEdges.forEach(edge => {
            const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
            const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
            
            if (!sourceId || !targetId) return; // Skip malformed edges
            
            // Check if this edge connects to our memory node and is of type 'memory_with'
            if ((sourceId === memoryNodeId || targetId === memoryNodeId) && edge.type === 'memory_with') {
                // Identify the connected person (the one that's not our memory)
                const personNodeId = sourceId === memoryNodeId ? targetId : sourceId;
                
                // Check if it's a user node
                if (this.userNodeIds.has(personNodeId)) {
                    // Store the edge but don't add the user node itself
                    const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                    subgraphEdgeIds.add(edgeKey);
                    console.log(`AdvancedSearchService: Stored edge to user in memory (will be added later): ${memoryNodeId} <-[${edge.type}]-> ${personNodeId}`);
                }
            }
        });
    }

    /**
     * Store edges between a calendar event and user nodes who are attendees
     * without adding the user nodes themselves (which will be added in the final step).
     */
    private storeEdgesToUserAttendees(
        eventNodeId: string,
        subgraphEdgeIds: Set<string>
    ): void {
        // Find all edges connecting this event to user nodes
        this.allEdges.forEach(edge => {
            const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
            const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
            
            if (!sourceId || !targetId) return; // Skip malformed edges
            
            // Check if this edge connects to our event node and is of type 'participates_in'
            if ((sourceId === eventNodeId || targetId === eventNodeId) && edge.type === 'participates_in') {
                // Identify the connected person (the one that's not our event)
                const personNodeId = sourceId === eventNodeId ? targetId : sourceId;
                
                // Check if it's a user node
                if (this.userNodeIds.has(personNodeId)) {
                    // Store the edge but don't add the user node itself
                    const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                    subgraphEdgeIds.add(edgeKey);
                    console.log(`AdvancedSearchService: Stored edge to user attendee (will be added later): ${eventNodeId} <-[${edge.type}]-> ${personNodeId}`);
                }
            }
        });
    }

    /**
     * Find and include all persons connected to memory nodes that are already in the subgraph.
     * This ensures that when a memory is included, all persons involved in that memory are also included.
     */
    private includePersonsConnectedToMemories(
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>
    ): Set<string> {
        console.log(`AdvancedSearchService: Finding persons connected to memories in the subgraph`);
        
        const memoryNodeIds = Array.from(currentSubgraphNodeIds).filter(nodeId => {
            const node = this.allNodesMap.get(nodeId);
            return node && node.type === 'memory';
        });
        
        const newlyAddedPersonIds = new Set<string>();
        
        // For each memory node, find all connected persons
        memoryNodeIds.forEach(memoryId => {
            this.allEdges.forEach(edge => {
                const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
                const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
                
                if (!sourceId || !targetId) return; // Skip malformed edges
                
                // Check if this edge connects to our memory node and is of type 'memory_with'
                if ((sourceId === memoryId || targetId === memoryId) && edge.type === 'memory_with') {
                    // Identify the connected person (the one that's not our memory)
                    const personNodeId = sourceId === memoryId ? targetId : sourceId;
                    const personNode = this.allNodesMap.get(personNodeId);
                    
                    // Check if it's a user node - if so, just store the edge but don't add the node
                    if (personNode && personNode.type === 'person' && this.userNodeIds.has(personNodeId)) {
                        // Store the edge but don't add the user node itself
                        // (User nodes will be added back in the final step)
                        const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                        subgraphEdgeIds.add(edgeKey);
                        console.log(`AdvancedSearchService: Stored edge to user node (will be added later): ${memoryId} <-[${edge.type}]-> ${personNodeId}`);
                        return;
                    }
                    
                    // Only process if it's a person node and not already included
                    if (personNode && 
                        personNode.type === 'person' && 
                        !this.userNodeIds.has(personNodeId) && 
                        !currentSubgraphNodeIds.has(personNodeId)) {
                        
                        currentSubgraphNodeIds.add(personNodeId);
                        newlyAddedPersonIds.add(personNodeId);
                        
                        // Store the edge
                        const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                        subgraphEdgeIds.add(edgeKey);
                        
                        console.log(`AdvancedSearchService: Added person connected to memory: ${memoryId} <-[${edge.type}]-> ${personNodeId} (${personNode.label || 'unknown'})`);
                        
                        // Also store edges to user nodes connected to this memory (if any)
                        this.storeEdgesToUserMemories(memoryId, subgraphEdgeIds);
                    }
                }
            });
        });
        
        return newlyAddedPersonIds;
    }

    /**
     * Connect persons through calendar events, finding relationships between participants.
     * This is important for discovering contextual relationships not explicitly stated.
     */
    private connectPersonsThroughEvents(
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>,
        focusPersonIds: Set<string>,
        keywordMatchedNodeIds: Set<string>,
        preFilterResult: PreFilterResult
    ): void {
        // Find calendar events that were added in community expansion
        const calendarNodeIds = Array.from(currentSubgraphNodeIds)
            .filter(id => {
                const node = this.allNodesMap.get(id);
                return node && node.type === 'calendar_event';
            });
            
        if (calendarNodeIds.length === 0) return;
        
        console.log(`AdvancedSearchService: Connecting persons through ${calendarNodeIds.length} calendar events`);
        const dateRange = this.parseDateRange(preFilterResult.dateStart, preFilterResult.dateEnd);
        const participationEdgeTypes = new Set(['participates_in', 'attends']);
        
        calendarNodeIds.forEach(eventId => {
            const eventNode = this.allNodesMap.get(eventId);
            if (!eventNode) return;
            
            // Skip events outside our date range if date filtering is active
            if (dateRange && !this.isNodeInDateRange(eventNode, dateRange)) {
                return;
            }
            
            // For each event, find all participants
            this.allEdges.forEach(edge => {
                if (!participationEdgeTypes.has(edge.type || '')) return;
                
                const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
                const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
                
                if (!sourceId || !targetId) return;
                
                // Check if this edge connects to our event
                if (sourceId === eventId || targetId === eventId) {
                    // Identify the connected person
                    const connectedNodeId = sourceId === eventId ? targetId : sourceId;
                    const connectedNode = this.allNodesMap.get(connectedNodeId);
                    
                    // Only add relevant persons not already in our subgraph
                    if (connectedNode && 
                        connectedNode.type === 'person' && 
                        !this.userNodeIds.has(connectedNodeId) && 
                        !currentSubgraphNodeIds.has(connectedNodeId)) {
                        
                        // Only add if they're a focus person or keyword match
                        const isRelevantPerson = focusPersonIds.has(connectedNodeId) || 
                                                keywordMatchedNodeIds.has(connectedNodeId);
                        
                        if (isRelevantPerson) {
                            currentSubgraphNodeIds.add(connectedNodeId);
                            const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                            subgraphEdgeIds.add(edgeKey);
                            
                            console.log(`AdvancedSearchService: Added relevant person from event: ${eventId} <-[${edge.type}]-> ${connectedNodeId} (${connectedNode.label || 'unknown'})`);
                        }
                    } else if (connectedNode && currentSubgraphNodeIds.has(connectedNodeId)) {
                        // If person already in subgraph, just ensure the edge is included
                        const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                        subgraphEdgeIds.add(edgeKey);
                    }
                }
            });
        });
    }

    /**
     * Perform general graph expansion from seed nodes based on hints.
     */
    private performGeneralGraphExpansion(
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>,
        initialMatchedNodeIds: Set<string>,
        focusAndBaseIds: Set<string>
    ): void {
        console.log(`AdvancedSearchService: Performing General Graph Expansion (mode: ${this.hints.expandFrom})...`);
        
        // Determine expansion seeds based on hints
        let nodesToExpandFrom = new Set<string>();

        if (this.hints.expandFrom === 'FOCUS_ONLY') {
            // Only use original focus persons and base nodes
            focusAndBaseIds.forEach(id => { 
                if (currentSubgraphNodeIds.has(id) && !this.userNodeIds.has(id)) {
                    nodesToExpandFrom.add(id);
                } else if (this.userNodeIds.has(id)) {
                    console.log(`AdvancedSearchService: Skipping user node ${id} from expansion seeds`);
                }
            });
            console.log(`AdvancedSearchService: General expansion seeds limited to ${nodesToExpandFrom.size} original focus/base nodes (excluding user nodes).`);
        } else { // 'ALL_INITIAL' (default)
            // Use all initially matched non-user nodes
            initialMatchedNodeIds.forEach(id => {
                if (!this.userNodeIds.has(id)) {
                    nodesToExpandFrom.add(id);
                } else {
                    console.log(`AdvancedSearchService: Skipping user node ${id} from expansion seeds`);
                }
            });
            console.log(`AdvancedSearchService: General expansion seeds include ${nodesToExpandFrom.size} initially matched nodes (excluding user nodes).`);
        }

        // Perform 1-hop expansion from these general seeds
        if (nodesToExpandFrom.size > 0) {
            const newlyAddedNodesGeneral = new Set<string>();
            
            // For better performance, map edges by node ID for faster lookups
            const nodeToEdgesMap = this.buildNodeToEdgesMap();
            
            // Process each seed node
            nodesToExpandFrom.forEach(seedId => {
                const connectedEdges = nodeToEdgesMap.get(seedId) || [];
                
                for (const edge of connectedEdges) {
                    const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
                    const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
                    
                    if (!sourceId || !targetId) continue;
                    
                    // Get the neighbor node (the one that's not our seed)
                    const neighborId = sourceId === seedId ? targetId : sourceId;
                    
                    // Skip user nodes and already included nodes
                    if (this.userNodeIds.has(neighborId) || currentSubgraphNodeIds.has(neighborId)) continue;
                    
                    // Add the neighbor node and the connecting edge
                    if (this.allNodesMap.has(neighborId)) {
                        currentSubgraphNodeIds.add(neighborId);
                        newlyAddedNodesGeneral.add(neighborId);
                        const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                        subgraphEdgeIds.add(edgeKey);
                    }
                }
            });
            
            console.log(`AdvancedSearchService: Added ${newlyAddedNodesGeneral.size} nodes during general expansion.`);
        }
        
        console.log(`AdvancedSearchService: Nodes after General Expansion: ${currentSubgraphNodeIds.size}`);
    }

    /**
     * Build a map from node IDs to their connected edges for faster lookups.
     */
    private buildNodeToEdgesMap(): Map<string, EdgeData[]> {
        const nodeToEdgesMap = new Map<string, EdgeData[]>();
        
        this.allEdges.forEach(edge => {
            const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
            const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
            
            if (!sourceId || !targetId) return;
            
            // Add edge to source node's list
            if (!nodeToEdgesMap.has(sourceId)) {
                nodeToEdgesMap.set(sourceId, []);
            }
            nodeToEdgesMap.get(sourceId)!.push(edge);
            
            // Add edge to target node's list (if different from source)
            if (sourceId !== targetId) {
                if (!nodeToEdgesMap.has(targetId)) {
                    nodeToEdgesMap.set(targetId, []);
                }
                nodeToEdgesMap.get(targetId)!.push(edge);
            }
        });
        
        return nodeToEdgesMap;
    }

    /**
     * Finalize the subgraph by ensuring all internal edges are included
     * and reintegrating user nodes.
     */
    private finalizeSubgraph(
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>
    ): Set<string> {
        // Ensure all edges connecting nodes within the final non-user set are included
        console.log("AdvancedSearchService: Selecting final internal edges...");
        this.includeAllInternalEdges(currentSubgraphNodeIds, subgraphEdgeIds);
        
        // Reintegrate user nodes and their edges
        console.log("AdvancedSearchService: Re-integrating user node(s)...");
        const finalNodesWithUserIds = this.reintegrateUserNodes(
            currentSubgraphNodeIds,
            subgraphEdgeIds
        );
        
        console.log(`AdvancedSearchService: Final node count (incl user): ${finalNodesWithUserIds.size}, Final edge count: ${subgraphEdgeIds.size}`);
        
        return finalNodesWithUserIds;
    }

    /**
     * Include all edges between nodes that are already in the subgraph.
     */
    private includeAllInternalEdges(
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>
    ): void {
        // Using a node-to-edges map for faster lookups
        const nodeToEdgesMap = this.buildNodeToEdgesMap();
        
        // Process each node in our subgraph
        currentSubgraphNodeIds.forEach(nodeId => {
            const connectedEdges = nodeToEdgesMap.get(nodeId) || [];
            
            for (const edge of connectedEdges) {
                const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
                const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
                
                if (!sourceId || !targetId) continue;
                
                // Create an edge key for lookup
                const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                
                // Skip if already added
                if (subgraphEdgeIds.has(edgeKey)) continue;

                // Include edge if both endpoints are in the subgraph
                if (currentSubgraphNodeIds.has(sourceId) && currentSubgraphNodeIds.has(targetId)) {
                    subgraphEdgeIds.add(edgeKey);
                }
            }
        });
    }

    /**
     * Reintegrate user nodes into the subgraph and connect them appropriately.
     */
    private reintegrateUserNodes(
        currentSubgraphNodeIds: Set<string>,
        subgraphEdgeIds: Set<string>
    ): Set<string> {
        let finalNodesWithUserIds = new Set(currentSubgraphNodeIds);
        
        // Add all user nodes
        this.userNodeIds.forEach(userId => {
            if (this.allNodesMap.has(userId)) {
                finalNodesWithUserIds.add(userId);
            }
        });

        // Use node-to-edges map for faster lookups
        const nodeToEdgesMap = this.buildNodeToEdgesMap();
        
        // Connect user nodes to the rest of the subgraph
        this.userNodeIds.forEach(userId => {
            const connectedEdges = nodeToEdgesMap.get(userId) || [];
            
            for (const edge of connectedEdges) {
                const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
                const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
                
                if (!sourceId || !targetId) continue;
                
                // Create an edge key for lookup
                const edgeKey = this.createEdgeKey(sourceId, targetId, edge.type);
                
                // Skip if already added
                if (subgraphEdgeIds.has(edgeKey)) continue;

                // Add edge if it connects to a node in the non-user subgraph
                const otherNodeId = sourceId === userId ? targetId : sourceId;
                if (currentSubgraphNodeIds.has(otherNodeId)) {
                    subgraphEdgeIds.add(edgeKey);
                }
            }
        });
        
        return finalNodesWithUserIds;
    }

    /**
     * Check if all required constraints are met in the final subgraph.
     */
    private checkConstraints(finalNodesWithUserIds: Set<string>): boolean {
        let constraintsMet = true;
        
        if (this.hints.requireEntityPresent.size > 0) {
            // Get set of node types in the final subgraph
            const finalNodeTypes = new Set(
                Array.from(finalNodesWithUserIds)
                    .map(id => this.allNodesMap.get(id)?.type)
                    .filter(type => !!type) as string[]
            );
            
            // Check each required entity type
            for (const requiredType of this.hints.requireEntityPresent) {
                if (!finalNodeTypes.has(requiredType)) {
                    console.warn(`AdvancedSearchService: Constraint check FAILED! Required entity type '${requiredType}' not found in the final subgraph.`);
                    constraintsMet = false;
                    break;
                }
            }
            
            if (constraintsMet) {
                console.log("AdvancedSearchService: All REQUIRE_ENTITY_PRESENT constraints met.");
            }
        }
        
        return constraintsMet;
    }

    /**
     * Apply final filters to the subgraph (date and entity type filters).
     */
    private applyFinalFilters(
        finalNodesWithUserIds: Set<string>,
        subgraphEdgeIds: Set<string>,
        preFilterResult: PreFilterResult
    ): {
        nodeIds: Set<string>;
        edgeIds: Set<string>;
    } {
        let filteredNodeIds = finalNodesWithUserIds;
        
        // --- Date Range Final Filtering ---
        const dateRange = this.parseDateRange(preFilterResult.dateStart, preFilterResult.dateEnd);
        if (dateRange && preFilterResult.dateStart !== '0000-00-00' && preFilterResult.dateEnd !== '0000-00-00') {
            console.log(`AdvancedSearchService: Applying final date range filter: ${preFilterResult.dateStart} to ${preFilterResult.dateEnd}`);
            
            const nodesToKeep = new Set<string>();
            
            // Add all person and memory nodes, filter other types by date
            Array.from(filteredNodeIds).forEach(id => {
                const node = this.allNodesMap.get(id);
                if (node) {
                    // Always keep person and memory nodes regardless of date
                    if (node.type === 'person' || node.type === 'memory') {
                        nodesToKeep.add(id);
                    }
                    // For other nodes, check if they have dates in range
                    else if (this.isNodeInDateRangeExcludingCreatedAt(node, dateRange)) {
                        nodesToKeep.add(id);
                    }
                }
            });
            
            console.log(`AdvancedSearchService: After date filtering, kept ${nodesToKeep.size} nodes out of ${filteredNodeIds.size}`);
            filteredNodeIds = nodesToKeep;
        }
        
        // --- Entity Type Filtering ---
        if (preFilterResult.relevantEntityTypes && preFilterResult.relevantEntityTypes.length > 0) {
            console.log(`AdvancedSearchService: Filtering by entity types: ${preFilterResult.relevantEntityTypes.join(', ')}`);
            
            const relevantEntityTypeSet = new Set(preFilterResult.relevantEntityTypes);
            const entityFilteredNodeIds = new Set<string>();
            
            Array.from(filteredNodeIds).forEach(id => {
                const node = this.allNodesMap.get(id);
                if (node) {
                    // Always keep user nodes regardless of entity type
                    if (this.userNodeIds.has(id)) {
                        entityFilteredNodeIds.add(id);
                    } 
                    // Keep nodes with matching entity types
                    else if (relevantEntityTypeSet.has(node.type)) {
                        entityFilteredNodeIds.add(id);
                    }
                }
            });
            
            console.log(`AdvancedSearchService: After entity filtering, kept ${entityFilteredNodeIds.size} nodes out of ${filteredNodeIds.size}`);
            filteredNodeIds = entityFilteredNodeIds;
        }
        
        // Filter edges to only include those connecting nodes in the filtered set
        const filteredEdgeIds = this.getEdgesForFilteredNodes(filteredNodeIds, subgraphEdgeIds);
        
        return { nodeIds: filteredNodeIds, edgeIds: filteredEdgeIds };
    }

    /**
     * Get edges that connect nodes in the filtered set.
     */
    private getEdgesForFilteredNodes(
        nodeIds: Set<string>,
        edgeIds: Set<string>
    ): Set<string> {
        const finalEdgeIds = new Set<string>();
        
        // Create a map for faster edge lookup
        const edgeMap = new Map<string, EdgeData>();
        this.allEdges.forEach(edge => {
            const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
            const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
            if (sourceId && targetId) {
                const key = this.createEdgeKey(sourceId, targetId, edge.type);
                edgeMap.set(key, edge);
            }
        });
        
        // Keep only edges where both endpoints are in the filtered node set
        edgeIds.forEach(edgeKey => {
            const edge = edgeMap.get(edgeKey);
            if (edge) {
                const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
                const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
                
                if (nodeIds.has(sourceId) && nodeIds.has(targetId)) {
                    finalEdgeIds.add(edgeKey);
                }
            }
        });
        
        return finalEdgeIds;
    }

    /**
     * Build the final subgraph object from the filtered nodes and edges.
     */
    private buildFinalSubgraph(
        finalNodeIds: Set<string>,
        edgeIds: Set<string>
    ): { nodes: NodeData[]; edges: EdgeData[] } {
        // Retrieve full node data
        const finalNodes = Array.from(finalNodeIds)
            .map(id => this.allNodesMap.get(id))
            .filter((n): n is NodeData => n !== undefined);

        // Create a map for faster edge lookup
        const edgeKeyMap = new Map<string, EdgeData>();
        this.allEdges.forEach(edge => {
            const sourceId = this.getNodeIdFromEdgeEndpoint(edge.source);
            const targetId = this.getNodeIdFromEdgeEndpoint(edge.target);
            if (sourceId && targetId) {
                const key = this.createEdgeKey(sourceId, targetId, edge.type);
                edgeKeyMap.set(key, edge);
            }
        });
        
        // Build the final edge array
        const finalEdges: EdgeData[] = Array.from(edgeIds)
            .map(key => edgeKeyMap.get(key))
            .filter((e): e is EdgeData => e !== undefined);

        console.log(`AdvancedSearchService: Final subgraph constructed with ${finalNodes.length} nodes and ${finalEdges.length} edges.`);
        return { nodes: finalNodes, edges: finalEdges };
    }

    // Helper Functions

    /**
     * Safely extracts the node ID from an edge endpoint (which might be a string or an object).
     * Improved for better type safety.
     */
    private getNodeIdFromEdgeEndpoint(endpoint: string | any): string {
        if (typeof endpoint === 'string') return endpoint;
        if (endpoint && typeof endpoint === 'object' && typeof endpoint.id === 'string') return endpoint.id;
        return '';
    }

    /**
     * Creates a unique edge identifier key for edge lookup and storage.
     */
    private createEdgeKey(sourceId: string, targetId: string, type?: string): string {
        return `${sourceId}-${targetId}-${type || 'default'}`;
    }

    /**
     * Check if a node ID represents a person (used for type-specific edge handling).
     */
    private isPerson(nodeId: string): boolean {
        const node = this.allNodesMap.get(nodeId);
        return !!node && node.type === 'person';
    }

    /**
     * Helper to check if a node's date falls within the range.
     */
    private isNodeInDateRange(node: NodeData, dateRange: { start: Date; end: Date } | null): boolean {
        if (!dateRange || !node.properties) return false;
        
        const datePropsToCheck = [
            'start_datetime', 'end_datetime', 'date', 'start_date', 'end_date', 
            'datetime', 'timestamp', 'updated_at', 'check_in_date', 'check_out_date', 
            'departure_date', 'arrival_date', 'departure_time', 'arrival_time'
        ];
        
        for (const prop of datePropsToCheck) {
            const dateValue = node.properties[prop];
            if (!dateValue) continue;
            
            let nodeDate: Date | null = null;
            
            // Handle string dates
            if (typeof dateValue === 'string') { 
                try { nodeDate = parseISO(dateValue); } 
                catch (e) { /* Ignore parsing errors */ } 
            }
            // Handle timestamp numbers
            else if (typeof dateValue === 'number') { 
                try { nodeDate = new Date(dateValue); } 
                catch (e) { /* Ignore parsing errors */ } 
            }
            
            if (nodeDate && isValid(nodeDate) && isWithinInterval(nodeDate, dateRange)) {
                return true;
            }
        }
        return false;
    }
    
    /**
     * Helper to check if a node's date falls within the range, excluding created_at and updated_at properties.
     * This focuses on actual event dates, not metadata timestamps.
     */
    private isNodeInDateRangeExcludingCreatedAt(node: NodeData, dateRange: { start: Date; end: Date } | null): boolean {
        if (!dateRange || !node.properties) return false;
        
        // Same as regular date props but exclude created_at and updated_at (metadata properties)
        const datePropsToCheck = [
            'start_datetime', 'end_datetime', 'date', 'start_date', 'end_date', 
            'datetime', 'timestamp', 'check_in_date', 'check_out_date', 
            'departure_date', 'arrival_date', 'departure_time', 'arrival_time'
        ];
        
        for (const prop of datePropsToCheck) {
            const dateValue = node.properties[prop];
            if (!dateValue) continue;
            
            let nodeDate: Date | null = null;
            
            if (typeof dateValue === 'string') { 
                try { nodeDate = parseISO(dateValue); } 
                catch (e) { /* Ignore parsing errors */ } 
            }
            else if (typeof dateValue === 'number') { 
                try { nodeDate = new Date(dateValue); } 
                catch (e) { /* Ignore parsing errors */ } 
            }
            
            if (nodeDate && isValid(nodeDate) && isWithinInterval(nodeDate, dateRange)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Simple case-insensitive substring matching for fuzzy search.
     */
    private fuzzyMatch(text: string | undefined | null, term: string): boolean {
        if (!text || !term) return false;
        return text.toLowerCase().includes(term.toLowerCase());
    }

    /**
     * Checks if a node type is typically associated with temporal (date-based) data.
     */
    private isTemporalNode(node: NodeData): boolean {
        const temporalTypes = new Set([
            'calendar_event', 'flight', 'hotel_stay', 'dining', 
            'email', 'memory', 'event'
        ]);
        return temporalTypes.has(node.type);
    }

    /**
     * Parses start and end date strings into a Date range object.
     */
    private parseDateRange(startStr?: string, endStr?: string): { start: Date; end: Date } | null {
        if (!startStr || !endStr || startStr === '0000-00-00' || endStr === '0000-00-00') return null;
        
        try {
            const start = parseISO(startStr);
            const end = endOfDay(parseISO(endStr)); // Use endOfDay for inclusive range check
            
            if (isValid(start) && isValid(end) && start <= end) {
                return { start, end };
            } else { 
                console.warn(`AdvancedSearchService: Invalid date range: Start=${startStr}, End=${endStr}`); 
                return null; 
            }
        } catch (e) { 
            console.error("AdvancedSearchService: Error parsing date range", startStr, endStr, e); 
        }
        
        return null;
    }

    /**
     * Maps a conceptual property key to potential actual property names in the graph.
     */
    private getGraphPropertiesForConcept(entityType: string, conceptPropKey: string): string[] {
        const key = conceptPropKey.toLowerCase(); // Normalize key for case-insensitive matching

        // --- Flight Specific Mappings ---
        if (entityType === 'flight') {
            if (key === 'location' || key === 'airport' || key === 'airports' || key === 'origin' || key === 'destination') {
                // Check all relevant airport fields including nested ones
                return ['origin', 'destination', 'legs.departure_airport', 'legs.arrival_airport', 'complete_route', 'label'];
            }
             if (key === 'airline') {
                return ['legs.airline', 'label']; // Check airline in legs and potentially label
            }
            if (key === 'flight_number') {
                return ['legs.flight_number', 'label']; // Check flight number in legs and potentially label
            }
        }
        // --- Hotel Specific Mappings ---
        else if (entityType === 'hotel_stay') {
            if (key === 'location' || key === 'city' || key === 'address' || key === 'hotel_name') {
                return ['location', 'address', 'city', 'hotel_name', 'label'];
            }
        }
        // --- Calendar Event Specific Mappings ---
        else if (entityType === 'calendar_event') {
            if (key === 'location' || key === 'address') {
                return ['location', 'address'];
            }
            if (key === 'title' || key === 'topic' || key === 'subject') {
                return ['title', 'label', 'summary', 'description'];
            }
            if (key === 'attendees' || key === 'participants' || key === 'with') {
                 // Add all properties where participant names might be stored
                 return ['attendees', 'participant_names', 'with', 'participants'];
            }
            // Explicitly avoid returning ical_uid for any search
            if (key === 'ical_uid') {
                return [];
            }
        }
        // --- Memory Specific Mappings ---
        else if (entityType === 'memory') {
             if (key === 'content' || key === 'topic' || key === 'text' || key === 'notes') {
                 return ['content', 'description', 'notes', 'label'];
             }
             if (key === 'people' || key === 'participants' || key === 'involved_people') {
                 // Add all properties where involved people might be stored
                 return ['involved_people', 'memory_with', 'participants'];
             }
        }
        // --- Dining Specific Mappings ---
         else if (entityType === 'dining') {
            if (key === 'location' || key === 'restaurant' || key === 'restaurant_name') {
                return ['location', 'address', 'restaurant_name', 'label'];
            }
             if (key === 'attendees' || key === 'participants' || key === 'with') {
                 return ['attendees', 'with', 'participants'];
             }
        }
        // --- Email Specific Mappings ---
        else if (entityType === 'email') {
             if (key === 'subject' || key === 'topic') {
                 return ['subject', 'thread_topic'];
             }
             if (key === 'sender' || key === 'from') {
                 return ['sender_email', 'sender_name'];
             }
              if (key === 'recipient' || key === 'to' || key === 'cc' || key === 'bcc') {
                 // Add properties where recipients might be stored
                 return ['recipient_emails', 'recipient_names', 'recipients'];
             }
        }
        // --- Person Specific Mappings ---
        else if (entityType === 'person') {
             if (key === 'name') {
                 return ['name', 'label', 'full_name']; // Check multiple common name properties
             }
             if (key === 'email') {
                 return ['email', 'emails']; // Check potential email properties
             }
             if (key === 'organization' || key === 'company') {
                 return ['organization', 'company_name']; // Check potential org properties
             }
        }

        // --- General Fallbacks (Apply to multiple types) ---
        if (key === 'location') return ['location', 'address', 'city', 'place', 'label'];
        if (key === 'name' || key === 'title') return ['name', 'title', 'label'];
        if (key === 'content') return ['content', 'description', 'notes', 'text', 'label'];

        // Default: return the key itself if no specific mapping found
        return [conceptPropKey];
    }

     /**
     * Safely retrieves a potentially nested property value from a node's properties object.
     * Supports dot notation (e.g., 'address.city') and array wildcard notation (e.g., 'legs[*].departure_airport').
     */
    private getNodePropertyValue(properties: Record<string, any> | null | undefined, propPath: string): any {
         if (!properties || !propPath) {
            return undefined;
        }

        const parts = propPath.split('.');
        let currentLevelValue: any = properties;

        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];

            // If current level is null or not an object/array, cannot traverse further
            if (currentLevelValue === null || typeof currentLevelValue === 'undefined') {
                 return undefined;
            }

            // Handle array wildcard notation: key[*]
            if (part.endsWith('[*]')) {
                const arrayKey = part.substring(0, part.length - 3);
                const remainingPath = parts.slice(i + 1).join('.'); // Path for elements inside array

                // Check if the key exists and is an array
                if (Object.prototype.hasOwnProperty.call(currentLevelValue, arrayKey) && Array.isArray(currentLevelValue[arrayKey])) {
                    const arrayValue = currentLevelValue[arrayKey];
                    if (!remainingPath) {
                         // If [*] is the last part, return the whole array
                         return arrayValue;
                    }
                    // Otherwise, map over the array and get nested values recursively
                    // Ensure items are objects before recursing if remainingPath exists
                    return (arrayValue as any[])
                        .map(item => typeof item === 'object' && item !== null ? this.getNodePropertyValue(item, remainingPath) : undefined)
                        .filter(v => v !== undefined); // Filter out undefined results from deeper levels
                } else {
                    return undefined; // Key doesn't exist or is not an array
                }
            } else {
                 // Standard property access
                 // Check if currentLevel is an object before accessing property
                 if (typeof currentLevelValue !== 'object' || !Object.prototype.hasOwnProperty.call(currentLevelValue, part)) {
                     return undefined; // Not an object or property doesn't exist
                 }
                 currentLevelValue = currentLevelValue[part];
            }
        }
        return currentLevelValue; // Return the final value found
    }

    /**
     * Checks if a value (string or array of strings/values) contains any of the target strings.
     * Performs a case-insensitive 'includes' check.
     * IMPORTANT: Ignores ical_uid values for any matching to prevent false positives.
     */
    private checkValueMatch(value: any, targetValues: string[], propertyName?: string): boolean {
        // Skip checking if the property is ical_uid
        if (propertyName === 'ical_uid') return false;
        
        if (!Array.isArray(targetValues) || targetValues.length === 0) return false;

        // Prepare lowercase target values once for efficiency, filtering out non-strings
        const lowerTargetValues = targetValues
            .map(t => typeof t === 'string' ? t.toLowerCase() : null)
            .filter((t): t is string => t !== null); // Ensure we have an array of strings

        if (lowerTargetValues.length === 0) return false; // No valid string targets

        // Recursive function to check value (handles nested arrays potentially returned by getNodePropertyValue)
        const check = (valToCheck: any): boolean => {
            // Skip if the value is an ical_uid-like value (typically very long strings with @ symbols)
            if (typeof valToCheck === 'string' && 
                (valToCheck.includes('@') && valToCheck.length > 30 && 
                 (valToCheck.startsWith('ical') || valToCheck.includes('_ical')))) {
                return false;
            }
            
            if (typeof valToCheck === 'string') {
                const lowerSingleValue = valToCheck.toLowerCase();
                // Check if the string *includes* any of the target values
                return lowerTargetValues.some(target => lowerSingleValue.includes(target));
            } else if (Array.isArray(valToCheck)) {
                // Check if *any* element in the array matches *any* target value
                return valToCheck.some(item => check(item)); // Recurse for nested arrays
            } else if (typeof valToCheck === 'object' && valToCheck !== null) {
                // For objects, check all values except ical_uid
                return Object.entries(valToCheck)
                    .filter(([key]) => key !== 'ical_uid') // Skip ical_uid properties
                    .some(([_, val]) => check(val));
            }
            return false; // Type not handled or no match
        };

        return check(value);
    }

    // Compact Prefilter Result Conversion Methods

    /**
     * Converts a compact prefilter result to the standard verbose format
     */
    public convertCompactToVerbose(compactResult: CompactPrefilterResult): PrefilterVoiceQueryResult {
        const verboseResult: PrefilterVoiceQueryResult = {
            refinedQuery: compactResult.q,
            relevantEntityTypes: this.expandEntityTypes(compactResult.e),
            dateStart: compactResult.ds || '0000-00-00',
            dateEnd: compactResult.de || '0000-00-00',
            baseNodes: []
        };

        // Convert focus person IDs from numbers to full IDs
        if (compactResult.p && compactResult.p.length > 0) {
            verboseResult.focusPersonIds = compactResult.p.map(id => `person_${id}`);
        }

        // Convert base node IDs from numbers to full IDs
        if (compactResult.b && compactResult.b.length > 0) {
            verboseResult.baseNodes = compactResult.b.map(id => `person_${id}`);
        }

        // Convert search terms
        if (compactResult.t) {
            verboseResult.searchTerms = {};
            for (const [entityCode, terms] of Object.entries(compactResult.t)) {
                const entityType = this.expandEntityType(entityCode);
                if (!entityType) continue; // Skip if entity type is not recognized

                verboseResult.searchTerms[entityType] = {
                    exactMatch: terms.x || [],
                    fuzzyMatch: terms.f || [],
                    propertyMatch: this.expandPropertyMatches(terms.m || {}),
                    expansionDepth: terms.d || 1
                };
            }
        }

        // Convert search patterns
        if (compactResult.s && compactResult.s.length > 0) {
            verboseResult.searchPatterns = compactResult.s.map(pattern => {
                const verbosePattern: any = {
                    pattern: this.expandPatternCode(pattern.p),
                    startNodeIds: pattern.i ? pattern.i.map(id => `person_${id}`) : [],
                    targetTypes: pattern.tt ? this.expandEntityTypes(pattern.tt) : [],
                };
                return verbosePattern;
            });
        }

        // Copy hints and explanation
        if (compactResult.h) {
            verboseResult.searchStrategyHints = compactResult.h;
        }
        
        if (compactResult.x) {
            verboseResult.explanation = compactResult.x;
        }

        return verboseResult;
    }

    /**
     * Converts a standard prefilter result to the compact format for LLM efficiency
     */
    public convertVerboseToCompact(verboseResult: PrefilterVoiceQueryResult): CompactPrefilterResult {
        const compactResult: CompactPrefilterResult = {
            q: verboseResult.refinedQuery,
            e: this.compactEntityTypes(verboseResult.relevantEntityTypes)
        };

        // Convert focus person IDs
        if (verboseResult.focusPersonIds && verboseResult.focusPersonIds.length > 0) {
            compactResult.p = verboseResult.focusPersonIds
                .map(id => this.extractPersonId(id))
                .filter((id): id is number => id !== null);
        }

        // Convert base nodes
        if (verboseResult.baseNodes && verboseResult.baseNodes.length > 0) {
            compactResult.b = verboseResult.baseNodes
                .map(id => this.extractPersonId(id))
                .filter((id): id is number => id !== null);
        }

        // Convert dates
        if (verboseResult.dateStart && verboseResult.dateStart !== '0000-00-00') {
            compactResult.ds = verboseResult.dateStart;
        }
        
        if (verboseResult.dateEnd && verboseResult.dateEnd !== '0000-00-00') {
            compactResult.de = verboseResult.dateEnd;
        }

        // Convert search terms
        if (verboseResult.searchTerms && Object.keys(verboseResult.searchTerms).length > 0) {
            compactResult.t = {};
            
            for (const [entityType, terms] of Object.entries(verboseResult.searchTerms)) {
                const entityCode = this.compactEntityType(entityType);
                if (!entityCode) continue; // Skip if entity type cannot be compacted
                
                compactResult.t[entityCode] = {};
                
                if (terms.exactMatch && terms.exactMatch.length > 0) {
                    compactResult.t[entityCode].x = terms.exactMatch;
                }
                
                if (terms.fuzzyMatch && terms.fuzzyMatch.length > 0) {
                    compactResult.t[entityCode].f = terms.fuzzyMatch;
                }
                
                if (terms.propertyMatch && Object.keys(terms.propertyMatch).length > 0) {
                    compactResult.t[entityCode].m = this.compactPropertyMatches(terms.propertyMatch);
                }
                
                if (terms.expansionDepth !== undefined) {
                    compactResult.t[entityCode].d = terms.expansionDepth;
                }
            }
        }

        // Convert search patterns
        if (verboseResult.searchPatterns && verboseResult.searchPatterns.length > 0) {
            compactResult.s = verboseResult.searchPatterns.map(pattern => {
                const compactPattern: any = {
                    p: this.compactPatternCode(pattern.pattern)
                };
                
                if (pattern.startNodeIds && pattern.startNodeIds.length > 0) {
                    compactPattern.i = pattern.startNodeIds
                        .map(id => this.extractPersonId(id))
                        .filter((id): id is number => id !== null);
                }
                
                if (pattern.targetTypes && pattern.targetTypes.length > 0) {
                    compactPattern.tt = this.compactEntityTypes(pattern.targetTypes);
                }
                
                return compactPattern;
            });
        }

        // Copy hints and explanation
        if (verboseResult.searchStrategyHints && verboseResult.searchStrategyHints.length > 0) {
            compactResult.h = verboseResult.searchStrategyHints;
        }
        
        if (verboseResult.explanation) {
            compactResult.x = verboseResult.explanation;
        }

        return compactResult;
    }

    /**
     * Extracts the numeric ID from a person ID string (e.g., "person_123" -> 123)
     */
    private extractPersonId(personId: string): number | null {
        if (!personId) return null;
        
        const match = personId.match(/^person_(\d+)$/);
        if (match && match[1]) {
            const id = parseInt(match[1], 10);
            return isNaN(id) ? null : id;
        }
        return null;
    }

    /**
     * Converts entity type codes to full entity type names
     */
    private expandEntityTypes(entityCodes: string[]): string[] {
        return entityCodes
            .map(code => this.expandEntityType(code))
            .filter((type): type is string => type !== null);
    }

    private expandEntityType(code: string): string | null {
        return ENTITY_TYPE_REVERSE_MAP[code] || null;
    }

    private compactEntityTypes(entityTypes: string[]): string[] {
        return entityTypes
            .map(type => this.compactEntityType(type))
            .filter((code): code is string => code !== null);
    }

    private compactEntityType(entityType: string): string | null {
        return ENTITY_TYPE_MAP[entityType] || null;
    }

    private expandPropertyMatches(compactMatches: Record<string, string[]>): Record<string, string[]> {
        const expandedMatches: Record<string, string[]> = {};
        
        for (const [compactKey, values] of Object.entries(compactMatches)) {
            const expandedKey = PROPERTY_KEY_REVERSE_MAP[compactKey] || compactKey;
            expandedMatches[expandedKey] = values;
        }
        
        return expandedMatches;
    }

    private compactPropertyMatches(verboseMatches: Record<string, string[]>): Record<string, string[]> {
        const compactMatches: Record<string, string[]> = {};
        
        for (const [verboseKey, values] of Object.entries(verboseMatches)) {
            const compactKey = PROPERTY_KEY_MAP[verboseKey] || verboseKey;
            compactMatches[compactKey] = values;
        }
        
        return compactMatches;
    }

    private expandPatternCode(code: string): string {
        return PATTERN_REVERSE_MAP[code] || code;
    }

    private compactPatternCode(pattern: string): string {
        return PATTERN_MAP[pattern] || pattern;
    }
    
    /**
     * Determines if the provided result is in compact format
     */
    private isCompactFormat(result: PreFilterResult | CompactPrefilterResult): result is CompactPrefilterResult {
        return (
            'q' in result && 
            'e' in result && 
            !('refinedQuery' in result) && 
            !('relevantEntityTypes' in result)
        );
    }
}

// Export a singleton instance for use in the application
export const advancedSearchService = new AdvancedSearchService();