import { useState } from 'react';
import { inject, observer } from 'mobx-react';

import withRouter from 'hooks/withRouter';
import StepHeader from './StepHeader';
import SafeguardsModal from '../modals/SafeguardsModal';
import DataHandlingModal from '../modals/DataHandlingModal';
import { GoogleIcon, CaretRightIcon } from 'components/common/Icon';
import OnboardingWrapper from '../index';
import { Text, H3Text, LINK } from 'components/common/Typography';
import Card from 'components/common/Card';
import { EVENTS } from 'pages/support';

const AccountOption = ({ name, id, onClick, Icon, showCaret = false, comingSoon = false, requested = false }) => (
    <div
        className={`connect-account-option ${comingSoon ? 'coming-soon' : ''} ${requested ? 'requested' : ''}`}
        onClick={() => onClick(id)}
    >
        <div><Icon /></div>
        <div className="flex-1">
            <Text>{name}</Text>
        </div>
        {showCaret && <div><CaretRightIcon color="#3F3F3F" /></div>}
    </div>
);

const Connect = ({ OnboardingStore, router }: { OnboardingStore?: any, router?: any }) => {
    const [safeguardsOpen, setSafeguardsOpen] = useState(false);
    const [dataHandlingOpen, setDataHandlingOpen] = useState(false);

    return (
        <OnboardingWrapper>
            <StepHeader
                progress={60}
                text='Connection'
                onClick={() => router.navigate('/onboarding/details')}
            />

            <Card>
                <div className="adapter-two-col">

                    <div className="adapter-two-col--title">
                        <div className="flex-1"></div>

                        <H3Text cls='adapter-font-color-primary-900'>
                            Connect an Account
                        </H3Text>

                        <Text>
                            Adapter unlocks valuable activity data from the tools you use every day. It imports your data history
                            into Adapter and gets any new data when it is created. You can set filters for the type of data that
                            is collected and revoke access to an account at any time.
                        </Text>

                        <Text>
                            <LINK onClick={() => setSafeguardsOpen(true)}>Learn more with our data FAQ&nbsp;&rarr;</LINK>
                        </Text>

                        <div className="flex-1"></div>
                    </div>

                    <div className="adapter-two-col--content">
                        <div className="flex-1"></div>

                        <div className="account-option-wrapper">
                            <AccountOption
                                name="Google Mail &amp; Calendar"
                                id="google"
                                Icon={GoogleIcon}
                                onClick={() => router.navigate('/onboarding/controls')}
                                showCaret
                            />

                            <Text fontSize='xs' style={{ color: '#727271' }}>
                                Don&apos;t have a Google account?&nbsp;
                                <a 
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }} 
                                    onClick={() => {
                                        // @ts-ignore
                                        ADAPTER.publishEvent(EVENTS.openFeedback);
                                    }}
                                >
                                    Let us know
                                </a>&nbsp;
                                what other types of account you would like to connect.
                            </Text>
                        </div>

                        <div className="flex-1"></div>
                    </div>
                </div>
            </Card>

            {dataHandlingOpen && <DataHandlingModal onClose={() => setDataHandlingOpen(false)} />}
            {safeguardsOpen && <SafeguardsModal onClose={() => setSafeguardsOpen(false)} />}
        </OnboardingWrapper>
    );
}

export default withRouter(inject('OnboardingStore')(observer(Connect)));