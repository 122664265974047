import React from 'react';
import Progress from 'components/common/Progress';
import { Text } from 'components/common/Typography';

const BackArrow = ({ onClick = () => {}, style = { cursor: 'pointer' } }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none" onClick={onClick} style={style}>
        <path d="M28.5 18H7.5M7.5 18L18 28.5M7.5 18L18 7.5" stroke="#73788C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

const StepHeader = ({ progress = 100, text = '', onClick }) => {
    return (
        <div className="adapter-onboarding-step-header">
            <BackArrow onClick={onClick} />
            {text && <Text>{text}</Text>}
            <Progress progress={progress} solid />
        </div>
    );
};

export default StepHeader;