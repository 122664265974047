import React, { useState } from 'react';
import ResultCard from './RestaurantResultCard';
import { Text } from 'components/common/Typography';
import { convertTo12Hour } from 'util/Time';
import Feedback from 'components/common/Feedback/Feedback';
import { MartiniIcon, LocationIcon, GlobeIcon } from 'components/common/Icon';
import Button from 'components/common/Button';
import MatchComparisonBar from '../bar/MatchComparisonBar';
import './RestaurantResponse.scss';

// Single Star component for all types (empty, partial, and full)
const Star = ({ fillPercentage = 0 }) => {
  // Create unique gradient ID for each star
  const gradientId = `star-gradient-${Math.random().toString(36).substring(2, 9)}`;
  
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset={`${fillPercentage}%`} stopColor="#F59E0B" />
          <stop offset={`${fillPercentage}%`} stopColor="#D1D5DB" />
        </linearGradient>
      </defs>
      <path 
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        fill={`url(#${gradientId})`}
        stroke="#D1D5DB" 
        strokeWidth="0.5"
      />
    </svg>
  );
};

// Complete rating stars component
const RatingStars = ({ rating }) => {
  return (
    <div className="stars-row">
      {[...Array(5)].map((_, i) => {
        let fillPercentage;
        
        if (rating >= i + 1) {
          // Full star (100% filled)
          fillPercentage = 100;
        } else if (rating > i) {
          // Partial star - calculate exact percentage
          fillPercentage = (rating - i) * 100;
        } else {
          // Empty star (0% filled)
          fillPercentage = 0;
        }
        
        return <Star key={i} fillPercentage={fillPercentage} />;
      })}
    </div>
  );
};

const RestaurantCard = ({ restaurant, index, allScores }) => {
    const [showMap, setShowMap] = useState(false);
    
    // Calculate star display with support for half stars
    const rating = restaurant.option.rating || 0;
    
    return (
        <div className="restaurant-result-wrapper">
            <div className="restaurant-header">
                <div className="restaurant-name-overlay">
                    {restaurant.option.name}
                </div>
                
                <div className="info-pills">
                    {index === 0 && (
                        <div className="info-pill best-match">
                            Best Match
                        </div>
                    )}
                    
                    {restaurant.option.type && (
                        <div className="info-pill">
                            {restaurant.option.type}
                        </div>
                    )}
                    
                    {restaurant.option.time && (
                        <div className="info-pill">
                            {convertTo12Hour(restaurant.option.time)}
                        </div>
                    )}
                    
                    {restaurant.option.price && (
                        <div className="info-pill">
                            {restaurant.option.price}
                        </div>
                    )}
                </div>
            </div>
            
            {restaurant.option.gps_coordinates && showMap && (
                <div className="map-preview expanded">
                    <ResultCard
                        gps_coordinates={restaurant.option.gps_coordinates}
                        renderMapOnly={true}
                    />
                </div>
            )}
            
            <div className="restaurant-content">
                <div className="restaurant-rating">
                  <RatingStars rating={restaurant.option.rating || 0} />
                  <span className="rating-text">
                    {rating} ({restaurant.option.reviews} reviews)
                  </span>
                </div>
                
                <div className="restaurant-address-row">
                    <div className="restaurant-location">
                        <LocationIcon title="Location" color="#6B7280" />
                        <span style={{ whiteSpace: 'break-spaces' }}>{restaurant.option.address}</span>
                    </div>
                    
                    {restaurant.option.gps_coordinates && (
                        <button 
                            className={`map-toggle-button ${showMap ? 'active' : ''}`}
                            onClick={() => setShowMap(!showMap)}
                            aria-label={showMap ? "Hide map" : "Display on map"}
                        >
                            <LocationIcon title="Map" color={showMap ? "#6D28D9" : "#6B7280"} />
                            <span>{showMap ? 'Hide Map' : 'Display on Map'}</span>
                        </button>
                    )}
                </div>
                
               {restaurant.option.website && (
                    <div className="restaurant-website">
                        <GlobeIcon title="Website" color="#6B7280" />
                        <a href={restaurant.option.website} target="_blank" rel="noopener noreferrer" style={{ whiteSpace: 'break-spaces' }}>
                            {restaurant.option.website.replace(/^https?:\/\//, '').replace(/\/$/, '')}
                        </a>
                    </div>
                )}
                
                <div className="divider"></div>
                
                <MatchComparisonBar 
                    score={restaurant.score} 
                    allScores={allScores}
                    ranking_reasoning={restaurant.ranking_reasoning}
                />
                
                <div className="reservation-cta">
                    <Button
                        onClick={() => window.open(restaurant.option.booking_url || restaurant.option.reserve_url, '_blank')}
                    >
                        Make Reservation
                    </Button>
                </div>
            </div>
        </div>
    );
};

const RestaurantResponse = ({ results, jobId }) => {
    if (!Array.isArray(results) || results.length === 0) {
        return (
            <div className="no-restaurant-results">
                <div className="no-results-icon">
                    <MartiniIcon title="No Results" />
                </div>
                <h3>No matching restaurants found</h3>
                <p>
                    We couldn't find any restaurants that match your request.
                    Try adjusting your search criteria for better results.
                </p>

                <div className="tips-container">
                    <h4>Helpful Tips:</h4>
                    <ul>
                        <li>Consider a different time or date</li>
                        <li>Expand your search radius</li>
                        <li>Try different cuisine types</li>
                        <li>Check for alternative neighborhoods</li>
                    </ul>
                </div>
            </div>
        );
    }

    // Extract all scores for comparison
    const allScores = results.map(r => r.score);

    return (
        <div className="restaurant-results-container">
            <div className="results-header">
                <div className="results-summary">
                    <div className="text-container">
                        <h2>Restaurant Options</h2>
                        <Text>
                            Adapted {results.length} option{results.length !== 1 ? 's' : ''} for you saving approximately {results.length * .75} minutes of your time.
                        </Text>
                    </div>
                </div>
            </div>

            <div className="restaurant-results-grid">
                {results.map((restaurant, index) => (
                    <RestaurantCard 
                        key={index} 
                        restaurant={restaurant} 
                        index={index}
                        allScores={allScores}
                    />
                ))}
            </div>

            <Feedback
                style={{
                    backgroundColor: '#F9F5FF',
                    borderRadius: '16px',
                    padding: '24px',
                    alignSelf: 'stretch',
                    justifyContent: 'center',
                    marginTop: '32px'
                }}
                text="Were these restaurant suggestions helpful?"
                responseKey={'prompt::results::restaurants::jobId'}
                metadata={{ jobId }}
            />
        </div>
    );
};

export default RestaurantResponse;