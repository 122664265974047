import React from 'react';

import Loading from 'components/common/Loading';

const ImportingData = () => {
    return (
        <>
            <Loading modal={false} />
        </>
    );
};

export default ImportingData;