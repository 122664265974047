import React, { createContext, useContext } from 'react';
import { NodeData, EdgeData, AIInsight } from '../types/types';

// Define the shape of context data
interface GraphContextData {
  nodes: NodeData[];
  edges: EdgeData[];
  filteredNodes: NodeData[];
  filteredEdges: EdgeData[];
  selectedNode: NodeData | null;
  connectedNodes: NodeData[];
  connectedEdges: EdgeData[];
  communities: Record<string, string>;
  communityDetails?: {
    names: Record<string, string>;
    nodeCounts: Record<string, number>;
    communityMap: Record<string, number>;
  };
  communityInsights?: Record<string, AIInsight[]>;
  suggestedQueries?: any[];
  isLoading?: boolean; // Overall loading state
  isLoadingCommunities?: boolean;
  isLoadingInsights?: boolean;
  isLoadingSuggestions?: boolean;
  darkMode: boolean;
}

// Create the context with default values
export const GraphContext = createContext<GraphContextData>({
  nodes: [],
  edges: [],
  filteredNodes: [],
  filteredEdges: [],
  selectedNode: null,
  connectedNodes: [],
  connectedEdges: [],
  communities: {},
  communityDetails: { names: {}, nodeCounts: {}, communityMap: {} },
  communityInsights: {},
  suggestedQueries: [],
  isLoading: true, // Default to loading state
  isLoadingCommunities: false,
  isLoadingInsights: false,
  isLoadingSuggestions: false,
  darkMode: false
});

// Provider component
export const GraphProvider: React.FC<{
  value: GraphContextData;
  children: React.ReactNode;
}> = ({ value, children }) => {
  return (
    <GraphContext.Provider value={value}>
      {children}
    </GraphContext.Provider>
  );
};

// Custom hook for using the graph context
export const useGraphContext = () => useContext(GraphContext);

// Re-export ForceGraphGL
export { default as ForceGraphGL } from '../graph/ForceGraphGL';