// src/pages/NodeVisualizerPage.tsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import NodeVisualizer from '../components/NodeVisualizer';
import { NodeData, NodeStatus, NodePhase } from '../types'; // Ensure all needed types are imported/exported from types.ts
import '../components/NodeVisualizer.css';
import '../components/NodeDot.css';

// --- Simulation Constants ---
const TOTAL_NODES = 100;
const FINAL_ADAPTED_NODES = 8;
const CONTEMPLATING_NODE_COUNT = 22; // Target number for the contemplating layout
const PROCESSING_COMPLETE_TARGET_PERCENT = 0.6;
const CONTEMPLATION_COMPLETE_TARGET_PERCENT = 0.5;
// --- Define the two interval speeds ---
const SLOW_INTERVAL_MS = 250; // Speed for updates *within* a visual phase
const FAST_INTERVAL_MS = 50;  // Speed *during* the visual phase transition animation
// How long the fast transition animation should roughly last
const FAST_TRANSITION_DURATION_MS = 600; // Adjust as needed for visual feel
// --- Always update 1 node per tick now, speed is controlled by interval ---
const NODES_TO_UPDATE_PER_INTERVAL = 1;

// Helper: Generate Initial Nodes
const generateInitialNodes = (count: number): NodeData[] => {
  return Array.from({ length: count }, (_, i) => ({
    id: `node-${i}`,
    status: 'processing',
    score: undefined,
  }));
};

// Simulation Phases Enum
enum SimulationPhase {
  IDLE,
  PROCESSING_COMPLETING,      // Slow speed
  SELECTING_CONTEMPLATING,    // Fast speed (transition phase)
  CONTEMPLATION_COMPLETING, // Slow speed
  SELECTING_ADAPTED,          // Fast speed (transition phase)
  FINISHED,
}

function NodeVisualizerPage() {
  const [nodes, setNodes] = useState<NodeData[]>(generateInitialNodes(TOTAL_NODES));
  const [isRunning, setIsRunning] = useState(false);
  const [currentPhase, setCurrentPhase] = useState<SimulationPhase>(SimulationPhase.IDLE);
  // State to manage the current simulation interval speed
  const [currentIntervalSpeed, setCurrentIntervalSpeed] = useState(SLOW_INTERVAL_MS);

  // Refs for counts
  const processingCompleteCountRef = useRef(0);
  const contemplatingCountRef = useRef(0);
  const contemplationCompleteCountRef = useRef(0);
  const adaptedCountRef = useRef(0);

  // Ref for the main simulation interval timer
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  // Ref for the timeout that switches back to slow speed after a fast transition
  const slowDownTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // --- Function to trigger phase change and speed adjustment ---
  const transitionToPhase = useCallback((nextPhase: SimulationPhase) => {
      console.log(`Phase transition triggered: ${SimulationPhase[currentPhase]} -> ${SimulationPhase[nextPhase]}`);

      // Determine if this transition requires speeding up
      const needsFastTransition =
          (currentPhase === SimulationPhase.PROCESSING_COMPLETING && nextPhase === SimulationPhase.SELECTING_CONTEMPLATING) ||
          (currentPhase === SimulationPhase.CONTEMPLATION_COMPLETING && nextPhase === SimulationPhase.SELECTING_ADAPTED) ||
          (currentPhase === SimulationPhase.SELECTING_CONTEMPLATING && nextPhase === SimulationPhase.CONTEMPLATION_COMPLETING) || // Speed up briefly even when selecting is "done"
          (currentPhase === SimulationPhase.SELECTING_ADAPTED && nextPhase === SimulationPhase.FINISHED) // Speed up final transition
          ;


      if (needsFastTransition) {
          // Set speed to FAST for the transition animation
          setCurrentIntervalSpeed(FAST_INTERVAL_MS);
          // Schedule returning to SLOW speed after the transition animation time
          if (slowDownTimeoutRef.current) clearTimeout(slowDownTimeoutRef.current); // Clear previous timeout if any
          slowDownTimeoutRef.current = setTimeout(() => {
              console.log("Switching back to SLOW interval speed after transition.");
              setCurrentIntervalSpeed(SLOW_INTERVAL_MS);
              slowDownTimeoutRef.current = null;
              // Only set the next *logical* phase after the fast visual transition completes
              // if the next phase is not another fast one (e.g. FINISHED)
              if(nextPhase !== SimulationPhase.FINISHED) {
                 // If transitioning FROM selecting_contemplating, the NEXT slow phase is contemplation_completing
                 if (currentPhase === SimulationPhase.SELECTING_CONTEMPLATING) {
                    setCurrentPhase(SimulationPhase.CONTEMPLATION_COMPLETING);
                 }
                 // Add other cases if needed where fast phase leads to slow phase
              } else {
                 setCurrentPhase(SimulationPhase.FINISHED); // Set finished directly
              }

          }, FAST_TRANSITION_DURATION_MS);
           // Set the *intermediate* fast phase now
           setCurrentPhase(nextPhase);
      } else {
          // If no fast transition needed, just set the phase and ensure slow speed
          setCurrentIntervalSpeed(SLOW_INTERVAL_MS);
          setCurrentPhase(nextPhase);
      }


  }, [currentPhase]); // Dependency needed

  // --- Simulation Logic (`runSimulationStep`) ---
  const runSimulationStep = useCallback(() => {

    setNodes((prevNodes) => {
       let newNodes = [...prevNodes]; // Work on a mutable copy
       let nodesUpdatedThisTick = 0;

       // --- Phase 1: PROCESSING_COMPLETING (Slow Phase) ---
       if (currentPhase === SimulationPhase.PROCESSING_COMPLETING) {
         const processingNodes = newNodes.filter(n => n.status === 'processing');
         const targetComplete = Math.floor(TOTAL_NODES * PROCESSING_COMPLETE_TARGET_PERCENT);
         if (processingNodes.length > 0 && processingCompleteCountRef.current < targetComplete) {
           for (let i = 0; i < NODES_TO_UPDATE_PER_INTERVAL && processingNodes.length > 0; i++) {
               if (processingCompleteCountRef.current >= targetComplete) break;
               const randomIndex = Math.floor(Math.random() * processingNodes.length);
               const nodeToUpdate = processingNodes[randomIndex];
               const nodeIndexInNewNodes = newNodes.findIndex(n => n.id === nodeToUpdate.id);
               if (nodeIndexInNewNodes !== -1) {
                   newNodes[nodeIndexInNewNodes] = { ...nodeToUpdate, status: 'processing-complete' };
                   processingCompleteCountRef.current++;
                   nodesUpdatedThisTick++;
                   processingNodes.splice(randomIndex, 1);
               }
           }
         } else if (processingCompleteCountRef.current >= targetComplete || processingNodes.length === 0) {
            // Transition TO the fast selection phase
            transitionToPhase(SimulationPhase.SELECTING_CONTEMPLATING);
         }
       }

       // --- Phase 2: SELECTING_CONTEMPLATING (Fast Transition Phase) ---
       else if (currentPhase === SimulationPhase.SELECTING_CONTEMPLATING) {
          const eligibleNodes = newNodes.filter(n => n.status !== 'contemplating' && (n.status === 'processing-complete' || n.status === 'processing'));
          if (eligibleNodes.length > 0 && contemplatingCountRef.current < CONTEMPLATING_NODE_COUNT) {
             for (let i = 0; i < NODES_TO_UPDATE_PER_INTERVAL && eligibleNodes.length > 0; i++) {
                 if (contemplatingCountRef.current >= CONTEMPLATING_NODE_COUNT) break;
                 const randomIndex = Math.floor(Math.random() * eligibleNodes.length);
                 const nodeToUpdate = eligibleNodes[randomIndex];
                 const nodeIndexInNewNodes = newNodes.findIndex(n => n.id === nodeToUpdate.id);
                 if (nodeIndexInNewNodes !== -1) {
                     newNodes[nodeIndexInNewNodes] = { ...nodeToUpdate, status: 'contemplating' };
                     contemplatingCountRef.current++;
                     nodesUpdatedThisTick++;
                     eligibleNodes.splice(randomIndex, 1);
                 }
             }
          } else if (contemplatingCountRef.current >= CONTEMPLATING_NODE_COUNT || eligibleNodes.length === 0) {
             // Selection is complete. The `transitionToPhase` call was already made
             // Its timeout will handle setting the *next* phase (CONTEMPLATION_COMPLETING)
             // and slowing down the interval. Do nothing further here in this tick.
             console.log("Contemplating selection finished, waiting for slow down timeout...");
          }
       }

        // --- Phase 3: CONTEMPLATION_COMPLETING (Slow Phase) ---
        else if (currentPhase === SimulationPhase.CONTEMPLATION_COMPLETING) {
            const contemplatingNodes = newNodes.filter(n => n.status === 'contemplating');
            const targetComplete = Math.floor(CONTEMPLATING_NODE_COUNT * CONTEMPLATION_COMPLETE_TARGET_PERCENT);
            if (contemplatingNodes.length > 0 && contemplationCompleteCountRef.current < targetComplete) {
                for (let i = 0; i < NODES_TO_UPDATE_PER_INTERVAL && contemplatingNodes.length > 0; i++) {
                    if (contemplationCompleteCountRef.current >= targetComplete) break;
                    const randomIndex = Math.floor(Math.random() * contemplatingNodes.length);
                    const nodeToUpdate = contemplatingNodes[randomIndex];
                    const nodeIndexInNewNodes = newNodes.findIndex(n => n.id === nodeToUpdate.id);
                    if (nodeIndexInNewNodes !== -1) {
                        newNodes[nodeIndexInNewNodes] = { ...nodeToUpdate, status: 'contemplation-complete' };
                        contemplationCompleteCountRef.current++;
                        nodesUpdatedThisTick++;
                        contemplatingNodes.splice(randomIndex, 1);
                    }
                }
            } else if (contemplationCompleteCountRef.current >= targetComplete || contemplatingNodes.length === 0) {
                 // Transition TO the fast selection phase
                 transitionToPhase(SimulationPhase.SELECTING_ADAPTED);
            }
        }

        // --- Phase 4: SELECTING_ADAPTED (Fast Transition Phase) ---
        else if (currentPhase === SimulationPhase.SELECTING_ADAPTED) {
            const eligibleNodes = newNodes.filter(n => n.status !== 'adapted' && (n.status === 'contemplating' || n.status === 'contemplation-complete'));
            if (eligibleNodes.length > 0 && adaptedCountRef.current < FINAL_ADAPTED_NODES) {
                for (let i = 0; i < NODES_TO_UPDATE_PER_INTERVAL && eligibleNodes.length > 0; i++) {
                    if (adaptedCountRef.current >= FINAL_ADAPTED_NODES) break;
                    const randomIndex = Math.floor(Math.random() * eligibleNodes.length);
                    const nodeToUpdate = eligibleNodes[randomIndex];
                    const nodeIndexInNewNodes = newNodes.findIndex(n => n.id === nodeToUpdate.id);
                    if (nodeIndexInNewNodes !== -1) {
                        newNodes[nodeIndexInNewNodes] = {
                            ...nodeToUpdate, status: 'adapted', score: Math.floor(Math.random() * 100) + 1
                        };
                        adaptedCountRef.current++;
                        nodesUpdatedThisTick++;
                        eligibleNodes.splice(randomIndex, 1);
                    }
                }
            } else if (adaptedCountRef.current >= FINAL_ADAPTED_NODES || eligibleNodes.length === 0) {
                // Selection complete. Transition to FINISHED was already called.
                // Timeout will handle actual state change and stop.
                console.log("Adapted selection finished, waiting for slow down timeout (which sets FINISHED)...");
            }
        }

       // Check if finished state reached (final state handled by timeout now)
       if (currentPhase === SimulationPhase.FINISHED) {
         if (intervalRef.current) clearInterval(intervalRef.current);
         if (slowDownTimeoutRef.current) clearTimeout(slowDownTimeoutRef.current);
         intervalRef.current = null;
         slowDownTimeoutRef.current = null;
         setIsRunning(false);
         setCurrentIntervalSpeed(SLOW_INTERVAL_MS); // Reset speed for next run
         console.log("Simulation Finished");
       }

       return nodesUpdatedThisTick > 0 ? newNodes : prevNodes;
     });
   }, [currentPhase, transitionToPhase]); // Add transitionToPhase dependency

  // --- Effect to manage the simulation interval ---
  useEffect(() => {
    // Clear existing timers on change/cleanup
    if (intervalRef.current) clearInterval(intervalRef.current);
    // Don't clear slowDownTimeoutRef here, it's managed actively

    if (isRunning && currentPhase !== SimulationPhase.FINISHED && currentPhase !== SimulationPhase.IDLE) {
      // Start the interval with the *current* speed state
      intervalRef.current = setInterval(runSimulationStep, currentIntervalSpeed);
      console.log(`Simulation interval started/updated. Speed: ${currentIntervalSpeed}ms, Phase: ${SimulationPhase[currentPhase]}`);
    } else {
      // Ensure interval is cleared if not running or finished/idle
      intervalRef.current = null; // Set to null when cleared
       console.log("Simulation interval stopped.");
    }

    // Cleanup function: clears both interval and the speed timeout
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
      if (slowDownTimeoutRef.current) clearTimeout(slowDownTimeoutRef.current);
       intervalRef.current = null;
       slowDownTimeoutRef.current = null;
      console.log("Simulation timers cleared on cleanup.");
    };
    // Depend on isRunning, currentPhase, currentIntervalSpeed, and runSimulationStep
  }, [isRunning, currentPhase, currentIntervalSpeed, runSimulationStep]);


  // --- Control Handlers ---
  const handleStartReset = () => {
    console.log("Starting/Resetting Simulation...");
    setIsRunning(false); // Stop any running simulation first
    // Clear any pending slowdown timeout immediately
    if (slowDownTimeoutRef.current) clearTimeout(slowDownTimeoutRef.current);
    slowDownTimeoutRef.current = null;

    setTimeout(() => { // Allow stop effect/cleanup to run
        setNodes(generateInitialNodes(TOTAL_NODES));
        processingCompleteCountRef.current = 0;
        contemplatingCountRef.current = 0;
        contemplationCompleteCountRef.current = 0;
        adaptedCountRef.current = 0;
        setCurrentIntervalSpeed(SLOW_INTERVAL_MS); // Start with slow speed
        setCurrentPhase(SimulationPhase.PROCESSING_COMPLETING); // Set initial active phase
        setIsRunning(true); // Start the simulation
    }, 50);
  };

  const handleStop = () => {
      console.log("Stopping Simulation...");
      setIsRunning(false); // This will trigger the useEffect cleanup
      // Also clear any pending speed change timeout
      if (slowDownTimeoutRef.current) clearTimeout(slowDownTimeoutRef.current);
      slowDownTimeoutRef.current = null;
      setCurrentIntervalSpeed(SLOW_INTERVAL_MS); // Reset speed state
  }

   const handleResetView = () => {
     console.log("Resetting View Only...");
     setIsRunning(false); // Stop simulation if running
     if (slowDownTimeoutRef.current) clearTimeout(slowDownTimeoutRef.current); // Clear timeout
     slowDownTimeoutRef.current = null;

     setNodes(generateInitialNodes(TOTAL_NODES));
     setCurrentPhase(SimulationPhase.IDLE);
     setCurrentIntervalSpeed(SLOW_INTERVAL_MS); // Reset speed state
     processingCompleteCountRef.current = 0;
     contemplatingCountRef.current = 0;
     contemplationCompleteCountRef.current = 0;
     adaptedCountRef.current = 0;
  };


  // --- JSX ---
  return (
    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1>Node Visualizer Simulation</h1>
      <p>Status: {isRunning ? `Running - Phase: ${SimulationPhase[currentPhase]} (Speed: ${currentIntervalSpeed}ms)` : `Stopped - Phase: ${SimulationPhase[currentPhase]}`}</p>
      {/* Optional Debug Counts
      <p style={{fontSize: '0.8em', color: '#666'}}>
          Counts: ProcComp({processingCompleteCountRef.current}) Cont({contemplatingCountRef.current}) ContComp({contemplationCompleteCountRef.current}) Adap({adaptedCountRef.current})
      </p>
       */}

      <NodeVisualizer nodes={nodes} />

      <div style={{ marginTop: '20px', display: 'flex', gap: '10px', justifyContent: 'center' }}>
        <button onClick={handleStartReset} disabled={isRunning && currentPhase !== SimulationPhase.FINISHED}>
          {/* Change button text based on state */}
          {currentPhase === SimulationPhase.IDLE || currentPhase === SimulationPhase.FINISHED ? 'Start Simulation' : 'Restart Simulation'}
        </button>
         <button onClick={handleStop} disabled={!isRunning}>
           Stop
         </button>
        <button onClick={handleResetView}>
          Reset View
        </button>
      </div>
    </div>
  );
}

export default NodeVisualizerPage;