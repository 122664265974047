import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SearchBar } from '../common/SearchBar';
import { ThemeToggle } from '../common/ThemeToggle';
import { VoiceCommandButton } from '../common/VoiceCommandButton';
import { CollapsibleSection } from '../common/CollapsibleSection';
import Logger from 'util/Logger';

export interface GraphFilters {
  nodeTypes: string[];
  edgeTypes: string[];
  dateRange?: [Date, Date];
  propertyFilters: Record<string, any>;
}

interface ControlPanelProps {
  onSearch: (query: string) => void;
  onAdvancedSearch?: (query: string) => void; // Optional callback for AI-powered search
  onFilter: (filters: GraphFilters) => void;
  onLayoutChange: (layout: string) => void;
  onToggleDarkMode: () => void;
  darkMode: boolean;
  onVoiceCommand: (command: string) => void;
  nodeTypes: string[];
  edgeTypes: string[];
  selectedNodeTypes: string[]; // Currently selected node types (controlled)
  selectedEdgeTypes: string[]; // Currently selected edge types (controlled)
  maxHops: number; // Max hops from user nodes
  onMaxHopsChange: (hops: number) => void; // Callback when max hops changes
  isProcessingVoice: boolean;
  onSaveGraph?: () => void; // Optional callback to save the graph data
}

const Panel = styled.div<{ $darkMode: boolean }>`
  background-color: ${props => props.$darkMode ? '#1E293B' : '#FFFFFF'};
  color: ${props => props.$darkMode ? '#E2E8F0' : '#1E293B'};
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 16px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  transition: all 0.3s ease;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h3<{ $darkMode: boolean }>`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: ${props => props.$darkMode ? '#F1F5F9' : '#0F172A'};
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 8px;
  }
`;

const FilterGroup = styled.div`
  margin-bottom: 16px;
`;

const FilterLabel = styled.div<{ $darkMode: boolean }>`
  font-size: 14px;
  margin-bottom: 6px;
  color: ${props => props.$darkMode ? '#CBD5E1' : '#334155'};
`;

const FilterOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 5px;
`;

const FilterOption = styled.div<{ 
  $darkMode: boolean; 
  $isActive: boolean;
  $isPreserved?: boolean;
  $isDisabled?: boolean;
  $isInactive?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  padding: 6px 10px;
  border-radius: 6px;
  cursor: ${props => props.$isDisabled ? 'default' : 'pointer'};
  background-color: ${props => 
    props.$isActive 
      ? props.$darkMode ? '#3B82F6' : '#2563EB' // Brighter blue when active
      : props.$isPreserved 
        ? props.$darkMode ? '#41526E' : '#E2EFFF' // Special color for preserved user node types
        : props.$darkMode ? '#1E293B' : '#F8FAFC'  // Lighter for inactive
  };
  color: ${props => 
    props.$isActive 
      ? '#FFFFFF'
      : props.$isPreserved
        ? props.$darkMode ? '#A3C2FF' : '#2563EB' // Special text color for preserved
        : props.$darkMode ? '#94A3B8' : '#64748B'  // More subtle text for inactive
  };
  opacity: ${props => {
    if (props.$isDisabled) return '0.6';
    if (props.$isInactive) return '0.75'; // Slightly translucent for inactive filters
    return '1';
  }};
  transition: all 0.15s ease;
  user-select: none;
  margin-bottom: 6px;
  font-weight: ${props => props.$isActive || props.$isPreserved ? '500' : '400'}; 
  border: ${props => 
    props.$isActive 
      ? props.$darkMode ? '2px solid #3B82F6' : '2px solid #2563EB'  // Bright border when active
      : 'none'  // No border when inactive
  };
  
  /* Apply different style on hover */
  &:hover {
    background-color: ${props => 
      !props.$isDisabled && (
        props.$isActive 
          ? props.$darkMode ? '#2563EB' : '#1D4ED8'  // Darker blue when active
          : props.$darkMode ? '#334155' : '#F1F5F9'  // Slightly darker when inactive
      )
    };
    transform: ${props => !props.$isDisabled && props.$isActive ? 'translateY(-1px)' : 'none'};
  }
`;

const LayoutSelector = styled.select<{ $darkMode: boolean }>`
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${props => props.$darkMode ? '#334155' : '#F1F5F9'};
  color: ${props => props.$darkMode ? '#F1F5F9' : '#334155'};
  border: 1px solid ${props => props.$darkMode ? '#475569' : '#CBD5E1'};
  margin-top: 8px;
  cursor: pointer;
  
  &:focus {
    outline: none;
    border-color: #3B82F6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 16px;
`;

const ActionButton = styled.button<{ $darkMode: boolean; $primary?: boolean }>`
  padding: 8px 12px;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  flex: 1;
  
  background-color: ${props => 
    props.$primary
      ? props.$darkMode ? '#3B82F6' : '#2563EB'
      : props.$darkMode ? '#334155' : '#F1F5F9'
  };
  
  color: ${props => 
    props.$primary
      ? '#FFFFFF'
      : props.$darkMode ? '#CBD5E1' : '#334155'
  };
  
  &:hover {
    background-color: ${props => 
      props.$primary
        ? props.$darkMode ? '#2563EB' : '#1D4ED8'
        : props.$darkMode ? '#475569' : '#E2E8F0'
    };
  }
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
  }
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const SliderContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 16px;
`;

const SliderLabel = styled.div<{ $darkMode: boolean }>`
  font-size: 14px;
  margin-bottom: 8px;
  color: ${props => props.$darkMode ? '#CBD5E1' : '#475569'};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RangeSlider = styled.input<{ $darkMode: boolean }>`
  width: 100%;
  -webkit-appearance: none;
  height: 6px;
  border-radius: 3px;
  background: ${props => props.$darkMode ? '#334155' : '#E2E8F0'};
  outline: none;
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${props => props.$darkMode ? '#3B82F6' : '#2563EB'};
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      transform: scale(1.1);
      background: ${props => props.$darkMode ? '#2563EB' : '#1D4ED8'};
    }
  }
  
  &::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${props => props.$darkMode ? '#3B82F6' : '#2563EB'};
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      transform: scale(1.1);
      background: ${props => props.$darkMode ? '#2563EB' : '#1D4ED8'};
    }
  }
`;

const HopValue = styled.span<{ $darkMode: boolean }>`
  display: inline-block;
  padding: 2px 8px;
  background: ${props => props.$darkMode ? '#475569' : '#E2E8F0'};
  border-radius: 12px;
  font-size: 13px;
  font-weight: 500;
  color: ${props => props.$darkMode ? '#F1F5F9' : '#334155'};
  min-width: 30px;
  text-align: center;
`;

export const ControlPanel: React.FC<ControlPanelProps> = ({
  onSearch,
  onAdvancedSearch,
  onFilter,
  onLayoutChange,
  onToggleDarkMode,
  darkMode,
  onVoiceCommand,
  nodeTypes,
  edgeTypes,
  selectedNodeTypes,
  selectedEdgeTypes,
  maxHops,
  onMaxHopsChange,
  isProcessingVoice,
  onSaveGraph,
}) => {
  // Use layout as local state
  const [selectedLayout, setSelectedLayout] = useState('force-directed');
  const [currentSearchQuery, setCurrentSearchQuery] = useState('');
  
  // Store all node and edge types ever seen to ensure inactive ones remain visible
  const [allNodeTypes, setAllNodeTypes] = useState<string[]>([]);
  const [allEdgeTypes, setAllEdgeTypes] = useState<string[]>([]);
  
  // Update allNodeTypes and allEdgeTypes whenever new types are discovered
  useEffect(() => {
    const newNodeTypes = nodeTypes.filter(type => !allNodeTypes.includes(type));
    if (newNodeTypes.length > 0) {
      setAllNodeTypes(prev => [...prev, ...newNodeTypes]);
    }
  }, [nodeTypes, allNodeTypes]);
  
  useEffect(() => {
    const newEdgeTypes = edgeTypes.filter(type => !allEdgeTypes.includes(type));
    if (newEdgeTypes.length > 0) {
      setAllEdgeTypes(prev => [...prev, ...newEdgeTypes]);
    }
  }, [edgeTypes, allEdgeTypes]);
  
  // Handle node type filter change
  const handleNodeTypeChange = (type: string, e?: React.ChangeEvent<HTMLInputElement>) => {
    Logger.log('Node type checkbox clicked:', type);
    Logger.log('Current selected node types:', selectedNodeTypes);
    Logger.log('Is currently checked:', selectedNodeTypes.includes(type));
    
    // Stop propagation if event is provided
    if (e) {
      e.stopPropagation();
    }
    
    // Create a new array to ensure React detects the state change
    const newTypes = selectedNodeTypes.includes(type)
      ? selectedNodeTypes.filter(t => t !== type) // Remove type if already included
      : [...selectedNodeTypes, type];             // Add type if not included
    
    Logger.log('New selected node types:', newTypes);
    
    // Apply filters with the new types directly to parent
    onFilter({
      nodeTypes: newTypes,
      edgeTypes: selectedEdgeTypes,
      propertyFilters: {}
    });
    
    // CRITICAL: Attempt to directly update the visualization using the window function
    // This ensures the filter is applied regardless of React's state management
    setTimeout(() => {
      if (typeof window.__restartSimulation === 'function' && window.__restartSimulation) {
        Logger.log("CRITICAL FIX: Directly triggering visualization update for node type change");
        window.__restartSimulation();
      }
    }, 50);
  };
  
  // Handle edge type filter change
  const handleEdgeTypeChange = (type: string, e?: React.ChangeEvent<HTMLInputElement>) => {
    Logger.log('Edge type checkbox clicked:', type);
    Logger.log('Current selected edge types:', selectedEdgeTypes);
    Logger.log('Is currently checked:', selectedEdgeTypes.includes(type));
    
    // Stop propagation if event is provided
    if (e) {
      e.stopPropagation();
    }
    
    // Create a new array to ensure React detects the state change
    const newTypes = selectedEdgeTypes.includes(type)
      ? selectedEdgeTypes.filter(t => t !== type)  // Remove type if already included
      : [...selectedEdgeTypes, type];              // Add type if not included
    
    Logger.log('New selected edge types:', newTypes);
    
    // No need to set state - we're using controlled props
    
    // Apply filters directly to parent
    onFilter({
      nodeTypes: selectedNodeTypes,
      edgeTypes: newTypes,
      propertyFilters: {}
    });
    
    // CRITICAL: Attempt to directly update the visualization using the window function
    // This ensures the filter is applied regardless of React's state management
    setTimeout(() => {
      if (typeof window.__restartSimulation === 'function' && window.__restartSimulation) {
        Logger.log("CRITICAL FIX: Directly triggering visualization update for edge type change");
        window.__restartSimulation();
      }
    }, 50);
  };
  
  // Handle layout change
  const handleLayoutChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const layout = e.target.value;
    setSelectedLayout(layout);
    onLayoutChange(layout);
  };
  
  // Handle max hops change
  const handleMaxHopsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hops = parseInt(e.target.value, 10);
    Logger.log(`Changing max hops to: ${hops}`);
    
    // CRITICAL: Always store the current filter state before changing maxHops
    // This ensures the FilterService has access to the correct filters
    
    // 1. Store currently filtered nodes
    if (typeof (window as any).__filteredNodes !== 'undefined') {
      Logger.log("CRITICAL FIX: Storing current filtered nodes before changing maxHops");
      (window as any).__currentFilteredNodes = [...((window as any).__filteredNodes || [])];
    }
    
    // 2. Always store selected node types, regardless of whether __filteredNodes exists
    (window as any).__selectedNodeTypes = [...selectedNodeTypes];
    Logger.log("CRITICAL FIX: Preserving node types filter:", selectedNodeTypes.join(', '));
    
    // 3. Always store selected edge types, regardless of whether __filteredNodes exists
    (window as any).__selectedEdgeTypes = [...selectedEdgeTypes];
    Logger.log("CRITICAL FIX: Preserving edge types filter:", selectedEdgeTypes.join(', '));
    
    // 4. Store number of selected types to verify filters are working properly
    (window as any).__selectedNodeTypesCount = selectedNodeTypes.length;
    (window as any).__selectedEdgeTypesCount = selectedEdgeTypes.length;
    
    // Call the parent's callback to update maxHops only after we've stored all necessary state
    onMaxHopsChange(hops);
    
    // Try to trigger an immediate update
    setTimeout(() => {
      if (typeof window.__restartSimulation === 'function' && window.__restartSimulation) {
        Logger.log("CRITICAL FIX: Directly triggering visualization update for max hops change");
        
        // Verify filter state is preserved before restarting simulation
        Logger.log("Verification before restart - selected node types:", (window as any).__selectedNodeTypes?.length || 0);
        Logger.log("Verification before restart - selected edge types:", (window as any).__selectedEdgeTypes?.length || 0);
        
        window.__restartSimulation();
      }
    }, 50);
  };
  
  // Handle search to update local state
  const handleSearch = (query: string) => {
    Logger.log('ControlPanel: handleSearch called with:', query);
    setCurrentSearchQuery(query);
    onSearch(query);
  };

  // Reset filters
  const resetFilters = () => {
    Logger.log('Resetting filters to all types');
    Logger.log('All node types:', nodeTypes);
    Logger.log('All edge types:', edgeTypes);
    
    // Reset search query in local state
    setCurrentSearchQuery('');
    
    // Clear the global search query flag
    (window as any).__activeSearchQuery = '';
    
    // First ensure search is cleared if any
    onSearch('');
    
    // Apply reset directly to parent with a slight delay
    // to ensure other state updates happen first
    setTimeout(() => {
      // Reset filters to show all types
      onFilter({
        nodeTypes: nodeTypes,
        edgeTypes: edgeTypes,
        propertyFilters: {}
      });
      
      Logger.log('ControlPanel: Reset completed - all filters should be cleared');
    }, 50);
  };
  
  return (
    <Panel $darkMode={darkMode}>
      <Section>
        <SectionTitle $darkMode={darkMode}>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" 
              stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Search & Voice
        </SectionTitle>
        <SearchBar 
          onSearch={handleSearch}
          onAdvancedSearch={onAdvancedSearch}
          darkMode={darkMode} 
          searchQuery={currentSearchQuery}
        />
        <ActionsContainer>
          <VoiceCommandButton 
            onCommand={onVoiceCommand} 
            darkMode={darkMode} 
            isProcessing={isProcessingVoice} 
          />
          <ThemeToggle 
            darkMode={darkMode} 
            onToggle={onToggleDarkMode} 
          />
        </ActionsContainer>
      </Section>
      
      <Section>
        <SectionTitle $darkMode={darkMode}>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 4.5H17.25M3 12H17.25M3 19.5H17.25M21 4.5V4.51M21 12V12.01M21 19.5V19.51" 
              stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Filters
        </SectionTitle>
        
        <FilterGroup>
          <CollapsibleSection
            title="Entity Types"
            darkMode={darkMode}
            defaultOpen={false}
          >
            {(window as any).__preservedUserNodeTypes?.length > 0 && (
              <div style={{ 
                fontSize: '12px', 
                color: darkMode ? '#A3C2FF' : '#3B82F6', 
                marginBottom: '8px',
                padding: '4px 8px',
                backgroundColor: darkMode ? '#31425A' : '#EFF6FF',
                borderRadius: '4px',
                border: darkMode ? '1px solid #4B6BBA' : '1px solid #A3C2FF'
              }}>
                Some node types are preserved to keep user nodes visible
              </div>
            )}
            <FilterOptionsContainer>
              {allNodeTypes.map(type => {
                const isActive = selectedNodeTypes.includes(type);
                const isInactive = !isActive;
                const isAvailable = nodeTypes.includes(type);
                const isPreserved = (window as any).__preservedUserNodeTypes?.includes(type);
                
                return (
                  <FilterOption 
                    key={type} 
                    $darkMode={darkMode}
                    $isActive={isActive}
                    $isPreserved={isPreserved}
                    $isInactive={isInactive}
                    $isDisabled={false} // Always enable for re-selection
                    onClick={() => handleNodeTypeChange(type)}
                  >
                    {type}
                  </FilterOption>
                );
              })}
            </FilterOptionsContainer>
          </CollapsibleSection>
        </FilterGroup>
        
        <FilterGroup>
          <CollapsibleSection
            title="Relationship Types"
            darkMode={darkMode}
            defaultOpen={false}
          >
            <FilterOptionsContainer>
              {allEdgeTypes.map(type => {
                const isActive = selectedEdgeTypes.includes(type);
                const isInactive = !isActive;
                const isAvailable = edgeTypes.includes(type);
                
                return (
                  <FilterOption 
                    key={type} 
                    $darkMode={darkMode}
                    $isActive={isActive}
                    $isInactive={isInactive}
                    $isDisabled={false} // Always enable for re-selection
                    onClick={() => handleEdgeTypeChange(type)}
                  >
                    {type}
                  </FilterOption>
                );
              })}
            </FilterOptionsContainer>
          </CollapsibleSection>
        </FilterGroup>
        
        {/* Connection Distance section hidden for now
        <FilterGroup>
          <CollapsibleSection
            title="Connection Distance"
            darkMode={darkMode}
            defaultOpen={false}
          >
            <div style={{
              fontSize: '12px',
              color: darkMode ? '#A3C2FF' : '#3B82F6',
              marginBottom: '8px',
            }}>
              Control how many hops away from you to show
            </div>
            
            <SliderContainer>
              <SliderLabel $darkMode={darkMode}>
                Max Hops from User
                <HopValue $darkMode={darkMode}>{maxHops}</HopValue>
              </SliderLabel>
              <RangeSlider
                type="range"
                min="1"
                max="5"
                step="1"
                value={maxHops}
                onChange={handleMaxHopsChange}
                $darkMode={darkMode}
              />
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '12px',
                color: darkMode ? '#94A3B8' : '#64748B',
                marginTop: '4px'
              }}>
                <span>Direct<br />connections</span>
                <span>Friends of<br />friends</span>
                <span>Extended<br />network</span>
              </div>
            </SliderContainer>
          </CollapsibleSection>
        </FilterGroup>
        */}
        
        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
          <ActionButton 
            $darkMode={darkMode} 
            onClick={resetFilters}
          >
            Reset Filters
          </ActionButton>
          
          {onSaveGraph && (
            <ActionButton 
              $darkMode={darkMode} 
              onClick={onSaveGraph}
              $primary
            >
              Save Graph
            </ActionButton>
          )}
        </div>
      </Section>
      
      {/* Layout section hidden for now
      <Section>
        <SectionTitle $darkMode={darkMode}>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V7C19 5.89543 18.1046 5 17 5H15M9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5" 
              stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Layout
        </SectionTitle>
        
        <CollapsibleSection
          title="Graph Layout"
          darkMode={darkMode}
          defaultOpen={false}
        >
          <LayoutSelector 
            value={selectedLayout}
            onChange={handleLayoutChange}
            $darkMode={darkMode}
          >
            <option value="force-directed">Force-Directed</option>
            <option value="radial">Radial</option>
            <option value="hierarchical">Hierarchical</option>
            <option value="circular">Circular</option>
            <option value="grid">Grid</option>
          </LayoutSelector>
        </CollapsibleSection>
      </Section>
      */}
    </Panel>
  );
};
