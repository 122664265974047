import React from 'react';
import { inject, observer } from 'mobx-react';

import { Text } from '../Typography';
import withPostHog from "hooks/withPosthog";
import { MessageIcon, GuideIcon, EmailIcon, ExternalIcon } from "components/common/Icon";
import FeedbackModal from 'pages/support/modals/FeedbackModal';
import CustomerSupportModal from 'pages/support/modals/ContactSupportModal';
import ADAPTER from 'adapter';
import { EVENTS } from 'pages/support';
import Tour from '../Tour';
import { APPLICATION_BUILD_NUMBER } from '../../../constants';

import './helpmenu.scss';

interface HelpMenuProps {
    UserStore?: any;
    AuthStore?: any;
    userEvents?: any;
}

class HelpMenu extends React.Component<HelpMenuProps, any> {
    state = {
        isOpen: false,
        feedbackOpen: false,
        supportOpen: false,
        tourOpen: false,
    };

    componentDidMount(): void {
        ADAPTER.subscribeToEvent(EVENTS.openFeedback, this.toggleFeedback);
        ADAPTER.subscribeToEvent(EVENTS.openSupport, this.toggleSupport);
        ADAPTER.subscribeToEvent(EVENTS.showTour, this.showTour);
    }

    toggleMessageBox = () => {
        const { isOpen } = this.state;
        this.setState({ 
            isOpen: !isOpen,
            feedbackOpen: false,
            supportOpen: false,
            tourOpen: false,
        });
    }

    toggleFeedback = () => {
        const { feedbackOpen } = this.state;
        this.setState({ 
            feedbackOpen: !feedbackOpen,

            isOpen: false,
            supportOpen: false,
            tourOpen: false,
        });
    }

    toggleSupport = () => {
        const { supportOpen } = this.state;
        this.setState({ 
            supportOpen: !supportOpen,

            isOpen: false,
            feedbackOpen: false,
            tourOpen: false,
        });
    }

    showTour = () => {
        const { tourOpen } = this.state;
        this.setState({ 
            tourOpen: !tourOpen,

            isOpen: false,
            feedbackOpen: false,
            supportOpen: false,
        });
    }

    renderMessageBox = () => {
        const { UserStore } = this.props;

        return (
            <div className="adapter-help-menu items-stretch">

                {!UserStore.isOnboarding && (
                    <Text fontSize='s'>
                      <Tour>
                        <a href="#" onClick={this.showTour}>
                            <GuideIcon style={{ float: 'left', padding: 0 }} />&nbsp;Get Started
                        </a>
                      </Tour>
                    </Text>
                )}

                <Text fontSize='s'>
                    <a href="#" onClick={this.toggleFeedback}>
                        <MessageIcon style={{ float: 'left', padding: 0 }} />&nbsp;Provide Feedback
                    </a>
                </Text>

                <Text fontSize='s'>
                    <a href="#" onClick={this.toggleSupport}>
                        <EmailIcon style={{ float: 'left', padding: 0 }} />&nbsp;Contact Support
                    </a>
                </Text>

                <Text fontSize='s'>
                    <a href="https://www.adapter.com" target="_blank">
                        <ExternalIcon style={{ float: 'left', padding: 0 }} />&nbsp;About Adapter
                    </a>
                </Text>
                <hr />
                <Text fontSize='xs'>
                    UI Version: {APPLICATION_BUILD_NUMBER}
                </Text>
            </div>
        );
    }

    render() {
        const { AuthStore } = this.props;
        const { isOpen, feedbackOpen, supportOpen, tourOpen } = this.state;

        if (!AuthStore.isAuthenticated) {
            return (<></>);
        }

        return (
            <>
                <div className="adapter-help-menu-button" onClick={this.toggleMessageBox}>
                    <Text title="Support">?</Text>
                </div>

                {isOpen && this.renderMessageBox()}

                {feedbackOpen && <FeedbackModal onClose={this.toggleFeedback} />}

                {supportOpen && <CustomerSupportModal onClose={this.toggleSupport} />}

                {tourOpen && <Tour />}
            </>
            
        );
    }
}

export default withPostHog(inject('UserStore', 'AuthStore')(observer(HelpMenu)));