import { makeObservable, observable, action, runInAction } from "mobx";

import API from "util/API";
import {
  saveToLocalStorage,
  clearLocalStorage,
  getFromLocalStorage,
  StorageKeys
} from "../util/LocalStorage";

import { MessageHandlerType } from "../util/NativePromiseBuilder";

import { jwtNeedsRefresh } from '../util/JWT';

const timer = 1000 * 10; // every 10 seconds
let intervalId: any;

class AuthStore {
  public isAuthenticated: boolean = false;
  public isLoading: boolean = false;

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      isLoading: observable,

      signIn: action,
      signInSuccess: action,
      signInFailure: action,

      logout: action,
      logoutSuccess: action,
      logoutFailure: action,

      signUp: action,
      signUpFailure: action,
      signUpSuccess: action,

      activate: action,
      activationFailure: action,
      activationSuccess: action,

      refreshJwt: action,
      refreshJwtFailure: action,
      refreshJwtSuccess: action,

      resetPassword: action,
      resetPasswordFailure: action,
      resetPasswordSuccess: action,

      oauth: action,
      oauthFailure: action,
      oauthSuccess: action,

      disconnectAccount: action,
      disconnectAccountFailure: action,
      disconnectAccountSuccess: action,
    });

    this.initialize();
  }

  async initialize() {
    try {
      const accessToken = await getFromLocalStorage(StorageKeys.JWT_ACCESS_TOKEN);
      
      // Use runInAction for async state updates
      runInAction(() => {
        this.isAuthenticated = !!accessToken;
        this.isLoading = false;
      });
      
      if (accessToken) {
        this.setJwtRefreshInterval();
      }
    } catch (error) {
      runInAction(() => {
        this.isAuthenticated = false;
        this.isLoading = false;
      });
      console.error('Failed to initialize auth store:', error);
    }
  }

  setJwtRefreshInterval = () => {
    intervalId = setInterval(this.jwtRefreshCheck, timer);
  }

  clearJwtRefreshInterval = () => {
    // @ts-ignore
    clearInterval(intervalId);
  }

  jwtRefreshCheck = async () => {
    const accessToken = await getFromLocalStorage(StorageKeys.JWT_ACCESS_TOKEN);
    const needsRefresh = jwtNeedsRefresh(accessToken);

    if (needsRefresh) {
      this.clearJwtRefreshInterval();
      try {
        this.refreshJwt();
      } catch (error) {
        console.error("Can't refresh the JWT");
      }
    }
  }

  refreshJwtSuccess = async ({ access, refresh }: any) => {
    this.isLoading = false;

    try {
      await saveToLocalStorage(StorageKeys.JWT_ACCESS_TOKEN, access);
      await saveToLocalStorage(StorageKeys.JWT_REFRESH_TOKEN, refresh);
    } catch (error) {
      console.error("Failed to save access tokens");
    }

    this.setJwtRefreshInterval();
  }

  refreshJwtFailure = () => {
    this.isLoading = false;
  }

  refreshJwt = async () => {
    try {
      this.isLoading = true;
      const refresh_token = await getFromLocalStorage(StorageKeys.JWT_REFRESH_TOKEN);
      
      // don't pass down an access token as it won't be used.
      const response = await API.postnoauth("/api/jwt/refresh", { refresh_token });
      const data = await response.json();
      
      this.refreshJwtSuccess(data);
      return data;
    } catch (error) {
      this.refreshJwtFailure();
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  logoutSuccess = () => {
    this.isLoading = false;
    this.isAuthenticated = false;
    clearLocalStorage().catch();

    // let the native app know that the user signed out
    try {
      if (window.webkit?.messageHandlers?.signoutRequest) {
        window.webkit.messageHandlers.signoutRequest.postMessage({
          "operation": "signout-native"
        });
      }
    } catch (error) {
      console.error("Unexpected error requesting native sign-off:", error)
    }
  };

  logoutFailure = () => {
    this.isLoading = false;
    clearLocalStorage().catch();  // no reason not to attempt to clear
  };

  logout = () => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.post("/api/signout", {})
        .then((data) => {
          this.logoutSuccess();
          return resolve(data);
        })
        .catch((error) => {
          this.logoutFailure();
          return reject(error);
        });
    });
  };

  signInSuccess = async ({ access, refresh }: any) => {
    this.isLoading = false;
    this.isAuthenticated = true;
    try {
      await saveToLocalStorage(StorageKeys.JWT_ACCESS_TOKEN, access);
      await saveToLocalStorage(StorageKeys.JWT_REFRESH_TOKEN, refresh);
    } catch (error) {
      console.error("Failed to save access tokens", error);
    }
    this.setJwtRefreshInterval();
  };

  signInFailure = () => {
    this.isLoading = false;
    clearLocalStorage().catch();
  };

  signIn = (data: { user_access_token: string }) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.post("/api/jwt/login", data)
        .then((response) => response.json())
        .then((data) => {
          this.signInSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.signInFailure();
          return reject(error);
        });
    });
  };

  signUpSuccess = () => {
    this.isLoading = false;
  };

  signUpFailure = () => {
    this.isLoading = false;
  };

  signUp = (credentials: {
    email: string;
    phone: string;
    first_name: string;
    last_name: string;
    password: string;
  }) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.post("/api/user/register", credentials, true)
        .then((data) => {
          this.signUpSuccess();
          return resolve(data);
        })
        .catch((error) => {
          this.signUpFailure();
          return reject(error);
        });
    });
  };

  activationSuccess = () => {
    this.isLoading = false;
  };

  activationFailure = () => {
    this.isLoading = false;
  };

  resendActivation = (credentials: { email: string; }) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.post("/api/user/resend-activation", credentials)
        .then((response) => {
          this.activationSuccess();
          return resolve(response);
        })
        .catch((error) => {
          this.activationFailure();
          return reject(error);
        });
    });
  }

  resendVerification = (credentials: { token: string; }) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.post("/api/user/resend-verification", credentials)
        .then((response) => {
          this.activationSuccess();
          return resolve(response);
        })
        .catch((error) => {
          this.activationFailure();
          return reject(error);
        });
    });
  }

  activate = (credentials: { token: string; }) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.post("/api/user/activation", credentials)
        .then((response) => {
          this.activationSuccess();
          return resolve(response);
        })
        .catch((error) => {
          this.activationFailure();
          return reject(error);
        });
    });
  };

  oauthSuccess = () => {
    this.isLoading = false;
  };

  oauthFailure = () => {
    this.isLoading = false;
  };

  oauth = (oauthData: { code: string; error: any; }) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.post("/api/google-oauth2-callback", oauthData)
        .then(response => response.json())
        .then(({ reconnect }) => {
          this.oauthSuccess();
          return resolve(reconnect);
        })
        .catch((error) => {
          this.oauthFailure();
          return reject(error);
        });
    });
  };

  resetPasswordSuccess = () => {
    this.isLoading = false;
  };

  resetPasswordFailure = () => {
    this.isLoading = false;
  };

  resetPassword = () => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.post("/api/user/reset_password", {})
        .then((response) => {
          this.resetPasswordSuccess();
          return resolve(response);
        })
        .catch((error) => {
          this.resetPasswordFailure();
          return reject(error);
        });
    });
  };

  disconnectAccountSuccess = () => {
    this.isLoading = false;
  };

  disconnectAccountFailure = () => {
    this.isLoading = false;
  };

  disconnectAccount = (accountEmail: string, accountVendor: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.post("/api/user/disconnectAccount", { accountEmail, accountVendor })
        .then((response) => {
          this.disconnectAccountSuccess();
          return resolve(response);
        })
        .catch((error) => {
          this.disconnectAccountFailure();
          return reject(error);
        });
    });
  };
}

const authStore = new AuthStore();

export default authStore;
