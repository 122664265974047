// components/common/Loading/index.tsx
import React from 'react';
import RiveSpinner from './RiveSpinner';
import './Loading.scss';

const Loading = ({ modal = true, style = {} }) => (
    modal
        ? (
            <div className="adapter-modal" style={Object.assign({}, style, { backdropFilter: 'none' })}>
                <div className="adapter-loading">
                    <RiveSpinner />
                </div>
            </div>
        )
        : (
            <div className="adapter-loading" style={style}>
                <RiveSpinner />
            </div>
        )
);

export default Loading;