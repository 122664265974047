import React from 'react';
import { observer } from 'mobx-react';

import BasePage from 'components/common/BasePage';
import userMemoryStore from 'stores/MemoryStore';
import { H3Text, Text, UL, LI } from 'components/common/Typography';
import Card from 'components/common/Card';

type MemoryRecord = {
  content: string;
  created_at: string;
  categories?: string[];
  id: string;
};

class UserMemory extends React.Component {
  componentDidMount() {
    userMemoryStore.fetchMemories();
  }
  handleCategoryChange = (category: string | null) => {
    userMemoryStore.setCategoryFilter(category);
    userMemoryStore.fetchMemories();
  };
  renderCategoryFilters = () => {
    const categories = Array.from(new Set(userMemoryStore.memories.flatMap(memory => memory.categories || [])));
    return (
      <div className="category-filters">
        <button onClick={() => this.handleCategoryChange(null)}>All</button>
        {categories.map(category => (
          <button
            key={category}
            onClick={() => this.handleCategoryChange(category)}
            className={userMemoryStore.categoryFilter === category ? 'active' : ''}
          >
            {category}
          </button>
        ))}
      </div>
    );
  };
  renderMemories = () => {
    const { loading, filteredMemories } = userMemoryStore;
    if (loading) {
      return <div>Loading memories...</div>;
    }
    if (filteredMemories.length === 0) {
      return <div>No memories found</div>;
    }
    return (
      <UL>
        {filteredMemories.map((memory: MemoryRecord) => (
          <LI key={memory.id}>

            <Text>{memory.content}</Text>

            <Text fontSize='s'>{new Date(memory.created_at).toLocaleString()}</Text>

            <Text fontSize='s'>{memory.categories?.join(', ')}</Text>
          </LI>
        ))}
      </UL>
    );
  };
  render() {
    return (
      <BasePage innerCls="adapter-bg-base-100">
        <div className='flex-1'/>

        <Card style={{ margin: '0 auto' }}>
          <H3Text>User Memories</H3Text>

          {this.renderCategoryFilters()}
          {this.renderMemories()}
        </Card>

        <div className='flex-1'/>
      </BasePage>
    );
  }
}
export default observer(UserMemory);