import { 
  buildCallback, 
  MessageHandlerType, 
  NativePromiseError 
} from "./NativePromiseBuilder";

// Error types
class StorageError extends NativePromiseError { }

class ValidationError extends StorageError {
  constructor(message: string) {
    super(message);
    this.name = 'ValidationError';
  }
}

class RateLimitError extends StorageError {
  constructor(message: string) {
    super(message);
    this.name = 'RateLimitError';
  }
}

class StorageBridge {
  private readonly maxKeyLength: number = 256;
  private requestCount: number = 0;
  private lastRequestTime: number = Date.now();

  private isValidKey(key: string): boolean {
    if (!key || typeof key !== 'string') return false;
    if (key.includes('../') || key.includes('./')) return false;
    if (key.length > this.maxKeyLength) return false;
    return /^[\w\-\.]+$/.test(key);
  }

  private sanitizeKey(key: string): string {
    return key.replace(/[^a-zA-Z0-9\-_\.]/g, '');
  }

  private checkRateLimit(): void {
    const now = Date.now();
    const timeWindow = 1000; // 1 second
    const maxRequests = 50; // Maximum requests per second
    
    if (now - this.lastRequestTime > timeWindow) {
      this.requestCount = 0;
      this.lastRequestTime = now;
    }
    
    this.requestCount++;
    
    if (this.requestCount > maxRequests) {
      throw new RateLimitError('Rate limit exceeded');
    }
  }

  private handleLocalStorage(operation: string, key?: string, value?: unknown): unknown {
    if (operation !== 'clear' && (!key || !this.isValidKey(key))) {
      throw new ValidationError('Invalid key');
    }

    switch (operation) {
      case 'setItem':
        localStorage.setItem(key!, JSON.stringify(value));
        return null;
      case 'getItem':
        const storedValue = localStorage.getItem(key!);
        if (storedValue) {
          try {
            return JSON.parse(storedValue);
          } catch {
            return storedValue;
          }
        }
        return null;
      case 'removeItem':
        localStorage.removeItem(key!);
        return null;
      case 'clear':
        localStorage.clear();
        return null;
      default:
        throw new StorageError(`Unknown operation: ${operation}`);
    }
  }

  private async callNative(operation: string, key?: string, value?: unknown): Promise<unknown> {
    if (!window.webkit?.messageHandlers?.storage) {
      throw new StorageError('Native storage handler not available');
    }

    return new Promise((resolve, reject) => {

      const callbackName = buildCallback(
        MessageHandlerType.Storage,
        StorageError,
        resolve,
        reject
      );

      window.webkit.messageHandlers.storage.postMessage({
        operation,
        key,
        value,
        callback: callbackName
      });
    });
  }

  public async setItem<T>(key: string, value: T): Promise<void> {
    try {
      this.checkRateLimit();
      
      if (!this.isValidKey(key)) {
        throw new ValidationError('Invalid storage key');
      }

      const sanitizedKey = this.sanitizeKey(key);
      
      if (window.webkit?.messageHandlers?.storage) {
        await this.callNative('setItem', sanitizedKey, value);
      } else {
        this.handleLocalStorage('setItem', sanitizedKey, value);
      }
    } catch (error) {
      if (error instanceof StorageError) {
        throw error;
      }
      throw new StorageError(error instanceof Error ? error.message : 'Set item failed');
    }
  }

  public async getItem<T>(key: string): Promise<T | null> {
    try {
      this.checkRateLimit();
      
      if (!this.isValidKey(key)) {
        throw new ValidationError('Invalid storage key');
      }

      const sanitizedKey = this.sanitizeKey(key);
      
      if (window.webkit?.messageHandlers?.storage) {
        const result = await this.callNative('getItem', sanitizedKey);
        return result as T;
      } else {
        return this.handleLocalStorage('getItem', sanitizedKey) as T;
      }
    } catch (error) {
      if (error instanceof StorageError) {
        throw error;
      }
      throw new StorageError(error instanceof Error ? error.message : 'Get item failed');
    }
  }

  public async removeItem(key: string): Promise<void> {
    try {
      this.checkRateLimit();
      
      if (!this.isValidKey(key)) {
        throw new ValidationError('Invalid storage key');
      }

      const sanitizedKey = this.sanitizeKey(key);
      
      if (window.webkit?.messageHandlers?.storage) {
        await this.callNative('removeItem', sanitizedKey);
      } else {
        this.handleLocalStorage('removeItem', sanitizedKey);
      }
    } catch (error) {
      if (error instanceof StorageError) {
        throw error;
      }
      throw new StorageError(error instanceof Error ? error.message : 'Remove item failed');
    }
  }

  public async clear(): Promise<void> {
    try {
      this.checkRateLimit();
      
      if (window.webkit?.messageHandlers?.storage) {
        await this.callNative('clear');
      } else {
        this.handleLocalStorage('clear');
      }
    } catch (error) {
      if (error instanceof StorageError) {
        throw error;
      }
      throw new StorageError(error instanceof Error ? error.message : 'Clear storage failed');
    }
  }
}

// Export a singleton instance
const storageBridge = new StorageBridge();
export default storageBridge;