import React from "react";
import { observer, inject } from "mobx-react";
import { toast } from "react-toastify";

import Loading from "../Loading";
import withPostHog from "hooks/withPosthog";
import { initializePendo } from "util/pendoUtils";
import { initTelemetry } from "util/Telemetry";

type AuthenticatedPageProps = {
  children: any;
  AuthStore?: any;
  UserStore?: any;
  WebSocketStore?: any;
  userEvents?: any;
}

type AuthenticatedPageState = {
  isReady: boolean;
}

class AuthenticatedPage extends React.Component<AuthenticatedPageProps, AuthenticatedPageState> {
  state = {
    isReady: false
  }

  componentDidMount = () => {
    const { AuthStore, UserStore, WebSocketStore, userEvents } = this.props;

    if (!AuthStore.isAuthenticated) {
      window.location.href = "/login";
      return;
    }

    WebSocketStore.connect();

    // if the user should be in the onboarding flow but got lost... redirect them
    const onboardingUrlRE = /onboarding/i;
    const isOnboardingPath = onboardingUrlRE.test(window.location.pathname);

    const oAuthUrlRE = /oauth/i;
    const isOAuthPath = oAuthUrlRE.test(window.location.pathname);

    // React Router will attempt "soft" page changes, but this function still runs
    // in that case, short-circuit because we don't need to re-load any of this
    if (UserStore.user) {
      this.setState({ isReady: true });
      return;
    }

    UserStore.loadUser()
      .then(
        (user) => UserStore.loadUserAccounts()
          .then(({ connectedAccounts }) => {
            if (userEvents) {
              const email = UserStore.user?.email;
              const id = UserStore.user?.id
              userEvents?.posthog.identify(email, { email: email });
              initializePendo(id);
            }
        
            if (UserStore.isOnboarding && !isOnboardingPath && !isOAuthPath) {
              window.location.href = '/onboarding';
              return;
            }

            if (isOAuthPath && UserStore.isOnboarding) {
              // go finish onboarding
              window.location.href = `/onboarding/ingest${window.location.search}`;
              return;
            }

            // if the user has not completed onboarding, and is NOT inside onboarding flow, force them to onboard
            if (UserStore.isOnboarding && !isOnboardingPath) {
              window.location.href = '/onboarding';
              return;
            }

            const ingestUrl = '/onboarding/ingest';
            if (UserStore.isOnboarding && connectedAccounts.length > 0 && window.location.pathname !== ingestUrl) {
              window.location.href = ingestUrl;
              return;
            }

            this.setState({ isReady: true }, () => {

              if (!UserStore.isOnboarding && connectedAccounts?.length === 0) {
                toast.error(
                  (
                    <div>You do not have any accounts connected. <a href='/settings/accounts'>Connect account now</a>.</div>
                  ),
                  { theme: 'dark', position: 'bottom-right', icon: false }
                );
              }
              else if (!isOAuthPath && connectedAccounts?.length > 0 && connectedAccounts?.some((account) => account.connected === false)) {
                toast.error(
                  (
                    <div>One of your accounts is no longer connected. <a href='/settings/accounts'>Reconnect now</a>.</div>
                  ),
                  { theme: 'dark', position: 'bottom-right', icon: false }
                );
              }

              if (!UserStore.isOnboarding) {
                initTelemetry();
  
                // send a webkit/js native message to let the iOS app know that we logged in
                window.webkit?.messageHandlers?.signedInSuccess?.postMessage({
                  "operation": "signedin-native"
                });
              }
            });
          })
      );
  }

  render() {
    const { AuthStore, UserStore, children } = this.props;
    const { isReady } = this.state;

    if (!isReady || !AuthStore.isAuthenticated || UserStore.user === null) {
      return (
        <div className="adapter-fullscreen-content adapter-bg-base-100">
          <Loading />
        </div>
      );
    }
   
    return <>{children}</>;
  }
}

export default withPostHog(inject('AuthStore', 'UserStore', 'WebSocketStore')(observer(AuthenticatedPage)));
