import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Logger from 'util/Logger';

interface SearchBarProps {
  onSearch: (query: string) => void;
  onAdvancedSearch?: (query: string) => void;
  darkMode: boolean;
  searchQuery?: string; // Allow parent to control search query
}

const SearchContainer = styled.div<{ $darkMode: boolean }>`
  position: relative;
  width: 100%;
  margin-bottom: 8px;
`;

const SearchInput = styled.input<{ $darkMode: boolean }>`
  width: 100%;
  padding: 10px 16px 10px 40px;
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid ${props => props.$darkMode ? '#475569' : '#CBD5E1'};
  background-color: ${props => props.$darkMode ? '#334155' : '#F8FAFC'};
  color: ${props => props.$darkMode ? '#F1F5F9' : '#0F172A'};
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    border-color: ${props => props.$darkMode ? '#60A5FA' : '#3B82F6'};
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
  }
  
  &::placeholder {
    color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  }
`;

const SearchIcon = styled.div<{ $darkMode: boolean }>`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionButtonsContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ClearButton = styled.button<{ $darkMode: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  
  &:hover {
    color: ${props => props.$darkMode ? '#F1F5F9' : '#0F172A'};
  }
  
  &:focus {
    outline: none;
  }
`;

const BrainButton = styled.button<{ $darkMode: boolean; $active: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.$active 
    ? (props.$darkMode ? '#60A5FA' : '#3B82F6') 
    : (props.$darkMode ? '#94A3B8' : '#64748B')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: all 0.2s ease;
  
  &:hover {
    color: ${props => props.$active 
      ? (props.$darkMode ? '#93C5FD' : '#60A5FA') 
      : (props.$darkMode ? '#F1F5F9' : '#0F172A')};
    transform: scale(1.1);
  }
  
  &:focus {
    outline: none;
  }
`;

export const SearchBar: React.FC<SearchBarProps> = ({ onSearch, onAdvancedSearch, darkMode, searchQuery }) => {
  const [query, setQuery] = useState('');
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const debounceTimerRef = useRef<number | null>(null);
  
  // Handle external updates of search query
  React.useEffect(() => {
    if (searchQuery !== undefined && searchQuery !== query) {
      Logger.log("SearchBar: External searchQuery update:", searchQuery);
      setQuery(searchQuery);
    }
  }, [searchQuery]);
  
  // Cleanup any pending debounce timer on unmount
  React.useEffect(() => {
    return () => {
      if (debounceTimerRef.current !== null) {
        window.clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    
    // Clear any existing timer
    if (debounceTimerRef.current !== null) {
      window.clearTimeout(debounceTimerRef.current);
    }
    
    // Set a new timer to trigger search after 1 second of inactivity
    debounceTimerRef.current = window.setTimeout(() => {
      Logger.log("SearchBar: Debounced search for:", value);
      
      // Use advanced search if mode is active and handler is available
      if (isAdvancedMode && onAdvancedSearch) {
        onAdvancedSearch(value);
      } else {
        onSearch(value);
      }
      
      debounceTimerRef.current = null;
    }, 1000);
  };
  
  const handleClear = () => {
    setQuery('');
    
    // Clear any existing timer
    if (debounceTimerRef.current !== null) {
      window.clearTimeout(debounceTimerRef.current);
      debounceTimerRef.current = null;
    }
    
    // Immediately trigger empty search
    onSearch('');
    
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  
  const toggleAdvancedMode = () => {
    const newMode = !isAdvancedMode;
    setIsAdvancedMode(newMode);
    
    // Apply current search with new mode if there's a query
    if (query && onAdvancedSearch && newMode) {
      onAdvancedSearch(query);
    } else if (query && !newMode) {
      onSearch(query);
    }
  };
  
  return (
    <SearchContainer $darkMode={darkMode}>
      <SearchIcon $darkMode={darkMode}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
      </SearchIcon>
      <SearchInput
        ref={inputRef}
        type="text"
        placeholder={isAdvancedMode ? "AI-powered search..." : "Search entities..."}
        value={query}
        onChange={handleChange}
        $darkMode={darkMode}
      />
      <ActionButtonsContainer>
        {onAdvancedSearch && (
          <BrainButton 
            onClick={toggleAdvancedMode}
            aria-label={isAdvancedMode ? "Disable AI search" : "Enable AI search"}
            title={isAdvancedMode ? "Disable AI search" : "Enable AI search"}
            $darkMode={darkMode}
            $active={isAdvancedMode}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z"></path>
            </svg>
          </BrainButton>
        )}
        
        {query && (
          <ClearButton
            onClick={handleClear}
            aria-label="Clear search"
            title="Clear search"
            $darkMode={darkMode}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </ClearButton>
        )}
      </ActionButtonsContainer>
    </SearchContainer>
  );
};
