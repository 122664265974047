import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { AtlasCategoryData, AtlasCache } from '../types';
import { CACHE_DURATION, MAX_RETRY_ATTEMPTS, RETRY_DELAY } from '../constants';

interface UseAtlasDataProps {
    AtlasStore?: {
        fetchAtlasPreference: (category: string) => Promise<AtlasCategoryData>;
        updateAtlasPreference: (category: string, preferenceId: string, value: any) => Promise<void>;
    };
}

export const useAtlasData = ({ AtlasStore }: UseAtlasDataProps) => {
    const [atlasCache, setAtlasCache] = useState<AtlasCache>({});
    const [lastUpdated, setLastUpdated] = useState<Record<string, number>>({});
    const [isLoadingPreferences, setIsLoadingPreferences] = useState(false);
    const [retryCount, setRetryCount] = useState(0);
    const [error, setError] = useState<string | null>(null);

    const shouldFetchCategory = useCallback((category: string): boolean => {
        const cachedData = atlasCache[category];
        if (!cachedData) return true;
        const now = Date.now();
        return now >= cachedData.expiresAt;
    }, [atlasCache]);

    const setCachedData = useCallback((category: string, data: AtlasCategoryData) => {
        const now = Date.now();
        const newCache = {
            ...atlasCache,
            [category]: {
                data,
                timestamp: now,
                expiresAt: now + CACHE_DURATION
            }
        };

        setAtlasCache(newCache);
        setLastUpdated(prev => ({
            ...prev,
            [category]: now
        }));
    }, [atlasCache]);

    const fetchAtlasData = useCallback(async (category: string, silent: boolean = false) => {
        if (!silent) {
            setIsLoadingPreferences(true);
        }

        const attempt = async (retryCount: number = 0) => {
            try {
                const data = await AtlasStore?.fetchAtlasPreference(category);
                if (data) {
                    setCachedData(category, data);
                }

                if (!silent) {
                    setIsLoadingPreferences(false);
                    setRetryCount(0);
                }

                return data;
            } catch (error) {
                if (retryCount < MAX_RETRY_ATTEMPTS) {
                    setTimeout(() => {
                        attempt(retryCount + 1);
                    }, RETRY_DELAY * Math.pow(2, retryCount));

                    if (!silent) {
                        setRetryCount(retryCount + 1);
                    }
                } else {
                    if (!silent) {
                        setIsLoadingPreferences(false);
                        setError('Failed to load preferences after multiple attempts');
                        setRetryCount(0);
                    }
                    throw error;
                }
            }
        };

        return attempt();
    }, [AtlasStore, setCachedData]);

    const updatePreference = useCallback(async (
        category: string,
        preferenceId: string,
        value: any
    ) => {
        try {
            await AtlasStore?.updateAtlasPreference(category, preferenceId, value);
            toast.success('Preference updated successfully');
        } catch (error) {
            toast.error('Failed to update preference');
        }
    }, [AtlasStore]);

    return {
        atlasCache,
        lastUpdated,
        isLoadingPreferences,
        retryCount,
        error,
        shouldFetchCategory,
        fetchAtlasData,
        updatePreference,
        setError
    };
}; 