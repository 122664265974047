// This wraps the posthog script snippet (see project settings) instead of using posthog-react
// this is necessary (use of the snippet) to work fully with the self-hosted posthog
import React, { useEffect, useState, createContext } from 'react';

// Define PostHog interface
export interface PostHogInterface {
  capture: (event: string, properties?: Record<string, any>) => void;
  identify: (userId: string, properties?: Record<string, any>) => void;
  isFeatureEnabled: (flag: string) => boolean;
  onFeatureFlags: (callback: (flags: Record<string, boolean>) => void) => void;
  [key: string]: any;
}

// Dummy implementation to use when PostHog is not available
const dummyPostHog: PostHogInterface = {
  capture: () => { },
  identify: () => { },
  isFeatureEnabled: () => false,
  onFeatureFlags: () => { }
};

// Create the context
export const PostHogContext = createContext<PostHogInterface>(dummyPostHog);

// Global state for PostHog loading
const postHogState: {
  instance: PostHogInterface | null;
  loading: boolean;
  hasLoaded: boolean;
  hasFailed: boolean;
  loadPromise: Promise<PostHogInterface> | null;
} = {
  instance: null,
  loading: false,
  hasLoaded: false,
  hasFailed: false,
  loadPromise: null
};

// Get PostHog from window safely
function getPostHogFromWindow(): PostHogInterface | null {
  return (typeof window !== 'undefined' && window.posthog) ? window.posthog : null;
}

// Load PostHog with feature flags
function loadPostHog(): Promise<PostHogInterface> {
  // Return existing promise if already loading
  if (postHogState.loadPromise) return postHogState.loadPromise;

  // Create new promise
  postHogState.loading = true;
  postHogState.loadPromise = new Promise<PostHogInterface>((resolve, reject) => {
    const posthog = getPostHogFromWindow();

    // If PostHog isn't on window, fail immediately
    if (!posthog) {
      return reject(new Error('PostHog not found on window'));
    }

    // Set timeout for feature flags loading
    const timeout = setTimeout(() => {
      reject(new Error('PostHog feature flags timed out'));
    }, 5000);

    // Check if flags already loaded.. BILL - this uses an internal state
    // likely stable, but beware.
    if (posthog._flags && Object.keys(posthog._flags).length > 0) {
      clearTimeout(timeout);
      return resolve(posthog);
    }

    // Wait for flags
    posthog.onFeatureFlags(() => {
      clearTimeout(timeout);
      resolve(posthog);
    });
  })
    .then(posthog => {
      postHogState.instance = posthog;
      postHogState.hasLoaded = true;
      return posthog;
    })
    .catch(error => {
      console.error('PostHog loading failed:', error);
      postHogState.hasFailed = true;
      return dummyPostHog;
    })
    .finally(() => {
      postHogState.loading = false;
    });

  return postHogState.loadPromise;
}

// Public function to get PostHog instance for direct usage
export function getPostHog(): Promise<PostHogInterface> {
  // If already loaded successfully, return the instance immediately
  if (postHogState.hasLoaded && postHogState.instance) {
    return Promise.resolve(postHogState.instance);
  }

  // If already failed, return the dummy immediately
  if (postHogState.hasFailed) {
    return Promise.resolve(dummyPostHog);
  }

  // Otherwise, trigger the loading process
  return loadPostHog();
}

export function PostHogProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [posthog, setPosthog] = useState<PostHogInterface>(
    postHogState.instance || dummyPostHog
  );

  useEffect(() => {
    // If we already have PostHog loaded, use it
    if (postHogState.hasLoaded && postHogState.instance) {
      setPosthog(postHogState.instance);
      return;
    }

    // If we've already failed, don't retry
    if (postHogState.hasFailed) {
      return;
    }

    // Load PostHog if needed
    let isMounted = true;
    loadPostHog().then(ph => {
      if (isMounted) setPosthog(ph);
    });

    return () => { isMounted = false; };
  }, []);

  return (
    <PostHogContext.Provider value={posthog}>
      {children}
    </PostHogContext.Provider>
  );
}

// Hook to use PostHog with async loading 
export function usePostHog(): PostHogInterface {
  const context = React.useContext(PostHogContext);
  if (!context) {
    console.warn('usePostHog must be used within a PostHogProvider');
  }
  return context || dummyPostHog;
}


// Declare global type
declare global {
  interface Window {
    posthog?: PostHogInterface;
  }
}

const phWrapper = {
  PostHogContext,
  PostHogProvider,
  usePostHog,
  getPostHog
};
export default phWrapper;