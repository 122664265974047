import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Feedback from './common/Feedback/Feedback';

const feedbackStyles = {
    border: '1px solid #E4E4E7',
    borderRadius: '4px',
    display: 'inline-flex',
    justifyContent: 'end',
    alignSelf: 'flex-end'
}

// Styled Components
const Container = styled(motion.div)`
    background: white;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    margin: 24px;
    overflow: hidden;
    width: calc(100% - 48px);
    max-width: 1200px;
`;

const Header = styled.div`
    padding: 24px;
    background: #F4F3FF;
    border-bottom: 1px solid #E6E3FF;
`;

const HeaderContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled.h1`
    font-size: 28px;
    font-family: "Rand Medium", sans-serif;
    color: #171717;
    margin: 8px 0;
`;

const RefreshButton = styled.button`
    background: #6B4EFF;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 14px;
    font-family: "Rand Medium", sans-serif;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s ease-in-out;
    margin-left: auto;
    
    &:hover {
        background: #5B3EEF;
        transform: translateY(-1px);
    }
    
    &:active {
        transform: translateY(0);
    }
    
    svg {
        width: 16px;
        height: 16px;
    }
`;

const Section = styled.div`
    padding: 24px;
    border-top: 1px solid #EAEAEA;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    &:nth-child(odd) {
        background: #FAFAFA;
    }
`;

const SectionTitle = styled.h2`
    font-size: 18px;
    font-family: "Rand Medium", sans-serif;
    color: #171717;
    margin-bottom: 16px;
`;

const InsightText = styled.p`
    color: #666;
    font-size: 16px;
    line-height: 1.6;
    font-family: "Rand Light", sans-serif;
    margin-bottom: 16px;
`;

const PatternContainer = styled.div`
    margin-bottom: 24px;
    
    &:last-child {
        margin-bottom: 0;
    }
`;

const PatternTitle = styled.h3`
    font-size: 16px;
    font-family: "Rand Medium", sans-serif;
    color: #333;
    margin-bottom: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid #EAEAEA;
`;

const PatternList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const PatternItem = styled.li`
    display: flex;
    align-items: flex-start;
    padding: 8px 0;
    font-family: "Rand Light", sans-serif;
    color: #444;
    line-height: 1.5;
    
    &:before {
        content: "•";
        color: #6B4EFF;
        font-weight: bold;
        margin-right: 12px;
    }
`;

const SignatureList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 16px;
`;

const SignatureCard = styled.div`
    background: white;
    border-radius: 24px;
    padding: 16px 24px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    border: 1px solid #E6E3FF;
    color: #444;
    font-size: 15px;
    line-height: 1.5;
    font-family: "Rand Light", sans-serif;
    transition: all 0.2s ease-in-out;
    
    &:hover {
        transform: translateX(4px);
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        border-color: #6B4EFF;
    }
    
    display: flex;
    align-items: center;
    
    &:before {
        content: "";
        width: 8px;
        height: 8px;
        background: #6B4EFF;
        border-radius: 50%;
        margin-right: 16px;
        flex-shrink: 0;
    }
`;

const IlluminatingMoment = styled.div`
    background: white;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 16px;
    border-left: 3px solid #6B4EFF;
`;

const AnalysisSection = styled.div`
    margin-bottom: 24px;
`;

const AnalysisCard = styled.div`
    background: white;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 12px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

interface DossierViewProps {
    analysis?: {
        opening_insight: string;
        pattern_weaving: { [key: string]: string[] };
        behavioral_signatures: string[];
        key_insight: string;
        illuminating_moments: string;
        adaptive_patterns: string;
        preference_architecture: string;
    };
    onRefresh?: () => void;
}

export const DossierView: React.FC<DossierViewProps> = ({ analysis, onRefresh }) => {
    if (!analysis) return null;

    const sectionVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };

    return (
        <Container
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
        >
            <Header>
                <HeaderContent>
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Title>Personal Dossier</Title>
                    </motion.div>
                    {onRefresh && (
                        <RefreshButton onClick={onRefresh}>
                            <svg
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.3" />
                            </svg>
                            Refresh Analysis
                        </RefreshButton>
                    )}
                </HeaderContent>
            </Header>

            {analysis.opening_insight && (
                <motion.div
                    variants={sectionVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.3, delay: 0.1 }}
                >
                    <Section>
                        <SectionTitle>Opening Insight</SectionTitle>
                        <InsightText>{analysis.opening_insight}</InsightText>

                        <Feedback
                            style={feedbackStyles}
                            text="Is this correct and useful?"
                            responseKey={'atlas::Dossier::section'}
                            metadata={{ section: 'Opening Insight' }}
                        />
                    </Section>
                </motion.div>
            )}

            {Object.keys(analysis.pattern_weaving || {}).length > 0 && (
                <motion.div
                    variants={sectionVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.3, delay: 0.2 }}
                >
                    <Section>
                        <SectionTitle>Pattern Analysis</SectionTitle>
                        {Object.entries(analysis.pattern_weaving).map(([category, points], index) => (
                            <PatternContainer key={category}>
                                <PatternTitle>{category}</PatternTitle>
                                <PatternList>
                                    {Array.isArray(points) ? (
                                        points.map((point, pointIndex) => (
                                            <PatternItem key={pointIndex}>{point}</PatternItem>
                                        ))
                                    ) : (
                                        <PatternItem>{points}</PatternItem>
                                    )}
                                </PatternList>
                            </PatternContainer>
                        ))}

                        <Feedback
                            style={feedbackStyles}
                            text="Is this correct and useful?"
                            responseKey={'atlas::Dossier::section'}
                            metadata={{ section: 'Pattern Analysis' }}
                        />
                    </Section>
                </motion.div>
            )}

            {analysis.preference_architecture && (
                <motion.div
                    variants={sectionVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.3, delay: 0.3 }}
                >
                    <Section>
                        <SectionTitle>Preference Architecture</SectionTitle>
                        <AnalysisSection>
                            <AnalysisCard>
                                {analysis.preference_architecture}
                            </AnalysisCard>
                        </AnalysisSection>

                        <Feedback
                            style={feedbackStyles}
                            text="Is this correct and useful?"
                            responseKey={'atlas::Dossier::section'}
                            metadata={{ section: 'Preference Architecture' }}
                        />
                    </Section>
                </motion.div>
            )}

            {analysis.adaptive_patterns && (
                <motion.div
                    variants={sectionVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.3, delay: 0.4 }}
                >
                    <Section>
                        <SectionTitle>Adaptive Patterns</SectionTitle>
                        <AnalysisSection>
                            <AnalysisCard>
                                {analysis.adaptive_patterns}
                            </AnalysisCard>
                        </AnalysisSection>

                        <Feedback
                            style={feedbackStyles}
                            text="Is this correct and useful?"
                            responseKey={'atlas::Dossier::section'}
                            metadata={{ section: 'Adaptive Patterns' }}
                        />
                    </Section>
                </motion.div>
            )}

            {Array.isArray(analysis.behavioral_signatures) && analysis.behavioral_signatures.length > 0 && (
                <motion.div
                    variants={sectionVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.3, delay: 0.5 }}
                >
                    <Section>
                        <SectionTitle>Behavioral Signatures</SectionTitle>
                        <SignatureList>
                            {analysis.behavioral_signatures.map((signature, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, x: -20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.3, delay: 0.1 * index }}
                                >
                                    <SignatureCard>{signature}</SignatureCard>
                                </motion.div>
                            ))}
                        </SignatureList>

                        <Feedback
                            style={feedbackStyles}
                            text="Is this correct and useful?"
                            responseKey={'atlas::Dossier::section'}
                            metadata={{ section: 'Behavioral Signatures' }}
                        />
                    </Section>
                </motion.div>
            )}

            {analysis.illuminating_moments && (
                <motion.div
                    variants={sectionVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.3, delay: 0.6 }}
                >
                    <Section>
                        <SectionTitle>Illuminating Moments</SectionTitle>
                        <IlluminatingMoment>
                            {analysis.illuminating_moments}
                        </IlluminatingMoment>

                        <Feedback
                            style={feedbackStyles}
                            text="Is this correct and useful?"
                            responseKey={'atlas::Dossier::section'}
                            metadata={{ section: 'Illuminating Moments' }}
                        />
                    </Section>
                </motion.div>
            )}

            {analysis.key_insight && (
                <motion.div
                    variants={sectionVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.3, delay: 0.7 }}
                >
                    <Section>
                        <SectionTitle>Key Insight</SectionTitle>
                        <InsightText>{analysis.key_insight}</InsightText>

                        <Feedback
                            style={feedbackStyles}
                            text="Is this correct and useful?"
                            responseKey={'atlas::Dossier::section'}
                            metadata={{ section: 'Key Insight' }}
                        />
                    </Section>
                </motion.div>
            )}
        </Container>
    );
};

export default DossierView;
