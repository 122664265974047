import React, { useState } from 'react';
import styled from 'styled-components';

interface CollapsibleSectionProps {
  title: string;
  darkMode: boolean;
  defaultOpen?: boolean;
  children: React.ReactNode;
}

const SectionHeader = styled.div<{ $darkMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px 0;
  margin-bottom: 6px;
  transition: all 0.2s ease;
  
  &:hover {
    opacity: 0.8;
  }
`;

const Title = styled.div<{ $darkMode: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.$darkMode ? '#CBD5E1' : '#334155'};
  display: flex;
  align-items: center;
`;

const Arrow = styled.div<{ $isOpen: boolean; $darkMode: boolean }>`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${props => props.$darkMode ? '#334155' : '#F1F5F9'};
  transform: rotate(${props => props.$isOpen ? '180deg' : '0deg'});
  transition: transform 0.3s ease;
  
  svg {
    color: ${props => props.$darkMode ? '#CBD5E1' : '#64748B'};
    width: 14px;
    height: 14px;
  }
`;

const Content = styled.div<{ $isOpen: boolean }>`
  max-height: ${props => props.$isOpen ? '1000px' : '0'};
  overflow: hidden;
  transition: max-height 0.3s ease;
  opacity: ${props => props.$isOpen ? '1' : '0'};
  transition: max-height 0.3s ease, opacity 0.3s ease;
`;

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  darkMode,
  defaultOpen = false,
  children
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div>
      <SectionHeader 
        onClick={() => setIsOpen(!isOpen)} 
        $darkMode={darkMode}
      >
        <Title $darkMode={darkMode}>{title}</Title>
        <Arrow $isOpen={isOpen} $darkMode={darkMode}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </Arrow>
      </SectionHeader>
      <Content $isOpen={isOpen}>
        {children}
      </Content>
    </div>
  );
};