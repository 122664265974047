import { makeObservable, observable, action, computed } from "mobx";
import { toast } from "react-toastify";

import runGoogleOAuthFlow, { getGoogleConfigs } from "util/GoogleOAuth";
import { delay, exponentialBackoff } from "util/ExponentialBackoff";

import API from "util/API";

const DELAY = 3_000;
const MAX_POLLS = 5;

class OnboardingStore {
    public pollCount: number = 0;
    public isPolling: boolean = false;
    public isLoading: boolean = false;
    
    public ingestedEmailCount: number = 0;
    public routedEmailCount: number = 0;
    public otherCategoriesIngested: number = 0;
    
    public totalEmails: number = 0;
    public processedEmails: number = 0;
    
    // Extraction stats
    public flightExtractions: number = 0;
    public hotelStayExtractions: number = 0;
    public diningExtractions?: number;
    public calendarEvents?: number;
    
    // Status flags
    public userCreated: boolean = false;
    public onboardingCompleted: boolean = false;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            isPolling: observable,
            pollCount: observable,
            isMaxPolling: computed,

            ingestedEmailCount: observable,
            routedEmailCount: observable,
            otherCategoriesIngested: observable,

            flightExtractions: observable,
            hotelStayExtractions: observable,
            diningExtractions: observable,
            calendarEvents: observable,

            totalEmails: observable,
            processedEmails: observable,

            onboardingCompleted: observable,
            userCreated: observable,

            handleGoogleAuth: action,
            completeOnboarding: action,
            getOnboardingStatsSuccess: action,
            pollForStats: action,
            stopPollingForStats: action,
            requestVendor: action
        });
    }

    get isMaxPolling(): boolean {
        return this.pollCount >= MAX_POLLS;
    }

    completeOnboarding = () => {
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            API.post("/api/user/completeOnboarding", {})
                .then(data => {
                    window.location.href = '/dashboard';
                    return resolve(data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    requestVendor = (vendor: string) => {
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            API.post("/api/user/requestVendor", { vendor })
                .then(data => {
                    return resolve(data);
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    handleGoogleAuth = (
        gmailCategoryState: string[],
        gmailTimeState: string = '',
        accountEmail: string = undefined
    ) => {
        const [allowedGmailCategories, gmailStartingAfter] = getGoogleConfigs(gmailCategoryState, gmailTimeState);

        const requestBody = {
            gmail_categories: allowedGmailCategories,
            gmail_starting_after: gmailStartingAfter,
            accountEmail,
            timeOption: gmailTimeState
        };

        runGoogleOAuthFlow(requestBody);
    };

    getOnboardingStatsSuccess = (data: any) => {
        this.isLoading = false;

        // sometimes the backend numbers aren't logical to the user... 
        if (data.totalEmails > this.totalEmails) {
            this.totalEmails = data.totalEmails;
        }
        if (data.processedEmails > this.processedEmails) {
            this.processedEmails = data.processedEmails;
        }

        this.flightExtractions = data.flightExtractions;

        this.hotelStayExtractions = data.hotelStayExtractions;

        if ('diningExtractions' in data) {
            this.diningExtractions = data.diningExtractions;
        }

        if ('calendarEvents' in data) {
            this.calendarEvents = data.calendarEvents;
        }

        this.userCreated = data.userCreated;
        this.onboardingCompleted = data.onboardingCompleted;
    };

    getOnboardingStatsFailure = (error) => {
        this.isLoading = false;
    };

    getOnboardingStats = () => {
        this.isLoading = true;

        return new Promise((resolve, reject) => {
            API.post("/api/user/accountStats", {})
                .then(response => response.json())
                .then(data => {
                    this.getOnboardingStatsSuccess(data);
                    return resolve(data);
                })
                .catch((error) => {
                    this.getOnboardingStatsFailure(error);
                    return reject(error);
                });
        });
    };

    stopPollingForStats = () => {
        this.isPolling = false;
    }

    pollForStats = async (onComplete: () => void = () => {}) => {
        this.isPolling = true;

        await exponentialBackoff(this.getOnboardingStats, 4)
            .then(({ onboardingCompleted }: any) => {
                this.pollCount += 1;

                if (onboardingCompleted || this.pollCount >= MAX_POLLS) {
                    this.isPolling = false;
                } else if (this.isPolling) {
                    delay(DELAY).then(() => this.pollForStats(onComplete));
                }
            })
            .catch(e => {
                console.error(e);
                // BILL - techdebt - this error is not useful here.  We need an actionable or 
                // informative error message - system is busy will retry, try deleting and 
                // adding your account again,  ...
                // toast.error('An error occurred during account onboarding.');
                // defaulting to system busy for now.
                toast.error('Adapter is working hard to learn about your past. We will continue to do so in the background');
            });
    }
}

const onboardingStore = new OnboardingStore();

export default onboardingStore;
