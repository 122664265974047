import { makeObservable, action, observable, computed } from "mobx";

import API from "util/API";
import { MapOption } from "pages/intentions/MapView";
import { StorageKeys, getFromLocalStorage, saveToLocalStorage } from "util/LocalStorage";

class TelemetryStore {
    loading: boolean = false;
    locations: MapOption[] = [];

    constructor() {
        makeObservable(this, {
            loading: observable,
            locations: observable,

            lastKnownLocation: computed,

            setLastKnownLocation: action,
            setLocationData: action,
            setLoading: action,

            getLocationData: action,
            getNetworkSummary: action,
            getBatterySummary: action,
        });

        getFromLocalStorage(StorageKeys.lastKnownLocation)
            .then(location => {
                try {
                    // @ts-ignore
                    const l = JSON.parse(location);
                    // @ts-ignore
                    this.locations.replace([l]);
                }
                catch (e) {
                    console.error(e);
                }
            });
    }

    get lastKnownLocation() {
        if (this.locations.length === 0) {
            return false;
        }

        return this.locations[this.locations.length - 1];
    }

    setLoading = (loading) => {
        this.loading = loading;
    }

    setLocationData = (data = []) => {
        const allData = [];
        for (let i = 0; i < data.length; i++) {
            const d: MapOption[] = data[i];

            allData.push(...d);
        }
        
        // @ts-ignore
        this.locations.replace(allData);
        this.setLoading(false);

        // TODO: this is no longer true
        // locations should be DESC order, most-recent first
        // this.setLastKnownLocation(this.locations[0]);
    }

    setLastKnownLocation = (lastLocation) => {
        if (lastLocation) {
            saveToLocalStorage(StorageKeys.lastKnownLocation, JSON.stringify(lastLocation));
        }
    }

    getLocationData = (startTimestamp: number, endTimestamp: number) => {
        this.setLoading(true);
        const promises = [];

        promises.push(
            new Promise((resolve, reject) => {
                API.post('/api/user/inferredLocations', { startTimestamp, endTimestamp })
                    .then(response => response.json())
                    .then(data => resolve(data))
                    .catch(error => {
                        console.error('Error fetching inferredLocations:', error);
                        reject(error);
                    });
            })
        );

        promises.push(
            new Promise((resolve, reject) => {
                API.get(`/api/user/telemetry/geolocation?startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}`)
                    .then(response => response.json())
                    .then(data => resolve(data))
                    .catch(error => {
                        console.error('Error fetching geolocation:', error);
                        reject(error);
                    });
            })
        );

        return Promise.all(promises)
            .then(this.setLocationData);
    }

    getNetworkSummary = () => {
        this.setLoading(true);

        return new Promise((resolve, reject) => {
            API.get('/api/user/telemetry/network')
                .then(response => response.json())
                .then(data => {
                    this.setLoading(false);
                    resolve(data);
                })
                .catch(error => {
                    this.setLoading(false);
                    console.error('Error fetching network telemetry:', error);
                    reject(error);
                });
        })
    }

    getBatterySummary = () => {
        this.setLoading(true);

        return new Promise((resolve, reject) => {
            API.get('/api/user/telemetry/battery')
                .then(response => response.json())
                .then(data => {
                    this.setLoading(false);
                    resolve(data);
                })
                .catch(error => {
                    this.setLoading(false);
                    console.error('Error fetching battery telemetry:', error);
                    reject(error);
                });
        })
    }
}

const telemetryStore = new TelemetryStore();

export default telemetryStore;