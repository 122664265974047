import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { PreferenceItem, UnderstandingItem } from '../types';
import { determineTileType } from 'constants/CategoryMappings';
import { sampleData } from '../data';
import AtlasStore from 'stores/AtlasStore';

interface UsePreferenceManagementProps {
    atlasCache: Record<string, any>;
    selectedCategory: string | null;
    selectedTileType: string | null;
    updatePreference: (category: string, preferenceId: string, value: any) => Promise<void>;
}

export const usePreferenceManagement = ({
    atlasCache,
    selectedCategory,
    selectedTileType,
    updatePreference
}: UsePreferenceManagementProps) => {
    const getCurrentPreferences = useCallback((): PreferenceItem[] => {
        const selectedCategoryData = sampleData.categories.find(
            c => c.id === selectedCategory
        );

        if (selectedCategoryData?.type === 'Dossier') {
            return [{
                id: 'dossier-analysis',
                categoryId: '6',
                categoryType: 'Dossier',
                tileType: 'analysis',
                name: 'Personal Analysis Report',
                value: 'Click to view your full personal analysis',
                metadata: {
                    source: 'Analysis Engine',
                    updated_at: new Date().toISOString()
                }
            }];
        }

        if (selectedCategoryData?.type === 'Memories') {
            const memories = AtlasStore?.memories || [];
            const transformedMemories = memories
                .filter(memory =>
                    !selectedTileType || memory.categories?.some(category => {
                        const cleanCategory = category.replace('Category.', '');
                        return cleanCategory === selectedTileType;
                    })
                )
                .map(memory => ({
                    id: memory.id,
                    categoryId: '5',
                    categoryType: 'Memories',
                    tileType: memory.categories?.[0]?.replace('Category.', '') || 'UNCATEGORIZED',
                    name: memory.content.substring(0, 50) + (memory.content.length > 50 ? '...' : ''),
                    value: memory.content,
                    metadata: {
                        created_at: new Date(memory.created_at).toLocaleString(),
                        last_referenced: memory.last_referenced ?
                            new Date(memory.last_referenced).toLocaleString() : undefined,
                        updated_at: memory.updated_at ?
                            new Date(memory.updated_at).toLocaleString() : undefined,
                        categories: memory.categories?.map(c => c.replace('Category.', '')),
                        source: 'Memory Bank'
                    }
                }));
            return transformedMemories;
        }

        if (selectedCategoryData?.type) {
            const cachedData = atlasCache[selectedCategoryData.type]?.data;
            if (cachedData) {
                return transformAtlasData(cachedData).filter(
                    pref => pref.tileType === selectedTileType
                );
            }
        }

        return sampleData.preferences.filter(p =>
            p.categoryType === selectedCategoryData?.type &&
            p.tileType === selectedTileType
        );
    }, [atlasCache, selectedCategory, selectedTileType]);

    const getPreferenceHistory = useCallback((preferenceId: string) => {
        const selectedCategoryData = sampleData.categories.find(
            c => c.id === selectedCategory
        );

        if (selectedCategoryData?.type === 'Memories') {
            const memory = AtlasStore.memories?.find(m => m.id === preferenceId);
            if (memory) {
                return [{
                    provider: 'Memory Bank',
                    date: memory.created_at,
                    lastReferenced: memory.last_referenced,
                    updated: memory.updated_at
                }];
            }
            return [];
        }

        if (preferenceId.startsWith('atlas-')) {
            const preference = getCurrentPreferences().find(p => p.id === preferenceId);
            return [{
                provider: 'Atlas Service',
                date: preference?.metadata?.lastUpdated || new Date().toISOString(),
                confidence: preference?.metadata?.confidence
            }];
        }
        return [];
    }, [selectedCategory, getCurrentPreferences]);

    const getPreferenceUnderstanding = useCallback((preferenceId: string): UnderstandingItem[] => {
        const selectedCategoryData = sampleData.categories.find(
            c => c.id === selectedCategory
        );

        if (selectedCategoryData?.type === 'Memories') {
            const memory = AtlasStore.memories?.find(m => m.id === preferenceId);
            if (memory) {
                return [
                    {
                        title: 'Categories',
                        value: memory.categories?.map(c => c.replace('Category.', '')).join(', ') || 'Uncategorized',
                        type: "text"
                    },
                    {
                        title: 'Created',
                        value: new Date(memory.created_at).toLocaleString(),
                        type: "text"
                    },
                    {
                        title: 'Last Referenced',
                        value: memory.last_referenced ?
                            new Date(memory.last_referenced).toLocaleString() : 'Never',
                        type: "text"
                    }
                ];
            }
            return [];
        }

        if (preferenceId.startsWith('atlas-')) {
            const preference = getCurrentPreferences().find(p => p.id === preferenceId);
            return [
                {
                    title: 'Source',
                    value: preference?.metadata?.source || 'Atlas Inference',
                    type: "text"
                },
                {
                    title: 'Confidence',
                    value: preference?.metadata?.confidence || 0,
                    type: "percentage"
                }
            ];
        }
        return [];
    }, [selectedCategory, getCurrentPreferences]);

    const debouncedUpdatePreference = useCallback(
        debounce(async (category: string, preferenceId: string, value: any) => {
            await updatePreference(category, preferenceId, value);
        }, 500),
        [updatePreference]
    );

    const handlePreferenceUpdate = useCallback(async (preferenceId: string, value: any) => {
        if (preferenceId.startsWith('atlas-')) {
            const preference = getCurrentPreferences().find(p => p.id === preferenceId);
            if (preference) {
                await debouncedUpdatePreference(
                    preference.categoryType,
                    preferenceId,
                    value
                );
            }
        }
    }, [getCurrentPreferences, debouncedUpdatePreference]);

    const transformAtlasData = (data: any) => {
        return data.preferences.map((pref: any, index: number) => ({
            id: `atlas-${data.category}-${index}`,
            categoryId: '1',
            categoryType: data.category,
            tileType: determineTileType(pref.name),
            name: pref.name,
            value: pref.value,
            metadata: {
                ...pref.metadata,
                confidence: pref.confidence,
                lastUpdated: pref.lastUpdated,
                source: pref.source
            }
        }));
    };

    return {
        getCurrentPreferences,
        getPreferenceHistory,
        getPreferenceUnderstanding,
        handlePreferenceUpdate
    };
}; 