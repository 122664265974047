// /frontend/src/components/StatisticsView.tsx
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { NetworkMetrics } from '../types/network-types';

const StatsContainer = styled(motion.div)`
    padding: 24px;
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 24px auto;
`;

const StatsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 24px;
    margin-top: 24px;
`;

const MetricCard = styled(motion.div)`
    background: #f8f9fa;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #e9ecef;
    
    .metric-title {
        font-size: 14px;
        color: #6c757d;
        margin-bottom: 8px;
    }
    
    .metric-value {
        font-size: 24px;
        font-weight: 600;
        color: #212529;
        margin-bottom: 8px;
    }
    
    .metric-description {
        font-size: 13px;
        color: #868e96;
        line-height: 1.4;
    }
`;

const TrendIndicator = styled.span<{ trend: 'up' | 'down' | 'neutral' }>`
    color: ${props => 
        props.trend === 'up' ? '#40c057' :
        props.trend === 'down' ? '#fa5252' :
        '#868e96'};
    font-size: 14px;
    margin-left: 8px;
`;

const Header = styled.div`
    margin-bottom: 24px;
    
    h2 {
        font-size: 24px;
        color: #212529;
        margin: 0 0 8px 0;
    }
    
    p {
        color: #6c757d;
        margin: 0;
        font-size: 14px;
    }
`;

interface StatisticsViewProps {
    metrics: NetworkMetrics;
}

const formatNumber = (num: number): string => {
    if (num >= 1) return num.toFixed(2);
    if (num >= 0.01) return num.toFixed(3);
    return num.toFixed(4);
};

const getTrend = (value: number, threshold: number): 'up' | 'down' | 'neutral' => {
    if (value > threshold) return 'up';
    if (value < threshold) return 'down';
    return 'neutral';
};

export const StatisticsView: React.FC<StatisticsViewProps> = ({ metrics }) => {
    const densityTrend = getTrend(metrics.density, 0.3);
    const clusteringTrend = getTrend(metrics.clustering, 0.5);

    return (
        <StatsContainer
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Header>
                <h2>Network Analysis</h2>
                <p>Detailed metrics and statistics about the network structure</p>
            </Header>

            <StatsGrid>
                <MetricCard
                    whileHover={{ scale: 1.02 }}
                    transition={{ type: "spring", stiffness: 300 }}
                >
                    <div className="metric-title">Total Nodes</div>
                    <div className="metric-value">{metrics.totalNodes}</div>
                    <div className="metric-description">
                        Active participants in the network
                    </div>
                </MetricCard>

                <MetricCard
                    whileHover={{ scale: 1.02 }}
                    transition={{ type: "spring", stiffness: 300 }}
                >
                    <div className="metric-title">Total Links</div>
                    <div className="metric-value">{metrics.totalLinks}</div>
                    <div className="metric-description">
                        Connections between participants
                    </div>
                </MetricCard>

                <MetricCard
                    whileHover={{ scale: 1.02 }}
                    transition={{ type: "spring", stiffness: 300 }}
                >
                    <div className="metric-title">Average Degree</div>
                    <div className="metric-value">
                        {formatNumber(metrics.averageDegree)}
                    </div>
                    <div className="metric-description">
                        Average connections per participant
                    </div>
                </MetricCard>

                <MetricCard
                    whileHover={{ scale: 1.02 }}
                    transition={{ type: "spring", stiffness: 300 }}
                >
                    <div className="metric-title">Network Density</div>
                    <div className="metric-value">
                        {formatNumber(metrics.density)}
                        <TrendIndicator trend={densityTrend}>
                            {densityTrend === 'up' ? '↑' : 
                             densityTrend === 'down' ? '↓' : '→'}
                        </TrendIndicator>
                    </div>
                    <div className="metric-description">
                        Ratio of actual to possible connections
                    </div>
                </MetricCard>

                <MetricCard
                    whileHover={{ scale: 1.02 }}
                    transition={{ type: "spring", stiffness: 300 }}
                >
                    <div className="metric-title">Clustering Coefficient</div>
                    <div className="metric-value">
                        {formatNumber(metrics.clustering)}
                        <TrendIndicator trend={clusteringTrend}>
                            {clusteringTrend === 'up' ? '↑' : 
                             clusteringTrend === 'down' ? '↓' : '→'}
                        </TrendIndicator>
                    </div>
                    <div className="metric-description">
                        Degree of node clustering in the network
                    </div>
                </MetricCard>

                {metrics.communities !== undefined && (
                    <MetricCard
                        whileHover={{ scale: 1.02 }}
                        transition={{ type: "spring", stiffness: 300 }}
                    >
                        <div className="metric-title">Communities</div>
                        <div className="metric-value">{metrics.communities}</div>
                        <div className="metric-description">
                            Distinct groups identified in the network
                        </div>
                    </MetricCard>
                )}
            </StatsGrid>
        </StatsContainer>
    );
};

export default StatisticsView;