import React, { ChangeEvent, FormEvent } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';

import withRouter from 'hooks/withRouter';
import Modal from 'components/common/Modal';
import { AlertCircleIcon, CloseIcon } from 'components/common/Icon';
import BaseForm from 'components/forms/BaseForm';
import { H4Text, Text, LINK } from 'components/common/Typography';
import Loading from 'components/common/Loading';

type VerifyPhoneModalProps = {
    UserStore?: any;
    router?: any;
    onClose: () => void;
    onRetry: () => void;
}

type VerifyPhoneModalState = {
    passcode: string;
    isLoading: boolean;
}

class VerifyPhoneModal extends React.Component<VerifyPhoneModalProps, VerifyPhoneModalState> {
    state = {
        passcode: undefined,
        isLoading: false
    }

    onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { UserStore, router } = this.props;
        const { passcode } = this.state;

        this.setState({ isLoading: true }, () => {
            UserStore.sendPhoneVerification(passcode)
                .then(() => {
                    router.navigate('/onboarding/connect');
                })
                .catch(() => {
                    this.setState({ isLoading: false }, () => {
                        toast.error(
                            "Incorrect phone verification. Please check the entered code and try again.",
                            { theme: 'dark', position: 'bottom-right', icon: false }
                        );
                    });
                })
        });
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        // @ts-ignore
        this.setState({ passcode: value });
    };

    onRetry = (e) => {
        const { onRetry } = this.props;
        e.preventDefault();
        this.setState({ passcode: '' });
        onRetry();
    }

    render() {
        const { passcode, isLoading } = this.state;
        const { onClose } = this.props;

        const config = [
            {
                labelText: "Verification Code",
                fieldId: "passcode",
                type: "text",
                value: passcode,
                required: true,
                errorText: passcode !== undefined && passcode?.length === 0 ? "Verification code is required" : undefined
            }
        ];

        return (
            <Modal onClose={onClose} cls="onboarding-popup">
                <div className="onboarding-modal">

                    <div className="onboarding-modal-head">
                        <AlertCircleIcon color="#5C33CC" />
                        <CloseIcon
                            onClick={onClose}
                            style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
                        />
                    </div>

                    <div className="onboarding-modal-body">
                        <H4Text>Verify your mobile phone number</H4Text>

                        <Text>
                            Please check your mobile phone for a text message containing a verification code and enter it here:
                        </Text>

                        {!isLoading && (
                                <BaseForm
                                config={config}
                                isLoading={isLoading}
                                buttonText="Verify Phone Number"
                                onChange={this.onChangeHandler}
                                onSubmit={this.onSubmitHandler}
                                style={{ minWidth: '50%' }}
                            />
                        )}

                        {isLoading && <Loading modal={false} />}

                        <div className='text-center pt-8'>
                            <LINK onClick={this.onRetry}>Request a new code</LINK>
                        </div>
                    </div>

                    <div className="onboarding-modal-foot"></div>
                </div>
            </Modal>
        );
    }
}

export default withRouter(inject('UserStore')(observer(VerifyPhoneModal)));