import React from 'react';

import Card from '../Card';
import './Modal.scss';

const Modal = ({ children, cls = '', onClose = () => { }, wrapWithCard = true }: any) => {
    let innerElements = (
        <>
            {children}
        </>
    );

    if (wrapWithCard) {
        innerElements = (
            <Card>
                {children}
            </Card>
        );
    }

    return (
        <div className={`adapter-modal ${cls}`}>
            <div className="adapter-modal-background" onClick={onClose} />
            <div className="adapter-modal-content">
                {innerElements}
            </div>
        </div>
    );
};

export default Modal;