/**
 * Simple toggleable logging utility
 */

// Check for URL parameter to enable/disable logging
function checkUrlLoggingParam(): boolean | null {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    const loggingParam = params.get('logging');
    if (loggingParam === 'true') return true;
    if (loggingParam === 'false') return false;
  }
  return null;
}

// Check for environment variable to enable/disable logging
function checkEnvLoggingFlag(): boolean | null {
  // Env variables in create-react-app must start with REACT_APP_
  if (process.env.REACT_APP_ENABLE_LOGGING === 'true') return true;
  if (process.env.REACT_APP_ENABLE_LOGGING === 'false') return false;
  return null;
}

// Global toggle for logging - URL param overrides env var, which overrides default
let isLoggingEnabled = checkUrlLoggingParam() ?? 
                       checkEnvLoggingFlag() ?? 
                       (process.env.NODE_ENV !== 'production');

// Logger object with methods that mirror console
const Logger = {
  // Toggle logging on/off
  enable: () => {
    isLoggingEnabled = true;
    return true;
  },
  
  disable: () => {
    isLoggingEnabled = false;
    return false;
  },
  
  toggle: () => {
    isLoggingEnabled = !isLoggingEnabled;
    return isLoggingEnabled;
  },
  
  isEnabled: () => isLoggingEnabled,
  
  log: (...args: any[]) => {
    if (isLoggingEnabled) {
      console.log(...args);
    }
  },
  
  warn: (...args: any[]) => {
    if (isLoggingEnabled) {
      console.warn(...args);
    }
  },
  
  error: (...args: any[]) => {
    if (isLoggingEnabled) {
      console.error(...args);
    }
  },
  
  info: (...args: any[]) => {
    if (isLoggingEnabled) {
      console.info(...args);
    }
  },
  
  debug: (...args: any[]) => {
    if (isLoggingEnabled) {
      console.debug(...args);
    }
  }
};

export default Logger;