import React, { useMemo } from 'react';
import Card from 'components/common/Card';

// Icons
const DocumentIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6668 1.66667H5.00016C4.55814 1.66667 4.13421 1.84226 3.82165 2.15482C3.5091 2.46738 3.3335 2.8913 3.3335 3.33333V16.6667C3.3335 17.1087 3.5091 17.5326 3.82165 17.8452C4.13421 18.1577 4.55814 18.3333 5.00016 18.3333H15.0002C15.4422 18.3333 15.8661 18.1577 16.1787 17.8452C16.4912 17.5326 16.6668 17.1087 16.6668 16.6667V6.66667L11.6668 1.66667Z" 
      stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const CalendarIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.6665 1.66667V4.16667M13.3332 1.66667V4.16667M2.9165 7.5H17.0832M5.4165 2.91667H14.5832C15.9639 2.91667 17.0832 4.03596 17.0832 5.41667V15.4167C17.0832 16.7974 15.9639 17.9167 14.5832 17.9167H5.4165C4.03579 17.9167 2.9165 16.7974 2.9165 15.4167V5.41667C2.9165 4.03596 4.03579 2.91667 5.4165 2.91667Z" 
      stroke="#7549F2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const EmailIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.6665 5L9.1665 10.8333L16.6665 5M2.49984 3.33333H15.8332C16.7536 3.33333 17.4998 4.07953 17.4998 5V15C17.4998 15.9205 16.7536 16.6667 15.8332 16.6667H2.49984C1.57936 16.6667 0.833171 15.9205 0.833171 15V5C0.833171 4.07953 1.57936 3.33333 2.49984 3.33333Z" 
      stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const commonWords = [
  // Articles
  'A', 'An', 'The',
  
  // Pronouns
  'I', 'Me', 'My', 'Mine', 'Myself',
  'You', 'Your', 'Yours', 'Yourself', 'Yourselves',
  'He', 'Him', 'His', 'Himself',
  'She', 'Her', 'Hers', 'Herself',
  'It', 'Its', 'Itself',
  'We', 'Us', 'Our', 'Ours', 'Ourselves',
  'They', 'Them', 'Their', 'Theirs', 'Themselves',
  'This', 'That', 'These', 'Those', 
  'Who', 'Whom', 'Whose', 'Which', 'What',
  'Whatever', 'Whichever', 'Whoever', 'Whomever',
  'Anyone', 'Anybody', 'Anything',
  'Someone', 'Somebody', 'Something',
  'Everyone', 'Everybody', 'Everything',
  'No one', 'Nobody', 'Nothing',
  'Each', 'Either', 'Neither', 'Both',
  'One', 'Ones', 'Other', 'Others', 'Another',
  'Such', 'Same',
  
  // Prepositions
  'About', 'Above', 'Across', 'After', 'Against', 'Along', 'Alongside', 'Amid', 'Among', 'Amongst',
  'Around', 'As', 'At', 'Before', 'Behind', 'Below', 'Beneath', 'Beside', 'Besides', 'Between',
  'Beyond', 'By', 'Concerning', 'Despite', 'Down', 'During', 'Except', 'For', 'From', 'In',
  'Inside', 'Into', 'Like', 'Near', 'Of', 'Off', 'On', 'Onto', 'Out', 'Outside', 'Over',
  'Past', 'Per', 'Plus', 'Regarding', 'Round', 'Since', 'Than', 'Through', 'Throughout', 'Till',
  'To', 'Toward', 'Towards', 'Under', 'Underneath', 'Unlike', 'Until', 'Up', 'Upon', 'Via',
  'With', 'Within', 'Without',
  
  // Conjunctions
  'And', 'But', 'Or', 'Nor', 'So', 'Yet', 'For', 'While', 'Because', 'Although',
  'Though', 'Even though', 'If', 'Unless', 'Since', 'As', 'When', 'Whenever', 'Where',
  'Whereas', 'Whether', 'After', 'Before', 'Until', 'Once', 'Now that', 'As if',
  'Than', 'Rather than', 'As much as', 'Both', 'Either', 'Neither', 'Not only',
  'But also', 'As well as',
  
  // Common adverbs
  'Very', 'Quite', 'Rather', 'Too', 'Enough', 'Extremely', 'Almost', 'Nearly',
  'Hardly', 'Scarcely', 'Just', 'Only', 'Merely', 'Already', 'Still', 'Yet',
  'Never', 'Ever', 'Always', 'Often', 'Frequently', 'Usually', 'Sometimes', 'Occasionally',
  'Seldom', 'Rarely', 'Here', 'There', 'Now', 'Then', 'Soon', 'Later', 'Today',
  'Tomorrow', 'Yesterday', 'Again', 'Once', 'Twice', 'Moreover', 'Furthermore', 'Therefore',
  'Consequently', 'However', 'Hence', 'Thus', 'Prior',
  
  // Common adjectives
  'Good', 'Bad', 'Big', 'Small', 'New', 'Old', 'High', 'Low', 'Great', 'Poor', 'Happy', 'Sad',
  'Long', 'Short', 'Hot', 'Cold', 'Easy', 'Hard', 'Fast', 'Slow', 'Early', 'Late', 'Right',
  'Wrong', 'Important', 'Best', 'Worst', 'Better', 'Worse', 'First', 'Last', 'Next', 'Previous',
  'Main', 'Primary', 'Secondary', 'Major', 'Minor', 'Many', 'Few', 'Several', 'Some', 'All',
  'Most', 'None', 'Any', 'Every',
  
  // Common verbs (various forms)
  'Am', 'Is', 'Are', 'Was', 'Were', 'Be', 'Being', 'Been',
  'Have', 'Has', 'Had', 'Having',
  'Do', 'Does', 'Did', 'Done', 'Doing',
  'Can', 'Could', 'May', 'Might', 'Must', 'Shall', 'Should', 'Will', 'Would',
  'Go', 'Goes', 'Going', 'Gone', 'Went',
  'Come', 'Comes', 'Coming', 'Came',
  'Take', 'Takes', 'Taking', 'Took', 'Taken',
  'Make', 'Makes', 'Making', 'Made',
  'Say', 'Says', 'Saying', 'Said',
  'See', 'Sees', 'Seeing', 'Saw', 'Seen',
  'Know', 'Knows', 'Knowing', 'Knew', 'Known',
  'Get', 'Gets', 'Getting', 'Got', 'Gotten',
  'Give', 'Gives', 'Giving', 'Gave', 'Given',
  'Use', 'Uses', 'Using', 'Used',
  'Find', 'Finds', 'Finding', 'Found',
  'Want', 'Wants', 'Wanting', 'Wanted',
  'Need', 'Needs', 'Needing', 'Needed',
  'Become', 'Becomes', 'Becoming', 'Became',
  'Leave', 'Leaves', 'Leaving', 'Left',
  'Put', 'Puts', 'Putting',
  'Mean', 'Means', 'Meaning', 'Meant',
  'Keep', 'Keeps', 'Keeping', 'Kept',
  'Let', 'Lets', 'Letting',
  'Begin', 'Begins', 'Beginning', 'Began', 'Begun',
  'Seem', 'Seems', 'Seeming', 'Seemed',
  'Help', 'Helps', 'Helping', 'Helped',
  'Talk', 'Talks', 'Talking', 'Talked',
  'Turn', 'Turns', 'Turning', 'Turned',
  'Start', 'Starts', 'Starting', 'Started',
  'Show', 'Shows', 'Showing', 'Showed', 'Shown',
  'Hear', 'Hears', 'Hearing', 'Heard',
  'Play', 'Plays', 'Playing', 'Played',
  'Run', 'Runs', 'Running', 'Ran',
  'Move', 'Moves', 'Moving', 'Moved',
  'Like', 'Likes', 'Liking', 'Liked',
  'Live', 'Lives', 'Living', 'Lived',
  'Believe', 'Believes', 'Believing', 'Believed',
  'Hold', 'Holds', 'Holding', 'Held',
  'Bring', 'Brings', 'Bringing', 'Brought',
  'Happen', 'Happens', 'Happening', 'Happened',
  'Write', 'Writes', 'Writing', 'Wrote', 'Written',
  'Read', 'Reads', 'Reading',
  'Set', 'Sets', 'Setting',
  'Sent', 'Send', 'Sending', 'Sends',
  'End', 'Ends', 'Ending', 'Ended',
  'Call', 'Calls', 'Calling', 'Called',
  'Ask', 'Asks', 'Asking', 'Asked',
  'Try', 'Tries', 'Trying', 'Tried',
  'Feel', 'Feels', 'Feeling', 'Felt',
  'Become', 'Becomes', 'Becoming', 'Became',
  'Tell', 'Tells', 'Telling', 'Told',
  'Think', 'Thinks', 'Thinking', 'Thought',
  'Look', 'Looks', 'Looking', 'Looked',
  
  // Time-related words
  'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 
  'October', 'November', 'December',
  'Today', 'Tomorrow', 'Yesterday', 'Now', 'Then', 'Soon', 'Later',
  'Morning', 'Afternoon', 'Evening', 'Night',
  'Year', 'Years', 'Month', 'Months', 'Week', 'Weeks', 'Day', 'Days', 'Hour', 'Hours',
  'Minute', 'Minutes', 'Second', 'Seconds',
  'Time', 'Period', 'Duration', 'Interval', 'Date', 'Term', 'Quarter', 'Daily', 'Weekly',
  'Monthly', 'Yearly', 'Annual', 'Annually',
  'Spring', 'Summer', 'Fall', 'Autumn', 'Winter',
  'Century', 'Decade',
  
  // Business and technical terms
  'Company', 'Business', 'Corporation', 'Organization', 'Enterprise',
  'Industry', 'Sector', 'Market', 'Economy', 'Finance',
  'Product', 'Service', 'Solution', 'Platform', 'System',
  'Technology', 'Software', 'Hardware', 'Application', 'Interface',
  'Data', 'Information', 'Analytics', 'Intelligence',
  'Strategy', 'Plan', 'Initiative', 'Project', 'Program',
  'Goal', 'Objective', 'Target', 'Result', 'Outcome',
  'Performance', 'Efficiency', 'Effectiveness', 'Productivity',
  'Revenue', 'Profit', 'Loss', 'Cost', 'Expense', 'Budget',
  'Investment', 'Funding', 'Financing', 'Capital',
  'Customer', 'Client', 'Partner', 'Vendor', 'Supplier',
  'Employee', 'Staff', 'Team', 'Group', 'Department',
  'Leadership', 'Management', 'Executive', 'Director', 'Manager',
  'Meeting', 'Conference', 'Call', 'Discussion', 'Presentation',
  'Report', 'Analysis', 'Review', 'Assessment', 'Evaluation',
  'Implementation', 'Deployment', 'Integration', 'Development',
  'Innovation', 'Growth', 'Expansion', 'Improvement',
  'Challenge', 'Issue', 'Problem', 'Concern', 'Risk',
  'Opportunity', 'Advantage', 'Benefit', 'Value',
  'Priority', 'Focus', 'Emphasis', 'Attention',
  'Decision', 'Choice', 'Option', 'Alternative',
  'Process', 'Procedure', 'Method', 'Approach',
  'Agreement', 'Contract', 'Deal', 'Negotiation',
  'Resource', 'Asset', 'Tool', 'Capability',
  'Communication', 'Message', 'Notification', 'Alert',
  'Support', 'Assistance', 'Help', 'Aid',
  'Change', 'Transformation', 'Shift', 'Transition',
  'Quality', 'Standard', 'Specification', 'Requirement',
  'Success', 'Achievement', 'Accomplishment', 'Milestone',
  'Feedback', 'Input', 'Comment', 'Response',
  'Update', 'Status', 'Progress', 'Development',
  'Future', 'Potential', 'Prospect', 'Outlook',
  
  // Specific technical/business terms from your domain
  'M&A', 'OEM', 'Email', 'Interest', 'Potential', 'Strategic', 'Opportunity', 'Opportunities',
  'Partnership', 'Partnerships', 'Integration', 'Project', 'Timeline', 'Forwarded', 'Specifically',
  'Regarding', 'Concerning', 'Discussion', 'Receiving', 'Serious', 'Previously', 'Evaluate',
  'Mentioned', 'Include', 'Including', 'Based', 'Recent', 'Called',
  
  // Numbers and numerical expressions
  'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 
  'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen', 'Twenty',
  'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety', 'Hundred', 'Thousand', 'Million', 'Billion',
  'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth',
  'Once', 'Twice', 'Thrice', 'Single', 'Double', 'Triple', 'Multiple',
  'Few', 'Many', 'Several', 'Some', 'Any', 'All', 'None', 'Half', 'Quarter', 'Whole',
  
  // Quantifiers and determiners
  'Much', 'More', 'Most', 'Less', 'Least', 'Enough', 'Several', 'Few', 'Little',
  'Many', 'Each', 'Every', 'Any', 'Some', 'No', 'All', 'Both', 'Neither', 'Either',
  
  // Question words
  'Why', 'How', 'When', 'Where', 'What', 'Which', 'Who', 'Whom', 'Whose', 'Whether',
  
  // Miscellaneous common terms
  'Yes', 'No', 'Not', 'Maybe', 'Perhaps', 'Possibly', 'Certainly', 'Definitely',
  'Actually', 'Really', 'Simply', 'Just', 'Only', 'Merely', 'Exactly', 'Precisely',
  'Generally', 'Usually', 'Typically', 'Commonly', 'Normally', 'Naturally',
  'Especially', 'Particularly', 'Specifically', 'Notably', 'Remarkably',
  'Etc', 'Also', 'Too', 'Additionally', 'Furthermore', 'Moreover',
  'Instead', 'Otherwise', 'Rather', 'Alternatively', 'Conversely',
  'Finally', 'Lastly', 'Ultimately', 'Eventually', 'Subsequently',
  'Recently', 'Lately', 'Previously', 'Formerly', 'Originally',
  'Currently', 'Presently', 'Now', 'Nowadays', 'Today',
  'Like', 'Such as', 'For example', 'For instance', 'Namely', 'Specifically',
  'Indeed', 'Certainly', 'Definitely', 'Undoubtedly', 'Clearly',
  'Mostly', 'Mainly', 'Primarily', 'Principally', 'Chiefly',
  'Basically', 'Essentially', 'Fundamentally', 'Effectively', 'Virtually',
  'Literally', 'Actually', 'In fact', 'Indeed', 'Truly',
  'Probably', 'Possibly', 'Perhaps', 'Maybe', 'Presumably',
  'Accordingly', 'Consequently', 'Therefore', 'Thus', 'Hence',
  'Nevertheless', 'Nonetheless', 'However', 'Even so', 'Still',
  'Although', 'Though', 'Even though', 'While', 'Whereas',
  'Despite', 'In spite of', 'Regardless of', 'Notwithstanding',
  'Because', 'Since', 'As', 'Given that', 'Owing to',
  'Unless', 'Until', 'While', 'Whilst', 'Whether',
  'Whereby', 'Wherein', 'Thereof', 'Therein', 'Herein',
  'Wherever', 'Whenever', 'Whatever', 'Whichever', 'Whoever',
  'Amongst', 'Amid', 'Along', 'Around', 'Through',
  'Throughout', 'During', 'Within', 'Without', 'Beyond',
  'Besides', 'Except', 'Apart from', 'Other than', 'Aside from',
  'Together', 'Along with', 'As well as', 'In addition to',
  'Rather than', 'Instead of', 'In place of', 'In lieu of'
];

// Convert to Set for faster lookups
const commonWordsSet = new Set(commonWords.map(word => word.toLowerCase()));

const SmartResponseFormatter = ({ text }: { text: string }) => {
  // Extract and process entities from text
  const analysis = useMemo(() => {
    // Determine context type
    const isEmail = /email|sent|forwarded|replied|message|inbox|outbox/i.test(text);
    const isCalendar = /calendar|event|scheduled|appointment|meeting|concert/i.test(text);
    
    // Extract month and date
    const monthMatch = text.match(/\b(January|February|March|April|May|June|July|August|September|October|November|December)\b/i);
    const month = monthMatch ? monthMatch[1] : null;
    
    const dateMatch = text.match(/\b(January|February|March|April|May|June|July|August|September|October|November|December)\s+\d{1,2}(?:st|nd|rd|th)?,\s+\d{4}\b/i) || 
                     text.match(/\b((?:January|February|March|April|May|June|July|August|September|October|November|December))\s+(\d{1,2})(?:,|\s)\s*(\d{4})\b/i);
    const specificDate = dateMatch ? dateMatch[0] : null;
    
    // Extract event title (with various patterns)
    let eventTitle = null;
    if (isCalendar) {
      const patterns = [
        /\bthe\s+([A-Za-z0-9\s']+(?:concert|event|meeting))\b/i,
        /\b(?:concert|event|meeting)\s+(?:titled|called|named)\s+['"]?([^'".,!?]+)['"]?/i,
        /\b(?:concert|event|meeting)\s+(?:with|by|featuring)\s+([A-Za-z0-9\s&']+)\b/i,
        /\b([A-Za-z0-9\s&']+(?:concert|show|performance))\b/i,
        /\bLANE\s*8!/i,  // Special case for "LANE 8!"
        /\bconcert\s+titled\s+['"]?([^'".,!?]+)['"]?/i
      ];
      
      for (const pattern of patterns) {
        const match = text.match(pattern);
        if (match) {
          eventTitle = match[1] ? match[1].trim() : "LANE 8!";
          break;
        }
      }
      
      // Special case direct check for "LANE 8!" if not found by patterns
      if (!eventTitle && text.includes("LANE 8")) {
        eventTitle = "LANE 8!";
      }
    }
    
    // Extract company mentions
    const companyPatterns = [
      /\ba\s+company\s+called\s+([A-Za-z0-9]+)\b/i,
      /\bcompany\s+([A-Za-z0-9]+)\b/i,
      /\b([A-Za-z0-9]+)'s\s+(?:potential|strategic|opportunities)\b/i
    ];
    
    let company = null;
    for (const pattern of companyPatterns) {
      const match = text.match(pattern);
      if (match) {
        company = match[1];
        break;
      }
    }
    
    // Extract people mentions with intelligent filtering
    const potentialPeople = Array.from(text.matchAll(/\b([A-Z][a-z]+(?:\s+[A-Z][a-z]+)*)\b/g))
      .map(match => match[1])
      .filter(name => {
        // Always keep multi-word names (more likely to be people)
        if (name.includes(' ')) return !commonWordsSet.has(name.toLowerCase());
        
        // For single words, check against stop words
        return !commonWordsSet.has(name.toLowerCase());
      });
    
    // Intelligent name resolution for entities with same first name
    const resolveNameEntities = (names: string[]): string[] => {
      if (names.length <= 1) return names;
      
      // Group by first name
      const nameGroups: Record<string, string[]> = {};
      names.forEach(name => {
        const firstName = name.split(' ')[0];
        if (!nameGroups[firstName]) nameGroups[firstName] = [];
        nameGroups[firstName].push(name);
      });
      
      // For each group, keep only the longest/most complete name
      return Object.values(nameGroups).map(group => {
        if (group.length === 1) return group[0];
        
        // Sort by length (descending) to get the most complete form
        return group.sort((a, b) => b.length - a.length)[0];
      });
    };
    
    const people = resolveNameEntities(potentialPeople);
    
    // Extract time information
    const timeMatch = text.match(/(\d{1,2}:\d{2}\s*(?:AM|PM|am|pm))\s+to\s+(\d{1,2}:\d{2}\s*(?:AM|PM|am|pm))/i);
    const timeInfo = timeMatch ? `${timeMatch[1]} to ${timeMatch[2]}` : null;
    
    return {
      isEmail,
      isCalendar,
      month,
      specificDate,
      company,
      people,
      eventTitle,
      timeInfo,
      originalText: text
    };
  }, [text]);
  
  return (
    <Card style={{
      alignItems: 'flex-start',
      alignSelf: 'stretch',
      background: 'var(--colors-bg-primary)',
      border: '1px solid var(--colors-border-secondary)',
      borderRadius: 'var(--radius-xl)',
      boxShadow: '0 1px 2px 0 rgba(16, 24, 40, 0.05)',
      display: 'flex',
      flexDirection: 'column',
      margin: '24px 0',
      minHeight: '40px',
      padding: '24px',
      maxWidth: '510px',
    }}>
      {/* Header with document/month */}
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: '12px',
        marginBottom: '16px'
      }}>
        <DocumentIcon />
        <span style={{ 
          fontSize: '16px',
          fontWeight: 500,
          color: 'var(--colors-text-text-primary-900)'
        }}>
          {analysis.month || 'Document'}
        </span>
      </div>
      
      {/* Date with calendar icon */}
      {analysis.specificDate && (
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '8px',
          color: 'var(--Colors-Adapter-Purple-500)',
          marginBottom: '24px',
          fontSize: '16px',
          fontWeight: 500
        }}>
          <CalendarIcon />
          {analysis.specificDate}
          {analysis.timeInfo && ` • ${analysis.timeInfo}`}
        </div>
      )}
      
      {/* People mentioned */}
      {analysis.people.length > 0 && (
        <div style={{ marginBottom: '24px' }}>
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: '8px',
            color: 'var(--colors-text-text-tertiary-600)',
            marginBottom: '12px',
            fontSize: '14px'
          }}>
            <EmailIcon />
            <span>Pivot Points:</span>
          </div>
          
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
            {analysis.people.map((person, index) => (
              <span key={index} style={{
                padding: '6px 12px',
                borderRadius: '20px',
                backgroundColor: 'var(--Echo-100)',
                color: 'var(--Echo-950)',
                fontSize: '14px',
              }}>
                {person}
              </span>
            ))}
          </div>
        </div>
      )}
      
      {/* Original text */}
      <div style={{ 
        fontSize: '14px', 
        lineHeight: '1.6', 
        color: 'var(--colors-text-text-secondary-700)' 
      }}>
        {analysis.originalText}
      </div>
    </Card>
  );
};

export default SmartResponseFormatter;