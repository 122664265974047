import React, { useState, useRef, useEffect } from 'react';

import Button from 'components/common/Button';
import { Text } from 'components/common/Typography';

const PlaybackControl = ({
    playerTimestamp,
    startTimestamp,
    endTimestamp,
    onTimeChange,
    onPlayPause = () => { },
    onSeek = (n) => { },
    isPlaying = false,
    filters,
    onFilterChange,
    onZoomOut
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [localTimestamp, setLocalTimestamp] = useState(playerTimestamp);
    const progressRef = useRef(null);
    const localTimestampRef = useRef(localTimestamp);
    const elRef = useRef(null);

    // Keep the ref updated with the latest value
    useEffect(() => {
        localTimestampRef.current = localTimestamp;
    }, [localTimestamp]);

    // Calculate progress percentage
    const duration = endTimestamp - startTimestamp;
    const progressPercent = ((localTimestamp - startTimestamp) / duration) * 100;

    // Update local timestamp when playerTimestamp changes (if not dragging)
    useEffect(() => {
        if (!isDragging) {
            setLocalTimestamp(playerTimestamp);
        }
    }, [playerTimestamp, isDragging]);

    // Handle mouse down on thumb
    const handleMouseDown = (e) => {
        setIsDragging(true);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    // Handle mouse move while dragging
    const handleMouseMove = (e) => {
        if (progressRef.current && isDragging) {
            const rect = progressRef.current.getBoundingClientRect();
            const position = (e.clientX - rect.left) / rect.width;
            const clampedPosition = Math.max(0, Math.min(1, position));
            const newTimestamp = startTimestamp + (clampedPosition * duration);
            setLocalTimestamp(newTimestamp);
        }
    };

    // Handle mouse up to stop dragging
    const handleMouseUp = (e) => {
        if (isDragging) {
            // Use the ref to get the most current value
            onSeek(localTimestampRef.current);
            setIsDragging(false);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    };

    // Handle click on progress bar
    const handleProgressClick = (e) => {
        if (progressRef.current) {
            const rect = progressRef.current.getBoundingClientRect();
            const position = (e.clientX - rect.left) / rect.width;
            const clampedPosition = Math.max(0, Math.min(1, position));
            const newTimestamp = startTimestamp + (clampedPosition * duration);
            setLocalTimestamp(newTimestamp);
            onSeek(newTimestamp);
        }
    };

    // date inputs expect YYYY-MM-DD, but we're storing state as number (ms since epoch)
    const startDateDisplay = new Date(startTimestamp).toISOString().split('T')[0];
    const endDateDisplay = new Date(endTimestamp).toISOString().split('T')[0];

    return (
        <div ref={elRef} className="bg-gray-100 rounded-lg p-4 shadow-md">

            <div className='flex flex-row gap-[10px] items-center mb-[10px]'>
                <input
                    type="date" 
                    name="start_date"
                    value={startDateDisplay} 
                    onChange={onTimeChange} 
                />

                <div className='text-center text-gray-900 flex-1'>
                    <Text>{new Date(localTimestamp).toLocaleString()}</Text>
                </div>

                <input
                    type="date" 
                    name="end_date"
                    value={endDateDisplay} 
                    onChange={onTimeChange} 
                />
            </div>

            <div className="flex items-center mb-2">
                <button
                    onClick={onPlayPause}
                    className="w-10 h-10 bg-blue-600 rounded-full flex items-center justify-center text-white focus:outline-none hover:bg-blue-700"
                >
                    {isPlaying ? (
                        <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
                            <rect x="6" y="4" width="4" height="16" />
                            <rect x="14" y="4" width="4" height="16" />
                        </svg>
                    ) : (
                        <svg className="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M8 5v14l11-7z" />
                        </svg>
                    )}
                </button>

                <div className="flex-grow mx-4 relative w-[100px]">
                    <div
                        ref={progressRef}
                        className="bg-gray-300 h-2 rounded-full cursor-pointer"
                        onClick={handleProgressClick}
                    >
                        <div
                            className="bg-blue-600 h-2 rounded-full"
                            style={{ width: `${progressPercent}%` }}
                        ></div>

                        <div
                            className={`absolute top-1/2 transform -translate-y-1/2 w-4 h-4 bg-white border-2 border-blue-600 rounded-full shadow-md cursor-grab ${isDragging ? 'cursor-grabbing' : ''}`}
                            style={{ left: `calc(${progressPercent}% - 0.5rem)` }}
                            onMouseDown={handleMouseDown}
                        ></div>
                    </div>
                </div>

                <button
                    onClick={onZoomOut}
                    className="w-10 h-10 bg-blue-600 rounded-full flex items-center justify-center text-white focus:outline-none hover:bg-blue-700"
                >
                    <i className='material-icons'>zoom_out</i>
                </button>
            </div>

            <div className='flex flex-row gap-[10px] legend-buttons'>
                <Button size="small" cls={filters.calendar ? 'filterOn' : ''} onClick={() => onFilterChange('calendar')}>
                    <span className="circle calendar"></span>
                    Calendar Events
                </Button>

                <Button size="small" cls={filters.flight ? 'filterOn' : ''} onClick={() => onFilterChange('flight')}>
                    <span className="circle flight"></span>
                    Flights
                </Button>

                <Button size="small" cls={filters.train ? 'filterOn' : ''} onClick={() => onFilterChange('train')}>
                    <span className="circle train"></span>
                    Trains
                </Button>

                <Button size="small" cls={filters.car ? 'filterOn' : ''} onClick={() => onFilterChange('car')}>
                    <span className="circle car"></span>
                    Cars
                </Button>

                <Button size="small" cls={filters.restaurant ? 'filterOn' : ''} onClick={() => onFilterChange('restaurant')}>
                    <span className="circle restaurant"></span>
                    Restaurants
                </Button>

                <Button size="small" cls={filters.hotel ? 'filterOn' : ''} onClick={() => onFilterChange('hotel')}>
                    <span className="circle hotel"></span>
                    Hotels
                </Button>

                <Button size="small" cls={filters.purchase ? 'filterOn' : ''} onClick={() => onFilterChange('purchase')}>
                    <span className="circle purchase"></span>
                    Purchases
                </Button>
            </div>
        </div>
    );
};

export default PlaybackControl;