import React, { useEffect, useCallback, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { GraphProvider } from './context/GraphContext';
import { Container, CSPMetadataStyle, CustomTabsStyle } from './styles/KnowledgeGraphExplorerStyles';
import { useKnowledgeGraphState } from './hooks/state/useKnowledgeGraphState';
import * as handlers from './handlers/graphEventHandlers';
import LeftSidebar from './components/LeftSidebar';
import RightSidebar from './components/RightSidebar';
import GraphDisplay from './components/GraphDisplay';
import BottomPanel from './components/BottomPanel/index';
import SearchHistoryButton from './common/SearchHistoryButton';
import SearchHistoryPanel from './common/SearchHistoryPanel';
import { searchHistoryService } from './services/SearchHistoryService';
import { SearchHistoryEntry } from './types/types';

const KnowledgeGraphExplorer: React.FC = () => {
  // Get state from custom hook
  const {
    // User experience state
    showTour,
    setShowTour,
    showWelcome,
    setShowWelcome,
    viewMode,
    setViewMode,
    
    // Theme state
    darkMode,
    setDarkMode,
    
    // Panel state
    leftPanelCollapsed,
    setLeftPanelCollapsed,
    rightPanelCollapsed,
    setRightPanelCollapsed,
    isDetailPanelExpanded,
    setIsDetailPanelExpanded,
    activeTab,
    setActiveTab,
    
    // Selected node state
    selectedNode,
    setSelectedNode,
    connectedNodes,
    setConnectedNodes,
    connectedEdges,
    setConnectedEdges,
    
    // Graph data state
    nodes,
    setNodes,
    edges,
    setEdges,
    filteredNodes,
    setFilteredNodes,
    filteredEdges,
    setFilteredEdges,
    
    // Filter state
    selectedNodeTypes,
    setSelectedNodeTypes,
    selectedEdgeTypes,
    setSelectedEdgeTypes,
    maxHops,
    setMaxHops,
    searchQuery,
    setSearchQuery,
    
    // Voice processing state
    isProcessingVoice,
    setIsProcessingVoice,
    humanSummary,
    setHumanSummary: originalSetHumanSummary, // Rename for our patched version
    searchResultNodeCount,
    setSearchResultNodeCount,
    searchResultEdgeCount,
    setSearchResultEdgeCount,
    searchResultCommunityCount,
    setSearchResultCommunityCount,
    
    // Community state
    communities,
    setCommunities,
    communityDetails,
    setCommunityDetails,
    communityInsights,
    setCommunityInsights,
    suggestedQueries,
    setSuggestedQueries,
    isLoadingCommunities,
    setIsLoadingCommunities,
    isLoadingInsights,
    setIsLoadingInsights,
    isLoadingSuggestions,
    setIsLoadingSuggestions,
    
    // Data loading state
    isLoadingData,
    setIsLoadingData,
    loadError,
    setLoadError,
    
    // Performance monitoring
    fps,
    setFps,
    lastQueryTime,
    setLastQueryTime,
    
    // Focused view data
    focusedNodes,
    focusedEdges,
    communityFocus,
    
    // AI Service
    aiService,
    
    // Graph context value
    graphContextValue
  } = useKnowledgeGraphState();

  // Listen for dark mode toggle events
  useEffect(() => {
    const handleDarkModeToggle = (event: Event) => {
      const customEvent = event as CustomEvent;
      if (customEvent.detail && typeof customEvent.detail.newMode === 'boolean') {
        const newMode = customEvent.detail.newMode;
        setDarkMode(newMode);
        localStorage.setItem('darkMode', String(newMode));
      }
    };
    
    document.addEventListener('toggleDarkMode', handleDarkModeToggle);
    
    return () => {
      document.removeEventListener('toggleDarkMode', handleDarkModeToggle);
    };
  }, [setDarkMode]);

  // Search history state
  const [isHistoryPanelVisible, setIsHistoryPanelVisible] = useState(false);
  const [historyEntryCount, setHistoryEntryCount] = useState(0);
  const [historyEntries, setHistoryEntries] = useState<SearchHistoryEntry[]>([]);
  
  // Create a patched version of setHumanSummary that captures the summary for history
  const setHumanSummary = useCallback((summary: string) => {
    // Call the original setter
    originalSetHumanSummary(summary);
    
    // Store in window global for immediate access
    (window as any).__lastHumanSummary = summary;
    
    // If we have a hook waiting, call it with the summary
    if (typeof (window as any).onHumanSummarySet === 'function') {
      (window as any).onHumanSummarySet(summary);
    }
  }, [originalSetHumanSummary]);
  
  // Load history entries when component mounts or when entries change
  useEffect(() => {
    const entries = searchHistoryService.getEntries();
    setHistoryEntryCount(entries.length);
    setHistoryEntries(entries);
    
    console.log(`[Explorer] Loaded ${entries.length} history entries`);
  }, []);
  
  // Handle selecting a history entry
  const handleSelectHistoryEntry = (entry: SearchHistoryEntry) => {
    // Debug the entry first
    console.log(`Selecting history entry: ${entry.id}`, {
      query: entry.query,
      importantNodeIds: entry.importantNodesById?.length || 0,
      summary: entry.humanSummary,
      communityCount: entry.communityCount || 0
    });
    
    // Check if the entry has valid data (just needs importantNodesById)
    if (!entry.importantNodesById || entry.importantNodesById.length === 0) {
      console.error("Invalid search history entry, missing important node IDs");
      return;
    }
    
    // First simulate the search context by setting the window global that tracks search state
    (window as any).__hasActiveSearch = true;
    
    // Set search query in a global for components that need to know there's an active search
    (window as any).__activeSearchQuery = entry.query;
    
    // Set the important nodes - this is the key element for restoring the search
    (window as any).__importantNodeIds = [...entry.importantNodesById];
    
    console.log(`Restoring search with ${entry.importantNodesById.length} important nodes from history`);
    
    // Highlight just the important nodes in the graph
    // Find nodes that match the important node IDs
    const highlightedNodes = nodes.filter(node => 
      entry.importantNodesById.includes(node.id)
    );
    
    // Also get their direct connections
    const connectedNodeIds = new Set<string>();
    edges.forEach(edge => {
      const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source;
      const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target;
      
      if (entry.importantNodesById.includes(sourceId)) {
        connectedNodeIds.add(targetId);
      }
      if (entry.importantNodesById.includes(targetId)) {
        connectedNodeIds.add(sourceId);
      }
    });
    
    // Create the final set of nodes to display
    const relevantNodes = [
      ...highlightedNodes,
      ...nodes.filter(node => connectedNodeIds.has(node.id))
    ];
    
    // Find edges between these nodes
    const nodeIdsSet = new Set([
      ...entry.importantNodesById,
      ...connectedNodeIds
    ]);
    
    const relevantEdges = edges.filter(edge => {
      const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source;
      const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target;
      return nodeIdsSet.has(sourceId) && nodeIdsSet.has(targetId);
    });
    
    // Mark important nodes with highlighted property
    const enhancedNodes = relevantNodes.map(node => {
      const isImportant = entry.importantNodesById.includes(node.id);
      return {
        ...node,
        highlighted: isImportant,
        importance: isImportant ? 1.0 : 0
      };
    });
    
    console.log(`Reconstructed search with ${enhancedNodes.length} nodes and ${relevantEdges.length} edges`);
    
    // Update the state with our reconstructed search
    setFilteredNodes(enhancedNodes);
    setFilteredEdges(relevantEdges);
    
    // Update node and edge types based on the reconstructed nodes
    const nodeTypes = new Set<string>();
    const edgeTypes = new Set<string>();
    
    enhancedNodes.forEach(node => {
      if (node.type) nodeTypes.add(node.type);
    });
    
    relevantEdges.forEach(edge => {
      if (edge.type) edgeTypes.add(edge.type);
    });
    
    // Update the node and edge type filters
    setSelectedNodeTypes(Array.from(nodeTypes));
    setSelectedEdgeTypes(Array.from(edgeTypes));
    
    // Update search result stats
    setSearchResultNodeCount(enhancedNodes.length);
    setSearchResultEdgeCount(relevantEdges.length);
    setSearchResultCommunityCount(1); // Just assume 1 community for these focused results
    
    // Close the history panel
    setIsHistoryPanelVisible(false);
    
    // Update the last query time to trigger a graph re-render
    setLastQueryTime(Date.now());
    
    // Set search query state to let components know we have an active search
    // Note: We don't call setSearchQuery directly to avoid triggering another search
    
    // Set human summary last to ensure the bottom panel receives all the necessary data before expanding
    // Use setTimeout to ensure this happens in a separate render cycle
    setTimeout(() => {
      // Force __hasActiveSearch again just to be sure it wasn't reset by another component
      (window as any).__hasActiveSearch = true;
      (window as any).__activeSearchQuery = entry.query;
      
      // Create a one-time flag to force panel expansion
      (window as any).__forceExpandPanel = true;
      
      // Then set the human summary which will trigger the bottom panel
      setHumanSummary(entry.humanSummary);
      
      console.log(`Search history restoration complete for "${entry.query}" with ${enhancedNodes.length} nodes`);
    }, 50);
  };
  
  // Add entry to search history
  const addToSearchHistory = (
    query: string, 
    humanSummary: string, 
    importantNodesById: string[]
  ) => {
    try {
      // Calculate community count if needed
      let communityCount = 0;
      if (filteredNodes && filteredNodes.length > 0) {
        const communities = new Set<string | number>();
        filteredNodes.forEach(node => {
          if (node.community !== undefined) {
            communities.add(node.community);
          }
        });
        communityCount = communities.size;
      }
      
      searchHistoryService.addEntry(
        query,
        humanSummary,
        importantNodesById,
        communityCount
      );
      
      // Update the count
      setHistoryEntryCount(searchHistoryService.getEntries().length);
    } catch (error) {
      console.error('Error adding search to history:', error);
    }
  };
  
  // Memoize theme to prevent rerenders when darkMode doesn't change
  const theme = React.useMemo(() => {
    // Import the theme configuration
    const themeConfig = require('./config/themeConfig').default;
    return themeConfig.getTheme(darkMode);
  }, [darkMode]);
  
  // Handler functions memoized with useCallback to prevent unnecessary rerenders
  const handleNodeClickWrapped = useCallback((node: any) => 
    handlers.handleNodeClick(
      node,
      edges,
      nodes,
      rightPanelCollapsed,
      setSelectedNode,
      setConnectedNodes,
      setConnectedEdges,
      setRightPanelCollapsed
    ), [edges, nodes, rightPanelCollapsed, setSelectedNode, setConnectedNodes, setConnectedEdges, setRightPanelCollapsed]);
  
  const handleNodeExpandWrapped = (node: any) => 
    handlers.handleNodeExpand(node, setNodes);
  
  const handleHideNodeWrapped = (nodeId: string) => 
    handlers.handleHideNode(
      nodeId,
      selectedNode,
      setFilteredNodes,
      setFilteredEdges,
      setSelectedNode,
      setConnectedNodes,
      setConnectedEdges
    );
  
  const handleSearchWrapped = (query: string) => 
    handlers.handleSearch(
      query,
      nodes,
      edges,
      filteredNodes,
      filteredEdges,
      setFilteredNodes,
      setFilteredEdges
    );
  
  const handleAdvancedSearchWrapped = async (query: string) => {
    // Save the current timestamp to detect when the search completes
    const searchStartTime = Date.now();
    
    // Cache the human summary from the window before the search
    const originalHumanSummary = (window as any).__lastHumanSummary;
    
    // Call the handler which will update state via the setter functions
    await handlers.handleAdvancedSearch(
      query,
      nodes,
      edges,
      aiService,
      handleSearchWrapped,
      setIsProcessingVoice,
      setFilteredNodes,
      setFilteredEdges,
      setSelectedNodeTypes,
      setSelectedEdgeTypes,
      setHumanSummary,
      setSearchResultNodeCount,
      setSearchResultEdgeCount,
      setSearchResultCommunityCount,
      setLastQueryTime
    );
    
    // Set a hook for when the human summary is updated
    // We use a custom global to capture the direct summary 
    // since it might be passed directly to setHumanSummary
    (window as any).onHumanSummarySet = (directSummary: string) => {
      // Clear the hook
      (window as any).onHumanSummarySet = null;
      
      // Cache the direct summary in a window global for immediate access
      (window as any).__lastHumanSummary = directSummary;
      
      // Set flag to ensure the panel expands when we have search results
      (window as any).__forceExpandPanel = true;
      
      console.log(`Captured direct human summary: "${directSummary}"`);
      
      // Get the important nodes
      const importantNodesToSave = (window as any).__importantNodeIds || [];
      
      if (importantNodesToSave.length > 0 && directSummary) {
        console.log(`Saving search with ${importantNodesToSave.length} important node IDs`);
        
        // Use the direct summary
        addToSearchHistory(
          query,
          directSummary,
          importantNodesToSave
        );
      }
    };
    
    // After a reasonable timeout, clean up and use fallback if needed
    setTimeout(() => {
      // Clear the hook if it wasn't triggered
      (window as any).onHumanSummarySet = null;
      
      // Check if we have filtered nodes but no history was saved
      if (filteredNodes && filteredNodes.length > 0) {
        // Get the current human summary - either from state or window cache
        const latestHumanSummary = (window as any).__lastHumanSummary;
        const summaryToSave = latestHumanSummary || humanSummary || "Search results";
        
        // If the summary has changed, we have a new search result
        if (summaryToSave !== originalHumanSummary) {
          // Set one-time flag to force panel expansion
          (window as any).__forceExpandPanel = true;
          
          const importantNodesToSave = (window as any).__importantNodeIds || [];
          
          if (importantNodesToSave.length > 0) {
            console.log(`Fallback: Adding search to history: "${query}"`);
            
            // Add to search history with just the important node IDs and query
            addToSearchHistory(
              query,
              summaryToSave,
              importantNodesToSave
            );
          } else {
            console.log(`No important nodes found for search "${query}", not saving to history`);
          }
        }
      }
    }, 1000); // Longer delay to ensure all updates have completed
  };
  
  const handleFilterWrapped = (filters: any) => 
    handlers.handleFilter(
      filters,
      nodes,
      edges,
      setSelectedNodeTypes,
      setSelectedEdgeTypes,
      setFilteredNodes,
      setFilteredEdges
    );
  
  const handleMaxHopsChangeWrapped = (hops: number) => 
    handlers.handleMaxHopsChange(
      hops,
      maxHops,
      filteredNodes,
      setMaxHops,
      setFilteredNodes,
      setFilteredEdges
    );
  
  const handleVoiceCommandWrapped = async (query: string) => {
    const result = await handlers.handleAdvancedSearch(
      query,
      nodes,
      edges,
      aiService,
      handleSearchWrapped,
      setIsProcessingVoice,
      setFilteredNodes,
      setFilteredEdges,
      setSelectedNodeTypes,
      setSelectedEdgeTypes,
      setHumanSummary,
      setSearchResultNodeCount,
      setSearchResultEdgeCount,
      setSearchResultCommunityCount,
      setLastQueryTime
    );
    
    // After search completes, add to history (same as for regular search)
    const importantNodesById = (window as any).__importantNodeIds || [];
    const currentHumanSummary = humanSummary || "Search results";
    
    // Add to search history if we have results
    if (filteredNodes.length > 0) {
      addToSearchHistory(
        query,
        currentHumanSummary,
        importantNodesById
      );
    }
    
    return result;
  };
  
  const toggleLeftPanelWrapped = () => 
    setLeftPanelCollapsed(prev => !prev);
  
  const toggleRightPanelWrapped = () => {
    setRightPanelCollapsed(prev => !prev);
    setIsDetailPanelExpanded(false);
  };
  
  const toggleDetailPanelWrapped = () => 
    setIsDetailPanelExpanded(prev => !prev);
  
  const handleHighlightCommunityWrapped = (communityId: string) => 
    handlers.handleHighlightCommunity(
      communityId,
      communities,
      nodes,
      edges,
      setFilteredNodes,
      setFilteredEdges
    );
  
  const getNodeCommunityColorWrapped = useCallback((nodeId: string, options?: { searchResults?: boolean }): string => 
    handlers.getNodeCommunityColor(nodeId, communities, options), [communities]);

  return (
    <ThemeProvider theme={theme}>
      <CSPMetadataStyle />
      <CustomTabsStyle />
      <Container>
        <GraphProvider value={graphContextValue}>
          {/* Search History Panel */}
          <SearchHistoryPanel
            darkMode={darkMode}
            onSelectEntry={handleSelectHistoryEntry}
            onClose={() => setIsHistoryPanelVisible(false)}
            isVisible={isHistoryPanelVisible}
            historyEntries={historyEntries}
            refreshEntries={() => {
              // Don't refresh if the panel is not visible to prevent loops
              if (isHistoryPanelVisible) {
                console.log("[Explorer] Refreshing history entries");
                const entries = searchHistoryService.getEntries();
                setHistoryEntries(entries);
                setHistoryEntryCount(entries.length);
              }
            }}
          />
          
          <GraphDisplay 
            // Pass props directly to ensure the graph has access to the latest data
            nodes={nodes}
            edges={edges}
            filteredNodes={filteredNodes}
            filteredEdges={filteredEdges}
            selectedNode={selectedNode}
            onNodeClick={handleNodeClickWrapped}
            darkMode={darkMode}
            fps={fps}
            lastQueryTime={lastQueryTime}
            isProcessingVoice={isProcessingVoice}
            getNodeCommunityColor={getNodeCommunityColorWrapped}
            communities={communities}
            isLoadingData={isLoadingData}
          />
          
          <LeftSidebar 
            isCollapsed={leftPanelCollapsed}
            onCollapseToggle={toggleLeftPanelWrapped}
            darkMode={darkMode}
            activeTab={activeTab}
            onTabChange={setActiveTab}
            onSearch={handleSearchWrapped}
            onAdvancedSearch={handleAdvancedSearchWrapped}
            onFilter={handleFilterWrapped}
            onVoiceCommand={handleVoiceCommandWrapped}
            isProcessingVoice={isProcessingVoice}
            maxHops={maxHops}
            onMaxHopsChange={handleMaxHopsChangeWrapped}
            communityDetails={communityDetails}
            suggestedQueries={suggestedQueries}
            isLoadingCommunities={isLoadingCommunities}
            isLoadingInsights={isLoadingInsights}
            isLoadingSuggestions={isLoadingSuggestions}
            entityTypes={selectedNodeTypes}
            relationshipTypes={selectedEdgeTypes}
            onHighlightCommunity={handleHighlightCommunityWrapped}
            historyEntryCount={historyEntryCount}
            onShowSearchHistory={() => setIsHistoryPanelVisible(true)}
          />
          
          {selectedNode && (
            <RightSidebar 
              isCollapsed={rightPanelCollapsed}
              onCollapseToggle={toggleRightPanelWrapped}
              selectedNode={selectedNode}
              connectedNodes={connectedNodes}
              connectedEdges={connectedEdges}
              darkMode={darkMode}
              onNodeClick={handleNodeClickWrapped}
              onExpandNode={handleNodeExpandWrapped}
              onHideNode={handleHideNodeWrapped}
              isDetailPanelExpanded={isDetailPanelExpanded}
              onToggleDetailPanel={toggleDetailPanelWrapped}
            />
          )}
          
          <BottomPanel 
            humanSummary={humanSummary}
            nodeCount={searchResultNodeCount}
            edgeCount={searchResultEdgeCount}
            communityCount={searchResultCommunityCount}
          />
        </GraphProvider>
      </Container>
    </ThemeProvider>
  );
};

export default KnowledgeGraphExplorer;