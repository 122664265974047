import storageBridge from "./StorageBridge";

// Define specific types for each storage key
interface StorageKeyTypes {
  'ADAPTER_lastKnownLocation': string;
  'ADAPTER_leftNavCollapse': boolean;
  'jwt_refresh': string;
  'jwt_access': string;
}

// Type-safe storage keys
export const StorageKeys = {
  JWT_ACCESS_TOKEN: 'jwt_access',
  JWT_REFRESH_TOKEN: 'jwt_refresh',
  leftNavCollapse: 'ADAPTER_leftNavCollapse',
  lastKnownLocation: 'ADAPTER_lastKnownLocation'
} as const;

// Create a type that maps from our key names to their literal string values
type StorageKeyMap = typeof StorageKeys;
type StorageKey = StorageKeyMap[keyof StorageKeyMap];

// Save with type checking
export async function saveToLocalStorage<K extends StorageKey>(
  key: K,
  value: StorageKeyTypes[K]
): Promise<void> {
  await storageBridge.setItem(key, value);
}

// Get with type checking
export async function getFromLocalStorage<K extends StorageKey>(
  key: K
): Promise<StorageKeyTypes[K] | null> {
  return storageBridge.getItem<StorageKeyTypes[K]>(key);
}

// Delete with type checking
export async function deleteFromLocalStorage(key: StorageKey): Promise<void> {
  await storageBridge.removeItem(key);
}

// Clear storage and cookies
export async function clearLocalStorage(): Promise<void> {
  await storageBridge.clear();
  clearAllCookies();
}

// Helper to clear cookies
function clearAllCookies(): void {
  const cookies = document.cookie.split(";");
  
  for (const cookie of cookies) {
    const [name] = cookie.split("=").map(c => c.trim());
    if (name) {
      deleteCookie(name);
    }
  }
}

function deleteCookie(name: string): void {
  const expires = new Date(0).toUTCString();
  document.cookie = `${name}=; expires=${expires}; path=/`;
  document.cookie = `${name}=; expires=${expires}; path=/acc/html`;
}