import { useState } from 'react';
import { inject, observer } from 'mobx-react';
import Button from 'components/common/Button';
import NLComprehension from 'components/common/NLComprehension';
import { usePostHog } from 'util/posthogWrapper';
import SmartResponseFormatter from './SmartResponseFormatter/index';

const QueryOption = ({ text, NLPStore }: { text: string, NLPStore?: any }) => {
    const [submittedWrong, setSubmittedWrong] = useState(false);
    const [submittedParsing, setSubmittedParsing] = useState(false);

    const posthog = usePostHog()

    const wrongResultsHandler = () => {
        NLPStore.giveFeedback("UserFeedbackWrongResults");
        posthog.capture("query feedback", { feedback: 'wrong results' });
        setSubmittedWrong(true);
    }

    const incorrectParsingHandler = () => {
        NLPStore.giveFeedback("UserFeedbackIncorrectQueryParsing");
        posthog.capture("query feedback", { feedback: 'incorrect parsing' });
        setSubmittedParsing(true);
    }

    return (
        <>
            <NLComprehension />

            <SmartResponseFormatter text={text} />

            <div className="nl-feedback" style={{
                display: 'flex',
                gap: '12px',
                marginTop: '8px'
            }}>
                <Button
                    disabled={submittedWrong}
                    onClick={wrongResultsHandler}
                >Wrong Results</Button>

                <Button
                    disabled={submittedParsing}
                    onClick={incorrectParsingHandler}
                >Incorrect query parsing</Button>
            </div>
        </>
    );
};

export default inject("NLPStore")(observer(QueryOption));