import React from 'react';

const Adapter = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48" fill="none">
        <g clipPath="url(#clip0_8_1825)">
            <path
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M27.2293 0C30.0871 0 32.7279 1.52464 34.1568 3.99959L52.6329 36.0012C54.0618 38.4762 54.0618 41.5255 52.6329 44.0004C51.204 46.4754 48.5633 48 45.7054 48H8.75308C5.89525 48 3.25451 46.4754 1.82559 44.0004C0.396677 41.5255 0.396677 38.4762 1.82559 36.0012L20.3018 3.99959C21.7307 1.52464 24.3715 0 27.2293 0ZM31.2289 7.99917C31.2289 10.2081 29.4382 11.9988 27.2293 11.9988C25.0204 11.9988 23.2297 10.2081 23.2297 7.99917C23.2297 5.79026 25.0204 3.99958 27.2293 3.99958C29.4382 3.99958 31.2289 5.79026 31.2289 7.99917ZM12.7527 40.0017C12.7527 42.2111 10.962 44.0022 8.75307 44.0022C6.54416 44.0022 4.75348 42.2111 4.75348 40.0017C4.75348 37.7923 6.54416 36.0013 8.75307 36.0013C10.962 36.0013 12.7527 37.7923 12.7527 40.0017ZM49.7059 40.0009C49.7059 42.2098 47.9152 44.0005 45.7063 44.0005C43.4974 44.0005 41.7067 42.2098 41.7067 40.0009C41.7067 37.7919 43.4974 36.0013 45.7063 36.0013C47.9152 36.0013 49.7059 37.7919 49.7059 40.0009Z" 
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_8_1825">
                <rect width="54" height="48" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default Adapter;