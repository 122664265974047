import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import AuthenticatedPage from 'components/common/AuthenticatedPage';
import Button from 'components/common/Button';
import { H2Text, H3Text, Text } from 'components/common/Typography';
import ImportingData from 'components/stats/ImportingData';
import Card from 'components/common/Card';
import Flexagon from 'components/delight/Flexagon';

import './oauth_redirect.scss';

// Types
interface AuthStore {
  oauth: (params: { code: string | null; error: string | null }) => Promise<boolean>;
}

interface OAuthRedirectProps {
  AuthStore?: AuthStore;
}

// Components
const ErrorView: React.FC<{ onRetry: () => void }> = ({ onRetry }) => (
  <div className='flex flex-col items-center gap-[20px]'>
    <div className="flex-1"></div>

    <H2Text>
      Google Permission Error
    </H2Text>

    <Text>Unable to connect account. Please try again.</Text>
    <Text>This commonly happens when Adapter is not given enough permissions.</Text>


    <Button size="2xl" onClick={onRetry}>
      Retry
    </Button>
    <div className="flex-1"></div>
  </div>
);

const StatsView: React.FC = () => {
  const [intervalCount, setInteralCount] = useState(0);

  useEffect(() => {
    const onInterval = () => {
      if (intervalCount > 5) {
        window.location.href = '/dashboard';
      } else {
        console.log('interval: ', intervalCount)
        setInteralCount(intervalCount + 1);
      }
    };

    setTimeout(onInterval, 1_000);
  }, [intervalCount, setInteralCount]);

  return (
    <div className="adapter-two-col">

      <div className="adapter-two-col--title">
        <div className="flex-1"></div>

        <H3Text>
          Importing account data...
        </H3Text>

        <Text>
          Retrieving, categorizing, and processing your data for events, insights, and preferences.
        </Text>

        <Text>
          Depending on the size of your inbox, this may take a few minutes to complete.
          In the meantime, you can continue to interact with Adapter!
        </Text>

        <div className="flex-1"></div>
      </div>

      <div className="adapter-two-col--content" style={{ alignItems: 'center' }}>
        <div className="flex-1"></div>

        <div className='flex flex-col items-center'>
          <div className="flex flex-col gap-[20px]">
            <Flexagon />

            <Button size="2xl" onClick={() => window.location.href = "/dashboard"}>
              Continue &rarr;
            </Button>
          </div>
        </div>

        <div className="flex-1"></div>
      </div>

    </div>
  );
}

const LoadingView: React.FC = () => (
  <>
    <div className="flex-1" />
    <ImportingData />
    <div className="flex-1" />
  </>
);

// Main Component
const OAuthRedirect = ({ AuthStore }: OAuthRedirectProps): JSX.Element => {
  const [state, setState] = useState({
    oauthError: false,
    showStats: false,
  });

  const handleRetry = () => {
    window.location.href = '/settings/accounts';
  };

  useEffect(() => {
    const handleOAuth = async () => {
      if (!AuthStore) {
        console.error('AuthStore is required');
        return;
      }

      try {
        setState({ oauthError: false, showStats: false });

        const searchParams = new URL(window.location.href).searchParams;
        const code = searchParams.get('code');
        const error = searchParams.get('error');

        const isReconnect = await AuthStore.oauth({ code, error });

        if (isReconnect) {
          window.location.href = '/dashboard';
          return;
        }

        setState({ oauthError: false, showStats: true });
      } catch (err) {
        console.error("OAuth error:", err);
        setState({ oauthError: true, showStats: false });
      }
    };

    handleOAuth();
  }, [AuthStore, setState]);

  const { oauthError, showStats } = state;

  return (
    <main className="adapter-basepage">
      <div className="adapter-basepage-inner">
        <div className="adapter-fullscreen-content">
          <div className="flex-1" />

          <div className="adapter-onboarding">
            {oauthError && <ErrorView onRetry={handleRetry} />}
            {!oauthError && showStats && <Card><StatsView /></Card>}
            {!oauthError && !showStats && <Card><LoadingView /></Card>}
          </div>

          <div className="flex-1" />
        </div>
      </div>
    </main>
  );
};

// Wrap with MobX injection
const OAuthRedirectWithStores = inject("AuthStore")(observer(OAuthRedirect));

// Wrap with AuthenticatedPage
const OAuthRedirectWrapper = () => (
  <AuthenticatedPage>
    <OAuthRedirectWithStores />
  </AuthenticatedPage>
);

export default OAuthRedirectWrapper;