import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const MARGIN = 30; // Reduced margin to allow nodes to spread more
const MIN_DISTANCE = 60; // Minimum distance between nodes
const MAX_DISTANCE = 220; // Maximum distance from center
const SIZE_MULTIPLIER = 2;
const MAX_NODES = 33;
const BASE_NODE_SIZE = 16;
const MIN_NODE_SIZE = 16;
const CENTER_NODE_SIZE = 48;
const CONTAINER_SIZE = 300;

const gradientShift = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const pulseGlow = keyframes`
  0% { box-shadow: 0 0 20px rgba(110, 86, 207, 0.3); }
  50% { box-shadow: 0 0 30px rgba(110, 86, 207, 0.5); }
  100% { box-shadow: 0 0 20px rgba(110, 86, 207, 0.3); }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  gap: 2rem; // Increased gap between elements
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.05) 100%
  );
  backdrop-filter: blur(10px);
  border-radius: 20px;
  min-height: 500px; // Ensure consistent height
`;

const MapContainer = styled.div`
  position: relative;
  width: ${CONTAINER_SIZE}px;
  height: ${CONTAINER_SIZE}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    circle at center,
    rgba(110, 86, 207, 0.05) 0%,
    rgba(110, 86, 207, 0) 70%
  );
  margin: 1rem 0; // Add vertical margin
`;

const MessageContainer = styled.div`
  position: relative;
  height: 60px; // Fixed height for message container
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
`;

const LoadingText = styled(motion.div)`
  font-family: "SF Pro Display", -apple-system, BlinkMacSystemFont, sans-serif;
  color: #6E56CF;
  font-size: 1.1rem;
  text-align: center;
  opacity: 0.9;
  max-width: 280px;
  line-height: 1.4;
  font-weight: 500;
  letter-spacing: -0.02em;
  position: absolute;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  border-radius: 12px;
`;

const SVGContainer = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
`;

const NodesContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;

interface ConnectionProps {
  $active: boolean;
}

const Connection = styled(motion.path)<ConnectionProps>`
  stroke: url(#gradientLine);
  stroke-width: 2;
  fill: none;
  filter: drop-shadow(0 0 2px rgba(155, 138, 251, 0.3));
  opacity: ${props => props.$active ? 0.8 : 0.4};
`;

interface NodeProps {
  size: number;
  $isPrimary?: boolean;
  $isActive?: boolean;
}

const nodeGlow = css<NodeProps>`
  animation: ${pulseGlow} 3s ease-in-out infinite;
  animation-delay: ${props => Math.random() * 2}s;
`;

const Node = styled(motion.div)<NodeProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  position: absolute;
  transform-origin: center center;
  background: ${props => props.$isPrimary 
    ? 'linear-gradient(135deg, #8E6EFF, #6E56CF)'
    : 'linear-gradient(135deg, #9B8AFB, #7B6AD8)'};
  background-size: 200% 200%;
  animation: ${gradientShift} 5s ease infinite;
  box-shadow: 
    0 0 20px ${props => props.$isPrimary 
      ? 'rgba(110, 86, 207, 0.4)'
      : 'rgba(155, 138, 251, 0.3)'},
    inset 0 0 15px rgba(255, 255, 255, 0.1);
  ${props => props.$isActive && nodeGlow}
  transform: translate(-50%, -50%); /* Center the node on its position */
  will-change: transform, opacity, width, height;
  z-index: 2;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background: radial-gradient(
      circle at 30% 30%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.1) 20%,
      rgba(255, 255, 255, 0) 60%
    );
  }
`;



interface NodeData {
  id: number;
  x: number;
  y: number;
  connections: number[];
  active: boolean;
  visible: boolean;
  size: number;
}


const messages = [
  "Charting your Atlas...",
  "Synthesizing knowledge patterns...",
  "Crystallizing insights...",
  "Exploring cognitive connections...",
  "Expanding knowledge..."
];

export const AtlasLoader: React.FC = () => {
  const [message, setMessage] = useState(messages[0]);
  const [nodes, setNodes] = useState<NodeData[]>([]);

  const getNodeSize = useCallback((nodeCount: number) => {
    if (nodeCount <= 6) return BASE_NODE_SIZE;
    const scale = Math.max(MIN_NODE_SIZE / BASE_NODE_SIZE, 1 - (nodeCount - 6) * 0.1);
    return Math.round(BASE_NODE_SIZE * scale);
  }, []);

  const calculateNodeSize = useCallback((node: NodeData, index: number) => {
    if (index === 0) return CENTER_NODE_SIZE;
    
    const connectionCount = node.connections.length;
    const incomingConnections = nodes.filter(n => n.connections.includes(node.id)).length;
    const totalConnections = connectionCount + incomingConnections;
    
    const baseSize = getNodeSize(nodes.length);
    const sizeIncrease = Math.min(totalConnections * SIZE_MULTIPLIER, BASE_NODE_SIZE);
    
    return Math.max(MIN_NODE_SIZE, baseSize + sizeIncrease);
  }, [nodes, getNodeSize]);

  const generatePosition = useCallback(() => {
    const angle = Math.random() * Math.PI * 2;
    const minDistance = MIN_DISTANCE;
    const maxDistance = MAX_DISTANCE;
    const distance = minDistance + Math.random() * (maxDistance - minDistance);
    
    let x = CONTAINER_SIZE / 2 + Math.cos(angle) * distance;
    let y = CONTAINER_SIZE / 2 + Math.sin(angle) * distance;
    
    x = Math.max(MARGIN, Math.min(CONTAINER_SIZE - MARGIN, x));
    y = Math.max(MARGIN, Math.min(CONTAINER_SIZE - MARGIN, y));
    
    return { x, y };
  }, []);

  const addNode = useCallback(() => {
    setNodes(prev => {
      if (prev.length >= MAX_NODES) return prev;

      const position = generatePosition();
      const connections = prev.length === 0 
        ? [] 
        : [
            0, // Always connect to center
            ...Array(Math.floor(Math.random() * 2 + 1)) // 1-2 additional random connections
              .fill(0)
              .map(() => Math.floor(Math.random() * prev.length))
              .filter((val, idx, arr) => arr.indexOf(val) === idx) // Remove duplicates
          ];

      const newNode: NodeData = {
        id: prev.length,
        x: position.x,
        y: position.y,
        connections,
        active: false,
        visible: false,
        size: BASE_NODE_SIZE
      };
      
      setTimeout(() => {
        setNodes(current => 
          current.map((node, index) => 
            index === current.length - 1 ? { ...node, visible: true } : node
          )
        );
      }, 100);

      return [...prev, newNode];
    });
  }, [generatePosition]);

  const adjustNodePositions = useCallback(() => {
    setNodes(prev => {
      if (prev.length <= 1) return prev;

      return prev.map((node, index) => {
        if (index === 0) return node; // Don't move center node

        // Calculate repulsion from other nodes
        let dx = 0, dy = 0;
        prev.forEach((otherNode, otherIndex) => {
          if (otherIndex !== index) {
            const diffX = node.x - otherNode.x;
            const diffY = node.y - otherNode.y;
            const distance = Math.sqrt(diffX * diffX + diffY * diffY);
            if (distance < MIN_DISTANCE) {
              const force = (MIN_DISTANCE - distance) / distance;
              dx += diffX * force * 0.1;
              dy += diffY * force * 0.1;
            }
          }
        });

        // Apply position adjustment
        let newX = node.x + dx;
        let newY = node.y + dy;

        // Keep within bounds
        newX = Math.max(MARGIN, Math.min(CONTAINER_SIZE - MARGIN, newX));
        newY = Math.max(MARGIN, Math.min(CONTAINER_SIZE - MARGIN, newY));

        return { ...node, x: newX, y: newY };
      });
    });
  }, []);

  const activateNodes = useCallback(() => {
    setNodes(prev => {
      const activeNodeIndex = Math.floor(Math.random() * prev.length);
      return prev.map((node, index) => ({
        ...node,
        active: node.connections.includes(activeNodeIndex) || index === activeNodeIndex
      }));
    });
  }, []);

  const getNodeCenter = useCallback((node: NodeData, index: number): { x: number; y: number } => {
    const size = calculateNodeSize(node, index);
    return {
      x: node.x + size / 2,
      y: node.y + size / 2
    };
  }, [calculateNodeSize]);

  const generateCurvedPath = useCallback((startNode: NodeData, startIndex: number, endNode: NodeData, endIndex: number) => {
    const start = getNodeCenter(startNode, startIndex);
    const end = getNodeCenter(endNode, endIndex);
    
    const midX = (start.x + end.x) / 2;
    const midY = (start.y + end.y) / 2;
    const dx = end.x - start.x;
    const dy = end.y - start.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const curvature = distance * 0.2;
    
    const controlX = midX - dy * (curvature / distance);
    const controlY = midY + dx * (curvature / distance);
    
    return `M ${start.x} ${start.y} Q ${controlX} ${controlY} ${end.x} ${end.y}`;
  }, [getNodeCenter]);

  useEffect(() => {
    let mounted = true;
    let messageInterval: NodeJS.Timeout;
    let activationInterval: NodeJS.Timeout;
    let addNodeTimeout: NodeJS.Timeout;
    let adjustmentInterval: NodeJS.Timeout;

    const setup = () => {
      // Initialize with center node
      if (nodes.length === 0) {
        setNodes([{
          id: 0,
          x: CONTAINER_SIZE / 2 - CENTER_NODE_SIZE / 2,
          y: CONTAINER_SIZE / 2 - CENTER_NODE_SIZE / 2,
          connections: [],
          active: true,
          visible: true,
          size: CENTER_NODE_SIZE
        }]);
      }

      // Start message cycling
      let currentIndex = 0;
      messageInterval = setInterval(() => {
        if (mounted) {
          currentIndex = (currentIndex + 1) % messages.length;
          setMessage(messages[currentIndex]);
        }
      }, 4000);

      // Start node activation
      activationInterval = setInterval(() => {
        if (mounted) {
          activateNodes();
        }
      }, 2000);

      // Start position adjustment
      adjustmentInterval = setInterval(() => {
        if (mounted) {
          adjustNodePositions();
        }
      }, 2000);

      // Start adding nodes
      const addNextNode = () => {
        if (mounted && nodes.length < MAX_NODES) {
          addNode();
          addNodeTimeout = setTimeout(addNextNode, 1000 + Math.random() * 500);
        }
      };

      addNodeTimeout = setTimeout(addNextNode, 500);
    };

    setup();

    return () => {
      mounted = false;
      clearInterval(messageInterval);
      clearInterval(activationInterval);
      clearInterval(adjustmentInterval);
      clearTimeout(addNodeTimeout);
    };
  }, [activateNodes, addNode, adjustNodePositions, nodes.length]);

  return (
    <Container className='Atlas-Container'>
      <MapContainer>
        <SVGContainer>
          <defs>
            <linearGradient id="gradientLine" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#9B8AFB" stopOpacity="0.6" />
              <stop offset="100%" stopColor="#6E56CF" stopOpacity="0.6" />
            </linearGradient>
          </defs>
          {nodes.map((node, nodeIndex) => 
            node.visible && node.connections.map((connectionId, connectionIndex) => {
              const targetNode = nodes[connectionId];
              return targetNode?.visible && (
                <Connection
                  key={`${node.id}-${connectionId}-${nodeIndex}-${connectionIndex}`}
                  d={generateCurvedPath(node, nodeIndex, targetNode, connectionId)}
                  initial={{ pathLength: 0, opacity: 0 }}
                  animate={{ pathLength: 1, opacity: node.active ? 0.8 : 0.4 }}
                  transition={{ 
                    duration: 0.8,
                    delay: 0.2,
                    ease: "easeOut"
                  }}
                  $active={node.active && targetNode.active}
                />
              );
            })
          )}
        </SVGContainer>
        <NodesContainer>
          <AnimatePresence>
            {nodes.map((node, index) => (
              node.visible && (
                <Node
                  key={node.id}
                  size={calculateNodeSize(node, index)}
                  $isPrimary={index === 0}
                  $isActive={node.active}
                  initial={{ scale: 0, opacity: 0, x: node.x, y: node.y }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    x: node.x,
                    y: node.y,
                    width: calculateNodeSize(node, index),
                    height: calculateNodeSize(node, index)
                  }}
                  exit={{ scale: 0, opacity: 0, width: 0, height: 0 }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 25
                  }}
                />
              )
            ))}
          </AnimatePresence>
        </NodesContainer>
      </MapContainer><MessageContainer>
      <LoadingText
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
      >
        {message}
      </LoadingText>
    </MessageContainer>
    </Container>
  );
};