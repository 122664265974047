import React from 'react';
import { inject, observer } from 'mobx-react';

import MapView from 'pages/intentions/MapViewPlayer';
import AuthenticatedPage from 'components/common/AuthenticatedPage';
import BasePage from 'components/common/BasePage';
import Loading from 'components/common/Loading';

type LocationProps = {
    TelemetryStore?: any;
    userEvents?: any;
}

class Location extends React.Component<LocationProps, {}> {
    componentDidMount() {
        const { TelemetryStore } = this.props;
        TelemetryStore.getLocationData();
    }

    render() {
        const { TelemetryStore } = this.props;

        return (
            <AuthenticatedPage>
                <BasePage innerCls="adapter-bg-base-100">
                    <MapView options={TelemetryStore.locations.slice(0, -1)} type="hotel" />
                    {TelemetryStore?.loading ? <Loading /> : <></>}
                </BasePage>
            </AuthenticatedPage>
        );
    }
};

export default inject("TelemetryStore")(observer(Location));