const config = {
    auth0: {
      domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
      redirectUri: `${window.location.origin}${process.env.REACT_APP_REDIRECT_URI || ""}`,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
    },
    posthog: {
      host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST || "",
      key: process.env.REACT_APP_PUBLIC_POSTHOG_KEY || "",
    },
    proxied: process.env.REACT_APP_IS_PROXIED || false
  };
  
  export default config;
  