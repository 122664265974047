// src/components/NodeDot.tsx
import React from 'react';
import { motion, HTMLMotionProps } from 'framer-motion'; // Import HTMLMotionProps
import { NodeData, NodePhase } from '../types';
import './NodeDot.css';

interface NodeDotProps {
  node: NodeData;
  phase: NodePhase;
  style?: React.CSSProperties;
  isTopAdapted?: boolean;
}

const getRandomDelay = () => Math.random() * 0.2;

const NodeDot: React.FC<NodeDotProps> = React.memo(({ node, phase, style, isTopAdapted }) => {

  const getDotClass = () => {
    // ... (getDotClass logic remains the same)
    let classes = 'node-dot';
    switch (node.status) {
      case 'processing':
        classes += ' status-processing';
        break;
      case 'processing-complete':
        classes += ' status-processing-complete';
        break;
      case 'contemplating':
        classes += ' status-contemplating';
        break;
      case 'contemplation-complete':
        classes += ' status-contemplation-complete';
        break;
      case 'adapted':
        classes += ` status-adapted ${isTopAdapted ? 'adapted-top' : 'adapted-other'}`;
        break;
    }
    return classes;
  };

   // Define the specific type for layout prop here
   const layoutProp: HTMLMotionProps<"div">['layout'] = "position"; // Or true if you need size anim too

  // Explicitly type motionProps or ensure correct assignment
  const motionProps: Omit<HTMLMotionProps<"div">, "ref"> = { // Be more explicit with the type
    layout: layoutProp, // Use the correctly typed variable
    initial: { opacity: 0, scale: 0.7 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.5, transition: { duration: 0.2 } },
    transition: {
      layout: {
        type: "spring",
        stiffness: 350,
        damping: 25,
        mass: 0.8,
        delay: getRandomDelay(),
      },
      opacity: { duration: 0.3, ease: "easeInOut" },
      scale: { duration: 0.3, ease: "backInOut" }
    },
    style: style,
    className: getDotClass(),
    // key is applied directly on the component, not needed inside motionProps
  };

  // Apply key directly to motion.div
  return <motion.div key={node.id} {...motionProps} />;
});

export default NodeDot;