import React from "react";
import { inject, observer } from "mobx-react";
import { AiOutlineApi } from "react-icons/ai";

import Button from "components/common/Button";
import Card from "components/common/Card";
import HeaderWithButton from "components/common/HeaderWithButton";

import ConnectAccountPopup from "./ConnectAccountPopup";
import AccountDisplay from './AccountDisplay';
import AccountStats from "./AccountStats";
import { H4Text, Text } from "components/common/Typography";
import { SettingsLayout } from "./SettingsLayout";
import Feedback from "components/common/Feedback/Feedback";

import "../../brand/index.scss";
import "./index.scss";

interface ConnectedAccountsPageProps {
    UserStore?: any;
}

export interface Account {
    accountVendor: string;
    accountEmail: string;
    connected: boolean;
    userId: string;
    configuration: {
        labels: string[];
        timeRange: {
            unit: 'months' | 'years';
            value: number;
        }
    };
}

class ConnectedAccountsPage extends React.Component<ConnectedAccountsPageProps, any> {
    state = {
        isModalOpen: false
    };

    onConnectButtonClick = () => {
        this.setState({ isModalOpen: true });
    }

    onCloseModal = () => {
        this.setState({ isModalOpen: false });
    }

    render() {
        const { isModalOpen } = this.state;
        const { UserStore } = this.props;

        return (
            <SettingsLayout>
                <div className="adapter-connected-accounts-wrapper">
                    <div className="adapter-connected-accounts-header">
                        <HeaderWithButton
                            content={
                                <>
                                    <H4Text fontSize="xs" cls='adapter-font-color-primary-900'>
                                        Connected accounts
                                    </H4Text>

                                    <Text>Revoke access to an account or connect a new account.</Text>
                                </>
                            }
                            button={
                                <Button onClick={this.onConnectButtonClick}>
                                    <AiOutlineApi style={{ display: 'inline-block' }} /> Connect more accounts
                                </Button>
                            }
                        />
                    </div>

                    <div className="adapter-connected-accounts">
                        {UserStore.userAccounts?.map((account: Account, index: number) => (
                            <Card key={index}>
                                <AccountDisplay account={account} buttonText="Save changes" />
                                <AccountStats accountEmail={account.accountEmail} />

                                <Feedback
                                    style={{ 
                                        backgroundColor: '#F1F1F4', 
                                        borderRadius: '4px',
                                        alignSelf: 'stretch',
                                        justifyContent: 'center'
                                    }}
                                    text="Does this information looks correct?"
                                    metadata={{ 
                                        accountEmail: account.accountEmail 
                                    }}
                                    responseKey={'settings::accounts::accountEmail'}
                                />
                            </Card>
                        ))}
                    </div>
                </div>

                <div className="flex-1"></div>

                {isModalOpen && <ConnectAccountPopup onClose={this.onCloseModal} />}
            </SettingsLayout>
        );
    }
}

export default inject("UserStore")(observer(ConnectedAccountsPage));