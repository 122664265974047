import { css, keyframes, styled } from "styled-components";
import { FiUsers, FiClock, FiAlertTriangle, FiStar, FiSend, FiHome, FiCoffee, FiMapPin, FiCalendar } from "react-icons/fi";

// Animations
export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

export const scaleIn = keyframes`
  from { transform: scale(0.95); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
`;

// Create snappier blooming animations
export const bloomIn = keyframes`
  0% {
    transform: scale(1) translateZ(0);
    opacity: 0.95;
    border-radius: 6px;
  }
  100% {
    transform: scale(1.02) translateZ(0);
    opacity: 1;
    border-radius: 8px;
  }
`;

export const bloomOut = keyframes`
  0% {
    transform: scale(1.02) translateZ(0);
    opacity: 1;
    border-radius: 8px;
  }
  100% {
    transform: scale(1) translateZ(0);
    opacity: 0.95;
    border-radius: 6px;
  }
`;

// Modal Container
export const ModalOverlay = styled.div<{ $darkMode: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => (props.$darkMode ? "rgba(0, 0, 0, 0.8)" : "rgba(226, 232, 240, 0.75)")};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
  animation: ${fadeIn} 0.3s ease;
`;

export const ModalContent = styled.div<{ $darkMode: boolean }>`
  background-color: ${(props) => (props.$darkMode ? "#1c1c1c" : "#FFFFFF")}; /* Updated to a slightly lighter dark gray (100) */
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 1600px; /* Increased to better fit 3 columns */
  max-height: 90vh;
  overflow-y: auto;
  padding: 24px;
  animation: ${scaleIn} 0.3s ease;
  position: relative;
  isolation: isolate; /* Creates a stacking context so internal elements with z-index work properly */
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const ModalTitle = styled.h2<{ $darkMode: boolean }>`
  font-size: 24px;
  font-weight: 600;
  color: ${(props) => (props.$darkMode ? "#F8F9FA" : "#212529")}; // Updated to match brand colors
  margin: 0;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 12px;
    color: ${(props) => (props.$darkMode ? "#CCC" : "#0891B2")}; // Lighter icon color in dark mode
    font-size: 20px;
  }
`;

export const CloseButton = styled.button<{ $darkMode: boolean }>`
  background: none;
  border: none;
  color: ${(props) => (props.$darkMode ? "#94A3B8" : "#64748B")};
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  transition: background 0.2s ease, color 0.2s ease;
  
  &:hover {
    background-color: ${(props) => (props.$darkMode ? "#334155" : "#F1F5F9")};
    color: ${(props) => (props.$darkMode ? "#F1F5F9" : "#0F172A")};
  }
`;

// Categories navigation
export const CategoryNav = styled.div`
  display: flex;
  margin-bottom: 24px;
  overflow-x: auto;
  padding-bottom: 8px;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  
  &::-webkit-scrollbar {
    height: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(73, 80, 87, 0.3);
    border-radius: 6px;
  }
`;

export const CategoryButton = styled.button<{ 
  $darkMode: boolean; 
  $active: boolean;
  $category?: string;
}>`
  padding: 8px 10px;
  border-radius: 4px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s ease;
  
  background-color: ${props => {
    // In dark mode, all buttons use the dark grey theme
    if (props.$darkMode) {
      return props.$active ? 'rgba(40, 40, 40, 0.8)' : 'rgba(25, 25, 25, 0.8)';
    } else {
      // Light mode can keep some variation
      if (props.$active) {
        switch (props.$category) {
          case 'travel':
            return 'rgba(8, 145, 178, 0.15)'; // Tide
          case 'conflict':
            return 'rgba(225, 29, 72, 0.15)'; // Rose
          case 'meeting':
            return 'rgba(8, 145, 178, 0.15)'; // Tide
          case 'schedule':
            return 'rgba(22, 163, 74, 0.15)'; // Mint
          case 'people':
            return 'rgba(124, 58, 237, 0.15)'; // Echo
          case 'insight':
            return 'rgba(202, 138, 4, 0.15)'; // Sand
          default:
            return 'rgba(248, 249, 250, 0.8)'; // Light/dark
        }
      }
      return 'rgba(255, 255, 255, 0.8)';
    }
  }};
  
  color: ${props => {
    // In dark mode, active buttons use cyan, inactive buttons are grey
    if (props.$darkMode) {
      return props.$active ? '#67E8F9' : '#ADB5BD';
    } else {
      // Light mode can keep some color variation
      if (props.$active) {
        switch (props.$category) {
          case 'travel':
            return '#0891B2'; // Tide text
          case 'conflict':
            return '#E11D48'; // Rose text
          case 'meeting':
            return '#0891B2'; // Tide text
          case 'schedule':
            return '#16A34A'; // Mint text
          case 'people':
            return '#7C3AED'; // Echo text
          case 'insight':
            return '#EAB308'; // Sand text
          default:
            return '#343A40'; // Light/dark text
        }
      }
      return '#6C757D'; // Default text color for inactive buttons
    }
  }};
  
  border: 1px solid transparent; // Remove border by default
  
  svg {
    margin-right: 6px;
    width: 14px;
    height: 14px;
  }
  
  &:hover {
    background-color: ${props => {
      if (props.$darkMode) {
        return props.$active ? 'rgba(50, 50, 50, 0.9)' : 'rgba(35, 35, 35, 0.9)';
      } else {
        if (props.$active) {
          switch (props.$category) {
            case 'travel':
              return 'rgba(8, 145, 178, 0.25)';
            case 'conflict':
              return 'rgba(225, 29, 72, 0.25)';
            case 'meeting':
              return 'rgba(8, 145, 178, 0.25)';
            case 'schedule':
              return 'rgba(22, 163, 74, 0.25)';
            case 'people':
              return 'rgba(124, 58, 237, 0.25)';
            case 'insight':
              return 'rgba(202, 138, 4, 0.25)';
            default:
              return 'rgba(233, 236, 239, 0.8)';
          }
        }
        return 'rgba(233, 236, 239, 0.8)';
      }
    }};
  }
`;

export const CategoryCount = styled.span<{ $darkMode: boolean; $category?: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
  background-color: ${props => props.$darkMode ? 'rgba(50, 50, 50, 0.9)' : 'rgba(240, 240, 240, 0.8)'};
  color: ${props => props.$darkMode ? '#67A5B9' : '#0891B2'}; // Muted cyan in dark mode
`;

// Card grid layout
export const SuggestionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Fixed 3 columns */
  gap: 16px; /* Slightly reduced gap for a more condensed look */
  
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* 1 column on mobile */
  }
  
  animation: ${fadeIn} 0.4s ease-out;
`;

// Enhanced Card
export const EnhancedCard = styled.div<{
  $darkMode: boolean;
  $category?: string;
  $importance: number;
  $isExpanded?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  position: relative;
  animation: ${slideUp} 0.4s ease-out;
  transform-origin: center bottom;
  height: 100%;
  isolation: isolate; /* Create stacking context for z-index */
  transform: translateZ(0); /* Force hardware acceleration */
  
  background-color: ${props => props.$darkMode ? '#121212' : '#FFFFFF'}; // Very dark grey in dark mode
  
  /* Add colored border based on category - with muted vibrancy */
  border: 1px solid ${props => {
    if (!props.$darkMode) return 'rgba(240, 240, 240, 1)'; // Light mode border
    
    // Dark mode colored borders from the color chart, muted by factor of 2
    switch (props.$category) {
      case 'travel':
        return 'rgba(204, 176, 85, 0.4)'; // Sand (500)
      case 'meeting':
        return 'rgba(103, 176, 195, 0.4)'; // Tide (500)
      case 'conflict':
        return 'rgba(190, 114, 123, 0.4)'; // Rose (500)
      case 'schedule':
        return 'rgba(113, 166, 121, 0.4)'; // Mint (500)
      case 'people':
        return 'rgba(149, 128, 169, 0.4)'; // Echo (500)
      case 'insight':
        return 'rgba(173, 148, 85, 0.4)'; // Sand (600)
      default:
        return 'rgba(40, 40, 40, 0.5)'; // Default dark border
    }
  }};
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }
  
  &.expanded {
    transform: translateY(-3px) scale(1.01);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
    z-index: 150;
  }
  
  /* Make the card react to the query expansion, but don't change opacity */
  .query-container {
    transition: margin-bottom 0.4s ease;
  }
`;

export const CardHeader = styled.div<{ $darkMode: boolean; $category?: string }>`
  padding: 12px 16px;
  background-color: ${props => props.$darkMode ? '#121212' : '#FFFFFF'}; // Same very dark grey as card background
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none; // Remove border to match screenshot
  position: relative;
  min-height: 28px; /* Ensure consistent height */
  flex-wrap: nowrap; /* Prevent wrapping to ensure layout */
  gap: 12px; /* Add space between elements */
  
  /* Category label styling */
  & > *:first-child {
    flex-shrink: 1; /* Allow shrinking if needed */
  }
  
  /* Date styling */
  & > *:last-child {
    flex-shrink: 0; /* Prevent date from shrinking */
    min-width: 100px; /* Ensure minimum width for date */
  }
`;

export const CategoryLabel = styled.div<{ $darkMode: boolean; $category?: string }>`
  font-size: 11px;
  font-weight: 600;
  padding: 3px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: ${props => {
    if (!props.$darkMode) return 'rgba(240, 240, 240, 0.8)';
    
    // Dark mode category colors - muted by factor of 2
    switch (props.$category) {
      case 'travel':
        return 'rgba(204, 176, 85, 0.12)'; // Sand (500)
      case 'meeting':
        return 'rgba(103, 176, 195, 0.12)'; // Tide (500)
      case 'conflict':
        return 'rgba(190, 114, 123, 0.12)'; // Rose (500)
      case 'schedule':
        return 'rgba(113, 166, 121, 0.12)'; // Mint (500)
      case 'people':
        return 'rgba(149, 128, 169, 0.12)'; // Echo (500)
      case 'insight':
        return 'rgba(173, 148, 85, 0.12)'; // Sand (600)
      default:
        return 'rgba(40, 40, 40, 0.8)';
    }
  }};
  color: ${props => {
    if (!props.$darkMode) return '#0891B2';
    
    // Dark mode category text colors - muted by factor of 2
    switch (props.$category) {
      case 'travel':
        return '#CCB055'; // Sand (500)
      case 'meeting':
        return '#67A5B9'; // Tide (500)
      case 'conflict':
        return '#BE727B'; // Rose (500)
      case 'schedule':
        return '#71A679'; // Mint (500)
      case 'people':
        return '#9580A9'; // Echo (500)
      case 'insight':
        return '#B59C58'; // Sand (600)
      default:
        return '#67A5B9';
    }
  }};
  display: flex;
  align-items: center;
  flex-shrink: 1; /* Allow shrinking if needed */
  max-width: 130px; /* Limit width to make room for date */
  overflow: hidden; /* Hide overflow */
  
  svg {
    margin-right: 6px;
    flex-shrink: 0; /* Don't shrink the icon */
    width: 14px;
    height: 14px;
  }
`;

export const EventDate = styled.div<{ $darkMode: boolean; $category?: string }>`
  font-size: 13px;
  display: flex;
  align-items: center;
  color: ${props => {
    if (!props.$darkMode) return '#0891B2';
    
    // Dark mode category text colors - muted by factor of 2
    switch (props.$category) {
      case 'travel':
        return '#CCB055'; // Sand (500)
      case 'meeting':
        return '#67A5B9'; // Tide (500)
      case 'conflict':
        return '#BE727B'; // Rose (500)
      case 'schedule':
        return '#71A679'; // Mint (500)
      case 'people':
        return '#9580A9'; // Echo (500)
      case 'insight':
        return '#B59C58'; // Sand (600)
      default:
        return '#67A5B9';
    }
  }};
  margin-left: auto; /* Push to the right side */
  padding: 4px 10px; /* Match padding from screenshot */
  font-weight: 500; /* Slightly bolder for better readability */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
  min-width: 80px; /* Ensure width for date */
  background-color: ${props => {
    if (!props.$darkMode) return 'rgba(240, 240, 240, 0.8)';
    
    // Dark mode category colors - muted by factor of 2
    switch (props.$category) {
      case 'travel':
        return 'rgba(103, 176, 195, 0.12)'; // Tide (500)
      case 'meeting':
        return 'rgba(103, 176, 195, 0.12)'; // Tide (500)
      case 'conflict':
        return 'rgba(190, 114, 123, 0.12)'; // Rose (500)
      case 'schedule':
        return 'rgba(113, 166, 121, 0.12)'; // Mint (500)
      case 'people':
        return 'rgba(149, 128, 169, 0.12)'; // Echo (500)
      case 'insight':
        return 'rgba(204, 176, 85, 0.12)'; // Sand (500)
      default:
        return 'rgba(40, 40, 40, 0.8)';
    }
  }};
  border-radius: 4px;
  
  svg {
    margin-right: 6px;
    flex-shrink: 0; /* Prevent icon from shrinking */
    color: ${props => {
      if (!props.$darkMode) return '#0891B2';
      
      // Dark mode category text colors for icon - muted by factor of 2
      switch (props.$category) {
        case 'travel':
          return '#CCB055'; // Sand (500)
        case 'meeting':
          return '#67A5B9'; // Tide (500)
        case 'conflict':
          return '#BE727B'; // Rose (500)
        case 'schedule':
          return '#71A679'; // Mint (500)
        case 'people':
          return '#9580A9'; // Echo (500)
        case 'insight':
          return '#B59C58'; // Sand (600)
        default:
          return '#67A5B9';
      }
    }};
    width: 14px;
    height: 14px;
  }
`;

export const CardBody = styled.div`
  padding: 16px 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 250px; /* Limit height for better appearance with 3 columns */
  overflow: hidden;
`;

export const CardTitle = styled.h3<{ $darkMode: boolean; $category?: string }>`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 8px 0;
  color: ${props => props.$darkMode ? '#F8F9FA' : '#212529'}; // Updated to match brand colors
`;

export const CardDescription = styled.p<{ $darkMode: boolean; $category?: string }>`
  font-size: 13px;
  margin: 0 0 12px 0;
  color: ${props => props.$darkMode ? '#DEE2E6' : '#495057'}; // Updated to match brand colors
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// Placeholder component (no visible overlay)
export const OverlayDim = styled.div<{ $visible: boolean }>`
  display: none; /* Remove the overlay entirely */
`;

export interface QueryTextProps {
  $darkMode: boolean;
  $isExpanded?: boolean;
}

export const QueryText = styled.div<QueryTextProps & { $category?: string }>`
  background-color: ${props => {
    // Darker background color that doesn't depend on category
    return props.$darkMode 
      ? 'rgba(28, 31, 35, 0.95)' 
      : 'rgba(33, 37, 41, 0.95)'; // Dark gray background for all
  }};
  padding: 12px 14px;
  border-radius: 4px;
  font-size: 13px;
  font-family: 'SF Mono', 'Menlo', 'Monaco', 'Courier New', monospace;
  letter-spacing: 0.01em;
  line-height: 1.5;
  color: #DEE2E6; // Light gray text for all
  position: ${props => props.$isExpanded ? 'absolute' : 'relative'};
  cursor: default;
  border: none; // No border
  transform-origin: center;
  will-change: transform, opacity, max-height, border-radius, filter;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  
  ${props => props.$isExpanded ? css`
    white-space: normal;
    overflow: visible;
    word-break: break-word;
    z-index: 100; /* High enough to appear above other elements */
    left: 0;
    right: 0;
    max-height: none; /* Allow full expansion */
    animation: ${bloomIn} 0.12s cubic-bezier(0.17, 0.67, 0.38, 1) forwards;
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.5);
    border: none;
    background-color: rgba(16, 18, 20, 0.98); // More opaque dark background
    color: #F8F9FA; // Bright text for better contrast 
    filter: contrast(105%); // Slight contrast enhancement
    transition: all 0.12s ease-out;
  ` : css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 40px; /* Fixed height when collapsed */
    animation: ${bloomOut} 0.12s cubic-bezier(0.33, 0, 0.67, 1) forwards;
    transition: all 0.12s ease-out;
  `}
`;

export const RelatedInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: auto;
  padding-top: 12px;
`;

export const PersonTag = styled.div<{ $darkMode: boolean; $category?: string }>`
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 4px; // Changed to match the UI in screenshot
  display: flex;
  align-items: center;
  white-space: nowrap;
  
  background-color: ${props => {
    if (props.$category === 'meeting') {
      return props.$darkMode ? 'rgba(103, 176, 195, 0.12)' : 'rgba(8, 145, 178, 0.08)'; // Muted Tide colors
    }
    return props.$darkMode ? 'rgba(149, 128, 169, 0.12)' : 'rgba(124, 58, 237, 0.08)'; // Muted Echo colors
  }};
  
  color: ${props => {
    if (props.$category === 'meeting') {
      return props.$darkMode ? '#67A5B9' : '#0891B2'; // Muted Tide text colors
    }
    return props.$darkMode ? '#9580A9' : '#7C3AED'; // Muted Echo text colors
  }};
  
  svg {
    margin-right: 6px;
    font-size: 10px;
  }
`;

export const LocationTag = styled.div<{ $darkMode: boolean; $entityType?: string; $category?: string }>`
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 4px; // Changed to match the UI in screenshot
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 4px;
  
  background-color: ${props => {
    // For meeting category, use muted tide colors
    if (props.$category === 'meeting') {
      return props.$darkMode ? 'rgba(103, 176, 195, 0.12)' : 'rgba(8, 145, 178, 0.08)';
    }
    
    // Different background colors based on entity type - muted by factor of 2
    const type = props.$entityType || '';
    if (type.includes('flight')) {
      return props.$darkMode ? 'rgba(103, 176, 195, 0.12)' : 'rgba(8, 145, 178, 0.08)'; // Muted Tide
    } else if (type.includes('hotel') || type.includes('stay')) {
      return props.$darkMode ? 'rgba(190, 114, 123, 0.12)' : 'rgba(244, 63, 94, 0.08)'; // Muted Rose
    } else if (type.includes('dining') || type.includes('restaurant')) {
      return props.$darkMode ? 'rgba(204, 176, 85, 0.12)' : 'rgba(202, 138, 4, 0.08)'; // Muted Sand
    }
    return props.$darkMode ? 'rgba(103, 176, 195, 0.12)' : 'rgba(8, 145, 178, 0.08)'; // Default to muted Tide
  }};
  
  color: ${props => {
    // For meeting category, use muted tide colors
    if (props.$category === 'meeting') {
      return props.$darkMode ? '#67A5B9' : '#0891B2';
    }
    
    // Different text colors based on entity type - muted by factor of 2
    const type = props.$entityType || '';
    if (type.includes('flight')) {
      return props.$darkMode ? '#67A5B9' : '#0891B2'; // Muted Tide
    } else if (type.includes('hotel') || type.includes('stay')) {
      return props.$darkMode ? '#BE727B' : '#E11D48'; // Muted Rose
    } else if (type.includes('dining') || type.includes('restaurant')) {
      return props.$darkMode ? '#CCB055' : '#EAB308'; // Muted Sand
    }
    return props.$darkMode ? '#67A5B9' : '#0891B2'; // Default to muted Tide
  }};
  
  svg {
    margin-right: 6px;
    width: 14px; /* Fixed width */
    height: 14px; /* Fixed height */
    flex-shrink: 0; /* Prevent shrinking */
  }
`;

export const FilterTag = styled.div<{ $darkMode: boolean; $type: string; $category?: string }>`
  font-size: 11px;
  padding: 6px 10px;
  border-radius: 4px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  
  background-color: ${props => {
    if (props.$darkMode) {
      // Dark mode - more subtle background as in screenshot
      switch(props.$category) {
        case 'travel':
          return "rgba(204, 176, 85, 0.1)"; // Sand
        case 'meeting':
          return "rgba(103, 176, 195, 0.1)"; // Tide
        case 'conflict':
          return "rgba(190, 114, 123, 0.1)"; // Rose
        case 'schedule':
          return "rgba(113, 166, 121, 0.1)"; // Mint
        case 'people':
          return "rgba(149, 128, 169, 0.1)"; // Echo
        case 'insight':
          return "rgba(173, 148, 85, 0.1)"; // Sand
        default:
          return "rgba(40, 40, 40, 0.25)"; // Default gray
      }
    } else {
      // Light mode
      return "rgba(240, 240, 240, 0.8)"; // Light gray background in light mode
    }
  }};
  
  color: ${props => {
    if (props.$darkMode) {
      // Dark mode - matching the category colors
      switch(props.$category) {
        case 'travel':
          return "#CCB055"; // Sand
        case 'meeting':
          return "#67A5B9"; // Tide
        case 'conflict':
          return "#BE727B"; // Rose
        case 'schedule':
          return "#71A679"; // Mint
        case 'people':
          return "#9580A9"; // Echo
        case 'insight':
          return "#B59C58"; // Sand
        default:
          return "#999999"; // Default gray
      }
    } else {
      // Light mode - darker text
      return "#666666"; 
    }
  }};
`;

export const CardFooter = styled.div<{ $darkMode: boolean; $category?: string }>`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.$darkMode ? '#121212' : '#FFFFFF'}; // Same very dark grey as card
  border-top: 1px solid ${props => 
    props.$darkMode ? 'rgba(40, 40, 40, 0.5)' : 'rgba(222, 226, 230, 0.5)' // Darker border in dark mode
  };
  height: 72px; /* Fixed height to match screenshot */
`;

export const ApplyButton = styled.button<{ $darkMode: boolean; $category?: string }>`
  background-color: #FFFFFF; /* Off-white button as shown in screenshot */
  color: #1A1A1A; /* Dark gray text for all categories */
  border: none; /* No visible border in screenshot */
  border-radius: 8px; /* More rounded corners */
  padding: 10px 20px; /* Larger padding for bigger button */
  font-size: 14px; /* Slightly larger font */
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px; /* Ensure consistent width */
  height: 40px; /* Fixed height */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow */
  
  &:hover {
    background-color: #F8F8F8; /* Slightly darker on hover */
    transform: translateY(-1px);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
  }
  
  &:active {
    transform: translateY(0);
    background-color: #F0F0F0;
  }
`;

export const Filters = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  min-height: 30px; /* Ensure consistent height */
`;

// Loading placeholder elements
export const LoadingPlaceholder = styled.div<{ $darkMode: boolean }>`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
`;

export const LoadingSpinner = styled.div`
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.8s linear infinite;
  margin-bottom: 16px;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const EmptyState = styled.div<{ $darkMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  
  svg {
    font-size: 48px;
    margin-bottom: 16px;
    opacity: 0.6;
  }
`;

// Tooltip that appears on hover to explain query expansion
export const HoverHint = styled.div<{ $darkMode: boolean; $isVisible: boolean }>`
  position: absolute;
  top: -30px;
  right: 10px;
  background-color: ${props => props.$darkMode ? 'rgba(30, 41, 59, 0.95)' : 'rgba(255, 255, 255, 0.95)'};
  color: ${props => props.$darkMode ? '#E2E8F0' : '#334155'};
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 11px;
  font-family: sans-serif;
  pointer-events: none;
  opacity: ${props => props.$isVisible ? 1 : 0};
  transform: translateY(${props => props.$isVisible ? '0' : '5px'});
  transition: all 0.2s ease;
  white-space: nowrap;
  z-index: 10;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid ${props => props.$darkMode ? 'rgba(71, 85, 105, 0.5)' : 'rgba(226, 232, 240, 0.8)'};
`;

// Floating Action Button for collapsed mode
export const SuggestionsFloatingButton = styled.button<{ $darkMode: boolean; $hasNotification?: boolean }>`
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: ${props => props.$darkMode ? '#06B6D4' : '#0891B2'}; // Updated to Tide colors
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  box-shadow: 0 4px 12px rgba(6, 182, 212, 0.3); // Updated shadow color
  z-index: 100;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 16px rgba(6, 182, 212, 0.4); // Updated shadow color
    background-color: ${props => props.$darkMode ? '#0E7490' : '#0E7490'}; // Darker tide on hover
  }
  
  &:active {
    transform: translateY(0);
  }
  
  ${props => props.$hasNotification && css`
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 12px;
      height: 12px;
      background-color: #E11D48; // Rose color for notification
      border-radius: 50%;
      border: 2px solid ${props.$darkMode ? '#212529' : '#FFFFFF'};
    }
  `}
`;

// Helper functions
export function getCategoryColor(category?: string, darkMode: boolean = false, opacity: number = 1): string {
  // Updated colors with muted vibrancy (factor of 2)
  const categoryColors = {
    meeting: darkMode ? `rgba(67, 115, 128, ${opacity})` : `rgba(0, 128, 128, ${opacity})`, // Muted Teal
    people: darkMode ? `rgba(95, 76, 113, ${opacity})` : `rgba(102, 51, 153, ${opacity})`, // Muted Purple
    schedule: darkMode ? `rgba(56, 83, 59, ${opacity})` : `rgba(32, 97, 39, ${opacity})`, // Muted Green
    conflict: darkMode ? `rgba(128, 72, 78, ${opacity})` : `rgba(171, 34, 46, ${opacity})`, // Muted Red
    insight: darkMode ? `rgba(138, 114, 56, ${opacity})` : `rgba(184, 134, 11, ${opacity})`, // Muted Gold
    travel: darkMode ? `rgba(204, 176, 85, ${opacity})` : `rgba(184, 134, 11, ${opacity})` // Sand color
  };
  
  return category && categoryColors[category as keyof typeof categoryColors] 
    ? categoryColors[category as keyof typeof categoryColors] 
    : darkMode ? `rgba(73, 80, 87, ${opacity})` : `rgba(73, 80, 87, ${opacity})`;
}

/**
 * Format the event date for display
 * Handles different date formats including ranges like "Apr 03-04"
 */
export function formatEventDate(dateStr: string): string {
  if (!dateStr) return '';
  
  try {
    // Only replace hyphens with en dashes to improve typography
    return dateStr.replace(/-/g, '–');
  } catch (e) {
    // If any error occurs, return the original string
    return dateStr;
  }
}

export function getCategoryTextColor(category?: string, darkMode: boolean = false): string {
  // Use muted colors from the color chart - slightly off-white for better readability
  return '#F8F9FA';
}

export function getCategoryIcon(category?: string) {
  switch (category) {
    case 'meeting':
      return <FiUsers />;
    case 'people':
      return <FiUsers />;
    case 'schedule':
      return <FiClock />;
    case 'conflict':
      return <FiAlertTriangle />;
    case 'travel':
      return <FiSend />;
    case 'insight':
    default:
      return <FiStar />;
  }
}

// Function to get the appropriate icon for location type
export function getLocationIcon(location: string | undefined, entityType?: string) {
  // Handle undefined location
  if (!location) {
    // If we at least have an entity type, use that to determine the icon
    if (entityType) {
      switch(entityType) {
        case 'hotel':
        case 'hotel_stay':
          return <FiHome size={14} />;
        case 'dining':
        case 'restaurant':
          return <FiCoffee size={14} />;
        case 'airport':
        case 'flight':
          return <FiSend size={14} />;
        case 'calendar_event':
          return <FiCalendar size={14} />;
      }
    }
    // No location and no entity type, return default
    return <FiMapPin size={14} />;
  }
  
  // Process location string to standardize it
  const locationStr = location.toString().trim();
  
  // Try to determine entity type from the location string if not provided
  if (!entityType) {
    const lowercaseLocation = locationStr.toLowerCase();
    
    // Check for hotel/lodging keywords
    if (lowercaseLocation.includes('hotel') || 
        lowercaseLocation.includes('inn') || 
        lowercaseLocation.includes('resort') ||
        lowercaseLocation.includes('lodging') ||
        lowercaseLocation.includes('stay')) {
      return <FiHome size={14} />;
    } 
    
    // Check for dining/restaurant keywords
    else if (lowercaseLocation.includes('restaurant') || 
        lowercaseLocation.includes('grill') || 
        lowercaseLocation.includes('cafe') ||
        lowercaseLocation.includes('dining') ||
        lowercaseLocation.includes('bistro') ||
        lowercaseLocation.includes('bar')) {
      return <FiCoffee size={14} />;
    } 
    
    // Check for airport/flight keywords
    else if (lowercaseLocation.includes('airport') || 
        lowercaseLocation.includes('terminal') ||
        lowercaseLocation.includes('flight') ||
        /\b[A-Z]{3}\b/.test(locationStr)) { // 3-letter airport code
      return <FiSend size={14} />;
    }
  } else {
    // Use entityType to determine icon
    switch(entityType) {
      case 'hotel':
      case 'hotel_stay':
        return <FiHome size={14} />;
      case 'dining':
      case 'restaurant':
        return <FiCoffee size={14} />;
      case 'airport':
      case 'flight':
        return <FiSend size={14} />;
      case 'calendar_event':
        return <FiCalendar size={14} />;
    }
  }
  
  // Default icon
  return <FiMapPin size={14} />;
}