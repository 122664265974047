import React, { useState, useEffect } from 'react';
import { RightSidebar as RightSidebarStyled, RightSidebarContainer, RightPanelCollapseButton, CondensedNodeInfo, NodeConnectionCount, ExpandPanelButton } from '../styles/KnowledgeGraphExplorerStyles';
import { DetailPanel } from '../panels/DetailPanel';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { NodeData } from '../types/types';

interface RightSidebarProps {
  isCollapsed: boolean;
  onCollapseToggle: () => void;
  selectedNode: NodeData | null;
  connectedNodes?: NodeData[];
  connectedEdges?: any[];
  darkMode: boolean;
  onNodeClick?: (node: NodeData) => void;
  onExpandNode?: (node: NodeData) => void;
  onHideNode?: (nodeId: string) => void;
  isDetailPanelExpanded?: boolean;
  onToggleDetailPanel?: () => void;
}

const RightSidebar: React.FC<RightSidebarProps> = ({
  isCollapsed,
  onCollapseToggle,
  selectedNode,
  connectedNodes = [],
  connectedEdges = [],
  darkMode,
  onNodeClick,
  onExpandNode,
  onHideNode,
  isDetailPanelExpanded,
  onToggleDetailPanel
}) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [collapsed, setCollapsed] = useState(isCollapsed);

  // Sync internal state with props
  useEffect(() => {
    setCollapsed(isCollapsed);
  }, [isCollapsed]);

  // Handle collapse with transition protection
  const handleCollapseToggle = () => {
    setIsTransitioning(true);
    
    // Call parent handler
    onCollapseToggle();
    
    // Allow time for transition to complete (match CSS transition duration)
    setTimeout(() => {
      setIsTransitioning(false);
    }, 350); // 350ms to ensure it completes after 300ms transition
  };

  if (!selectedNode) {
    return null;
  }

  return (
    <RightSidebarContainer>
      <RightSidebarStyled 
        $collapsed={collapsed} 
        $hasSelectedNode={!!selectedNode}
        $isTransitioning={isTransitioning}
      >
        {collapsed ? (
          /* Condensed view for collapsed panel */
          <>
            <div className="detail-panel">
              <CondensedNodeInfo $darkMode={darkMode}>
                <h4>{selectedNode.label}</h4>
                <p>{selectedNode.type}</p>
                {selectedNode.properties && (
                  <small>
                    {selectedNode.properties.occupation || selectedNode.properties.relationship || selectedNode.properties.description || ''}
                  </small>
                )}
                <NodeConnectionCount $darkMode={darkMode}>
                  <span>Connections: {connectedNodes.length}</span>
                  {connectedNodes.length > 0 && onNodeClick && (
                    <button onClick={() => onNodeClick(connectedNodes[0])}>
                      View {connectedNodes[0].label.length > 10 ? connectedNodes[0].label.substring(0, 8) + '...' : connectedNodes[0].label}
                    </button>
                  )}
                </NodeConnectionCount>
              </CondensedNodeInfo>
            </div>
            <ExpandPanelButton 
              onClick={handleCollapseToggle} 
              $darkMode={darkMode}
            >
              Show Details
            </ExpandPanelButton>
          </>
        ) : (
          /* Full detailed view for expanded panel */
          <DetailPanel
            selectedNode={selectedNode}
            connectedNodes={connectedNodes}
            connectedEdges={connectedEdges}
            darkMode={darkMode}
            onNodeClick={onNodeClick}
            onHideNode={onHideNode || (() => {})}
            onExpandNode={onExpandNode || (() => {})}
            aiService={{} as any} // Temporary workaround for missing dependency
          />
        )}
        
        <RightPanelCollapseButton 
          onClick={handleCollapseToggle}
          $darkMode={darkMode}
        >
          {collapsed ? <FiChevronLeft /> : <FiChevronRight />}
        </RightPanelCollapseButton>
      </RightSidebarStyled>
    </RightSidebarContainer>
  );
};

export default RightSidebar;