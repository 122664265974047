import React from 'react';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import { AlertCircleIcon, CloseIcon } from 'components/common/Icon';
import { H4Text, Text } from 'components/common/Typography';

const headerStyle = { fontWeight: '600' };

const SafeguardsModal = ({ onClose }) => (
    <Modal onClose={onClose} cls="onboarding-popup">
        <div className="onboarding-modal">
            <div className="onboarding-modal-head">
                <AlertCircleIcon color="#5C33CC" />
                <CloseIcon
                    onClick={onClose}
                    style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
                />
            </div>

            <div className="onboarding-modal-body">
                <H4Text>Adapter Data FAQ</H4Text>

                <Text fontSize='l' style={headerStyle}>How do you safeguard my data?</Text>
                <Text>
                    Adapter is a platform that truly knows you. That level of access means security isn&apos;t just a feature,
                    it&apos;s the foundation of everything we do. Our privacy protection is built in-house, from the ground up,
                    and we employ encryption wherever possible. Our approach is rooted in both processes and people because
                    securing your data depends on accounting for technology and human factors.
                </Text>

                <Text fontSize='l' style={headerStyle}>Who are you?</Text>
                <Text>
                    Adapter is a venture-backed startup. Our team of engineers, scientists, designers, and innovators is united
                    in the belief that everyone deserves to be empowered by transformative intelligent technologies and that people
                    should have agency over their data.
                </Text>

                <Text fontSize='l' style={headerStyle}>Why should I trust you?</Text>
                <Text>
                    As cryptography veterans we&apos;ve spent decades developing world-class security innovations for global leaders.
                    We bring that same rigor to Adapter, setting new standards that empower you to take control of your digital life,
                    safely, and securely.
                </Text>

                <Text fontSize='l' style={headerStyle}>Does Adapter sell my data?</Text>
                <Text>
                    No. Your data is never sold or shared with third parties. No matter what.
                </Text>

                <Text fontSize='l' style={headerStyle}>Does Adapter serve me targeted ads?</Text>
                <Text>
                    No. Adapter does not use your data to serve you ads. Your information serves you, not the other way around.
                    Adapter does not analyze your data for any purpose that isn&apos;t directly beneficial to you.
                </Text>

                <Text fontSize='l' style={headerStyle}>Does Adapter track my product usage?</Text>
                <Text>
                    Adapter&apos;s private preview uses data processing and analytics to improve product interactions for our customer
                    as well as for debug support issues. User session recordings capture information including Adapter pages displayed,
                    form interactions, console logs, network requests, technical data, and DOM changes. Passwords and sensitive fields
                    are not captured. Only interactions with Adapter&apos;s website and application are tracked and this data never
                    leaves Adapter managed infrastructure. This data is never shared, sold or transferred to another party.
                </Text>

                <Text fontSize='l' style={headerStyle}>How does Adapter use my data?</Text>
                <Text>
                    Adapter analyzes your imported data to extract specific details like flights, hotel stays, and expenses that it can
                    use to understand your patterns and preferences. It uses this understanding to power features that make your life
                    easier, like a matching tool that finds you the best flight for a trip. Adapter may also reprocess your data in
                    the future to refine its understanding of you over time.
                </Text>

                <Text fontSize='l' style={headerStyle}>How does Adapter store my data?</Text>
                <Text>
                    Adapter only stores data that can be useful to you and that you have given it permission to access. This data is
                    stored in an encrypted format on Adapter&apos;s hosted servers.
                </Text>

                <Text fontSize='l' style={headerStyle}>What data does Adapter import?</Text>
                <Text>
                    You decide what data we can access and use. When you connect a 3rd party account to Adapter, you are giving Adapter
                    permission to access a scoped set of data from that product. Once granted access, Adapter imports your historical
                    user data as well as any new pieces of data when they are created.
                </Text>

                <Text fontSize='l' style={headerStyle}>How do Adapter’s data category filters work?</Text>
                <Text>
                    When Adapter imports your data it uses various methods to automatically sort it into categories. Then it creates an
                    encrypted copy of the data that matches the categories and time range you have decided to share. Any data that
                    does not match your selection is securely deleted.
                </Text>

                <Text fontSize='l' style={headerStyle}>Who can access my data?</Text>
                <Text>
                    Only you can access your data via Adapter. Internally, data access is restricted to Adapter employees for incident-response
                    purposes only and monitoring systems ensure that access to data is always limited, time bound, and audited.
                    We have implemented strict programmatic access controls to compartmentalize information within our systems and
                    keep your information private.
                </Text>

                <Text fontSize='l' style={headerStyle}>Can I delete data after adding it to Adapter?</Text>
                <Text>
                    You may revoke access to 3rd party accounts at any time. If you disconnect an account, Adapter will no longer be able to
                    import data from that account, including both new and historical data. However, any data already imported from this account,
                    will not be deleted and may still be re-analyzed. Information extracted from this data or generated from your interactions
                    with this data via Adapter can also not be deleted.
                </Text>

                <Text>
                    You may request that all of your data and account be deleted at any time by contacting customer support.
                </Text>
            </div>

            <div className="onboarding-modal-foot">
                <Button onClick={onClose}>Close</Button>
            </div>
        </div>
    </Modal>
);

export default SafeguardsModal;