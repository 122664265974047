import { useState, useCallback } from 'react';
import { scrollToTop } from '../constants';

interface NavigationItem {
    type: 'category' | 'tiletype' | 'preference';
    id: string;
}

interface UseAtlasNavigationProps {
    onBack?: () => void;
}

export const useAtlasNavigation = ({ onBack }: UseAtlasNavigationProps = {}) => {
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [selectedTileType, setSelectedTileType] = useState<string | null>(null);
    const [selectedPreference, setSelectedPreference] = useState<string | null>(null);
    const [selectedCategoryType, setSelectedCategoryType] = useState<string | null>(null);
    const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
    const [navigationHistory, setNavigationHistory] = useState<NavigationItem[]>([]);

    const handleBack = useCallback(() => {
        setNavigationHistory(prev => {
            const newHistory = [...prev];
            newHistory.pop();
            const lastItem = newHistory[newHistory.length - 1];

            if (!lastItem) {
                setSelectedCategory(null);
                setSelectedTileType(null);
                setSelectedPreference(null);
                setBreadcrumbs([]);
                setNavigationHistory([]);
                return [];
            }

            if (lastItem.type === 'category') {
                setSelectedCategory(lastItem.id);
                setSelectedTileType(null);
                setSelectedPreference(null);
            }

            if (lastItem.type === 'tiletype') {
                setSelectedTileType(lastItem.id);
                setSelectedPreference(null);
            }

            if (lastItem.type === 'preference') {
                setSelectedPreference(lastItem.id);
            }

            setBreadcrumbs(prev => prev.slice(0, -1));
            return newHistory;
        });

        scrollToTop();
        onBack?.();
    }, [onBack]);

    const handleBreadcrumbClick = useCallback((index: number) => {
        if (index === -1) {
            setSelectedCategory(null);
            setSelectedTileType(null);
            setSelectedPreference(null);
            setBreadcrumbs([]);
            setNavigationHistory([]);
            return;
        }

        setNavigationHistory(prev => {
            const newHistory = prev.slice(0, index + 1);
            const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
            const lastItem = newHistory[newHistory.length - 1];

            setSelectedCategory(lastItem?.type === 'category' ? lastItem.id : selectedCategory);
            setSelectedTileType(lastItem?.type === 'tiletype' ? lastItem.id : null);
            setSelectedPreference(lastItem?.type === 'preference' ? lastItem.id : null);
            setBreadcrumbs(newBreadcrumbs);

            return newHistory;
        });

        scrollToTop();
    }, [breadcrumbs, selectedCategory]);

    const updateNavigation = useCallback((
        category: string | null,
        tileType: string | null,
        preference: string | null,
        categoryType: string | null,
        newBreadcrumb: string
    ) => {
        setSelectedCategory(category);
        setSelectedTileType(tileType);
        setSelectedPreference(preference);
        setSelectedCategoryType(categoryType);
        setBreadcrumbs(prev => [...prev, newBreadcrumb]);
        setNavigationHistory(prev => {
            const newItem: NavigationItem = {
                type: preference ? 'preference' : tileType ? 'tiletype' : 'category',
                id: preference || tileType || category || ''
            };
            return [...prev, newItem];
        });
    }, []);

    return {
        selectedCategory,
        selectedTileType,
        selectedPreference,
        selectedCategoryType,
        breadcrumbs,
        navigationHistory,
        handleBack,
        handleBreadcrumbClick,
        updateNavigation
    };
}; 