import React from 'react';
import { BreadcrumbNav, BreadcrumbItem, BreadcrumbSeparator } from './StyledComponents';
import { AtlasPageState } from '../types';
import { sampleData } from '../data';

interface BreadcrumbProps {
    state: AtlasPageState;
    onBreadcrumbClick: (index: number) => void;
}

export class Breadcrumb extends React.Component<BreadcrumbProps> {
    renderBreadcrumbMeta = (index: number) => {
        const { selectedCategory } = this.props.state;
        if (index === 0 && selectedCategory) {
            const category = sampleData.categories.find(c => c.id === selectedCategory);
            if (category?.type === 'Travel' || category?.type === 'Dining') {
                const lastUpdated = this.props.state.lastUpdated[category?.type];
                if (lastUpdated) {
                    return (
                        <span style={{ fontSize: '12px', color: '#666', marginLeft: '8px' }}>
                            (Updated {new Date(lastUpdated).toLocaleTimeString()})
                        </span>
                    );
                }
            }
        }
        return null;
    };

    render() {
        return (
            <BreadcrumbNav>
                <BreadcrumbItem onClick={() => this.props.onBreadcrumbClick(-1)}>
                    Home
                </BreadcrumbItem>
                {this.props.state.breadcrumbs.map((crumb, index) => (
                    <React.Fragment key={index}>
                        <BreadcrumbSeparator>›</BreadcrumbSeparator>
                        <BreadcrumbItem
                            onClick={() => this.props.onBreadcrumbClick(index)}
                            isActive={index === this.props.state.breadcrumbs.length - 1}
                        >
                            {crumb}
                            {this.renderBreadcrumbMeta(index)}
                        </BreadcrumbItem>
                    </React.Fragment>
                ))}
            </BreadcrumbNav>
        );
    }
} 