import React, { useEffect, useRef } from 'react';

// ===== Type Definitions =====
interface Vector2D {
  x: number;
  y: number;
}

interface CornerTangent {
  P_in: Vector2D;
  P_out: Vector2D;
  center: Vector2D;
  theta: number;
}

// ===== Configuration =====
const CONFIG = {
  // Animation settings
  rotationSpeed: 0.5,        // Global rotation (radians per second)
  morphDuration: 2,          // Seconds per morph transition
  pipeRadius: 12,            // Node radius in pixels
  gap: 8,                    // Gap between nodes for rounded corners
  
  // Shape settings
  baseScale: 0.3,            // Relative to container size
  minSides: 3,               // Minimum number of sides in sequence
  maxSides: 7,               // Maximum number of sides in sequence
  
  // Colors
  fillColor: "#B5B0FF",      // More vibrant lavender fill
  nodeColor: "#FFFFFF",      // White nodes
  
  // Effects
  blurRadius: 6,             // Edge blur radius in pixels
  shadowColor: "rgba(137, 123, 255, 0.35)", // Shadow color for depth
  innerGlowColor: "rgba(246, 244, 255, 0.8)" // Inner glow for shine
};

// Compute effective corner radius based on pipe radius and gap
const R_corner = CONFIG.pipeRadius + CONFIG.gap;

// ===== Vector Math Utility Functions =====
const vec = (x: number, y: number): Vector2D => ({ x, y });

const add = (a: Vector2D, b: Vector2D): Vector2D => ({ 
  x: a.x + b.x, 
  y: a.y + b.y 
});

const sub = (a: Vector2D, b: Vector2D): Vector2D => ({ 
  x: a.x - b.x, 
  y: a.y - b.y 
});

const scale = (v: Vector2D, s: number): Vector2D => ({ 
  x: v.x * s, 
  y: v.y * s 
});

const length = (v: Vector2D): number => Math.hypot(v.x, v.y);

const normalize = (v: Vector2D): Vector2D => {
  const len = length(v);
  return len < 0.0001 
    ? { x: 0, y: 0 } 
    : { x: v.x / len, y: v.y / len };
};

const dot = (a: Vector2D, b: Vector2D): number => a.x * b.x + a.y * b.y;

const angleOf = (v: Vector2D): number => Math.atan2(v.y, v.x);

const cot = (a: number): number => Math.cos(a) / Math.sin(a);

// Linear interpolation between two points
const lerp = (a: Vector2D, b: Vector2D, u: number): Vector2D => ({
  x: a.x + (b.x - a.x) * u,
  y: a.y + (b.y - a.y) * u
});

// Returns the difference between two angles in [0,2π)
const angleDiff = (a: number, b: number): number => {
  let diff = b - a;
  while (diff < 0) diff += 2 * Math.PI;
  while (diff >= 2 * Math.PI) diff -= 2 * Math.PI;
  return diff;
};

// Ease in/out for smooth transitions
const easeInOut = (u: number): number => 
  u < 0.5 ? 2 * u * u : -1 + (4 - 2 * u) * u;

// Remove nearly duplicate consecutive vertices
const removeDuplicateVertices = (verts: Vector2D[], tol = 0.5): Vector2D[] => {
  if (verts.length === 0) return verts;
  let cleaned = [verts[0]];
  for (let i = 1; i < verts.length; i++) {
    if (length(sub(verts[i], cleaned[cleaned.length - 1])) > tol) {
      cleaned.push(verts[i]);
    }
  }
  if (cleaned.length > 1 && length(sub(cleaned[0], cleaned[cleaned.length - 1])) < tol) {
    cleaned.pop();
  }
  return cleaned;
};

// ===== Flexagon Component =====
const Flexagon: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationFrameId = useRef<number | null>(null);
  const irregularMultipliersRef = useRef<Record<number, number[]>>({});
  
  // ===== Create Side Sequence =====
  const createSideSequence = (): number[] => {
    const { minSides, maxSides } = CONFIG;
    const ascending = Array.from(
      { length: maxSides - minSides + 1 }, 
      (_, i) => minSides + i
    );
    const descending = [...ascending].reverse().slice(1);
    return [...ascending, ...descending];
  };
  
  // Generate sequence of polygon sides to morph through
  const sideSequence = createSideSequence();
  const totalTransitions = sideSequence.length - 1;
  const totalCycleTime = CONFIG.morphDuration * totalTransitions;
  
  // Get multipliers for irregular polygons
  const getIrregularMultipliers = (sides: number): number[] => {
    if (sides === 3) return [1, 1, 1];
    
    if (irregularMultipliersRef.current[sides]) {
      return irregularMultipliersRef.current[sides];
    }
    
    const arr: number[] = [];
    for (let i = 0; i < sides; i++) {
      // Using 1 for rigid behavior
      arr.push(1);
    }
    
    irregularMultipliersRef.current[sides] = arr;
    return arr;
  };
  
  // Generate polygon vertices
  const getPolygonVertices = (
    sides: number, 
    cx: number, 
    cy: number, 
    baseRadius: number, 
    overallRotation: number
  ): Vector2D[] => {
    const verts: Vector2D[] = [];
    
    if (sides === 3) {
      for (let i = 0; i < sides; i++) {
        const angle = overallRotation + (2 * Math.PI * i / sides) - Math.PI / 2;
        verts.push(vec(
          cx + baseRadius * Math.cos(angle),
          cy + baseRadius * Math.sin(angle)
        ));
      }
    } else {
      const multipliers = getIrregularMultipliers(sides);
      
      for (let i = 0; i < sides; i++) {
        const angle = overallRotation + (2 * Math.PI * i / sides) - Math.PI / 2;
        const r = baseRadius * multipliers[i];
        verts.push(vec(
          cx + r * Math.cos(angle),
          cy + r * Math.sin(angle)
        ));
      }
    }
    
    return verts;
  };
  
  // Build a morphing polygon
  const buildMorphPolygon = (
    startSides: number, 
    endSides: number, 
    cx: number, 
    cy: number, 
    baseRadius: number, 
    overallRotation: number, 
    u: number
  ): Vector2D[] => {
    let morphVerts: Vector2D[] = [];
    
    if (endSides > startSides) {
      // GROWING: from n to n+1 vertices
      const v = getPolygonVertices(startSides, cx, cy, baseRadius, overallRotation);
      const w = getPolygonVertices(startSides + 1, cx, cy, baseRadius, overallRotation);
      
      // Calculate the distance at which nodes should be considered separate
      // This threshold determines when a new node emerges
      const separationThreshold = CONFIG.pipeRadius * 2;
      
      // First, add the stable node (first vertex)
      w[0] = { x: v[0].x, y: v[0].y };
      morphVerts.push(v[0]);
      
      // Handle the splitting of the first node into two nodes (node birth)
      // Instead of an abrupt split at halfway, make it more elegant
      const birthProgress = Math.max(0, u - 0.4) / 0.6; // Start split at 40% through animation
      
      if (birthProgress > 0) {
        // Apply easing for a more natural birth
        const easedBirth = Math.pow(birthProgress, 1.5); // Start slow, then accelerate
        const newNodePos = lerp(v[0], w[1], easedBirth);
        
        // Only add the new node if it has separated enough from its parent
        if (length(sub(newNodePos, v[0])) > separationThreshold) {
          morphVerts.push(newNodePos);
        }
      }
      
      // Handle the rest of the vertices
      for (let i = 1; i < v.length; i++) {
        // Each vertex moves to its new position in the target shape
        const targetIndex = i + 1; // Offset by 1 because we inserted a new vertex
        const lerpedPos = lerp(v[i], w[targetIndex], u);
        morphVerts.push(lerpedPos);
      }
    } else if (endSides < startSides) {
      // SHRINKING: from n to n-1 vertices
      const v = getPolygonVertices(startSides, cx, cy, baseRadius, overallRotation);
      const w = getPolygonVertices(startSides - 1, cx, cy, baseRadius, overallRotation);
      
      // First node is stable
      w[0] = { x: v[0].x, y: v[0].y };
      morphVerts.push(v[0]);
      
      // Calculate the distance at which nodes should be considered merged
      // This is when their perimeters touch (2 * pipeRadius)
      const mergeThreshold = CONFIG.pipeRadius * 2;
      
      // Second node (the one that merges into first node)
      const secondNodeDist = length(sub(v[1], v[0]));
      const currentDist = secondNodeDist * (1 - u);
      
      // Only include the second vertex if it hasn't reached the merge threshold
      if (currentDist > mergeThreshold) {
        // Calculate smoother easing for the merge
        const easedProgress = Math.pow(u, 1.25); // Slightly accelerate the merge
        const mergePosition = lerp(v[1], v[0], easedProgress);
        morphVerts.push(mergePosition);
      }
      
      // Process the rest of the vertices
      for (let i = 2; i < v.length; i++) {
        const targetIndex = i - 1;
        const targetNode = w[targetIndex];
        const currentNode = v[i];
        const lerpedPos = lerp(currentNode, targetNode, u);
        
        // Check if this node has reached its merge threshold with any other node
        let shouldAdd = true;
        for (const existingVertex of morphVerts) {
          if (length(sub(lerpedPos, existingVertex)) <= mergeThreshold) {
            shouldAdd = false;
            break;
          }
        }
        
        if (shouldAdd) {
          morphVerts.push(lerpedPos);
        }
      }
    } else {
      morphVerts = getPolygonVertices(startSides, cx, cy, baseRadius, overallRotation);
    }
    
    return morphVerts;
  };
  
  // Draw the Flexagon with rounded corners and nodes
  const drawFlexagon = (vertices: Vector2D[]): void => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    
    vertices = removeDuplicateVertices(vertices);
    const n = vertices.length;
    const tangents: CornerTangent[] = [];
    const eps = 0.001;
    
    // Calculate tangent points for rounded corners
    for (let i = 0; i < n; i++) {
      const curr = vertices[i];
      const prev = vertices[(i - 1 + n) % n];
      const next = vertices[(i + 1) % n];
      const v1 = sub(curr, prev);
      const v2 = sub(next, curr);
      
      if (length(v1) < eps || length(v2) < eps) {
        tangents.push({ P_in: curr, P_out: curr, center: curr, theta: 0 });
        continue;
      }
      
      const e1 = normalize(v1);
      const e2 = normalize(v2);
      let theta = Math.acos(dot(scale(e1, -1), e2));
      
      if (Math.abs(theta) < eps) theta = eps;
      
      const d = R_corner * cot(theta / 2);
      const P_in = sub(curr, scale(e1, d));
      const P_out = add(curr, scale(e2, d));
      const bisector = normalize(add(scale(e1, -1), e2));
      const center = add(curr, scale(bisector, R_corner / Math.sin(theta / 2)));
      
      tangents.push({ P_in, P_out, center, theta });
    }
    
    // Save the context for clipping
    ctx.save();
    
    // Create path for the main shape with rounded corners
    ctx.beginPath();
    ctx.moveTo(tangents[0].P_out.x, tangents[0].P_out.y);
    
    for (let i = 0; i < n; i++) {
      const next = (i + 1) % n;
      ctx.lineTo(tangents[next].P_in.x, tangents[next].P_in.y);
      
      const { center, P_in, P_out, theta } = tangents[next];
      let startAngle = angleOf(sub(P_in, center));
      let endAngle = angleOf(sub(P_out, center));
      const expectedSweep = Math.PI - theta;
      let diff = angleDiff(startAngle, endAngle);
      let anticlockwise = false;
      
      if (Math.abs(diff - expectedSweep) > 0.001 && diff > Math.PI) {
        anticlockwise = true;
      }
      
      ctx.arc(center.x, center.y, R_corner, startAngle, endAngle, anticlockwise);
    }
    ctx.closePath();
    
    // Create a clip path for the inner glow
    ctx.save();
    ctx.clip();
    
    // Draw inner glow
    const cx = canvas.width / 2;
    const cy = canvas.height / 2;
    const gradient = ctx.createRadialGradient(
      cx, cy, 0, 
      cx, cy, Math.min(canvas.width, canvas.height) * 0.3
    );
    gradient.addColorStop(0, CONFIG.innerGlowColor);
    gradient.addColorStop(1, CONFIG.fillColor);
    
    // Fill with gradient
    ctx.fillStyle = gradient;
    ctx.fill();
    ctx.restore();
    
    // Draw high-quality blurred edges
    ctx.shadowColor = CONFIG.shadowColor;
    ctx.shadowBlur = CONFIG.blurRadius;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    
    // Slightly smaller stroke to create a subtle inner border
    ctx.lineWidth = 1.5;
    ctx.strokeStyle = CONFIG.fillColor;
    ctx.stroke();
    
    // Draw the nodes at each corner with a subtle shadow
    for (let i = 0; i < n; i++) {
      const { center } = tangents[i];
      
      // Draw node shadow
      ctx.save();
      ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
      ctx.shadowBlur = 4;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 1;
      ctx.beginPath();
      ctx.arc(center.x, center.y, CONFIG.pipeRadius, 0, 2 * Math.PI);
      ctx.fillStyle = CONFIG.nodeColor;
      ctx.fill();
      ctx.restore();
      
      // Draw node highlight
      ctx.beginPath();
      ctx.arc(center.x, center.y - 1, CONFIG.pipeRadius - 2, 0, 2 * Math.PI);
      ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
      ctx.fill();
    }
    
    // Restore the context
    ctx.restore();
  };
  
  // Animation loop
  const animate = (timeStamp: number): void => {
    const canvas = canvasRef.current;
    
    // Race condition check when the function gets called after canvas is removed
    if (!canvas) {
      return;
    }
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    
    const time = timeStamp / 1000;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    // Calculate animation parameters
    const overallRotation = time * CONFIG.rotationSpeed;
    const cx = canvas.width / 2;
    const cy = canvas.height / 2;
    const baseRadius = Math.min(canvas.width, canvas.height) * CONFIG.baseScale;
    
    // Determine progress within current morph phase
    const tCycle = time % totalCycleTime;
    const transitionIndex = Math.floor(tCycle / CONFIG.morphDuration);
    let u = (tCycle - transitionIndex * CONFIG.morphDuration) / CONFIG.morphDuration;
    u = easeInOut(u);
    
    const startSides = sideSequence[transitionIndex];
    const endSides = sideSequence[transitionIndex + 1];
    const morphPolygon = buildMorphPolygon(startSides, endSides, cx, cy, baseRadius, overallRotation, u);
    
    drawFlexagon(morphPolygon);
    animationFrameId.current = requestAnimationFrame(animate);
  };
  
  // Setup and cleanup
  useEffect(() => {
    const resize = (): void => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      
      const parent = canvas.parentElement;
      if (!parent) return;
      
      canvas.width = parent.clientWidth;
      canvas.height = parent.clientHeight;
    };
    
    resize();
    window.addEventListener('resize', resize);
    animationFrameId.current = requestAnimationFrame(animate);
    
    return () => {
      window.removeEventListener('resize', resize);
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);
  
  return (
    <canvas 
      ref={canvasRef} 
      style={{ width: '100%', height: '100%' }}
    />
  );
};

export default Flexagon;