import styled from 'styled-components';
import { motion } from 'framer-motion';

export const HeaderContainer = styled.div`
    margin-bottom: 2rem;
    position: relative;
`;

export const Title = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 0.5rem;
`;

export const Subtitle = styled.p`
    font-size: 1rem;
    color: #6B7280;
    margin-bottom: 1rem;
`;

export const Icon = styled.span`
    font-size: 2rem;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
`;

export const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    margin-top: 2rem;
`;

export const MainContent = styled.div`
    background: white;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

export const Card = styled.div`
    background: white;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

export const CardTitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 1rem;
`;

export const ValueContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
`;

export const Value = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    color: #111827;
`;

export const EditButton = styled.button`
    background: none;
    border: none;
    color: #6B7280;
    font-size: 0.875rem;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: color 0.2s;

    &:hover {
        color: #4B5563;
    }
`;

export const HistoryItem = styled.div`
    padding: 1rem;
    border-bottom: 1px solid #E5E7EB;

    &:last-child {
        border-bottom: none;
    }
`;

export const HistoryProvider = styled.div`
    font-weight: 500;
    color: #111827;
    margin-bottom: 0.25rem;
`;

export const HistoryDate = styled.div`
    font-size: 0.875rem;
    color: #6B7280;
`;

export const UnderstandingItem = styled.div`
    padding: 1rem;
    border-bottom: 1px solid #E5E7EB;

    &:last-child {
        border-bottom: none;
    }
`;

export const UnderstandingTitle = styled.div`
    font-weight: 500;
    color: #111827;
    margin-bottom: 0.25rem;
`;

export const UnderstandingValue = styled.div`
    font-size: 0.875rem;
    color: #6B7280;
`;

export const EditForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const Input = styled.input`
    padding: 0.5rem;
    border: 1px solid #E5E7EB;
    border-radius: 0.375rem;
    font-size: 1rem;
    width: 100%;

    &:focus {
        outline: none;
        border-color: #4F46E5;
        box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
`;

export const Button = styled.button<{ variant?: 'primary' | 'secondary' }>`
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;

    ${props => props.variant === 'primary' ? `
        background: #4F46E5;
        color: white;
        border: none;

        &:hover {
            background: #4338CA;
        }
    ` : `
        background: white;
        color: #6B7280;
        border: 1px solid #E5E7EB;

        &:hover {
            background: #F9FAFB;
        }
    `}
`;

// Base Styled Components
export const Container = styled(motion.div)`
    background: white;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    margin: 24px auto;
    overflow: hidden;
    width: calc(100% - 48px);
    max-width: 1200px;
`;

export const Header = styled.div`
    padding: 24px;
    background: #F4F3FF;
    border-bottom: 1px solid #E6E3FF;
`;

export const CategoryLabel = styled.div`
    color: #727271;
    font-family: "Rand Light", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 4px;
`;

export const PreferenceName = styled.h1`
    font-size: 28px;
    font-family: "Rand Medium", sans-serif;
    color: #171717;
    margin: 8px 0;
`;

export const PreferenceValue = styled.div`
    font-size: 20px;
    color: #333;
    font-family: "Rand Medium", sans-serif;
    margin-top: 8px;
`;

export const Section = styled.div`
    padding: 24px;
    border-top: 1px solid #EAEAEA;
`;

export const SectionTitle = styled.h2`
    font-size: 18px;
    font-family: "Rand Medium", sans-serif;
    color: #171717;
    margin-bottom: 16px;
`;

export const MemoryContent = styled.div`
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    font-family: "Rand Light", sans-serif;
    padding: 24px;
    white-space: pre-wrap;
`;

export const CategoryTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 16px;
`;

export const CategoryTag = styled.span`
    display: inline-block;
    padding: 4px 12px;
    background: #F4F3FF;
    border-radius: 12px;
    font-size: 12px;
    color: #666;
    font-family: "Rand Medium", sans-serif;
`;

export const TimelineSection = styled(Section)`
    background: #F9F9F9;
`;

export const TimelineItem = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 16px 0;
    
    &:not(:last-child) {
        border-bottom: 1px solid #EAEAEA;
    }
`;

export const TimelineIcon = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #E6E3FF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

export const TimelineContent = styled.div`
    flex: 1;
`;

export const TimelineTitle = styled.div`
    font-size: 14px;
    color: #333;
    font-family: "Rand Medium", sans-serif;
    margin-bottom: 4px;
`;

export const DateText = styled.div`
    font-size: 12px;
    color: #999;
    font-family: "Rand Light", sans-serif;
`;

export const FlightGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    padding: 24px;
`;

export const RouteCard = styled.div`
    background: white;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

export const RouteIcon = styled.div`
    font-size: 24px;
`;

export const DiningSection = styled(Section)`
    background: #FFF8F5;
`;

export const CuisineTag = styled(CategoryTag)`
    background: #FFF0E8;
    color: #D4691E;
`;

export const LodgingSection = styled(Section)`
    background: #F5F9FF;
`;

export const PropertyCard = styled.div`
    background: white;
    border-radius: 12px;
    padding: 16px;
    border-left: 4px solid #4A90E2;
`;

export const PurchaseSection = styled(Section)`
    background: #F5FFF7;
`;

export const MetricCard = styled.div`
    background: white;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const MetricLabel = styled.div`
    font-size: 12px;
    color: #666;
    font-family: "Rand Light", sans-serif;
`;

export const MetricValue = styled.div`
    font-size: 24px;
    color: #171717;
    font-family: "Rand Medium", sans-serif;
`;

export const MetricGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
    margin-top: 16px;
`;

export const ConfidenceIndicator = styled.div<{ score: number }>`
    display: inline-block;
    padding: 4px 8px;
    background: ${props => {
        if (props.score >= 0.8) return '#E6FFE6';
        if (props.score >= 0.6) return '#FFF3E6';
        return '#FFE6E6';
    }};
    color: ${props => {
        if (props.score >= 0.8) return '#28A745';
        if (props.score >= 0.6) return '#FD7E14';
        return '#DC3545';
    }};
    border-radius: 4px;
    font-size: 12px;
    font-family: "Rand Medium", sans-serif;
`;

export const MetadataGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
    margin-top: 16px;
`;

export const MetadataItem = styled.div`
    background: white;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
`;

export const MetadataLabel = styled.div`
    font-size: 12px;
    color: #666;
    margin-bottom: 4px;
    font-family: "Rand Light", sans-serif;
`;

export const MetadataValue = styled.div`
    font-size: 14px;
    color: #333;
    font-family: "Rand Medium", sans-serif;
    word-break: break-word;
    white-space: pre-wrap;
`; 