import React from 'react';
import Card from 'components/common/Card';

const TextOption = ({ text }: { text: string }) => {
    return (
        <Card style={{
            borderRadius: '12px',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.05), 0px 4px 8px rgba(16, 24, 40, 0.03)',
            padding: '20px',
            transition: 'all 0.2s ease',
        }}>
            <p style={{
                fontSize: '14px',
                lineHeight: '1.5',
                color: '#374151',
                fontWeight: 400,
                margin: 0
            }}>{text}</p>
        </Card>
    );
};

export default TextOption;