import * as Bowser from "bowser";

import { getPostHog } from './posthogWrapper';

import API from "./API";
import { getCurrentPosition } from './LocationService';
import { LocationServiceError } from './LocationServiceBridge';
import TelemetryStore from 'stores/TelemetryStore';

type ClientTelemetry = {
    // the timestamp of telemetry collection
    // unix timestamp (Seconds since Jan 01 1970, UTC)
    timestamp: number;

    clientInfo?: {
        type?: string; // 'browser'? 'mobile'?

        userAgentString?: string; // 'Chrome x.y.x'

        metadata?: any; // specific denormalized data
    }

    geolocation?: GeolocationCoordinates;

    datetime?: {
        locale?: string; // e.g. 'en-US'

        timezone?: string; // e.g. 'America/Chicago'

        // the difference, in minutes, between a date as evaluated in UTC, 
        // and the same date as evaluated in the user's local timezone
        timezoneOffset?: number;
    }

    networkInfo?: {
        // bandwidth estimate in megabits per second, 
        // rounded to the nearest multiple of 25 kilobits per seconds
        downlink?: number;

        // estimated effective round-trip time of the current connection, 
        // rounded to the nearest multiple of 25 milliseconds		
        rtt?: number;

        speed?: 'slow-2g' | '2g' | '3g' | '4g';

        type?: 'bluetooth' | 'cellular' | 'ethernet' | 'none' | 'wifi' | 'wimax' | 'other' | 'unknown';

        // client's IP address
        ipAddress?: string;
    }

    batteryInfo?: {
        // remaining time in seconds until the battery is fully charged, 
        // or 0 if the battery is already fully charged
        timeToFull?: number;

        // remaining time in seconds until the battery is expired and the system suspends
        timeToEmpty?: number;

        // A number scaled to represent the battery charge level, 0.0 to 1.0
        level?: number;
    }
}

let userTelemetry: ClientTelemetry = {
    timestamp: Date.now(),
    clientInfo: {
        type: 'browser',
        userAgentString: window.navigator.userAgent,
        metadata: Bowser.parse(window.navigator.userAgent)
    },
    batteryInfo: {},
    datetime: {},
    networkInfo: {},
    geolocation: {} as GeolocationCoordinates,
};

const getDatetimeData = () => {
    return new Promise((resolve, reject) => {
        Object.assign(userTelemetry.datetime, {
            locale: Intl.DateTimeFormat().resolvedOptions().locale,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timezoneOffset: new Date().getTimezoneOffset()
        });
        resolve(null);
    });
}

const getNetworkInfo = () => {
    return new Promise((resolve, reject) => {
        if ("connection" in navigator) {
            // @ts-ignore
            const { downlink, rtt, effectiveType, type } = navigator.connection;
            Object.assign(userTelemetry.networkInfo, {
                speed: effectiveType,
                downlink,
                rtt,
                type
            });
        } else {
            console.warn('NETWORKINFO is not available in this browser');
        }

        resolve(null);
    });
}

const getBatteryInfo = () => {
    return new Promise((resolve, reject) => {
        // @ts-ignore
        if (navigator.getBattery) {
            // @ts-ignore
            navigator.getBattery().then(resolve).catch(reject);
            // @ts-ignore
        } else if (navigator.battery) { // Older Chrome/Firefox
            // @ts-ignore
            resolve(navigator.battery);
            // @ts-ignore
        } else if (navigator.webkitBattery) { // Older Safari
            // @ts-ignore
            resolve(navigator.webkitBattery);
            // @ts-ignore
        } else if (navigator.mozBattery) { // Older Firefox
            // @ts-ignore
            resolve(navigator.mozBattery);
        } else {
            console.warn("Battery API not supported in this browser.");
            resolve(null);
        }
    })
        .then((battery) => {
            if (!battery) {
                return;
            }

            // @ts-ignore
            const { level, chargingTime, dischargingTime } = battery;

            Object.assign(userTelemetry.batteryInfo, {
                timeToFull: chargingTime,
                timeToEmpty: dischargingTime,
                level
            });
        });
};

const getGeolocationInfo = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const posthog = await getPostHog();
            if (!posthog?.isFeatureEnabled('client-geolocation')) {
                console.warn('client-geolocation feature flag is disabled, will not gather that telemetry data');
                resolve(null);
                return;
            }
        } catch (e) {
            console.warn('Error waiting on posthog. client-geolocation is assumed disabled');
            resolve(null);
            return;
        }

        const assignGeolocationData = (position) => {
            const {
                latitude,
                longitude,
                accuracy,
                altitude,
                altitudeAccuracy,
                heading,
                speed
            } = position.coords;

            Object.assign(userTelemetry.geolocation, {
                accuracy,
                latitude,
                longitude,
                altitudeAccuracy,
                altitude,
                heading,
                speed
            });

            resolve(null);
        }

        const onGeoLocationFailure = (err) => {
            console.error('GEOLOCATION error: ', err);
            reject();
        };

        // Unsure if using getCurrentPosition().then.catch is better than turning 
        // `getGeolocationInfo()` itself into an async function.
        getCurrentPosition()
            .then(assignGeolocationData)
            .catch(error => {
                // Handle any errors from either native or browser implementation
                if (error instanceof LocationServiceError) {
                    console.warn("GeolocationError: ${error.message}");
                    onGeoLocationFailure(error);
                } else {
                    console.error('Unexpected error getting location:', error);
                    onGeoLocationFailure(new Error('Failed to get location'));
                }
            });
    });
}

export const sendUserTelemetry = () => {
    API.post('/api/user/telemetry', userTelemetry)
        .then(() => {
            // cache the geolocation so we can display it on maps later without having to request it
            const location = {
                timestamp: userTelemetry.timestamp,
                option: {
                    gps_coordinates: {
                        lat: userTelemetry?.geolocation?.latitude,
                        lng: userTelemetry?.geolocation?.longitude
                    }
                }
            };

            if (userTelemetry?.geolocation?.latitude && userTelemetry?.geolocation?.longitude) {
                TelemetryStore.setLastKnownLocation(location);
            }
        })
        .catch(e => {
            console.error('TELEMETRY ERROR: ', e)
        });
}

export const getUserTelemetry = () => {
    return userTelemetry;
}

export const initTelemetry = () => {
    Promise.all([
        getDatetimeData(),
        getNetworkInfo(),
        getBatteryInfo(),
        getGeolocationInfo()
    ])
        .then(() => {
            // update timestamp for accuracy
            userTelemetry.timestamp = Date.now();
            sendUserTelemetry();
        })
        .catch((e) => {
            console.error('initTelemetry() encountered a problem', e);
        });
}