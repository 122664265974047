import React, { ChangeEvent, FormEvent } from 'react';
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";

import withRouter from 'hooks/withRouter';
import StepHeader from './StepHeader';
import Card from 'components/common/Card';
import BaseForm from 'components/forms/BaseForm';
import OnboardingWrapper from '../index';
import VerifyPhoneModal from '../modals/VerifyPhoneModal';
import Loading from 'components/common/Loading';
import { Text, H3Text } from 'components/common/Typography';
import { isValidOnboardingDate } from 'util/Time';

type AccountDetailsProps = {
    UserStore?: any;
    router?: any;
};

type AccountDetailsState = {
    first_name: string | undefined;
    last_name: string | undefined;
    phone: string | undefined;
    email: string | undefined;
    homeAddress: string | undefined;
    dateOfBirth: string | undefined;
    showModal: boolean;
    isLoading: boolean;
}

class AccountDetails extends React.Component<AccountDetailsProps, AccountDetailsState> {
    state = {
        first_name: undefined,
        last_name: undefined,
        phone: undefined,
        email: undefined,
        homeAddress: undefined,
        dateOfBirth: undefined,
        showModal: false,
        isLoading: false
    };

    componentDidMount(): void {
        const { UserStore } = this.props;

        this.setState({
            first_name: UserStore.user.first_name || undefined,
            last_name: UserStore.user.last_name || undefined,
            phone: UserStore.user.phone || undefined,
            email: UserStore.user.email || undefined,
            homeAddress: UserStore.user.metadata.homeAddress || undefined,
            dateOfBirth: UserStore.user.metadata.dateOfBirth || undefined
        });
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        let newValue = value;

        if (name === 'dateOfBirth') {
            // only let the user keep numeric input, we'll handle the rest
            newValue = value.replace(/[^0-9]/g, '').substring(0, 8);

            const len = newValue.length;
            let formatted = "";

            if (len > 0) {
                formatted += newValue.slice(0, Math.min(2, len)); // Up to 2 digits for MM
                if (len > 2) {
                    formatted += "-" + newValue.slice(2, Math.min(4, len)); // Up to 2 digits for DD
                    if (len > 4) {
                        formatted += "-" + newValue.slice(4, Math.min(8, len)); // Up to 4 digits for YYYY
                    }
                }
            }

            newValue = formatted;
        }

        // @ts-ignore
        this.setState({ [name]: newValue });
    };

    updateUser = () => {
        const { UserStore } = this.props;
        const { email, first_name, last_name, phone, homeAddress, dateOfBirth } = this.state;

        UserStore.updateUser({
            // @ts-ignore
            email: email.toLowerCase(),
            first_name,
            last_name,
            phone,
            metadata: { homeAddress, dateOfBirth }
        })
            .then(this.requestPasscode)
            .catch(() => {
                this.setState({ isLoading: false }, () => {
                    toast.error("An error occurred while trying to save your account details.", { icon: false });
                });
            });
    }

    requestPasscode = () => {
        const { UserStore } = this.props;
        const { phone } = this.state;

        UserStore.requestPhoneVerification(phone)
            .then(() => {
                this.setState({ showModal: true, isLoading: false });
            })
            .catch(() => {
                this.setState({ isLoading: false }, () => {
                    toast.error("An error occurred while trying to send a verification code to your mobile phone.", { icon: false });
                });
            })
    }

    onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.setState({ isLoading: true }, this.updateUser);
    };

    render() {
        const { UserStore, router } = this.props;
        const { phone, first_name, last_name, homeAddress, dateOfBirth, showModal, isLoading } = this.state;

        const hasDobError = !isValidOnboardingDate(dateOfBirth);

        const config = [
            {
                labelText: "First Name*",
                fieldId: "first_name",
                type: "text",
                value: first_name,
                required: true,
                errorText: first_name !== undefined && first_name?.length === 0 ? "First name is required" : undefined
            },
            {
                labelText: "Last Name*",
                fieldId: "last_name",
                type: "text",
                value: last_name,
                required: true,
                errorText: last_name !== undefined && last_name?.length === 0 ? "Last name is required" : undefined
            },
            {
                labelText: "Phone Number*",
                fieldId: "phone",
                type: "text",
                value: phone,
                required: true,
                errorText: phone !== undefined && phone?.length === 0 ? "Mobile phone number is required" : undefined
            },
            {
                labelText: "Birth Date*",
                fieldId: "dateOfBirth",
                type: "dob",
                value: dateOfBirth,
                required: true,
                errorText: dateOfBirth !== undefined && hasDobError ? "DOB is required in MM-DD-YYYY format" : undefined
            },
            {
                cls: 'col-span-2',
                labelText: "Home Address*",
                fieldId: "homeAddress",
                type: "address",
                value: homeAddress,
                required: true,
                errorText: homeAddress !== undefined && homeAddress?.length === 0 ? "Home address is required" : undefined
            },
        ];

        return (
            <>
                <StepHeader
                    progress={40}
                    text='Your Details'
                    onClick={() => router.navigate('/onboarding/trust')}
                />

                {showModal && (
                    <VerifyPhoneModal
                        onClose={() => this.setState({ showModal: false, isLoading: false })}
                        onRetry={
                            () => UserStore.requestPhoneVerification(phone)
                                .then(() => {
                                    toast.success("Verification code sent to your mobile phone.", { icon: false });
                                })
                                .catch(() => {
                                    toast.error("An error occurred while trying to send a verification code to your mobile phone.", { icon: false });
                                })
                        }
                    />
                )}
                {isLoading && <Loading />}

                <Card>
                    <div className="adapter-two-col">
                        <div className="adapter-two-col--title">
                            <div className="flex-1"></div>

                            <H3Text cls='adapter-font-color-primary-900'>
                                Account details
                            </H3Text>

                            <Text>
                                These details are used to verify user eligibility, secure your account,
                                and serve you relevant results. We will never sell your data. No matter what.
                                You must be at least 18 years of age to use Adapter.
                            </Text>

                            <div className="flex-1"></div>
                        </div>

                        <div className="adapter-two-col--content">
                            <div className="flex-1"></div>

                            <BaseForm
                                config={config}
                                isLoading={UserStore.isLoading}
                                buttonText="Continue"
                                onChange={this.onChangeHandler}
                                onSubmit={this.onSubmitHandler}
                                style={{ alignSelf: 'stretch' }}
                                cls='flex md:!grid md:!grid-cols-2'
                            />

                            <div className="flex-1"></div>
                        </div>
                    </div>
                </Card>
            </>
        );
    }
}

const AccountDetailsWithStores = withRouter(inject("UserStore")(observer(AccountDetails)));

const Wrapper = () => (
    <OnboardingWrapper>
        <AccountDetailsWithStores />
    </OnboardingWrapper>
);

export default Wrapper;