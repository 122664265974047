import Logger from 'util/Logger';
/**
 * Service Configuration 
 * 
 * This file provides simple, static configuration for service endpoints.
 */

// Get the domain and port number from the current URL
const currentUrl = new URL(window.location.href);

// Use static ports as requested
const FRONTEND_PORT = '3000'; // Frontend running on 3000
const BACKEND_PORT = '8000'; // Backend running on 8000

// Log port information
Logger.log(`[ServiceConfig] Using frontend port: ${FRONTEND_PORT}, backend port: ${BACKEND_PORT}`);

// Hostname detection - use current hostname (IP or localhost)
let hostname = currentUrl.hostname;

// If we have env vars with specific hostname, use that
if (process.env.REACT_APP_BACKEND_HOST) {
  hostname = process.env.REACT_APP_BACKEND_HOST;
  Logger.log(`[ServiceConfig] Using hostname from env: ${hostname}`);
}

// Construct the base URL for all services
const backendUrl = ``;

// API endpoints
export const serviceConfig = {
  // Base URL
  backendUrl,
  
  // Specific API endpoints
  graphApiUrl: `/api/graph`,
  aiApiUrl: `/api/anthropic`,
  ttsApiUrl: `/api/tts`,
  sttApiUrl: `/api/stt`,
  knowledgeGraphApiUrl: `/api/knowledge-graph`,
  
  // Configuration
  allowNetworkAccess: process.env.REACT_APP_ALLOW_NETWORK_ACCESS === 'true',
  
  // For debugging
  _debug: {
    frontendPort: FRONTEND_PORT,
    backendPort: BACKEND_PORT,
    hostname
  }
};

// Log the final configuration
Logger.log('[ServiceConfig] Configuration:', serviceConfig);

// Additional diagnostic output for 172.20.*.* network
if (hostname.startsWith('172.20.')) {
  Logger.log('[ServiceConfig] Detected 172.20.*.* network!');
  //Logger.log('[ServiceConfig] Backend URL: ' + backendUrl);
  Logger.log('[ServiceConfig] If you experience CORS issues, ensure the backend is running on port 8000');
  Logger.log('[ServiceConfig] and that it is configured to allow requests from ' + currentUrl.origin);
}

export default serviceConfig;