import { SearchHistoryEntry } from '../types/types';
import Logger from '../../../util/Logger';

// Maximum number of history entries to store
const MAX_HISTORY_ENTRIES = 20;

// LocalStorage key for search history
const STORAGE_KEY = 'knowledge_graph_search_history';

/**
 * Service for managing search history, using localStorage for persistence
 */
class SearchHistoryService {
  private history: SearchHistoryEntry[] = [];

  constructor() {
    this.loadFromStorage();
  }

  /**
   * Add a new search entry to history
   */
  addEntry(
    query: string, 
    humanSummary: string, 
    importantNodesById: string[], 
    communityCount?: number
  ): SearchHistoryEntry {
    try {
      // Create new entry
      const entry: SearchHistoryEntry = {
        id: `search_${Date.now()}_${Math.floor(Math.random() * 1000)}`,
        timestamp: Date.now(),
        query,
        humanSummary,
        importantNodesById,
        communityCount: communityCount || 0
      };

      // Add to history array
      this.history.push(entry);

      // Sort by timestamp (newest first)
      this.history.sort((a, b) => b.timestamp - a.timestamp);
      
      // Limit the number of entries
      if (this.history.length > MAX_HISTORY_ENTRIES) {
        this.history = this.history.slice(0, MAX_HISTORY_ENTRIES);
      }
      
      console.log(`[Service] Added entry ${entry.id}, now have ${this.history.length} entries sorted by time`);

      // Save to localStorage
      this.saveToStorage();

      Logger.log(`Added search history entry: "${query}" with ${importantNodesById.length} important nodes`);
      return entry;
    } catch (error) {
      Logger.error('Error adding search history entry:', error);
      throw error;
    }
  }

  /**
   * Get all search history entries, sorted by timestamp (newest first)
   */
  getEntries(): SearchHistoryEntry[] {
    // Create a copy of the history array and sort by timestamp (newest first)
    const entries = [...this.history]
      .sort((a, b) => b.timestamp - a.timestamp);
    
    // Only log this in development to reduce noise
    if (process.env.NODE_ENV === 'development') {
      console.log("[Service] Returning sorted entries:", 
        entries.length > 0 ? `${entries.length} entries, newest: ${entries[0].id}` : "0 entries");
    }
    
    return entries;
  }

  /**
   * Get a specific entry by ID
   */
  getEntryById(id: string): SearchHistoryEntry | undefined {
    return this.history.find(entry => entry.id === id);
  }

  /**
   * Clear all search history
   */
  clearHistory(): void {
    this.history = [];
    this.saveToStorage();
    Logger.log('Search history cleared');
  }

  /**
   * Delete a specific entry by ID
   */
  deleteEntry(id: string): void {
    const initialLength = this.history.length;
    this.history = this.history.filter(entry => entry.id !== id);
    
    if (this.history.length !== initialLength) {
      this.saveToStorage();
      Logger.log(`Deleted search history entry with ID: ${id}`);
    }
  }

  /**
   * Load history from localStorage
   */
  private loadFromStorage(): void {
    try {
      const storedData = localStorage.getItem(STORAGE_KEY);
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        
        // Convert legacy format entries to new format if needed
        this.history = parsedData.map((entry: any) => {
          // Check if it's a legacy entry with filteredNodes and no importantNodesById
          if (entry.filteredNodes && (!entry.importantNodesById || entry.importantNodesById.length === 0)) {
            // Extract important node IDs from filteredNodes if possible
            const importantNodes = entry.filteredNodes.filter((node: any) => 
              node.highlighted || node.selected || (node.importance && node.importance > 0.7)
            );
            
            // Create new format entry
            return {
              id: entry.id,
              timestamp: entry.timestamp,
              query: entry.query,
              humanSummary: entry.humanSummary || '',
              importantNodesById: importantNodes.map((node: any) => node.id),
              communityCount: entry.communityCount || 0
            };
          }
          
          // Handle entries that have importantNodesById but also have legacy fields
          if (entry.importantNodesById && (entry.filteredNodes || entry.filteredEdges)) {
            // Return only the fields we need
            return {
              id: entry.id,
              timestamp: entry.timestamp,
              query: entry.query,
              humanSummary: entry.humanSummary || '',
              importantNodesById: entry.importantNodesById,
              communityCount: entry.communityCount || 0
            };
          }
          
          // Already in the right format or close enough, just ensure required fields
          return {
            ...entry,
            humanSummary: entry.humanSummary || '',
            importantNodesById: entry.importantNodesById || []
          };
        });
        
        console.log(`[ServiceLoad] Loaded ${this.history.length} search history entries from storage`);
        
        // Debug each entry
        this.history.forEach((entry, index) => {
          console.log(`[ServiceLoad] Entry ${index}: ${entry.id}`, {
            query: entry.query,
            importantNodesCount: entry.importantNodesById?.length || 0,
            timestamp: new Date(entry.timestamp).toLocaleString()
          });
        });
      }
    } catch (error) {
      Logger.error('Error loading search history from storage:', error);
      this.history = [];
    }
  }

  /**
   * Save history to localStorage
   */
  private saveToStorage(): void {
    try {
      // Before saving, ensure we're only storing the fields we need
      const optimizedHistory = this.history.map(entry => ({
        id: entry.id,
        timestamp: entry.timestamp,
        query: entry.query,
        humanSummary: entry.humanSummary || '',
        importantNodesById: entry.importantNodesById || [],
        communityCount: entry.communityCount || 0
      }));
      
      localStorage.setItem(STORAGE_KEY, JSON.stringify(optimizedHistory));
      
      // Track storage usage for debugging
      const usedStorage = JSON.stringify(optimizedHistory).length;
      console.log(`[Storage] Saved ${optimizedHistory.length} entries (${Math.round(usedStorage / 1024)}KB)`);
    } catch (error) {
      Logger.error('Error saving search history to storage:', error);
      
      // If the error is due to localStorage being full, try to save a smaller version
      if (error instanceof DOMException && (
        error.name === 'QuotaExceededError' || 
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      )) {
        try {
          // Reduce the number of entries we're trying to save
          const reducedHistory = this.history
            .slice(0, Math.max(5, Math.floor(this.history.length / 2)))
            .map(entry => ({
              id: entry.id,
              timestamp: entry.timestamp,
              query: entry.query,
              humanSummary: entry.humanSummary || '',
              importantNodesById: entry.importantNodesById || []
            }));
          
          localStorage.setItem(
            STORAGE_KEY + '_reduced', 
            JSON.stringify(reducedHistory)
          );
          Logger.log('Saved reduced search history due to storage constraints');
        } catch (innerError) {
          Logger.error('Error saving reduced search history:', innerError);
        }
      }
    }
  }
}

// Export singleton instance
export const searchHistoryService = new SearchHistoryService();