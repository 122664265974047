/**
 * @fileoverview File providing a unified and simple interface for getting location data from 
 * both native iOS (via WebKit) and browser environments.
 */

import locationServiceBridge from "./LocationServiceBridge";

/**
* Gets the current geographic position of the device.
* 
* Uses native implementation via WebKit messaging bridge when available,
* otherwise falls back to the browser's Geolocation API.
* 
* @returns {Promise<GeolocationPosition>} A promise that resolves with the device's position
* @throws {LocationServiceError} If retrieving the position fails
*/
export async function getCurrentPosition(): Promise<GeolocationPosition> {
    return await locationServiceBridge.getCurrentPosition();
}