import React, { ChangeEvent } from "react";
import { Link } from "react-router-dom";

import './Input.scss';

type DateInputProps = {
    labelText?: string | any;
    fieldId: string;

    value?: string | number;
    hintText?: string;
    disabled?: boolean;
    required?: boolean;

    errorText?: string;
    error?: boolean;

    link?: {
        linkText: string;
        linkUrl: string;
    };

    onChange: (event: ChangeEvent<any>) => void;

    style?: any;
    darkMode?: boolean;
};

export default class DOB extends React.Component<DateInputProps, {}> {
    renderTextInput = () => {
        const { disabled, errorText, value, fieldId, error, onChange } = this.props;

        return (
            <input
                type="text"
                inputMode="numeric"
                placeholder="MM-DD-YYYY"
                id={fieldId}
                className={errorText || error ? 'error' : ''}
                name={fieldId}
                value={value}
                disabled={disabled}
                onChange={onChange}
            />
        );
    }

    render() {
        const {
            fieldId,
            hintText,
            errorText,
            labelText,
            link,
            style = {},
            darkMode = false
        } = this.props;

        return (
            <div className={`adapter-form-field-input DOBInput ${darkMode ? 'darkMode' : ''}`} style={style}>
                {labelText && (
                    <label htmlFor={fieldId}>
                        {labelText}
                    </label>
                )}

                {this.renderTextInput()}

                {link && (
                    <div className="text-sm text-right" style={{ width: '100%' }}>
                        <Link className="font-semibold" to={link.linkUrl}>
                            {link.linkText}
                        </Link>
                    </div>
                )}

                {!errorText && hintText && (
                    <p className="hintText">{hintText}</p>
                )}

                {errorText && (
                    <p className="errorText">{errorText || 'MM-DD-YYYY format expected'}</p>
                )}
            </div>
        );
    }
}