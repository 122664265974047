import React from 'react';

const Mirror = ({ color }: { color: string }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="10" r="8" stroke={color} strokeWidth="2" />
        <line x1="12" y1="18" x2="12" y2="23" stroke={color} strokeWidth="2" />
        <path d="M5 22H19" stroke={color} strokeWidth="2" strokeLinecap="round" />
        <path d="M9 10L13.5 6.5" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
        <path d="M10 13.5L14.5 10" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    </svg>
);

export default Mirror;