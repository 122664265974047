import React from "react";

import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from 'components/common/BasePage';
import { H4Text, Text } from "components/common/Typography";
import Card from "components/common/Card";
import Tour from 'components/common/Tour';
import { MessageIcon, GuideIcon, EmailIcon, ExternalIcon } from "components/common/Icon";
import ADAPTER from 'adapter';

import "../../brand/index.scss";

export const EVENTS = {
    openSupport: 'support::openSupport',
    openFeedback: 'support::openFeedback',
    showTour: 'support::showTour'
};

interface SupportPageProps {
    AtlasStore?: any;
}

class SupportPage extends React.Component<SupportPageProps, {}> {
    showTour = () => {
        ADAPTER.publishEvent(EVENTS.showTour);
    }

    openFeedback = () => {
        ADAPTER.publishEvent(EVENTS.openFeedback);
    }

    openSupport = () => {
        ADAPTER.publishEvent(EVENTS.openSupport);
    }

    render() {
        return (
            <AuthenticatedPage>
                <BasePage innerCls="adapter-bg-base-100">
                    <div className="flex-1"></div>

                    <div style={{ width: '80vw', maxWidth: '600px', alignSelf: 'center' }}>
                        <H4Text cls='adapter-font-color-primary-900'>
                            Help &amp; Support
                        </H4Text>

                        <Tour>
                            <Card>
                                <Text>
                                    <a href="#" onClick={this.showTour}>
                                        <GuideIcon style={{ float: 'left', padding: 0 }} />&nbsp;Get Started Guide
                                    </a>
                                </Text>
                            </Card>
                        </Tour>

                        <Card>
                            <Text>
                                <a href="#" onClick={this.openFeedback}>
                                    <MessageIcon style={{ float: 'left', padding: 0 }} />&nbsp;Provide Feedback
                                </a>
                            </Text>
                        </Card>

                        <Card>
                            <Text>
                                <a href="#" onClick={this.openSupport}>
                                    <EmailIcon style={{ float: 'left', padding: 0 }} />&nbsp;Contact Support
                                </a>
                            </Text>
                        </Card>

                        <Card>
                            <Text>
                                <a href="https://www.adapter.com" target="_blank">
                                    <ExternalIcon style={{ float: 'left', padding: 0 }} />&nbsp;About Adapter
                                </a>
                            </Text>
                        </Card>
                    </div>

                    <div className="flex-1"></div>

                </BasePage>
            </AuthenticatedPage>
        );
    }
}

export default SupportPage;
