import React from 'react';
import ReactDOM from 'react-dom';
import { inject, observer } from 'mobx-react';
import {
    MapContainer,
    TileLayer,
    Marker,
    Polyline,
    Tooltip
} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.glify';

import Card from 'components/common/Card';
import { Text } from 'components/common/Typography';
import MapViewPlayerControls from './MapViewPlayerControls';

import './MapView.scss';

const ZOOM_SCALE = 3;

const INTERVAL_DELTA = (24 * 60 * 60 * 1000) * 30;

const generateCurvedPath = (startPoint, endPoint, arcHeight = 1.5) => {
    const latlngs = [];
    const offsetX = endPoint[1] - startPoint[1];
    const offsetY = endPoint[0] - startPoint[0];
    const distance = Math.sqrt(offsetX * offsetX + offsetY * offsetY);
    const numPoints = Math.max(Math.floor(distance / 50), 10); // Adjust for smoother curves

    // Calculate a midpoint that's offset to create the arc
    const midPoint = [
        (startPoint[0] + endPoint[0]) / 2,
        (startPoint[1] + endPoint[1]) / 2
    ];

    // Perpendicular offset for the control point
    const controlPoint = [
        midPoint[0] + arcHeight * -offsetX / distance,
        midPoint[1] + arcHeight * offsetY / distance
    ];

    // Generate points along a quadratic Bezier curve
    for (let i = 0; i <= numPoints; i++) {
        const t = i / numPoints;

        // Quadratic Bezier curve formula
        const lat = Math.pow(1 - t, 2) * startPoint[0] +
            2 * (1 - t) * t * controlPoint[0] +
            Math.pow(t, 2) * endPoint[0];

        const lng = Math.pow(1 - t, 2) * startPoint[1] +
            2 * (1 - t) * t * controlPoint[1] +
            Math.pow(t, 2) * endPoint[1];

        latlngs.push([lat, lng]);
    }

    return latlngs;
};

export type MapOption = {
    type: string;
    timestamp?: number;
    score?: number;
    metadata?: any;
    option?: {
        gps_coordinates?: {
            lat: number;
            lng: number;
        }
    }
}

type MapViewProps = {
    options: MapOption[];
    type: 'restaurant' | 'hotel';
    TelemetryStore?: any;
};

type MapViewState = {
    playerStartTime: number;
    playerEndTime: number;
    playerTimestamp: number;
    playerOffset: number;
    isPaused: boolean;
    intervalId: number;
    glifyLayer: any;

    filters: {
        hotel: boolean;
        calendar: boolean;
        flight: boolean;
        train: boolean;
        restaurant: boolean;
        car: boolean;
        purchase: boolean;
    }
}

const NOW = Date.now();
const START = NOW - ((24 * 60 * 60 * 1000) * 180); // ~6 months ago

class MapView extends React.Component<MapViewProps, MapViewState> {
    state = {
        playerTimestamp: NOW,
        playerStartTime: START,
        playerEndTime: NOW,

        playerOffset: INTERVAL_DELTA * 30,
        isPaused: true,
        intervalId: null,

        glifyLayer: null,

        filters: {
            hotel: true,
            calendar: true,
            flight: true,
            train: true,
            restaurant: true,
            car: true,
            purchase: true,
        }
    }

    mapRef = null;

    constructor(props) {
        super(props);

        this.mapRef = React.createRef();
    }

    componentDidMount() {
        // Initialize the map on mount
        if (this.mapRef?.current) {
            this.updateMapPoints();
        }
    }

    componentWillUnmount() {
        // Clean up any existing layer when component unmounts
        this.cleanupGlifyLayer();

        if (this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if we need to update map points
        if (this.mapRef?.current &&
            (prevProps.options !== this.props.options ||
                prevState.playerTimestamp !== this.state.playerTimestamp ||
                prevState.filters !== this.state.filters)) {
            this.updateMapPoints();
        }
    }

    findCenterLatLng = () => {
        const { options, TelemetryStore } = this.props;

        // if (TelemetryStore.lastKnownLocation) {
        //     const location = TelemetryStore.lastKnownLocation?.option;

        //     if (location && location?.gps_coordinates) {
        //         const { lat, lng } = location.gps_coordinates;
        //         return [lat, lng];
        //     }
        // }

        if (options.length === 0) {
            return [40, -74];
        }

        let totalLat = 0;
        let totalLng = 0;

        for (const point of options) {
            if (!point) { continue; }

            const lat = point.option?.gps_coordinates?.lat;
            const lng = point.option?.gps_coordinates?.lng;

            if (lat && lng) {
                totalLat += lat;
                totalLng += lng;
            }
        }

        if (totalLat === 0 && totalLng === 0) {
            return [40, -74.2];
        }

        return [
            totalLat / options.length,
            totalLng / options.length
        ];
    }

    zoomOut = () => {
        const map = this.mapRef.current;
        map.flyTo(map.getCenter(), ZOOM_SCALE);
    }

    getOptimalZoom = (padding = 20) => {
        const { options } = this.props;
        if (!options || options.length === 0) {
            return ZOOM_SCALE;
        }

        const map = this.mapRef.current;
        if (map) {
            // @ts-ignore
            const bounds = new L.LatLngBounds();
            options.forEach(o => bounds.extend([
                o.option.gps_coordinates.lat,
                o.option.gps_coordinates.lng
            ]));

            return map.getBoundsZoom(bounds, { padding: [padding, padding] });
        }

        return ZOOM_SCALE;
    }

    handleMarkerClick = (position) => {
        this.mapRef.current.flyTo(position, 15);
    }

    // handlePopupClose = () => {
    //     const map = this.mapRef.current;

    //     if (map) {
    //         map.flyTo(map.getCenter(), 5);
    //     }
    // }

    generateMarker = (data) => {
        const option = data.option;
        const lat = option.gps_coordinates?.lat;
        const lng = option.gps_coordinates?.lng;

        if (lat && lng) {
            let card;

            switch (data.type) {
                case 'hotel':
                    card = (
                        <>
                            <i className='material-icons'>hotel</i>
                            <Text>{data?.metadata?.name}</Text>
                            <Text>{new Date(data.timestamp).toLocaleString()}</Text>
                        </>
                    );
                    break;

                case 'calendar':
                    card = (
                        <>
                            <i className='material-icons'>event</i>
                            <Text>{data.metadata.name}</Text>
                            <Text>{new Date(data.timestamp).toLocaleString()}</Text>
                        </>
                    );
                    break;

                case 'current-location':
                    card = (
                        <>
                            <i className='material-icons'>location_on</i>
                            <Text fontSize='l'>Current Location</Text>
                            <Text>Last recorded: {new Date(data.timestamp).toLocaleString()}</Text>
                        </>
                    );
                    break;

                case 'flight':
                case 'train':
                    card = (
                        <>
                            <i className='material-icons'>{data.type === 'flight' ? 'flight' : 'train'}</i>
                            <Text>{data.metadata.name}</Text>
                            <Text>{new Date(data.timestamp).toLocaleString()}</Text>
                        </>
                    );
                    break;

                case 'restaurant':
                case 'car':
                    card = (
                        <>
                            <i className='material-icons'>{data.type === 'restaurant' ? 'restaurant' : 'directions_car'}</i>
                            <Text>{data?.metadata?.name}</Text>
                            <Text>{new Date(data.timestamp).toLocaleString()}</Text>
                        </>
                    );
                    break;

                default:
                    card = (
                        <>
                            <i className='material-icons'>help</i>
                            <Text>{data?.metadata?.name} (from "{data.type}")</Text>
                            <Text>{new Date(data.timestamp).toLocaleString()}</Text>
                        </>
                    );
                    break;
            }

            return <Card style={{ minWidth: 300 }}>{card}</Card>;
        }
    }

    updateMapPoints() {
        const { options } = this.props;
        const { playerTimestamp, filters } = this.state;
        const map = this.mapRef?.current;

        if (map) {
            // Clean up existing layer first
            this.cleanupGlifyLayer();

            if (options && options.length > 0) {
                const features = [];
                for (let i = 0; i < options.length; i++) {
                    const option = options[i];
                    const isTypeIncluded = filters[option.type];

                    if (option.timestamp < playerTimestamp && isTypeIncluded === true) {
                        features.push({
                            type: "Feature",
                            properties: option,
                            geometry: {
                                type: "Point",
                                coordinates: [
                                    option.option.gps_coordinates.lat,
                                    option.option.gps_coordinates.lng,
                                ],
                            },
                        });
                    }
                }

                if (features.length > 0) {
                    const glifySettings = {
                        map: map,
                        data: {
                            type: "FeatureCollection",
                            features: features,
                        },
                        radius: 10,
                        size: 10,
                        color: (index, point) => {
                            switch (point.properties.type) {
                                case 'hotel':
                                    return { r: 255, g: 0, b: 0, a: 1 };

                                case 'calendar':
                                    return { r: 0, g: 255, b: 0, a: 1 };

                                case 'current-location':
                                    return { r: 0, g: 0, b: 255, a: 1 };

                                case 'flight':
                                    return { r: 255, g: 255, b: 0, a: 1 };

                                case 'train':
                                    return { r: 0, g: 255, b: 255, a: 1 };

                                case 'restaurant':
                                    return { r: 100, g: 0, b: 100, a: 1 };

                                case 'car':
                                    return { r: 100, g: 100, b: 255, a: 1 };

                                default:
                                    return { r: 0, g: 0, b: 0, a: 1 };
                            }
                        },
                        click: (e, feature) => {
                            const popupContentContainer = L.DomUtil.create('div');
                            L.popup({
                                className: 'transparent-popup',
                                closeButton: false,
                                autoPan: true
                            })
                                .setLatLng(feature.geometry.coordinates)
                                .setContent(popupContentContainer)
                                .openOn(map);

                            ReactDOM.render(
                                this.generateMarker(feature.properties),
                                popupContentContainer
                            );

                            this.handleMarkerClick(feature.geometry.coordinates);
                        },
                        hover: (e, feature) => {
                            const contentContainer = L.DomUtil.create('div');
                            L.tooltip({ className: 'transparent-tooltip' })
                                .setLatLng(feature.geometry.coordinates)
                                .setContent(contentContainer)
                                .openOn(map);

                            ReactDOM.render(
                                this.generateMarker(feature.properties),
                                contentContainer
                            );
                        },
                    };

                    // @ts-ignore
                    const newGlifyLayer = L.glify.points(glifySettings);

                    // Store it in state
                    this.setState({ glifyLayer: newGlifyLayer });
                } else {
                    this.setState({ glifyLayer: null });
                }
            } else {
                this.setState({ glifyLayer: null });
            }
        }
    }

    cleanupGlifyLayer() {
        const map = this.mapRef?.current;
        const { glifyLayer } = this.state;

        if (map && glifyLayer) {
            glifyLayer.remove();
            map.removeLayer(glifyLayer);

            // Clear any references we have
            this.setState({ glifyLayer: null });
        }
    }

    defineArcs = () => {
        const { options } = this.props;
        const { playerTimestamp, filters } = this.state;
        const arcs = [];
        const flightLegs = options.filter(o => (filters.flight && o?.type === 'flight') || (filters.train && o?.type === 'train'));
        const now = Date.now();

        for (let i = 0; i < flightLegs.length; i++) {
            const option = flightLegs[i];
            if (option.timestamp < playerTimestamp) {
                // @ts-ignore
                const arcPath = this.createArcPath(option);

                if (arcPath) {
                    const pathOptions = {
                        color: option.timestamp > now ? '#CA8504' : '#7549F2',
                        weight: 2,
                        opacity: 0.8,
                        className: 'flight-arc'
                    };

                    arcs.push(
                        <Polyline positions={arcPath} {...pathOptions} key={i}>
                            <Tooltip className="transparent-tooltip" sticky={true}>
                                <Card>
                                    <i className='material-icons'>{option.type === 'flight' ? 'flight' : 'train'}</i>
                                    <Text>{option?.metadata?.name}</Text>
                                    <Text>{new Date(option.timestamp).toLocaleString()}</Text>
                                </Card>
                            </Tooltip>
                        </Polyline>
                    );
                }
            }
        }

        return arcs;
    }

    createArcPath = (option, numPoints = 50) => {
        const { lat, lng, destLat, destLng } = option.option.gps_coordinates;

        const departureLatLng = [lat, lng];
        const arrivalLatLng = [destLat, destLng];

        // Calculate distance to adjust arc height
        // @ts-ignore
        const distance = L.latLng(departureLatLng).distanceTo(L.latLng(arrivalLatLng));
        const arcHeight = distance / 1000000; // Adjust this divisor to control arc height

        return generateCurvedPath(departureLatLng, arrivalLatLng, arcHeight);
    }

    onInterval = () => {
        const { playerTimestamp, playerEndTime } = this.state;

        this.setState({
            playerTimestamp: playerTimestamp + INTERVAL_DELTA
        }, () => {
            if (playerTimestamp + INTERVAL_DELTA > playerEndTime) {
                this.onPause();
            }
        });
    }

    onPlay = () => {
        const { playerTimestamp, playerEndTime, playerStartTime, isPaused } = this.state;
        if (!isPaused) {
            return;
        }

        const intervalId = setInterval(this.onInterval, 500);

        // reset to start if needed
        let start = playerTimestamp;
        if (start === playerEndTime) {
            start = playerStartTime;
        }

        this.setState({
            isPaused: false,
            playerTimestamp: start,

            // @ts-ignore
            intervalId
        });
    }

    onPause = () => {
        const { intervalId } = this.state;
        clearInterval(intervalId);
        this.setState({ intervalId: null, isPaused: true });
    }

    onSeek = (newTimestamp) => {
        this.setState({ playerTimestamp: newTimestamp });
    }

    onFilterChange = (filterName: string) => {
        const { filters } = this.state;
        const currVal = filters[filterName];
        const newFilters = Object.assign({}, filters, { [filterName]: !currVal });

        this.setState({ filters: newFilters });
    }

    reloadData = () => {
        const { TelemetryStore } = this.props;
        const { playerStartTime, playerEndTime } = this.state;

        TelemetryStore.getLocationData(playerStartTime, playerEndTime);
    }

    onTimeChange = (e) => {
        const input = e.target;
        this.onPause();

        if (input.name === 'start_date') {
            this.setState({ playerStartTime: new Date(input.value).getTime() }, this.reloadData);
        } else {
            this.setState({ playerEndTime: new Date(input.value).getTime() }, this.reloadData);
        }
    }

    renderTimeline = () => {
        const { isPaused, playerTimestamp, playerEndTime, playerStartTime, filters } = this.state;

        return (
            <div className='adapter-mapview-timeline'>
                <MapViewPlayerControls
                    playerTimestamp={playerTimestamp}
                    startTimestamp={playerStartTime}
                    endTimestamp={playerEndTime}
                    onTimeChange={this.onTimeChange}
                    onPlayPause={isPaused ? this.onPlay : this.onPause}
                    onSeek={this.onSeek}
                    isPlaying={!isPaused}
                    filters={filters}
                    onFilterChange={this.onFilterChange}
                    onZoomOut={this.zoomOut}
                />
            </div>
        );
    }

    render() {
        return (
            <MapContainer
                // @ts-ignore
                center={this.findCenterLatLng()}
                zoom={this.getOptimalZoom()} // TODO: not working...
                scrollWheelZoom={false}
                className='adapter-mapview flex-1'
                ref={this.mapRef}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                {this.defineArcs()}

                {this.renderTimeline()}
            </MapContainer>
        );
    }
}

export default inject("TelemetryStore")(observer(MapView));