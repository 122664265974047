import React from "react";
import { observer, inject } from "mobx-react";
import { H1Text, Text, LINK } from "components/common/Typography";
import config from "config";

type AccessDeniedProps = {
  AuthStore?: any;
}

class AccessDeniedPage extends React.Component<AccessDeniedProps, {}> {
  componentDidMount(): void {
    this.handleSignout();
  }
  
  handleSignout = () => {
    const { AuthStore } = this.props;
    const auth0Domain = window.REACT_APP_AUTH0_DOMAIN ?? config.auth0.domain;

    AuthStore.logout().then(() => {
      window.location.href = `https://${auth0Domain}/v2/logout?returnTo=${encodeURIComponent(window.location.origin)}`;
    });
  }

  render() {
    return (<></>);
  }
}

export default inject('AuthStore')(observer(AccessDeniedPage));