import React from 'react';
import { observer, inject } from 'mobx-react';

import withRouter from 'hooks/withRouter';
import { SendIcon } from '../../../components/common/Icon';
import InputWithIconButton from '../../../components/forms/fields/InputWithIconButton';
import Loading from 'components/common/Loading';
import withPostHog from 'hooks/withPosthog';
import './InputBar.scss';

type InputBarProps = {
    placeholder: string;
    NLPStore?: any;
    router?: any;
    style?: any;
    cls?: string;
    userEvents?: any;
};

class InputBar extends React.Component<InputBarProps, any> {
    state = {
        loading: false
    }

    promptResponse = (data: any) => {
        this.setState({ loading: false });
    }

    promptError = () => {
        this.setState({ loading: false });
    }

    onParseInput = () => {
        const { NLPStore, router, userEvents } = this.props;
        this.setState({ loading: true }, () => {
            router.navigate(`/dashboard`);
            if (userEvents) {
                userEvents?.posthog.capture("query submitted");
            }
            NLPStore.parseInput()
                .then(this.promptResponse)
                .catch(this.promptError);
        });
    }

    render() {
        const { placeholder, NLPStore, style = {}, cls = ''  } = this.props;

        return (
            <div className={`intention-inputbar ${cls}`} style={style}>
                <InputWithIconButton
                    fieldId=""
                    dataTestId="knowledge-graph-prompt"
                    placeholder={placeholder}
                    onChange={(e: any) => NLPStore.setRawPrompt(e.target.value)}
                    Icon={SendIcon}
                    onClick={this.onParseInput}
                    onEnterKey={this.onParseInput}
                    style={{ flex: 1 }}
                    value={NLPStore.rawPrompt}
                    buttonConfig={{
                        title: 'Let\'s go!',
                        color: NLPStore.rawPrompt === null ? '#98A2B3' : '#7549F2',
                        disabled: NLPStore.rawPrompt === null
                    }}
                />
            </div>
        );
    }
}

export default withPostHog(withRouter(inject("NLPStore")(observer(InputBar))));