import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import * as THREE from 'three';
import ForceGraph3D from '3d-force-graph';
// Remove unused imports but keep the window.THREE assignment
import { NodeData, EdgeData, GraphViewport } from '../types/types';
import { useGraphSelection } from '../hooks/useGraphSelection';
import styled, { keyframes, css } from 'styled-components';
import Logger from 'util/Logger';

// Global type declarations for custom properties and APIs
declare global {
  // Performance memory API (non-standard)
  interface Performance {
    memory?: {
      totalJSHeapSize: number;
      usedJSHeapSize: number;
      jsHeapSizeLimit: number;
    };
  }
  
  // Custom window properties for graph state management and performance optimizations
  interface Window {
    // Graph metadata
    __graphMetadata?: {
      totalNodeCount?: number;
      totalEdgeCount?: number;
      graphDensity?: number;
      initialized?: boolean;
      hasPerformedInitialFit?: boolean;
      lastViewTransform?: {
        scale: number;
        translateX: number;
        translateY: number;
      };
    };
    
    // Event handling flags
    __isHandlingNodeClick?: boolean;
    __isHandlingNodeExpand?: boolean;
    __lastHoverTime?: number;
    __hoverNodeState?: {
      node: any;
      lastUpdateTime: number;
      callbackSent?: boolean;
    };
    __pendingHoverNode?: any;
    __hoverUpdateTimer?: any;
    __initialRenderInProgress?: boolean;
    __initialLayoutReady?: boolean;
    __initialRenderComplete?: boolean;
    __graphCenteredOnUserNode?: boolean;
    __isFilterAnimationInProgress?: boolean;
    __animationTimeouts?: (number | NodeJS.Timeout)[];
    __cancelPendingAnimations?: () => void;
    __restartSimulation?: (nodes?: any[], edges?: any[]) => void;
    __zoomBehavior?: any;
    __suppressTransformEvents?: boolean;
    
    // Filter state
    __selectedNodeTypes?: string[];
    __selectedEdgeTypes?: string[];
    __activeSearchQuery?: string;
    __preserveUserNodesWithoutEdges?: boolean;
    
    // Performance settings
    __graphSettings?: {
      isLargeGraph?: boolean;
      frameCap?: number;
      lastRenderTime?: number;
    };
    __graphFrameRateCap?: number;
    
    // Node state cache
    __nodeSelectionState?: {
      selectedNodeId?: string;
      hoveredNodeId?: string;
    };
    
    // Reset function
    __resetGraphView?: () => void;
  }
}

// Expose THREE to window so 3d-force-graph can access it
// This needs to happen before the 3d-force-graph is initialized
if (typeof window !== 'undefined') {
  window.THREE = THREE;
}

interface ForceGraphGLProps {
  nodes: NodeData[];
  edges: EdgeData[];
  onNodeClick: (node: NodeData) => void;
  onNodeHover: (node: NodeData | null) => void;
  onNodeExpand: (node: NodeData) => void;
  width: number;
  height: number;
  darkMode: boolean;
  communities?: Record<string, string>;
  getNodeCommunityColor?: (nodeId: string) => string;
}

const GraphContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${props => props.theme.bgColor};
  transition: background-color 0.3s ease;
  z-index: 1;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  touch-action: none; /* Prevent default touch behaviors in iOS Safari */
  
  &:focus {
    outline: none; /* Remove focus outline when container is focused for keyboard control */
  }
`;

const KeyboardHelpOverlay = styled.div<{$visible: boolean}>`
  position: absolute;
  bottom: 16px;
  right: 16px;
  padding: 10px 14px;
  background-color: ${props => props.theme.cardBgColor};
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  max-width: 330px;
  z-index: 5;
  opacity: ${props => props.$visible ? 0.95 : 0};
  transform: translateY(${props => props.$visible ? '0' : '20px'});
  visibility: ${props => props.$visible ? 'visible' : 'hidden'};
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
  font-size: 12px;
  color: ${props => props.theme.textColor};
  pointer-events: ${props => props.$visible ? 'auto' : 'none'};
`;

const KeyboardHelpTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const KeyboardHelpGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 6px 12px;
  margin-bottom: 8px;
`;

const KeyboardKey = styled.kbd`
  display: inline-block;
  padding: 2px 5px;
  font-family: monospace;
  font-size: 11px;
  line-height: 1;
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.buttonBgColor};
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  margin: 0 2px;
`;

const LastAction = styled.div`
  font-style: italic;
  font-size: 11px;
  opacity: 0.8;
  margin-top: 6px;
  border-top: 1px solid ${props => props.theme.borderColor}40;
  padding-top: 6px;
`;

// Animation for pulsing effect
const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(66, 135, 245, 0.7);
    transform: scale(1);
  }
  
  70% {
    box-shadow: 0 0 0 10px rgba(66, 135, 245, 0);
    transform: scale(1.05);
  }
  
  100% {
    box-shadow: 0 0 0 0 rgba(66, 135, 245, 0);
    transform: scale(1);
  }
`;

const KeyboardHelpToggle = styled.button<{ $pulse?: boolean }>`
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${props => props.theme.buttonBgColor};
  border: 1px solid ${props => props.theme.borderColor};
  color: ${props => props.theme.textColor};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 4;
  opacity: 0.9;
  transition: all 0.2s ease;
  animation: ${props => props.$pulse ? css`${pulse} 2s infinite` : 'none'};
  
  &:hover {
    background-color: ${props => props.theme.buttonHoverBgColor};
    transform: scale(1.05);
    opacity: 1;
    animation: none;
  }
  
  &:active {
    transform: scale(0.95);
  }
  
  svg {
    width: 20px;
    height: 20px;
  }
`;

// Helper function to normalize edge source/target IDs and filter out edges with missing nodes or edges of filtered types
const normalizeEdges = (edges: EdgeData[], nodeMap: Map<string, NodeData>): EdgeData[] => {
  // Get the currently selected edge types
  const selectedEdgeTypes = (window as any).__selectedEdgeTypes || [];
  
  // Log info about edge filtering
  Logger.log('ForceGraphGL.normalizeEdges: processing', edges.length, 'edges');
  Logger.log('ForceGraphGL.normalizeEdges: selected edge types =', selectedEdgeTypes);
  
  // If no edge types are selected, return empty array immediately
  if (selectedEdgeTypes.length === 0) {
    Logger.log('ForceGraphGL.normalizeEdges: No edge types selected, returning empty edge list');
    return [];
  }
  
  // First normalize all edges
  const normalizedEdges = edges.map(edge => {
    // Make a new copy of the edge to avoid mutating the original
    const newEdge = { ...edge };
    
    // Handle source - ensure it's a string ID
    if (typeof newEdge.source === 'object' && newEdge.source !== null) {
      const sourceId = (newEdge.source as any).id;
      if (sourceId && nodeMap.has(sourceId)) {
        newEdge.source = sourceId;
      } else {
        // Just capture the ID as a string, but we'll filter this edge out later
        newEdge.source = sourceId || 'unknown';
        // Log only in development mode and as info, not warning
        if (process.env.NODE_ENV === 'development') {
          Logger.log(`Node not found for edge source: ${sourceId || 'unknown'}`);
        }
      }
    }
    
    // Handle target - ensure it's a string ID
    if (typeof newEdge.target === 'object' && newEdge.target !== null) {
      const targetId = (newEdge.target as any).id;
      if (targetId && nodeMap.has(targetId)) {
        newEdge.target = targetId;
      } else {
        // Just capture the ID as a string, but we'll filter this edge out later
        newEdge.target = targetId || 'unknown';
        // Log only in development mode and as info, not warning
        if (process.env.NODE_ENV === 'development') {
          Logger.log(`Node not found for edge target: ${targetId || 'unknown'}`);
        }
      }
    }
    
    return newEdge;
  });
  
  // Then filter out edges with missing nodes, or edges that aren't of the selected types
  const filteredEdges = normalizedEdges.filter(edge => {
    const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source;
    const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target;
    
    // Check that the nodes exist in our nodeMap
    const hasSource = sourceId && nodeMap.has(sourceId as string);
    const hasTarget = targetId && nodeMap.has(targetId as string);
    
    // Check if the edge type is one of the selected types
    const isSelectedType = selectedEdgeTypes.includes(edge.type);
    
    // Only keep edges where:
    // 1. Both source and target nodes exist, AND
    // 2. Edge type is one of the selected types
    return hasSource && hasTarget && isSelectedType;
  });
  
  Logger.log('ForceGraphGL.normalizeEdges: filtered to', filteredEdges.length, 'edges');
  
  return filteredEdges;
};

/**
 * Calculate and normalize metrics for each node based on connections, centrality, and importance
 */
function calculateConnectionMetrics(nodes: NodeData[], edges: EdgeData[]) {
  // Create maps to store metrics for each node
  const connectionCounts = new Map<string, number>();
  
  // Create maps for centrality metrics
  const degreeCentralities = new Map<string, number>();
  const eigenvectorCentralities = new Map<string, number>();
  const importanceScores = new Map<string, number>();
  
  // If there are no nodes or edges, return empty results to avoid errors
  if (nodes.length === 0 || edges.length === 0) {
    Logger.log('No nodes or edges for connection metrics, returning defaults');
    // Create default densities (0.5) for all nodes
    const defaultDensities = new Map<string, number>();
    nodes.forEach(node => {
      connectionCounts.set(node.id, 1); // Give each node a default of 1 connection
      defaultDensities.set(node.id, 0.5); // Set a middle-of-range density
    });
    return { 
      connectionCounts, 
      connectionDensities: defaultDensities,
      normalizedCentralities: defaultDensities,
      normalizedImportance: defaultDensities,
      normalizedEigenvector: defaultDensities
    };
  }
  
  // Extract and store centrality and importance values
  let minDegree = Infinity, maxDegree = 0;
  let minEigenvector = Infinity, maxEigenvector = 0;
  let minImportance = Infinity, maxImportance = 0;
  
  // First pass: collect raw metrics and find min/max values
  nodes.forEach(node => {
    // Initialize connection count to 0
    connectionCounts.set(node.id, 0);
    
    // Extract and store degree centrality
    const degreeCentrality = node.centrality?.degree || 0;
    degreeCentralities.set(node.id, degreeCentrality);
    if (degreeCentrality > 0) {
      minDegree = Math.min(minDegree, degreeCentrality);
      maxDegree = Math.max(maxDegree, degreeCentrality);
    }
    
    // Extract and store eigenvector centrality
    const eigenvectorCentrality = node.centrality?.eigenvector || 0;
    eigenvectorCentralities.set(node.id, eigenvectorCentrality);
    if (eigenvectorCentrality > 0) {
      minEigenvector = Math.min(minEigenvector, eigenvectorCentrality);
      maxEigenvector = Math.max(maxEigenvector, eigenvectorCentrality);
    }
    
    // Extract and store importance
    const importance = node.importance || 1;
    importanceScores.set(node.id, importance);
    minImportance = Math.min(minImportance, importance);
    maxImportance = Math.max(maxImportance, importance);
  });
  
  // Handle edge cases where min/max are the same
  if (minDegree === maxDegree || minDegree === Infinity) {
    minDegree = 0;
    maxDegree = maxDegree || 1;
  }
  
  if (minEigenvector === maxEigenvector || minEigenvector === Infinity) {
    minEigenvector = 0;
    maxEigenvector = maxEigenvector || 1;
  }
  
  if (minImportance === maxImportance) {
    minImportance = 0.5;
    maxImportance = maxImportance || 1.5;
  }
  
  // Count connections from edges
  edges.forEach(edge => {
    const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source;
    const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target;
    
    if (connectionCounts.has(sourceId)) {
      connectionCounts.set(sourceId, connectionCounts.get(sourceId)! + 1);
    }
    
    if (connectionCounts.has(targetId)) {
      connectionCounts.set(targetId, connectionCounts.get(targetId)! + 1);
    }
  });
  
  // Find min and max connection counts
  let minConnections = Infinity;
  let maxConnections = 0;
  
  connectionCounts.forEach(count => {
    minConnections = Math.min(minConnections, count);
    maxConnections = Math.max(maxConnections, count);
  });
  
  // Handle edge case where there are no connections
  if (minConnections === Infinity) minConnections = 0;
  if (maxConnections === minConnections) maxConnections = minConnections + 1;
  
  // Create maps for normalized values (0 to 1)
  const connectionDensities = new Map<string, number>();
  const normalizedCentralities = new Map<string, number>();
  const normalizedEigenvector = new Map<string, number>();
  const normalizedImportance = new Map<string, number>();
  
  // Apply normalization to all metrics
  nodes.forEach(node => {
    const nodeId = node.id;
    
    // Normalize connection count to 0-1 range
    const count = connectionCounts.get(nodeId) || 0;
    connectionDensities.set(nodeId, 
      maxConnections === minConnections ? 0.5 : 
        (count - minConnections) / (maxConnections - minConnections)
    );
    
    // Normalize degree centrality to 0-1 range using logarithmic scaling for better distribution
    const rawDegree = degreeCentralities.get(nodeId) || 0;
    if (maxDegree > minDegree) {
      // Apply log scaling to compress the range and highlight differences
      const logScaled = rawDegree === 0 ? 0 : 
        (Math.log(rawDegree + 0.001) - Math.log(minDegree + 0.001)) / 
        (Math.log(maxDegree + 0.001) - Math.log(minDegree + 0.001));
      normalizedCentralities.set(nodeId, Math.max(0, Math.min(1, logScaled)));
    } else {
      normalizedCentralities.set(nodeId, 0.5);
    }
    
    // Normalize eigenvector centrality to 0-1 range using square root scaling
    const rawEigenvector = eigenvectorCentralities.get(nodeId) || 0;
    if (maxEigenvector > minEigenvector) {
      // Square root scaling gives more weight to smaller differences
      const sqrtScaled = rawEigenvector === 0 ? 0 :
        Math.sqrt((rawEigenvector - minEigenvector) / (maxEigenvector - minEigenvector));
      normalizedEigenvector.set(nodeId, Math.max(0, Math.min(1, sqrtScaled)));
    } else {
      normalizedEigenvector.set(nodeId, 0.5);
    }
    
    // Normalize importance using linear scaling
    const rawImportance = importanceScores.get(nodeId) || 1;
    normalizedImportance.set(nodeId, 
      (rawImportance - minImportance) / (maxImportance - minImportance)
    );
  });
  
  return { 
    connectionCounts, 
    connectionDensities, 
    normalizedCentralities,
    normalizedEigenvector,
    normalizedImportance
  };
}

// Harmonious pastel color palette inspired by the Tetractys and color theory
// The Tetractys (1:2:3:4) ratios are applied to hue selection, saturation, and lightness
// Creating a balanced palette with mathematical harmony and pleasing contrasts
const colorPalettes = {
  light: [
    '#94C6FF', // Pastel Blue (base harmonic - 1:1)
    '#FFB5AE', // Pastel Coral (ratio 3:2 complement)
    '#B8E6C9', // Pastel Mint (ratio 4:3 tetrad)
    '#E1BCFF', // Pastel Lavender (ratio 2:1 tetrad)
    '#FFD3A2', // Pastel Peach (ratio 3:1 triad)
    '#A2E2E6', // Pastel Aqua (ratio 4:1 complement)
    '#FFBAD2', // Pastel Pink (ratio 3:4 tetrad)
    '#D6C3AD', // Pastel Taupe (ratio 6:4 neutral)
    '#BEBDFF', // Pastel Periwinkle (ratio 9:8 adjacent)
    '#F7E5A4', // Pastel Buttercream (ratio 16:9 complement)
    '#BFE0FA', // Pastel Sky (ratio 4:3 variation)
    '#FFD1B8', // Pastel Apricot (ratio 3:2 adjacent)
    '#BAEED0', // Pastel Seafoam (ratio 4:3 adjacent)
    '#E8DEAE', // Pastel Sand (ratio 9:8 neutral)
    '#E2C0F0', // Pastel Lilac (ratio 9:6 tetrad)
    '#C4E7FF', // Pastel Azure (ratio 3:2 variation)
    '#FFD0B0', // Pastel Cantaloupe (ratio 4:3 triad)
    '#C5F2D0', // Pastel Honeydew (ratio 9:6 tetrad)
    '#DCDCDD', // Pastel Silver (ratio 1:1 neutral)
    '#C1F0F1', // Pastel Cyan (ratio 4:3 complement)
  ],
  dark: [
    '#6D99CE', // Muted Blue (base harmonic - 1:1)
    '#CE817B', // Muted Coral (ratio 3:2 complement)
    '#82B696', // Muted Sage (ratio 4:3 tetrad)
    '#AC89C2', // Muted Lavender (ratio 2:1 tetrad)
    '#D1A777', // Muted Peach (ratio 3:1 triad)
    '#77B5B8', // Muted Teal (ratio 4:1 complement)
    '#CC8BA2', // Muted Rose (ratio 3:4 tetrad)
    '#A69683', // Muted Taupe (ratio 6:4 neutral)
    '#908FC6', // Muted Periwinkle (ratio 9:8 adjacent)
    '#C5B57B', // Muted Ochre (ratio 16:9 complement)
    '#89ACCC', // Muted Steel Blue (ratio 4:3 variation)
    '#C69D85', // Muted Amber (ratio 3:2 adjacent)
    '#82B396', // Muted Sage (ratio 4:3 adjacent)
    '#B4AA82', // Muted Sand (ratio 9:8 neutral)
    '#B092BD', // Muted Mauve (ratio 9:6 tetrad)
    '#92B8D1', // Muted Azure (ratio 3:2 variation)
    '#D1A382', // Muted Terracotta (ratio 4:3 triad)
    '#93B79A', // Muted Mint (ratio 9:6 tetrad)
    '#9D9D9F', // Muted Silver (ratio 1:1 neutral)
    '#8BBABB', // Muted Aqua (ratio 4:3 complement),
  ]
};

// Define a fixed map of edge types to specific colors
// This ensures each edge type always gets the same distinct color
const edgeTypeColors: Record<string, {light: string, dark: string}> = {
  // Common relationship types with highly distinct colors
  'knows':         { light: '#FF6B6B', dark: '#CC4444' },         // Bright red
  'sent':          { light: '#4ECDC4', dark: '#36A39B' },         // Teal
  'attended':      { light: '#FFD166', dark: '#CCA24E' },         // Gold
  'created':       { light: '#6A0572', dark: '#4A0350' },         // Purple
  'participated':  { light: '#FF9F1C', dark: '#CC7D16' },         // Orange
  'purchased':     { light: '#2EC4B6', dark: '#219A8F' },         // Seafoam
  'visited':       { light: '#E84855', dark: '#BA3844' },         // Coral
  'traveled_to':   { light: '#7209B7', dark: '#56078A' },         // Violet
  'stayed_at':     { light: '#3A86FF', dark: '#2F6BCC' },         // Blue
  'booked':        { light: '#8AC926', dark: '#6E9F1E' },         // Lime
  'works_for':     { light: '#FF595E', dark: '#CC484B' },         // Red-orange
  'belongs_to':    { light: '#1982C4', dark: '#14689D' },         // Blue
  'owns':          { light: '#6A4C93', dark: '#533C74' },         // Lavender
  'received_by':   { light: '#F15BB5', dark: '#C14991' },         // Pink
  'located_in':    { light: '#00BBF9', dark: '#0095C7' },         // Sky blue
  'part_of':       { light: '#9B5DE5', dark: '#7C4AB8' },         // Amethyst
  'related_to':    { light: '#F8961E', dark: '#C67818' },         // Amber
  'traveled':      { light: '#72A276', dark: '#5B815E' },         // Forest
  'contacted':     { light: '#F9C74F', dark: '#C79F3F' },         // Yellow
  'manages':       { light: '#43AA8B', dark: '#36886F' },         // Jade
  'reports_to':    { light: '#277DA1', dark: '#1F6480' },         // Steel blue
  'met_with':      { light: '#F94144', dark: '#C73437' },         // Vermilion
  'replied_to':    { light: '#90BE6D', dark: '#739857' },         // Olive
  'authored':      { light: '#577590', dark: '#455D73' },         // Slate
  'reviewed':      { light: '#F9844A', dark: '#C7693B' },         // Tangerine
  'collaborated':  { light: '#4D908E', dark: '#3D7371' },         // Teal blue
  'referenced':    { light: '#F7B801', dark: '#C59300' },         // Yellow-orange
  'quoted':        { light: '#FCC0C5', dark: '#CA9A9E' },         // Pink-gray
  'voted_for':     { light: '#55A630', dark: '#448526' },         // Green
  'liked':         { light: '#FF8FA3', dark: '#CC7282' },         // Rose
  'commented_on':  { light: '#B5838D', dark: '#916971' },         // Mauve
  'shared':        { light: '#9BC53D', dark: '#7C9E31' },         // Apple green
  'attached':      { light: '#5BC0EB', dark: '#499ABB' },         // Sky
  'tagged':        { light: '#8D99AE', dark: '#707A8B' },         // Gray-blue
};

// Color registry to cache colors for any edge types not in our predefined list
const edgeColorRegistry: Record<string, {light: string, dark: string}> = {};

// Helper function to double the brightness of a color
function doubleBrightness(hexColor: string): string {
  // Create a THREE.js color object from the hex color
  const color = new THREE.Color(hexColor);
  
  // Convert to HSL to manipulate brightness
  let hsl = {h: 0, s: 0, l: 0};
  color.getHSL(hsl);
  
  // Double the lightness (brightness), but cap at 0.9 to avoid pure white
  hsl.l = Math.min(0.9, hsl.l * 2);
  
  // Set the color back with the new HSL values
  color.setHSL(hsl.h, hsl.s, hsl.l);
  
  // Return the new brighter hex color
  return '#' + color.getHexString();
}

// Helper function to get a consistent, unique color for each edge type
function getEdgeColor(type: string, darkMode: boolean): string {
  // Normalize the edge type by lowercasing and replacing spaces with underscores
  const normalizedType = type.toLowerCase().replace(/\s+/g, '_');
  
  // Get the base color first
  let baseColor: string;
  
  // First check if we have a predefined color for this edge type
  if (edgeTypeColors[normalizedType]) {
    baseColor = darkMode ? edgeTypeColors[normalizedType].dark : edgeTypeColors[normalizedType].light;
  } else {
    // If not in our predefined map, use the registry system as fallback
    if (!edgeColorRegistry[normalizedType]) {
      // Get the count of currently registered types to determine the index
      const currentCount = Object.keys(edgeColorRegistry).length;
      
      // Get colors from the palette, cycling through if we have more edge types than colors
      const lightColor = colorPalettes.light[currentCount % colorPalettes.light.length];
      const darkColor = colorPalettes.dark[currentCount % colorPalettes.dark.length];
      
      // Log any edge types we don't have predefined colors for
      Logger.log(`Edge type not in predefined color map: "${normalizedType}". Assigning dynamic color.`);
      
      // Register the colors for this edge type
      edgeColorRegistry[normalizedType] = {
        light: lightColor,
        dark: darkColor
      };
    }
    
    // Get the appropriate base color based on the current mode
    baseColor = darkMode ? edgeColorRegistry[normalizedType].dark : edgeColorRegistry[normalizedType].light;
  }
  
  // Double the brightness of the edge color
  return doubleBrightness(baseColor);
}

// Define a complete map of node types to specific colors
// This ensures each node type always gets the same highly distinguishable color
const nodeTypeColors: Record<string, {light: string, dark: string}> = {
  // Primary entity types with distinct colors
  'person':       { light: '#4287f5', dark: '#3269c2' },      // Vibrant blue
  'user':         { light: '#4D9FFF', dark: '#0077FF' },      // Electric blue (new default for user nodes)
  'email':        { light: '#ff9966', dark: '#cc7a52' },      // Coral orange
  'event':        { light: '#5dc292', dark: '#4a9b74' },      // Emerald green
  'flight':       { light: '#7785eb', dark: '#5f6abd' },      // Royal purple-blue
  'hotel':        { light: '#e05297', dark: '#b34279' },      // Hot pink
  'reservation':  { light: '#bf85eb', dark: '#986abd' },      // Soft purple
  'document':     { light: '#f6bc66', dark: '#c59852' },      // Amber
  'location':     { light: '#59c1d0', dark: '#479aa6' },      // Turquoise
  'organization': { light: '#9579ce', dark: '#7661a5' },      // Lavender
  'transaction':  { light: '#f9d16b', dark: '#c7a756' },      // Gold
  'meeting':      { light: '#65c080', dark: '#509a66' },      // Mint green
  'project':      { light: '#f5555d', dark: '#c4444a' },      // Bright red
  'task':         { light: '#7fccea', dark: '#65a3bb' },      // Sky blue
  'message':      { light: '#ffa500', dark: '#cc8400' },      // Orange
  'comment':      { light: '#c48aff', dark: '#9d6ecc' },      // Purple
  'product':      { light: '#5fb9a8', dark: '#4c9486' },      // Teal
  'service':      { light: '#ff7eb3', dark: '#cc658f' },      // Pink
  'application':  { light: '#7ed56f', dark: '#65aa59' },      // Lime green
  'device':       { light: '#8499e7', dark: '#697aba' },      // Periwinkle
  'account':      { light: '#ebcb8b', dark: '#bca26f' },      // Caramel
  'group':        { light: '#c678dd', dark: '#9e60b0' },      // Magenta
  'role':         { light: '#a9c27f', dark: '#879b65' },      // Olive
  'file':         { light: '#f9966c', dark: '#c77856' },      // Peach
  'page':         { light: '#52adcc', dark: '#428aa3' },      // Cerulean
  'article':      { light: '#e3a250', dark: '#b58140' },      // Bronze
  'post':         { light: '#f2cc8f', dark: '#c2a372' },      // Tan
  'calendar':     { light: '#61c9a8', dark: '#4ea186' },      // Seafoam
  'expense':      { light: '#e67c73', dark: '#b8635c' },      // Salmon
  'purchase':     { light: '#5a9bd5', dark: '#487caa' },      // Steel blue
  'invoice':      { light: '#ffca28', dark: '#cca120' },      // Yellow
  'address':      { light: '#66c2a4', dark: '#519b83' },      // Jade
  'contact':      { light: '#fc8eac', dark: '#ca7289' },      // Bubblegum
  'phone':        { light: '#d1b0cb', dark: '#a78da2' },      // Mauve
  'website':      { light: '#81d4fa', dark: '#67a9c8' },      // Light blue
  'link':         { light: '#7986cb', dark: '#616ba2' },      // Indigo
  'dining':       { light: '#ff9e80', dark: '#cc7e66' },      // Terracotta
  'hotel_stay':   { light: '#d580ff', dark: '#aa66cc' },      // Violet
  'lodging':      { light: '#80cbc4', dark: '#66a29d' },      // Aqua
  'transport':    { light: '#a5d6a7', dark: '#84ab86' },      // Sage
  'restaurant':   { light: '#ffab91', dark: '#cc8974' },      // Burnt orange
  'venue':        { light: '#90caf9', dark: '#73a2c7' },      // Baby blue
};

// Helper function to determine node color based on type and user status
function getNodeColor(type: string, darkMode: boolean, isUser?: boolean): string {
  // Normalize the node type
  const normalizedType = type.toLowerCase().replace(/\s+/g, '_');
  
  // Special case for user nodes - they get their own color regardless of type
  if (isUser) {
    return darkMode ? nodeTypeColors['user'].dark : nodeTypeColors['user'].light;
  }
  
  // Look up the color in our type map
  if (nodeTypeColors[normalizedType]) {
    return darkMode ? nodeTypeColors[normalizedType].dark : nodeTypeColors[normalizedType].light;
  }
  
  // If we don't have a predefined color, log it and use a default
  Logger.log(`Node type not in predefined color map: "${normalizedType}". Using default color.`);
  return darkMode ? '#9D9D9F' : '#DCDCDD'; // Default: Pastel Silver
}

// Shared geometry, material and texture pools to avoid creating new objects
// These are defined outside the component to persist across renders
const geometryPool = {
  sphere: null as THREE.SphereGeometry | null
};

// Material pools keyed by color/type
const materialPool = new Map<string, THREE.Material>();

// Label texture cache (reuse textures for identical labels)
const textureCache = new Map<string, {
  texture: THREE.Texture,
  lastUsed: number
}>();

// Texture cleanup interval
let textureCleanupInterval: number | null = null;

// Maximum number of textures to keep in cache
const MAX_TEXTURE_CACHE_SIZE = 1500;

// Texture cache automatic cleanup function
const cleanupUnusedTextures = () => {
  if (textureCache.size <= MAX_TEXTURE_CACHE_SIZE) return;
  
  const now = Date.now();
  const entries = Array.from(textureCache.entries());
  
  // Sort by last used time (oldest first)
  entries.sort((a, b) => a[1].lastUsed - b[1].lastUsed);
  
  // Remove oldest textures until we're under the limit
  const texturesToRemove = entries.slice(0, Math.ceil(textureCache.size * 0.2)); // Remove oldest 20%
  
  for (const [key, value] of texturesToRemove) {
    // Dispose of the texture properly
    value.texture.dispose();
    // Remove from cache
    textureCache.delete(key);
  }
  
  Logger.log(`Cleaned up ${texturesToRemove.length} unused textures. Cache size: ${textureCache.size}`);
};

// Shared canvas for label textures
let sharedLabelCanvas: HTMLCanvasElement | null = null;
let sharedLabelContext: CanvasRenderingContext2D | null = null;

export const ForceGraphGL: React.FC<ForceGraphGLProps> = ({
  nodes,
  edges,
  onNodeClick,
  onNodeHover,
  onNodeExpand,
  width,
  height,
  darkMode,
  communities,
  getNodeCommunityColor,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const graphRef = useRef<any>(null);
  
  // Refs to hold object pools during component lifetime
  const nodeObjectsRef = useRef(new Map<string, THREE.Group>());
  
  // Create stable callback refs to avoid recreation and dependency changes
  const onNodeClickRef = useRef(onNodeClick);
  const onNodeHoverRef = useRef(onNodeHover);
  const onNodeExpandRef = useRef(onNodeExpand);
  
  // Update stable callback refs when props change (but don't use in dependencies)
  useEffect(() => {
    onNodeClickRef.current = onNodeClick;
    onNodeHoverRef.current = onNodeHover;
    onNodeExpandRef.current = onNodeExpand;
  }, [onNodeClick, onNodeHover, onNodeExpand]);
  
  // Add debug logging
  useEffect(() => {
    Logger.log('ForceGraphGL received nodes:', nodes.length);
    Logger.log('ForceGraphGL received edges:', edges.length);
  }, [nodes, edges]);
  
  // Create a map of node IDs to nodes for quick lookup
  const nodeMap = useMemo(() => {
    const map = new Map<string, NodeData>();
    nodes.forEach(node => map.set(node.id, node));
    return map;
  }, [nodes]);
  
  // Normalize edges to ensure source and target are string IDs
  const normalizedEdges = useMemo(() => {
    // Add additional safety check
    if (!nodes.length) {
      Logger.log('No nodes to normalize against');
      return [];
    }
    
    if (!edges.length) {
      Logger.log('No edges to normalize');
      return [];
    }
    
    Logger.log('Normalizing edges...');
    return normalizeEdges(edges, nodeMap);
  }, [edges, nodeMap, nodes.length]);
  
  // Memoize nodes and normalized edges
  // Filter nodes based on active edge filters, but KEEP all nodes when search is active
  const filteredNodes = useMemo(() => {
    // Get the currently selected edge types
    const selectedNodeTypes = (window as any).__selectedNodeTypes || [];
    const selectedEdgeTypes = (window as any).__selectedEdgeTypes || [];
    
    // Check if search is active - when search is active, we don't filter out orphaned nodes
    const searchQuery = (window as any).__activeSearchQuery || '';
    const isSearchActive = searchQuery.trim().length > 0;
    
    Logger.log('ForceGraphGL: selectedNodeTypes =', selectedNodeTypes);
    Logger.log('ForceGraphGL: selectedEdgeTypes =', selectedEdgeTypes);
    Logger.log('ForceGraphGL: normalizedEdges count =', normalizedEdges.length);
    Logger.log('ForceGraphGL: isSearchActive =', isSearchActive, `(query: "${searchQuery}")`);
    
    // When search is active, keep ALL nodes that match the selected types,
    // even if they don't have connected edges
    if (isSearchActive) {
      Logger.log('ForceGraphGL: Search is active - keeping all nodes matching selected types');
      // First, extract user nodes which are ALWAYS preserved
      const userNodes = nodes.filter(node => 
        node.user === true || (node.properties && node.properties.user === true)
      );
      Logger.log(`ForceGraphGL: Found ${userNodes.length} user nodes to always preserve`);
      
      // Filter non-user nodes by type
      const typeFilteredNodes = nodes.filter(node => {
        // Skip user nodes - we'll handle them separately to ensure they're all preserved
        if (node.user === true || (node.properties && node.properties.user === true)) {
          return false;
        }
        
        // Only keep nodes of selected types
        return selectedNodeTypes.includes(node.type);
      });
      
      Logger.log(`ForceGraphGL: Type filtering kept ${typeFilteredNodes.length} non-user nodes during search`);
      
      // Combine node sets, ensuring user nodes are always included
      return [...typeFilteredNodes, ...userNodes];
    }
    
    // Standard filtering logic when no search is active
    // If no edge types are selected, we should not display any nodes
    if (selectedEdgeTypes.length === 0) {
      Logger.log('ForceGraphGL: No edge types selected - returning empty node list');
      // Only keep user nodes if any exist and we really want to preserve them
      const userNodes = nodes.filter(node => node.user === true);
      if (userNodes.length > 0 && (window as any).__preserveUserNodesWithoutEdges === true) {
        Logger.log('ForceGraphGL: Preserving user nodes despite no edge types being selected:', userNodes.length);
        return userNodes;
      }
      return []; // No nodes should be visible without edge types
    }
    
    // Create a Set of all node IDs that appear in edges that match selected edge types
    const connectedNodeIds = new Set<string>();
    
    // Only count nodes as connected if they are connected by a selected edge type
    normalizedEdges.forEach(edge => {
      // Skip edges of types that aren't selected
      if (!selectedEdgeTypes.includes(edge.type)) {
        return;
      }
      
      const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source as string;
      const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target as string;
      
      if (sourceId) connectedNodeIds.add(sourceId);
      if (targetId) connectedNodeIds.add(targetId);
    });
    
    Logger.log('ForceGraphGL: Found nodes connected by selected edge types:', connectedNodeIds.size);
    
    // If no nodes are connected by selected edge types, only show user nodes if specified
    if (connectedNodeIds.size === 0) {
      Logger.log('ForceGraphGL: No nodes connected by selected edge types');
      
      // Only keep user nodes if any exist and we really want to preserve them
      const userNodes = nodes.filter(node => node.user === true);
      if (userNodes.length > 0 && (window as any).__preserveUserNodesWithoutEdges === true) {
        Logger.log('ForceGraphGL: Preserving user nodes despite no connections:', userNodes.length);
        return userNodes;
      }
      
      // If no user nodes or not preserving them, return empty array
      return [];
    }
    
    // Keep nodes that:
    // 1. Appear in at least one SELECTED edge type AND have a selected node type, OR
    // 2. Are user nodes (marked with user: true) - these are ALWAYS preserved
    const filteredNodeList = nodes.filter(node => {
      // ALWAYS preserve user nodes regardless of node type or connection status
      // Check both node.user and node.properties.user for backward compatibility
      if (node.user === true || (node.properties && node.properties.user === true)) {
        Logger.log(`ForceGraphGL: Preserving user node ${node.id} (${node.label}) regardless of filters`);
        return true;
      }
      
      // First check if node appears in selected edge types
      const isConnected = connectedNodeIds.has(node.id);
      
      // Check if node type is selected
      const isSelectedType = selectedNodeTypes.includes(node.type);
      
      // Log any nodes that would be filtered out despite being connected
      if (isConnected && !isSelectedType) {
        Logger.log(`ForceGraphGL: Filtering out connected node ${node.id} (${node.label}) because type ${node.type} is not selected`);
      }
      
      // For non-user nodes, must pass BOTH connected AND node type check
      return isConnected && isSelectedType;
    });
    
    Logger.log('ForceGraphGL: Filtered node list count:', filteredNodeList.length);
    
    // If no nodes passed the filter, return only user nodes if any
    if (filteredNodeList.length === 0 && nodes.length > 0) {
      Logger.log('ForceGraphGL: No nodes passed filter - checking user nodes');
      const userNodes = nodes.filter(node => 
        node.user === true || (node.properties && node.properties.user === true)
      );
      if (userNodes.length > 0) {
        Logger.log('ForceGraphGL: Preserving only user nodes:', userNodes.length);
        return userNodes;
      }
      return [];
    }
    
    return filteredNodeList;
  }, [nodes, normalizedEdges]);

  const memoizedNodes = useMemo(() => filteredNodes, [filteredNodes]);
  const memoizedEdges = useMemo(() => normalizedEdges, [normalizedEdges]);
  
  // Calculate graph density factor (0-1) based on node and edge count
  // This helps us scale visual elements appropriately for different graph sizes
  const graphDensity = useMemo(() => {
    const nodeCount = filteredNodes.length;
    const edgeCount = normalizedEdges.length;
    
    // Node density factor: 0 for few nodes (<30), up to 0.8 for 500+ nodes
    const nodeDensity = Math.min(0.8, nodeCount / 500);
    
    // Edge density: ratio of actual edges to maximum possible edges in a complete graph
    // For a graph with n nodes, max possible edges is n(n-1)/2
    const maxPossibleEdges = nodeCount * (nodeCount - 1) / 2;
    const edgeDensity = maxPossibleEdges > 0 
      ? Math.min(0.8, edgeCount / (maxPossibleEdges * 0.1)) // Only expect 10% of max edges
      : 0;
    
    // Combined density, weighted toward node count for visual scaling
    return Math.max(0, Math.min(1, nodeDensity * 0.7 + edgeDensity * 0.3));
  }, [filteredNodes.length, normalizedEdges.length]);
  
  // Store graph metadata globally for access by components
  useEffect(() => {
    // Make graph metadata available globally for sizing calculations
    window.__graphMetadata = {
      totalNodeCount: filteredNodes.length,
      totalEdgeCount: edges.length,
      graphDensity,
      initialized: true
    };
  }, [filteredNodes.length, edges.length, graphDensity]);

  // Calculate and normalize metrics for all nodes
  const { 
    connectionDensities, 
    normalizedCentralities, 
    normalizedEigenvector, 
    normalizedImportance 
  } = useMemo(() => {
    return calculateConnectionMetrics(memoizedNodes, memoizedEdges);
  }, [memoizedNodes, memoizedEdges]);
  
  // Calculate edge significance values up-front
  const getEdgeSignificance = useMemo(() => {
    // Calculate the min-max range of both centrality metrics and importance across the graph
    let minDegree = Infinity, maxDegree = -Infinity;
    let minEigen = Infinity, maxEigen = -Infinity;
    let minImportance = Infinity, maxImportance = -Infinity;
    
    // Collect all metrics to determine the global distribution
    memoizedNodes.forEach(node => {
      const nodeId = node.id;
      
      // Degree centrality
      const degree = normalizedCentralities.get(nodeId) || 0;
      minDegree = Math.min(minDegree, degree);
      maxDegree = Math.max(maxDegree, degree);
      
      // Eigenvector centrality
      const eigen = normalizedEigenvector.get(nodeId) || 0;
      minEigen = Math.min(minEigen, eigen);
      maxEigen = Math.max(maxEigen, eigen);
      
      // Importance 
      const importance = normalizedImportance.get(nodeId) || 0;
      minImportance = Math.min(minImportance, importance);
      maxImportance = Math.max(maxImportance, importance);
    });
    
    // Handle edge cases
    if (minDegree === Infinity) minDegree = 0;
    if (minEigen === Infinity) minEigen = 0;
    if (minImportance === Infinity) minImportance = 0;
    if (maxDegree === minDegree) maxDegree = minDegree + 1;
    if (maxEigen === minEigen) maxEigen = minEigen + 1;
    if (maxImportance === minImportance) maxImportance = minImportance + 1;
    
    // Return a function that calculates edge importance with global context
    return (sourceId: string, targetId: string, weight: number = 1): number => {
      // Get metrics for source node with fallbacks
      const sourceDegree = normalizedCentralities.get(sourceId) || 0;
      const sourceEigen = normalizedEigenvector.get(sourceId) || 0;
      const sourceImportance = normalizedImportance.get(sourceId) || 0;
      
      // Get metrics for target node with fallbacks
      const targetDegree = normalizedCentralities.get(targetId) || 0;
      const targetEigen = normalizedEigenvector.get(targetId) || 0;
      const targetImportance = normalizedImportance.get(targetId) || 0;
      
      // Calculate relative positions within the global distributions
      // This uses advanced normalization based on the min-max distribution
      const relSourceDegree = (sourceDegree - minDegree) / (maxDegree - minDegree);
      const relTargetDegree = (targetDegree - minDegree) / (maxDegree - minDegree);
      const relSourceEigen = (sourceEigen - minEigen) / (maxEigen - minEigen);
      const relTargetEigen = (targetEigen - minEigen) / (maxEigen - minEigen);
      const relSourceImportance = (sourceImportance - minImportance) / (maxImportance - minImportance);
      const relTargetImportance = (targetImportance - minImportance) / (maxImportance - minImportance);
      
      // Calculate edge betweenness approximation using the harmonic mean of node centralities
      // Harmonic mean emphasizes when both nodes are important vs. arithmetic mean
      const degreeHarmonic = 2 * relSourceDegree * relTargetDegree / 
        (relSourceDegree + relTargetDegree || 0.001);
      const eigenHarmonic = 2 * relSourceEigen * relTargetEigen / 
        (relSourceEigen + relTargetEigen || 0.001);
      const importanceHarmonic = 2 * relSourceImportance * relTargetImportance / 
        (relSourceImportance + relTargetImportance || 0.001);
      
      // Use PageRank-inspired weight calculation
      // Edges between high-eigenvector nodes get highest priority,
      // combined with degree centrality and importance
      const edgeSignificance = 
        (eigenHarmonic * 0.5) +       // 50% - Eigenvector (most important for connection strength)
        (degreeHarmonic * 0.25) +     // 25% - Degree centrality (connectivity influence)
        (importanceHarmonic * 0.15) + // 15% - Manual importance (domain knowledge)
        (weight * 0.1);               // 10% - Edge weight (base significance)
        
      return Math.max(0, Math.min(1, edgeSignificance));
    };
  }, [memoizedNodes, normalizedCentralities, normalizedEigenvector, normalizedImportance]);
  
  // We're using a single getEdgeSignificance function, defined above
  // No need for a duplicate function here
  
  // Pre-calculate edge significance values for all edges once
  const edgeSignificanceMap = useMemo(() => {
    const map = new Map<string, number>();
    
    memoizedEdges.forEach(edge => {
      // Type guard for edge source
      const sourceId = typeof edge.source === 'string' 
        ? edge.source 
        : (edge.source && typeof edge.source === 'object' && 'id' in edge.source)
          ? (edge.source as NodeData).id
          : null;
      
      // Type guard for edge target
      const targetId = typeof edge.target === 'string' 
        ? edge.target 
        : (edge.target && typeof edge.target === 'object' && 'id' in edge.target)
          ? (edge.target as NodeData).id
          : null;
      
      if (sourceId && targetId) {
        const key = `${sourceId}-${targetId}`;
        const weight = edge.weight || 1;
        map.set(key, getEdgeSignificance(sourceId, targetId, weight));
      }
    });
    
    return map;
  }, [memoizedEdges, getEdgeSignificance]);

  // Add a pre-render check for WebGL errors
const preRenderCheck = () => {
  try {
    // This function will be called before each render
    // It ensures that errors from previous frames don't persist
    if (window.__lastWebGLError && typeof window.__restartSimulation === 'function') {
      Logger.log("Attempting recovery from WebGL error");
      window.__lastWebGLError = false;
      window.__restartSimulation();
    }
  } catch (e) {
    Logger.warn("Error in preRenderCheck:", e);
  }
};

// CRITICAL CHANGE: Access selection state through a mutable ref
  // This prevents the component from re-rendering when selection changes
  const selectionStateRef = useRef<{
    selectedNode: NodeData | null;
    hoverNode: NodeData | null;
    selectNode: (node: NodeData | null) => void;
    setHoverNode: (node: NodeData | null) => void;
  }>({
    selectedNode: null,
    hoverNode: null,
    selectNode: () => {},
    setHoverNode: () => {}
  });
  
  // Use a separate ref for keyboard navigation state to avoid re-renders
  const navigationStateRef = useRef({
    adjacentNodes: [] as NodeData[],
    currentIndex: -1,
    connectedNodeIndices: new Map<string, number[]>(),
    lastNavigationAction: ''
  });
  
  // Use custom hook for selection management
  const { selectedNode, selectNode, hoverNode, setHoverNode } = useGraphSelection();
  
  // Store selection state in ref - update with the latest values
  useEffect(() => {
    selectionStateRef.current.selectedNode = selectedNode;
    selectionStateRef.current.selectNode = selectNode;
    selectionStateRef.current.hoverNode = hoverNode;
    selectionStateRef.current.setHoverNode = setHoverNode;
    
    // When selectedNode changes, update the adjacentNodes list for keyboard navigation
    if (selectedNode) {
      // Find all nodes connected to the selected node
      const adjacentNodes = memoizedNodes.filter(node => {
        if (node.id === selectedNode.id) return false; // Skip the selected node itself
        
        return memoizedEdges.some(edge => {
          const sourceId = typeof edge.source === 'string' ? edge.source : (edge.source as any)?.id;
          const targetId = typeof edge.target === 'string' ? edge.target : (edge.target as any)?.id;
          
          return (sourceId === selectedNode.id && targetId === node.id) || 
                 (targetId === selectedNode.id && sourceId === node.id);
        });
      });
      
      // Sort by significance/importance for better navigation
      adjacentNodes.sort((a, b) => (b.importance || 0) - (a.importance || 0));
      
      // Store in navigation ref instead of selection ref to avoid re-renders
      navigationStateRef.current.adjacentNodes = adjacentNodes;
      Logger.log(`Found ${adjacentNodes.length} adjacent nodes for keyboard navigation`);
    } else {
      navigationStateRef.current.adjacentNodes = [];
    }
    
    // Reset current index when selection changes
    navigationStateRef.current.currentIndex = -1;
  }, [selectedNode, selectNode, hoverNode, setHoverNode, memoizedNodes, memoizedEdges]);
  
  // Handle keyboard navigation
  useEffect(() => {
    // Focus the graph container to ensure it receives keyboard events
    if (containerRef.current) {
      containerRef.current.tabIndex = 0; // Make container focusable
      containerRef.current.focus();
    }
    
    const handleKeyDown = (event: KeyboardEvent) => {
      // Skip if inside an input, textarea, or other form element
      if (
        event.target instanceof HTMLInputElement || 
        event.target instanceof HTMLTextAreaElement ||
        event.target instanceof HTMLSelectElement
      ) {
        return;
      }
      
      const { key, ctrlKey, altKey, shiftKey } = event;
      const selection = selectionStateRef.current;
      const navigation = navigationStateRef.current;
      const graph = graphRef.current;
      
      if (!graph) return;
      
      // Helper function to center camera on a node
      const focusOnNode = (node: NodeData) => {
        // Update hover and selected state through the proper React state setter
        // This intentionally causes a re-render since we're changing actual state
        selectNode(node);
        
        // If we have a 3D engine, get the 3D position of the node and update camera
        if (graph && node.x !== undefined && node.y !== undefined) {
          const distance = graph.camera().position.z || 1111; // Get current camera distance
          // Animate camera to center on node
          graph.cameraPosition(
            { x: node.x, y: node.y, z: distance }, // Position
            { x: node.x, y: node.y, z: 0 },        // Look-at point
            800                                    // Animation duration (ms)
          );
          
          // Log navigation without triggering re-render
          Logger.log(`Keyboard navigation: focused on ${node.label || node.id} (${node.type})`);
          navigation.lastNavigationAction = `Focused on ${node.label || node.id}`;
        }
      };
      
      // Getting the current 3D camera
      const camera = graph.camera?.();
      
      // Based on pressed key, handle different navigation actions
      switch (key) {
        case 'ArrowRight': 
        case 'ArrowLeft':
        case 'ArrowUp':
        case 'ArrowDown': {
          // Two different navigation modes:
          // 1. If a node is selected, navigate between connected nodes
          // 2. If no node is selected or holding Shift, pan the camera
          
          if (selection.selectedNode && navigation.adjacentNodes.length > 0 && !shiftKey) {
            // Node-to-node navigation mode (when a node is selected)
            let nextIndex;
            
            if (key === 'ArrowRight' || key === 'ArrowDown') {
              nextIndex = navigation.currentIndex + 1;
              // Loop back to beginning if we've reached the end
              if (nextIndex >= navigation.adjacentNodes.length) {
                nextIndex = 0;
              }
            } else { // ArrowLeft or ArrowUp
              nextIndex = navigation.currentIndex - 1;
              // Loop to end if we're at the beginning
              if (nextIndex < 0) {
                nextIndex = navigation.adjacentNodes.length - 1;
              }
            }
            
            navigation.currentIndex = nextIndex;
            const nextNode = navigation.adjacentNodes[nextIndex];
            focusOnNode(nextNode);
            event.preventDefault();
          } else {
            // Camera panning mode (when no node is selected or holding Shift)
            // Get current camera position
            if (!camera) break;
            
            const currentPos = {
              x: camera.position.x,
              y: camera.position.y,
              z: camera.position.z
            };
            
            // Calculate pan distance based on current zoom level
            // The further zoomed out, the larger the pan steps
            const panStep = camera.position.z / 10;
            
            // New position based on arrow key
            const newPos = { ...currentPos };
            
            // Update position based on arrow key
            switch (key) {
              case 'ArrowRight':
                newPos.x += panStep;
                break;
              case 'ArrowLeft':
                newPos.x -= panStep;
                break;
              case 'ArrowUp':
                newPos.y -= panStep; // Y is inverted in 3D space
                break;
              case 'ArrowDown':
                newPos.y += panStep; // Y is inverted in 3D space
                break;
            }
            
            // Pan camera while keeping current orientation
            graph.cameraPosition(
              newPos,
              { x: newPos.x, y: newPos.y, z: 0 }, // Look at point is under camera
              300
            );
            
            event.preventDefault();
            Logger.log(`Keyboard navigation: panned camera ${key.replace('Arrow', '')}`);
            navigation.lastNavigationAction = `Panned camera ${key.replace('Arrow', '')}`;
          }
          break;
        }
        
        case 'Tab': {
          // If we have a node selected and Shift was NOT pressed, go to the next node by importance
          if (!shiftKey && selection.selectedNode) {
            // Find the current node index in the memoizedNodes array
            const currentNodeIndex = memoizedNodes.findIndex(node => node.id === selection.selectedNode?.id);
            if (currentNodeIndex >= 0) {
              const nextNodeIndex = (currentNodeIndex + 1) % memoizedNodes.length;
              focusOnNode(memoizedNodes[nextNodeIndex]);
              event.preventDefault(); // Prevent default tab behavior
            }
          }
          // If we have a node selected and Shift WAS pressed, go to the previous node by importance
          else if (shiftKey && selection.selectedNode) {
            // Find the current node index in the memoizedNodes array
            const currentNodeIndex = memoizedNodes.findIndex(node => node.id === selection.selectedNode?.id);
            if (currentNodeIndex >= 0) {
              const prevNodeIndex = (currentNodeIndex - 1 + memoizedNodes.length) % memoizedNodes.length;
              focusOnNode(memoizedNodes[prevNodeIndex]);
              event.preventDefault(); // Prevent default tab behavior
            }
          }
          break;
        }
        
        case 'Escape': {
          // Deselect current node using React state setter
          if (selection.selectedNode) {
            selectNode(null);
            event.preventDefault();
            Logger.log('Keyboard navigation: cleared selection');
            navigation.lastNavigationAction = 'Cleared selection';
          }
          break;
        }
        
        case 'Home': {
          // Select the most important node in the graph
          if (memoizedNodes.length > 0) {
            // Sort by importance and take the first one
            const sortedNodes = [...memoizedNodes].sort((a, b) => (b.importance || 0) - (a.importance || 0));
            focusOnNode(sortedNodes[0]);
            event.preventDefault();
          }
          break;
        }
        
        case 'End': {
          // Focus on user's own node if it exists
          const userNode = memoizedNodes.find(node => node.user);
          if (userNode) {
            focusOnNode(userNode);
            event.preventDefault();
          }
          break;
        }
        
        case '+':
        case '=': {
          // Zoom in while maintaining the current camera position and orientation
          if (camera) {
            // Get current camera position and target
            const currentPos = camera.position.clone();
            
            // Calculate the current look-at target
            // This is a point in the direction the camera is looking
            const lookAtDirection = new THREE.Vector3(0, 0, -1);
            lookAtDirection.applyQuaternion(camera.quaternion);
            
            // The current look-at point is somewhere along this direction
            // We use the current position and move in the look direction
            const currentTarget = {
              x: currentPos.x + lookAtDirection.x,
              y: currentPos.y + lookAtDirection.y,
              z: currentPos.z + lookAtDirection.z
            };
            
            // Create the new position by moving 20% closer to the target
            const zoomFactor = 0.8; // Zoom in by 20%
            const newZ = Math.max(100, currentPos.z * zoomFactor);
            
            // Move along the camera direction vector toward the target
            // This preserves the current camera orientation
            const newPos = {
              x: currentPos.x + (lookAtDirection.x * (1 - zoomFactor) * currentPos.z),
              y: currentPos.y + (lookAtDirection.y * (1 - zoomFactor) * currentPos.z),
              z: newZ
            };
            
            // Animate the camera to the new position while maintaining orientation
            graph.cameraPosition(
              newPos,
              currentTarget,
              300
            );
            
            event.preventDefault();
            Logger.log('Keyboard navigation: zoomed in');
            navigation.lastNavigationAction = 'Zoomed in';
          }
          break;
        }
        
        case '-': {
          // Zoom out while maintaining the current camera position and orientation
          if (camera) {
            // Get current camera position and orientation
            const currentPos = camera.position.clone();
            
            // Calculate the current look-at target
            // This is a point in the direction the camera is looking
            const lookAtDirection = new THREE.Vector3(0, 0, -1);
            lookAtDirection.applyQuaternion(camera.quaternion);
            
            // The current look-at point is somewhere along this direction
            // We use the current position and move in the look direction
            const currentTarget = {
              x: currentPos.x + lookAtDirection.x,
              y: currentPos.y + lookAtDirection.y,
              z: currentPos.z + lookAtDirection.z
            };
            
            // Create the new position by moving 20% further from the target
            const zoomFactor = 1.2; // Zoom out by 20%
            const newZ = Math.min(5000, currentPos.z * zoomFactor);
            
            // Move along the camera direction vector away from the target
            // This preserves the current camera orientation
            const newPos = {
              x: currentPos.x - (lookAtDirection.x * (zoomFactor - 1) * currentPos.z),
              y: currentPos.y - (lookAtDirection.y * (zoomFactor - 1) * currentPos.z),
              z: newZ
            };
            
            // Animate the camera to the new position while maintaining orientation
            graph.cameraPosition(
              newPos,
              currentTarget,
              300
            );
            
            event.preventDefault();
            Logger.log('Keyboard navigation: zoomed out');
            navigation.lastNavigationAction = 'Zoomed out';
          }
          break;
        }
        
        case '0': {
          // Reset zoom
          graph.zoomToFit(1000, 20);
          event.preventDefault();
          Logger.log('Keyboard navigation: reset zoom');
          navigation.lastNavigationAction = 'Reset zoom to fit all nodes';
          break;
        }
        
        case ' ': { // Spacebar
          // Toggle expanding connections for selected node
          if (selection.selectedNode && onNodeExpandRef.current) {
            onNodeExpandRef.current(selection.selectedNode);
            event.preventDefault();
            Logger.log('Keyboard navigation: expanded connections');
            navigation.lastNavigationAction = 'Expanded connections';
          }
          break;
        }
        
        case 'u': 
        case 'U': {
          // Find and focus on the user node
          const userNode = memoizedNodes.find(node => node.user === true);
          if (userNode) {
            focusOnNode(userNode);
            event.preventDefault();
            Logger.log('Keyboard navigation: focused on user node');
            navigation.lastNavigationAction = 'Focused on user node';
          }
          break;
        }
        
        case '?': {
          // Toggle keyboard help overlay
          setShowKeyboardHelp(prev => !prev);
          event.preventDefault();
          Logger.log('Keyboard navigation: toggled help panel');
          navigation.lastNavigationAction = 'Toggled keyboard help';
          break;
        }
        
        // Type-to-search navigation - find nodes starting with the pressed letter
        default: {
          // Check if key is a single letter or number
          if (key.length === 1 && /[a-zA-Z0-9]/.test(key) && !ctrlKey && !altKey) {
            // Find nodes that start with this letter (case insensitive)
            const matchingNodes = memoizedNodes.filter(node => 
              node.label && node.label.toLowerCase().startsWith(key.toLowerCase())
            );
            
            if (matchingNodes.length > 0) {
              // If we have a current node with the same starting letter,
              // find the next one in the list
              if (selection.selectedNode?.label?.toLowerCase().startsWith(key.toLowerCase())) {
                const currentIndex = matchingNodes.findIndex(node => node.id === selection.selectedNode?.id);
                const nextIndex = (currentIndex + 1) % matchingNodes.length;
                focusOnNode(matchingNodes[nextIndex]);
              } else {
                // Otherwise select the first match
                focusOnNode(matchingNodes[0]);
              }
              
              event.preventDefault();
              Logger.log(`Keyboard navigation: found node starting with "${key}"`);
              navigation.lastNavigationAction = `Found node starting with "${key}"`;
            }
          }
        }
      }
    };
    
    // Add keyboard event listener
    window.addEventListener('keydown', handleKeyDown);
    
    // Clean up listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [memoizedNodes, memoizedEdges]);
  
  
  // Build a map of all node connections for fast lookup during keyboard navigation
  useEffect(() => {
    const connectionMap = new Map<string, number[]>();
    
    // For each node, identify all its connected nodes
    memoizedNodes.forEach((sourceNode, sourceIndex) => {
      const connectedIndices: number[] = [];
      
      memoizedNodes.forEach((targetNode, targetIndex) => {
        if (sourceNode.id === targetNode.id) return; // Skip self
        
        // Check if there's an edge between these nodes
        const isConnected = memoizedEdges.some(edge => {
          const edgeSource = typeof edge.source === 'string' ? edge.source : (edge.source as any)?.id;
          const edgeTarget = typeof edge.target === 'string' ? edge.target : (edge.target as any)?.id;
          
          return (edgeSource === sourceNode.id && edgeTarget === targetNode.id) || 
                 (edgeTarget === sourceNode.id && edgeSource === targetNode.id);
        });
        
        if (isConnected) {
          connectedIndices.push(targetIndex);
        }
      });
      
      // Sort by importance for better navigation
      connectedIndices.sort((a, b) => {
        const nodeA = memoizedNodes[a];
        const nodeB = memoizedNodes[b];
        return (nodeB.importance || 0) - (nodeA.importance || 0);
      });
      
      connectionMap.set(sourceNode.id, connectedIndices);
    });
    
    // Store in navigation ref instead of selection ref
    navigationStateRef.current.connectedNodeIndices = connectionMap;
  }, [memoizedNodes, memoizedEdges]);

  // Initialize and configure the 3D force graph
  useEffect(() => {
    if (!containerRef.current) return;
    
    // Start the texture cleanup interval if not already running
    if (!textureCleanupInterval) {
      textureCleanupInterval = window.setInterval(cleanupUnusedTextures, 30000); // Run every 30 seconds
      Logger.log("Started texture cleanup interval");
    }
    
    // Clean up any previous instances to prevent WebGL context leaks
    if (graphRef.current) {
      try {
        const renderer = graphRef.current.renderer?.();
        if (renderer) {
          Logger.log("Properly disposing previous WebGL renderer");
          renderer.dispose();
          
          // Force garbage collection of WebGL resources
          renderer.forceContextLoss();
          renderer.context = null;
          renderer.domElement = null;
        }
        
        // Remove all children from the container
        while (containerRef.current.firstChild) {
          containerRef.current.removeChild(containerRef.current.firstChild);
        }
        
        graphRef.current = null;
      } catch (err) {
        Logger.error("Error cleaning up previous graph instance:", err);
      }
    }
    
    // Patch the WebGL shader error by intercepting and handling shaderSource calls
    // This prevents errors with: "Failed to execute 'shaderSource' on 'WebGL2RenderingContext': parameter 1 is not of type 'WebGLShader'"
    try {
      // Only patch if we're in a browser environment with WebGL2
      if (typeof window !== 'undefined' && window.WebGL2RenderingContext) {
        // Patch createProgram method to safely handle errors
        const originalCreateProgram = WebGL2RenderingContext.prototype.createProgram;
        WebGL2RenderingContext.prototype.createProgram = function() {
          try {
            const program = originalCreateProgram.call(this);
            return program;
          } catch (e) {
            Logger.warn("WebGL createProgram error caught:", e);
            // Return a dummy object that can be used as a placeholder
            // This prevents further errors in the rendering pipeline
            return {
              __isDummyProgram: true,
              __webglProgramDummy: true
            };
          }
        };
        Logger.log("Applied WebGL createProgram safety patch");
        
        // Store the original shaderSource method
        const originalShaderSource = WebGL2RenderingContext.prototype.shaderSource;
        
        // Replace with a safe version that validates the shader parameter
        WebGL2RenderingContext.prototype.shaderSource = function(shader, source) {
          try {
            // Check if shader is valid before proceeding
            if (shader && typeof shader === 'object') {
              return originalShaderSource.call(this, shader, source);
            }
            // If shader is invalid, silently ignore instead of throwing error
            // This keeps the graph rendering without disruption
            Logger.warn("Prevented WebGL shader error: Invalid shader parameter");
            return null;
          } catch (e) {
            // If any other error occurs in the shaderSource call, log it but don't crash
            Logger.warn("Handled WebGL shader error:", e);
            return null;
          }
        };
        
        Logger.log("Applied WebGL shader source safety patch");
        
        // Patch compileShader method to catch "parameter is not of type WebGLShader" error
        const originalCompileShader = WebGL2RenderingContext.prototype.compileShader;
        WebGL2RenderingContext.prototype.compileShader = function(shader) {
          try {
            // Check if shader is valid before proceeding
            if (shader && typeof shader === 'object') {
              return originalCompileShader.call(this, shader);
            }
            // If shader is invalid, silently ignore instead of throwing error
            Logger.warn("Prevented WebGL compileShader error: Invalid shader parameter");
            return null;
          } catch (e) {
            // If any other error occurs, log it but don't crash
            Logger.warn("Handled WebGL compileShader error:", e);
            return null;
          }
        };
        Logger.log("Applied WebGL compileShader safety patch");
        
        // Patch attachShader method to catch "parameter 1 is not of type 'WebGLProgram'" error
        const originalAttachShader = WebGL2RenderingContext.prototype.attachShader;
        WebGL2RenderingContext.prototype.attachShader = function(program, shader) {
          try {
            // Check if program and shader are valid before proceeding
            if (program && shader && typeof program === 'object' && typeof shader === 'object') {
              return originalAttachShader.call(this, program, shader);
            }
            // If parameters are invalid, silently ignore instead of throwing error
            Logger.warn("Prevented WebGL attachShader error: Invalid program or shader parameter");
            return null;
          } catch (e) {
            // If any other error occurs, log it but don't crash
            Logger.warn("Handled WebGL attachShader error:", e);
            return null;
          }
        };
        Logger.log("Applied WebGL attachShader safety patch");
        
        // We can't safely patch WebGLProgram constructor in TypeScript
        // Instead we'll focus on catching errors in other ways that are type-safe
        Logger.log("Using alternative approach for WebGLProgram error handling");
      }
    } catch (err) {
      // Log but don't crash if our patch attempt fails
      Logger.warn("Could not apply WebGL shader safety patch:", err);
    }
    
    Logger.log("Initializing ForceGraph3D with optimized WebGL configuration");
    
    // Increased limits for node and edge rendering
    // With our optimized pooling, we can handle many more nodes and edges
    const MAX_NODES = 10000;
    const MAX_EDGES = 10000;
    
    // Add global WebGL error handling to catch and report shader errors
    // This prevents uncaught exceptions from crashing the component
    try {
      if (typeof window !== 'undefined') {
        // Add a global unhandled error listener to catch WebGL errors without modifying THREE.js internals
        // This is more type-safe and won't cause conflicts with TypeScript
        window.addEventListener('error', (event: ErrorEvent) => {
          // Check if this is a WebGL or THREE.js related error
          const errorMsg = event.message || '';
          if (
            errorMsg.includes('WebGL') || 
            errorMsg.includes('three.js') || 
            errorMsg.includes('WebGLShader') || 
            errorMsg.includes('WebGLProgram') ||
            errorMsg.includes('compileShader') ||
            errorMsg.includes('attachShader') ||
            errorMsg.includes('parameter 1 is not of type')
          ) {
            Logger.warn('Caught WebGL/THREE.js error:', errorMsg);
            // Prevent the error from bubbling up and crashing the app
            event.preventDefault();
            event.stopPropagation();
          }
        }, true); // Use capture phase to catch errors early
        
        // Advanced error handling: patch THREE.js's WebGLRenderer.render method
        // This will safely wrap the render method in a try/catch block without affecting its functionality
        if (window.THREE && THREE.WebGLRenderer) {
          const originalRenderMethod = THREE.WebGLRenderer.prototype.render;
          
          THREE.WebGLRenderer.prototype.render = function(scene, camera) {
            try {
              // Call the original render method
              return originalRenderMethod.call(this, scene, camera);
            } catch (e) {
              // Log the error but don't crash the application
              Logger.warn('Caught error in THREE.js WebGLRenderer.render, continuing:', e);
              
              // Try to recover the WebGL context if possible
              try {
                if (typeof this.forceContextRestore === 'function') {
                  this.forceContextRestore();
                }
              } catch (restoreErr) {
                Logger.warn('Could not restore WebGL context:', restoreErr);
              }
              
              // Return undefined instead of crashing
              return undefined;
            }
          };
          
          // Create a safer approach that leverages the global error handler for WebGL errors
          // This specifically targets the "parameter 1 is not of type WebGLProgram" error
          
          // Add a more comprehensive global error listener specifically for this error
          window.addEventListener('error', function(event) {
            const errorMsg = event.message || '';
            
            // Specifically look for the getProgramInfoLog error that's causing issues
            if (errorMsg.includes('getProgramInfoLog') || 
                (errorMsg.includes('parameter 1 is not of type') && errorMsg.includes('WebGLProgram'))) {
              
              // Prevent this specific error from crashing the application
              Logger.warn('Intercepted WebGL error:', errorMsg);
              event.preventDefault();
              event.stopPropagation();
              
              // Set a flag to indicate we had a WebGL error
              // This will be checked by the preRenderCheck function
              window.__lastWebGLError = true;
              
              // Try to recover, but don't restart the simulation immediately
              // as that might cause cascading errors
              try {
                // Do minimal recovery here
                Logger.log("WebGL error intercepted, will attempt recovery on next frame");
              } catch (err) {
                Logger.warn("Error setting up recovery:", err);
              }
              
              return false;
            }
          }, true);
          
          Logger.log("Applied enhanced WebGL error handling for getProgramInfoLog errors");
          
          Logger.log("Patched THREE.js WebGLRenderer.render with error handling");
        }
        
        Logger.log("Applied global WebGL error handler");
        
        // Set up a global handler for WebGL context lost/restore events
        window.addEventListener('webglcontextlost', (event) => {
          Logger.warn('WebGL context lost. Attempting to recover...');
          event.preventDefault(); // Allow automatic context restoration
        }, false);
        
        window.addEventListener('webglcontextrestored', () => {
          Logger.log('WebGL context restored successfully');
          // If we have an active graph, trigger a re-render
          if (graphRef.current) {
            try {
              // Gentle way to refresh without recreating everything
              const currentSize = graphRef.current.nodeRelSize();
              graphRef.current.nodeRelSize(currentSize);
            } catch (err) {
              Logger.warn('Error refreshing graph after context restore:', err);
            }
          }
        }, false);
      
        // Create a safer version of the shader compile status check
        if (window.WebGL2RenderingContext) {
          const originalGetShaderParameter = WebGL2RenderingContext.prototype.getShaderParameter;
          WebGL2RenderingContext.prototype.getShaderParameter = function(shader, pname) {
            try {
              if (shader && typeof shader === 'object') {
                return originalGetShaderParameter.call(this, shader, pname);
              }
              // Return a safe default for invalid shaders
              return pname === this.COMPILE_STATUS ? true : null;
            } catch (e) {
              Logger.warn('Handled shader parameter error:', e);
              return pname === this.COMPILE_STATUS ? true : null;
            }
          };
          
          // Patch getProgramParameter to prevent errors when program is invalid
          const originalGetProgramParameter = WebGL2RenderingContext.prototype.getProgramParameter;
          WebGL2RenderingContext.prototype.getProgramParameter = function(program, pname) {
            try {
              if (program && typeof program === 'object') {
                return originalGetProgramParameter.call(this, program, pname);
              }
              // Return a safe default for invalid programs
              return pname === this.LINK_STATUS ? true : null;
            } catch (e) {
              Logger.warn('Handled program parameter error:', e);
              return pname === this.LINK_STATUS ? true : null;
            }
          };
          
          // Patch getProgramInfoLog to prevent errors when program is invalid
          const originalGetProgramInfoLog = WebGL2RenderingContext.prototype.getProgramInfoLog;
          WebGL2RenderingContext.prototype.getProgramInfoLog = function(program) {
            try {
              if (program && typeof program === 'object') {
                return originalGetProgramInfoLog.call(this, program);
              }
              // Return empty string for invalid programs
              Logger.warn('Prevented WebGL getProgramInfoLog error: Invalid program parameter');
              return '';
            } catch (e) {
              Logger.warn('Handled getProgramInfoLog error:', e);
              return '';
            }
          };
          
          // Patch linkProgram to prevent crashes
          const originalLinkProgram = WebGL2RenderingContext.prototype.linkProgram;
          WebGL2RenderingContext.prototype.linkProgram = function(program) {
            try {
              if (program && typeof program === 'object') {
                return originalLinkProgram.call(this, program);
              }
              Logger.warn("Prevented WebGL linkProgram error: Invalid program parameter");
              return null;
            } catch (e) {
              Logger.warn('Handled program linking error:', e);
              return null;
            }
          };
          
          Logger.log("Applied additional WebGL safety patches for program operations");
        }
      }
    } catch (err) {
      Logger.warn('Error setting up WebGL error handlers:', err);
    }
    
    // Create a set of node IDs for quick reference
    const nodeIdSet = new Set(memoizedNodes.map(node => node.id));
    
    Logger.log('Available node IDs:', nodeIdSet.size);
    
    // Prepare nodes with proper mass values for the 3D force graph
    // Can handle up to MAX_NODES with our optimized pooling and resource reuse
    const preparedNodes = memoizedNodes
      .slice(0, MAX_NODES)
      .map(node => {
        // Calculate mass based on connection density and importance
        const density = connectionDensities.get(node.id) || 0.5;
        const importanceFactor = Math.sqrt(node.importance || 1) * 0.3 + 0.7;
        
        // Return a clean copy with explicit mass and val properties
        // These properties are used by the force-directed layout algorithm
        return {
          ...node,
          id: node.id, // Ensure ID is properly set
          mass: 1 + (density * 5 * importanceFactor),
          val: 1 + (density * 5 * importanceFactor) // Use the same value for size
        };
      });
    
    // Create a set of prepared node IDs for filtering valid edges
    const preparedNodeIdSet = new Set(preparedNodes.map(node => node.id));
    
    // Filter and prepare edges to ensure they only reference existing nodes
    // Our optimized object pooling allows us to handle up to MAX_EDGES efficiently
    const preparedEdges = memoizedEdges
      .slice(0, MAX_EDGES)
      .map(edge => {
        // Normalize edge source and target to ensure they're string IDs
        const sourceId = typeof edge.source === 'string' 
          ? edge.source 
          : (edge.source as any).id;
        const targetId = typeof edge.target === 'string' 
          ? edge.target 
          : (edge.target as any).id;
        
        return {
          ...edge,
          source: sourceId,
          target: targetId
        };
      })
      // Critical: Only include edges where both source and target nodes exist
      // This prevents "node not found" errors and maintains data integrity
      .filter(edge => {
        const hasSource = preparedNodeIdSet.has(edge.source as string);
        const hasTarget = preparedNodeIdSet.has(edge.target as string);
        
        // Only log in debug mode to avoid console spam
        if ((!hasSource || !hasTarget) && process.env.NODE_ENV === 'development') {
          Logger.debug(`Filtering out edge: ${edge.source} -> ${edge.target} (missing node)`);
        }
        
        return hasSource && hasTarget;
      });
      
    Logger.log(`Prepared ${preparedNodes.length} nodes and ${preparedEdges.length} valid edges`);
    
    // Initialize 3D force graph with WebGL2 configuration inside a try-catch block
    // to prevent any WebGL errors from crashing the app
    // Define a type for the ForceGraph3D instance that includes all the methods we use
    type ForceGraph3DInstance = ReturnType<typeof ForceGraph3D> & {
      cameraPosition: (position: {x: number, y: number, z: number}, 
                      target: {x: number, y: number, z: number}, 
                      duration?: number) => any;
      zoomToFit: (duration?: number, padding?: number) => any;
      graphData: (data: {nodes: any[], links: any[]}) => any;
      nodeVal: (accessor: (node: any) => number) => any;
      nodeColor: (accessor: (node: any) => string) => any;
      linkWidth: (accessor: (link: any) => number) => any;
      linkColor: (accessor: (link: any) => string) => any;
      linkOpacity: (opacity: number) => any;
      linkDirectionalArrowLength: (accessor: (link: any) => number) => any;
      linkDirectionalArrowRelPos: (pos: number) => any;
      linkDirectionalParticles: (accessor: (link: any) => number) => any;
      linkDirectionalParticleSpeed: (accessor: ((link: any) => number) | number) => any;
      linkDirectionalParticleWidth: (accessor: (link: any) => number) => any;
      onNodeClick: (callback: (node: any) => void) => any;
      onNodeRightClick: (callback: (node: any) => void) => any;
      onNodeHover: (callback: (node: any) => void) => any;
      enableNodeDrag: (enable: boolean) => any;
      enableNavigationControls: (enable: boolean) => any;
      showNavInfo: (show: boolean) => any;
      nodeThreeObject: (accessor: (node: any) => any) => any;
      nodeRelSize: (size: number) => any;
      width: (width: number) => any;
      height: (height: number) => any;
      backgroundColor: (color: string) => any;
      nodeLabel: (accessor: (node: any) => string) => any;
      d3AlphaDecay: (decay: number) => any;
      d3VelocityDecay: (decay: number) => any;
      d3AlphaMin: (min: number) => any;
      warmupTicks: (ticks: number) => any;
      cooldownTicks: (ticks: number) => any;
      cooldownTime: (time: number) => any;
      linkPositionUpdate: (callback: (source: any, target: any, line: any, link: any) => boolean | void) => any;
      renderer?: () => THREE.WebGLRenderer;
      scene?: () => THREE.Scene;
      camera?: () => THREE.Camera;
    };
    
    let Graph: ForceGraph3DInstance | null = null;
    try {
      // Cast the result to our custom type
      Graph = ForceGraph3D({
        rendererConfig: {
          powerPreference: 'medium-performance',
          antialias: true,          // Enable antialiasing for smoother edges
          alpha: true,              // Enable transparency
          precision: 'highp',       // Use high precision for large datasets (better coordinates)
          depth: true,              // Enable depth testing for proper 3D rendering
          stencil: false,           // Disable stencil buffer (not needed)
          premultipliedAlpha: true, // Better alpha blending
          // Use WebGL2 with optimized context attributes
          contextAttributes: {
            alpha: true,
            depth: true, 
            antialias: true,
            powerPreference: 'medium-performance',
            preserveDrawingBuffer: false // Better performance
          },
          onError: (e: Error) => {
            // Custom error handler for WebGL errors during initialization
            Logger.warn('Handled WebGL initialization error:', e);
            // Continue with degraded functionality rather than failing completely
          }
        }
      })(containerRef.current);
    } catch (err) {
      Logger.error('Failed to initialize 3D force graph, attempting fallback:', err);
      
      // Fallback to simpler configuration if the initial one fails
      try {
        // Cast the result to our custom type to maintain TypeScript safety
        Graph = ForceGraph3D({
          rendererConfig: {
            precision: 'mediump', // Lower precision is more compatible
            antialias: false,     // Disable potentially problematic features
            alpha: true,
            contextAttributes: {
              powerPreference: 'default', // Use default power mode instead of high-performance
              preserveDrawingBuffer: false
            }
          }
        })(containerRef.current);
        
        Logger.log('Successfully initialized with fallback configuration');
      } catch (fallbackErr) {
        Logger.error('Critical error: Failed to initialize graph with fallback:', fallbackErr);
        // At this point, we've tried our best - the component will not crash,
        // but the graph won't be visible (better than crashing the entire app)
        return; // Exit the effect to avoid further errors
      }
    }
    
    // Continue only if we have a valid Graph object
    if (!Graph) {
      Logger.error('Could not initialize graph with any configuration');
      return;
    }
    
    // Store the valid graph instance in the ref for future access
    graphRef.current = Graph;
    
    // Add pre-render check to the scene before each frame
    const originalRender = Graph.renderer?.();
    if (originalRender && typeof originalRender.render === 'function') {
      const originalRenderMethod = originalRender.render;
      originalRender.render = function(scene: THREE.Scene, camera: THREE.Camera) {
        // Run pre-render check
        preRenderCheck();
        
        // Try to render with error handling
        try {
          return originalRenderMethod.call(this, scene, camera);
        } catch (err: unknown) {
          const error = err as Error;
          // If error specifically mentions getProgramInfoLog or WebGLProgram
          if (error && error.message && (
              error.message.includes('getProgramInfoLog') || 
              (error.message.includes('parameter 1 is not of type') && 
               error.message.includes('WebGLProgram'))
          )) {
            Logger.warn('Caught WebGL render error:', error.message);
            window.__lastWebGLError = true;
            return; // Prevent crash
          }
          
          // For other errors, still log but don't crash
          Logger.error('Unexpected render error:', error);
        }
      };
      Logger.log("Applied render method safety wrapper");
    }
    
    Graph.width(width)
      .height(height)
      .backgroundColor(darkMode ? '#1a1a2e' : '#f8f9fa')
      .nodeLabel((node: any) => node.label)
      .nodeRelSize(6)
      // Optimize force simulation for larger datasets
      .d3AlphaDecay(0.05)        // Faster simulation convergence (default 0.0228)
      .d3VelocityDecay(0.4)      // Higher value gives more rigid movement (default 0.4)
      .warmupTicks(20)           // Reduce number of warmup ticks (default 0)
      .cooldownTicks(1000)       // Ensure adequate time to settle (default Infinity)
      .cooldownTime(15000)       // Force-stop the simulation after 15s
      // Optimization: For large graphs, disable directional particles
      .linkDirectionalParticles(memoizedNodes.length > 5000 ? 0 : undefined)
      .nodeVal((node: any) => {
        // Explicitly check for valid node.id
        if (!node || typeof node.id !== 'string') {
          return 1; // Default value for invalid nodes
        }
        
        // Get normalized metrics with fallbacks
        const density = connectionDensities.get(node.id) || 0.5;
        const centrality = normalizedCentralities.get(node.id) || 0.5;
        const eigenvector = normalizedEigenvector.get(node.id) || 0.5;
        const importance = normalizedImportance.get(node.id) || 0.5;
        
        // Combine metrics with weighted influence:
        // - Eigenvector centrality (40%): Identifies influential nodes in the network
        // - Degree centrality (30%): Shows well-connected nodes
        // - Importance (20%): Emphasizes specifically tagged important nodes
        // - Connection density (10%): Base metric for general connectivity
        const combinedMetric = 
          (eigenvector * 0.4) + 
          (centrality * 0.3) + 
          (importance * 0.2) + 
          (density * 0.1);
        
        // Apply non-linear scaling to emphasize differences
        // Square root scaling makes smaller differences more noticeable
        // while preventing the largest nodes from dominating too much
        const scalingFactor = Math.sqrt(combinedMetric) * 1.5 + 0.5;
        
        // Special case: make user nodes stand out more
        if (node.user === true || (node.properties && node.properties.user === true)) {
          return Math.max(1.5, 2 + (scalingFactor * 4));
        }
        
        // Scale node size based on the combined metric
        // Ensure the result is always a valid number
        return Math.max(1, 1 + (scalingFactor * 3));
      })
      .nodeColor((node: any) => {
        // ALWAYS use node type color for consistent coloring by type
        return getNodeColor(
          node.type, 
          darkMode, 
          node.user === true || (node.properties && node.properties.user === true)
        );
      })
      .linkWidth((link: any) => {
        // Get source and target node IDs
        const sourceId = typeof link.source === 'string' ? link.source : link.source?.id;
        const targetId = typeof link.target === 'string' ? link.target : link.target?.id;
        
        if (!sourceId || !targetId) return 0.5; // Default minimum width
        
        // Calculate importance based on connected nodes' metrics
        const sourceEigenvector = normalizedEigenvector.get(sourceId) || 0.1;
        const targetEigenvector = normalizedEigenvector.get(targetId) || 0.1;
        const sourceCentrality = normalizedCentralities.get(sourceId) || 0.1;
        const targetCentrality = normalizedCentralities.get(targetId) || 0.1;
        
        // Base weight from the edge itself
        const weight = link.weight || 1;
        
        // Calculate a combined importance score for the edge:
        // - Edge weight contributes 20%
        // - The average eigenvector centrality of connected nodes contributes 50%
        // - The average degree centrality of connected nodes contributes 30%
        const edgeImportance = 
          (weight * 0.2) + 
          ((sourceEigenvector + targetEigenvector) * 0.25) + 
          ((sourceCentrality + targetCentrality) * 0.15);
        
        // Apply non-linear scaling to create more visible differences
        // between important and less important edges
        return Math.max(0.2, Math.min(3.5, 0.5 + (Math.pow(edgeImportance * 3, 1.2))));
      })
      .linkColor((link: any) => {
        // Get color based on edge type
        return getEdgeColor(link.type, darkMode);
      });
      
      // Use the pre-defined functions from outside the useEffect
      // The getEdgeSignificance and edgeSignificanceMap are now defined at the component level
      
      // Fix bug: linkOpacity method signature mismatch
      // The linkOpacity method only accepts a single scalar value, not a function
      // This is why all edges were appearing with the same opacity
      
      // Log edge significance distribution for debugging
      const significanceValues = Array.from(edgeSignificanceMap.values());
      if (significanceValues.length > 0) {
        Logger.log('Edge significance distribution:', {
          count: significanceValues.length,
          min: Math.min(...significanceValues),
          max: Math.max(...significanceValues),
          average: significanceValues.reduce((sum, val) => sum + val, 0) / significanceValues.length,
          quartiles: {
            '0-0.25': significanceValues.filter(v => v >= 0 && v < 0.25).length,
            '0.25-0.5': significanceValues.filter(v => v >= 0.25 && v < 0.5).length,
            '0.5-0.75': significanceValues.filter(v => v >= 0.5 && v < 0.75).length,
            '0.75-1.0': significanceValues.filter(v => v >= 0.75 && v <= 1.0).length
          }
        });
      }
      
      // To simulate variable opacity in 3D-force-graph when it only allows a fixed opacity value,
      // we'll use a two-part visual approach:
      // 1. Set a fixed base opacity of 0.25 for all links (the minimum requested)
      // 2. Use color brightness to simulate higher opacity for more important links
      
      // Set the fixed base opacity to a higher value for better visibility (0.35 instead of 0.25)
      Graph.linkOpacity(0.35);
      
      // Create a helper to blend colors to simulate transparency while maintaining link type identity
      const getBlendedColor = (baseColor: string, significance: number): string => {
        const color = new THREE.Color(baseColor);
        const bgColor = new THREE.Color(darkMode ? '#1a1a2e' : '#f8f9fa'); // Background color
        
        // Calculate the opacity effect we want to simulate (0.25 to 0.75)
        // Use cubic easing for better perceptual scaling
        const t = Math.pow(significance, 3);
        // Double the opacity range for more visibility - now from 0.35 to 0.95
        const simulatedOpacity = 0.35 + (t * 0.6); 
        
        // Convert to HSL to boost saturation
        let hsl = {h: 0, s: 0, l: 0};
        color.getHSL(hsl);
        
        // Increase saturation for more vibrant colors while maintaining identity
        hsl.s = Math.min(1, hsl.s * 1.3);
        
        // Set the color back with the new HSL values
        color.setHSL(hsl.h, hsl.s, hsl.l);
        
        // Blend the color with the background based on the simulated opacity
        // This simulates the visual effect of transparency
        const blendedColor = new THREE.Color().copy(color);
        
        // Blend formula: result = foreground * opacity + background * (1 - opacity)
        blendedColor.lerp(bgColor, 1 - simulatedOpacity);
        
        return '#' + blendedColor.getHexString();
      };
      
      // Use color blending to simulate variable opacity while keeping link type identity
      Graph.linkColor((link: any) => {
        // Get source and target node IDs
        const sourceId = typeof link.source === 'string' ? link.source : (link.source as any)?.id;
        const targetId = typeof link.target === 'string' ? link.target : (link.target as any)?.id;
        
        if (!sourceId || !targetId) {
          return darkMode ? '#333333' : '#cccccc'; // Default for invalid links
        }
        
        // Get base color by edge type
        const baseColor = getEdgeColor(link.type, darkMode);
        
        // Use the precomputed significance
        const key = `${sourceId}-${targetId}`;
        const edgeSignificance = edgeSignificanceMap.get(key) || 0;
        
        // Get a color that simulates the desired opacity through blending
        return getBlendedColor(baseColor, edgeSignificance);
      });
      
      // Adjust the link width based on significance (thicker = more important)
      Graph.linkWidth((link: any) => {
        // Get source and target node IDs
        const sourceId = typeof link.source === 'string' ? link.source : (link.source as any)?.id;
        const targetId = typeof link.target === 'string' ? link.target : (link.target as any)?.id;
        
        if (!sourceId || !targetId) return 0.2; // Default min width
        
        // Use the precomputed significance
        const key = `${sourceId}-${targetId}`;
        const edgeSignificance = edgeSignificanceMap.get(key) || 0;
        
        // Scale width from 0.2 to 1.8 based on significance
        // This creates a visual hierarchy with significant difference between min and max
        // Cubic easing gives more visual differentiation
        const t = Math.pow(edgeSignificance, 2);
        return 0.2 + (t * 1.6);
      });
      
      Graph.linkDirectionalArrowLength((link: any) => {
        // Add arrows for directional relationships
        return ['sent', 'received_by', 'traveled'].includes(link.type) ? 3.5 : 0;
      })
      .linkDirectionalArrowRelPos(1)
      .linkDirectionalParticles((link: any) => {
        // Get source and target node IDs
        const sourceId = typeof link.source === 'string' ? link.source : link.source?.id;
        const targetId = typeof link.target === 'string' ? link.target : link.target?.id;
        
        if (!sourceId || !targetId) return 0; // Default - no particles for insignificant edges
        
        // Use the same precomputed significance value used for opacity
        // This ensures visual consistency between opacity and particles
        const key = `${sourceId}-${targetId}`;
        const edgeSignificance = edgeSignificanceMap.get(key) || 0;
        
        // Special case: Connections to/from user nodes
        const isToFromUser = (
          (typeof link.source === 'object' && link.source?.user) || 
          (typeof link.target === 'object' && link.target?.user) ||
          (link.__sourceIsUserNode || link.__targetIsUserNode)
        );
        
        // Thresholds based on edge significance distribution:
        // - Bottom 25% (0-0.25): No particles for low significance edges
        // - 25-50% range (0.25-0.5): 1 particle
        // - 50-75% range (0.5-0.75): 2 particles
        // - Top 25% (0.75-1.0): 3-4 particles
        // - User connections: At least 1 particle and up to 5 based on significance
        
        if (isToFromUser) {
          // More particles for user connections, but still scaled by significance
          return Math.max(1, Math.min(5, Math.ceil(edgeSignificance * 6)));
        } else if (edgeSignificance < 0.25) {
          // No particles for the lowest 25% significance edges to reduce visual noise
          return 0;
        } else if (edgeSignificance < 0.5) {
          // 1 particle for low-mid significance
          return 1;
        } else if (edgeSignificance < 0.75) {
          // 2 particles for mid-high significance
          return 2;
        } else {
          // 3-4 particles for the highest significance connections
          return Math.min(4, Math.ceil(edgeSignificance * 5));
        }
      })
      .linkDirectionalParticleSpeed((link: any) => {
        // Get source and target node IDs
        const sourceId = typeof link.source === 'string' ? link.source : link.source?.id;
        const targetId = typeof link.target === 'string' ? link.target : link.target?.id;
        
        if (!sourceId || !targetId) return 0.005; // Default
        
        // Use the same precomputed significance for consistent visual treatment
        const key = `${sourceId}-${targetId}`;
        const edgeSignificance = edgeSignificanceMap.get(key) || 0;
        
        // Calculate variable speed based on edge significance:
        // - Low significance edges have slower particles (0.003-0.005)
        // - High significance edges have faster particles (0.005-0.012)
        // 
        // This creates a visual hierarchy where important edges have
        // more noticeable, faster-moving particles
        const minSpeed = 0.003;
        const maxSpeed = 0.012;
        
        // Use quadratic easing for perceptually smoother transitions
        const t = Math.pow(edgeSignificance, 2);
        return minSpeed + (t * (maxSpeed - minSpeed));
      })
      .linkDirectionalParticleWidth((link: any) => {
        // Get source and target node IDs
        const sourceId = typeof link.source === 'string' ? link.source : link.source?.id;
        const targetId = typeof link.target === 'string' ? link.target : link.target?.id;
        
        if (!sourceId || !targetId) return 0.4; // Default minimum width
        
        // Use the same precomputed significance for consistent visual treatment
        const key = `${sourceId}-${targetId}`;
        const edgeSignificance = edgeSignificanceMap.get(key) || 0;
        
        // Special case for user node connections
        const isToFromUser = (
          (typeof link.source === 'object' && link.source?.user) || 
          (typeof link.target === 'object' && link.target?.user) ||
          (link.__sourceIsUserNode || link.__targetIsUserNode)
        );
        
        // Scale particle width proportionally to significance
        // For regular edges: 0.4 to 2.2 width
        // For user edges: 0.8 to 2.5 width
        const minWidth = isToFromUser ? 0.8 : 0.4;
        const maxWidth = isToFromUser ? 2.5 : 2.2;
        
        // Use quadratic easing for smoother perceptual scaling
        // This matches the easing used for particle speed
        const t = Math.pow(edgeSignificance, 2);
        return minWidth + (t * (maxWidth - minWidth));
      })
      .onNodeClick((node: any) => {
        // Skip if no node (click on background) or invalid node
        if (!node || !node.id) return;
        
        // Prevent duplicate click handling
        if (window.__isHandlingNodeClick) return;
        window.__isHandlingNodeClick = true;
        
        // Immediately update global state for rendering without React state changes
        // This is much faster than waiting for React state updates
        window.__nodeSelectionState = {
          ...window.__nodeSelectionState,
          selectedNodeId: node.id
        };
        
        // Force a local re-render of the affected nodes WITHOUT a full graph re-render
        if (graphRef.current) {
          try {
            // Trigger just the rendering update without recreating the graph
            const graph = graphRef.current;
            if (typeof graph.nodeRelSize === 'function') {
              const currentSize = graph.nodeRelSize();
              graph.nodeRelSize(currentSize);
            }
          } catch (err) {
            Logger.error('Error during optimized node click update:', err);
          }
        }
        
        // Use queueMicrotask for better performance than requestAnimationFrame
        // This still defers the React state updates but with less delay
        queueMicrotask(() => {
          try {
            // Get the current callback reference to avoid closure issues
            const selectNodeCallback = selectionStateRef.current?.selectNode;
            const onNodeClickCallback = onNodeClickRef.current;
            
            // Only update if we have callbacks
            if (selectNodeCallback) {
              // This will update React state but won't trigger a graph re-render
              selectNodeCallback(node);
            }
            
            // Call the callback for application logic to respond to the selection
            if (onNodeClickCallback) {
              onNodeClickCallback(node);
            }
          } finally {
            // Always clear the flag when done
            window.__isHandlingNodeClick = false;
          }
        });
      })
      .onNodeRightClick((node: any) => {
        // Skip if no node or invalid node
        if (!node || !node.id) return;
        
        // Track if handling is already in progress
        if (window.__isHandlingNodeExpand) return;
        window.__isHandlingNodeExpand = true;
        
        // Use microtask for better performance
        queueMicrotask(() => {
          try {
            // Get the callback from ref
            const onNodeExpandCallback = onNodeExpandRef.current;
            
            if (onNodeExpandCallback) {
              onNodeExpandCallback(node);
            }
          } finally {
            window.__isHandlingNodeExpand = false;
          }
        });
      })
      .onNodeHover((node: any) => {
        // EXTREME hover throttling to prevent violations
        const now = performance.now();
        
        // Initialize hover state if not already done
        if (!window.__hoverNodeState) {
          window.__hoverNodeState = { 
            node: null, 
            lastUpdateTime: 0,
            callbackSent: false
          };
        }
        
        // Skip if the node is the same as what we're already hovering over - important optimization!
        if (window.__hoverNodeState.node === node) return;
        
        // Even more aggressive throttling for hover events
        const HOVER_THROTTLE = 300; // Increase to 300ms to further reduce violations
        
        // Store node in global state immediately for rendering - this is critical!
        // This way nodeThreeObject can access it without needing React state updates
        window.__hoverNodeState.node = node;
        
        // This is the key change: Don't call setHoverNode() for every hover event,
        // only use it for callback-related functions that actually need to trigger UI updates
        
        // Check if we should update React state and call the callback
        const shouldTriggerCallback = (!window.__hoverUpdateTimer && 
          (now - window.__hoverNodeState.lastUpdateTime > HOVER_THROTTLE));
        
        if (shouldTriggerCallback) {
          // Update timestamp
          window.__hoverNodeState.lastUpdateTime = now;
          
          // Only tell React about significant hover changes (not every pixel movement)
          // This dramatically reduces state updates during hover
          window.__nodeSelectionState = {
            ...window.__nodeSelectionState,
            hoveredNodeId: node?.id
          };
          
          // Force a local re-render of the affected nodes WITHOUT a full graph re-render
          if (graphRef.current) {
            try {
              // This is a much better approach - trigger just the rendering part
              // without recreating the graph
              const graph = graphRef.current;
              
              // Mark the graph for a gentle re-render - much lighter than state updates
              if (typeof graph.nodeRelSize === 'function') {
                // Get current size to retain it
                const currentSize = graph.nodeRelSize();
                // Call with same value (no visible change, but forces update of affected nodes)
                graph.nodeRelSize(currentSize);
              }
            } catch (err) {
              Logger.error('Error during optimized node hover update:', err);
            }
          }
          
                  // Use a microtask for the callback to avoid blocking the UI thread
          queueMicrotask(() => {
            // Get the callbacks from refs to avoid closure issues
            const setHoverNodeCallback = selectionStateRef.current?.setHoverNode;
            const onNodeHoverCallback = onNodeHoverRef.current;
            
            // Call the callback (user code needs this for tooltip or other UI elements)
            // This will trigger a UI update but not a graph re-render
            if (!window.__hoverNodeState || node !== window.__hoverNodeState.node) {
              // Node changed since we queued the callback, don't call with stale data
              return;
            }
            
            // Only call the callback with changes that last at least the HOVER_THROTTLE duration
            if (onNodeHoverCallback) {
              onNodeHoverCallback(node);
            }
            
            // Use the ref callback instead of direct state setter to avoid re-renders
            if (setHoverNodeCallback) {
              setHoverNodeCallback(node);
            }
          });
          
          // Clear any pending timer 
          if (window.__hoverUpdateTimer) {
            clearTimeout(window.__hoverUpdateTimer);
            window.__hoverUpdateTimer = null;
          }
        } else {
          // Too frequent hover update, don't update React state yet
          // But ensure node appearance updates via THREE.js (handled by nodeThreeObject)
          
          // Schedule a deferred update if not already scheduled
          if (!window.__hoverUpdateTimer) {
            window.__hoverUpdateTimer = setTimeout(() => {
              // Only update after timeout if the hovered node is still the same
              // This prevents flicker and unnecessary work
              if (window.__hoverNodeState && window.__hoverNodeState.node === node) {
                window.__hoverNodeState.lastUpdateTime = performance.now();
                
                // Set the global selection state
                window.__nodeSelectionState = {
                  ...window.__nodeSelectionState,
                  hoveredNodeId: node?.id
                };
                
                // Force render update without React state change
                if (graphRef.current) {
                  const graph = graphRef.current;
                  if (typeof graph.nodeRelSize === 'function') {
                    const currentSize = graph.nodeRelSize();
                    graph.nodeRelSize(currentSize);
                  }
                }
                
                // Get the callbacks from refs
                const setHoverNodeCallback = selectionStateRef.current?.setHoverNode;
                const onNodeHoverCallback = onNodeHoverRef.current;
                
                // Call the hover callback only if the node is still the same
                if (onNodeHoverCallback) {
                  onNodeHoverCallback(node);
                }
                
                // Use the ref callback instead of direct function to avoid re-renders
                if (setHoverNodeCallback) {
                  setHoverNodeCallback(node);
                }
              }
              
              window.__hoverUpdateTimer = null;
            }, HOVER_THROTTLE);
          }
        }
      })
      .enableNodeDrag(true)
      .enableNavigationControls(true)
      .showNavInfo(false);
    
    // Configure custom WebGL material for nodes to ensure compatibility
    Graph
      .nodeColor((node: any) => {
        // ALWAYS use node type color for consistent coloring by type
        return getNodeColor(
          node.type, 
          darkMode, 
          node.user === true || (node.properties && node.properties.user === true)
        );
      })
      // @ts-ignore - Type issues with nodeThreeObject return type
      .nodeThreeObject((node: any) => {
        try {
          // Always check validity first to prevent errors
          if (!node || !node.id) {
            return null;
          }
          
          const nodeId = node.id;
          const isLargeDataset = memoizedNodes.length > 500;
          
          // Fast identity checks with global state caching to avoid React state lookups
          // This improves performance by avoiding unnecessary React state access
          // and using cached values directly
          
          // Global state cache for selection & hover - already set by onNodeClick/onNodeHover
          if (!window.__nodeSelectionState) {
            window.__nodeSelectionState = {
              selectedNodeId: undefined,
              hoveredNodeId: undefined
            };
          }
          
          // Don't update from React state at all - completely decouple state
          // This ensures the graph will not re-render when React state changes
          
          // ONLY use the global state, completely avoiding React state
          // This is critical for preventing re-renders
          const isSelected = window.__nodeSelectionState?.selectedNodeId === nodeId;
          const isHovered = window.__nodeSelectionState?.hoveredNodeId === nodeId || window.__hoverNodeState?.node?.id === nodeId;
          
          // Determine if this is a special node
          const isUserNode = node.user === true || (node.properties && node.properties.user === true);
          
          // For large datasets, use default rendering for most nodes (massive performance gain)
          // Only apply custom rendering to important nodes like user nodes, selected, or hovered
          if (isLargeDataset && !isSelected && !isHovered && !isUserNode) {
            // Return undefined to use default rendering (much faster!)
            return undefined;
          }
          
          // Get this node's metrics and calculate combined importance
          const density = connectionDensities.get(nodeId) || 0.5;
          const centrality = normalizedCentralities.get(nodeId) || 0.5;
          const eigenvector = normalizedEigenvector.get(nodeId) || 0.5;
          const importance = normalizedImportance.get(nodeId) || 0.5;
          
          // Calculate combined metric (same formula as in nodeVal for consistency)
          const combinedMetric = 
            (eigenvector * 0.4) + 
            (centrality * 0.3) + 
            (importance * 0.2) + 
            (density * 0.1);
            
          // Apply non-linear scaling to emphasize differences
          const scalingFactor = Math.sqrt(combinedMetric) * 1.5 + 0.5;
          
          // Calculate size based on the combined metrics (same as in nodeVal)
          const size = isUserNode 
            ? Math.max(1.5, 2 + (scalingFactor * 4)) 
            : Math.max(1, 1 + (scalingFactor * 3));
          
          // Get node color based on type (ignore community colors)
          const nodeColor = getNodeColor(node.type, darkMode, isUserNode);
          
          // Create shared geometry if needed (or use pool)
          if (!geometryPool.sphere) {
            // Use very low poly count for better performance
            geometryPool.sphere = new THREE.SphereGeometry(1, isLargeDataset ? 6 : 8, isLargeDataset ? 6 : 8);
          }
          
          // Reuse existing group if available
          let group = nodeObjectsRef.current.get(nodeId);
          if (!group) {
            group = new THREE.Group();
            nodeObjectsRef.current.set(nodeId, group);
          } else {
            // Clear existing group (much cheaper than recreating)
            while (group.children.length > 0) {
              group.remove(group.children[0]);
            }
          }
          
          // Get or create material (pooled by color)
          // Special material for selected/hovered nodes
          const materialKey = isSelected || isHovered 
            ? `${nodeColor}-highlight-${darkMode ? 'dark' : 'light'}`
            : `${nodeColor}-${darkMode ? 'dark' : 'light'}`;
            
          let material = materialPool.get(materialKey);
          if (!material) {
            // Create material with colors adjusted for selection/hover
            const color = new THREE.Color(nodeColor);
            
            // Brighten the color for selected/hovered nodes
            if (isSelected || isHovered) {
              color.r = Math.min(1, color.r * 1.2);
              color.g = Math.min(1, color.g * 1.2);
              color.b = Math.min(1, color.b * 1.2);
            }
            
            material = new THREE.MeshBasicMaterial({ 
              color: color,
              transparent: true,
              opacity: isSelected || isHovered ? 1.0 : 0.9
            });
            materialPool.set(materialKey, material);
          }
          
          // Create main node sphere using the pooled geometry
          if (geometryPool.sphere) {
            const mesh = new THREE.Mesh(geometryPool.sphere, material);
            // Make selected/hovered nodes slightly larger
            const scaleFactor = isSelected || isHovered ? 1.1 : 1.0;
            mesh.scale.set(size * scaleFactor, size * scaleFactor, size * scaleFactor);
            group.add(mesh);
            
            // Apply special effect to user nodes
            if (isUserNode) {
              // Special unique color for user nodes that's distinct from other nodes
              // Bright electric blue that's very recognizable
              const userNodeBaseColor = new THREE.Color(darkMode ? '#4D9FFF' : '#0077FF');
              
              // Apply the unique color to the main node sphere
              mesh.material = new THREE.MeshBasicMaterial({
                color: userNodeBaseColor,
                transparent: true,
                opacity: isSelected || isHovered ? 1.0 : 0.9
              });
              
              // Create glowing ring effect for user nodes
              const ringGeometry = new THREE.RingGeometry(size * 1.1, size * 1.25, 32);
              
              // Calculate phase based on node ID to ensure different nodes pulse at different times
              const nodeIdSum = nodeId.split('').reduce((sum: number, char: string) => sum + char.charCodeAt(0), 0);
              const initialPhase = (nodeIdSum % 100) / 100; // Value between 0-1 based on node ID
              
              // Use performance.now() for smooth animation
              const time = performance.now() * 0.001 * 1.5; // Pulse speed 1.5
              
              // Calculate color pulse effect - shift between blue and teal
              const colorPulse = Math.sin((time + initialPhase) * Math.PI * 2) * 0.5 + 0.5; // 0-1 value
              const ringColor = new THREE.Color().setRGB(
                0.1,  // Red component - keep low for blue/teal effect
                0.5 + colorPulse * 0.3, // Green component - varies from 0.5 to 0.8
                1.0 - colorPulse * 0.2  // Blue component - varies from 1.0 to 0.8
              );
              
              // Create glowing ring material with pulsing color
              const ringMaterial = new THREE.MeshBasicMaterial({
                color: ringColor,
                transparent: true,
                opacity: isSelected || isHovered ? 0.9 : 0.75,
                side: THREE.DoubleSide
              });
              
              const ring = new THREE.Mesh(ringGeometry, ringMaterial);
              
              // Make sure ring is visible on all axes
              ring.rotation.x = Math.PI / 2;
              group.add(ring);
              
              // Scale pulsing animation
              const pulseScale = 0.15; // Scale of pulse effect (0.15 = 15% size variation)
              const pulseFactor = 1 + (Math.sin((time + initialPhase + 0.5) * Math.PI * 2) * pulseScale);
              
              // Apply pulse scaling to both the node and the ring
              mesh.scale.multiplyScalar(pulseFactor);
              ring.scale.set(pulseFactor, pulseFactor, pulseFactor);
              
              // Add a second outer ring for extra emphasis
              const outerRingGeometry = new THREE.RingGeometry(size * 1.3, size * 1.35, 32);
              const outerRingMaterial = new THREE.MeshBasicMaterial({
                color: ringColor.clone().offsetHSL(0.1, 0, 0), // Slightly different hue
                transparent: true,
                opacity: isSelected || isHovered ? 0.6 : 0.4,
                side: THREE.DoubleSide
              });
              
              const outerRing = new THREE.Mesh(outerRingGeometry, outerRingMaterial);
              outerRing.rotation.x = Math.PI / 2; // Rotate around X axis (horizontal)
              // Set the outer ring to pulse in opposite phase for interesting effect
              outerRing.scale.set(2 - pulseFactor, 2 - pulseFactor, 2 - pulseFactor);
              group.add(outerRing);
              
              // Add a perpendicular ring (90 degrees - vertical ring)
              const verticalRingGeometry = new THREE.RingGeometry(size * 1.3, size * 1.35, 32);
              const verticalRingMaterial = new THREE.MeshBasicMaterial({
                color: ringColor.clone().offsetHSL(-0.1, 0, 0), // Different hue variation
                transparent: true,
                opacity: isSelected || isHovered ? 0.5 : 0.3, // Slightly more transparent
                side: THREE.DoubleSide
              });
              
              const verticalRing = new THREE.Mesh(verticalRingGeometry, verticalRingMaterial);
              // No rotation on X axis keeps it vertical (perpendicular to the first ring)
              verticalRing.rotation.y = Math.PI / 2; // Rotate around Y axis
              // Using slightly different animation phase
              const verticalPulseFactor = 1 + (Math.sin((time + initialPhase + 0.25) * Math.PI * 2) * pulseScale);
              verticalRing.scale.set(verticalPulseFactor, verticalPulseFactor, verticalPulseFactor);
              group.add(verticalRing);
              
              // Add a ring at 45 degrees angle
              const angledRingGeometry = new THREE.RingGeometry(size * 1.4, size * 1.45, 32);
              const angledRingMaterial = new THREE.MeshBasicMaterial({
                color: ringColor.clone().offsetHSL(0.2, 0, 0.1), // Different hue and lightness
                transparent: true,
                opacity: isSelected || isHovered ? 0.5 : 0.35,
                side: THREE.DoubleSide
              });
              
              const angledRing = new THREE.Mesh(angledRingGeometry, angledRingMaterial);
              // Apply 45 degree rotations on two axes to create the angled effect
              angledRing.rotation.x = Math.PI / 4; // 45 degrees on X axis
              angledRing.rotation.y = Math.PI / 4; // 45 degrees on Y axis
              // Use yet another slightly different animation phase
              const angledPulseFactor = 1 + (Math.sin((time + initialPhase + 0.75) * Math.PI * 2) * pulseScale);
              angledRing.scale.set(angledPulseFactor, angledPulseFactor, angledPulseFactor);
              group.add(angledRing);
            }
          }
          
          // Only add text labels for specific nodes or when not using a huge dataset
          // This dramatically reduces the number of WebGL textures created
          const shouldAddLabel = 
            // Must have a label
            node.label && 
            node.label.length > 0 && 
            // Only add for special nodes if using a large dataset
            (!isLargeDataset || isSelected || isHovered || isUserNode);
            
          if (shouldAddLabel) {
            // Create shared canvas on demand
            if (!sharedLabelCanvas) {
              sharedLabelCanvas = document.createElement('canvas');
              sharedLabelCanvas.width = 256;
              sharedLabelCanvas.height = 64;
              sharedLabelContext = sharedLabelCanvas.getContext('2d', { willReadFrequently: false });
            }
            
            // Skip label if context couldn't be created
            if (sharedLabelContext) {
              // Truncate long labels
              const label = node.label.length > 20 ? node.label.substring(0, 20) + '...' : node.label;
              const textureKey = `${label}-${darkMode ? 'dark' : 'light'}`;
              
              // Use cached texture if available
              let textureCacheEntry = textureCache.get(textureKey);
              let texture: THREE.Texture;
              
              // If found in cache, update last used timestamp
              if (textureCacheEntry) {
                texture = textureCacheEntry.texture;
                // Update the "last used" timestamp
                textureCache.set(textureKey, {
                  texture,
                  lastUsed: Date.now()
                });
              } else {
                // Create new texture if not in cache
                const context = sharedLabelContext;
                context.clearRect(0, 0, sharedLabelCanvas!.width, sharedLabelCanvas!.height);
                context.font = `18px Arial`;
                context.textAlign = 'center';
                context.textBaseline = 'middle';
                context.fillStyle = darkMode ? '#ffffff' : '#000000';
                context.fillText(label, sharedLabelCanvas!.width / 2, sharedLabelCanvas!.height / 2);
                
                texture = new THREE.CanvasTexture(sharedLabelCanvas!);
                texture.needsUpdate = true;
                texture.generateMipmaps = false;
                texture.minFilter = THREE.LinearFilter;
                texture.wrapS = THREE.ClampToEdgeWrapping;
                texture.wrapT = THREE.ClampToEdgeWrapping;
                
                // Add to cache with current timestamp
                textureCache.set(textureKey, {
                  texture,
                  lastUsed: Date.now()
                });
                
                // Check if we need to clean up (if too many textures)
                if (textureCache.size > MAX_TEXTURE_CACHE_SIZE) {
                  // Schedule cleanup in next tick to avoid blocking the current operation
                  setTimeout(cleanupUnusedTextures, 0);
                }
              }
              
              // Create sprite for text label
              const spriteMaterial = new THREE.SpriteMaterial({
                map: texture,
                transparent: true
              });
              
              const sprite = new THREE.Sprite(spriteMaterial);
              sprite.position.set(0, size + 1.2, 0);
              sprite.scale.set(0.2, 0.05, 1);
              
              group.add(sprite);
            }
          }
          
          return group;
        } catch (error) {
          // Return undefined instead of null on error to use default rendering
          // This ensures visible nodes even if custom rendering fails
          Logger.error("Error in nodeThreeObject:", error);
          return undefined;
        }
      });
    
    // Graph reference is already stored at this point after validation
    
    // Set nodes and links with prepared data that has proper types and properties
    // @ts-ignore - Type issues with 3d-force-graph data format
    Graph.graphData({
      nodes: preparedNodes,
      links: preparedEdges
    });
    
    // Optimize scene and rendering performance
    const scene = Graph.scene();
    const renderer = Graph.renderer?.();
    
    if (scene) {
      try {
        // Remove any existing lights
        const existingLights = scene.children.filter((child: any) => child instanceof THREE.Light);
        existingLights.forEach((light: THREE.Light) => {
          scene.remove(light);
        });
        
        // Configure scene for optimal performance
        scene.matrixAutoUpdate = false; // Disable automatic matrix updates
        scene.autoUpdate = true; // Keep auto updates for scene graph
        
        // Use a single ambient light for better performance
        // MeshBasicMaterial doesn't need lighting, but this provides fallback for other materials
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.9);
        scene.add(ambientLight);
      } catch (error) {
        Logger.error("Error configuring scene:", error);
      }
    }
    
    // Configure renderer for better performance
    if (renderer) {
      try {
        // Set pixel ratio to balance quality and performance
        const maxPixelRatio = Math.min(2, window.devicePixelRatio || 1);
        renderer.setPixelRatio(maxPixelRatio);
        
        // Optimize renderer settings for performance
        renderer.physicallyCorrectLights = false;
        renderer.shadowMap.enabled = false;  // Disable shadows for better performance
        
        // Lower precision for fragment shaders on large datasets
        if (memoizedNodes.length > 5000 && renderer.capabilities) {
          renderer.capabilities.precision = 'mediump';
        }
        
        // For large datasets, reduce the animation speed using a less invasive approach
        // Store frame rate limit in window for global coordination
        if (memoizedNodes.length > 3000) {
          window.__graphFrameRateCap = 30; // Cap at 30fps for large graphs
        } else {
          window.__graphFrameRateCap = 60; // Normal 60fps for smaller graphs
        }
      } catch (error) {
        Logger.error("Error configuring renderer:", error);
      }
    }
    
    // Handle renderer carefully - the 3d-force-graph library
    // manages its renderer internally, so we need to be careful
    try {
      // Access renderer through the method provided by 3d-force-graph
      // but don't try to modify it directly as it may not be fully initialized
      
      // Set background color via the graph API instead
      Graph.backgroundColor(darkMode ? '#1a1a2e' : '#f8f9fa');
    } catch (err) {
      Logger.warn("Could not configure renderer:", err);
    }
    
    // For large graphs, implement a global throttle on frame rendering
    // This is a safer approach than trying to modify animation loops
    if (memoizedNodes.length > 3000) {
      // Set a reasonable frame cap for large graphs to improve interaction performance
      const fps = 30;
      Logger.log(`Large graph detected (${memoizedNodes.length} nodes): limiting to ${fps}fps`);
      
      // Store settings globally
      window.__graphSettings = {
        ...window.__graphSettings,
        isLargeGraph: true,
        frameCap: fps,
        lastRenderTime: 0
      };
      
      // Adjust force graph physics settings for better performance with large graphs
      Graph
        .d3AlphaMin(0.1)      // Stop simulation earlier (default 0.001)
        .cooldownTicks(100)   // Limit the number of simulation iterations
        .linkWidth(0.5)       // Thinner links for better performance
        .nodeResolution(8);   // Lower sphere segments for better performance
    } else {
      window.__graphSettings = {
        ...window.__graphSettings,
        isLargeGraph: false
      };
    }
    
    // Find the highest density user node to focus on
    const findHighestDensityUserNode = () => {
      // Look for user nodes first
      const userNodes = preparedNodes.filter(node => 
        node.user === true || (node.properties && node.properties.user === true)
      );
      
      if (userNodes.length === 0) {
        Logger.log('No user nodes found, using standard zoom to fit');
        return null;
      }
      
      // Find the user node with the highest connection density
      let highestDensityNode = userNodes[0];
      let highestDensity = connectionDensities.get(highestDensityNode.id) || 0;
      
      userNodes.forEach(node => {
        const density = connectionDensities.get(node.id) || 0;
        if (density > highestDensity) {
          highestDensity = density;
          highestDensityNode = node;
        }
      });
      
      Logger.log(`Focusing on highest density user node: ${highestDensityNode.label} (density: ${highestDensity})`);
      return highestDensityNode;
    };
    
    // Apply custom zoom focusing on the highest density user node
    setTimeout(() => {
      const focusNode = findHighestDensityUserNode();
      
      if (focusNode) {
        // Center on the high-density user node with a precise zoom level
        Logger.log(`Setting camera position 1111 units away and centering on ${focusNode.label}`);
        // Set camera directly to the final position in one step
        // Position the camera 1111 units away on Z axis for precise zoom level
        try {
          Logger.log(`Positioning camera to focus on node at (${focusNode.x}, ${focusNode.y})`);
          if (Graph) {
            Graph.cameraPosition(
              { x: focusNode.x, y: focusNode.y, z: 1111 }, // Position at 1111 units away for precise zoom level
              { x: focusNode.x, y: focusNode.y, z: 0 },   // Target the node's position
              1800  // Transition duration in ms (slower for smoother movement)
            );
          }
        } catch (err) {
          Logger.error("Error setting camera position:", err);
          // Fallback to standard zoom to fit
          if (Graph) {
            Graph.zoomToFit(1000, 50);
          }
        }
      } else {
        // Fallback to standard zoom to fit
        if (Graph) {
          Graph.zoomToFit(1000, 50);
        }
      }
      
      window.__graphMetadata = {
        ...window.__graphMetadata,
        hasPerformedInitialFit: true
      };
      window.__graphCenteredOnUserNode = !!focusNode;
    }, 200); // Slight delay to ensure graph has initialized
    
    return () => {
      // Clear the texture cleanup interval
      if (textureCleanupInterval) {
        clearInterval(textureCleanupInterval);
        textureCleanupInterval = null;
        Logger.log("Cleared texture cleanup interval");
      }
      
      // Comprehensive cleanup to prevent memory leaks and WebGL context issues
      if (graphRef.current) {
        try {
          Logger.log("Properly disposing WebGL resources on unmount");
          
          // Get access to renderer for proper disposal
          const renderer = graphRef.current.renderer?.();
          if (renderer) {
            // First dispose the renderer to release WebGL resources
            renderer.dispose();
            
            // Clear renderer references
            if (typeof renderer.forceContextLoss === 'function') {
              renderer.forceContextLoss();
            }
          }
          
          // Get scene and dispose all geometries and materials
          const scene = graphRef.current.scene?.();
          if (scene) {
            // Clear scene first (removes references to objects)
            while (scene.children.length > 0) {
              scene.remove(scene.children[0]);
            }
          }
          
          // Clean up pooled resources
          
          // 1. Clean up node objects map
          nodeObjectsRef.current.clear();
          
          // 2. Dispose pooled geometries
          if (geometryPool.sphere) {
            geometryPool.sphere.dispose();
            geometryPool.sphere = null;
          }
          
          // 3. Dispose pooled materials
          const disposePooledMaterials = (materialMap: Map<string, THREE.Material>) => {
            materialMap.forEach((material) => {
              disposeMaterial(material);
            });
            materialMap.clear();
          };
          
          disposePooledMaterials(materialPool);
          
          // 4. Dispose pooled textures
          textureCache.forEach((entry) => {
            entry.texture.dispose();
          });
          textureCache.clear();
          
          // 5. Clean up shared label canvas
          if (sharedLabelCanvas) {
            sharedLabelCanvas.width = 1;
            sharedLabelCanvas.height = 1;
            sharedLabelContext = null;
            sharedLabelCanvas = null;
          }
          
          // Helper function to dispose material and its textures
          function disposeMaterial(material: any) {
            // Dispose textures
            if (material.map) material.map.dispose();
            if (material.lightMap) material.lightMap.dispose();
            if (material.bumpMap) material.bumpMap.dispose();
            if (material.normalMap) material.normalMap.dispose();
            if (material.specularMap) material.specularMap.dispose();
            if (material.envMap) material.envMap.dispose();
            
            // Dispose material
            material.dispose();
          }
          
          // Reset graphRef
          graphRef.current = null;
        } catch (err) {
          Logger.error("Error during WebGL cleanup:", err);
        }
      }
      
      // Clean up DOM
      if (containerRef.current) {
        while (containerRef.current.firstChild) {
          containerRef.current.removeChild(containerRef.current.firstChild);
        }
      }
      
      // Log memory stats to help detect leaks
      Logger.log("Memory stats after cleanup:", 
        performance.memory ? 
          {
            totalJSHeapSize: Math.round(performance.memory.totalJSHeapSize / (1024 * 1024)) + 'MB',
            usedJSHeapSize: Math.round(performance.memory.usedJSHeapSize / (1024 * 1024)) + 'MB'
          } : 
          'Memory API not available'
      );
    };
  // CRITICAL: Remove hoverNode and selectedNode from the dependency array
  // This prevents the entire graph from being recreated when hover/selection changes
  // We handle hover/selection updates directly in the nodeThreeObject function
  }, [
    // Only recreate on fundamental changes to the graph data
    memoizedNodes, 
    memoizedEdges, 
    width, 
    height, 
    darkMode, 
    connectionDensities, 
    communities, 
    getNodeCommunityColor
    
    // CRITICAL: We've removed ALL event handler/callback dependencies
    // We now access these through refs to avoid re-rendering the graph
    // when callbacks or selection state changes
  ]);
  
  // Effect to update nodes and links when data changes
  useEffect(() => {
    if (graphRef.current) {
      Logger.log('Updating graph data without full recreation');
      
      // Use the same node preparation logic as in initialization
      const preparedNodes = memoizedNodes.map(node => {
        const density = connectionDensities.get(node.id) || 0.5;
        const importanceFactor = Math.sqrt(node.importance || 1) * 0.3 + 0.7;
        
        return {
          ...node,
          id: node.id, // Ensure ID is properly set
          mass: 1 + (density * 5 * importanceFactor),
          val: 1 + (density * 5 * importanceFactor)
        };
      });
      
      // Create a set of prepared node IDs for filtering valid edges
      const preparedNodeIdSet = new Set(preparedNodes.map(node => node.id));
      
      // Map of user nodes for special edge handling
      const userNodeMap = new Map<string, NodeData>();
      preparedNodes.forEach(node => {
        if (node.user === true || (node.properties && node.properties.user === true)) {
          userNodeMap.set(node.id, node);
        }
      });
      
      // Use the same edge filtering logic to ensure data consistency
      const preparedEdges = memoizedEdges
        .map(edge => {
          const sourceId = typeof edge.source === 'string' 
            ? edge.source 
            : (edge.source as any).id;
          const targetId = typeof edge.target === 'string' 
            ? edge.target 
            : (edge.target as any).id;
          
          const baseEdge = {
            ...edge,
            source: sourceId,
            target: targetId
          };
          
          // Special handling for edges connected to user nodes
          const isSourceUserNode = userNodeMap.has(sourceId);
          const isTargetUserNode = userNodeMap.has(targetId);
          
          if (isSourceUserNode || isTargetUserNode) {
            // Add special properties to edges connected to user nodes
            return {
              ...baseEdge,
              // Add property to indicate this edge connects to a user node
              // This will be used in the linkPositionUpdate function
              __connectedToUserNode: true,
              __sourceIsUserNode: isSourceUserNode,
              __targetIsUserNode: isTargetUserNode
            };
          }
          
          return baseEdge;
        })
        // Critical: Only include edges where both source and target nodes exist
        .filter(edge => {
          return preparedNodeIdSet.has(edge.source as string) && 
                 preparedNodeIdSet.has(edge.target as string);
        });
      
      Logger.log(`Update: ${preparedNodes.length} nodes and ${preparedEdges.length} valid edges`);
      
      // Only update if we have a valid graph and nodes/edges
      if (graphRef.current && preparedNodes.length > 0) {
        // Configure custom edge positioning for user nodes
        graphRef.current.linkPositionUpdate((source: any, target: any, line: any, link: any) => {
          // Skip custom positioning if link is undefined or not connected to a user node
          if (!link || link.__connectedToUserNode !== true) return;
          
          // Ensure source and target objects are valid
          if (!source || !target) return;
          
          // Extract node positions
          const sourcePos = source;
          const targetPos = target;
          
          // Custom start/end positions for links connected to user nodes
          // Calculate direction vector from source to target
          let startPos, endPos;
          
          if (link.__sourceIsUserNode) {
            // Calculate direction from source to target
            const dirVect = {
              x: targetPos.x - sourcePos.x,
              y: targetPos.y - sourcePos.y,
              z: targetPos.z - sourcePos.z
            };
            
            // Calculate direction length
            const vectLength = Math.sqrt(
              dirVect.x * dirVect.x + 
              dirVect.y * dirVect.y + 
              dirVect.z * dirVect.z
            );
            
            // Normalize direction vector
            if (vectLength > 0) {
              dirVect.x /= vectLength;
              dirVect.y /= vectLength;
              dirVect.z /= vectLength;
              
                  // Calculate size of the user node (including the extended perimeter)
              // Ensure source.id exists and is valid
              const sourceId = source.id || '';
              const density = sourceId ? (connectionDensities.get(sourceId) || 0.5) : 0.5;
              const importanceFactor = Math.sqrt(source.importance || 1) * 0.3 + 0.7;
              const nodeSize = 1 + (density * 5 * importanceFactor);
              const extendedRadius = nodeSize * 2.5; // 2.5x the node size for the invisible perimeter
              
              // Set start position at the extended perimeter of the source node
              startPos = {
                x: sourcePos.x + dirVect.x * extendedRadius,
                y: sourcePos.y + dirVect.y * extendedRadius,
                z: sourcePos.z + dirVect.z * extendedRadius
              };
              
              // End position is unchanged (at the target node)
              endPos = targetPos;
            } else {
              // If direction vector is zero (nodes at same position), use original positions
              startPos = sourcePos;
              endPos = targetPos;
            }
          } else if (link.__targetIsUserNode) {
            // Calculate direction from target to source
            const dirVect = {
              x: sourcePos.x - targetPos.x,
              y: sourcePos.y - targetPos.y,
              z: sourcePos.z - targetPos.z
            };
            
            // Calculate direction length
            const vectLength = Math.sqrt(
              dirVect.x * dirVect.x + 
              dirVect.y * dirVect.y + 
              dirVect.z * dirVect.z
            );
            
            // Normalize direction vector
            if (vectLength > 0) {
              dirVect.x /= vectLength;
              dirVect.y /= vectLength;
              dirVect.z /= vectLength;
              
              // Calculate size of the user node (including the extended perimeter)
              // Ensure target.id exists and is valid
              const targetId = target.id || '';
              const density = targetId ? (connectionDensities.get(targetId) || 0.5) : 0.5;
              const importanceFactor = Math.sqrt(target.importance || 1) * 0.3 + 0.7;
              const nodeSize = 1 + (density * 5 * importanceFactor);
              const extendedRadius = nodeSize * 2.5; // 2.5x the node size for the invisible perimeter
              
              // Start position is unchanged (at the source node)
              startPos = sourcePos;
              
              // Set end position at the extended perimeter of the target node
              endPos = {
                x: targetPos.x + dirVect.x * extendedRadius,
                y: targetPos.y + dirVect.y * extendedRadius,
                z: targetPos.z + dirVect.z * extendedRadius
              };
            } else {
              // If direction vector is zero (nodes at same position), use original positions
              startPos = sourcePos;
              endPos = targetPos;
            }
          } else {
            // No custom positioning needed (should never reach here due to earlier check)
            return;
          }
          
          // Apply the calculated positions to the line geometry
          // Validate that line and its geometry exist
          if (!line || !line.geometry) return;
          
          try {
            // line is the THREE.Line object representing the link
            const linePos = line.geometry.getAttribute('position');
            
            // Ensure positions attribute exists and has the right number of points
            if (!linePos || linePos.count < 2) return;
            
            // Validate startPos and endPos values - ensure nothing is NaN
            if (startPos && endPos &&
                !isNaN(startPos.x) && !isNaN(startPos.y) && !isNaN(startPos.z) &&
                !isNaN(endPos.x) && !isNaN(endPos.y) && !isNaN(endPos.z)) {
              
              // Set start position (position index 0)
              linePos.setXYZ(0, startPos.x, startPos.y, startPos.z);
              
              // Set end position (position index 1)
              linePos.setXYZ(1, endPos.x, endPos.y, endPos.z);
              
              // Mark line positions as needing update
              linePos.needsUpdate = true;
            }
          } catch (err) {
            // Silently fail rather than crashing if any unexpected condition occurs
            Logger.error('Error updating edge positions:', err);
            return;
          }
          
          // Return true to indicate we've handled the position update
          return true;
        });
        
        // Update the graph data
        graphRef.current.graphData({
          nodes: preparedNodes,
          links: preparedEdges
        });
      }
    }
  }, [memoizedNodes, memoizedEdges, connectionDensities]);
  
  // REMOVED: Handle hover/selection state changes without recreating the graph
  // This effect was removed because it was causing re-renders when selection state changed
  // Instead, we now handle all updates to node appearance directly in the click/hover handlers
  // and through the global window.__nodeSelectionState variable
  
  // Effect to update colors when dark mode changes
  useEffect(() => {
    if (graphRef.current) {
      graphRef.current.backgroundColor(darkMode ? '#1a1a2e' : '#f8f9fa');
    }
  }, [darkMode]);
  
  // Add animation frame for user node effects
  useEffect(() => {
    if (!graphRef.current) return;
    
    // Set up animation frame
    let animationFrameId: number;
    const animate = () => {
      if (graphRef.current) {
        // Only trigger a subtle update - we don't need to modify anything 
        // since the user node animation relies on performance.now() in the nodeThreeObject function
        // This just ensures the scene is re-rendered each frame to show the animation
        const graph = graphRef.current;
        if (typeof graph.nodeRelSize === 'function') {
          // Get current size to retain it (this triggers re-render without changing values)
          const currentSize = graph.nodeRelSize();
          graph.nodeRelSize(currentSize);
        }
      }
      animationFrameId = requestAnimationFrame(animate);
    };
    
    // Start animation
    animationFrameId = requestAnimationFrame(animate);
    
    // Clean up
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);
  
  // Handle resize
  useEffect(() => {
    if (graphRef.current) {
      graphRef.current.width(width).height(height);
    }
  }, [width, height]);
  
  // Add method to zoom to fit graph or center on high-density user node
  const fitView = useCallback(() => {
    if (graphRef.current) {
      const graph = graphRef.current;
      
      // Find highest density user node
      const userNodes = memoizedNodes.filter(node => 
        node.user === true || (node.properties && node.properties.user === true)
      );
      
      if (userNodes.length > 0) {
        // Find the user node with the highest connection density
        let highestDensityNode = userNodes[0];
        let highestDensity = connectionDensities.get(highestDensityNode.id) || 0;
        
        userNodes.forEach(node => {
          const density = connectionDensities.get(node.id) || 0;
          if (density > highestDensity) {
            highestDensity = density;
            highestDensityNode = node;
          }
        });
        
        // Get the node's position from the current simulation
        const graphData = graph.graphData();
        const simulationNode = graphData.nodes.find((n: any) => n.id === highestDensityNode.id);
        
        if (simulationNode) {
          // Center on the high-density user node with a precise zoom level
          Logger.log(`Re-centering on user node: ${highestDensityNode.label} at 1111 units distance`);
          
          // Set camera position in one step for consistent zoom level
          try {
            // Position the camera 1111 units away on Z axis for precise zoom level
            graph.cameraPosition(
              { x: simulationNode.x, y: simulationNode.y, z: 1111 }, // Position at 1111 units for desired zoom
              { x: simulationNode.x, y: simulationNode.y, z: 0 },   // Target node
              1200  // Transition duration
            );
          } catch (err) {
            Logger.error("Error setting camera position:", err);
            // Fallback to zoomToFit as a last resort
            graph.zoomToFit(1000, 50);
          }
          
          return;
        }
      }
      
      // Fallback to standard zoom to fit if no user nodes found
      graph.zoomToFit(1000, 50);
    }
  }, [memoizedNodes, connectionDensities]);
  
  // Expose method to reset the camera
  useEffect(() => {
    window.__resetGraphView = fitView;
    
    return () => {
      delete window.__resetGraphView;
    };
  }, [fitView]);
  
  // Handle double-click on background to reset view
  useEffect(() => {
    const handleDblClick = (event: MouseEvent) => {
      // Check if click was on the background (canvas), not on a node
      if (event.target instanceof HTMLCanvasElement) {
        fitView();
      }
    };
    
    containerRef.current?.addEventListener('dblclick', handleDblClick);
    
    return () => {
      containerRef.current?.removeEventListener('dblclick', handleDblClick);
    };
  }, [fitView]);
  
  // State for toggling the keyboard help panel
  const [showKeyboardHelp, setShowKeyboardHelp] = useState(false);
  const [pulseHelpButton, setPulseHelpButton] = useState(true);
  
  // Toggle keyboard help panel
  const toggleKeyboardHelp = () => {
    setShowKeyboardHelp(prev => !prev);
    setPulseHelpButton(false); // Stop pulsing once user interacts with it
  };
  
  // Stop pulsing the help button after 15 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setPulseHelpButton(false);
    }, 15000);
    
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <GraphContainer ref={containerRef}>
      {/* Keyboard help toggle button */}
      <KeyboardHelpToggle 
        onClick={toggleKeyboardHelp} 
        title="Keyboard Controls (Press ? for help)"
        aria-label="Toggle keyboard controls help"
        $pulse={pulseHelpButton}
      >
        ⌨
      </KeyboardHelpToggle>
      
      {/* Keyboard help overlay */}
      <KeyboardHelpOverlay $visible={showKeyboardHelp}>
        <KeyboardHelpTitle>
          Keyboard Navigation
          <span style={{ cursor: 'pointer' }} onClick={() => setShowKeyboardHelp(false)}>×</span>
        </KeyboardHelpTitle>
        
        <KeyboardHelpGrid>
          <div><KeyboardKey>←</KeyboardKey> <KeyboardKey>→</KeyboardKey> <KeyboardKey>↑</KeyboardKey> <KeyboardKey>↓</KeyboardKey></div>
          <div>Navigate between connected nodes (with node selected)</div>
          
          <div><KeyboardKey>Shift</KeyboardKey>+<KeyboardKey>←→↑↓</KeyboardKey></div>
          <div>Pan camera in any direction</div>
          
          <div><KeyboardKey>Tab</KeyboardKey></div>
          <div>Move to next node in graph</div>
          
          <div><KeyboardKey>Shift</KeyboardKey>+<KeyboardKey>Tab</KeyboardKey></div>
          <div>Move to previous node in graph</div>
          
          <div><KeyboardKey>Home</KeyboardKey></div>
          <div>Focus on most important node</div>
          
          <div><KeyboardKey>End</KeyboardKey></div>
          <div>Focus on your user node</div>
          
          <div><KeyboardKey>U</KeyboardKey></div>
          <div>Jump to user node</div>
          
          <div><KeyboardKey>Space</KeyboardKey></div>
          <div>Expand connections for selected node</div>
          
          <div><KeyboardKey>+</KeyboardKey> <KeyboardKey>-</KeyboardKey></div>
          <div>Zoom in/out (preserves current view orientation)</div>
          
          <div><KeyboardKey>0</KeyboardKey></div>
          <div>Reset zoom to fit all nodes</div>
          
          <div><KeyboardKey>Esc</KeyboardKey></div>
          <div>Clear selection</div>
          
          <div><KeyboardKey>a</KeyboardKey>...<KeyboardKey>z</KeyboardKey></div>
          <div>Find nodes starting with letter</div>
          
          <div><KeyboardKey>?</KeyboardKey></div>
          <div>Show/hide this keyboard help panel</div>
        </KeyboardHelpGrid>
        
        {navigationStateRef.current.lastNavigationAction && (
          <LastAction>
            Last action: {navigationStateRef.current.lastNavigationAction}
          </LastAction>
        )}
      </KeyboardHelpOverlay>
    </GraphContainer>
  );
};

export default ForceGraphGL;