import { useState } from 'react';
import { Text } from '../../../../components/common/Typography';
import './MatchComparisonBar.scss';

type MatchComparisonBarProps = {
    score: number;
    allScores: number[];
    tooltipFormatter?: (score: number) => string;
    ranking_reasoning?: string;
};

const MatchComparisonBar = ({
    score,
    allScores,
    tooltipFormatter = (score) => `Match Score: ${(score * 100).toFixed(0)}%`,
    ranking_reasoning
  }: MatchComparisonBarProps) => {
    const [activeTooltip, setActiveTooltip] = useState(null);
    
    if (!allScores || allScores.length === 0) {
      return null;
    }
  
    const sortedScores = [...allScores].sort((a, b) => a - b);
    const maxScore = Math.max(...allScores);
    const minScore = Math.min(...allScores);
  
    // Create fixed number of dots (15) for consistency
    const createDots = () => {
      const dots = [];
      const numDots = 15;
      
      for (let i = 0; i < numDots; i++) {
        // Determine position percentage
        const position = (i / (numDots - 1)) * 100;
        
        // Find if any score falls close to this position
        const highlightedScore = score;
        const scorePosition = normalizeValue(highlightedScore);
        
        // Check if this dot position is close to the score position
        const isHighlighted = Math.abs(position - scorePosition) < (100 / (numDots * 2));
        
        dots.push(
          <div 
            key={i}
            className={`dot ${isHighlighted ? 'highlighted' : ''}`}
            onMouseEnter={() => isHighlighted && setActiveTooltip(i)}
            onMouseLeave={() => setActiveTooltip(null)}
          >
            {activeTooltip === i && (
              <div className="scoreTooltip">
                <div className="scoreValue">
                  {tooltipFormatter(score)}
                </div>
              </div>
            )}
          </div>
        );
      }
      
      return dots;
    };
    
    // Normalize position on bar
    const normalizeValue = (value: number) => {
      if (allScores.length === 1) {
        return value * 100;
      }
      
      if (maxScore === minScore) return 50; // Center it if all scores are the same
      return ((value - minScore) / (maxScore - minScore) * 100);
    };

    return (
        <div className="matchComparisonBarContainer">
            <div className="title">Match Comparison:</div>      
            <div className="slider-wrapper">
              <div className="slider-container">
                <div className="dot-grid">
                  {createDots()}
                </div>
              </div>
              <div className="labelContainer">
                <span className="label">Worst</span>
                <span className="label">Best</span>
              </div>
            </div>
            {ranking_reasoning && (
              <div className="reason-text">
                {ranking_reasoning}
              </div>
            )}
        </div>
    );
};

export default MatchComparisonBar;