import React from 'react';
import classNames from 'classnames';

import './Message.scss';

type MessageProps = {
    name: string;
    content: any;
    time?: number;
}

export const Message = ({ name = '', content, time }: MessageProps) => (
    <div
        className={classNames({
            'adapter-chat-message': true,
            'fade-in': true,
            user: name !== 'Adapter'
        })}
    >
        <div className={`avatar ${name === 'Adapter' ? 'adapter' : ''}`}>
            {name === 'Adapter' && (
                <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="38.764" height="38.764" rx="19.382" fill="#0C0A09" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M19.3819 7.76392C20.5359 7.76392 21.6022 8.37955 22.1792 9.37892L29.6397 22.3009C30.2167 23.3003 30.2167 24.5316 29.6397 25.5309C29.0627 26.5303 27.9964 27.1459 26.8425 27.1459H11.9214C10.7674 27.1459 9.70113 26.5303 9.12414 25.5309C8.54716 24.5316 8.54716 23.3003 9.12414 22.3009L16.5847 9.37892C17.1617 8.37955 18.228 7.76392 19.3819 7.76392ZM20.997 10.9939C20.997 11.8859 20.2739 12.6089 19.3819 12.6089C18.49 12.6089 17.7669 11.8859 17.7669 10.9939C17.7669 10.102 18.49 9.37891 19.3819 9.37891C20.2739 9.37891 20.997 10.102 20.997 10.9939ZM13.5364 23.9163C13.5364 24.8084 12.8133 25.5317 11.9214 25.5317C11.0295 25.5317 10.3064 24.8084 10.3064 23.9163C10.3064 23.0242 11.0295 22.3009 11.9214 22.3009C12.8133 22.3009 13.5364 23.0242 13.5364 23.9163ZM28.4578 23.9159C28.4578 24.8079 27.7348 25.5309 26.8428 25.5309C25.9509 25.5309 25.2278 24.8079 25.2278 23.9159C25.2278 23.024 25.9509 22.3009 26.8428 22.3009C27.7348 22.3009 28.4578 23.024 28.4578 23.9159Z" fill="#FCFCFA" />
                </svg>

            )}
            {name !== 'Adapter' && (
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="20" fill="#D2D2D0" />
                    <path d="M20 14C21.1 14 22 14.9 22 16C22 17.1 21.1 18 20 18C18.9 18 18 17.1 18 16C18 14.9 18.9 14 20 14ZM20 24C22.7 24 25.8 25.29 26 26H14C14.23 25.28 17.31 24 20 24ZM20 12C17.79 12 16 13.79 16 16C16 18.21 17.79 20 20 20C22.21 20 24 18.21 24 16C24 13.79 22.21 12 20 12ZM20 22C17.33 22 12 23.34 12 26V28H28V26C28 23.34 22.67 22 20 22Z" fill="#0C0A09" />
                </svg>

            )}
        </div>

        <div className="message">
            <div className="content">{content}</div>
        </div>

        {/* leave empty to fix alignment issues */}
        <div className="avatar-spacer" />
    </div>
);