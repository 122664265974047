import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

interface ExpandableSearchBarProps {
  onSearch: (query: string) => void;
  onAdvancedSearch?: (query: string) => void;
  darkMode: boolean;
}

const expandAnimation = keyframes`
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: 220px;
    opacity: 1;
  }
`;

const collapseAnimation = keyframes`
  from {
    width: 220px;
    opacity: 1;
  }
  to {
    width: 0;
    opacity: 0;
  }
`;

// Dynamic width based on content length
const calculateWidth = (textLength: number, minWidth: number, maxWidth: number, charsPerPixel: number) => {
  if (textLength === 0) return minWidth;
  // Calculate width based on content length plus some padding for cursors and buttons
  const calculatedWidth = Math.min(maxWidth, Math.max(minWidth, textLength * charsPerPixel + 60));
  return calculatedWidth;
};

const SearchButton = styled.button<{ $darkMode: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  transition: background-color 0.2s ease, color 0.2s ease;
  
  &:hover {
    background-color: ${props => props.$darkMode ? 'rgba(51, 65, 85, 0.5)' : 'rgba(241, 245, 249, 0.8)'};
    color: ${props => props.$darkMode ? '#E2E8F0' : '#334155'};
  }
  
  &:focus {
    outline: none;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SearchContainer = styled.div<{ 
  $expanded: boolean; 
  $darkMode: boolean; 
  $width: number;
  $animating: boolean;
}>`
  position: fixed;
  left: 74px; /* Position right outside the collapsed sidebar */
  /* Match the original position */
  top: 90px; /* Fixed position aligned with the search icon position */
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: ${props => props.$expanded ? `${props.$width}px` : '0'};
  transition: ${props => props.$animating ? 'none' : 'width 0.3s ease, opacity 0.3s ease'};
  animation: ${props => {
    if (props.$animating) {
      return props.$expanded ? expandAnimation : collapseAnimation;
    }
    return 'none';
  }} 0.3s ease forwards;
  opacity: ${props => props.$expanded ? 1 : 0};
  background-color: ${props => props.$darkMode ? '#1E293B' : '#FFFFFF'};
  border-radius: 8px;
  box-shadow: ${props => props.$expanded ? '0 4px 12px rgba(0, 0, 0, 0.1)' : 'none'};
  border: 1px solid ${props => props.$darkMode ? '#334155' : '#E2E8F0'};
  z-index: 10; /* Ensure it appears above other elements */
  
  /* Prevent any blue focus borders */
  * {
    outline: none !important;
  }
`;

const SearchInput = styled.input<{ $darkMode: boolean }>`
  width: 100%;
  height: 100%;
  padding: 0 12px;
  border: none;
  background: transparent;
  color: ${props => props.$darkMode ? '#F1F5F9' : '#0F172A'};
  font-size: 14px;
  
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  
  &::placeholder {
    color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-right: 8px;
  height: 100%;
  background: inherit;
`;

const ClearButton = styled.button<{ $darkMode: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  
  &:hover {
    color: ${props => props.$darkMode ? '#F1F5F9' : '#0F172A'};
  }
  
  &:focus {
    outline: none;
  }
`;

const BrainButton = styled.button<{ $darkMode: boolean; $active: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.$active 
    ? (props.$darkMode ? '#60A5FA' : '#3B82F6') 
    : (props.$darkMode ? '#94A3B8' : '#64748B')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: all 0.2s ease;
  outline: none !important;
  box-shadow: none !important;
  
  &:hover {
    color: ${props => props.$active 
      ? (props.$darkMode ? '#93C5FD' : '#60A5FA') 
      : (props.$darkMode ? '#F1F5F9' : '#0F172A')};
    transform: scale(1.1);
  }
  
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
  
  &::before, &::after {
    display: none;
  }
`;

export const ExpandableSearchBar: React.FC<ExpandableSearchBarProps> = ({ onSearch, onAdvancedSearch, darkMode }) => {
  const [expanded, setExpanded] = useState(false);
  const [isAdvancedMode, setIsAdvancedMode] = useState(true);
  const [query, setQuery] = useState('');
  const [width, setWidth] = useState(220); // Default width
  const [isAnimating, setIsAnimating] = useState(true); // Initial animation flag
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const debounceTimerRef = useRef<number | null>(null);
  
  // Constants for width calculation
  const MIN_WIDTH = 180;  // Minimum width when expanded
  const MAX_WIDTH = 650;  // Maximum width 
  const CHARS_PER_PIXEL = 8; // Approximately how many pixels per character
  
  // Focus the input when expanded and handle initial animation
  useEffect(() => {
    if (expanded) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
      
      // After initial animation completes, switch to smooth transitions for dynamic width
      const animationTimeout = setTimeout(() => {
        setIsAnimating(false);
      }, 300); // Match animation duration
      
      return () => clearTimeout(animationTimeout);
    } else {
      // When collapsing, use animation
      setIsAnimating(true);
    }
  }, [expanded]);
  
  // Update width based on query length
  useEffect(() => {
    if (expanded && !isAnimating) {
      const newWidth = calculateWidth(query.length, MIN_WIDTH, MAX_WIDTH, CHARS_PER_PIXEL);
      setWidth(newWidth);
    }
  }, [query, expanded, isAnimating]);
  
  // Handle click outside to collapse
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setExpanded(false);
      }
    };
    
    if (expanded) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [expanded]);
  
  // Cleanup any pending debounce timer on unmount
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current !== null) {
        window.clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);
  
  const toggleSearch = () => {
    if (expanded) {
      // If already expanded, just collapse without clearing search
      // Only clear the pending debounce timer
      if (debounceTimerRef.current !== null) {
        window.clearTimeout(debounceTimerRef.current);
        debounceTimerRef.current = null;
      }
      
      // Only clear the search and trigger a search update if there's an active query
      if (query) {
        setQuery('');
        // Use a short timeout to avoid synchronous re-renders
        setTimeout(() => {
          onSearch('');
        }, 10);
      }
    }
    setExpanded(!expanded);
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    
    // Clear any existing timer
    if (debounceTimerRef.current !== null) {
      window.clearTimeout(debounceTimerRef.current);
    }
    
    // Set a new timer to trigger search after 1 second of inactivity
    debounceTimerRef.current = window.setTimeout(() => {
      // Use advanced search if mode is active and handler is available
      if (isAdvancedMode && onAdvancedSearch) {
        onAdvancedSearch(value);
      } else {
        onSearch(value);
      }
      debounceTimerRef.current = null;
    }, 1000);
  };
  
  const handleClear = () => {
    setQuery('');
    
    // Clear any existing timer
    if (debounceTimerRef.current !== null) {
      window.clearTimeout(debounceTimerRef.current);
      debounceTimerRef.current = null;
    }
    
    // Immediately trigger empty search
    onSearch('');
    
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  
  const toggleAdvancedMode = () => {
    const newMode = !isAdvancedMode;
    setIsAdvancedMode(newMode);
    
    // Apply current search with new mode if there's a query
    if (query && onAdvancedSearch && newMode) {
      onAdvancedSearch(query);
    } else if (query && !newMode) {
      onSearch(query);
    }
  };
  
  return (
    <Container ref={containerRef}>
      <SearchButton 
        onClick={toggleSearch} 
        $darkMode={darkMode}
        aria-label={expanded ? "Close search" : "Open search"}
        title={expanded ? "Close search" : "Open search"}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
      </SearchButton>
      
      {(expanded || query) && (
        <SearchContainer 
          $expanded={expanded} 
          $darkMode={darkMode} 
          $width={width} 
          $animating={isAnimating}
        >
          <SearchInput
            ref={inputRef}
            type="text"
            placeholder={isAdvancedMode ? "Adaptive search..." : "Search..."}
            value={query}
            onChange={handleChange}
            $darkMode={darkMode}
          />
          <ActionButtonsContainer>
            {onAdvancedSearch && (
              <BrainButton 
                onClick={toggleAdvancedMode}
                aria-label={isAdvancedMode ? "Disable Adaptive search" : "Enable Adaptive search"}
                title={isAdvancedMode ? "Disable Adaptive search" : "Enable Adaptive search"}
                $darkMode={darkMode}
                $active={isAdvancedMode}
                style={{ outline: 'none', border: 'none' }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z"></path>
                </svg>
              </BrainButton>
            )}
            {query && (
              <ClearButton
                onClick={handleClear}
                aria-label="Clear search"
                title="Clear search"
                $darkMode={darkMode}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </ClearButton>
            )}
          </ActionButtonsContainer>
        </SearchContainer>
      )}
    </Container>
  );
};