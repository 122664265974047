import React from 'react';

const Link = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path 
            d="M10.5896 15.3032L9.41104 16.4817C7.78386 18.1089 5.14567 18.1089 3.51848 16.4817C1.8913 14.8546 1.8913 12.2164 3.51848 10.5892L4.697 9.41068M15.3036 10.5892L16.4821 9.41067C18.1093 7.78349 18.1093 5.1453 16.4821 3.51812C14.8549 1.89093 12.2167 1.89093 10.5896 3.51812L9.41104 4.69663M7.08363 12.9166L12.917 7.08325" 
            stroke={color} 
            strokeWidth="1.5" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
    </svg>
);

export default Link;