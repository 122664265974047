// /frontend/src/context/FullScreenContext.tsx
import React, { createContext, useContext, useState } from 'react';

interface FullScreenContextType {
  isFullScreen: boolean;
  toggleFullScreen: () => void;
}

const FullScreenContext = createContext<FullScreenContextType>({
  isFullScreen: false,
  toggleFullScreen: () => {},
});

export const FullScreenProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  
  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);

  return (
    <FullScreenContext.Provider value={{ isFullScreen, toggleFullScreen }}>
      {children}
    </FullScreenContext.Provider>
  );
};

export const useFullScreen = () => useContext(FullScreenContext);