import React from 'react';

import './Pill.scss';

const Pill = ({ children, highlighted = false, ...rest }) => {
    return (
        <span
            className={`adapter-pill ${highlighted && 'highlighted'}`} 
            {...rest}
        >{children}</span>
    );
};

export default Pill;