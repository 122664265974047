/**
 * @fileoverview Bridge for accessing geolocation services in a hybrid mobile/web application.
 * Provides a unified interface for getting location data from both native iOS (via WebKit) 
 * and browser environments.
 */

import { 
    buildCallback,
    MessageHandlerType, 
    NativePromiseError 
} from "./NativePromiseBuilder";

/**
 * Bridge class that provides access to device location services
 * in both native (iOS WebKit) and web browser environments.
 * 
 * This class exposes a common API that works transparently across platforms,
 * delegating to the appropriate implementation (native or web) based on the runtime environment.
 */
class LocationServiceBridge {
    /**
     * Gets the current geographic position of the device.
     * 
     * Uses native implementation via WebKit messaging bridge when available,
     * otherwise falls back to the browser's Geolocation API.
     * 
     * @returns {Promise<GeolocationPosition>} A promise that resolves with the device's position
     * @throws {LocationServiceError} If retrieving the position fails
     */
    public async getCurrentPosition(): Promise<GeolocationPosition> {
        if (window.webkit?.messageHandlers?.location) {
            return await this.callNative("get_current_position") as Promise<GeolocationPosition>;
        } else {
            if ("geolocation" in navigator) {
                return new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(
                        (position) => resolve(position),
                        (error) => reject(new LocationServiceError(error.message))
                    );
                })
            } else {
                // Handle the case where geolocation isn't available
                throw new LocationServiceError('Geolocation is not available in this browser');
            }
        }
    }

    /**
     * Calls a native method via the WebKit messaging bridge.
     * 
     * This method facilitates communication with native iOS code by setting up a temporary
     * callback function on the window object and passing its name to the native side,
     * which will later call it with the result.
     * 
     * @param {string} operation - The operation to perform on the native side
     * @returns {Promise<unknown>} A promise that resolves with the result from the native call
     * @throws {LocationServiceError} If the native handler is not available or if the call fails
     * @private
     */
    private async callNative(operation: string): Promise<unknown> {
        if (!window.webkit?.messageHandlers?.location) {
            throw new LocationServiceError('Native location handler not available');
        }

        return new Promise((resolve, reject) => {
            const callbackName = buildCallback(
                MessageHandlerType.Location,
                LocationServiceError,
                resolve,
                reject
            );

            try {
                window.webkit.messageHandlers.location.postMessage({
                    operation,
                    callback: callbackName
                });
            } catch (error) {
                delete (window as any)[callbackName]; // Clean up
                reject(new LocationServiceError(`Failed to send message to native: ${error}`));
            }
        });
    }
}

/**
 * Custom error class for location service related errors.
 * 
 * This provides a specific error type that can be caught and handled
 * separately from other errors in the application.
 */
export class LocationServiceError extends NativePromiseError {}

// Export a singleton instance of the bridge
const locationServiceBridge = new LocationServiceBridge();
export default locationServiceBridge;