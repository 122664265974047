import React, { useState, useEffect, useRef } from "react";
import { FiX, FiCalendar, FiUsers } from "react-icons/fi";
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalTitle,
  CloseButton,
  CategoryNav,
  CategoryButton,
  CategoryCount,
  SuggestionsGrid,
  EnhancedCard,
  CardHeader,
  CategoryLabel,
  EventDate,
  CardBody,
  CardTitle,
  CardDescription,
  QueryText,
  RelatedInfo,
  PersonTag,
  LocationTag,
  FilterTag,
  CardFooter,
  Filters,
  ApplyButton,
  LoadingPlaceholder,
  LoadingSpinner,
  EmptyState,
  getCategoryIcon,
  getCategoryColor,
  formatEventDate,
  getCategoryTextColor,
  getLocationIcon,
  SuggestionsFloatingButton
} from "./EnhancedSuggestedQueriesPanel.styles";

// Re-export the SuggestionsFloatingButton for use in other components
export { SuggestionsFloatingButton } from "./EnhancedSuggestedQueriesPanel.styles";

interface LocationInfo {
  name: string;
  type: string;
  nodeId?: string;
}

interface QuerySuggestion {
  title: string;
  description: string;
  query: string;
  filters: {
    nodeTypes?: string[];
    edgeTypes?: string[];
    properties?: Record<string, any>;
  };
  importance: number;
  _dataStatus?: 'loading' | 'waiting_for_data';
  category?: 'meeting' | 'people' | 'schedule' | 'conflict' | 'insight' | 'travel';
  eventDate?: string;
  relatedPeople?: string[];
  relatedEvents?: string[];
  locations?: LocationInfo[];
  icon?: string;
}

interface EnhancedSuggestedQueriesPanelProps {
  suggestions: QuerySuggestion[];
  darkMode: boolean;
  onApplyFilter: (filters: any) => void;
  onSearch: (query: string) => void;
  isLoading: boolean;
  isModalView?: boolean;
  onClose?: () => void;
}

export const EnhancedSuggestedQueriesPanel: React.FC<EnhancedSuggestedQueriesPanelProps> = ({
  suggestions,
  darkMode,
  onApplyFilter,
  onSearch,
  isLoading,
  isModalView = false,
  onClose
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [categoryCounts, setCategoryCounts] = useState<Record<string, number>>({});
  const [hoveredQueries, setHoveredQueries] = useState<Record<string, boolean>>({});
  const [isAnyQueryExpanded, setIsAnyQueryExpanded] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  
  // Collapse expanded queries - used for click-outside handling
  const handleCloseExpanded = () => {
    if (isAnyQueryExpanded) {
      setHoveredQueries({});
      setIsAnyQueryExpanded(false);
    }
  };
  
  // Count suggestions by category 
  useEffect(() => {
    if (!suggestions) return;
    
    // Filter for valid suggestions inside the effect to avoid recreation on each render
    const validSuggestionsList = Array.isArray(suggestions)
      ? suggestions.filter((suggestion) => !suggestion?._dataStatus)
      : [];
    
    const counts: Record<string, number> = {};
    
    validSuggestionsList.forEach(suggestion => {
      const category = suggestion.category || 'insight';
      counts[category] = (counts[category] || 0) + 1;
    });
    
    // Compare with previous state to avoid unnecessary updates
    setCategoryCounts(prevCounts => {
      // Check if counts are different
      const hasChanged = Object.keys(counts).length !== Object.keys(prevCounts).length || 
        Object.keys(counts).some(key => counts[key] !== prevCounts[key]);
        
      return hasChanged ? counts : prevCounts;
    });
  }, [suggestions]);
  
  // Handle click outside to close modal
  useEffect(() => {
    if (!isModalView || !onClose) return;
    
    // Define the handler inside useEffect to avoid recreating it on each render
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalView, onClose]);
  
  // Add escape key handler to close expanded queries
  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isAnyQueryExpanded) {
        handleCloseExpanded();
      }
    };
    
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isAnyQueryExpanded]);
  
  // Filter for valid suggestions
  const validSuggestions = Array.isArray(suggestions)
    ? suggestions.filter((suggestion) => !suggestion?._dataStatus)
    : [];
  
  // Filter suggestions by category
  const filteredSuggestions = selectedCategory
    ? validSuggestions.filter(s => s.category === selectedCategory)
    : validSuggestions;
  
  // Handle suggestion click
  const handleSuggestionClick = (suggestion: QuerySuggestion) => {
    onApplyFilter({
      nodeTypes: suggestion.filters?.nodeTypes || [],
      edgeTypes: suggestion.filters?.edgeTypes || [],
      propertyFilters: suggestion.filters?.properties || {},
    });
    
    if (suggestion.query) {
      onSearch(suggestion.query);
    }
    
    // Close modal if in modal view
    if (isModalView && onClose) {
      onClose();
    }
  };
  
  // Display loading state
  if (isLoading) {
    return (
      <LoadingPlaceholder $darkMode={darkMode}>
        <LoadingSpinner />
        <p>Analyzing your calendar and travel plans to generate suggestions...</p>
      </LoadingPlaceholder>
    );
  }
  
  // Calculate date 30 days from today for the header
  const today = new Date();
  const futureDate = new Date();
  futureDate.setDate(today.getDate() + 30);
  
  // Format dates for display
  const formatDate = (date: Date) => {
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  };
  
  // Check if we're waiting for data to load
  const isWaitingForData =
    Array.isArray(suggestions) &&
    suggestions.some((item) => item?._dataStatus === "loading");
  
  // If we have no suggestions at all
  if (!isWaitingForData && (!validSuggestions || validSuggestions.length === 0)) {
    return (
      <EmptyState $darkMode={darkMode}>
        <FiCalendar />
        <h3>No suggestions available</h3>
        <p>There are no upcoming events or travel plans for the next 30 days.</p>
      </EmptyState>
    );
  }
  
  const content = (
    <>
      {/* Categories navigation */}
      <CategoryNav>
        <CategoryButton 
          $darkMode={darkMode} 
          $active={selectedCategory === null}
          onClick={() => setSelectedCategory(null)}
        >
          All 
          <CategoryCount $darkMode={darkMode}>
            {validSuggestions.length}
          </CategoryCount>
        </CategoryButton>
        
        {Object.entries(categoryCounts).map(([category, count]) => (
          <CategoryButton 
            key={category}
            $darkMode={darkMode} 
            $active={selectedCategory === category}
            $category={category}
            onClick={() => setSelectedCategory(category)}
          >
            {getCategoryIcon(category)} {category.charAt(0).toUpperCase() + category.slice(1)}
            <CategoryCount $darkMode={darkMode} $category={category}>
              {count}
            </CategoryCount>
          </CategoryButton>
        ))}
      </CategoryNav>
      
      {/* Suggestions grid */}
      <SuggestionsGrid>
        {filteredSuggestions.map((suggestion, index) => (
          <EnhancedCard 
            key={index}
            $darkMode={darkMode}
            $importance={suggestion.importance || 0.5}
            $category={suggestion.category}
            className={hoveredQueries[`query-${index}`] ? 'expanded' : ''}
            style={{
              transition: 'all 0.15s ease-out',
            }}
          >
            <CardHeader $darkMode={darkMode} $category={suggestion.category}>
              <CategoryLabel $darkMode={darkMode} $category={suggestion.category}>
                {getCategoryIcon(suggestion.category)}
                {suggestion.category || 'Insight'}
              </CategoryLabel>
              
              {suggestion.eventDate && (
                <EventDate $darkMode={darkMode} $category={suggestion.category} title={suggestion.eventDate}>
                  <FiCalendar />
                 {suggestion.eventDate}
                </EventDate>
              )}
              
              {/* Removed travel badges for simplicity */}
            </CardHeader>
            
            <CardBody>
              <CardTitle $darkMode={darkMode} $category={suggestion.category}>
                {suggestion.title || "Untitled Suggestion"}
              </CardTitle>
              
              <CardDescription $darkMode={darkMode} $category={suggestion.category}>
                {suggestion.description || "No description provided"}
              </CardDescription>
              
              <div 
                style={{ 
                  position: 'relative', 
                  marginBottom: '10px', 
                  height: '42px', /* Fixed height for consistency */
                  perspective: '1000px',
                  willChange: 'transform',
                  overflow: 'visible',
                  transformStyle: 'preserve-3d'
                }}
                className="query-container"
              >
                <QueryText 
                  $darkMode={darkMode}
                  $category={suggestion.category}
                  $isExpanded={hoveredQueries[`query-${index}`] || false}
                  onClick={(e) => {
                    // Prevent any click events from bubbling up
                    e.stopPropagation();
                  }}
                  onMouseEnter={() => {
                    // Use a stable way to update state and avoid re-renders
                    const queryKey = `query-${index}`;
                    if (!hoveredQueries[queryKey]) {
                      // Use a single state update to improve responsiveness
                      setHoveredQueries(prev => ({
                        ...prev,
                        [queryKey]: true
                      }));
                      setIsAnyQueryExpanded(true);
                    }
                  }}
                  onMouseLeave={() => {
                    const queryKey = `query-${index}`;
                    if (hoveredQueries[queryKey]) {
                      // Use requestAnimationFrame for smoother state transitions
                      requestAnimationFrame(() => {
                        setHoveredQueries(prev => ({
                          ...prev,
                          [queryKey]: false
                        }));
                        
                        // Check if any other query is still expanded
                        const stillExpanded = Object.values(hoveredQueries).some(
                          (value, i) => i !== parseInt(index.toString()) && value
                        );
                        if (!stillExpanded) {
                          setIsAnyQueryExpanded(false);
                        }
                      });
                    }
                  }}
                >
                  <span 
                    style={{ 
                      display: 'inline-block',
                      opacity: hoveredQueries[`query-${index}`] ? 1 : 0.92,
                      transition: 'opacity 0.1s ease-out',
                      transitionDelay: hoveredQueries[`query-${index}`] ? '0s' : '0s'
                    }}
                  >
                    {suggestion.query || ""}
                  </span>
                </QueryText>
              </div>
              
              <RelatedInfo>
                {suggestion.relatedPeople && suggestion.relatedPeople.length > 0 && (
                  suggestion.relatedPeople.slice(0, 2).map((person, i) => (
                    <PersonTag key={`person-${i}`} $darkMode={darkMode} $category={suggestion.category}>
                      <FiUsers />
                      {person}
                    </PersonTag>
                  ))
                )}
                
                {suggestion.locations && suggestion.locations.length > 0 && (
                  suggestion.locations
                    .filter(location => location && location.name) // Ensure location and location.name exist
                    .slice(0, 2)
                    .map((location, i) => {
                      return (
                        <LocationTag 
                          key={`location-${i}`} 
                          $darkMode={darkMode} 
                          $entityType={location.type}
                          $category={suggestion.category}
                        >
                          {getLocationIcon(location.name, location.type)}
                          {location.name}
                        </LocationTag>
                      );
                    })
                )}
                
                {/* Show "more" tag if necessary */}
                {((suggestion.relatedPeople && suggestion.relatedPeople.length > 2) || 
                 (suggestion.locations && suggestion.locations.length > 2)) && (
                  <PersonTag $darkMode={darkMode} $category={suggestion.category}>
                    +{((suggestion.relatedPeople?.length || 0) - 2) + 
                      ((suggestion.locations?.length || 0) - 2)} more
                  </PersonTag>
                )}
              </RelatedInfo>
            </CardBody>
            
            <CardFooter $darkMode={darkMode} $category={suggestion.category}>
              <Filters>
                {Array.isArray(suggestion.filters?.nodeTypes) && suggestion.filters.nodeTypes.length > 0 && (
                  <FilterTag $darkMode={darkMode} $type="node" $category={suggestion.category}>
                    {suggestion.filters.nodeTypes.length} node {suggestion.filters.nodeTypes.length === 1 ? 'type' : 'types'}
                  </FilterTag>
                )}
                
                {Array.isArray(suggestion.filters?.edgeTypes) && suggestion.filters.edgeTypes.length > 0 && (
                  <FilterTag $darkMode={darkMode} $type="edge" $category={suggestion.category}>
                    {suggestion.filters.edgeTypes.length} edge {suggestion.filters.edgeTypes.length === 1 ? 'type' : 'types'}
                  </FilterTag>
                )}
                
                {/* If no filters are present, show a placeholder */}
                {(!Array.isArray(suggestion.filters?.nodeTypes) || suggestion.filters?.nodeTypes.length === 0) && 
                 (!Array.isArray(suggestion.filters?.edgeTypes) || suggestion.filters?.edgeTypes.length === 0) && (
                  <span style={{ fontSize: '13px', color: darkMode ? '#777777' : '#999999', fontWeight: 500 }}>
                    No filters
                  </span>
                )}
              </Filters>
              
              <ApplyButton 
                $darkMode={darkMode}
                $category={suggestion.category}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                Ask Adapter
              </ApplyButton>
            </CardFooter>
          </EnhancedCard>
        ))}
      </SuggestionsGrid>
    </>
  );
  
  // Render as modal or regular component
  return (
    <>
      
      {isModalView ? (
        <ModalOverlay $darkMode={darkMode}>
          <ModalContent $darkMode={darkMode} ref={modalRef}>
            <ModalHeader>
              <ModalTitle $darkMode={darkMode}>
                <FiCalendar />
                Upcoming Events & Travel (Apr 3 - May 3)
              </ModalTitle>
              <CloseButton $darkMode={darkMode} onClick={onClose}>
                <FiX />
              </CloseButton>
            </ModalHeader>
            {content}
          </ModalContent>
        </ModalOverlay>
      ) : (
        <div>
          <h3>Upcoming Events & Travel ({formatDate(today)} - {formatDate(futureDate)})</h3>
          {content}
        </div>
      )}
    </>
  );
};
