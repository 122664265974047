import config from './config';

// Import the non-TypeScript PostHog initialization
import './posthog-init.js';

// Only handle the configuration part in TypeScript
if (typeof window !== 'undefined' && window.posthog) {
    const posthogKey = window.REACT_APP_PUBLIC_POSTHOG_KEY ?? config.posthog.key;
    const posthogHost = window.REACT_APP_PUBLIC_POSTHOG_HOST ?? config.posthog.host;
    const options = {
        api_host: posthogHost,
    }
    window.posthog.init(posthogKey, options);
}

export {};