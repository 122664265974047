// Spatial data interface
export interface SpatialData {
  coordinates?: {
    latitude: number;
    longitude: number;
  };
  address?: string;
  location_type?: string;
  regions?: string[];
  visits?: string[];
  confidence?: number;
}

// Node data interface
export interface NodeData {
  id: string;
  type: string;
  label: string;
  properties: Record<string, any>;
  importance?: number;
  centrality?: {
    degree?: number;
    eigenvector?: number;
  };
  community?: number;
  spatial?: SpatialData;
  x?: number;
  y?: number;
  user?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  important?: boolean;
  size?: number;
  fx?: number | null;
  fy?: number | null;
  vx?: number;
  vy?: number;
}

// Edge data interface
export interface EdgeData {
  id?: string;
  source: string;
  target: string;
  type: string;
  properties: Record<string, any>;
  weight?: number;
}

// AI insight interface
export interface AIInsight {
  id: string;
  type: 'pattern' | 'anomaly' | 'suggestion' | 'summary';
  title: string;
  description: string;
  relatedNodes: string[];
  confidence: number;
  timestamp: Date;
}

// Query response interface
export interface QueryResponse {
  answer: string;
  highlightedNodes?: string[];
  highlightedEdges?: string[];
  humanSummary?: string;
}

// Community interface
export interface Community {
  id: number;
  name: string;
  nodes: string[];
  properties: Record<string, any>;
}

// Community insight interface
export interface CommunityInsight extends AIInsight {
  communityId: number;
}

// Community suggestion interface
export interface CommunitySuggestion {
  title: string;
  description: string;
  query: string;
  filters: {
    nodeTypes: string[];
    edgeTypes: string[];
    properties: Record<string, any>;
  };
  importance: number;
  category?: 'meeting' | 'people' | 'schedule' | 'conflict' | 'insight' | 'travel';
  eventDate?: string;
  relatedPeople?: string[];
  locations?: Array<{
    name: string;
    type: string;
    nodeId?: string;
  }>;
}

// Community details interface
export interface CommunityDetails {
  names: Record<string, string>;
  nodeCounts: Record<string, number>;
}

// Graph viewport interface
export interface GraphViewport {
  scale: number;
  translateX: number;
  translateY: number;
  width?: number;
  height?: number;
}

// Graph data container
export interface GraphData {
  nodes: NodeData[];
  edges: EdgeData[];
}

// Filter recommendation interface
export interface FilterRecommendation {
  nodeTypes?: string[];
  edgeTypes?: string[];
  nodeIds?: string[];
  searchTerms?: string[];
  searchType?: 'exact' | 'and' | 'or';
  contextDepth?: number;
}

// Enhanced query response interface
export interface EnhancedQueryResponse extends Omit<QueryResponse, 'highlightedNodes' | 'highlightedEdges'> {
  highlightedNodes?: string[];
  highlightedEdges?: string[];
  recommendedFilters?: FilterRecommendation;
}

// Search history entry interface
export interface SearchHistoryEntry {
  id: string;
  timestamp: number;
  query: string;
  humanSummary: string;
  importantNodesById: string[];
  // We no longer store the full node and edge objects, just the important node IDs
  // The actual nodes and edges will be reconstructed at runtime when needed
  filteredNodes?: NodeData[]; // Optional for backward compatibility
  filteredEdges?: EdgeData[]; // Optional for backward compatibility
  communityCount?: number;    // Optional stats
}

// PrefilterVoiceQueryResult interface (original verbose format)
export interface PrefilterVoiceQueryResult {
  refinedQuery: string;
  relevantEntityTypes: string[];
  focusPersonIds?: string[];
  searchTerms?: Record<string, {
    exactMatch: string[];
    fuzzyMatch: string[];
    propertyMatch: Record<string, string[]>;
    expansionDepth?: number;
  }>;
  dateStart: string;
  dateEnd: string;
  baseNodes: string[];
  searchPatterns?: Array<{
    pattern: string;
    startNodeIds: string[];
    targetTypes: string[];
    description?: string;
  }>;
  searchStrategyHints?: string[];
  explanation?: string;
}

// CompactPrefilterResult interface (token-optimized format for LLM generation)
export interface CompactPrefilterResult {
  q: string;                         // refinedQuery
  e: string[];                       // relevantEntityTypes (entity codes)
  p?: number[];                      // focusPersonIds (just the numeric part)
  b?: number[];                      // baseNodes (just the numeric part)
  t?: Record<string, {               // searchTerms by entity code
    x?: string[];                    // exactMatch
    f?: string[];                    // fuzzyMatch
    m?: Record<string, string[]>;    // propertyMatch
    d?: number;                      // expansionDepth
  }>;
  ds?: string;                       // dateStart
  de?: string;                       // dateEnd
  s?: Array<{                        // searchPatterns
    p: string;                       // pattern code
    i?: number[];                    // startNodeIds (numeric part)
    tt?: string[];                   // targetTypes (entity codes)
  }>;
  h?: string[];                      // searchStrategyHints
  x?: string;                        // explanation
}

// Mapping constants for compact entity type codes
export const ENTITY_TYPE_MAP: Record<string, string> = {
  'person': 'p',
  'calendar_event': 'c',
  'memory': 'm',
  'email': 'e',
  'flight': 'f',
  'hotel_stay': 'h',
  'dining': 'd',
  'event': 'v',
  'document': 'o',
  'task': 'k',
  'project': 'j',
  'location': 'l',
  'organization': 'g',
  'message': 's',
  'note': 'n',
  'file': 'i',
  'meeting': 't',
  'conversation': 'r',
  'contact': 'a',
  'activity': 'y',
  'place': 'z'
};

// Reverse mapping for decoding
export const ENTITY_TYPE_REVERSE_MAP: Record<string, string> = Object.entries(ENTITY_TYPE_MAP)
  .reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

// Mapping for property keys
export const PROPERTY_KEY_MAP: Record<string, string> = {
  'name': 'n',
  'org': 'o',
  'email': 'e',
  'company': 'c',
  'location': 'l',
  'address': 'a',
  'title': 't',
  'content': 'z',
  'attendees': 'at',
  'participants': 'pa',
  'involved_people': 'ip',
  'destination': 'd',
  'city': 'cy',
  'with': 'w',
  'subject': 'su',
  'description': 'de',
  'notes': 'no',
  'text': 'tx',
  'summary': 'sm',
  'topic': 'tp',
  'sender': 'se',
  'from': 'fr',
  'recipient': 'rc',
  'to': 'to',
  'cc': 'cc',
  'bcc': 'bc',
  'sender_email': 'se',
  'sender_name': 'sn',
  'recipient_emails': 're',
  'recipient_names': 'rn',
  'recipients': 'rs',
  'full_name': 'fn',
  'organization': 'or',
  'company_name': 'cn',
  'place': 'pl',
  'label': 'lb',
  'origin': 'og',
  'airport': 'ap',
  'airports': 'as',
  'origin_airport': 'oa',
  'complete_route': 'cr',
  'airline': 'al',
  'flight_number': 'fn',
  'departure_airport': 'da',
  'arrival_airport': 'aa',
  'hotel_name': 'hn',
  'restaurant': 'rt',
  'restaurant_name': 'rn',
  'thread_topic': 'tt',
  'date': 'dt',
  'start_date': 'sd',
  'end_date': 'ed',
  'datetime': 'dt',
  'timestamp': 'ts',
  'start_datetime': 'sd',
  'end_datetime': 'ed',
  'check_in_date': 'ci',
  'check_out_date': 'co',
  'departure_date': 'dd',
  'arrival_date': 'ad',
  'departure_time': 'dt',
  'arrival_time': 'art',
  'memory_with': 'mw',
  'extracted_entities': 'ee',
  'created_at': 'ca',
  'updated_at': 'ua',
  'legs': 'lg',
  'legs.airline': 'la',
  'legs.flight_number': 'lf',
  'legs.departure_airport': 'ld',
  'legs.arrival_airport': 'la',
  'participant_names': 'pn'
};

// Reverse mapping for decoding
export const PROPERTY_KEY_REVERSE_MAP: Record<string, string> = Object.entries(PROPERTY_KEY_MAP)
  .reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

// Mapping for search pattern codes
export const PATTERN_MAP: Record<string, string> = {
  'person->participates_in->calendar_event': 'ppc',
  'person->memory_with->memory': 'pmm',
  'person->communicates_with->person': 'pcp',
  'person->traveled->flight': 'ptf',
  'person->stayed_at->hotel_stay': 'psh',
  'person->attends->meeting': 'pam',
  'person->sends->email': 'pse',
  'person->receives->email': 'pre',
  'person->creates->document': 'pcd',
  'person->visits->location': 'pvl',
  'person->works_at->organization': 'pwo',
  'person->mentioned_in->memory': 'pmim',
  'person->has_memory->memory': 'phm',
  'person->dines_at->dining': 'pdd',
  'memory->mentions->person': 'mmp',
  'memory->references->location': 'mrl',
  'memory->about->event': 'mae',
  'calendar_event->has_location->location': 'chl',
  'calendar_event->scheduled_with->person': 'csp',
  'email->sent_to->person': 'esp',
  'email->sent_by->person': 'ebp',
  'email->mentions->topic': 'emt',
  'flight->departs_from->airport': 'fda',
  'flight->arrives_at->airport': 'faa',
  'flight->operated_by->airline': 'foa',
  'hotel_stay->located_at->location': 'hll',
  'dining->at_restaurant->location': 'dal',
  'person->connected_to->person': 'pctp',
  'person->manages->person': 'pmp',
  'person->reports_to->person': 'prtp',
  'person->collaborates_with->person': 'pcwp',
  'person->friends_with->person': 'pfwp',
  'person->related_to->person': 'prtp',
  'person->knows->person': 'pkp',
  'person->met->person': 'pmetp'
};

// Reverse mapping for decoding
export const PATTERN_REVERSE_MAP: Record<string, string> = Object.entries(PATTERN_MAP)
  .reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});
