import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Logger from "util/Logger"; // Assuming this path is correct
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'leaflet/dist/leaflet.css'
import StoreWrapper from "./components/common/StoreWrapper"; // Assuming path is correct

import Home from "./pages/landing_page";
import Dashboard from "./pages/dashboard";
import SignIn from "./pages/sign_in";
import SignUpActivation from "./pages/sign_up_activation";
import VerificationPage from "./pages/email_verification";
import Memory from "./pages/memory";
import OauthRedirect from "./pages/oauth_redirect";
import FourOhFour from "./pages/404";
import Atlas from "./pages/atlas";
import KnowledgeGraphExplorer from './components/knowledge-graph'; // Note: Often components used as pages reside in 'pages' dir
import Support from "./pages/support";
import Location from "./pages/location";
import Telemetry from "./pages/telemetry";

import JobById from "./pages/intentions/JobById";

import Welcome from './pages/onboarding/steps/Welcome';
import Trust from './pages/onboarding/steps/Trust';
import Connect from './pages/onboarding/steps/Connect';
import AccountDetails from "./pages/onboarding/steps/AccountDetails";
import AccountIngest from "./pages/onboarding/steps/AccountIngest";
import DataControls from "./pages/onboarding/steps/DataControls";

import ProfilePage from "./pages/settings/Profile"; // Ensure consistent casing if needed (e.g., "./pages/settings/profile")
import ResetPasswordPage from "./pages/settings/ResetPassword";
import ConnectedAccountsPage from "./pages/settings/ConnectedAccounts";

import CallbackPage from "./pages/callback";
import AccessDeniedPage from "./pages/access_denied";

// --- Import the new Node Visualizer Page ---
import NodeVisualizerPage from "./pages/NodeVisualizerPage";
// -----------------------------------------

import "./App.scss"; // Make sure this imports global styles

import { PostHogProvider } from "util/posthogWrapper"; // Assuming path is correct

function App() {
  return (
    <StoreWrapper>
      <PostHogProvider>
        <BrowserRouter>
          <Routes>
            {/* Existing Routes */}
            <Route index element={<Home />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/callback" element={<CallbackPage />} />
            <Route path="/activate" element={<SignUpActivation />} />
            <Route path="/oauth/*" element={<OauthRedirect />} />
            <Route path="/email-verification" element={<VerificationPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/atlas" element={<Atlas />} />
            <Route path="/gen" element={<KnowledgeGraphExplorer />} /> {/* Consider moving KGExplorer to pages */}
            <Route path="/support" element={<Support />} />
            <Route path="/location" element={<Location />} />
            <Route path="/telemetry" element={<Telemetry />} />

            {/* --- Add Route for Node Visualizer --- */}
            <Route path="/nodes" element={<NodeVisualizerPage />} />
            {/* ------------------------------------ */}

            <Route path="/onboarding" element={<Welcome />} />
            <Route path="/onboarding/trust" element={<Trust />} />
            <Route path="/onboarding/details" element={<AccountDetails />} />
            <Route path="/onboarding/connect" element={<Connect />} />
            <Route path="/onboarding/controls" element={<DataControls />} />
            <Route path="/onboarding/ingest" element={<AccountIngest />} />

            <Route path="/intention/:jobId" element={<JobById />} />

            <Route path="/settings/profile" element={<ProfilePage />} />
            <Route path="/settings/password" element={<ResetPasswordPage />} />
            <Route path="/settings/accounts" element={<ConnectedAccountsPage />} />

            <Route path="/memory" element={<Memory />} />

            <Route path="/access-denied" element={<AccessDeniedPage />} />
            <Route path="*" element={<FourOhFour />} />
          </Routes>
        </BrowserRouter>
      </PostHogProvider>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />

    </StoreWrapper>
  );
}

export default App;