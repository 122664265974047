import { NodeData, EdgeData, AIInsight, EnhancedQueryResponse, FilterRecommendation } from '../types/types';
import Logger from 'util/Logger';

export class AIService {
  private backendUrl: string;
  private requestsInProgress: Map<string, Promise<any>> = new Map();
  
  constructor(apiKey: string, backendUrl?: string) {
    // API key is now only used on the backend
    // Import service config here to ensure it's evaluated at runtime
    const serviceConfig = require('./ServiceConfig').default;
    
    this.backendUrl = backendUrl || serviceConfig.aiApiUrl;
    Logger.log(`AIService initialized with backend URL: ${this.backendUrl}`);
    
    // Get the LLM configuration
    this.getLLMConfiguration().then(config => {
      Logger.log(`Using LLM provider: ${config.provider}, default model: ${config.defaultModel}`);
    }).catch(error => {
      Logger.error("Failed to get LLM configuration:", error);
    });
  }
  
  /**
   * Get current LLM configuration from the backend
   */
  async getLLMConfiguration(): Promise<{provider: string, defaultModel: string}> {
    try {
      const response = await fetch(`${this.backendUrl}/llm-config`);
      
      if (!response.ok) {
        throw new Error(`Backend API error: ${response.status}`);
      }
      
      const data = await response.json();
      return {
        provider: data.provider || 'anthropic',
        defaultModel: data.defaultModel || 'claude-3-7-sonnet-20250219'
      };
    } catch (error) {
      Logger.error("Error getting LLM configuration:", error);
      // Return default configuration if request fails
      return {
        provider: 'anthropic',
        defaultModel: 'claude-3-7-sonnet-20250219'
      };
    }
  }
  
  // Helper method to deduplicate requests
  private async makeRequest<T>(endpoint: string, data: any, requestId: string): Promise<T> {
    // Check if we already have this request in progress
    const existingRequest = this.requestsInProgress.get(requestId);
    if (existingRequest) {
      Logger.log(`Reusing in-progress request for ${requestId}`);
      return existingRequest as Promise<T>;
    }
    
    // Get service config dynamically to ensure freshest URL
    const serviceConfig = require('./ServiceConfig').default;
    
    // Special handling for voice queries which are under the anthropic API, not knowledge-graph API
    const endpoint_lower = endpoint.toLowerCase();
    let apiUrl;
    
    if (endpoint_lower === 'process-voice-query' || endpoint_lower === 'prefilter-voice-query') {
      // Voice queries and voice pre-filtering go to the anthropic endpoint
      apiUrl = serviceConfig.aiApiUrl;
      Logger.log(`Using AI API URL for endpoint ${endpoint}: ${apiUrl}`);
    } else {
      // All other requests use the knowledge graph endpoint
      apiUrl = serviceConfig.knowledgeGraphApiUrl || this.backendUrl;
    }
    
    // Create and store the new request
    const requestPromise = (async () => {
      try {
        Logger.log(`Making API request to: ${apiUrl}/${endpoint}`);
        const response = await fetch(`${apiUrl}/${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        });
        
        if (!response.ok) {
          throw new Error(`Backend API error: ${response.status}`);
        }
        
        return await response.json();
      } catch (error) {
        Logger.error(`Error in request ${requestId}:`, error);
        throw error;
      } finally {
        // Remove from in-progress requests when done
        this.requestsInProgress.delete(requestId);
      }
    })();
    
    this.requestsInProgress.set(requestId, requestPromise);
    return requestPromise;
  }
  
  // Enrich a node with additional information
  async enrichNode(node: NodeData): Promise<NodeData> {
    try {
      Logger.log(`Enriching node: ${node.id} (${node.label})`);
      
      const requestId = `enrich-node-${node.id}`;
      const data = await this.makeRequest<{enrichedProperties: any}>(
        'enrich-node', 
        { node }, 
        requestId
      );
      
      return {
        ...node,
        properties: {
          ...node.properties,
          ...data.enrichedProperties,
          enriched: true,
        }
      };
    } catch (error: unknown) {
      Logger.error('Error enriching node:', error);
      return node;
    }
  }
  
  // Generate insights for a subgraph
  async generateInsights(nodes: NodeData[], edges: EdgeData[]): Promise<AIInsight[]> {
    try {
      Logger.log(`Generating insights for ${nodes.length} nodes and ${edges.length} edges`);
      
      // Create a unique ID based on the node IDs to deduplicate similar requests
      const nodeIdsStr = nodes.map(n => n.id).sort().join(',');
      const requestId = `generate-insights-${nodeIdsStr.substring(0, 100)}`;
      
      const data = await this.makeRequest<{insights: AIInsight[]}>(
        'generate-insights', 
        { nodes, edges }, 
        requestId
      );
      
      return data.insights || [];
    } catch (error: unknown) {
      Logger.error('Error generating insights:', error);
      return [];
    }
  }
  
  /**
   * Extract community information from nodes
   */
  extractCommunityInfo(nodes: NodeData[]): {
    communities: Record<string, string>;
    communityDetails: {
      names: Record<string, string>;
      nodeCounts: Record<string, number>;
      communityMap: Record<string, number>;
    };
  } {
    try {
      Logger.log(`Extracting community info from ${nodes.length} nodes`);
      
      // Initialize community structures
      const communityMap: Record<string, number> = {};
      const communityNodeCounts: Record<string, number> = {};
      
      // Extract community information from nodes
      nodes.forEach(node => {
        if (node.community !== undefined) {
          // Map the node to its community
          communityMap[node.id] = node.community;
          
          // Count nodes in each community
          const communityId = node.community.toString();
          communityNodeCounts[communityId] = (communityNodeCounts[communityId] || 0) + 1;
        }
      });
      
      // Generate community names (this is just a placeholder - actual names would come from server)
      const communityNames: Record<string, string> = {};
      Object.keys(communityNodeCounts).forEach(communityId => {
        communityNames[communityId] = `Community ${communityId}`;
      });
      
      // Format the community data in the expected structure
      const communities: Record<string, string> = {};
      Object.entries(communityMap).forEach(([nodeId, communityId]) => {
        communities[nodeId] = communityId.toString();
      });
      
      return {
        communities,
        communityDetails: {
          names: communityNames,
          nodeCounts: communityNodeCounts,
          communityMap
        }
      };
    } catch (error: unknown) {
      Logger.error('Error extracting community info:', error);
      return { 
        communities: {}, 
        communityDetails: { 
          names: {}, 
          nodeCounts: {},
          communityMap: {}
        } 
      };
    }
  }
  
  /**
   * Get suggested queries based on calendar events, flights, hotel stays, and dining events
   * for today + next 30 days and connected people nodes, instead of sending the entire graph.
   * The API response now includes eventDate, relatedPeople, and locations properties.
   */
  async getSuggestedQueries(
    nodes: NodeData[], 
    edges: EdgeData[],
    communities?: Record<string, string>,
    communityInsights?: Record<string, AIInsight[]>,
  ): Promise<any[]> {
    try {
      Logger.log("Generating query suggestions for upcoming events and travel");

      // Get current date
      const today = new Date();

      // Calculate date 30 days from now
      const thirtyDaysFromNow = new Date();
      thirtyDaysFromNow.setDate(today.getDate() + 30);

      // Format dates as ISO strings for comparison
      const todayISO = today.toISOString().split("T")[0];
      const futureISO = thirtyDaysFromNow.toISOString().split("T")[0];

      Logger.log(`Filtering events from ${todayISO} to ${futureISO}`);

      // Check if we have any nodes at all - might still be loading
      if (nodes.length === 0) {
        Logger.log('Graph data not loaded yet, deferring query suggestions');
        return [{ _dataStatus: 'loading' }];
      }
      
      // Filter event nodes for the specified date range
      const calendarNodes = nodes.filter((node) => {
        if (node.type !== "calendar_event") return false;

        // Extract event date from node properties
        const startDateTime = 
          node.properties?.start_datetime || 
          node.properties?.start_date || 
          node.properties?.datetime ||
          node.properties?.date || "";
        if (!startDateTime) return false;

        // Convert to a Date object for proper comparison
        try {
          const eventDate = new Date(startDateTime);
          
          // Compare full dates for proper range check
          return eventDate >= today && eventDate <= thirtyDaysFromNow;
        } catch (e) {
          Logger.error(`Invalid date format in calendar event: ${startDateTime}`, e);
          return false;
        }
      });

      // Filter flight nodes for the specified date range
      const flightNodes = nodes.filter((node) => {
        if (node.type !== "flight") return false;

        // Extract flight date from node properties
        // Based on example, departure_datetime is the correct property
        let departureDate = 
          node.properties?.departure_datetime || 
          node.properties?.departure_time || 
          node.properties?.departure_date || 
          node.properties?.date || 
          node.properties?.datetime || "";
          
        // Extract from legs array if primary fields are missing
        if (!departureDate && Array.isArray(node.properties?.legs) && node.properties.legs.length > 0) {
          const firstLeg = node.properties.legs[0];
          if (firstLeg.departure_datetime) {
            departureDate = firstLeg.departure_datetime;
          }
        }
        
        if (!departureDate) return false;

        // Convert to a Date object for proper comparison
        try {
          const flightDate = new Date(departureDate);
          
          // Compare full dates for proper range check
          return flightDate >= today && flightDate <= thirtyDaysFromNow;
        } catch (e) {
          Logger.error(`Invalid date format in flight: ${departureDate}`, e);
          return false;
        }
      });

      // Filter hotel_stay nodes for the specified date range
      const hotelNodes = nodes.filter((node) => {
        if (node.type !== "hotel_stay") return false;

        // Extract hotel date from node properties
        // Based on example, checkin_datetime is the correct property
        const checkInDate = 
          node.properties?.checkin_datetime || 
          node.properties?.check_in_date || 
          node.properties?.start_date || 
          node.properties?.date || "";
        if (!checkInDate) return false;

        // Convert to a Date object for proper comparison
        try {
          const stayDate = new Date(checkInDate);
          
          // Compare full dates for proper range check
          return stayDate >= today && stayDate <= thirtyDaysFromNow;
        } catch (e) {
          Logger.error(`Invalid date format in hotel stay: ${checkInDate}`, e);
          return false;
        }
      });

      // Filter dining nodes for the specified date range
      const diningNodes = nodes.filter((node) => {
        if (node.type !== "dining") return false;

        // Extract dining date from node properties
        // Based on example, start_datetime is the correct property
        const diningDate = 
          node.properties?.start_datetime || 
          node.properties?.date || 
          node.properties?.datetime || 
          node.properties?.reservation_date || "";
        if (!diningDate) return false;

        // Convert to a Date object for proper comparison
        try {
          const mealDate = new Date(diningDate);
          
          // Compare full dates for proper range check
          return mealDate >= today && mealDate <= thirtyDaysFromNow;
        } catch (e) {
          Logger.error(`Invalid date format in dining: ${diningDate}`, e);
          return false;
        }
      });

      Logger.log(
        `Found events in the next 30 days: ${calendarNodes.length} calendar, ${flightNodes.length} flights, ` +
        `${hotelNodes.length} hotel stays, ${diningNodes.length} dining`
      );

      // Collect all entities in a single array for later use
      const allEventNodes = [
        ...calendarNodes, 
        ...flightNodes,
        ...hotelNodes,
        ...diningNodes
      ];

      // Collect person IDs connected to these events
      const connectedPersonIds = new Set<string>();

      // Find all edges connecting people to these events
      edges.forEach((edge) => {
        // For edges where person is the source
        if (
          edge.type === "participates_in" ||
          edge.type === "attends" ||
          edge.type === "scheduled_with" ||
          edge.type === "traveled" ||
          edge.type === "stayed_at" ||
          edge.type === "dines_at"
        ) {
          const eventId = edge.target;
          const personId = edge.source;

          // Check if this edge connects to one of our filtered events
          const isRelevantEvent = allEventNodes.some(
            (node) => node.id === eventId
          );

          if (isRelevantEvent) {
            connectedPersonIds.add(personId);
          }
        }

        // For edges where person is the target
        if (
          edge.type === "has_participant" ||
          edge.type === "includes" ||
          edge.type === "has_traveler" ||
          edge.type === "has_guest" ||
          edge.type === "has_diner"
        ) {
          const eventId = edge.source;
          const personId = edge.target;

          // Check if this edge connects to one of our filtered events
          const isRelevantEvent = allEventNodes.some(
            (node) => node.id === eventId
          );

          if (isRelevantEvent) {
            connectedPersonIds.add(personId);
          }
        }
      });

      // Get the person nodes that are connected to these events
      const personNodes = nodes.filter(
        (node) => node.type === "person" && connectedPersonIds.has(node.id)
      );

      Logger.log(
        `Found ${personNodes.length} people connected to these events`
      );

      // Combine all event and person nodes
      const relevantNodes = [...allEventNodes, ...personNodes];

      // Get edges between these nodes
      const relevantEdges = edges.filter((edge) => {
        const sourceId =
          typeof edge.source === "object"
            ? (edge.source as any).id
            : edge.source;
        const targetId =
          typeof edge.target === "object"
            ? (edge.target as any).id
            : edge.target;

        return (
          connectedPersonIds.has(sourceId) ||
          connectedPersonIds.has(targetId) ||
          allEventNodes.some(
            (node) => node.id === sourceId || node.id === targetId
          )
        );
      });

      Logger.log(
        `Sending ${relevantNodes.length} nodes and ${relevantEdges.length} edges to suggest-queries API`
      );

      // Only proceed if we have relevant nodes to work with
      if (relevantNodes.length === 0) {
        Logger.log(
          "No relevant events or people found, skipping query suggestions"
        );
        return [];
      }

      // Extract calendar event attendee information
      const eventAttendees = calendarNodes.map(event => {
        // Extract attendees array from properties if it exists
        const attendees = event.properties?.attendees || [];
        // Map attendee emails to a simple array
        const attendeeEmails = attendees.map((a: any) => a.email || '').filter(Boolean);
        
        // Format event data for the LLM to use
        return {
          eventId: event.id,
          title: event.properties?.title || event.label,
          startTime: event.properties?.start_datetime,
          endTime: event.properties?.end_datetime,
          attendeeEmails,
          location: event.properties?.location
        };
      });

      // Extract flight information
      const flightInfo = flightNodes.map(flight => {
        // Extract leg information if available
        let airline = flight.properties?.airline;
        let flightNumber = flight.properties?.flight_number;
        let departureAirport = flight.properties?.departure_airport;
        let arrivalAirport = flight.properties?.arrival_airport;
        let departureDate = flight.properties?.departure_datetime || 
                            flight.properties?.departure_time;
        
        // Extract from legs array if primary fields are missing
        if (Array.isArray(flight.properties?.legs) && flight.properties.legs.length > 0) {
          const firstLeg = flight.properties.legs[0];
          if (!airline && firstLeg.airline) airline = firstLeg.airline;
          if (!flightNumber && firstLeg.flight_number) flightNumber = firstLeg.flight_number;
          if (!departureAirport && firstLeg.departure_airport) departureAirport = firstLeg.departure_airport;
          if (!arrivalAirport && firstLeg.arrival_airport) arrivalAirport = firstLeg.arrival_airport;
          if (!departureDate && firstLeg.departure_datetime) departureDate = firstLeg.departure_datetime;
        }
        
        return {
          flightId: flight.id,
          airline: airline,
          flightNumber: flightNumber,
          departureDate: departureDate,
          departureAirport: departureAirport || flight.properties?.origin,
          arrivalAirport: arrivalAirport || flight.properties?.destination,
          origin: flight.properties?.origin,
          destination: flight.properties?.destination,
          recordLocator: flight.properties?.record_locator,
          routeDescription: flight.properties?.complete_route
        };
      });

      // Extract hotel stay information
      const hotelInfo = hotelNodes.map(hotel => {
        // Get city from lodging address if available
        let city = hotel.properties?.city;
        if (!city && hotel.properties?.lodging_address) {
          const addressParts = hotel.properties.lodging_address.split(', ');
          if (addressParts.length >= 2) {
            city = addressParts[1]; // Usually the second part is the city
          }
        }
        
        // Get city from spatial data if available
        if (!city && 'spatial' in hotel && hotel.spatial?.regions && hotel.spatial.regions.length > 0) {
          city = hotel.spatial.regions[0];
        }
        
        return {
          hotelId: hotel.id,
          hotelName: hotel.properties?.lodging_name || hotel.properties?.hotel_name,
          location: hotel.properties?.lodging_address || hotel.properties?.location,
          checkInDate: hotel.properties?.checkin_datetime || hotel.properties?.check_in_date,
          checkOutDate: hotel.properties?.checkout_datetime || hotel.properties?.check_out_date,
          city: city,
          confirmationCode: hotel.properties?.reservation_code,
          nights: hotel.properties?.nights || 
                 (hotel.properties?.checkin_datetime && hotel.properties?.checkout_datetime ? 
                  (new Date(hotel.properties.checkout_datetime).getTime() - 
                   new Date(hotel.properties.checkin_datetime).getTime()) / (1000 * 60 * 60 * 24) : null)
        };
      });

      // Extract dining information
      const diningInfo = diningNodes.map(dining => {
        // Extract city from address if available
        let city = dining.properties?.city;
        if (!city && dining.properties?.address) {
          const addressParts = dining.properties.address.split(' ');
          const cityStateIndex = addressParts.findIndex(part => part === 'TX' || part === 'CA' || part === 'NY');
          if (cityStateIndex > 0) {
            city = addressParts[cityStateIndex - 1];
          }
        }
        
        // Format time for display
        const formatTime = (datetime: string) => {
          try {
            const date = new Date(datetime);
            return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
          } catch (e) {
            return null;
          }
        };
        
        const time = dining.properties?.time || 
                    (dining.properties?.start_datetime ? formatTime(dining.properties.start_datetime) : null);
        
        return {
          diningId: dining.id,
          restaurantName: dining.properties?.name || dining.properties?.restaurant_name || dining.properties?.restaurant,
          location: dining.properties?.address || dining.properties?.location,
          reservationDate: dining.properties?.start_datetime || dining.properties?.date,
          time: time,
          city: city,
          partySize: dining.properties?.party_size,
          confirmationCode: dining.properties?.reservation_code,
          provider: dining.properties?.provider
        };
      });

      const requestId = `suggest-queries-events-${relevantNodes.length}`;
      const data = await this.makeRequest<{suggestions: any[]}>(
        "suggest-queries", 
        {
          nodes: relevantNodes,
          edges: relevantEdges,
          context: {
            dateRange: { start: todayISO, end: futureISO },
            focusTypes: ["calendar_event", "flight", "hotel_stay", "dining", "person"],
            eventAttendees: eventAttendees,
            flightInfo: flightInfo,
            hotelInfo: hotelInfo,
            diningInfo: diningInfo,
            currentDate: new Date().toISOString()
          },
        },
        requestId,
      );

      // Process the suggestions to add additional metadata
      const enhancedSuggestions = (data.suggestions || []).map(suggestion => {
        // Use eventDate directly from the API response, but fall back to extraction if not available
        let eventDate = suggestion.eventDate;
        
        // Extract event date from the description if not provided by the API
        if (!eventDate) {
          const dateMatch = suggestion.description?.match(/(\w{3}\s\d{1,2}(?:,\s\d{4})?)/);
          eventDate = dateMatch ? dateMatch[1] : null;
        }

        // Detect category based on the suggestion content
        let category = 'insight'; // Default category

        if (suggestion.title.toLowerCase().includes('conflict') || 
                  suggestion.description.toLowerCase().includes('conflict')) {
          category = 'conflict';
        } else if (suggestion.title.toLowerCase().includes('meeting') || 
            suggestion.description.toLowerCase().includes('meeting')) {
          category = 'meeting';
        } else if (suggestion.title.toLowerCase().includes('people') || 
                  suggestion.description.toLowerCase().includes('with')) {
          category = 'people';
        } else if (suggestion.title.toLowerCase().includes('schedule') || 
                  suggestion.description.toLowerCase().includes('schedule')) {
          category = 'schedule';
        } else if (suggestion.title.toLowerCase().includes('travel') || 
                  suggestion.description.toLowerCase().includes('travel') ||
                  suggestion.title.toLowerCase().includes('flight') || 
                  suggestion.description.toLowerCase().includes('flight') ||
                  suggestion.title.toLowerCase().includes('hotel') || 
                  suggestion.description.toLowerCase().includes('hotel') ||
                  suggestion.title.toLowerCase().includes('dining') || 
                  suggestion.description.toLowerCase().includes('dining') ||
                  suggestion.title.toLowerCase().includes('restaurant') || 
                  suggestion.description.toLowerCase().includes('restaurant')) {
          category = 'travel';
        }

        // Use relatedPeople from API response if available 
        let relatedPeople = suggestion.relatedPeople || [];
        
        // Fall back to extraction if not provided by the API
        if (!relatedPeople.length) {
          const personMatches = suggestion.description?.match(/([A-Z][a-z]+ [A-Z][a-z]+)/g) || [];
          relatedPeople = Array.from(new Set(personMatches)); // Deduplicate names
        }

        // Use locations from API response if available
        let allLocations = suggestion.locations || [];
        
        // Fall back to extraction if not provided by the API
        if (!allLocations.length) {
          // Extract location information from the description
          const locationMatches = suggestion.description?.match(/(?:in|to|at|from) ([A-Z][a-z]+(?: [A-Z][a-z]+)?)/g) || [];
          const extractedLocations = locationMatches.map(match => ({
            name: match.replace(/^(?:in|to|at|from) /, ''),
            type: 'unknown'
          }));
          
          // Find locations potentially associated with travel entities
          const travelLocations = [];
          
          // Add flight origin/destination if relevant
          if (flightNodes.length > 0 && category === 'travel') {
            flightNodes.forEach(flight => {
              const origin = flight.properties?.origin || 
                            (flight.properties?.legs && flight.properties.legs[0]?.departure_airport);
              const destination = flight.properties?.destination || 
                                 (flight.properties?.legs && flight.properties.legs[0]?.arrival_airport);
              
              if (origin && suggestion.description?.toLowerCase().includes(origin.toLowerCase())) {
                travelLocations.push({
                  name: origin,
                  type: 'flight',
                  nodeId: flight.id
                });
              }
              
              if (destination && suggestion.description?.toLowerCase().includes(destination.toLowerCase())) {
                travelLocations.push({
                  name: destination,
                  type: 'flight',
                  nodeId: flight.id
                });
              }
            });
          }
          
          // Add hotel locations if relevant
          if (hotelNodes.length > 0 && category === 'travel') {
            hotelNodes.forEach(hotel => {
              const hotelCity = hotel.properties?.city;
              const hotelName = hotel.properties?.lodging_name || hotel.properties?.hotel_name;
              
              if (hotelName && suggestion.description?.includes(hotelName)) {
                travelLocations.push({
                  name: hotelName,
                  type: 'hotel_stay',
                  nodeId: hotel.id
                });
              }
              
              if (hotelCity && suggestion.description?.toLowerCase().includes(hotelCity.toLowerCase())) {
                travelLocations.push({
                  name: hotelCity,
                  type: 'hotel_stay',
                  nodeId: hotel.id
                });
              }
            });
          }
          
          // Add dining locations if relevant
          if (diningNodes.length > 0 && category === 'travel') {
            diningNodes.forEach(dining => {
              const restaurantName = dining.properties?.name || 
                                     dining.properties?.restaurant_name || 
                                     dining.properties?.restaurant;
              
              if (restaurantName && suggestion.description?.includes(restaurantName)) {
                travelLocations.push({
                  name: restaurantName,
                  type: 'dining',
                  nodeId: dining.id
                });
              }
            });
          }
          
          // Combine all locations, prioritizing those with known types
          allLocations = [...travelLocations];
          
          // Add extracted locations that don't duplicate existing ones
          extractedLocations.forEach(loc => {
            if (!allLocations.some(existing => existing.name === loc.name)) {
              allLocations.push(loc);
            }
          });
        }

        return {
          ...suggestion,
          category: suggestion.category || category,
          eventDate,
          relatedPeople,
          locations: allLocations
        };
      });

      return enhancedSuggestions;
    } catch (error: unknown) {
      Logger.error("Error getting query suggestions:", error);
      return [];
    }
  }
  
  // Process a natural language query about the graph
  async processQuery(
    query: string,
    nodes: NodeData[],
    edges: EdgeData[],
  ): Promise<EnhancedQueryResponse> {
    try {
      Logger.log(`Processing query: "${query}" for ${nodes.length} nodes`);
      
      const requestId = `process-query-${query.substring(0, 20)}`;
      const data = await this.makeRequest<{
        answer: string;
        highlightedNodes?: string[];
        highlightedEdges?: string[];
        recommendedFilters?: {
          nodeTypes?: string[];
          edgeTypes?: string[];
          nodeIds?: string[];
          searchTerms?: string[];
          contextDepth?: number;
        };
      }>(
        'process-query', 
        { query, nodes, edges }, 
        requestId
      );
      
      // Process answer for nested JSON structure if present
      let cleanAnswer = data.answer || '';
      let highlightedNodes = data.highlightedNodes || [];
      let highlightedEdges = data.highlightedEdges || [];
      
      // Check if the answer is a nested JSON response
      if (cleanAnswer && cleanAnswer.trim().startsWith('{') && cleanAnswer.includes('"answer"')) {
        try {
          // First try to clean up the JSON to handle comments
          // Remove JavaScript-style comments from the JSON string
          const cleanedJsonString = cleanAnswer
            // Remove single-line comments that appear after values in arrays
            .replace(/,?\s*\/\/.*$/gm, '')
            // Remove trailing commas in arrays and objects
            .replace(/,(\s*[\]}])/g, '$1');
          
          // Attempt to parse the cleaned JSON
          let nestedResponse;
          try {
            nestedResponse = JSON.parse(cleanedJsonString);
          } catch (jsonError) {
            // If direct parsing fails, try a more aggressive approach to handle comment-like structures
            // Extract what looks like a valid JSON by finding matching braces
            const jsonPattern = /({[\s\S]*})/;
            const match = cleanAnswer.match(jsonPattern);
            if (match) {
              const potentialJson = match[0];
              // Remove all comments (lines starting with // or text after //)
              const withoutComments = potentialJson
                .split('\n')
                .map(line => {
                  const commentIndex = line.indexOf('//');
                  return commentIndex >= 0 ? line.substring(0, commentIndex) : line;
                })
                .join('\n')
                // Remove trailing commas
                .replace(/,(\s*[\]}])/g, '$1');
              
              try {
                nestedResponse = JSON.parse(withoutComments);
              } catch (e) {
                Logger.log('Failed to parse JSON even with aggressive cleaning:', e);
                throw e;
              }
            } else {
              throw new Error('Could not find valid JSON pattern');
            }
          }
          
          if (nestedResponse && nestedResponse.answer) {
            Logger.log('Found nested JSON response, extracting better data');
            
            // Extract the real answer from the nested JSON
            cleanAnswer = nestedResponse.answer;
            
            // Use the nested highlight data if available
            if (nestedResponse.highlightedNodes && Array.isArray(nestedResponse.highlightedNodes)) {
              // Handle highlighted nodes in comments format like: "person_6", // You
              highlightedNodes = nestedResponse.highlightedNodes.map((node: any) => {
                // If node is already a string, process it
                if (typeof node === 'string') {
                  // Remove quotes and any comment part
                  return node.replace(/^["']|["']$|\s*\/\/.*$/g, '').trim();
                }
                // If somehow node is an object, try to get its id
                return typeof node === 'object' && node !== null && node.id 
                  ? node.id 
                  : String(node).trim();
              }).filter(Boolean); // Remove any empty strings
            }
            
            if (nestedResponse.highlightedEdges && Array.isArray(nestedResponse.highlightedEdges)) {
              highlightedEdges = nestedResponse.highlightedEdges.map((edge: any) => {
                // If edge is already a string, process it
                if (typeof edge === 'string') {
                  // Remove quotes and any comment part
                  return edge.replace(/^["']|["']$|\s*\/\/.*$/g, '').trim();
                }
                // If edge is an object, try to construct a proper edge string
                if (typeof edge === 'object' && edge !== null) {
                  if (edge.source && edge.target && edge.type) {
                    return `${edge.source} -> ${edge.target} (${edge.type})`;
                  }
                }
                return String(edge).trim();
              }).filter(Boolean); // Remove any empty strings
            }
          }
        } catch (e) {
          // If parsing fails, use the original answer
          Logger.log('Attempted to parse nested JSON but failed:', e);
        }
      }
      
      // Include query in analysis to better determine intent
      const queryContext = query + ' ' + cleanAnswer;
      
      // Remove recommended filters altogether to simplify our approach
      // and rely only on highlighted nodes/edges
      
      // Log the processed data for debugging
      Logger.log(`Query processed with ${highlightedNodes.length} highlighted nodes and ${highlightedEdges.length} highlighted edges`);
      
      return {
        answer: cleanAnswer || 'No answer available.',
        highlightedNodes: highlightedNodes,
        highlightedEdges: highlightedEdges
        // Removed recommendedFilters to simplify filtering approach
      };
    } catch (error: unknown) {
      Logger.error('Error processing query:', error);
      return { answer: 'Sorry, I encountered an error processing your query.' };
    }
  }
  
  /**
   * Pre-filter a voice query to match named entities before processing
   * Enhanced with structured search terms and entity-specific matching strategies
   */
  async prefilterVoiceQuery(query: string, nodes: NodeData[]): Promise<{
    refinedQuery: string;
    relevantEntityTypes: string[];
    focusPersonIds?: string[];
    searchTerms: Record<string, any>;
    baseNodes: string[];
    dateStart: string;
    dateEnd: string;
    searchPatterns?: Array<{
      pattern: string;
      startNodeIds: string[];
      targetTypes: string[];
      description?: string;
    }>;
    searchStrategyHints?: string[];
    explanation?: string;
  }> {
    try {
      Logger.log(`Pre-filtering voice query: "${query}" with ${nodes.length} nodes`);
      
      const requestId = `prefilter-voice-query-${query.substring(0, 20)}`;
      // Updated type definition to include all properties from the LLM response
      const data = await this.makeRequest<{
        refinedQuery: string;
        relevantEntityTypes: string[];
        focusPersonIds?: string[];
        searchTerms: Record<string, any>;
        baseNodes: string[];
        dateStart: string;
        dateEnd: string;
        searchPatterns?: Array<{
          pattern: string;
          startNodeIds: string[];
          targetTypes: string[];
          description?: string;
        }>;
        searchStrategyHints?: string[];
        explanation?: string;
      }>(
        'prefilter-voice-query', 
        { query, nodes }, 
        requestId
      );
      
      // Log the pre-filtering results
      Logger.log(`Voice query pre-filtered: "${query}" → "${data.refinedQuery}"`);
      
      // Log search terms by entity type
      if (data.searchTerms) {
        Object.entries(data.searchTerms).forEach(([entityType, terms]) => {
          Logger.log(`Search terms for ${entityType}:`, terms);
        });
      }
      
      // Log focus person IDs if present
      if (data.focusPersonIds && data.focusPersonIds.length > 0) {
        Logger.log(`Focus person IDs detected: ${JSON.stringify(data.focusPersonIds)}`);
      }
      
      // Log search patterns if present
      if (data.searchPatterns && data.searchPatterns.length > 0) {
        Logger.log(`Search patterns detected: ${data.searchPatterns.length} patterns`);
        data.searchPatterns.forEach((pattern, index) => {
          Logger.log(`  Pattern ${index + 1}: ${pattern.pattern}`);
          Logger.log(`    Start nodes: ${JSON.stringify(pattern.startNodeIds)}`);
          Logger.log(`    Target types: ${JSON.stringify(pattern.targetTypes)}`);
        });
      }
      
      // Log date range if present
      if (data.dateStart && data.dateStart !== '0000-00-00' && 
          data.dateEnd && data.dateEnd !== '0000-00-00') {
        Logger.log(`Date range detected: ${data.dateStart} to ${data.dateEnd}`);
      }
      
      // Log base nodes if present
      if (data.baseNodes && data.baseNodes.length > 0) {
        Logger.log(`Base nodes detected: ${data.baseNodes.length} nodes to start search from`);
      }
      
      // Log search strategy hints if present
      if (data.searchStrategyHints && data.searchStrategyHints.length > 0) {
        Logger.log(`Search strategy hints: ${JSON.stringify(data.searchStrategyHints)}`);
      }
      
      // Return complete response with proper defaults for missing fields
      return {
        refinedQuery: data.refinedQuery,
        relevantEntityTypes: data.relevantEntityTypes || [],
        focusPersonIds: data.focusPersonIds || [],
        searchTerms: data.searchTerms || {},
        baseNodes: data.baseNodes || [],
        dateStart: data.dateStart || '0000-00-00',
        dateEnd: data.dateEnd || '0000-00-00',
        searchPatterns: data.searchPatterns || [],
        searchStrategyHints: data.searchStrategyHints || [],
        explanation: data.explanation || ''
      };
    } catch (error: unknown) {
      Logger.error('Error pre-filtering voice query:', error);
      // If there's an error, return the original query with all expected fields
      return { 
        refinedQuery: query,
        relevantEntityTypes: [],
        focusPersonIds: [],
        searchTerms: {},
        baseNodes: [],
        dateStart: '0000-00-00',
        dateEnd: '0000-00-00',
        searchPatterns: [],
        searchStrategyHints: [],
        explanation: 'Error occurred during pre-filtering'
      };
    }
  }

  /**
   * Process a voice query using the new optimized endpoint that only uses entity types
   * rather than sending the full graph with edges
   * 
   * Note: Date filtering is handled at the pre-filtering stage, so the nodes
   * passed here should already be filtered by date if that was specified in the query
   */
  async processVoiceQuery(
    query: string, 
    nodes: NodeData[],
    edges: EdgeData[]
  ): Promise<{
    filterSuggestions: {
      humanSummary?: string; // Human-friendly summary for audio playback
      importantNodesById?: string[]; // The nodes in the graph to highlight
    }
  }> {
    try {
      Logger.log(`Processing voice query: "${query}" with ${nodes.length} nodes and ${edges.length} edges`);
      
      // Check if we have nodes to process
      if (nodes.length === 0) {
        Logger.log(`No nodes available for processing voice query`);
        
        // Check if the query contains travel-related terms
        const isTravelQuery = this.isTravelRelatedQuery(query);
        const queryLocation = this.extractLocationFromQuery(query);
        const queryTimeframe = this.extractTimeframeFromQuery(query);
        
        if (isTravelQuery) {
          Logger.log(`Detected travel-related query with no nodes: "${query}"`);
          Logger.log(`Query location: ${queryLocation || 'none'}, timeframe: ${queryTimeframe || 'none'}`);
          
          return {
            filterSuggestions: {
              humanSummary: `I don't see any travel records${queryTimeframe ? ' for ' + queryTimeframe : ''}${queryLocation ? ' to ' + queryLocation : ''} in your data. Would you like to try a different time period?`,
              importantNodesById: []
            }
          };
        }
      }
      
      const requestId = `process-voice-query-${query.substring(0, 20)}`;
      
      const data = await this.makeRequest<{
        filterSuggestions: {
          humanSummary?: string;
          importantNodesById?: string[];
        }
      }>(
        'process-voice-query', 
        { query, nodes, edges }, 
        requestId
      );
      
      // Log the processed data for debugging
      Logger.log(`Voice query processed with suggestions:`, data.filterSuggestions);
      
      return data;
    } catch (error: unknown) {
      Logger.error('Error processing voice query:', error);
      return { 
        filterSuggestions: {
        humanSummary: "We were unable to locate that. Try asking us again in a slightly different way.",
        importantNodesById: []
        }
      };
    }
  }
  
  /**
   * Check if a query is related to travel
   */
  private isTravelRelatedQuery(query: string): boolean {
    const travelTerms = ['travel', 'trip', 'flight', 'journey', 'vacation', 'hotel', 'motel', 
                          'stay', 'visited', 'went to', 'fly', 'flew', 'book', 'airport'];
    
    const queryLower = query.toLowerCase();
    return travelTerms.some(term => queryLower.includes(term));
  }
  
  /**
   * Extract potential location from query
   */
  private extractLocationFromQuery(query: string): string | null {
    // Extract location from refined query [location:X]
    const locationMatch = query.match(/\[location:([^\]]+)\]/i);
    if (locationMatch && locationMatch[1]) {
      return locationMatch[1].trim();
    }
    
    return null;
  }
  
  /**
   * Extract timeframe from query
   */
  private extractTimeframeFromQuery(query: string): string | null {
    // Extract temporal from refined query [temporal:X]
    const temporalMatch = query.match(/\[temporal:([^\]]+)\]/i);
    if (temporalMatch && temporalMatch[1]) {
      return temporalMatch[1].trim();
    }
    
    // Check for common time expressions
    const timeExpressions = [
      'last month', 'last year', 'last week', 
      'this month', 'this year', 'this week',
      'in january', 'in february', 'in march', 'in april', 'in may', 'in june',
      'in july', 'in august', 'in september', 'in october', 'in november', 'in december',
      'last january', 'last february', 'last march', 'last april', 'last may', 'last june',
      'last july', 'last august', 'last september', 'last october', 'last november', 'last december'
    ];
    
    const queryLower = query.toLowerCase();
    for (const expr of timeExpressions) {
      if (queryLower.includes(expr)) {
        return expr;
      }
    }
    
    return null;
  }
  
  /**
   * Generate intelligent filter recommendations based on highlighted nodes and edges and AI answer
   * NOTE: This method is no longer used since we now only use highlighted nodes and edges directly
   */
  private generateFilterRecommendations(
    highlightedNodes: string[],
    highlightedEdges: string[],
    allNodes: NodeData[],
    allEdges: EdgeData[],
    answerText?: string
  ): any {
    // If no highlights, return empty filters
    if (highlightedNodes.length === 0 && highlightedEdges.length === 0) {
      return {};
    }
    
    // Find all nodes that were highlighted
    const highlightedNodeObjects = allNodes.filter(node => highlightedNodes.includes(node.id));
    
    // Find all edges that were highlighted
    const highlightedEdgeObjects = allEdges.filter(edge => {
      const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source;
      const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target;
      const edgeKey = `${sourceId} -> ${targetId} (${edge.type})`;
      return highlightedEdges.includes(edgeKey);
    });
    
    // Look for patterns in the answer to determine focus areas
    const focusAreas = this.detectFocusAreas(answerText || '');
    
    // Collect all unique node types and edge types from highlighted elements
    const nodeTypes = [...new Set(highlightedNodeObjects.map(node => node.type))];
    const edgeTypes = [...new Set(highlightedEdgeObjects.map(edge => edge.type))];
    
    // Determine context depth based on the query focus
    let contextDepth = 1; // Default context
    
    if (focusAreas.isDirectConnection) {
      // For direct connection queries, limit context to immediate connections
      contextDepth = 1;
    } else if (focusAreas.isMultiHopQuery) {
      // For multi-hop relationships, expand context to show paths
      contextDepth = 2;
    } else if (focusAreas.isNetworkQuery) {
      // For network/community queries, expand wider
      contextDepth = 3;
    }
    
    // Find connected nodes based on determined context depth
    const connectedNodeIds = new Set<string>(highlightedNodes);
    
    // First add directly connected nodes from highlighted edges
    highlightedEdgeObjects.forEach(edge => {
      const sourceId = typeof edge.source === 'object' 
        ? (edge.source as any).id 
        : edge.source as string;
      const targetId = typeof edge.target === 'object' 
        ? (edge.target as any).id 
        : edge.target as string;
      
      connectedNodeIds.add(sourceId);
      connectedNodeIds.add(targetId);
    });
    
    // Then add nodes based on the context depth
    if (contextDepth > 0) {
      // Get nodes directly connected to highlighted nodes
      this.addConnectedNodes(highlightedNodes, allEdges, connectedNodeIds);
      
      // For deeper context, add second-level connections
      if (contextDepth > 1) {
        const firstLevelNodes = [...connectedNodeIds].filter(id => !highlightedNodes.includes(id));
        this.addConnectedNodes(firstLevelNodes, allEdges, connectedNodeIds);
        
        // For even deeper context, add third-level connections
        if (contextDepth > 2) {
          const secondLevelNodes = [...connectedNodeIds].filter(id => 
            !highlightedNodes.includes(id) && !firstLevelNodes.includes(id));
          this.addConnectedNodes(secondLevelNodes, allEdges, connectedNodeIds);
        }
      }
    }
    
    // Extract important entities mentioned in the answer
    const entityInfo = this.extractEntitiesFromAnswer(answerText || '', allNodes);
    
    // Combine extracted search terms with node labels
    let searchTerms = highlightedNodeObjects
      .map(node => node.label)
      .filter(label => label && label.length > 0);
    
    // Add entity names and extracted emails to search terms
    if (entityInfo.names.length > 0) {
      searchTerms = [...searchTerms, ...entityInfo.names];
    }
    
    if (entityInfo.emails.length > 0 && focusAreas.isEmailQuery) {
      searchTerms = [...searchTerms, ...entityInfo.emails];
    }
    
    // Remove duplicates and empty terms
    searchTerms = [...new Set(searchTerms.filter(term => term && term.trim().length > 0))];
    
    // Prioritize edge types based on focus area
    let prioritizedEdgeTypes = edgeTypes;
    if (focusAreas.isEmailQuery) {
      // For email queries, prioritize email-related edge types
      const emailEdgeTypes = ['sent', 'received_by', 'has_contact_info'];
      prioritizedEdgeTypes = edgeTypes.filter(type => emailEdgeTypes.includes(type));
      if (prioritizedEdgeTypes.length === 0) {
        prioritizedEdgeTypes = emailEdgeTypes.filter(type => 
          allEdges.some(edge => edge.type === type)
        );
      }
    } else if (focusAreas.isConnectionQuery) {
      // For connection queries, prioritize connection-related edge types
      const connectionEdgeTypes = ['communicates_with', 'knows', 'works_with'];
      prioritizedEdgeTypes = edgeTypes.filter(type => connectionEdgeTypes.includes(type));
      if (prioritizedEdgeTypes.length === 0) {
        prioritizedEdgeTypes = connectionEdgeTypes.filter(type => 
          allEdges.some(edge => edge.type === type)
        );
      }
    }
    
    return {
      nodeTypes: nodeTypes.length > 0 ? nodeTypes : undefined,
      edgeTypes: prioritizedEdgeTypes.length > 0 ? prioritizedEdgeTypes : edgeTypes,
      nodeIds: [...connectedNodeIds],
      searchTerms: searchTerms.length > 0 ? searchTerms : undefined,
      contextDepth
    };
  }
  
  /**
   * Add nodes connected to the given node IDs to the accumulator set
   */
  private addConnectedNodes(nodeIds: string[], allEdges: EdgeData[], accumulator: Set<string>): void {
    for (const nodeId of nodeIds) {
      allEdges.forEach(edge => {
        const sourceId = typeof edge.source === 'object' 
          ? (edge.source as any).id 
          : edge.source as string;
        const targetId = typeof edge.target === 'object' 
          ? (edge.target as any).id 
          : edge.target as string;
        
        if (sourceId === nodeId) {
          accumulator.add(targetId);
        } else if (targetId === nodeId) {
          accumulator.add(sourceId);
        }
      });
    }
  }
  
  /**
   * Detect focus areas from the answer text to guide filter generation
   */
  private detectFocusAreas(answerText: string): {
    isEmailQuery: boolean;
    isConnectionQuery: boolean;
    isDirectConnection: boolean;
    isMultiHopQuery: boolean;
    isNetworkQuery: boolean;
  } {
    const lowerText = answerText.toLowerCase();
    
    // Email-related patterns
    const isEmailQuery = 
      lowerText.includes('email') || 
      lowerText.includes('sent') || 
      lowerText.includes('received') || 
      lowerText.includes('message') ||
      lowerText.includes('@');
    
    // Connection-related patterns
    const isConnectionQuery = 
      lowerText.includes('communicates with') || 
      lowerText.includes('connected to') || 
      lowerText.includes('knows') || 
      lowerText.includes('relationship') ||
      lowerText.includes('communication');
    
    // Direct vs. indirect relationship patterns
    const isDirectConnection = 
      lowerText.includes('direct ') || 
      lowerText.includes('directly') ||
      !lowerText.includes('through') && !lowerText.includes('via');
    
    // Multi-hop relationship patterns
    const isMultiHopQuery = 
      lowerText.includes('through') || 
      lowerText.includes('via') || 
      lowerText.includes('intermediate') ||
      lowerText.includes('path');
    
    // Network analysis patterns
    const isNetworkQuery = 
      lowerText.includes('network') || 
      lowerText.includes('community') || 
      lowerText.includes('group') ||
      lowerText.includes('cluster');
    
    return {
      isEmailQuery,
      isConnectionQuery,
      isDirectConnection,
      isMultiHopQuery,
      isNetworkQuery
    };
  }
  
  /**
   * Extract entities (people, emails, etc.) from answer text
   */
  private extractEntitiesFromAnswer(answerText: string, allNodes: NodeData[]): {
    names: string[];
    emails: string[];
    dates: string[];
    nodeIds: string[];
  } {
    // Extract emails using regex
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    const emails = (answerText.match(emailRegex) || []).map(email => email.toLowerCase());
    
    // Extract dates using regex (handles common formats)
    const dateRegex = /\b(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]* \d{1,2},? \d{4}|\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}\b/g;
    const dates = answerText.match(dateRegex) || [];
    
    // Extract potential person names by looking for capitalized words
    const nameRegex = /\b[A-Z][a-z]+(?:\s+[A-Z][a-z]+)*\b/g;
    const potentialNames = answerText.match(nameRegex) || [];
    
    // Filter names by checking if they match node labels in the graph
    const names: string[] = [];
    const nodeIds: string[] = [];
    
    // Look for node ID patterns like (person_441) in the text
    const nodeIdRegex = /\(([a-z]+_\d+)\)/g;
    let match;
    while ((match = nodeIdRegex.exec(answerText)) !== null) {
      if (match[1] && !nodeIds.includes(match[1])) {
        nodeIds.push(match[1]);
      }
    }
    
    // Verify names against node labels
    for (const name of potentialNames) {
      // Skip common non-name capitalized words
      if (['The', 'A', 'An', 'This', 'That', 'These', 'Those', 'I', 'You', 'He', 'She', 'It', 'We', 'They', 'Based', 'Specifically'].includes(name)) {
        continue;
      }
      
      // Check if this name appears in any node label
      const matchingNode = allNodes.find(node => 
        node.label && node.label.includes(name)
      );
      
      if (matchingNode) {
        names.push(name);
        if (!nodeIds.includes(matchingNode.id)) {
          nodeIds.push(matchingNode.id);
        }
      } else if (name.length > 3 && !names.includes(name)) {
        // Include longer capitalized words as potential names even if not in nodes
        names.push(name);
      }
    }
    
    return {
      names,
      emails,
      dates,
      nodeIds
    };
  }
}