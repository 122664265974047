export const CACHE_DURATION = 1000 * 60 * 15; // 15 minutes
export const MAX_RETRY_ATTEMPTS = 1;
export const RETRY_DELAY = 1000; // 1 second

export const pageTransition = {
    initial: { opacity: 0, x: 20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -20 },
    transition: { duration: 0.3 }
};

export const scrollToTop = () => {
    document.querySelector('.adapter-basepage-inner').scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}; 