import React, { useRef, useEffect, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import ForceGraph3D from '3d-force-graph';
import * as THREE from 'three';
import { useTheme } from 'styled-components';
import { NodeData, EdgeData } from '../types/types';
import WasmForceGraph from '../wasm/graph';
import { forceLink, forceManyBody, forceCenter, forceX, forceY, forceZ } from 'd3-force-3d';
import * as d3 from 'd3-force-3d';
import { Color } from 'three';
import Logger from 'util/Logger';
import { createTaskWorker, supportsWebWorkers } from './web-worker-setup';

// Define custom types for atomic particles
interface Particle {
  proton?: THREE.Mesh;
  electron?: THREE.Mesh;
  orbitalGroup: THREE.Group;
  speed: number;
}

// Simple interface for person node shell
interface PersonNode {
  shellGroup: THREE.Group;
  baseScale: number;
  offset: number;
}

interface ForceGraphWASMProps {
  nodes: NodeData[];
  edges: EdgeData[];
  onNodeClick: (node: NodeData) => void;
  onNodeHover: (node: NodeData | null) => void;
  onNodeExpand: (node: NodeData) => void;
  width: number;
  height: number;
  darkMode: boolean;
  communities?: Record<string, string>;
  getNodeCommunityColor?: (nodeId: string, options?: { searchResults?: boolean }) => string;
}

// This is a WASM-powered version of ForceGraphGL
const ForceGraphWASM: React.FC<ForceGraphWASMProps> = ({
  nodes,
  edges,
  onNodeClick,
  onNodeHover,
  onNodeExpand,
  width,
  height,
  darkMode,
  communities,
  getNodeCommunityColor
}): React.ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);
  const graphRef = useRef<any>(null);
  const wasmGraphRef = useRef<WasmForceGraph | null>(null);
  const theme = useTheme() as any;
  const [wasmReady, setWasmReady] = useState(false);
  const [showKeyboardHelp, setShowKeyboardHelp] = useState(false);

  // Get appropriate background color from theme
  const getBgColor = useMemo(() => {
    if (darkMode) {
      // Use theme colors if available, with fallbacks
      return theme?.bgColor || theme?.cardBg || '#111';
    }
    return theme?.bgColor || '#fff';
  }, [darkMode, theme]);
  
  // Track all user nodes with atom rings for animation
  const userNodeObjectsRef = useRef<THREE.Group[]>([]);
  
  // Track person nodes with sonar pulse effects for animation
  const personNodeObjectsRef = useRef<THREE.Group[]>([]);
  
  // Animation frame request ID for cleanup
  const animationFrameRef = useRef<number | null>(null);
  
  // Add animation effect for rotating rings and pulsing effects with performance optimizations
  useEffect(() => {
    let lastTime = 0;
    let frameCount = 0;
    let skipFrameCount = 0;
    const targetFPS = 30; // Target 30fps for animations instead of 60fps
    const frameDuration = 1000 / targetFPS;
    
    // Create a shared time source to reduce Date.now() calls
    let globalTime = 0;
    
    // Track animation start time for consistent timing
    const startTime = Date.now();
    
    // Function to update orbital animations and pulse effects with time-slicing
    const updateAnimations = (timestamp: number) => {
      // Calculate actual time delta for smooth animations regardless of frame rate
      const currentTime = timestamp || performance.now();
      const deltaTime = lastTime ? (currentTime - lastTime) / 1000 : 0.016; // Convert to seconds
      lastTime = currentTime;
      
      // Update global time using real timestamp for consistent animation speed
      globalTime = (Date.now() - startTime) * 0.001; // seconds
      
      // Frame skipping for performance - only animate every other frame when needed
      frameCount++;
      if (skipFrameCount > 0) {
        skipFrameCount--;
        animationFrameRef.current = requestAnimationFrame(updateAnimations);
        return;
      }
      
      // Adaptively set skip frames based on performance - check every 100 frames
      if (frameCount % 100 === 0 && deltaTime > 0.025) { // If frame takes > 25ms
        skipFrameCount = Math.min(3, Math.floor(deltaTime / 0.016)); // Skip up to 3 frames
      }
      
      // Use deltaTime for smooth animations regardless of actual frame rate
      const userNodeRotationSpeed = deltaTime * 0.5; // Scaled for appropriate speed
      
      // Process all user nodes first (atom-like particles)
      const userNodesLen = userNodeObjectsRef.current.length;
      // Process small batches per frame if we have many nodes
      const userNodeBatchSize = userNodesLen > 20 ? 10 : userNodesLen;
      
      // Incrementally update user nodes across multiple frames
      const userNodeStartIdx = frameCount % Math.max(1, Math.ceil(userNodesLen / userNodeBatchSize));
      const userNodeEndIdx = Math.min(userNodeStartIdx + userNodeBatchSize, userNodesLen);
      
      // Only animate a subset of nodes each frame
      for (let i = userNodeStartIdx; i < userNodeEndIdx; i++) {
        const nodeObject = userNodeObjectsRef.current[i];
        if (!nodeObject?.userData) continue;
        
        const { protons, electrons } = nodeObject.userData;
        
        // Update proton orbits with pre-calculated rotation offsets
        if (protons?.length) {
          for (let j = 0; j < Math.min(3, protons.length); j++) { // Only process max 3 protons per frame
            const p = protons[j];
            const orbitGroup = p.orbitalGroup;
            // Use global time with offset for deterministic motion that doesn't depend on frame rate
            const rotSpeed = p.speed * userNodeRotationSpeed;
            orbitGroup.rotation.y += rotSpeed;
            
            // Simplified wobble - less random calls, more predictable
            const wobblePhase = (globalTime * 2 + j * 1.5) % (Math.PI * 2);
            orbitGroup.rotation.z += rotSpeed * 0.1 * Math.sin(wobblePhase);
          }
        }
        
        // Update electron orbits with pre-calculated values
        if (electrons?.length) {
          // Process a single electron per frame for performance
          const electronIdx = frameCount % electrons.length;
          const e = electrons[electronIdx];
          const orbitGroup = e.orbitalGroup;
          const rotSpeed = e.speed * userNodeRotationSpeed;
          
          // More deterministic quantum-like motion with simplified math
          orbitGroup.rotation.y += rotSpeed;
          orbitGroup.rotation.x += rotSpeed * 0.05 * Math.sin(globalTime * 2 + electronIdx);
          orbitGroup.rotation.z += rotSpeed * 0.03 * Math.cos(globalTime * 3 + electronIdx * 2);
        }
      }
      
      // Process all person nodes (pulsing effect)
      const personNodesLen = personNodeObjectsRef.current.length;
      
      // Use pre-calculated sine values for fewer expensive operations
      const sineValue1 = Math.sin(globalTime * 0.5); // For shell pulse
      const sineValue2 = Math.sin(globalTime * 0.3); // For glow pulse
      
      // Process person nodes in batches 
      const personNodeBatchSize = personNodesLen > 30 ? 15 : personNodesLen;
      const personNodeStartIdx = (frameCount * 2) % Math.max(1, Math.ceil(personNodesLen / personNodeBatchSize));
      const personNodeEndIdx = Math.min(personNodeStartIdx + personNodeBatchSize, personNodesLen);
      
      // Only animate a subset of nodes each frame
      for (let i = personNodeStartIdx; i < personNodeEndIdx; i++) {
        const nodeObject = personNodeObjectsRef.current[i];
        if (!nodeObject?.userData) continue;
        
        // Handle shell group animation (outer wireframe) with pre-calculated values
        if (nodeObject.userData.shellGroup) {
          const { shellGroup, offset } = nodeObject.userData;
          
          // Use pre-calculated sine value and add the offset
          const offsetSine = Math.sin(globalTime * 0.5 + offset);
          const scaleFactor = 1 + 0.3 * offsetSine; // Reduced effect range for better performance
          
          // Apply the scale to the shell group
          shellGroup.scale.set(scaleFactor, scaleFactor, scaleFactor);
        }
        
        // Only update glow every other frame to save performance
        if (frameCount % 2 === 0 && nodeObject.userData.glowGroup) {
          const { glowGroup, glowOffset } = nodeObject.userData;
          
          // Use pre-calculated sine value and add the offset
          const offsetGlowSine = Math.sin(globalTime * 0.3 + glowOffset);
          const glowScaleFactor = 1 + 0.15 * offsetGlowSine; // Reduced range
          
          // Apply the breathing scale to the glow sphere
          glowGroup.scale.set(glowScaleFactor, glowScaleFactor, glowScaleFactor);
        }
      }
      
      // Schedule next animation frame with frame limiting for consistent performance
      if (targetFPS < 30) {
        // Limit animation update rate for better performance
        const elapsed = performance.now() - currentTime;
        const delay = Math.max(0, frameDuration - elapsed);
        
        if (delay > 1) {
          // Use setTimeout for frame limiting when we need a delay
          setTimeout(() => {
            animationFrameRef.current = requestAnimationFrame(updateAnimations);
          }, delay);
        } else {
          // No delay needed, request next frame directly
          animationFrameRef.current = requestAnimationFrame(updateAnimations);
        }
      } else {
        // Run at full speed
        animationFrameRef.current = requestAnimationFrame(updateAnimations);
      }
    };
    
    // Start animation loop
    animationFrameRef.current = requestAnimationFrame(updateAnimations);
    
    // Cleanup animation on unmount
    return () => {
      if (animationFrameRef.current !== null) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);
  
  // Define harmonious color palette using Pythagorean Tetractys harmonic ratios
  // These ratios (1:1, 2:1, 3:2, 4:3) create naturally pleasing color relationships
  const entityColorMap = useMemo(() => {
    // We'll use HSL color model for easier manipulation of hue, saturation, and lightness
    // The Tetractys ratios will guide our hue selections
    
    // Base hue from which other hues will be derived using harmonic ratios
    const baseHue = 210; // A calming blue as base
    
    // Create hues based on Tetractys ratios (musical ratios: octave, fifth, fourth)
    // Ratio 1:1 - Unison (same note) - baseHue
    // Ratio 2:1 - Octave - hue + 180 (opposite on color wheel)
    // Ratio 3:2 - Perfect fifth - hue + 120 (Golden triangle on color wheel)
    // Ratio 4:3 - Perfect fourth - hue + 90 (Quarter shift on color wheel)
    // Ratio 9:8 - Major second - hue + 40 (Smaller shift)
    // Ratio 256:243 - Minor second - hue + 20 (Smallest shift)
    
    // For saturation and lightness, we'll also use proportions inspired by tetractys
    const saturationValues = darkMode 
      ? [85, 75, 80, 70, 85, 80, 75] // Higher saturation for dark mode
      : [75, 65, 70, 60, 75, 70, 65]; // Lower saturation for light mode
    
    const lightnessValues = darkMode
      ? [65, 70, 60, 65, 70, 60, 70] // Brighter in dark mode for contrast
      : [45, 50, 40, 45, 50, 40, 45]; // Darker in light mode for contrast
    
    // Create our Tetractys-inspired color map
    return {
      person: `hsl(${baseHue}, ${saturationValues[0]}%, ${lightnessValues[0]}%)`,             // Base hue (1:1)
      flight: `hsl(${(baseHue + 120) % 360}, ${saturationValues[1]}%, ${lightnessValues[1]}%)`, // Perfect fifth (3:2)
      hotel_stay: `hsl(${(baseHue + 180) % 360}, ${saturationValues[2]}%, ${lightnessValues[2]}%)`, // Octave (2:1)
      calendar_event: `hsl(${(baseHue + 90) % 360}, ${saturationValues[3]}%, ${lightnessValues[3]}%)`, // Perfect fourth (4:3)
      dining: `hsl(${(baseHue + 40) % 360}, ${saturationValues[4]}%, ${lightnessValues[4]}%)`, // Major second (9:8)
      email: `hsl(${(baseHue + 220) % 360}, ${saturationValues[5]}%, ${lightnessValues[5]}%)`, // Minor seventh (16:9)
      memory: `hsl(${(baseHue + 270) % 360}, ${saturationValues[6]}%, ${lightnessValues[6]}%)` // Minor sixth (8:5)
    };
  }, [darkMode]);
  
  // Define edge colors based on harmonious relationships with nodes
  const edgeColorMap = useMemo(() => {
    // Convert our HSL colors to THREE.js colors and desaturate/lighten for edges
    const convertToEdgeColor = (hslColor: string): string => {
      // Extract HSL values
      const hslMatch = hslColor.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
      if (!hslMatch) return darkMode ? '#555555' : '#999999';
      
      const h = parseInt(hslMatch[1], 10);
      const s = parseInt(hslMatch[2], 10);
      // Adjust saturation and lightness for edges - more subtle
      const l = parseInt(hslMatch[3], 10);
      
      // Desaturated version of the same hue
      return `hsl(${h}, ${Math.max(s - 30, 15)}%, ${darkMode ? Math.min(l + 5, 75) : Math.max(l - 10, 30)}%)`;
    };
    
    // Create desaturated versions of our node colors for edges
    return Object.entries(entityColorMap).reduce((acc, [key, value]) => {
      acc[key] = convertToEdgeColor(value);
      return acc;
    }, {} as Record<string, string>);
  }, [entityColorMap, darkMode]);

  // Track frame skipping and stabilization phase for performance monitoring
  const skippedFramesRef = useRef(0);
  const stabilizationPhaseRef = useRef(true);
  
  // Define the type for the performance budget with methods
  type PerformanceBudget = {
    budget: number;
    usedBudget: number;
    startTime: number;
    startTimeSlice: () => void;
    endTimeSlice: () => number;
    isOverBudget: () => boolean;
    getRemainingBudget: () => number;
  };
  
  // Initialize with stub methods that will be replaced
  const performanceBudgetRef = useRef<PerformanceBudget>({
    budget: 12,
    usedBudget: 0,
    startTime: 0,
    startTimeSlice: () => {},
    endTimeSlice: () => 0,
    isOverBudget: () => false,
    getRemainingBudget: () => 0
  });
  
  // Use performance monitoring to detect and mitigate long tasks
  useEffect(() => {
    // Create monitoring system for long frame times
    let longFrameCount = 0;
    let consecutiveLongFrames = 0;
    
    // Performance observer to detect long tasks that cause violations
    if (typeof window !== 'undefined' && 'PerformanceObserver' in window) {
      try {
        // This observer watches for long tasks (browser internal mechanism)
        const observer = new PerformanceObserver((list) => {
          for (const entry of list.getEntries()) {
            // Check if this is related to our animation frame
            if (entry.duration > 50) { // Over 50ms is definitely a problem
              longFrameCount++;
              consecutiveLongFrames++;
              
              // After detecting several long frames, take more extreme measures
              if (consecutiveLongFrames > 3) {
                // Force a reduced update rate for the next 100 frames
                skippedFramesRef.current = 0;
                stabilizationPhaseRef.current = false;
                
                // Lower animation performance budget
                if (performanceBudgetRef.current) {
                  performanceBudgetRef.current.budget = 
                    Math.max(4, performanceBudgetRef.current.budget - 2);
                }
                
                // Log this only occasionally
                if (longFrameCount % 10 === 0) {
                  Logger.warn(`Detected long task: ${entry.duration.toFixed(1)}ms. ` +
                             `Reducing animation performance targets.`);
                }
              }
            } else {
              // Reset consecutive counter when we have a good frame
              consecutiveLongFrames = Math.max(0, consecutiveLongFrames - 1);
            }
          }
        });
        
        // Monitor for long tasks
        observer.observe({ type: 'longtask', buffered: true });
        
        return () => {
          observer.disconnect();
        };
      } catch (e) {
        // Some browsers don't support PerformanceObserver for longtask
        Logger.warn("PerformanceObserver not supported for longtask", e);
      }
    }
  }, []);
  
  // Create and manage web worker for offloading heavy tasks
  const workerRef = useRef<Worker | null>(null);
  const positionUpdateCallbackRef = useRef<((updatedNodes: any[]) => void) | null>(null);
  
  // Setup web worker for offloading heavy processing
  useEffect(() => {
    // Skip if web workers aren't supported
    if (!supportsWebWorkers()) {
      Logger.warn("Web Workers not supported - will run all tasks on main thread");
      return;
    }
    
    try {
      // Create worker
      workerRef.current = createTaskWorker();
      
      // Set up message handler
      workerRef.current.onmessage = (e) => {
        const { type, data } = e.data;
        
        switch (type) {
          case 'POSITIONS_UPDATED':
            // Apply position updates from worker
            if (positionUpdateCallbackRef.current && data) {
              positionUpdateCallbackRef.current(data);
            }
            break;
            
          case 'TASK_COMPLETE':
            // Handle other task completions
            break;
        }
      };
      
      // Cleanup worker on unmount
      return () => {
        if (workerRef.current) {
          workerRef.current.terminate();
          workerRef.current = null;
        }
      };
    } catch (error) {
      Logger.error("Failed to initialize web worker:", error);
    }
  }, []);
  
  // Initialize graph
  useEffect(() => {
    if (containerRef.current && !graphRef.current) {
      const graph = ForceGraph3D()(containerRef.current)
        .width(width)
        .height(height)
        .backgroundColor(getBgColor)
        .nodeAutoColorBy('type')
        // Configure label visibility
        .nodeLabel((node: any) => {
          // Check for search directly from window object to ensure we have the latest value
          const currentSearchQuery = window.__activeSearchQuery || "";
          const hasActiveSearch = typeof currentSearchQuery === 'string' && currentSearchQuery.trim() !== '';
          
          // During search, add prefixes to make node types more clear
          if (hasActiveSearch) {
            let prefix = "";
            if (node.isOneHopNeighbor) {
              prefix = "↪ "; // Arrow for one-hop neighbors
            } else if (node.isSearchMatch) {
              prefix = "✓ "; // Checkmark for direct matches
            } else if (node.user) {
              prefix = "👤 "; // User indicator
            }
            return `${prefix}${node.label || node.id}`;
          }
          // Always return a label so it's available for hover
          return node.label || node.id;
        })
        // Reduce the visibility threshold for labels - smaller = more visible
        .nodeRelSize(3)  
        // Handle node click (select)
        .onNodeClick((node: any) => {
          onNodeClick(node);
        })
        // Handle hover with cursor change
        .onNodeHover((node: any) => {
          onNodeHover(node || null);
          if (containerRef.current) {
            containerRef.current.style.cursor = node ? 'pointer' : 'default';
          }
        })
        // Handle node drag
        .onNodeDragEnd((node: any) => {
          // Update node position and fix it
          node.fx = node.x;
          node.fy = node.y;
          node.fz = node.z;
        });

      // Custom link material factory to support dynamic coloring based on edge type
      // Use longer links and more particles for a clearer visualization in a spread-out graph
      graph.linkDirectionalParticles(0)
        .linkDirectionalParticleWidth(2.5)
        .linkWidth(1.8) 
        .linkOpacity(0.5)
        .linkDirectionalParticleSpeed(0.006);
        
      // Set custom distance function for different relationship types with increased distances
      const getLinkDistance = (link: any) => {
        // Use much longer distances for specific relationship types to create a more spread-out structure
        if (link.type === 'participates_in') return 150;
        if (link.type === 'belongs_to') return 130;
        if (link.type === 'memory_with') return 140;
        return 120; // default distance - significantly increased
      };
      
      // Apply custom link distances
      if (typeof graph.d3Force === 'function') {
        const linkForce = graph.d3Force('link');
        if (linkForce && typeof linkForce.distance === 'function') {
          linkForce.distance(getLinkDistance);
        }
      }
      
      // Go back to a simpler approach - use default node styling
      // Let the ForceGraph3D library handle basic node rendering
      // Apply core node styling
      graph
        .nodeColor((node: any) => {
          return node.color || getNodeColor(node);
        })
        // Configure node sizes to provide visual hierarchy
        .nodeVal((node: any) => {
          // Check for search directly from window to ensure latest value
          const currentSearchQuery = window.__activeSearchQuery || "";
          const hasActiveSearch = typeof currentSearchQuery === 'string' && currentSearchQuery.trim() !== '';
          
          // Adjust node sizes based on type and search status
          if (node.user) return 256; // User nodes
          if (hasActiveSearch) {
            if (node.isSearchMatch) return 128; // Direct search matches size 64 as requested
            if (node.isOneHopNeighbor) return 64; // One-hop/indirect neighbors size 17 as requested
            return 32; // Other nodes (beyond one-hop) size 6 as requested
          }
          // Normal node sizes when no search is active
          return 17;
        })
        // Custom THREE.js object for user nodes (atom with orbiting particles)
        // and person nodes (with pulsing sonar effect)
        .nodeThreeObject((node: any) => {
          // Create a pulsing effect for person nodes that aren't user nodes
          if (node.type === 'person' && !node.user) {
            // Create a group to hold all node elements
            const group = new THREE.Group();
            
            // Create the central person node sphere
            const coreGeometry = new THREE.SphereGeometry(2, 24, 24);
            const coreMaterial = new THREE.MeshLambertMaterial({ 
              color: getNodeColor(node),
              emissive: new THREE.Color(getNodeColor(node)).multiplyScalar(0.4)
            });
            const core = new THREE.Mesh(coreGeometry, coreMaterial);
            group.add(core);
            
            // Create an outer glow sphere slightly larger than the core
            const glowGeometry = new THREE.SphereGeometry(2.4, 42, 42); // Higher resolution for smoother appearance
            const nodeColor = new THREE.Color(getNodeColor(node));
            const glowMaterial = new THREE.MeshBasicMaterial({
              color: nodeColor,
              transparent: true,
              opacity: 0.35, // Slightly higher opacity for more noticeable glow
              side: THREE.FrontSide,
              blending: THREE.AdditiveBlending // Additive blending for a more luminous glow effect
            });
            const glow = new THREE.Mesh(glowGeometry, glowMaterial);
            
            // Create a separate group for the glow so it can be animated independently
            const glowGroup = new THREE.Group();
            glowGroup.add(glow);
            group.add(glowGroup);
            
            // Store the glow group in userData for animation
            if (!group.userData) group.userData = {};
            group.userData.glowGroup = glowGroup;
            group.userData.glowBaseScale = 1;
            group.userData.glowOffset = Math.random() * Math.PI * 2; // Random offset for asynchronous breathing
            
            // Create outer shell that will be animated to pulse
            const shellGeometry = new THREE.SphereGeometry(3.0, 24, 24);
            const shellMaterial = new THREE.MeshBasicMaterial({
              color: new THREE.Color(getNodeColor(node)),
              transparent: true,
              opacity: 0.15,
              side: THREE.FrontSide,
              wireframe: true
            });
            const shell = new THREE.Mesh(shellGeometry, shellMaterial);
            
            // Add shell to its own group for independent scaling
            const shellGroup = new THREE.Group();
            shellGroup.add(shell);
            group.add(shellGroup);
            
            // Store scale data to animate
            group.userData = {
              shellGroup,
              baseScale: 1,
              offset: Math.random() * Math.PI * 2  // Random offset to avoid synchronized pulsing
            };
            
            // Scale the main group based on node value
            const scale = Math.sqrt(node.val || 12) / 7;
            group.scale.set(scale, scale, scale);
            
            // Add to tracking array for animation
            personNodeObjectsRef.current.push(group);
            
            return group;
          }
          
          // Only create atomic objects for user nodes
          if (!node.user) return null;
          
          // Create a group to hold all the atom components
          const group = new THREE.Group();
          
          // Create the central user node sphere
          const coreGeometry = new THREE.SphereGeometry(3, 32, 32);
          const coreMaterial = new THREE.MeshLambertMaterial({ 
            color: darkMode ? 0xFFC933 : 0xDAA520,  // Gold color
            emissive: darkMode ? 0x994400 : 0x996600, // Subtle glow
            emissiveIntensity: 0.4
          });
          const core = new THREE.Mesh(coreGeometry, coreMaterial);
          group.add(core);
          
          // Use the Particle interface defined at the top of the file
          
          // Create orbital paths (invisible, just for organization)
          const orbitalGroups: THREE.Group[] = [];
          
          // Create protons that will orbit the user node
          const protons: Particle[] = [];
          
          // Function to create a proton
          const createProton = (orbitRadius: number, initialAngle: number, orbitTilt: number) => {
            // Create an orbital path holder at specified tilt
            const orbitalGroup = new THREE.Group();
            orbitalGroup.rotation.x = (orbitTilt * Math.PI) / 180;
            
            // Create the proton
            const protonGeometry = new THREE.SphereGeometry(1, 16, 16);
            const protonMaterial = new THREE.MeshLambertMaterial({ 
              color: 0xFF4444,  // Bright red
              emissive: 0xAA1111, 
              emissiveIntensity: 0.5
            });
            const proton = new THREE.Mesh(protonGeometry, protonMaterial);
            
            // Position the proton at its initial orbital position
            const x = Math.cos(initialAngle) * orbitRadius;
            const z = Math.sin(initialAngle) * orbitRadius;
            proton.position.set(x, 0, z);
            
            // Add the proton to its orbital group
            orbitalGroup.add(proton);
            
            // Add the orbital group to the main group
            group.add(orbitalGroup);
            
            // Create the particle object
            const particleObj: Particle = { 
              proton, 
              orbitalGroup, 
              speed: 0.02 + Math.random() * 0.01 
            };
            
            // Track this pair for animation
            protons.push(particleObj);
            orbitalGroups.push(orbitalGroup);
            
            return particleObj;
          };
          
          // Create electrons orbiting in shells
          const electrons: Particle[] = [];
          
          // Function to create an electron
          const createElectron = (orbitRadius: number, initialAngle: number, orbitTilt: number, speed: number) => {
            // Create an orbital path holder at specified tilt
            const orbitalGroup = new THREE.Group();
            orbitalGroup.rotation.x = (orbitTilt * Math.PI) / 180;
            orbitalGroup.rotation.y = (Math.random() * 360 * Math.PI) / 180; // Random orientation
            
            // Create the electron
            const electronGeometry = new THREE.SphereGeometry(0.6, 16, 16);
            const electronMaterial = new THREE.MeshLambertMaterial({
              color: 0x44AAFF,  // Bright blue
              emissive: 0x0066CC, 
              emissiveIntensity: 0.7
            });
            const electron = new THREE.Mesh(electronGeometry, electronMaterial);
            
            // Position the electron at its initial orbital position
            const x = Math.cos(initialAngle) * orbitRadius;
            const z = Math.sin(initialAngle) * orbitRadius;
            electron.position.set(x, 0, z);
            
            // Add the electron to its orbital group
            orbitalGroup.add(electron);
            
            // Add the orbital group to the main group
            group.add(orbitalGroup);
            
            // Create the particle object
            const particleObj: Particle = { 
              electron, 
              orbitalGroup, 
              speed 
            };
            
            // Track this pair for animation
            electrons.push(particleObj);
            orbitalGroups.push(orbitalGroup);
            
            return particleObj;
          };
          
          // Create 6 protons at different orbital positions
          for (let i = 0; i < 6; i++) {
            const angle = (i / 6) * Math.PI * 2; // Distribute evenly
            const orbitTilt = 30 + i * 20; // Different tilts
            createProton(5, angle, orbitTilt); // Inner orbital radius 5
          }
          
          // Create 6 electrons - 2 in inner shell, 4 in outer shell
          // Inner K shell (2 electrons)
          for (let i = 0; i < 2; i++) {
            const angle = (i / 2) * Math.PI * 2; // Distribute evenly
            createElectron(8, angle, 0, 0.03); // Inner shell, faster
          }
          
          // Outer L shell (4 electrons)
          for (let i = 0; i < 4; i++) {
            const angle = (i / 4) * Math.PI * 2; // Distribute evenly
            const orbitTilt = 45 + i * 25; // Different tilts
            createElectron(12, angle, orbitTilt, 0.015); // Outer shell, slower
          }
          
          // Store particles for animation
          group.userData = {
            protons,
            electrons,
            orbitalGroups
          };
          
          // We'll update rotation in the global tick function to avoid multiple animation loops
          
          // Scale the entire group based on the node value
          const scale = Math.sqrt(node.val || 12) / 3;
          group.scale.set(scale, scale, scale);
          
          // Add this group to our tracking array for animation
          userNodeObjectsRef.current.push(group);
          
          // Ensure we don't accumulate duplicate references on re-renders
          setTimeout(() => {
            userNodeObjectsRef.current = [...new Set(userNodeObjectsRef.current)];
          }, 100);
          
          return group;
        })
        .nodeThreeObjectExtend(false)
      
      // Configure label styling and hover behavior
      graph
        .backgroundColor(getBgColor)
        // Configure hover behavior to always show labels on hover without bouncing
        .onNodeHover((node: any) => {
          onNodeHover(node || null);
          if (containerRef.current) {
            containerRef.current.style.cursor = node ? 'pointer' : 'default';
          }
          
          // Do not update the graph data when hovering - this prevents bouncing
          // The ForceGraph3D library will handle showing the label on hover automatically
          // as long as we return a label string in the nodeLabel function
        })
        // Set node label configuration - will be shown on hover and for specific nodes
        .nodeLabel((node: any) => {
          // Always construct a label, but visibility is controlled elsewhere
          let prefix = "";
          if (node.isOneHopNeighbor) {
            prefix = "↪ "; // Arrow for one-hop neighbors
          } else if (node.isSearchMatch) {
            prefix = "✓ "; // Checkmark for direct matches
          } else if (node.user) {
            prefix = "👤 "; // User indicator
          }
          
          // Always return a label - controls when the label text is available 
          return node.label ? `${prefix}${node.label}` : node.id;
        })
        // Ensure all nodes are visible in the graph
        .nodeVisibility((node: any) => {
          return true;
        })
        .linkColor((link: any) => {
          return link.color || getEdgeColor(link);
        });
      
      // Enable basic navigation controls
      graph.enableNodeDrag(true)
        .enableNavigationControls(true)
        .showNavInfo(true);
      
      // Configure linkWidth and linkColor for edges
      graph
        .linkWidth(1) // Fixed width for all links
        .linkColor((link: any) => {
          return link.color || getEdgeColor(link);
        });
        
      // Setup special configuration for search results: smaller nodeRelSize to make more labels visible
      // This needs to run after we've assigned other graph properties
      // Access the active search query directly from window
      const currentSearchQuery = window.__activeSearchQuery || "";
      const hasActiveSearch = typeof currentSearchQuery === 'string' && currentSearchQuery.trim() !== '';
      
      if (hasActiveSearch) {
        Logger.log("Active search detected, adjusting node sizes for search results");
        // Size 12 for direct search results, size 6 for indirect as requested
        // This affects the relative size of nodes, not the actual values
        graph.nodeRelSize(1); // Small value ensures more labels are visible
      } else {
        Logger.log("No active search, using default node sizes");
        graph.nodeRelSize(4);  // Default size - fewer labels visible
      }

      // Apply additional scene configurations based on theme
      if (darkMode) {
        // Enhance dark mode rendering
        const scene = graph.scene();
        if (scene) {
          // Add subtle ambient light for better visibility in dark mode
          const ambientLight = new THREE.AmbientLight(0x404040, 0.5);
          scene.add(ambientLight);
          
          // Remove any existing fog
          scene.fog = null;
        }
      }

      graphRef.current = graph;

      // Initialize WASM physics with more stable parameters
      const initWasm = async () => {
        try {
          // Configure physics with parameters for a much more spread out graph
          wasmGraphRef.current = new WasmForceGraph({
            // Slightly slower decay to allow more exploration of space
            alpha_decay: 0.02,
            // Higher minimum alpha means simulation stops sooner
            alpha_min: 0.001,
            // Lower alpha target means the simulation tries to reach equilibrium
            alpha_target: 0,
            // Minimal center force to allow nodes to spread out significantly
            center_strength: 0.03,
            // Much stronger repulsion to push nodes far apart
            repulsion_strength: -150,
            // Weaker link strength to allow nodes to separate more
            link_strength: 0.45,
            // Minimal gravity to allow nodes to move far from center
            gravity: 0.05,
            // Adding angular damping to reduce spinning
            angular_damping: 0.9
          });
          
          await wasmGraphRef.current.initialize();
          setWasmReady(true);
          
          // Start animation loop with extreme performance optimizations
          // to reduce requestAnimationFrame violations
          let lastTime = 0;
          let frameCount = 0;
          let simulationActive = true;
          let animationFrameId: number | null = null;
          
          // Use the refs for these values so they can be accessed from outside effects
          skippedFramesRef.current = 0;
          stabilizationPhaseRef.current = true;
          
          // Track performance metrics
          const frameTimes: number[] = [];
          const maxFrameTimes = 20; // Smaller sample for more responsive adaptation
          
          // Update the performance budget ref with methods
          performanceBudgetRef.current = {
            budget: 16, // Target ms per frame (ensuring we stay under 16ms)
            usedBudget: 0,
            startTime: 0,
            
            // Time-slice management
            startTimeSlice() {
              this.startTime = performance.now();
              this.usedBudget = 0;
            },
            
            endTimeSlice() {
              this.usedBudget = performance.now() - this.startTime;
              return this.usedBudget;
            },
            
            // Check if we're over budget
            isOverBudget() {
              return this.usedBudget > this.budget;
            },
            
            // Get remaining budget in ms
            getRemainingBudget() {
              return Math.max(0, this.budget - this.usedBudget);
            }
          };
          
          // Function to determine optimal frame skip count based on performance
          const getOptimalFrameSkip = (avgFrameTime: number): number => {
            if (avgFrameTime > 100) return 8;      // ~7.5fps - extreme throttling
            if (avgFrameTime > 80) return 6;       // ~10fps - heavy throttling
            if (avgFrameTime > 60) return 4;       // ~15fps - significant throttling
            if (avgFrameTime > 40) return 3;       // ~20fps - moderate throttling
            if (avgFrameTime > 25) return 2;       // ~30fps - light throttling
            return 1;                              // ~60fps - no throttling
          };
          
          // Optimized function to update graph positions with efficient filtering and batching
          const updateGraphPositionsOptimized = () => {
            if (!wasmGraphRef.current || !graphRef.current) return;
            
            try {
              // Get WASM-calculated positions
              const positions = wasmGraphRef.current.getNodePositions();
              if (!positions || positions.size === 0) return;
              
              // Get current graph data
              const graphData = graphRef.current.graphData();
              if (!graphData || !graphData.nodes || graphData.nodes.length === 0) return;
              
              // Determine optimization strategy based on node count
              const nodeCount = graphData.nodes.length;
              const isLargeGraph = nodeCount > 300;
              
              // For large graphs, use batch processing
              if (isLargeGraph) {
                // Create a node lookup map for faster position updates
                const nodeMap = new Map();
                for (const node of graphData.nodes) {
                  nodeMap.set(node.id, node);
                }
                
                // Track if any significant updates happened to avoid unnecessary renders
                let significantUpdates = false;
                const significanceThreshold = 0.05; // Only consider movements larger than this significant
                
                // Process in small batches to avoid jank on large graphs
                const batchSize = Math.min(250, Math.ceil(positions.size / 4));
                let processedCount = 0;
                
                // Process positions in batches
                const processNextBatch = () => {
                  let updatesInBatch = 0;
                  const posEntries = Array.from(positions.entries());
                  
                  // Process next batch
                  const endIdx = Math.min(processedCount + batchSize, posEntries.length);
                  
                  for (let i = processedCount; i < endIdx; i++) {
                    const [id, pos] = posEntries[i];
                    const node = nodeMap.get(id);
                    if (!node) continue;
                    
                    // Calculate movement delta for significance checking
                    const dx = pos.x - node.x;
                    const dy = pos.y - node.y;
                    const dz = pos.z - node.z;
                    const distanceSquared = dx*dx + dy*dy + dz*dz;
                    
                    // Only update if movement is significant
                    if (distanceSquared > significanceThreshold) {
                      // Apply a smart damping factor based on movement magnitude
                      // Faster damping for large movements, smoother for small ones
                      const dampingFactor = distanceSquared < 4 
                        ? 0.5 + 0.4 * (1 - distanceSquared/4) // Smoother transition 
                        : Math.min(0.5, 1.5 / Math.sqrt(distanceSquared)); // Faster convergence
                      
                      // Update position with damping
                      node.x = node.x + dx * dampingFactor;
                      node.y = node.y + dy * dampingFactor;
                      node.z = node.z + dz * dampingFactor;
                      
                      significantUpdates = true;
                      updatesInBatch++;
                    }
                  }
                  
                  processedCount = endIdx;
                  
                  // If there are more positions to process, schedule next batch
                  if (processedCount < posEntries.length) {
                    // Only continue if we're actually making updates
                    if (updatesInBatch > 0) {
                      setTimeout(processNextBatch, 0);
                    } else {
                      // No updates in this batch, check if we should apply changes
                      if (significantUpdates) {
                        graphRef.current?.graphData(graphData);
                      }
                    }
                  } else {
                    // All done, apply updates if any significant changes happened
                    if (significantUpdates) {
                      graphRef.current?.graphData(graphData);
                    }
                  }
                };
                
                // Start processing
                processNextBatch();
              } else {
                // Simpler approach for smaller graphs - process all at once
                let hasUpdates = false;
                const significanceThreshold = 0.01; // Lower threshold for small graphs
                
                // Performance optimization: create a reusable array to avoid in-loop garbage collection
                const nodes = graphData.nodes;
                
                positions.forEach((pos, id) => {
                  // Use binary search approach to find node by id (nodes are often clustered)
                  let foundNode = null;
                  
                  // Simple array traversal since we can't guarantee array is sorted by id
                  for (let i = 0; i < nodes.length; i++) {
                    if (nodes[i].id === id) {
                      foundNode = nodes[i];
                      break;
                    }
                  }
                  
                  if (foundNode) {
                    // Calculate movement delta
                    const dx = pos.x - foundNode.x;
                    const dy = pos.y - foundNode.y;
                    const dz = pos.z - foundNode.z;
                    const distanceSquared = dx*dx + dy*dy + dz*dz;
                    
                    // Only update if movement is significant
                    if (distanceSquared > significanceThreshold) {
                      // Apply adaptive damping based on movement magnitude
                      const dampingFactor = Math.min(0.9, Math.max(0.5, 1.0 - Math.sqrt(distanceSquared) * 0.1));
                      
                      // Update position with damping
                      foundNode.x = foundNode.x + dx * dampingFactor;
                      foundNode.y = foundNode.y + dy * dampingFactor;
                      foundNode.z = foundNode.z + dz * dampingFactor;
                      
                      hasUpdates = true;
                    }
                  }
                });
                
                // Only update graph if we had significant changes
                if (hasUpdates) {
                  graphRef.current.graphData(graphData);
                }
              }
            } catch (error) {
              Logger.error('Error updating graph positions:', error);
            }
          };
          
          // Advanced animation loop with smart throttling and device-aware performance optimizations
          const animate = (time: number) => {
            // Skip animation if no longer active
            if (!simulationActive) {
              return;
            }
            
            // Start performance budget tracking for this frame
            performanceBudgetRef.current.startTimeSlice();
            
            // Calculate time since last frame
            const deltaTime = lastTime === 0 ? 16 : time - lastTime;
            lastTime = time;
            frameCount++;
            
            // Add to performance tracking with intelligent outlier filtering
            // Ignore huge spikes (like tab switches or wakeups) to avoid skewing averages
            if (deltaTime > 0 && deltaTime < 500) {
              frameTimes.push(deltaTime);
              if (frameTimes.length > maxFrameTimes) {
                frameTimes.shift();
              }
            }
            
            // Detect if battery is low or device is throttling
            // This is an expensive check, so only do it occasionally
            if (frameCount % 300 === 0) {
              try {
                // Access Battery API with type assertion for TypeScript
                const nav = navigator as any;
                if (nav.getBattery) {
                  nav.getBattery().then((battery: any) => {
                    if (battery.level < 0.2 && !battery.charging) {
                      // Battery is low, increase throttling
                      performanceBudgetRef.current.budget = Math.max(6, performanceBudgetRef.current.budget * 0.8);
                      Logger.log("Low battery detected, reducing performance target");
                    }
                  }).catch(() => {
                    // Battery API error, ignore
                  });
                }
              } catch (e) {
                // Browser doesn't support battery API, ignore
              }
            }
            
            // Determine if this frame should be processed based on performance budget
            let shouldUpdate = false;
            
            // Calculate average frame time with percentile-based approach
            // This is more robust to temporary spikes than simple averaging
            if (frameTimes.length >= 5) {
              // Sort frame times to calculate median (50th percentile)
              const sortedTimes = [...frameTimes].sort((a, b) => a - b);
              const medianFrameTime = sortedTimes[Math.floor(sortedTimes.length / 2)];
              
              // Get 75th percentile for more conservative throttling
              const highPercentileIdx = Math.floor(sortedTimes.length * 0.75);
              const highPercentileTime = sortedTimes[highPercentileIdx];
              
              // Use the higher percentile time for more conservative frame skipping
              const effectiveFrameTime = highPercentileTime;
              const frameSkip = getOptimalFrameSkip(effectiveFrameTime);
              
              // Dynamic budget adjustment based on real device performance
              if (frameCount % 180 === 0) { // Every ~3 seconds
                if (medianFrameTime > 25) {
                  // Frames taking too long, reduce budget
                  performanceBudgetRef.current.budget = Math.max(5, performanceBudgetRef.current.budget - 1);
                  Logger.log(`Reducing performance budget to ${performanceBudgetRef.current.budget}ms`);
                } else if (medianFrameTime < 10 && performanceBudgetRef.current.budget < 12) {
                  // Plenty of headroom, increase budget slightly
                  performanceBudgetRef.current.budget = Math.min(12, performanceBudgetRef.current.budget + 0.5);
                }
              }
              
              // Only update if we haven't exceeded our frame budget in recent frames
              // And add a random factor to ensure some updates happen even during throttling
              const randomUpdateFactor = Math.random() < 0.1; // 10% chance of update even when throttling
              shouldUpdate = (skippedFramesRef.current >= frameSkip) || 
                             (randomUpdateFactor && skippedFramesRef.current >= 1);
              
              // Reset or increment skip counter
              if (shouldUpdate) {
                skippedFramesRef.current = 0;
              } else {
                skippedFramesRef.current++;
              }
              
              // Log severe performance issues once per 10 seconds (at 30fps)
              if (highPercentileTime > 100 && frameCount % 300 === 0) {
                Logger.warn(`Performance issue: Frame time is ${highPercentileTime.toFixed(1)}ms (75th percentile). ` +
                          `Using ${frameSkip} frame skips.`);
              }
            } else {
              // Until we have enough samples, update every 3rd frame
              shouldUpdate = frameCount % 3 === 0;
            }
            
            // Use a more efficient approach to check stabilization - less frequent 
            // and only when we'd actually update
            if (shouldUpdate && stabilizationPhaseRef.current && frameCount % 30 === 0) {
              const isActive = wasmGraphRef.current?.isActive() || false;
              
              // Check both activity and frame count to determine stabilization
              if ((!isActive && frameCount > 150) || frameCount > 300) {
                stabilizationPhaseRef.current = false;
                Logger.log("Graph stabilized - switching to maintenance mode");
                
                // Final position update with minimal processing
                if (wasmGraphRef.current?.tick()) {
                  // Use a deferred update to avoid frame drops
                  setTimeout(() => {
                    if (wasmGraphRef.current && graphRef.current) {
                      const positions = wasmGraphRef.current.getNodePositions();
                      const graphData = graphRef.current.graphData();
                      
                      // Update in batches to avoid jank
                      const nodeMap = new Map(graphData.nodes.map(n => [n.id, n]));
                      positions.forEach((pos, id) => {
                        const node = nodeMap.get(id) as any;
                        if (node) {
                          node.x = pos.x;
                          node.y = pos.y;
                          node.z = pos.z;
                        }
                      });
                      
                      graphRef.current.graphData(graphData);
                    }
                  }, 0);
                }
              }
            }
            
            // Only perform heavy operations if we're within our budget and should update
            if (shouldUpdate && wasmGraphRef.current && !performanceBudgetRef.current.isOverBudget()) {
              const tickResult = wasmGraphRef.current.tick();
              performanceBudgetRef.current.endTimeSlice();
              
              // Only update positions if significant changes happened and we have budget
              if (tickResult && performanceBudgetRef.current.getRemainingBudget() > 5) {
                // Run position updates a frame later to better distribute work
                if (frameCount % 2 === 0) {
                  updateGraphPositionsOptimized();
                } else {
                  // Schedule update for next frame to spread out workload
                  setTimeout(updateGraphPositionsOptimized, 0);
                }
              }
            } else {
              // No heavy operations this frame, close the time slice
              performanceBudgetRef.current.endTimeSlice();
            }
            
            // Schedule next frame using a paced approach to reduce pressure on the main thread
            const scheduleFn = (callback: FrameRequestCallback): number => {
              if (stabilizationPhaseRef.current) {
                // During stabilization, run at full speed
                return requestAnimationFrame(callback);
              } else {
                // After stabilization, we can pace updates more aggressively
                // Limit to ~40fps to save power when not much is happening
                const timeSinceStart = performance.now() - time;
                if (timeSinceStart < 25) { // Target ~40fps
                  // Use setTimeout followed by rAF but ensure we return a numeric ID
                  const timeoutId = window.setTimeout(() => {
                    const animId = requestAnimationFrame(callback);
                    // Update the ref to the latest animation frame ID
                    if (animationFrameId === timeoutId) {
                      animationFrameId = animId;
                    }
                  }, Math.max(0, 25 - timeSinceStart));
                  return timeoutId; // Return the timeout ID for cleanup
                } else {
                  return requestAnimationFrame(callback);
                }
              }
            };
            
            // Use browser scheduling hints if available
            try {
              // Check if browser supports scheduling priorities
              const hasSchedulingPriorities = 
                // @ts-ignore: Priority option checking
                typeof window.requestAnimationFrame === 'function' && 
                // @ts-ignore
                typeof window.requestAnimationFrame.bind(window).arguments === 'object' &&
                // @ts-ignore
                'priority' in window.requestAnimationFrame.bind(window).arguments;
                
              if (hasSchedulingPriorities) {
                // Low priority animation frame for browsers that support it
                try {
                  // @ts-ignore: This is fine for browsers that support it
                  animationFrameId = requestAnimationFrame(animate, { priority: 'low' });
                } catch {
                  // Fall back to standard rAF if priority argument throws an error
                  animationFrameId = requestAnimationFrame(animate);
                }
              } else {
                // Standard scheduling otherwise
                animationFrameId = scheduleFn(animate);
              }
            } catch (e) {
              // Fallback if any issues with scheduling
              animationFrameId = requestAnimationFrame(animate);
            }
          };
          
          // Start the animation loop
          animationFrameId = requestAnimationFrame(animate);
          
          // Add cleanup function to component
          return () => {
            simulationActive = false;
            if (animationFrameId !== null) {
              cancelAnimationFrame(animationFrameId);
            }
          };
          
          // Apply additional configuration to the 3D graph
          if (graphRef.current) {
            // Configure camera controls for more stability
            const controls = graphRef.current.controls();
            if (controls) {
              // Increase damping to reduce momentum after user interaction
              controls.dampingFactor = 0.9;
              // Enable damping for smoother rotation
              controls.enableDamping = true;
              // Limit rotation speed
              controls.rotateSpeed = 0.06;
              // Add zoom damping
              controls.zoomSpeed = 0.5;
              // Limit rotation around z-axis to reduce disorientation
              controls.maxPolarAngle = Math.PI * 0.8;
              controls.minPolarAngle = Math.PI * 0.2;
            }
            
            // Freeze the simulation during interaction to prevent bouncing
            // This is a key fix for the bouncing issue on hover
            graphRef.current
              // Pause simulation during mouse interaction
              .onEngineStop(() => {
                Logger.log("Physics simulation stopped");
              })
              // Don't automatically resume simulation on user interaction
              .cooldownTime(Infinity); // Never restart simulation after it's cooled down
          }
        } catch (error) {
          Logger.error('Failed to initialize WASM physics:', error);
          // Fallback to three.js physics if WASM fails with parameters for a much more spread out layout
          graphRef.current
            .d3AlphaDecay(0.02) // Slower decay to allow more exploration of space
            .d3VelocityDecay(0.2) // Lower velocity decay for more natural movement
            .d3Force('charge', forceManyBody().strength(-150).distanceMax(2400)) // Much stronger repulsion with very large range
            .d3Force('link', forceLink().distance((link: any) => {
              // Custom distance function for different relationship types with increased distances
              if (link.type === 'communicates_with') return 150;
              if (link.type === 'participates_in') return 130;
              if (link.type === 'memory_with') return 140;
              return 120; // default distance - significantly increased
            }).strength(0.1)) // Weaker link strength to allow more spreading
            .d3Force('center', forceCenter().strength(0.03)) // Minimal center force
            // Very minimal x/y/z forces to allow nodes to spread out extensively in 3D space
            .d3Force('x', d3.forceX().strength(0.01))
            .d3Force('y', d3.forceY().strength(0.01))
            .d3Force('z', d3.forceZ().strength(0.01));
            
          // Configure camera controls for fallback as well
          const controls = graphRef.current.controls();
          if (controls) {
            controls.dampingFactor = 0.25;
            controls.enableDamping = true;
            controls.rotateSpeed = 0.6;
            controls.zoomSpeed = 0.8;
          }
        }
      };
      
      initWasm();
      
      return () => {
        if (wasmGraphRef.current) {
          wasmGraphRef.current.dispose();
        }
        
        // Clean up our user node objects array
        userNodeObjectsRef.current = [];
      };
    }
  // The dependencies are known to be safe as they're props or derived from props
  }, [containerRef, width, height, darkMode, getBgColor, onNodeClick, onNodeHover] as const);

  // Track if we've run initial stabilization
  const [initialStabilizationDone, setInitialStabilizationDone] = useState(false);
  
  // Track the active search query
  const [activeSearchQuery, setActiveSearchQuery] = useState<string>('');
  
  // Effect to sync with window.__activeSearchQuery
  useEffect(() => {
    const checkSearchQuery = () => {
      if (window.__activeSearchQuery !== activeSearchQuery) {
        setActiveSearchQuery(window.__activeSearchQuery || '');
      }
    };
    
    // Check immediately
    checkSearchQuery();
    
    // And set up an interval to check periodically
    const interval = setInterval(checkSearchQuery, 500);
    
    return () => clearInterval(interval);
  }, [activeSearchQuery]);

  // Update graph data and background when nodes, edges, or theme changes
  useEffect(() => {
    if (graphRef.current) {
      // Update background color when theme changes
      graphRef.current.backgroundColor(getBgColor);
      
      // Check for active search query to identify one-hop nodes
      const hasActiveSearch = activeSearchQuery && activeSearchQuery.trim() !== '';
      Logger.log(`ForceGraphWASM: Active search query: "${activeSearchQuery}", hasActiveSearch=${hasActiveSearch}`);
      
      // Since we're having trouble detecting search matched nodes, let's also check for nodes that
      // have search-related terms in their properties
      let searchMatchedNodeIds = new Set<string>();
      
      if (hasActiveSearch) {
        // Try multiple approaches to find nodes that match the search
        const searchTerms = activeSearchQuery.toLowerCase().trim().split(/\s+/);
        
        // First check explicit searchMatched property
        const explicitMatches = nodes
          .filter(node => 
            // Check if node has a property that indicates it matched a search
            // Could be stored in node.properties.searchMatched or other locations
            ((node as any).searchMatched === true || 
             (node.properties && node.properties.searchMatched === true)) && 
            !node.user
          )
          .map(node => node.id);
        
        if (explicitMatches.length > 0) {
          Logger.log(`ForceGraphWASM: Found ${explicitMatches.length} nodes with explicit searchMatched=true`);
          explicitMatches.forEach(id => searchMatchedNodeIds.add(id));
        } else {
          // If no explicit matches, look for nodes where the label contains the search term
          Logger.log("ForceGraphWASM: No explicit searchMatched=true nodes found, checking node labels");
          nodes.forEach(node => {
            // Skip user nodes
            if (node.user) return;
            
            // Check if the node label contains any of the search terms
            if (node.label && searchTerms.some(term => node.label.toLowerCase().includes(term))) {
              searchMatchedNodeIds.add(node.id);
            }
          });
          
          Logger.log(`ForceGraphWASM: Found ${searchMatchedNodeIds.size} nodes with search term in label`);
        }
      }
      
      // Identify one-hop neighbor nodes
      const oneHopNeighborIds = new Set<string>();
      
      if (hasActiveSearch && searchMatchedNodeIds.size > 0) {
        Logger.log(`Identifying one-hop neighbors for ${searchMatchedNodeIds.size} search-matched nodes`);
        
        // Find all nodes that are directly connected to search-matched nodes
        edges.forEach(edge => {
          const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source as string;
          const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target as string;
          
          // If source is a search-matched node, target is a one-hop neighbor
          if (searchMatchedNodeIds.has(sourceId) && !searchMatchedNodeIds.has(targetId)) {
            oneHopNeighborIds.add(targetId);
          }
          
          // If target is a search-matched node, source is a one-hop neighbor
          if (searchMatchedNodeIds.has(targetId) && !searchMatchedNodeIds.has(sourceId)) {
            oneHopNeighborIds.add(sourceId);
          }
        });
        
        Logger.log(`Found ${oneHopNeighborIds.size} one-hop neighbor nodes`);
      }
      
      // Mark one-hop edges
      const oneHopEdgeIds = new Set<string>();
      
      if (oneHopNeighborIds.size > 0) {
        edges.forEach(edge => {
          const sourceId = typeof edge.source === 'object' ? (edge.source as any).id : edge.source as string;
          const targetId = typeof edge.target === 'object' ? (edge.target as any).id : edge.target as string;
          
          // An edge is a one-hop edge if it connects a search-matched node to a one-hop neighbor
          if ((searchMatchedNodeIds.has(sourceId) && oneHopNeighborIds.has(targetId)) ||
              (searchMatchedNodeIds.has(targetId) && oneHopNeighborIds.has(sourceId))) {
            oneHopEdgeIds.add(edge.id || `${sourceId}-${targetId}`);
          }
        });
        
        Logger.log(`Found ${oneHopEdgeIds.size} one-hop connection edges`);
      }
      
      // Add debugging for found nodes
      Logger.log(`ForceGraphWASM: Found ${searchMatchedNodeIds.size} search matched nodes and ${oneHopNeighborIds.size} one-hop neighbors`);
      
      // Log some example IDs
      if (searchMatchedNodeIds.size > 0) {
        Logger.log("ForceGraphWASM: Example search matched node IDs:", 
          [...searchMatchedNodeIds].slice(0, 3));
      }
      
      if (oneHopNeighborIds.size > 0) {
        Logger.log("ForceGraphWASM: Example one-hop neighbor node IDs:", 
          [...oneHopNeighborIds].slice(0, 3));
      }
      
      const nodeObjects = nodes.map(node => {
        // Check if this is a one-hop neighbor
        const isOneHopNeighbor = oneHopNeighborIds.has(node.id);
        
        // For debugging, log some of the one-hop neighbor nodes to verify
        if (isOneHopNeighbor && oneHopNeighborIds.size > 0 && node.id === [...oneHopNeighborIds][0]) {
          Logger.log(`ForceGraphWASM: One-hop neighbor example: ${node.id} (${node.label})`);
        }
        
        // Identify search-matched nodes for logging
        const isSearchMatch = searchMatchedNodeIds.has(node.id);
        if (isSearchMatch && searchMatchedNodeIds.size > 0 && node.id === [...searchMatchedNodeIds][0]) {
          Logger.log(`ForceGraphWASM: Search match example: ${node.id} (${node.label})`);
        }
        
        // Get base color
        let baseColor = getNodeColor(node);
        
        // For one-hop neighbors, create a desaturated gray color
        if (isOneHopNeighbor) {
          if (darkMode) {
            baseColor = '#AAAAAA'; // Light gray for dark mode
          } else {
            baseColor = '#555555'; // Dark gray for light mode
          }
        }

        
        // Check if we have an active search
        const currentSearchQuery = window.__activeSearchQuery || "";
        const hasActiveSearch = typeof currentSearchQuery === 'string' && currentSearchQuery.trim() !== '';
        
        // Determine if this node should have its label visible by default
        // - During search: show all matched nodes and one-hop neighbors
        // - Always show user nodes
        // - On hover, we'll show the label separately via hover handling
        const showLabel = hasActiveSearch ? (isSearchMatch || isOneHopNeighbor || node.user) : node.user;
        
        return {
          ...node,
          color: baseColor,
          // Set node sizes: 12 for user, 64 for direct matches, 17 for one-hop/indirect, 6 for beyond
          val: node.user ? 200 : (isSearchMatch ? 128 : (isOneHopNeighbor ? 64 : (hasActiveSearch ? 64 : 256))),
          // Add flags for use in rendering
          isOneHopNeighbor,
          isSearchMatch,
          showLabel,
          // Add visibility flag to ensure the node appears during search
          __forceLabelVisibility: showLabel
        };
      });
      
      const linkObjects = edges.map(edge => {
        const edgeId = edge.id || `${edge.source}-${edge.target}`;
        // Check if this is a one-hop connection
        const isOneHopConnection = oneHopEdgeIds.has(edgeId);
        
        // For debugging, log some of the one-hop connections to verify
        if (isOneHopConnection && oneHopEdgeIds.size > 0 && edgeId === [...oneHopEdgeIds][0]) {
          Logger.log(`ForceGraphWASM: One-hop connection example: ${edgeId}`);
        }
        
        // Get base color
        let baseColor = getEdgeColor(edge);
        
        // For one-hop connections, use a light gray color
        if (isOneHopConnection) {
          baseColor = darkMode ? '#999999' : '#777777'; // Gray for one-hop edges
        }
        
        return {
          ...edge,
          color: baseColor,
          // Make one-hop connections thinner
          width: isOneHopConnection ? 0.5 : 1,
          // Add flag for use in edge rendering
          isOneHopConnection
        };
      });
      
      // Update graph data in THREE.js renderer
      graphRef.current.graphData({
        nodes: nodeObjects,
        links: linkObjects
      });
      
      // After updating graph data, configure label visibility
      if (hasActiveSearch) {
        // Force a small nodeRelSize to make labels visible during search
        graphRef.current.nodeRelSize(1);
        // Update the label function to show all labels during search
        graphRef.current.nodeLabel((node: any) => {
          // Always return a label for search-matched nodes and one-hop neighbors
          let prefix = "";
          if (node.isOneHopNeighbor) {
            prefix = "↪ "; // Arrow for one-hop neighbors
          } else if (node.isSearchMatch) {
            prefix = "✓ "; // Checkmark for direct matches
          } else if (node.user) {
            prefix = "👤 "; // User indicator
          }
          
          return node.label ? `${prefix}${node.label}` : node.id;
        });
      } else {
        // In non-search mode, only show labels on hover or for user nodes
        graphRef.current.nodeRelSize(5);
        graphRef.current.nodeLabel((node: any) => {
          if (node.user) {
            return node.label || node.id;
          }
          // Always return a label, which will be shown on hover
          return node.label || node.id;
        });
      }
      
      // Also update WASM physics if ready
      if (wasmReady && wasmGraphRef.current) {
        wasmGraphRef.current.setNodes(nodeObjects);
        wasmGraphRef.current.setEdges(linkObjects);
        
        // Run initial stabilization if we haven't done it yet
        if (!initialStabilizationDone && nodes.length > 0) {
          // Set a flag to avoid running this again
          setInitialStabilizationDone(true);
          
          // Run more ticks with progressive phases to achieve a better spread layout
          const stabilize = async () => {
            Logger.log("Starting initial graph stabilization...");
            
            // Phase 1: Extreme expansion - push nodes very far apart initially
            // This creates maximum space between nodes
            const tempParams = { ...wasmGraphRef.current?.getParameters() };
            
            // Use extremely strong repulsion for initial explosive spread
            wasmGraphRef.current?.setParameters({
              repulsion_strength: -300,  // Extremely strong initial repulsion
              center_strength: 0.01,     // Minimal centering force
              gravity: 0.01,             // Minimal gravity
              link_strength: 0.2         // Very weak link strength to allow maximum spreading
            });
            
            // Execute expansion phase
            Logger.log("Phase 1: Explosive expansion");
            for (let i = 0; i < 120; i++) {
              wasmGraphRef.current?.tick();
              // Render occasionally during stabilization
              if (i % 10 === 0) {
                await new Promise(resolve => setTimeout(resolve, 0));
                updateGraphPositions();
              }
            }
            
            // Phase 2: Community formation
            // Strengthen links slightly to maintain some community structure
            Logger.log("Phase 2: Community formation");
            wasmGraphRef.current?.setParameters({
              repulsion_strength: -180,   // Still very strong repulsion
              link_strength: 0.5,         // Moderate link strength for loose community formation
              center_strength: 0.02,      // Very light centering
              gravity: 0.03               // Low gravity
            });
            
            // Allow communities to form
            for (let i = 0; i < 60; i++) {
              wasmGraphRef.current?.tick();
              if (i % 8 === 0) {
                await new Promise(resolve => setTimeout(resolve, 0));
                updateGraphPositions();
              }
            }
            
            // Phase 3: Fine-tuning and stabilization
            // Return to the configured parameters for final stabilization
            Logger.log("Phase 3: Fine-tuning");
            wasmGraphRef.current?.setParameters(tempParams);
            
            // Final stabilization
            for (let i = 0; i < 40; i++) {
              wasmGraphRef.current?.tick();
              if (i % 5 === 0) {
                await new Promise(resolve => setTimeout(resolve, 0));
                updateGraphPositions();
              }
            }
            
            Logger.log("Graph stabilization complete");
            
            // Completely stop the physics simulation after initial stabilization
            // This is the key fix to prevent bouncing when hovering over nodes
            if (graphRef.current) {
              // Stop the physics engine to freeze node positions
              graphRef.current.pauseAnimation();
              // Prevent the simulation from restarting automatically
              graphRef.current.cooldownTime(Infinity);
              Logger.log("Physics simulation completely stopped to prevent bouncing");
            }
            
            // Position camera to show the entire graph with extensive padding for very spread layout
            if (graphRef.current) {
              // Use much longer duration and very generous padding to ensure the widely spread-out graph is visible
              graphRef.current.zoomToFit(1200, 200);
              
              // Set initial camera distance for extremely wide view
              const cameraPosition = graphRef.current.cameraPosition();
              if (cameraPosition) {
                // Store the initial position for reference
                const distance = Math.sqrt(
                  cameraPosition.x * cameraPosition.x +
                  cameraPosition.y * cameraPosition.y +
                  cameraPosition.z * cameraPosition.z
                );
                
                // Calculate new position at a much greater distance to see the widely spread-out graph
                const optimalDistance = Math.max(distance * 1.8, 400);
                graphRef.current.cameraPosition(
                  { x: 0, y: 0, z: optimalDistance },  // New position (much farther away)
                  { x: 0, y: 0, z: 0 },                // Look-at position (center)
                  1500                                  // Transition time (ms) - slower for smoother experience
                );
              }
              
              // Also adjust field of view for wider perspective
              if (graphRef.current.scene()) {
                const camera = graphRef.current.scene().camera;
                if (camera && typeof camera === 'object' && 'fov' in camera) {
                  camera.fov = 60; // Wider field of view (default is usually 45)
                  camera.updateProjectionMatrix();
                }
              }
            }
          };
          
          stabilize();
        }
      }
    }
  }, [nodes, edges, wasmReady, communities, getNodeCommunityColor, darkMode, getBgColor, initialStabilizationDone, activeSearchQuery]);
  
  // Previous positions for calculating movement delta
  const prevPositions = useRef(new Map<string, { x: number, y: number, z: number }>());
  
  // Function to update THREE.js graph with positions from WASM, offloaded to web worker when possible
  // Track last update time for throttling
  const lastUpdateTime = useRef(0);
  const nodesToUpdate = useRef<Set<string>>(new Set());
  const consecutiveHeavyFrames = useRef(0);
  const isBrowserIdle = useRef(true);
  
  // Check if the browser is idle using requestIdleCallback
  if (typeof window !== 'undefined' && 'requestIdleCallback' in window) {
    const checkIdle = () => {
      window.requestIdleCallback((deadline) => {
        isBrowserIdle.current = deadline.timeRemaining() > 10;
        // Schedule next check
        setTimeout(checkIdle, 1000);
      }, { timeout: 1000 });
    };
    checkIdle();
  }
  
  const updateGraphPositions = () => {
    if (!wasmGraphRef.current || !graphRef.current) return;
    
    // Get current time to check if we should process this update
    const now = performance.now();
    
    // Calculate time since last update
    const timeSinceLastUpdate = now - lastUpdateTime.current;
    
    // More aggressive throttling based on consecutive heavy frames
    // As we detect more consecutive heavy frames, we reduce update frequency
    let throttleThreshold = 50; // Default: 20fps
    if (consecutiveHeavyFrames.current > 10) {
      throttleThreshold = 200; // 5fps for very heavy operations
    } else if (consecutiveHeavyFrames.current > 5) {
      throttleThreshold = 100; // 10fps for heavy operations
    }
    
    // If browser is very busy, skip this update
    if (timeSinceLastUpdate < throttleThreshold || !isBrowserIdle.current) {
      return;
    }
    
    // If this frame took too long, increment heavy frame counter
    if (timeSinceLastUpdate > 100) { // More than 100ms between frames
      consecutiveHeavyFrames.current++;
    } else {
      // Reset counter when performance improves
      consecutiveHeavyFrames.current = Math.max(0, consecutiveHeavyFrames.current - 1);
    }
    
    lastUpdateTime.current = now;
    
    const positions = wasmGraphRef.current.getNodePositions();
    const graphData = graphRef.current.graphData();
    
    // Reset nodes to update with a set capacity estimation
    nodesToUpdate.current.clear();
    const nodeCount = graphData.nodes.length;
    
    // Check if we can use web worker to offload processing
    if (workerRef.current && nodeCount > 50 && supportsWebWorkers()) { // Only use worker for larger graphs
      // Set up callback for when worker completes processing
      positionUpdateCallbackRef.current = (updatedNodes) => {
        // Check if we still have a valid graph
        if (!graphRef.current) return;
        
        if (updatedNodes && updatedNodes.length > 0) {
          // Get current graph data
          const currentData = graphRef.current.graphData();
          
          // Create a lookup for faster updates
          const nodeMap = new Map();
          for (const node of currentData.nodes) {
            nodeMap.set(node.id, node);
          }
          
          // Apply the position updates from the worker
          for (const update of updatedNodes) {
            const node = nodeMap.get(update.id);
            if (node) {
              node.x = update.x;
              node.y = update.y;
              node.z = update.z;
              
              // Store updated position for next frame
              prevPositions.current.set(update.id, { 
                x: update.x, 
                y: update.y, 
                z: update.z 
              });
            }
          }
          
          // Only update if we have enough significantly moved nodes
          if (updatedNodes.length > Math.max(1, Math.floor(nodeCount * 0.01))) {
            graphRef.current.graphData({
              nodes: currentData.nodes,
              links: currentData.links
            });
          }
        }
      };
      
      // Serialize the nodes to send to worker
      // We can't send Map objects directly to workers, so convert positions to array
      const positionsArray = Array.from(positions.entries()).map(([id, pos]) => ({
        id, 
        x: pos.x, 
        y: pos.y, 
        z: pos.z
      }));
      
      // Send data to worker for processing
      // This uses our bridge worker that handles the position processing
      workerRef.current.postMessage({
        type: 'PROCESS_POSITIONS',
        data: {
          nodes: graphData.nodes,
          positions: positionsArray, 
          nodeCount
        }
      });
      
      // Processing will continue in the callback when worker responds
      return;
    } 
    
    // Fallback to main thread processing if worker is not available
    // This is the same code as before
    let nodesWithSignificantMovement = 0;
    
    // Process nodes in main thread (only if worker is unavailable)
    if (nodeCount > 0) {
      for (let i = 0; i < nodeCount; i++) {
        const node = graphData.nodes[i];
        const newPos = positions.get(node.id);
        if (!newPos) continue;
        
        // Get previous position if available
        const prev = prevPositions.current.get(node.id) || { x: node.x, y: node.y, z: node.z };
        
        // Calculate movement delta
        const dx = newPos.x - prev.x;
        const dy = newPos.y - prev.y;
        const dz = newPos.z - prev.z;
        
        // Skip minimal movement
        const distanceSquared = dx*dx + dy*dy + dz*dz;
        if (distanceSquared < 0.01) continue;
        
        // Count significant movements
        if (distanceSquared > 0.1) {
          nodesWithSignificantMovement++;
        }
        
        // Mark as needing update
        nodesToUpdate.current.add(node.id);
        
        // Calculate damping
        let delta = distanceSquared < 4 ? 
          (distanceSquared * 0.5 + 0.5) : 
          Math.sqrt(distanceSquared);
        
        // Adaptive damping
        const dampingFactor = consecutiveHeavyFrames.current > 5 ? 
          (delta > 2 ? 0.3 : 0.6) :
          (delta > 5 ? 0.5 : delta > 2 ? 0.7 : 0.9);
        
        // Apply dampened position
        const dampedX = prev.x + dx * dampingFactor;
        const dampedY = prev.y + dy * dampingFactor;
        const dampedZ = prev.z + dz * dampingFactor;
        
        // Update in-place
        node.x = dampedX;
        node.y = dampedY;
        node.z = dampedZ;
        
        // Store for next frame
        prevPositions.current.set(node.id, { 
          x: dampedX, 
          y: dampedY, 
          z: dampedZ 
        });
      }
      
      // Only update the graph if enough nodes moved
      if (nodesWithSignificantMovement > Math.max(1, Math.floor(nodeCount * 0.01))) {
        graphRef.current.graphData({
          nodes: graphData.nodes,
          links: graphData.links
        });
      }
    }
  };
  
  // Helper function to get node color using harmonic color theory and music theory
  const getNodeColor = (node: NodeData) => {
    const currentTheme = theme as any;
    
    // Priority states take precedence over entity-based coloring
    if (node.selected) {
      return currentTheme?.colors?.selected || '#ff5500';
    }
    
    if (node.highlighted) {
      return currentTheme?.colors?.highlighted || '#ffaa00';
    }
    
    if (node.user) {
      return currentTheme?.colors?.userNode || '#9370DB';
    }
    
    // Check for search or highlighted nodes directly from window object
    const currentSearchQuery = window.__activeSearchQuery || "";
    const hasActiveSearch = typeof currentSearchQuery === 'string' && currentSearchQuery.trim() !== '';
    
    // Check if any nodes are highlighted (from search or voice commands)
    const highlightedNodes = window.__searchMatchedNodeIds || [];
    const hasHighlightedNodes = highlightedNodes.length > 0;
    
    // Community coloring if available
    if (communities && node.community !== undefined && getNodeCommunityColor) {
      // When we're searching or have highlighted nodes, use the searchResults mode for special colors
      return getNodeCommunityColor(node.id, { searchResults: hasActiveSearch || hasHighlightedNodes });
    }
    
    // Define a harmonious color system based on music theory intervals
    // Using the circle of fifths and the overtone series to create rich, harmonious colors
    // The overtone series naturally creates pleasant intervals: octave (2:1), perfect fifth (3:2), etc.
    const harmonicEntityColorMap = {
      // Base: sky blue - person nodes (root note / tonic)
      person: darkMode ? 'hsl(210, 85%, 65%)' : 'hsl(210, 85%, 45%)',
      
      // Perfect fifth (3:2 ratio) - warm red (complementary to person blue)
      calendar_event: darkMode ? 'hsl(0, 90%, 65%)' : 'hsl(0, 90%, 50%)',
      
      // Major third (5:4 ratio) - vibrant green
      email: darkMode ? 'hsl(120, 75%, 60%)' : 'hsl(120, 75%, 40%)',
      
      // Perfect fourth (4:3 ratio) - teal 
      dining: darkMode ? 'hsl(180, 80%, 55%)' : 'hsl(180, 80%, 40%)',
      
      // Major sixth (5:3 ratio) - violet purple
      flight: darkMode ? 'hsl(270, 80%, 65%)' : 'hsl(270, 80%, 50%)',
      
      // Minor third (6:5 ratio) - warm orange/amber
      hotel_stay: darkMode ? 'hsl(40, 100%, 65%)' : 'hsl(40, 100%, 50%)',
      
      // Minor seventh (9:5 ratio) - lime green
      memory: darkMode ? 'hsl(160, 85%, 60%)' : 'hsl(160, 85%, 45%)',
      
      // Additional harmonious colors based on the submediant and mediant relationships
      document: darkMode ? 'hsl(330, 75%, 65%)' : 'hsl(330, 75%, 50%)',
      location: darkMode ? 'hsl(90, 70%, 60%)' : 'hsl(90, 70%, 45%)',
      organization: darkMode ? 'hsl(300, 65%, 60%)' : 'hsl(300, 65%, 45%)'
    };
    
    // Get node type, defaulting to empty string if undefined
    const nodeType = (node.type || '').toLowerCase();
    
    // Use our harmonic color map for known entity types
    if (nodeType in harmonicEntityColorMap) {
      return harmonicEntityColorMap[nodeType as keyof typeof harmonicEntityColorMap];
    } else if (nodeType in entityColorMap) {
      return entityColorMap[nodeType as keyof typeof entityColorMap];
    }
    
    // For any unknown types, generate a consistent harmonic color using Golden Ratio
    // The Golden Ratio (φ ≈ 1.618) creates visually pleasing hue separations on the color wheel
    // Each successive color is separated by the golden angle (φ * 360° ≈ 222.5°)
    
    // Create a deterministic hash based on node type
    let hashValue = 0;
    for (let i = 0; i < nodeType.length; i++) {
      hashValue = (hashValue * 31 + nodeType.charCodeAt(i)) % 360;
    }
    
    // Use golden ratio to determine hue
    // 137.5° is the golden angle (approximately 360° * (1 - 1/φ))
    const goldenAngle = 137.5;
    // Start from our person node color (210°) and rotate by golden angle * hash
    const rotations = hashValue % 5; // Limit to 5 rotations for more control
    const hue = (210 + goldenAngle * rotations) % 360;
    
    // Use a Fibonacci-based approach for saturation and lightness to ensure harmony
    // These values are chosen to create good contrast while maintaining harmony
    const saturation = 65 + (nodeType.length % 3) * 10; // 65-85% saturation
    const lightness = darkMode ? 
      60 + (nodeType.charCodeAt(0) % 10) : // 60-70% lightness in dark mode
      40 + (nodeType.charCodeAt(0) % 10);  // 40-50% lightness in light mode
    
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };
  
  // Helper function to get edge color based on source/target node types
  const getEdgeColor = (edge: EdgeData) => {
    // Try to get source and target node types
    const sourceNode = nodes.find(n => n.id === edge.source);
    const targetNode = nodes.find(n => n.id === edge.target);
    
    if (sourceNode && targetNode) {
      const sourceType = (sourceNode.type || '').toLowerCase();
      const targetType = (targetNode.type || '').toLowerCase();
      
      // If both nodes are the same entity type, use that entity's edge color
      if (sourceType === targetType && sourceType in edgeColorMap) {
        return edgeColorMap[sourceType as keyof typeof edgeColorMap];
      }
      
      // If either node is a person, prefer the other node's type for coloring
      if (sourceType === 'person' && targetType in edgeColorMap) {
        return edgeColorMap[targetType as keyof typeof edgeColorMap];
      }
      
      if (targetType === 'person' && sourceType in edgeColorMap) {
        return edgeColorMap[sourceType as keyof typeof edgeColorMap];
      }
      
      // If we have at least one valid type, use it
      if (sourceType in edgeColorMap) {
        return edgeColorMap[sourceType as keyof typeof edgeColorMap];
      }
      
      if (targetType in edgeColorMap) {
        return edgeColorMap[targetType as keyof typeof edgeColorMap];
      }
    }
    
    // Default color for edges without valid source/target
    return darkMode ? '#555555' : '#999999';
  };
  
  // Function to center on the highest density user node
  const centerOnHighestDensityUserNode = () => {
    if (!graphRef.current || !nodes.length) return;
    
    Logger.log("Centering graph on highest density user node");
    
    // Find all user nodes
    const userNodes = nodes.filter(node => node.user);
    
    if (!userNodes.length) {
      Logger.log("No user nodes found to center on");
      return;
    }
    
    // Find the user node with the highest connection count (density)
    let highestDensityNode = userNodes[0];
    let maxConnections = 0;
    
    // Count connections for each user node
    userNodes.forEach(userNode => {
      // Count edges connected to this user node
      const connectionCount = edges.filter(edge => 
        edge.source === userNode.id || edge.target === userNode.id
      ).length;
      
      Logger.log(`User node ${userNode.id}: ${connectionCount} connections`);
      
      if (connectionCount > maxConnections) {
        maxConnections = connectionCount;
        highestDensityNode = userNode;
      }
    });
    
    Logger.log(`Focusing on highest density user node: ${highestDensityNode.id} with ${maxConnections} connections`);
    
    // Get current graph data
    const graphData = graphRef.current.graphData();
    
    // Find the node in the current graph data (to get its current position)
    const targetNode = graphData.nodes.find((n: any) => n.id === highestDensityNode.id);
    
    if (targetNode) {
      // Center camera on this node
      const distance = 3333; // Optimal distance for wide view
      const position = { x: targetNode.x || 0, y: targetNode.y || 0, z: targetNode.z || 0 };
      
      // First zoom out to see the entire graph
      graphRef.current.zoomToFit(1000, 250);
      
      // Then after a delay, center on the highest density node
      setTimeout(() => {
        graphRef.current.cameraPosition(
          { 
            x: position.x, 
            y: position.y, 
            z: position.z + distance // Position camera at a distance from the node
          },
          position, // Look at the node
          2000      // Transition duration in ms
        );
      }, 1500);
    } else {
      // If node not found in current graph data, just zoom to fit all nodes
      graphRef.current.zoomToFit(1000, 200);
      Logger.log("Target node not found in current graph data, zooming to fit all");
    }
  };
  
  // Keyboard shortcuts handler
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Only process keyboard shortcuts when Ctrl key is pressed
      if (!e.ctrlKey) return;
      
      // Toggle help menu with Ctrl + '?' or Ctrl + 'h' key
      if (e.key === '?' || e.key.toLowerCase() === 'h') {
        e.preventDefault(); // Prevent browser default actions
        setShowKeyboardHelp(prev => !prev);
      }
      
      // Center on highest density user node when Ctrl + '0' key is pressed
      if (e.key === '0') {
        e.preventDefault(); // Prevent browser default actions
        centerOnHighestDensityUserNode();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [nodes, edges]);

  // Portal container for keyboard help to avoid React insertion errors
  const [helpContainer] = useState(() => document.createElement('div'));
  
  useEffect(() => {
    // Append portal container to body once on mount
    document.body.appendChild(helpContainer);
    
    // Cleanup on component unmount
    return () => {
      document.body.removeChild(helpContainer);
    };
  }, [helpContainer]);
  
  return (
    <Container ref={containerRef}>
      {/* Use React Portal for help dialog to avoid DOM insertion errors */}
      {ReactDOM.createPortal(
        showKeyboardHelp ? (
          <KeyboardHelp $darkMode={darkMode}>
            <h3>Keyboard Shortcuts</h3>
            <ul>
              <li><kbd>Ctrl</kbd> + <kbd>?</kbd> or <kbd>Ctrl</kbd> + <kbd>H</kbd> - Toggle this help</li>
              <li><kbd>Ctrl</kbd> + <kbd>0</kbd> - Center on highest density user node</li>
              <li><kbd>Click</kbd> - Select node</li>
              <li><kbd>Double-click</kbd> - Expand node</li>
              <li><kbd>Drag</kbd> - Move node</li>
              <li><kbd>Scroll</kbd> - Zoom in/out</li>
              <li><kbd>Right-click + Drag</kbd> - Rotate view</li>
            </ul>
            <CloseButton onClick={() => setShowKeyboardHelp(false)}>
              Close
            </CloseButton>
          </KeyboardHelp>
        ) : null,
        helpContainer
      )}
      {!wasmReady && <LoadingOverlay $darkMode={darkMode}>Loading WebAssembly physics engine...</LoadingOverlay>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const KeyboardHelp = styled.div<{ $darkMode: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ $darkMode }) => ($darkMode ? '#333' : '#fff')};
  color: ${({ $darkMode }) => ($darkMode ? '#fff' : '#333')};
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  z-index: 9999;
  max-width: 400px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
  h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
  }
  
  ul {
    padding-left: 20px;
    margin: 0 0 15px 0;
    list-style-type: none;
  }
  
  li {
    margin-bottom: 8px;
  }
  
  kbd {
    background-color: ${({ $darkMode }) => ($darkMode ? '#555' : '#f1f1f1')};
    border-radius: 3px;
    border: 1px solid ${({ $darkMode }) => ($darkMode ? '#777' : '#ccc')};
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    color: ${({ $darkMode }) => ($darkMode ? '#fff' : '#333')};
    display: inline-block;
    font-size: 0.85em;
    font-weight: 700;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
    margin-right: 4px;
  }
`;

const CloseButton = styled.button`
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background-color: #3a80d2;
  }
`;

const LoadingOverlay = styled.div<{ $darkMode?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ $darkMode, theme }) => 
    $darkMode 
      ? 'rgba(0, 0, 0, 0.7)' 
      : 'rgba(255, 255, 255, 0.7)'
  };
  color: ${({ $darkMode, theme }) => 
    $darkMode 
      ? theme?.textColor || 'white' 
      : theme?.textColor || '#333'
  };
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  z-index: 100;
  backdrop-filter: blur(3px);
  transition: background-color 0.3s ease, color 0.3s ease;
`;

export default ForceGraphWASM;