import styled from 'styled-components';
import { motion } from 'framer-motion';
import { SectionProps } from '../types';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  min-height: 100vh;
  background-color: #edebfa;
  width: 100%;
  max-width: none;
`;

export const BackButton = styled(motion.button)`
  border: none;
  background: none;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-family: 'Saans';
  transition: all 0.2s ease;
  
  &::before {
    content: '←';
    transition: transform 0.2s ease;
  }

  &:hover {
    color: #333;
    &::before {
      transform: translateX(-4px);
    }
  }
`;

export const Section = styled(motion.div)<SectionProps>`
  position: relative;
  margin-bottom: 24px;
  width: 100%;
  max-width: none;
  background-color: #edebfa;
  min-height: ${props => props.isLoading ? '100vh' : 'auto'};
`;

export const BreadcrumbNav = styled.nav`
  padding: 0 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BreadcrumbItem = styled.span<{ isActive?: boolean }>`
  color: ${props => props.isActive ? '#333' : '#666'};
  font-size: 14px;
  cursor: ${props => props.isActive ? 'default' : 'pointer'};
  
  &:hover {
    color: ${props => props.isActive ? '#333' : '#000'};
  }
`;

export const BreadcrumbSeparator = styled.span`
  color: #999;
  margin: 0 4px;
`; 