import React from 'react';
import { motion } from 'framer-motion';
import { addSpacesToTitleCase } from 'util/Text';
import MemoryAnalysis from 'components/MemoryAnalysis';
import {
    Container,
    Header,
    CategoryLabel,
    PreferenceName,
    PreferenceValue,
    Section,
    SectionTitle,
    MemoryContent,
    CategoryTags,
    CategoryTag,
    TimelineSection,
    TimelineItem,
    TimelineIcon,
    TimelineContent,
    TimelineTitle,
    DateText,
    FlightGrid,
    RouteCard,
    RouteIcon,
    DiningSection,
    CuisineTag,
    LodgingSection,
    PropertyCard,
    PurchaseSection,
    MetricCard,
    MetricLabel,
    MetricValue,
    MetricGrid,
    ConfidenceIndicator,
    MetadataGrid,
    MetadataItem,
    MetadataLabel,
    MetadataValue
} from './StyledComponents';

// Types
interface Preference {
    id: string;
    categoryId: string;
    categoryType: string;
    tileType: string;
    name: string;
    value: any;
    metadata?: {
        confidence?: number;
        lastUpdated?: string;
        source?: string;
        created_at?: string;
        last_referenced?: string;
        updated_at?: string;
        categories?: string[];
        location?: string;
        [key: string]: any;
    };
    location?: {
        code?: string;
        city?: string;
        state?: string;
        country?: string;
    };
}

interface HistoryItem {
    provider: string;
    date: string;
    confidence?: number;
    lastReferenced?: string;
    updated?: string;
}

interface UnderstandingItem {
    title: string;
    value: any;
    type: "text" | "percentage" | "memory";
    date?: string;
}

interface PreferenceDetailProps {
    preference: Preference;
    history: HistoryItem[];
    understanding: UnderstandingItem[];
    onBack: () => void;
}

// Part 3: Component Implementation

export const PreferenceDetail: React.FC<PreferenceDetailProps> = ({ preference }) => {
    const formatDate = (dateString: string) => {
        try {
            return new Date(dateString).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        } catch (e) {
            return dateString;
        }
    };

    const renderMemoryView = () => (
        <>
            <Header>
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <CategoryLabel>Memory</CategoryLabel>
                    <PreferenceName>
                        {preference.value.substring(0, 50)}
                        {preference.value.length > 50 ? '...' : ''}
                    </PreferenceName>
                    {preference.metadata?.categories && (
                        <CategoryTags>
                            {preference.metadata.categories.map((category: string, index: number) => (
                                <CategoryTag key={index}>
                                    {category.replace('Category.', '')}
                                </CategoryTag>
                            ))}
                        </CategoryTags>
                    )}
                </motion.div>
            </Header>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
            >
                <MemoryContent>
                    {preference.value}
                </MemoryContent>
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.2 }}
            >
                <Section>
                    <MemoryAnalysis 
                        memoryContent={preference.value}
                        categories={preference.metadata?.categories}
                        timestamp={preference.metadata?.created_at}
                        location={preference.metadata?.location}
                    />
                </Section>
            </motion.div>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.3 }}
            >
                <TimelineSection>
                    <SectionTitle>Timeline</SectionTitle>
                    
                    {/* Created */}
                    <TimelineItem>
                        <TimelineIcon>📝</TimelineIcon>
                        <TimelineContent>
                            <TimelineTitle>Created</TimelineTitle>
                            <DateText>
                                {formatDate(preference.metadata?.created_at || '')}
                            </DateText>
                        </TimelineContent>
                    </TimelineItem>
                    
                    {/* Last Referenced */}
                    {preference.metadata?.last_referenced && (
                        <TimelineItem>
                            <TimelineIcon>👁️</TimelineIcon>
                            <TimelineContent>
                                <TimelineTitle>Last Referenced</TimelineTitle>
                                <DateText>
                                    {formatDate(preference.metadata.last_referenced)}
                                </DateText>
                            </TimelineContent>
                        </TimelineItem>
                    )}
                    
                    {/* Last Updated */}
                    {preference.metadata?.updated_at && (
                        <TimelineItem>
                            <TimelineIcon>🔄</TimelineIcon>
                            <TimelineContent>
                                <TimelineTitle>Last Updated</TimelineTitle>
                                <DateText>
                                    {formatDate(preference.metadata.updated_at)}
                                </DateText>
                            </TimelineContent>
                        </TimelineItem>
                    )}

                    {/* Source Information */}
                    {preference.metadata?.source && (
                        <TimelineItem>
                            <TimelineIcon>📱</TimelineIcon>
                            <TimelineContent>
                                <TimelineTitle>Source</TimelineTitle>
                                <DateText>
                                    {preference.metadata.source}
                                </DateText>
                            </TimelineContent>
                        </TimelineItem>
                    )}

                    {/* Confidence Score */}
                    {preference.metadata?.confidence && (
                        <TimelineItem>
                            <TimelineIcon>📊</TimelineIcon>
                            <TimelineContent>
                                <TimelineTitle>Confidence Score</TimelineTitle>
                                <ConfidenceIndicator score={preference.metadata.confidence}>
                                    {(preference.metadata.confidence * 100).toFixed(1)}%
                                </ConfidenceIndicator>
                            </TimelineContent>
                        </TimelineItem>
                    )}
                </TimelineSection>
            </motion.div>

            {/* Additional Metadata Section */}
            {preference.metadata && Object.keys(preference.metadata).some(key => 
                !['categories', 'created_at', 'updated_at', 'last_referenced', 'source', 'confidence'].includes(key)
            ) && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.4 }}
                >
                    <Section>
                        <SectionTitle>Additional Details</SectionTitle>
                        <MetadataGrid>
                            {Object.entries(preference.metadata)
                                .filter(([key]) => !['categories', 'created_at', 'updated_at', 'last_referenced', 'source', 'confidence'].includes(key))
                                .map(([key, value]) => (
                                    <MetadataItem key={key}>
                                        <MetadataLabel>{addSpacesToTitleCase(key)}</MetadataLabel>
                                        <MetadataValue>
                                            {typeof value === 'object' ? JSON.stringify(value, null, 2) : String(value)}
                                        </MetadataValue>
                                    </MetadataItem>
                                ))}
                        </MetadataGrid>
                    </Section>
                </motion.div>
            )}
        </>
    );
// Part 4: Remaining render functions and final return statement

    const renderFlightView = () => (
        <>
            <Header>
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <CategoryLabel>Flight Preference</CategoryLabel>
                    <PreferenceName>{addSpacesToTitleCase(preference.name)}</PreferenceName>
                    <PreferenceValue>{preference.value}</PreferenceValue>
                </motion.div>
            </Header>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
            >
                <FlightGrid>
                    {preference.name.toLowerCase().includes('route') ? (
                        <RouteCard>
                            <RouteIcon>✈️</RouteIcon>
                            <div>
                                <MetricLabel>Route Details</MetricLabel>
                                <MetricValue>{preference.value}</MetricValue>
                            </div>
                        </RouteCard>
                    ) : preference.name.toLowerCase().includes('airline') ? (
                        <RouteCard>
                            <RouteIcon>🏢</RouteIcon>
                            <div>
                                <MetricLabel>Airline</MetricLabel>
                                <MetricValue>{preference.value}</MetricValue>
                            </div>
                        </RouteCard>
                    ) : (
                        <RouteCard>
                            <RouteIcon>🎯</RouteIcon>
                            <div>
                                <MetricLabel>{addSpacesToTitleCase(preference.name)}</MetricLabel>
                                <MetricValue>{preference.value}</MetricValue>
                            </div>
                        </RouteCard>
                    )}
                </FlightGrid>
            </motion.div>

            {preference.metadata && Object.keys(preference.metadata).length > 0 && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                >
                    <Section>
                        <SectionTitle>Additional Details</SectionTitle>
                        <MetricGrid>
                            {Object.entries(preference.metadata)
                                .filter(([key]) => !['confidence', 'lastUpdated', 'source'].includes(key))
                                .map(([key, value]) => (
                                    <MetricCard key={key}>
                                        <MetricLabel>{addSpacesToTitleCase(key)}</MetricLabel>
                                        <MetricValue>{value}</MetricValue>
                                    </MetricCard>
                                ))}
                        </MetricGrid>
                    </Section>
                </motion.div>
            )}
        </>
    );

    const renderDiningView = () => (
        <>
            <Header>
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <CategoryLabel>Dining Preference</CategoryLabel>
                    <PreferenceName>{addSpacesToTitleCase(preference.name)}</PreferenceName>
                    <PreferenceValue>{preference.value}</PreferenceValue>
                </motion.div>
            </Header>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
            >
                <DiningSection>
                    <SectionTitle>Details</SectionTitle>
                    {preference.name.toLowerCase().includes('cuisine') ? (
                        <CategoryTags>
                            {preference.value.split(',').map((cuisine: string, index: number) => (
                                <CuisineTag key={index}>{cuisine.trim()}</CuisineTag>
                            ))}
                        </CategoryTags>
                    ) : (
                        <MetricGrid>
                            <MetricCard>
                                <MetricLabel>{addSpacesToTitleCase(preference.name)}</MetricLabel>
                                <MetricValue>{preference.value}</MetricValue>
                            </MetricCard>
                        </MetricGrid>
                    )}
                </DiningSection>
            </motion.div>
        </>
    );

    const renderLodgingView = () => (
        <>
            <Header>
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <CategoryLabel>Lodging Preference</CategoryLabel>
                    <PreferenceName>{addSpacesToTitleCase(preference.name)}</PreferenceName>
                    <PreferenceValue>{preference.value}</PreferenceValue>
                </motion.div>
            </Header>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
            >
                <LodgingSection>
                    <SectionTitle>Property Details</SectionTitle>
                    <MetricGrid>
                        <PropertyCard>
                            <MetricLabel>{addSpacesToTitleCase(preference.name)}</MetricLabel>
                            <MetricValue>{preference.value}</MetricValue>
                        </PropertyCard>
                        {preference.metadata && Object.entries(preference.metadata)
                            .filter(([key]) => !['confidence', 'lastUpdated', 'source'].includes(key))
                            .map(([key, value]) => (
                                <PropertyCard key={key}>
                                    <MetricLabel>{addSpacesToTitleCase(key)}</MetricLabel>
                                    <MetricValue>{value}</MetricValue>
                                </PropertyCard>
                            ))}
                    </MetricGrid>
                </LodgingSection>
            </motion.div>
        </>
    );

    const renderPurchaseView = () => (
        <>
            <Header>
                <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <CategoryLabel>Purchase Pattern</CategoryLabel>
                    <PreferenceName>{addSpacesToTitleCase(preference.name)}</PreferenceName>
                    <PreferenceValue>{preference.value}</PreferenceValue>
                </motion.div>
            </Header>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.1 }}
            >
                <PurchaseSection>
                    <SectionTitle>Spending Analysis</SectionTitle>
                    <MetricGrid>
                        <MetricCard>
                            <MetricLabel>Value</MetricLabel>
                            <MetricValue>{preference.value}</MetricValue>
                        </MetricCard>
                        {preference.metadata && Object.entries(preference.metadata)
                            .filter(([key]) => !['confidence', 'lastUpdated', 'source'].includes(key))
                            .map(([key, value]) => (
                                <MetricCard key={key}>
                                    <MetricLabel>{addSpacesToTitleCase(key)}</MetricLabel>
                                    <MetricValue>{value}</MetricValue>
                                </MetricCard>
                            ))}
                    </MetricGrid>
                </PurchaseSection>
            </motion.div>
        </>
    );

    const renderView = () => {
        switch (preference.categoryType) {
            case 'Memories':
                return renderMemoryView();
            case 'Flights':
                return renderFlightView();
            case 'Dining':
                return renderDiningView();
            case 'Lodging':
                return renderLodgingView();
            case 'Purchases':
                return renderPurchaseView();
            default:
                return (
                    <>
                        <Header>
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <CategoryLabel>{preference.categoryType}</CategoryLabel>
                                <PreferenceName>{addSpacesToTitleCase(preference.name)}</PreferenceName>
                                <PreferenceValue>{preference.value}</PreferenceValue>
                            </motion.div>
                        </Header>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3, delay: 0.1 }}
                        >
                            <Section>
                                <SectionTitle>Details</SectionTitle>
                                <MetricGrid>
                                    {preference.metadata && Object.entries(preference.metadata)
                                        .filter(([key]) => !['confidence', 'lastUpdated', 'source'].includes(key))
                                        .map(([key, value]) => (
                                            <MetricCard key={key}>
                                                <MetricLabel>{addSpacesToTitleCase(key)}</MetricLabel>
                                                <MetricValue>{value}</MetricValue>
                                            </MetricCard>
                                        ))}
                                </MetricGrid>
                            </Section>
                        </motion.div>
                    </>
                );
        }
    };

    return (
        <Container
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className='PreferenceDetail'
        >
            {renderView()}
        </Container>
    );
};

export default PreferenceDetail;