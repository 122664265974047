import React, { Component, createRef } from 'react';

// ===== Configuration =====
const rotationSpeed = 0.5;   // Global rotation (radians per second)
const pipeRadius = 12;       // Radius of each node ("pipe")
const gap = 8;               // Gap used for computing rounded corners
const R_corner = pipeRadius + gap; // Effective rounding radius

// Morph phase timing and sequence:
const T_morph = 2;  // Seconds per morph phase (adjustable between 2–3 sec)

// The sequence: from 3 sides (equilateral) up to 4 sides and back down.
const sideSequence = [3, 4, 5, 6, 7, 6, 5, 4, 3];
const totalTransitions = sideSequence.length - 1;
const totalCycleTime = T_morph * totalTransitions;

// Colors:
const fillColor = "#E6E6FA";   // Lavender fill for the flexagon
const nodeColor = "#FFFFFF";   // Solid white nodes

function vec(x, y) { return { x, y }; }

function add(a, b) { return { x: a.x + b.x, y: a.y + b.y }; }

function sub(a, b) { return { x: a.x - b.x, y: a.y - b.y }; }

function scale(v, s) { return { x: v.x * s, y: v.y * s }; }

function length(v) { return Math.hypot(v.x, v.y); }

function normalize(v) {
    const len = length(v);
    return len < 0.0001 ? { x: 0, y: 0 } : { x: v.x / len, y: v.y / len };
}

function dot(a, b) { return a.x * b.x + a.y * b.y; }

function angleOf(v) { return Math.atan2(v.y, v.x); }

function cot(a) { return Math.cos(a) / Math.sin(a); }

// Returns the difference between two angles in [0,2π)
function angleDiff(a, b) {
    let diff = b - a;
    while (diff < 0) diff += 2 * Math.PI;
    while (diff >= 2 * Math.PI) diff -= 2 * Math.PI;
    return diff;
}

// Linear interpolation between two points.
function lerp(a, b, u) {
    return { x: a.x + (b.x - a.x) * u, y: a.y + (b.y - a.y) * u };
}

// Ease in/out for smooth overall morph transitions.
function easeInOut(u) {
    return u < 0.5 ? 2 * u * u : -1 + (4 - 2 * u) * u;
}

// Remove nearly duplicate consecutive vertices (for cleaning merged nodes).
function removeDuplicateVertices(verts, tol = 0.5) {
    if (verts.length === 0) return verts;
    let cleaned = [verts[0]];
    for (let i = 1; i < verts.length; i++) {
        if (length(sub(verts[i], cleaned[cleaned.length - 1])) > tol) {
            cleaned.push(verts[i]);
        }
    }
    if (cleaned.length > 1 && length(sub(cleaned[0], cleaned[cleaned.length - 1])) < tol) {
        cleaned.pop();
    }
    return cleaned;
}

class Flexagon extends Component {
    canvasRef: any = null;
    animationFrameId: number = null;

    state = {
        // For polygons with >3 sides, we use fixed multipliers (here set to 1 for a rigid shape).
        irregularMultipliers: {}
    }

    constructor(props) {
        super(props);

        this.canvasRef = createRef();
    }

    componentDidMount() {
        this.resize();
        window.addEventListener("resize", this.resize);
        this.animate(0);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        cancelAnimationFrame(this.animationFrameId);
    }

    resize = () => {
        const canvas = this.canvasRef.current;
        const parent = canvas.parentElement;

        if (canvas) {
            canvas.width = parent.clientWidth;
            canvas.height = parent.clientHeight;
        }
    };

    animate = (timeStamp) => {
        const canvas = this.canvasRef.current;

        // race condition, when this function gets called after canvasRef has been removed from the DOM
        if (!canvas) {
            return;
        }

        const ctx = canvas.getContext("2d");

        const time = timeStamp / 1000;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Overall rotation and base parameters.
        const overallRotation = time * rotationSpeed;
        const cx = canvas.width / 2;
        const cy = canvas.height / 2;
        const baseRadius = Math.min(canvas.width, canvas.height) * 0.3;

        // Determine progress within the current morph phase.
        const tCycle = time % totalCycleTime;
        const transitionIndex = Math.floor(tCycle / T_morph);
        let u = (tCycle - transitionIndex * T_morph) / T_morph;
        u = easeInOut(u);

        const startSides = sideSequence[transitionIndex];
        const endSides = sideSequence[transitionIndex + 1];
        const morphPolygon = this.buildMorphPolygon(startSides, endSides, cx, cy, baseRadius, overallRotation, u);

        this.drawFlexagon(morphPolygon);
        requestAnimationFrame(this.animate);
    }

    getIrregularMultipliers = (sides) => {
        const { irregularMultipliers } = this.state;

        if (sides === 3) return [1, 1, 1];

        if (irregularMultipliers[sides]) return irregularMultipliers[sides];

        let arr = [];
        for (let i = 0; i < sides; i++) {
            // Variation between 0.85 and 1.15; here we use 1 for rigid behavior.
            arr.push(1);
        }

        irregularMultipliers[sides] = arr;
        return arr;
    }

    // ===== Generate Polygon Vertices =====
    // For 3 sides, return an equilateral triangle.
    // For >3 sides, use the fixed multipliers.
    getPolygonVertices = (sides, cx, cy, baseRadius, overallRotation) => {
        let verts = [];

        if (sides === 3) {
            for (let i = 0; i < sides; i++) {
                let angle = overallRotation + (2 * Math.PI * i / sides) - Math.PI / 2;
                verts.push(vec(cx + baseRadius * Math.cos(angle),
                    cy + baseRadius * Math.sin(angle)));
            }
        } else {
            let multipliers = this.getIrregularMultipliers(sides);

            for (let i = 0; i < sides; i++) {
                let angle = overallRotation + (2 * Math.PI * i / sides) - Math.PI / 2;
                let r = baseRadius * multipliers[i];
                verts.push(vec(cx + r * Math.cos(angle),
                    cy + r * Math.sin(angle)));
            }
        }
        return verts;
    }

    // ===== Build a Morphing Polygon =====
    // In the growing case (n → n+1), the new node emerges from the stable node.
    // In the shrinking case (n → n-1), the disappearing node moves toward the stable node.
    buildMorphPolygon = (startSides, endSides, cx, cy, baseRadius, overallRotation, u) => {
        let morphVerts = [];
        if (endSides > startSides) {
            // GROWING: from n to n+1 vertices.
            const v = this.getPolygonVertices(startSides, cx, cy, baseRadius, overallRotation);
            const w = this.getPolygonVertices(startSides + 1, cx, cy, baseRadius, overallRotation);
            // Ensure continuity: the splitting node remains the same.
            w[0] = { x: v[0].x, y: v[0].y };
            morphVerts.push(v[0]);
            if (u < 0.5) {
                morphVerts.push(v[0]);
            } else {
                let t = (u - 0.5) * 2; // remap [0.5,1] to [0,1]
                morphVerts.push(lerp(v[0], w[1], t));
            }
            for (let i = 1; i < v.length; i++) {
                morphVerts.push(lerp(v[i], w[i + 1], u));
            }
        } else if (endSides < startSides) {
            // SHRINKING: from n to n-1 vertices.
            const v = this.getPolygonVertices(startSides, cx, cy, baseRadius, overallRotation);
            const w = this.getPolygonVertices(startSides - 1, cx, cy, baseRadius, overallRotation);
            w[0] = { x: v[0].x, y: v[0].y };
            morphVerts.push(v[0]);
            if (u < 0.5) {
                let t = u * 2; // remap [0,0.5] to [0,1]
                morphVerts.push(lerp(v[1], v[0], t));
            } else {
                morphVerts.push(v[0]);
            }
            for (let i = 2; i < v.length; i++) {
                morphVerts.push(lerp(v[i], w[i - 1], u));
            }
        } else {
            morphVerts = this.getPolygonVertices(startSides, cx, cy, baseRadius, overallRotation);
        }
        return morphVerts;
    }

    // ===== Draw the Flexagon with Rounded Corners and Nodes =====
    drawFlexagon = (vertices) => {
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext("2d");

        vertices = removeDuplicateVertices(vertices);
        const n = vertices.length;
        const tangents = [];
        const eps = 0.001;

        for (let i = 0; i < n; i++) {
            const curr = vertices[i];
            const prev = vertices[(i - 1 + n) % n];
            const next = vertices[(i + 1) % n];
            const v1 = sub(curr, prev);
            const v2 = sub(next, curr);

            if (length(v1) < eps || length(v2) < eps) {
                tangents.push({ P_in: curr, P_out: curr, center: curr, theta: 0 });
                continue;
            }

            const e1 = normalize(v1);
            const e2 = normalize(v2);
            let theta = Math.acos(dot(scale(e1, -1), e2));

            if (Math.abs(theta) < eps) theta = eps;

            const d = R_corner * cot(theta / 2);
            const P_in = sub(curr, scale(e1, d));
            const P_out = add(curr, scale(e2, d));
            const bisector = normalize(add(scale(e1, -1), e2));
            const center = add(curr, scale(bisector, R_corner / Math.sin(theta / 2)));
            tangents.push({ P_in, P_out, center, theta });
        }

        ctx.beginPath();
        ctx.moveTo(tangents[0].P_out.x, tangents[0].P_out.y);

        for (let i = 0; i < n; i++) {
            const next = (i + 1) % n;
            ctx.lineTo(tangents[next].P_in.x, tangents[next].P_in.y);
            const { center, P_in, P_out, theta } = tangents[next];
            let startAngle = angleOf(sub(P_in, center));
            let endAngle = angleOf(sub(P_out, center));
            const expectedSweep = Math.PI - theta;
            let diff = angleDiff(startAngle, endAngle);
            let anticlockwise = false;

            if (Math.abs(diff - expectedSweep) > 0.001 && diff > Math.PI) {
                anticlockwise = true;
            }

            ctx.arc(center.x, center.y, R_corner, startAngle, endAngle, anticlockwise);
        }

        ctx.closePath();
        ctx.fillStyle = fillColor;
        ctx.fill();

        for (let i = 0; i < n; i++) {
            const { center } = tangents[i];
            ctx.beginPath();
            ctx.arc(center.x, center.y, pipeRadius, 0, 2 * Math.PI);
            ctx.fillStyle = nodeColor;
            ctx.fill();
        }
    }

    render() {
        return (
            <canvas className="adapter-flexagon" ref={this.canvasRef}></canvas>
        );
    }

}

export default Flexagon;