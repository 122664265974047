import { clearLocalStorage, getFromLocalStorage, StorageKeys } from "./LocalStorage";
import authStore from '../stores/AuthStore';
import { getUserTelemetry } from "./Telemetry";

export const API_ERRORS = {
  aborted: 'Request aborted',
  timedOut: 'Timed out'
}

const appendTelemetryHeaders = (headers = {}) => {
  const userTelemetry = getUserTelemetry();

  Object.assign(headers, {
    "Request-Timestamp": new Date().toString(),
    "Request-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    "Request-Telemetry": JSON.stringify(userTelemetry)
  });
  
  return headers;
}

// default timeouts on frontend: 30 seconds
function fetchWithTimeout(url, options, timeout = 120_000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(API_ERRORS.timedOut), timeout)
    )
  ]);
}

const criticalAPIError = () => {
  clearLocalStorage();
  window.location.href = "/";
} 
const handle401 = (response: any) => {
  if (response.status === 401 && !isLoginPage()) {
      criticalAPIError();
  }
  return response;
};

async function retryWithRefresh(url: string, fetchOptions: any, timeout = 120_000) {
  const response = await fetchWithTimeout(url, fetchOptions, timeout) as Response;

  if (response.status === 401) {
    try {
      // Use the authStore instance to refresh the token
      const refreshData = await authStore.refreshJwt();

      // Get the new token after refresh
      const newToken = await getFromLocalStorage(StorageKeys.JWT_ACCESS_TOKEN);

      // Update the Authorization header with the new token
      fetchOptions.headers.Authorization = `Bearer ${newToken}`;

      // Retry the original request with the new token, using half the original timeout
      // This ensures we don't exceed the original timeout when combining both attempts
      const remainingTimeout = Math.floor(timeout / 2);
      const retryResponse = await fetchWithTimeout(url, fetchOptions, remainingTimeout) as Response;
      // If we still get a 401 after refresh, then ...
      return handle401(retryResponse);
    } catch (refreshError) {
      console.error('Token refresh failed:', refreshError);
      criticalAPIError();
    }
  }
  return response;
}

const FETCH_DEFAULTS = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  mode: "cors", // no-cors, *cors, same-origin
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  credentials: "include", // include, *same-origin, omit
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

const isLoginPage = () => {
  const URL = window.location.pathname;
  return URL === '/login';
};

const enforce200 = (response: any, allowServerResponse = false) => {
  if (response.status === 200 || response.status === 204) {
    return response;
  }

  if (!allowServerResponse) {
    throw new Error('Bad API response, check logs.');
  }

  return response;
};

async function postNoAuth(url = "", data = {}, allowServerResponse = false, signal = undefined) {
  const fetchOptions = Object.assign({}, FETCH_DEFAULTS, {
    body: JSON.stringify(data),
    headers: appendTelemetryHeaders({ "Content-Type": "application/json" }),
    signal
  });

  return fetchWithTimeout(url, fetchOptions)
    .then(response => enforce200(response, allowServerResponse));
}

async function postData(url = "", data = {}, allowServerResponse = false, signal = undefined) {

  const token = await getFromLocalStorage(StorageKeys.JWT_ACCESS_TOKEN);

  const fetchOptions = Object.assign({}, FETCH_DEFAULTS, {
    body: JSON.stringify(data),
    headers: appendTelemetryHeaders({ 
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
    signal
  });

  return retryWithRefresh(url, fetchOptions)
    .then(response => enforce200(response, allowServerResponse));
}

async function putData(url = "", data = {}, signal = undefined) {
  const token = await getFromLocalStorage(StorageKeys.JWT_ACCESS_TOKEN);
  const fetchOptions = Object.assign({}, FETCH_DEFAULTS, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: appendTelemetryHeaders({ 
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
    signal
  });

  return retryWithRefresh(url, fetchOptions)
    .then(enforce200);
}

async function postFiles(url = "", data: any) {
  const token = await getFromLocalStorage(StorageKeys.JWT_ACCESS_TOKEN);
  const fetchOptions = Object.assign({}, FETCH_DEFAULTS, {
    method: "POST",
    body: data,
    headers: appendTelemetryHeaders({ 
      Authorization: `Bearer ${token}`,
    }),
  });

  return retryWithRefresh(url, fetchOptions)
    .then(enforce200);
}

async function getData(url = "", signal = undefined) {
  const token = await getFromLocalStorage(StorageKeys.JWT_ACCESS_TOKEN);
  const fetchOptions = Object.assign({}, FETCH_DEFAULTS, {
    method: "GET",
    headers: appendTelemetryHeaders({ 
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
    signal
  });

  return retryWithRefresh(url, fetchOptions)
    .then(enforce200);
}

async function patchData(url = "", data = {}, signal = undefined) {
  const token = await getFromLocalStorage(StorageKeys.JWT_ACCESS_TOKEN);
  const fetchOptions = Object.assign({}, FETCH_DEFAULTS, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: appendTelemetryHeaders({ 
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
    signal
  });

  return retryWithRefresh(url, fetchOptions)
    .then(enforce200);
}

const API = {
  get: getData,
  post: postData,
  patch: patchData,
  put: putData,
  postnoauth: postNoAuth,
  postFiles
};

export default API;
