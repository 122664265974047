import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { motion, AnimatePresence } from 'framer-motion';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { styled } from '@mui/material/styles';
import { useGraphContext } from '../../context/GraphContext';

// Import the theme from themeConfig for color consistency
import themeConfig from '../../config/themeConfig';

/**
 * Formats a number for display in a human-readable format:
 * - Numbers < 1,000: 123
 * - Numbers 1,000 - 9,999: 1,234
 * - Numbers 10,000 - 999,999: 12.3k
 * - Numbers 1,000,000+: 1.2M
 */
const formatNumber = (num: number): string => {
  if (num === 0) return '0';
  
  // For numbers under 10,000, use the comma format
  if (num < 10000) {
    return num.toLocaleString();
  }
  
  // For numbers from 10,000 to 999,999, use k format with 1 decimal
  if (num < 1000000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  
  // For millions, use M format with 1 decimal
  if (num < 1000000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  
  // For billions, use B format with 1 decimal
  return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
};

// Helper function to get harmonious theme colors
const getThemeColors = (isDarkMode: boolean) => {
  const theme = isDarkMode ? themeConfig.darkTheme : themeConfig.lightTheme;
  return {
    bg: theme.bgColor,
    card: theme.cardBgColor,
    text: theme.textColor,
    secondaryText: theme.secondaryTextColor,
    primary: theme.primaryColor,
    secondary: theme.secondaryColor,
    border: theme.borderColor,
    shadow: theme.shadowColor,
    accent: {
      success: theme.successColor,
      error: theme.errorColor,
      warning: theme.warningColor,
      info: theme.infoColor
    }
  };
};

// Glass panel with frosted aesthetic
const GlassPanel = styled(motion.div)<{ $isDarkMode: boolean, $isExpanded?: boolean, $isMouseOver?: boolean }>(
  ({ $isDarkMode, $isExpanded, $isMouseOver }) => {
  const colors = getThemeColors($isDarkMode);
  
  // Calculate opacity based on mouse hover state
  const baseOpacity = $isMouseOver ? 0.33 : 0.17;
  
  return {
    backdropFilter: 'blur(10px)',
    background: $isDarkMode 
      ? `linear-gradient(135deg, rgba(22, 40, 80, ${baseOpacity + 0.08}) 0%, rgba(16, 30, 60, ${baseOpacity + 0.04}) 100%)` // Subtle gradient
      : `linear-gradient(135deg, rgba(30, 50, 90, ${baseOpacity}) 0%, rgba(20, 40, 80, ${baseOpacity - 0.02}) 100%)`,
    borderRadius: '20px', // Slightly more rounded
    // Enhanced blue glow
    boxShadow: $isDarkMode
      ? `0 10px 30px rgba(0, 0, 0, 0.25), 0 0 3px rgba(140, 200, 255, ${baseOpacity * 0.6}), inset 0 1px 1px rgba(255, 255, 255, 0.07)` 
      : `0 10px 30px rgba(8, 16, 40, 0.2), 0 0 3px rgba(140, 210, 255, ${baseOpacity * 0.9}), inset 0 1px 1px rgba(255, 255, 255, 0.1)`,
    // Using very translucent border with blue tint that blends with the panel
    border: `1px solid ${$isDarkMode 
      ? `rgba(75, 120, 200, ${baseOpacity + 0.15})` 
      : `rgba(100, 150, 255, ${baseOpacity + 0.1})`}`,
    padding: '20px', // Consistent padding all around
    overflow: 'visible', // Always allow content to be visible outside the container
    zIndex: 100,
    
    // Fullscreen styles
    '&.fullscreen': {
      maxWidth: '90%',
      height: '75vh !important',
      borderRadius: '20px',
      top: '50%',
      left: '50%',
      bottom: 'auto',
      transform: 'translate(-50%, -50%) !important',
      position: 'fixed',
      // Using same blue-tinted border as non-fullscreen mode
      border: `1px solid ${$isDarkMode 
        ? `rgba(80, 130, 220, ${baseOpacity + 0.2})` 
        : `rgba(110, 160, 255, ${baseOpacity + 0.15})`}`,
      overflowY: 'hidden', // Change to hidden to use inner container's scrolling
      display: 'flex',
      flexDirection: 'column',
      padding: '20px', // Keep consistent padding
      // Enhanced blue glow for fullscreen mode
      boxShadow: $isDarkMode
        ? `0 10px 50px rgba(10, 20, 40, 0.3), 0 0 3px 1px rgba(120, 180, 255, ${baseOpacity * 0.6})` 
        : `0 10px 50px rgba(10, 20, 50, 0.25), 0 0 3px 1px rgba(130, 200, 255, ${baseOpacity + 0.3})`,
    }
  };
});

// Header with separator
const Header = styled(Box)<{ $isDarkMode: boolean }>(({ $isDarkMode }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    cursor: 'pointer',
    position: 'relative',
    padding: '20px',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '-8px',
      left: '0',
      width: '100%',
      height: '1px',
      background: 'linear-gradient(to right, transparent, rgba(120, 180, 255, 0.4), transparent)',
    }
  };
});

// Resize handle 
const ResizeHandle = styled(Box)<{ $isDarkMode: boolean }>(({ $isDarkMode }) => {
  return {
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '70px',
    height: '4px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, transparent, rgba(120, 180, 255, 0.4), transparent)',
    backdropFilter: 'blur(4px)',
    cursor: 'ns-resize',
    transition: 'all 0.2s ease-out',
    boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      width: '80px', // Expand slightly on hover
      background: 'linear-gradient(to right, transparent, rgba(140, 200, 255, 0.6), transparent)',
      boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 3px rgba(120, 180, 255, 0.3)',
    }
  };
});

// Control buttons container
const Controls = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

// Stat card for metrics with active state - more translucent style
const StatCard = styled(Box)<{ $isDarkMode: boolean, $active?: boolean }>(({ $isDarkMode, $active }) => {
  const colors = getThemeColors($isDarkMode);
  const activeBgColor = $isDarkMode 
    ? 'linear-gradient(135deg, rgba(75, 145, 255, 0.3) 0%, rgba(60, 120, 240, 0.25) 100%)' // Gradient highlight
    : 'linear-gradient(135deg, rgba(70, 140, 250, 0.28) 0%, rgba(55, 115, 230, 0.22) 100%)';
  
  const hoverActiveBgColor = $isDarkMode 
    ? 'linear-gradient(135deg, rgba(85, 155, 255, 0.38) 0%, rgba(70, 130, 245, 0.32) 100%)' 
    : 'linear-gradient(135deg, rgba(80, 150, 255, 0.35) 0%, rgba(65, 125, 240, 0.3) 100%)';
    
  const normalBgColor = $isDarkMode 
    ? 'linear-gradient(135deg, rgba(35, 65, 130, 0.32) 0%, rgba(25, 55, 110, 0.28) 100%)' // Gradient background
    : 'linear-gradient(135deg, rgba(45, 75, 150, 0.3) 0%, rgba(35, 65, 130, 0.26) 100%)';
    
  const hoverBgColor = $isDarkMode 
    ? 'linear-gradient(135deg, rgba(45, 75, 150, 0.42) 0%, rgba(35, 65, 130, 0.38) 100%)'
    : 'linear-gradient(135deg, rgba(55, 85, 170, 0.4) 0%, rgba(45, 75, 150, 0.36) 100%)';
  
  const isActive = $active === true;
  
  return {
    padding: '20px',
    borderRadius: '16px', // More rounded for elegance
    backdropFilter: 'blur(10px)', // Enhanced blur
    background: isActive ? activeBgColor : normalBgColor, // Using gradients
    flex: '1 1 auto',
    minWidth: '150px',
    transition: 'all 0.4s cubic-bezier(0.2, 0.8, 0.2, 1)', // Smoother animation
    border: `1px solid ${isActive 
      ? 'rgba(120, 180, 255, 0.35)' 
      : 'rgba(100, 160, 255, 0.2)'}`, // Refined border
    position: 'relative',
    overflow: 'hidden',
    boxShadow: `0 8px 20px rgba(0, 0, 0, 0.1), inset 0 1px 1px rgba(255, 255, 255, 0.07)`, // Subtle inner highlight
    '&:hover': {
      transform: 'translateY(-3px) scale(1.02)', // Slight scale for more depth
      boxShadow: `0 10px 25px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(255, 255, 255, 0.1)`, // Enhanced shadow
      background: isActive ? hoverActiveBgColor : hoverBgColor,
    },
    ...(isActive ? {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '4px',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(140, 200, 255, 0.95), rgba(100, 170, 255, 0.8))',
        boxShadow: '0 0 15px rgba(120, 190, 255, 0.8)', // Enhanced blue glow
      },
      // Add a subtle top highlight
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '1px',
        background: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.15), transparent)',
      }
    } : {})
  };
});

// Feature tags with frosted glass effect
const FeatureTag = styled(Box)<{ $isDarkMode: boolean, color: string }>(({ $isDarkMode, color }) => {
  // Create a more translucent version of the color
  const getTranslucentColor = () => {
    // Extract RGB values and reduce opacity
    const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/);
    if (match) {
      const r = match[1];
      const g = match[2];
      const b = match[3];
      // Use 0.75 opacity for softer look
      return `rgba(${r}, ${g}, ${b}, 0.85)`;
    }
    return color;
  };

  const colorValue = getTranslucentColor();
  const gradientColor1 = colorValue.replace(/[\d.]+\)$/, '0.95)');
  const gradientColor2 = colorValue.replace(/[\d.]+\)$/, '0.85)');
  const shadowColor1 = colorValue.replace(/[\d.]+\)$/, '0.35)');
  const shadowColor2 = colorValue.replace(/[\d.]+\)$/, '0.45)');
  const shadowColor3 = colorValue.replace(/[\d.]+\)$/, '0.25)');

  return {
    padding: '8px', // More comfortable padding
    borderRadius: '30px', // More rounded for pill appearance
    backdropFilter: 'blur(8px)', // Enhanced blur
    background: `linear-gradient(to bottom, ${gradientColor1}, ${gradientColor2})`,
    color: 'white',
    fontSize: '0.85rem', // Slightly larger for better readability
    fontWeight: 600, // Bolder weight for better visibility
    letterSpacing: '0.01em', // Reduced letter spacing
    cursor: 'pointer',
    margin: '0px',
    boxShadow: `0 4px 8px ${shadowColor1}, inset 0 1px 1px rgba(255, 255, 255, 0.2)`,
    transition: 'all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1)', // Smoother animation
    border: '1px solid rgba(255, 255, 255, 0.25)', // Enhanced border
    textShadow: '0 1px 1px rgba(0, 0, 0, 0.2)', // Subtle text shadow for better contrast
    // Use translate only, no scale, to prevent overflow
    '&:hover': {
      transform: 'translateY(-2px)', // Reduced hover rise
      boxShadow: `0 6px 12px ${shadowColor2}, inset 0 1px 2px rgba(255, 255, 255, 0.3)`,
      filter: 'brightness(110%)',
    },
    '&:active': {
      transform: 'translateY(1px)', // Press effect without scale
      filter: 'brightness(95%)',
      boxShadow: `0 2px 6px ${shadowColor3}, inset 0 1px 1px rgba(255, 255, 255, 0.15)`,
    }
  };
});

// Styled component for the streaming summary text panel
const SummaryPanel = styled(Box)<{ $isDarkMode: boolean }>(({ $isDarkMode }) => {
  return {
    backdropFilter: 'blur(10px)',
    background: $isDarkMode 
      ? 'linear-gradient(135deg, rgba(45, 85, 155, 0.4) 0%, rgba(35, 75, 145, 0.35) 100%)'
      : 'linear-gradient(135deg, rgba(50, 90, 160, 0.38) 0%, rgba(40, 80, 150, 0.32) 100%)',
    padding: '18px 22px',
    borderRadius: '18px',
    border: '1px solid rgba(120, 180, 255, 0.3)',
    boxShadow: '0 10px 25px rgba(0, 0, 0, 0.18), 0 0 3px rgba(140, 210, 255, 0.3), inset 0 1px 1px rgba(255, 255, 255, 0.08)',
    marginBottom: '16px',
    position: 'relative',
    overflow: 'hidden',
    transition: 'all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1)',
    // Top light border effect
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '1px',
      background: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.3), transparent)',
    },
    // Left accent line
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '4px',
      height: '100%',
      background: 'linear-gradient(to bottom, rgba(94, 162, 255, 0.95), rgba(59, 125, 216, 0.8))',
      boxShadow: '0 0 15px rgba(94, 162, 255, 0.8)',
    }
  };
});

interface BottomPanelProps {
  humanSummary?: string;
  nodeCount?: number;
  edgeCount?: number;
  communityCount?: number;
}

const BottomPanel: React.FC<BottomPanelProps> = ({ 
  humanSummary,
  nodeCount: externalNodeCount,
  edgeCount: externalEdgeCount,
  communityCount: externalCommunityCount
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const themeColors = getThemeColors(isDarkMode);
  // Only start expanded if we have a human summary to show
  const [isExpanded, setIsExpanded] = useState(Boolean(humanSummary && humanSummary.length > 0));
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [displayedSummary, setDisplayedSummary] = useState(humanSummary || '');
  const [isStreamingText, setIsStreamingText] = useState(false);
  const [hasStreamedSummary, setHasStreamedSummary] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const streamingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const lastSummaryRef = useRef<string | undefined>();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { nodes, edges, communities, isLoading } = useGraphContext();

  // Calculate actual data based on context (always reflects current graph state)
  // Priority: 1. External counts (from search results), 2. Context data
  const nodeCount = externalNodeCount ?? nodes?.length ?? 0;
  const edgeCount = externalEdgeCount ?? edges?.length ?? 0;
  const clusterCount = externalCommunityCount ?? (communities
    ? new Set(Object.values(communities)).size 
    : 0);
    
  // Check if graph data has loaded - we just need to ensure we have nodes
  const hasGraphData = nodeCount > 0;
  
  // Track if we've completed streaming the summary
  const userCollapsedRef = useRef<boolean>(false);

  // Text streaming effect for the human summary - only runs when humanSummary changes
  useEffect(() => {
    // Track if the component is mounted to prevent state updates after unmount
    let isMounted = true;
    
    // Clear any existing streaming timer
    if (streamingTimerRef.current) {
      clearTimeout(streamingTimerRef.current);
      streamingTimerRef.current = null;
    }
    
    // If we have a new human summary, start streaming it
    if (humanSummary && humanSummary.length > 0) {
      // Store the current summary
      lastSummaryRef.current = humanSummary;
      
      // Check if the summary is different from what we already have displayed
      const isNewSummary = humanSummary !== displayedSummary;
      
      // Only auto-expand if:
      // 1. It's not already expanded AND
      // 2. Either the force flag is set OR we have valid search results
      // 3. AND the user hasn't manually collapsed the panel
      if ((!isExpanded && nodeCount > 0 && isNewSummary && !userCollapsedRef.current) || 
          ((window as any).__forceExpandPanel === true)) {
        
        console.log("Expanding bottom panel - forceExpand:", Boolean((window as any).__forceExpandPanel));
        
        // Reset the flag after using it
        (window as any).__forceExpandPanel = false;
        
        // Reset the user collapsed state since we're expanding
        userCollapsedRef.current = false;
        
        setIsExpanded(true);
      }
      
      // Reset the displayed text and start streaming from the beginning
      setDisplayedSummary('');
      setIsStreamingText(true);
      
      // Store the current summary to detect changes
      const currentSummary = humanSummary;
      
      // Use the full text directly instead of streaming character by character
      // which could have issues with special characters or encoding
      const simulateTyping = () => {
        // First show a small part of the text instantly
        const initialChunk = currentSummary.substring(0, Math.min(5, currentSummary.length));
        if (isMounted) setDisplayedSummary(initialChunk);
        
        // Then stream the rest with a typing effect
        let position = initialChunk.length;
        
        // Simulate typing with batched characters for performance
        const typeNextBatch = () => {
          if (position < currentSummary.length && isMounted) {
            // Add 2-3 characters at a time for a more natural typing feel
            const charsToAdd = Math.min(2 + Math.floor(Math.random() * 2), currentSummary.length - position);
            const nextChunk = currentSummary.substring(position, position + charsToAdd);
            setDisplayedSummary(current => current + nextChunk);
            
            position += charsToAdd;
            
            // Random typing speed variation (30-60ms) for natural feel
            const typingDelay = 30 + Math.floor(Math.random() * 30);
            streamingTimerRef.current = setTimeout(typeNextBatch, typingDelay);
          } else {
            // Finished typing
            if (isMounted) {
              setIsStreamingText(false);
              setHasStreamedSummary(true);
            }
            streamingTimerRef.current = null;
          }
        };
        
        // Start typing the remaining text after a short delay
        streamingTimerRef.current = setTimeout(typeNextBatch, 100);
      };
      
      // Start the streaming effect with a small delay
      streamingTimerRef.current = setTimeout(simulateTyping, 300);
    }
    
    // Clean up on unmount or when dependencies change
    return () => {
      isMounted = false;
      if (streamingTimerRef.current) {
        clearTimeout(streamingTimerRef.current);
        streamingTimerRef.current = null;
      }
    };
  }, [humanSummary, isExpanded]);
  
  // Handle re-displaying the complete summary when the panel is expanded after being collapsed
  useEffect(() => {
    // If the panel is expanded and we have a summary that's stored in our ref,
    // but the displayed summary is empty (panel was closed), show it instantly
    if (isExpanded && lastSummaryRef.current && !displayedSummary && !isStreamingText) {
      setDisplayedSummary(lastSummaryRef.current);
    } else if (!isExpanded) {
      // Clear the displayed summary when panel collapses
      setDisplayedSummary('');
    }
  }, [isExpanded, displayedSummary, isStreamingText]);

  // Auto collapse panel after inactivity (only when not in fullscreen)
  useEffect(() => {
    // Clear any existing timers when dependencies change
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    
    // Only set up auto-collapse when panel is expanded, not being interacted with,
    // not in fullscreen mode, not currently in a transition, and not streaming text
    if (isExpanded && !isMouseOver && !isFullscreen && !isTransitioning && !isStreamingText) {
      // Use a longer timeout (20 seconds) when displaying voice summary
      const timeoutDuration = displayedSummary ? 45000 : 4000;
      
      timerRef.current = setTimeout(() => {
        setIsTransitioning(true);
        
        // Use requestAnimationFrame for smoother transition timing
        requestAnimationFrame(() => {
          // This is an auto-collapse, not a user action
          // We don't set userCollapsedRef here because this is automatic,
          // not a user preference to keep it collapsed
          setIsExpanded(false);
          
          // Reset transition state after animation completes with a delay that matches our animation duration
          setTimeout(() => {
            setIsTransitioning(false);
          }, 1000); // Slightly longer than our animation duration to ensure completion
        });
      }, timeoutDuration);
    }

    // Cleanup function
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [isExpanded, isMouseOver, isFullscreen, isTransitioning, isStreamingText, displayedSummary]);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
    if (!isExpanded && !isTransitioning) {
      setIsExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const toggleExpand = () => {
    if (isTransitioning) return;
    
    setIsTransitioning(true);
    
    // Handle exit from fullscreen mode properly when collapsing
    if (isFullscreen && isExpanded) {
      // First exit fullscreen mode, then collapse in next step
      setIsFullscreen(false);
      
      // Schedule collapse after fullscreen exit animation completes
      setTimeout(() => {
        // Track that this was a user-initiated collapse
        if (isExpanded) {
          userCollapsedRef.current = true;
          console.log("User manually collapsed panel - will remember this preference");
        }
        
        setIsExpanded(false);
        
        // Reset transition state after all animations complete
        setTimeout(() => {
          setIsTransitioning(false);
        }, 600);
      }, 300);
    } else {
      // Normal expand/collapse when not in fullscreen
      // Use requestAnimationFrame for smoother state transitions
      requestAnimationFrame(() => {
        // If we're going from expanded to collapsed, track user preference
        if (isExpanded) {
          userCollapsedRef.current = true;
          console.log("User manually collapsed panel - will remember this preference");
        } else {
          // User is expanding manually, so clear the collapsed flag
          userCollapsedRef.current = false;
        }
        
        // Animation is controlled by Framer Motion
        setIsExpanded(!isExpanded);
        
        // Reset transition state after animation completes
        setTimeout(() => {
          setIsTransitioning(false);
        }, 900); // Slightly longer than animation duration
      });
    }
  };

  const toggleFullscreen = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isTransitioning) return;
    
    setIsTransitioning(true);
    
    // Use requestAnimationFrame for smoother transitions
    requestAnimationFrame(() => {
      // Toggle fullscreen state
      setIsFullscreen(!isFullscreen);
      
      // Ensure panel is expanded when entering fullscreen
      if (!isFullscreen) {
        setIsExpanded(true);
      }
      
      // Reset transition state after animation completes
      setTimeout(() => {
        setIsTransitioning(false);
      }, 900); // Slightly longer than animation duration
    });
  };
  
  // Feature data with harmonious color palette
  const features = [
    { name: 'Expand Context', color: isDarkMode ? 'rgba(255, 107, 112, 0.9)' : 'rgba(230, 69, 83, 0.9)' }, // error color
    { name: 'Temporal View', color: isDarkMode ? 'rgba(94, 162, 255, 0.9)' : 'rgba(59, 125, 216, 0.9)' },     // info color
    { name: 'Dive Deeper', color: isDarkMode ? 'rgba(52, 211, 153, 0.9)' : 'rgba(13, 166, 120, 0.9)' },  // success color
    { name: 'Relationship Overview', color: isDarkMode ? 'rgba(167, 139, 255, 0.9)' : 'rgba(124, 100, 230, 0.9)' }, // primary color
    { name: 'Anomaly Detection', color: isDarkMode ? 'rgba(251, 191, 36, 0.9)' : 'rgba(240, 162, 2, 0.9)' },  // warning color
  ];

  // Don't render anything if there's no graph data or if there's no human summary and no search results
  if (!hasGraphData || (!humanSummary && nodeCount === 0 && edgeCount === 0)) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ 
        duration: 0.8, 
        ease: [0.16, 1, 0.3, 1],
        delay: 0.5 // Wait a bit after data loads before showing panel
      }}
      onAnimationComplete={() => {
        // Reset transition state after entry animation completes
        setIsTransitioning(false);
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          bottom: '25px', // Space from bottom of screen
          left: '50%',
          transform: 'translateX(-50%)',
          width: isMobile ? 'calc(100% - 32px)' : 'auto',
          padding: '20px',
          zIndex: 100,
          overflow: 'visible', // Allow content to be visible
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // Add padding to ensure there's room for hover effects
          paddingLeft: '10px',
          paddingRight: '10px'
        }}
      >
        <AnimatePresence>
          <motion.div
            key="morphing-panel"
            layout
            initial={false}
            animate={{
              width: isExpanded ? (isMobile ? 'calc(100% - 32px)' : '760px') : (isMobile ? '90%' : '430px'),
              height: isExpanded ? (isFullscreen ? 'auto' : '350px') : '48px',
              borderRadius: isExpanded ? '20px' : '32px',
              y: 0,
              opacity: 1
            }}
            transition={{
              width: { duration: 0.8, ease: [0.16, 1, 0.3, 1] },
              height: { duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] },
              borderRadius: { duration: 0.6, ease: [0.16, 1, 0.3, 1] },
              layout: { duration: 0.8, ease: [0.16, 1, 0.3, 1] }
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              background: 'linear-gradient(135deg, rgba(30, 50, 90, 0.0) 0%, rgba(20, 40, 80, 0.0) 100%)',
              backdropFilter: 'blur(10px)',
              overflow: 'hidden',
              cursor: isExpanded ? 'default' : 'pointer'
            }}
            onMouseEnter={!isExpanded ? handleMouseEnter : undefined}
            onClick={!isExpanded ? toggleExpand : undefined}
            onMouseLeave={isExpanded ? handleMouseLeave : undefined}
            whileHover={!isExpanded ? { 
              scale: 1.03,
              boxShadow: '0 10px 25px rgba(0, 0, 0, 0.25), 0 0 5px rgba(140, 200, 255, 0.6)'
            } : undefined}
          >
            {isExpanded ? (
              // Expanded Panel Content
              <>
                <ResizeHandle $isDarkMode={isDarkMode} />
                
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ 
                    duration: 0.4, 
                    delay: 0.2,
                    ease: "easeOut" 
                  }}
                  layout
                >
                  <Header $isDarkMode={isDarkMode} onClick={(e) => {
                    // Handle header click differently based on current state
                    if (isFullscreen) {
                      // If in fullscreen, exit fullscreen first
                      setIsTransitioning(true);
                      setIsFullscreen(false);
                      
                      // Track that this was a user-initiated collapse
                      userCollapsedRef.current = true;
                      
                      // After exiting fullscreen, collapse
                      setTimeout(() => {
                        setIsExpanded(false);
                        setTimeout(() => {
                          setIsTransitioning(false);
                        }, 600);
                      }, 300);
                    } else {
                      // Normal toggle behavior
                      toggleExpand();
                    }
                  }}>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        fontSize: '1.125rem',
                        fontWeight: 600,
                        color: 'rgba(255, 255, 255, 0.95)',
                        letterSpacing: '-0.01em',
                        fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                      }}
                    >
                      Adaptive Insights
                    </Typography>
                    
                    <Controls>
                      <IconButton 
                        size="small" 
                        onClick={toggleFullscreen}
                        sx={{ 
                          background: 'linear-gradient(135deg, rgba(60, 100, 180, 0.35) 0%, rgba(50, 90, 160, 0.3) 100%)',
                          backdropFilter: 'blur(8px)',
                          width: '36px',
                          height: '36px',
                          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(255, 255, 255, 0.1)',
                          border: '1px solid rgba(100, 160, 255, 0.25)',
                          color: 'rgba(210, 230, 255, 0.9)',
                          transition: 'all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1)',
                          '&:hover': {
                            background: 'linear-gradient(135deg, rgba(70, 120, 200, 0.45) 0%, rgba(60, 100, 180, 0.4) 100%)',
                            transform: 'translateY(-2px)',
                            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2), inset 0 1px 2px rgba(255, 255, 255, 0.15)',
                            color: 'rgba(255, 255, 255, 1)',
                          }
                        }}
                      >
                        {isFullscreen ? <FullscreenExitIcon fontSize="small" /> : <FullscreenIcon fontSize="small" />}
                      </IconButton>
                      
                      <IconButton 
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          // If we're in fullscreen mode, exit fullscreen first
                          if (isFullscreen) {
                            // First exit fullscreen, then collapse
                            setIsTransitioning(true);
                            setIsFullscreen(false);
                            
                            // Track that this was a user-initiated collapse
                            userCollapsedRef.current = true;
                            
                            // After a short delay to allow fullscreen transition to start,
                            // trigger the collapse animation
                            setTimeout(() => {
                              setIsExpanded(false);
                              // Reset transition state after all animations complete
                              setTimeout(() => {
                                setIsTransitioning(false);
                              }, 600);
                            }, 300);
                          } else {
                            // Normal collapse behavior
                            toggleExpand();
                          }
                        }}
                        sx={{ 
                          background: 'linear-gradient(135deg, rgba(60, 100, 180, 0.35) 0%, rgba(50, 90, 160, 0.3) 100%)',
                          backdropFilter: 'blur(8px)',
                          width: '36px',
                          height: '36px',
                          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(255, 255, 255, 0.1)',
                          border: '1px solid rgba(100, 160, 255, 0.25)',
                          color: 'rgba(210, 230, 255, 0.9)',
                          transition: 'all 0.3s cubic-bezier(0.2, 0.8, 0.2, 1)',
                          marginLeft: '6px',
                          '&:hover': {
                            background: 'linear-gradient(135deg, rgba(70, 120, 200, 0.45) 0%, rgba(60, 100, 180, 0.4) 100%)',
                            transform: 'translateY(-2px)',
                            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2), inset 0 1px 2px rgba(255, 255, 255, 0.15)',
                            color: 'rgba(255, 255, 255, 1)',
                          }
                        }}
                      >
                        <ExpandMoreIcon fontSize="small" />
                      </IconButton>
                    </Controls>
                  </Header>
                </motion.div>
              
                <Box 
                  sx={{ 
                    position: 'relative',
                    minHeight: '120px', 
                    maxHeight: isFullscreen ? 'calc(75vh - 120px)' : '300px', 
                    mt: 2,
                    paddingBottom: '20px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    padding: '0 20px',
                    scrollbarWidth: 'thin',
                    scrollbarColor: 'rgba(120, 180, 255, 0.3) rgba(50, 90, 160, 0.1)',
                    '&::-webkit-scrollbar': {
                      width: '7px',
                      backgroundColor: 'rgba(50, 90, 160, 0.1)',
                      borderRadius: '10px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgba(120, 180, 255, 0.3)',
                      borderRadius: '10px',
                      '&:hover': {
                        backgroundColor: 'rgba(120, 180, 255, 0.5)'
                      }
                    },
                    // Fade-out effect at the bottom to indicate scrollable content
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: '30px',
                      pointerEvents: 'none' // Allow clicking through the gradient
                    }
                  }}>
                  {/* Human Summary Streaming Panel - show when we have summary text, now placed FIRST */}
                  {displayedSummary && (
                    <motion.div
                      initial={{ opacity: 0, y: 15 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, ease: [0.16, 1, 0.3, 1] }}
                      style={{ marginBottom: '24px' }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                        <Typography
                          sx={{ 
                            color: 'rgba(255, 255, 255, 0.95)',
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                          }}
                        >
                          <VolumeUpIcon sx={{ opacity: 0.8, fontSize: '1rem' }} />
                         Results Summary
                        </Typography>
                        
                        {isStreamingText && (
                          <Box 
                            sx={{ 
                              display: 'inline-flex',
                              alignItems: 'center',
                              borderRadius: '12px',
                              padding: '4px 10px',
                              fontSize: '0.7rem',
                              color: 'rgba(210, 230, 255, 0.9)',
                              fontWeight: 500,
                              letterSpacing: '0.03em',
                              border: '1px solid rgba(94, 162, 255, 0.3)',
                              boxShadow: '0 2px 4px rgba(0, 20, 40, 0.1)'
                            }}
                          >
                            Streaming...
                          </Box>
                        )}
                      </Box>
                      
                      <SummaryPanel $isDarkMode={isDarkMode}>
                        <Typography
                          sx={{ 
                            fontSize: '0.9rem',
                            lineHeight: 1.7,
                            color: 'rgba(255, 255, 255, 0.9)',
                            fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                            letterSpacing: '0.01em',
                            marginLeft: '10px', // Account for the left accent line
                            position: 'relative',
                            '&::after': isStreamingText ? {
                              content: '""',
                              display: 'inline-block',
                              width: '3px',
                              height: '1.1em',
                              background: 'rgba(255, 255, 255, 0.85)',
                              verticalAlign: 'text-bottom',
                              marginLeft: '2px',
                              animation: 'blink 1s infinite',
                              '@keyframes blink': {
                                '0%, 49%': { opacity: 1 },
                                '50%, 100%': { opacity: 0 }
                              }
                            } : {}
                          }}
                        >
                          {displayedSummary}
                        </Typography>
                      </SummaryPanel>
                    </motion.div>
                  )}
                
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.1 }}
                  >
                    <Typography 
                      sx={{ 
                        color: 'rgba(210, 230, 255, 0.85)',
                        fontSize: '0.875rem',
                        marginBottom: '16px',
                        fontWeight: 500,
                        fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                        letterSpacing: '0.01em'
                      }}
                    >
                      Key Entities: Person, Organization, Location
                    </Typography>
                  </motion.div>
                  
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                  >
                    <Box sx={{ 
                      display: 'flex', 
                      gap: 3, 
                      flexWrap: 'wrap',
                      justifyContent: 'space-between' 
                    }}>
                      {/* Connections Card */}
                      <motion.div
                        initial={{ opacity: 0, y: 15 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.4, delay: 0.1 }}
                      >
                        <StatCard $isDarkMode={isDarkMode} $active={true}>
                          <Typography 
                            sx={{ 
                              color: 'rgba(180, 215, 255, 0.85)',
                              fontSize: '0.75rem',
                              fontWeight: 500,
                              textTransform: 'uppercase',
                              letterSpacing: '0.05em',
                              fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                            }}
                          >
                            Connections
                          </Typography>
                          <Typography 
                            sx={{ 
                              fontSize: '1.75rem', 
                              fontWeight: 600,
                              color: 'rgba(255, 255, 255, 0.95)',
                              mt: 0.5,
                              fontVariantNumeric: 'tabular-nums',
                              fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                            }}
                          >
                            {formatNumber(edgeCount)}
                          </Typography>
                        </StatCard>
                      </motion.div>
                      
                      {/* Nodes Card */}
                      <motion.div
                        initial={{ opacity: 0, y: 15 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.4, delay: 0.2 }}
                      >
                        <StatCard $isDarkMode={isDarkMode} $active={false}>
                          <Typography 
                            sx={{ 
                              color: 'rgba(180, 215, 255, 0.85)',
                              fontSize: '0.75rem',
                              fontWeight: 500,
                              textTransform: 'uppercase',
                              letterSpacing: '0.05em',
                              fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                            }}
                          >
                            Nodes
                          </Typography>
                          <Typography 
                            sx={{ 
                              fontSize: '1.75rem', 
                              fontWeight: 600,
                              color: 'rgba(255, 255, 255, 0.95)',
                              mt: 0.5,
                              fontVariantNumeric: 'tabular-nums',
                              fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                            }}
                          >
                            {formatNumber(nodeCount)}
                          </Typography>
                        </StatCard>
                      </motion.div>
                      
                      {/* Communities Card */}
                      <motion.div
                        initial={{ opacity: 0, y: 15 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.4, delay: 0.3 }}
                      >
                        <StatCard $isDarkMode={isDarkMode} $active={false}>
                          <Typography 
                            sx={{ 
                              color: 'rgba(180, 215, 255, 0.85)',
                              fontSize: '0.75rem',
                              fontWeight: 500,
                              textTransform: 'uppercase',
                              letterSpacing: '0.05em',
                              fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                            }}
                          >
                            Communities
                          </Typography>
                          <Typography 
                            sx={{ 
                              fontSize: '1.75rem', 
                              fontWeight: 600,
                              color: 'rgba(255, 255, 255, 0.95)',
                              mt: 0.5,
                              fontVariantNumeric: 'tabular-nums',
                              fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                            }}
                          >
                            {formatNumber(clusterCount)}
                          </Typography>
                        </StatCard>
                      </motion.div>
                    </Box>
                  </motion.div>
                
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4, delay: 0.4 }}
                  >
                    <Box sx={{ mt: 3 }}>
                      <Typography 
                        sx={{ 
                          color: 'rgba(255, 255, 255, 0.95)',
                          fontSize: '0.875rem',
                          fontWeight: 600,
                          marginBottom: '8px',
                          fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                        }}
                      >
                        Adapt Further
                      </Typography>
                      
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          flexWrap: 'wrap',
                          marginBottom: '8px',
                          width: '100%',
                          overflow: 'visible',
                          justifyContent: 'flex-start',
                          gap: '12px',
                          position: 'relative',
                          zIndex: 1,
                          marginTop: '4px'
                        }}
                      >
                        {features.map((feature, index) => (
                          <motion.div 
                            key={feature.name}
                            initial={{ opacity: 0, scale: 0.8, y: 10 }}
                            animate={{ opacity: 1, scale: 1, y: 0 }}
                            transition={{ 
                              duration: 0.4, 
                              delay: 0.4 + (index * 0.05),
                              type: "spring",
                              stiffness: 400,
                              damping: 15
                            }}
                            whileHover={{ 
                              scale: 1.05, 
                              y: -2,
                              transition: { duration: 0.2 }
                            }}
                            whileTap={{ scale: 0.95 }}
                          >
                            <FeatureTag 
                              $isDarkMode={isDarkMode} 
                              color={feature.color}
                            >
                              {feature.name}
                            </FeatureTag>
                          </motion.div>
                        ))}
                      </Box>
                    </Box>
                  </motion.div>
                  
                  {isFullscreen && (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: 0.5 }}
                    >
                      <Box sx={{ mt: 4 }}>
                        <Typography 
                          sx={{ 
                            color: 'rgba(255, 255, 255, 0.95)',
                            fontSize: '0.875rem',
                            fontWeight: 600,
                            marginBottom: '16px',
                            fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                          }}
                        >
                          Insight Summary
                        </Typography>
                        
                        <Box 
                          sx={{ 
                            backdropFilter: 'blur(10px)',
                            background: 'linear-gradient(135deg, rgba(45, 85, 155, 0.35) 0%, rgba(35, 75, 145, 0.3) 100%)',
                            padding: '24px',
                            borderRadius: '18px',
                            border: '1px solid rgba(120, 180, 255, 0.25)',
                            boxShadow: '0 10px 25px rgba(0, 0, 0, 0.18), 0 0 3px rgba(140, 210, 255, 0.3), inset 0 1px 1px rgba(255, 255, 255, 0.08)',
                            position: 'relative',
                            overflow: 'hidden',
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              height: '1px',
                              background: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.2), transparent)',
                            }
                          }}
                        >
                          <Typography 
                            sx={{ 
                              fontSize: '0.9rem',
                              lineHeight: 1.7,
                              color: 'rgba(255, 255, 255, 0.9)',
                              fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                              letterSpacing: '0.01em',
                            }}
                          >
                            This knowledge graph contains {formatNumber(nodeCount)} entities connected through {formatNumber(edgeCount)} relationships, 
                            forming {formatNumber(clusterCount)} distinct communities. The largest community includes financial transactions 
                            between key organizations. Temporal analysis reveals increased activity in Q3 2024, 
                            with several high-strength relationships emerging between previously unconnected entities.
                          </Typography>
                        </Box>
                      </Box>
                    </motion.div>
                  )}
                </Box>
              </>
            ) : (
              // Collapsed Pill Content - rendered inside the same container that morphs
              <motion.div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  background: 'linear-gradient(135deg, rgba(70, 130, 230, 0.05) 0%, rgba(60, 110, 210, 0.05) 0%)',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: '100%',
                  padding: '0px',
                  marginLeft: '0px'
                }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.4,
                  ease: [0.16, 1, 0.3, 1]
                }}
                layout
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: '1.125rem',
                    fontWeight: 600,
                    color: 'rgba(255, 255, 255, 0.95)',
                    letterSpacing: '-0.01em',
                    fontFamily: '"Inter", "Roboto", "Helvetica", sans-serif',
                    textShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
                  }}
                >
                </Typography>
                
                <Box 
                  component="span" 
                  sx={{
                    fontSize: '0.9rem',
                    padding: '6px 26px',
                    borderRadius: '24px',
                    marginLeft: '0px',
                    fontWeight: 500,
                    backdropFilter: 'blur(2px)',
                    color: 'rgba(255, 255, 255, 0.88)',
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    textShadow: '0 1px 1px rgba(0, 0, 0, 0.15)',
                  }}
                >
                  {formatNumber(nodeCount)} Entities • {formatNumber(edgeCount)} Relationships • {formatNumber(clusterCount)} Communities
                  
                  <IconButton 
                    size="small"
                    sx={{ 
                      ml: 1.5,
                      width: '28px',
                      height: '28px',
                      background: 'linear-gradient(135deg, rgba(80, 140, 230, 0.45) 0%, rgba(70, 120, 210, 0.4) 100%)',
                      backdropFilter: 'blur(4px)',
                      color: 'rgba(255, 255, 255, 0.95)'
                    }}
                  >
                    <ExpandLessIcon fontSize="small" />
                  </IconButton>
                </Box>
              </motion.div>
            )}
          </motion.div>
        </AnimatePresence>
      </Box>
    </motion.div>
  );
};

export default BottomPanel;