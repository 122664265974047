import React from "react";

import HelpMenu from "../HelpMenu";

interface FooterProps {
  style?: any;
  copy?: boolean;
}

const Footer = ({ style = {}, copy = false }: FooterProps) => {
  return (
    <>
      {copy && (
        <footer className="h-16"  style={style}>
          <p>
            &copy; Adapter, {new Date().getFullYear()}
          </p>
        </footer>
      )}

      <HelpMenu />
    </>
  );
}

export default Footer;