import React from 'react';
import Notice from 'components/common/Notice';
import { LightbulbIcon } from 'components/common/Icon';

import '../Option.scss';

const RequestNotUnderstood = ({ message }) => {
    return (
        <>
            <Notice text='Request Not Understood' warning />

            <div data-test-id="errors.not_understood">
                Adapter could not understand your request.
                Please edit your request using the prompt bar below.
            </div>

            {message && (
                <div className='adapter-unsupported-option'>
                    <h3><LightbulbIcon /> Helpful Tips:</h3>

                    <ul>
                        <li>{message}</li>
                    </ul>
                </div>
            )}

            {!message && (
                <div className='adapter-unsupported-option'>
                    <h3><LightbulbIcon /> Helpful Tips:</h3>

                    <ul>
                        <li>Use simple sentences and be direct - try starting with &quot;I&apos;d like to book...&quot;</li>
                        <li>Ensure you&apos;re using references that Adapter understands or has in its memory</li>
                        <li>Use the &quot;task&quot; setting for a request and the &quot;question&quot; setting for a query</li>
                    </ul>
                </div>
            )}
        </>
    );
};

export default RequestNotUnderstood;