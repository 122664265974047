import React from 'react';

import '../../../brand/index.scss';
import './Typography.scss';

type Typography_Props = {
    fontSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'l' | 'm' | 's' | 'xs';
    title?: string;
    
    children: any;
    style?: any;
    cls?: string;
}

export const Text = ({ 
    children, 
    fontSize = 'm',
    title = '',
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <p
            className={`adapter-typography-text adapter-text-size-${fontSize} ${cls}`}
            style={style}
            title={title}
        >
            {children}
        </p>
    );
}

export const H1Text = ({ 
    children, 
    title = '',
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <h1
            className={`adapter-typography-text adapter-text-size-h1 ${cls}`}
            style={style}
            title={title}
        >
            {children}
        </h1>
    );
}

export const H2Text = ({ 
    children, 
    title = '',
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <h2
            className={`adapter-typography-text adapter-text-size-h2 ${cls}`}
            style={style}
            title={title}
        >
            {children}
        </h2>
    );
}

export const H3Text = ({ 
    children, 
    title = '',
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <h3
            className={`adapter-typography-text adapter-text-size-h3 ${cls}`}
            style={style}
            title={title}
        >
            {children}
        </h3>
    );
}

export const H4Text = ({ 
    children, 
    title = '',
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <h4
            className={`adapter-typography-text adapter-text-size-h4 ${cls}`}
            style={style}
            title={title}
        >
            {children}
        </h4>
    );
}

export const UL = ({ 
    children, 
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <ul
            className={`adapter-ul ${cls}`}
            style={style}
        >
            {children}
        </ul>
    );
}

export const OL = ({ 
    children, 
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <ol
            className={`adapter-ol ${cls}`}
            style={style}
        >
            {children}
        </ol>
    );
}

export const LI = ({ 
    children, 
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <li
            className={`adapter-li ${cls}`}
            style={style}
        >
            {children}
        </li>
    );
}

export const LINK = ({ 
    children, 
    style = {},
    cls = '',
    href = undefined,
    onClick = () => {},
    ...rest
}: any) => {
    return (
        <a
            className={`adapter-link ${cls}`}
            style={style}
            href={href}
            onClick={onClick}
            {...rest}
        >
            {children}
        </a>
    );
}