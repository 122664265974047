import React from 'react';

import Adapter from './icons/Adapter';
import AlertCircle from './icons/AlertCircle';
import Announcement from './icons/Announcement';
import Atlas from './icons/Atlas';
import CaretRight from './icons/CaretRight';
import ChevronLeft from './icons/ChevronLeft';
import ChevronRight from './icons/ChevronRight';
import CircleCheckMark from './icons/CircleCheckMark';
import Close from './icons/Close';
import Compass from './icons/Compass';
import Edit from './icons/Edit';
import Email from './icons/Email';
import External from './icons/External';
import EyeClosed from './icons/EyeClosed';
import EyeOpen from './icons/EyeOpen';
import Globe from './icons/Globe';
import Guide from './icons/Guide';
import Home from './icons/Home';
import Hotel from './icons/Hotel';
import Info from './icons/Information';
import Intentions from './icons/Intentions';
import Lightbulb from './icons/Lightbulb';
import Link from './icons/Link';
import LinkBroken from './icons/LinkBroken';
import Location from './icons/Location';
import Logout from './icons/Logout';
import Martini from './icons/Martini';
import Menu from './icons/Menu';
import Message from './icons/Message';
import Mirror from './icons/Mirror';
import Plane from './icons/Plane';
import PurpleCircle from './icons/PurpleCircle';
import QuestionCircle from './icons/QuestionCircle';
import Search from './icons/Search';
import Send from './icons/Send';
import Settings from './icons/Settings';
import Star from './icons/Star';
import Support from './icons/Support';
import Target from './icons/Target';
import ThumbsUp from './icons/ThumbsUp';
import ThumbsDown from './icons/ThumbsDown';
import Trash from './icons/Trash';
import Upload from './icons/Upload';
import User from './icons/User';

import Apple from './icons/thirdparty/Apple';
import Facebook from './icons/thirdparty/Facebook';
import Google from './icons/thirdparty/Google';
import LinkedIn from './icons/thirdparty/LinkedIn';
import Outlook from './icons/thirdparty/Outlook';
import X from './icons/thirdparty/X';

import './Icon.scss';

type IconProps = {
    IconComponent: any;
    title: string;
    color?: string;
    style?: any;
    cls?: string;
    inline?: boolean;
    onClick?: () => {}
};

const Icon = ({ IconComponent, title, color, inline = false, onClick, style = {}, cls = '' }: IconProps) => (
    <span title={title} className={`adapter-icon ${inline ? 'inline-block' : ''} ${cls}`} onClick={onClick} style={style}>
        <IconComponent color={color} />
    </span>
);

export default Icon;

const IconHOC = (AnyIcon: any) => {
    return (props: any) => <Icon IconComponent={AnyIcon} {...props} />;
}

export const AdapterIcon = IconHOC(Adapter);
export const AlertCircleIcon = IconHOC(AlertCircle);
export const AnnouncementIcon = IconHOC(Announcement);
export const AppleIcon = IconHOC(Apple);
export const AtlasIcon = IconHOC(Atlas);
export const CaretRightIcon = IconHOC(CaretRight);
export const ChevronLeftIcon = IconHOC(ChevronLeft);
export const ChevronRightIcon = IconHOC(ChevronRight);
export const CircleCheckMarkIcon = IconHOC(CircleCheckMark);
export const CloseIcon = IconHOC(Close);
export const CompassIcon = IconHOC(Compass);
export const EditIcon = IconHOC(Edit);
export const EmailIcon = IconHOC(Email);
export const ExternalIcon = IconHOC(External);
export const EyeClosedIcon = IconHOC(EyeClosed);
export const EyeOpenIcon = IconHOC(EyeOpen);
export const FacebookIcon = IconHOC(Facebook);
export const GlobeIcon = IconHOC(Globe);
export const GuideIcon = IconHOC(Guide);
export const GoogleIcon = IconHOC(Google);
export const HomeIcon = IconHOC(Home);
export const HotelIcon = IconHOC(Hotel);
export const InfoIcon = IconHOC(Info);
export const IntentionsIcon = IconHOC(Intentions);
export const LightbulbIcon = IconHOC(Lightbulb);
export const LinkIcon = IconHOC(Link);
export const LinkBrokenIcon = IconHOC(LinkBroken);
export const LinkedInIcon = IconHOC(LinkedIn);
export const LocationIcon = IconHOC(Location);
export const LogoutIcon = IconHOC(Logout);
export const MartiniIcon = IconHOC(Martini);
export const MenuIcon = IconHOC(Menu);
export const MessageIcon = IconHOC(Message);
export const MirrorIcon = IconHOC(Mirror);
export const OutlookIcon = IconHOC(Outlook);
export const PurpleCircleIcon = IconHOC(PurpleCircle);
export const PlaneIcon = IconHOC(Plane);
export const QuestionCircleIcon = IconHOC(QuestionCircle);
export const SearchIcon = IconHOC(Search);
export const SendIcon = IconHOC(Send);
export const SettingsIcon = IconHOC(Settings);
export const StarIcon = IconHOC(Star);
export const SupportIcon = IconHOC(Support);
export const TargetIcon = IconHOC(Target);
export const ThumbsUpIcon = IconHOC(ThumbsUp);
export const ThumbsDownIcon = IconHOC(ThumbsDown);
export const TrashIcon = IconHOC(Trash);
export const UploadIcon = IconHOC(Upload);
export const UserIcon = IconHOC(User);
export const XIcon = IconHOC(X);