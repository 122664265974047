import React from 'react';

import { Text } from '../Typography';
import Feedback from '../Feedback/Feedback';
import Modal from '../Modal';
import Card from '../Card';

import './Intention.scss';

const IntentionTooltip = ({ children, onClose }) => {

    return (
        <Modal onClose={onClose} wrapWithCard={false}>
            <Card style={{ margin: '0 16px' }}>
                <Text>
                    <strong>How did we infer this?&nbsp;</strong>
                </Text>

                {children}

                <Feedback
                    text="Is this suggestion relevant or useful?"
                    responseKey={'dashboard::intention'}
                    metadata={{
                        // TODO: this!
                    }}
                />
            </Card>
        </Modal>
    );
}

export default IntentionTooltip;