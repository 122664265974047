import React from 'react';
import { inject, observer } from 'mobx-react';

import FlightOption from './FlightOption';
import { PurpleCircleIcon } from "components/common/Icon";
import Feedback from 'components/common/Feedback/Feedback';
import Card from 'components/common/Card';

interface FlightResponseProps {
    results: any;
    PromptStore?: any;
    jobId: string;
}

const FlightResponse: React.FC<FlightResponseProps> = ({ results, PromptStore, jobId }) => {
    const comprehension = PromptStore?.comprehensionResponse;

    const totalPassengers = React.useMemo(() => {
        if (!comprehension?.typed_response) return 1;
        const response = comprehension.typed_response;
        return (response.num_adults || 0) + (response.num_children || 0);
    }, [comprehension]);

    const options = [];

    if (Array.isArray(results)) {
        for (let i = 0; i < results.length; i++) {
            options.push(
                <FlightOption
                    key={results[i].index}
                    option={results[i].option}
                    index={i}
                    allOptions={results.map(r => r.option)}
                    ranking_reasoning={results[i].ranking_reasoning}
                    score={results[i].score}
                    allScores={results.map(r => r.score)}
                    numPassengers={totalPassengers}
                />
            );
        }
    }

    if (options.length === 0) {
        return (
            <>
                <p>
                    Adapter could not find any results that match your request.
                    Please try adjusting your your request to find more options.
                </p>
                <p>
                    Helpful Tips:
                </p>
                <ul>
                    <li>- Expand the time window of your search</li>
                    <li>- Allow for more stops or alternative cabin classes</li>
                    <li>- Include additional airports</li>
                </ul>
            </>
        );
    }

    return (
        <>
            <Card>
                <p>
                    Adapter found {options.length} option(s) and ranked them based on your needs and preferences, including:
                </p>

                <div className="agent-response">
                    <div><PurpleCircleIcon />Timeliness</div>
                    <div><PurpleCircleIcon />Cost sensitivity</div>
                    <div><PurpleCircleIcon />Stops</div>
                    <div><PurpleCircleIcon />Preferred airline</div>
                </div>
            </Card>

            {options}

            <Feedback
                style={{
                    backgroundColor: '#F9F1DE',
                    borderRadius: '4px',
                    alignSelf: 'stretch',
                    justifyContent: 'center'
                }}
                text="Were these results useful?"
                responseKey={'prompt::results::flights::jobId'}
                metadata={{ jobId }}
            />
        </>
    );
};

export default inject('PromptStore')(observer(FlightResponse));