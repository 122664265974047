import React from 'react';
import { observer, inject } from 'mobx-react';

import withRouter from 'hooks/withRouter';
import InputWithIconButton from '../../../components/forms/fields/InputWithIconButton';
import { ComprehensionResponse } from 'models/comprehension';
import Modal from 'components/common/Modal';

import './InputBar.scss';

type InputBarProps = {
    placeholder: string;
    PromptStore?: any;
    ActionLogStore?: any;
    router?: any;
    style?: any;
    cls?: string;
    alwaysVisible?: boolean;
};

class InputBar extends React.Component<InputBarProps, any> {
    state = {
        loading: false,
        isOpen: false,
        isFocused: false
    }

    promptResponse = () => {
        const { router, PromptStore } = this.props;
        const data: ComprehensionResponse = PromptStore.comprehensionResponse;
        this.setState({ loading: false });
        const jobId = data.trigger_event_id || data.job_id;

        // redirect the page if necessary
        if (jobId) {
            // navigate without reloading the page, if we can
            router.navigate(`/intention/${jobId}`);
        }
    }

    promptError = () => {
        this.setState({ loading: false });
    }

    onParseInput = () => {
        const { ActionLogStore, PromptStore, router } = this.props;

        this.setState({ loading: true }, () => {
            router.navigate(`/dashboard`);

            PromptStore.parseInput()
                .then(() => ActionLogStore.listJobs())
                .then(this.promptResponse)
                .catch(this.promptError);
        });
    }

    toggleOpen = () => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen }, () => {
            if (!isOpen) {
                const field = document.querySelector('[data-test-id="mobile-hack"]');
                if (field) {
                    (field as HTMLElement).focus();
                }
            }
        });
    }

    handleFocus = () => {
        this.setState({ isFocused: true });
        // Clear the prompt if it's null
        if (this.props.PromptStore.rawPrompt === null) {
            this.props.PromptStore.setRawPrompt('');
        }
    }

    handleBlur = () => {
        this.setState({ isFocused: false });
        // Reset to null if empty when losing focus
        if (this.props.PromptStore.rawPrompt === '') {
            this.props.PromptStore.setRawPrompt(null);
        }
    }

    render() {
        const { placeholder, PromptStore, style = {}, cls = '', alwaysVisible = false } = this.props;
        const { loading, isOpen, isFocused } = this.state;

        const inputProps = {
            fieldId: "",
            placeholder: isFocused ? "" : placeholder,
            onChange: (e: any) => PromptStore.setRawPrompt(e.target.value),
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            Icon: () => (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M6.5 6V8H15.09L5.5 17.59L6.91 19L16.5 9.41V18H18.5V6H6.5Z" fill="white" />
                </svg>
            ),
            onClick: this.onParseInput,
            onEnterKey: this.onParseInput,
            style: { flex: 1 },
            value: PromptStore.rawPrompt || '',
            buttonConfig: {
                title: 'Let\'s go!',
                color: PromptStore.rawPrompt === null ? '#98A2B3' : '#7549F2',
                disabled: loading || PromptStore.rawPrompt === null
            }
        };

        return (
            <>
                <div className={`intention-inputbar ${cls} ${isOpen ? 'isMobileOpen' : ''}`} style={style}>
                    <InputWithIconButton
                        {...inputProps}
                        cls={alwaysVisible ? '' : 'desktopOnly'}
                        dataTestId="agent-service-prompt"
                    />

                    {!alwaysVisible && !isOpen && (
                        <div className='intention-inputbar-trigger mobileOnly' onClick={this.toggleOpen}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M6.5 6V8H15.09L5.5 17.59L6.91 19L16.5 9.41V18H18.5V6H6.5Z" fill="white" />
                            </svg>
                        </div>
                    )}

                    {!alwaysVisible && isOpen && (
                        <Modal onClose={this.toggleOpen} cls="mobileOnly" wrapWithCard={false}>
                            <InputWithIconButton
                                {...inputProps}
                                dataTestId="mobile-hack"
                            />
                        </Modal>
                    )}
                </div>
            </>
        );
    }
}

export default withRouter(inject("ActionLogStore", "PromptStore")(observer(InputBar)));