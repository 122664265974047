import React, { ChangeEvent, FocusEvent } from "react";
import { Link } from "react-router-dom";

import './Input.scss';

type ButtonConfig = {
    title: string;
    color: string;
    disabled?: boolean;
}

export type InputWithIconButtonProps = {
    labelText?: string | any;
    fieldId: string;
    dataTestId?: string;
    cls?: string;
    value?: string | number;
    placeholder?: string;
    hintText?: string;
    disabled?: boolean;
    required?: boolean;
    errorText?: string;
    error?: boolean;
    buttonConfig?: ButtonConfig;
    link?: {
        linkText: string;
        linkUrl: string;
    };
    Icon: any;
    onChange: (event: ChangeEvent<any>) => void;
    onClick: () => void;
    onEnterKey: () => void;
    onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
    onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
    style?: any;
    darkMode?: boolean;
};

type InputWithIconButtonState = {
    displayPlaceholder: boolean;
};

export default class InputWithIconButton extends React.Component<InputWithIconButtonProps, InputWithIconButtonState> {
    inputRef: React.RefObject<HTMLTextAreaElement>;

    inputHeight: string;

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {
            displayPlaceholder: !props.value,
        };
    }

    componentDidMount() {
        this.handleResize(false);
    }

    shouldComponentUpdate(nextProps, nextState): boolean {
        if (
            nextProps.value === this.props.value &&
            nextProps.buttonConfig.disabled === this.props.buttonConfig.disabled &&
            nextState.displayPlaceholder === this.state.displayPlaceholder
        ) {
            return false; // Don't re-render
        }

        setTimeout(() => this.handleResize(false), 200);
        return true;
    }

    handleResize = (e: any) => {
        const { value } = this.props;
        const textarea = this.inputRef.current;
        if (!textarea) {
            return;
        }

        // Reset height to auto first to get proper scrollHeight
        textarea.style.height = 'auto';

        if (value) {
            // Calculate new height with a small buffer
            const newHeight = Math.max(textarea.scrollHeight, 50);

            this.inputHeight = `${newHeight}px`;
            textarea.style.height = this.inputHeight;
        }

        if (e !== false && typeof e?.target?.value === 'string') {
            this.props.onChange(e);
        }
    }

    onKeyDown = (e: any) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            this.props.onEnterKey();
        }
    }

    handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
        this.setState({ displayPlaceholder: false });
        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    }

    handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
        if (e.target.value === '') {
            this.setState({ displayPlaceholder: true });
        }
        if (this.props.onBlur) {
            this.props.onBlur(e);
        }
    }

    renderTextInput = () => {
        const {
            fieldId,
            value,
            placeholder,
            errorText,
            error,
            disabled = false,
            Icon,
            dataTestId = '',
            buttonConfig = {
                title: 'Submit',
                color: '#7549F2',
                disabled: false
            },
            onClick
        } = this.props;

        const { displayPlaceholder } = this.state;

        return (
            <div className="inputWithIconButton">
                <textarea
                    ref={this.inputRef}
                    id={fieldId}
                    className={errorText || error ? 'error' : ''}
                    name={fieldId}
                    onChange={this.handleResize}
                    value={value ?? ''}
                    placeholder={displayPlaceholder ? placeholder : ''}
                    disabled={disabled}
                    onKeyDown={this.onKeyDown}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    data-test-id={dataTestId}
                    style={{ height: this.inputHeight }}
                />
                <div
                    role="button"
                    className="trigger"
                    title={buttonConfig.title}
                    onClick={!error && !errorText && !buttonConfig.disabled ? onClick : undefined}
                    style={{
                        color: buttonConfig.color,
                        opacity: buttonConfig.disabled ? 0.5 : 1  // Add this for better disabled state
                    }}
                >
                    <Icon />
                </div>
            </div>
        );
    }

    render() {
        const {
            fieldId,
            hintText,
            errorText,
            labelText,
            link,
            style = {},
            cls = '',
            darkMode = false
        } = this.props;

        return (
            <div className={`adapter-form-field-input ${cls} ${darkMode ? 'darkMode' : ''}`} style={style}>
                {labelText && (
                    <label htmlFor={fieldId}>{labelText}</label>
                )}

                {this.renderTextInput()}

                {link && (
                    <div className="text-sm text-right" style={{ width: '100%' }}>
                        <Link className="font-semibold" to={link.linkUrl}>
                            {link.linkText}
                        </Link>
                    </div>
                )}

                {!errorText && hintText && (
                    <p className="hintText">{hintText}</p>
                )}

                {errorText && (
                    <p className="errorText">{errorText}</p>
                )}
            </div>
        );
    }
}