import React from 'react';
import { Section } from './StyledComponents';
import { pageTransition } from '../constants';

interface ErrorProps {
    error: string;
    onRetry: () => void;
}

export class Error extends React.Component<ErrorProps> {
    render() {
        return (
            <Section key="error" {...pageTransition}>
                <div style={{
                    padding: 24,
                    color: 'red',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px'
                }}>
                    <div>{this.props.error}</div>
                    <button
                        onClick={this.props.onRetry}
                        style={{
                            padding: '8px 16px',
                            border: 'none',
                            borderRadius: '4px',
                            background: '#f0f0f0',
                            cursor: 'pointer'
                        }}
                    >
                        Retry
                    </button>
                </div>
            </Section>
        );
    }
} 