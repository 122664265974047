import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { filterService } from "../../services/FilterService";
import { graphService } from "../../services/GraphService";
import { AIService } from "../../services/AIService";
import { TTSService } from "../../services/TTSService";
import {
  filterNodesByQueryLegacy,
  normalizeEdgeReferencesLegacy,
} from "../../utils/dataProcessingLegacy";
import { NodeData, EdgeData, AIInsight, GraphData } from "../../types/types";
import { GraphProcessor } from "../../utils/dataprocessing";
import API from "../../../../util/API";
import Logger from "../../../../util/Logger";
import { useInitialView } from "../useInitialView";

// Sample data for initial state and fallback
const sampleData = {
  nodes: [
    {
      id: "person_123",
      type: "person",
      label: "John Smith (CEO, Example Corp)",
      properties: {
        primary_email_address: "john@example.com",
        user: true,
      },
      importance: 3.0,
      centrality: {
        degree: 0.85,
        eigenvector: 0.92,
      },
      community: 1,
    },
    {
      id: "person_456",
      type: "person",
      label: "Jane Doe (CTO, Example Corp)",
      properties: {
        primary_email_address: "jane@example.com",
      },
      importance: 2.0,
      community: 1,
    },
    {
      id: "event_789",
      type: "event",
      label: "Executive Meeting",
      properties: {},
      importance: 1.5,
      community: 1,
    },
  ],
  edges: [
    {
      source: "person_123",
      target: "person_456",
      type: "knows",
      properties: {
        strength: 0.85,
      },
    },
    {
      source: "person_123",
      target: "event_789",
      type: "attended",
      properties: {},
    },
    {
      source: "person_456",
      target: "event_789",
      type: "attended",
      properties: {},
    },
  ],
};

// Create the TTS service
const ttsService = new TTSService();

export interface KnowledgeGraphState {
  // User experience state
  showTour: boolean;
  setShowTour: React.Dispatch<React.SetStateAction<boolean>>;
  showWelcome: boolean;
  setShowWelcome: React.Dispatch<React.SetStateAction<boolean>>;
  viewMode: "welcome" | "focused" | "full";
  setViewMode: React.Dispatch<
    React.SetStateAction<"welcome" | "focused" | "full">
  >;

  // Theme state
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;

  // Panel state
  leftPanelCollapsed: boolean;
  setLeftPanelCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  rightPanelCollapsed: boolean;
  setRightPanelCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  isDetailPanelExpanded: boolean;
  setIsDetailPanelExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: "explore" | "insights" | "suggestions";
  setActiveTab: React.Dispatch<
    React.SetStateAction<"explore" | "insights" | "suggestions">
  >;

  // Selected node state
  selectedNode: NodeData | null;
  setSelectedNode: React.Dispatch<React.SetStateAction<NodeData | null>>;
  connectedNodes: NodeData[];
  setConnectedNodes: React.Dispatch<React.SetStateAction<NodeData[]>>;
  connectedEdges: EdgeData[];
  setConnectedEdges: React.Dispatch<React.SetStateAction<EdgeData[]>>;

  // Graph data state
  nodes: NodeData[];
  setNodes: React.Dispatch<React.SetStateAction<NodeData[]>>;
  edges: EdgeData[];
  setEdges: React.Dispatch<React.SetStateAction<EdgeData[]>>;
  filteredNodes: NodeData[];
  setFilteredNodes: React.Dispatch<React.SetStateAction<NodeData[]>>;
  filteredEdges: EdgeData[];
  setFilteredEdges: React.Dispatch<React.SetStateAction<EdgeData[]>>;

  // Filter state
  selectedNodeTypes: string[];
  setSelectedNodeTypes: React.Dispatch<React.SetStateAction<string[]>>;
  selectedEdgeTypes: string[];
  setSelectedEdgeTypes: React.Dispatch<React.SetStateAction<string[]>>;
  maxHops: number;
  setMaxHops: React.Dispatch<React.SetStateAction<number>>;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;

  // Voice processing state
  isProcessingVoice: boolean;
  setIsProcessingVoice: React.Dispatch<React.SetStateAction<boolean>>;
  humanSummary: string;
  setHumanSummary: React.Dispatch<React.SetStateAction<string>>;
  searchResultNodeCount: number | undefined;
  setSearchResultNodeCount: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  searchResultEdgeCount: number | undefined;
  setSearchResultEdgeCount: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  searchResultCommunityCount: number | undefined;
  setSearchResultCommunityCount: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;

  // Community state
  communities: Record<string, string>;
  setCommunities: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  communityDetails: {
    names: Record<string, string>;
    nodeCounts: Record<string, number>;
    communityMap: Record<string, number>;
  };
  setCommunityDetails: React.Dispatch<
    React.SetStateAction<{
      names: Record<string, string>;
      nodeCounts: Record<string, number>;
      communityMap: Record<string, number>;
    }>
  >;
  communityInsights: Record<string, AIInsight[]>;
  setCommunityInsights: React.Dispatch<
    React.SetStateAction<Record<string, AIInsight[]>>
  >;
  suggestedQueries: any[];
  setSuggestedQueries: React.Dispatch<React.SetStateAction<any[]>>;
  isLoadingCommunities: boolean;
  setIsLoadingCommunities: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingInsights: boolean;
  setIsLoadingInsights: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingSuggestions: boolean;
  setIsLoadingSuggestions: React.Dispatch<React.SetStateAction<boolean>>;

  // Data loading state
  isLoadingData: boolean;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  loadError: string | null;
  setLoadError: React.Dispatch<React.SetStateAction<string | null>>;

  // Performance monitoring
  fps: number;
  setFps: React.Dispatch<React.SetStateAction<number>>;
  lastQueryTime: number;
  setLastQueryTime: React.Dispatch<React.SetStateAction<number>>;

  // Focused view data
  focusedNodes: NodeData[];
  focusedEdges: EdgeData[];
  communityFocus: string | undefined;

  // AI Service
  aiService: AIService;

  // Graph context value
  graphContextValue: any;
}

export const useKnowledgeGraphState = (): KnowledgeGraphState => {
  // User experience state
  const [showTour, setShowTour] = useState<boolean>(false);
  const [showWelcome, setShowWelcome] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<"welcome" | "focused" | "full">(
    "full",
  );
  const welcomeShownRef = useRef<boolean>(false);

  // Theme state
  const [darkMode, setDarkMode] = useState(true);

  // Panel state
  const [leftPanelCollapsed, setLeftPanelCollapsed] = useState<boolean>(true);
  const [rightPanelCollapsed, setRightPanelCollapsed] = useState<boolean>(true);
  const [isDetailPanelExpanded, setIsDetailPanelExpanded] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<
    "explore" | "insights" | "suggestions"
  >("explore");

  // Selected node state
  const [selectedNode, setSelectedNode] = useState<NodeData | null>(null);
  const [connectedNodes, setConnectedNodes] = useState<NodeData[]>([]);
  const [connectedEdges, setConnectedEdges] = useState<EdgeData[]>([]);

  // Graph data state
  const [nodes, setNodes] = useState<NodeData[]>([]);
  const [edges, setEdges] = useState<EdgeData[]>([]);
  const [filteredNodes, setFilteredNodes] = useState<NodeData[]>([]);
  const [filteredEdges, setFilteredEdges] = useState<EdgeData[]>([]);

  // Filter state
  const [selectedNodeTypes, setSelectedNodeTypes] = useState<string[]>([]);
  const [selectedEdgeTypes, setSelectedEdgeTypes] = useState<string[]>([]);
  const [maxHops, setMaxHops] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>("");

  // Voice processing state
  const [isProcessingVoice, setIsProcessingVoice] = useState(false);
  const [humanSummary, setHumanSummary] = useState("");
  const [searchResultNodeCount, setSearchResultNodeCount] = useState<
    number | undefined
  >(undefined);
  const [searchResultEdgeCount, setSearchResultEdgeCount] = useState<
    number | undefined
  >(undefined);
  const [searchResultCommunityCount, setSearchResultCommunityCount] = useState<
    number | undefined
  >(undefined);

  // Community state
  const [communities, setCommunities] = useState<Record<string, string>>({});
  const [communityDetails, setCommunityDetails] = useState<{
    names: Record<string, string>;
    nodeCounts: Record<string, number>;
    communityMap: Record<string, number>;
  }>({ names: {}, nodeCounts: {}, communityMap: {} });
  const [communityInsights, setCommunityInsights] = useState<
    Record<string, AIInsight[]>
  >({});
  const [suggestedQueries, setSuggestedQueries] = useState<any[]>([]);
  const [isLoadingCommunities, setIsLoadingCommunities] = useState(false);
  const [isLoadingInsights, setIsLoadingInsights] = useState(false);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);

  // Data loading state
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [loadError, setLoadError] = useState<string | null>(null);

  // Performance monitoring
  const [fps, setFps] = useState<number>(0);
  const [lastQueryTime, setLastQueryTime] = useState<number>(0);

  // Reference for analysis completion
  const analysisCompletedRef = useRef(false);

  // Create a stable AIService instance - using dynamic config for ports
  const aiService = useMemo(() => {
    // Import service config here to ensure it's evaluated at runtime
    const { aiApiUrl } = require("../../services/ServiceConfig").default;

    return new AIService(
      process.env.REACT_APP_ANTHROPIC_API_KEY || "",
      // Will use dynamic config from ServiceConfig.ts
      undefined,
    );
  }, []);

  // Effect to update FPS from performance metrics
  useEffect(() => {
    // Initialize performance metrics object if it doesn't exist
    if (!(window as any).__performanceMetrics) {
      (window as any).__performanceMetrics = {
        avgFps: 0,
        lastFrameTime: 0,
        frameCount: 0,
        totalFrameTime: 0,
      };
    }

    // Create a function to update FPS
    const updateFPS = () => {
      // Calculate time since last frame
      const now = performance.now();
      const frameTime =
        (window as any).__performanceMetrics.lastFrameTime > 0
          ? now - (window as any).__performanceMetrics.lastFrameTime
          : 16.67; // Default to 60fps equivalent

      // Update metrics
      (window as any).__performanceMetrics.lastFrameTime = now;
      (window as any).__performanceMetrics.frameCount++;
      (window as any).__performanceMetrics.totalFrameTime += frameTime;

      // Calculate average FPS (over last ~30 frames)
      if ((window as any).__performanceMetrics.frameCount > 30) {
        const avgFrameTime =
          (window as any).__performanceMetrics.totalFrameTime /
          (window as any).__performanceMetrics.frameCount;
        (window as any).__performanceMetrics.avgFps = 1000 / avgFrameTime;

        // Reset counters periodically
        (window as any).__performanceMetrics.frameCount = 0;
        (window as any).__performanceMetrics.totalFrameTime = 0;
      }

      // Update state with current FPS
      if (typeof (window as any).__performanceMetrics.avgFps === "number") {
        setFps(Math.round((window as any).__performanceMetrics.avgFps));
      }

      // Schedule next update
      requestAnimationFrame(updateFPS);
    };

    // Start the FPS monitoring
    const animationFrameId = requestAnimationFrame(updateFPS);

    // Cleanup function
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  // Initialize settings from localStorage
  useEffect(() => {
    // Only check localStorage once to avoid rapid state changes
    if (welcomeShownRef.current) return;
    welcomeShownRef.current = true;

    // Force welcome to be hidden for debugging
    setShowWelcome(false);
    setViewMode("full");

    const storedDarkMode = localStorage.getItem("darkMode");
    if (storedDarkMode) {
      setDarkMode(storedDarkMode === "true");
    }

    // Set the preserve user nodes flag to false by default
    (window as any).__preserveUserNodesWithoutEdges = false;
  }, []);

  // Load data from API with Filter Service initialization
  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      setLoadError(null);

      try {
        Logger.log("Loading graph data...");

        var graphData = await graphService.fetchGraphData();

        try {
          Logger.log("Calling API for network data...");
          const response = await API.post("/api/query", {
            fn: "queryNetworkCommunity",
          });

          Logger.log("API response received:", response);

          // Check if response is a Response object that needs to be processed
          if (response instanceof Response) {
            if (!response.ok) {
              throw new Error(
                `Failed to fetch network data: ${response.status} ${response.statusText}`,
              );
            }

            // Extract the JSON data from the Response object
            const jsonData = await response.json();
            Logger.log("Extracted JSON from Response:", jsonData);

            if (jsonData && jsonData.nodes && jsonData.edges) {
              // Use the directly extracted data
              graphData = jsonData;
              Logger.log("Using data from Response.json()");
            } else {
              Logger.error(
                "Unexpected data structure in response body:",
                jsonData,
              );
              Logger.error("Will use fallback data from graphService");
            }
          } else if (response && response.status === 200) {
            // For non-Response objects (like from API helper libraries)
            if (response.data && response.data.nodes && response.data.edges) {
              // Use the data directly as it has the expected structure
              graphData = {
                nodes: response.data.nodes,
                edges: response.data.edges,
              };
              Logger.log("Using data from API response.data");
            } else if (response.nodes && response.edges) {
              // Response might have nodes/edges directly
              graphData = response;
              Logger.log("Using data directly from API response");
            } else {
              Logger.error("Unexpected API response structure:", response);
              Logger.error("Will use fallback data from graphService");
            }
          } else {
            throw new Error("Failed to fetch network data: Invalid response");
          }

          Logger.log(
            "Graph data to use:",
            graphData
              ? `Contains ${graphData.nodes?.length || 0} nodes and ${graphData.edges?.length || 0} edges`
              : "No data",
          );
        } catch (error) {
          Logger.error("Failed to load initial graph:", error);
          // Use the fallback data from graphService if API call fails
          Logger.log("Using fallback graph data from graphService");
        }

        // Validate graph data
        if (!graphData || !graphData.nodes || !Array.isArray(graphData.nodes)) {
          Logger.error("Invalid graph data structure:", graphData);
          throw new Error(
            "Invalid graph data structure: nodes array is missing or invalid",
          );
        }

        // Process data (ensure edges have proper references)
        const nodesData = graphData.nodes as NodeData[];

        // Process nodes to ensure user property is properly set
        const processedNodes = nodesData.map((node) => {
          // Set user property for nodes with user-indicating properties
          if (
            node.type === "person" &&
            (node.properties?.is_primary_user === true ||
              node.properties?.primary_user === true ||
              node.properties?.is_user === true ||
              node.properties?.user === true)
          ) {
            return { ...node, user: true };
          }
          return node;
        });

        // Validate edges array
        if (!graphData.edges || !Array.isArray(graphData.edges)) {
          Logger.error("Invalid graph data structure:", graphData);
          throw new Error(
            "Invalid graph data structure: edges array is missing or invalid",
          );
        }

        const rawEdgesData = graphData.edges as EdgeData[];
        const edgesData = normalizeEdgeReferencesLegacy(
          rawEdgesData,
          processedNodes,
        );

        Logger.log("Processed API data:", {
          nodes: processedNodes,
          edges: edgesData,
        });

        // Set original data states
        setNodes(processedNodes);
        setEdges(edgesData);

        // Initialize the filter service with the processed data
        filterService.initialize(processedNodes, edgesData);

        // Extract all unique node and edge types from the data
        const allNodeTypes = Array.from(
          new Set(
            processedNodes
              .map((node) => node.type || "unknown")
              .filter(Boolean),
          ),
        );
        const allEdgeTypes = Array.from(
          new Set(
            edgesData.map((edge) => edge.type || "unknown").filter(Boolean),
          ),
        );

        // Filter out 'email' type to avoid overwhelming the initial view
        const enabledNodeTypes = allNodeTypes.filter(
          (type) => type !== "email",
        );

        // Store all node types in a global for reference (including 'email')
        (window as any).__allNodeTypes = allNodeTypes;

        Logger.log("Found all entity types:", allNodeTypes);
        Logger.log(
          "Enabling these entity types by default (excluding email):",
          enabledNodeTypes,
        );
        Logger.log("Enabling all relationship types by default:", allEdgeTypes);

        // Set all node types except 'email' and all edge types as selected
        filterService.setNodeTypeFilters(enabledNodeTypes);
        filterService.setEdgeTypeFilters(allEdgeTypes);

        // Apply initial filters with email type disabled
        const initialFiltered = filterService.applyFilters();
        setFilteredNodes(initialFiltered.nodes);
        setFilteredEdges(initialFiltered.edges);

        // Set the selected types state to match enabled types
        setSelectedNodeTypes(enabledNodeTypes);
        setSelectedEdgeTypes(allEdgeTypes);

        // Also set these in the window object for global access
        (window as any).__selectedNodeTypes = enabledNodeTypes;
        (window as any).__selectedEdgeTypes = allEdgeTypes;

        Logger.log("API data loaded successfully");
      } catch (error) {
        Logger.error("Error loading graph data from API:", error);
        setLoadError(
          "Failed to load graph data from API. Using sample data instead.",
        );

        // Fallback to sample data if API fails
        Logger.warn("Falling back to sample data due to API error");

        try {
          // Handle fallback to sample data (for development/demo purposes)
          const nodesData = sampleData.nodes as NodeData[];

          // Process nodes to ensure user property is properly set
          const processedNodes = nodesData.map((node) => {
            // Set user property for nodes with user-indicating properties
            if (
              node.type === "person" &&
              (node.properties?.is_primary_user === true ||
                node.properties?.primary_user === true ||
                node.properties?.is_user === true ||
                node.properties?.user === true)
            ) {
              return { ...node, user: true };
            }
            return node;
          });

          const rawEdgesData = sampleData.edges as EdgeData[];
          const edgesData = normalizeEdgeReferencesLegacy(
            rawEdgesData,
            processedNodes,
          );

          // Set original data states
          setNodes(processedNodes);
          setEdges(edgesData);

          // Initialize the filter service with the processed data
          filterService.initialize(processedNodes, edgesData);

          // Extract all unique node and edge types from the data
          const allNodeTypes = Array.from(
            new Set(
              processedNodes
                .map((node) => node.type || "unknown")
                .filter(Boolean),
            ),
          );
          const allEdgeTypes = Array.from(
            new Set(
              edgesData.map((edge) => edge.type || "unknown").filter(Boolean),
            ),
          );

          // Filter out 'email' type to avoid overwhelming the initial view
          const enabledNodeTypes = allNodeTypes.filter(
            (type) => type !== "email",
          );

          // Store all node types in a global for reference (including 'email')
          (window as any).__allNodeTypes = allNodeTypes;

          Logger.log("Found all entity types (fallback):", allNodeTypes);
          Logger.log(
            "Enabling these entity types by default (excluding email, fallback):",
            enabledNodeTypes,
          );
          Logger.log(
            "Enabling all relationship types by default (fallback):",
            allEdgeTypes,
          );

          // Set all node types except 'email' and all edge types as selected
          filterService.setNodeTypeFilters(enabledNodeTypes);
          filterService.setEdgeTypeFilters(allEdgeTypes);

          // Apply initial filters with email type disabled
          const initialFiltered = filterService.applyFilters();
          setFilteredNodes(initialFiltered.nodes);
          setFilteredEdges(initialFiltered.edges);

          // Set the selected types state to match enabled types
          setSelectedNodeTypes(enabledNodeTypes);
          setSelectedEdgeTypes(allEdgeTypes);

          // Also set these in the window object for global access
          (window as any).__selectedNodeTypes = enabledNodeTypes;
          (window as any).__selectedEdgeTypes = allEdgeTypes;

          Logger.log("Sample data loaded as fallback");
        } catch (fallbackError) {
          Logger.error("Error loading fallback data:", fallbackError);
          setLoadError(
            "Failed to load fallback data. Please try refreshing the page.",
          );
        }
      } finally {
        setIsLoadingData(false);
      }
    };

    fetchData();
  }, []);

  // Get focused view using custom hook
  const { focusedNodes, focusedEdges, communityFocus } = useInitialView(
    filteredNodes,
    filteredEdges,
    communities,
  );

  // Effect to detect communities and generate insights when data is loaded
  useEffect(() => {
    // Skip if no nodes or edges or if analysis was already done
    if (
      nodes.length === 0 ||
      edges.length === 0 ||
      analysisCompletedRef.current
    )
      return;

    // Extract communities and generate insights
    const analyzeCommunities = async () => {
      setIsLoadingCommunities(true);
      try {
        Logger.log("Starting community analysis process...");

        // Extract communities from node data
        const { communities, communityDetails } =
          aiService.extractCommunityInfo(nodes);
        setCommunities(communities);
        setCommunityDetails(communityDetails);
        Logger.log("Community extraction completed");

        // Set empty insights since generateCommunityInsights is removed
        setIsLoadingInsights(true);
        setCommunityInsights({});
        Logger.log("Community insights step skipped");

        // Get suggested queries for calendar events in the next 30 days
        setIsLoadingSuggestions(true);
        const suggestions = await aiService.getSuggestedQueries(
          nodes,
          edges,
          communities,
          {},
        );
        setSuggestedQueries(suggestions || []);
        Logger.log("Calendar-focused query suggestions generated");
      } catch (error) {
        Logger.error("Error analyzing communities:", error);
        // Initialize empty states on error to prevent retries
        setCommunities({});
        setCommunityDetails({ names: {}, nodeCounts: {}, communityMap: {} });
        setCommunityInsights({});
        setSuggestedQueries([]);
      } finally {
        setIsLoadingCommunities(false);
        setIsLoadingInsights(false);
        setIsLoadingSuggestions(false);
        // Mark analysis as completed to prevent redundant calls
        analysisCompletedRef.current = true;
      }
    };

    analyzeCommunities();
  }, [nodes, edges, aiService]); // Keep dependencies but use ref to prevent multiple executions

  // Reset analysis flag when nodes/edges change substantially
  useEffect(() => {
    if (nodes.length > 0 && edges.length > 0) {
      analysisCompletedRef.current = false;
    }
  }, [nodes.length, edges.length]); // Only run when array lengths change

  // Create graph context value
  const graphContextValue = useMemo(
    () => ({
      nodes,
      edges,
      filteredNodes,
      filteredEdges,
      selectedNode,
      connectedNodes,
      connectedEdges,
      communities,
      darkMode,
      isLoading: isLoadingData,
    }),
    [
      nodes,
      edges,
      filteredNodes,
      filteredEdges,
      selectedNode,
      connectedNodes,
      connectedEdges,
      communities,
      darkMode,
      isLoadingData,
    ],
  );

  return {
    // User experience state
    showTour,
    setShowTour,
    showWelcome,
    setShowWelcome,
    viewMode,
    setViewMode,

    // Theme state
    darkMode,
    setDarkMode,

    // Panel state
    leftPanelCollapsed,
    setLeftPanelCollapsed,
    rightPanelCollapsed,
    setRightPanelCollapsed,
    isDetailPanelExpanded,
    setIsDetailPanelExpanded,
    activeTab,
    setActiveTab,

    // Selected node state
    selectedNode,
    setSelectedNode,
    connectedNodes,
    setConnectedNodes,
    connectedEdges,
    setConnectedEdges,

    // Graph data state
    nodes,
    setNodes,
    edges,
    setEdges,
    filteredNodes,
    setFilteredNodes,
    filteredEdges,
    setFilteredEdges,

    // Filter state
    selectedNodeTypes,
    setSelectedNodeTypes,
    selectedEdgeTypes,
    setSelectedEdgeTypes,
    maxHops,
    setMaxHops,
    searchQuery,
    setSearchQuery,

    // Voice processing state
    isProcessingVoice,
    setIsProcessingVoice,
    humanSummary,
    setHumanSummary,
    searchResultNodeCount,
    setSearchResultNodeCount,
    searchResultEdgeCount,
    setSearchResultEdgeCount,
    searchResultCommunityCount,
    setSearchResultCommunityCount,

    // Community state
    communities,
    setCommunities,
    communityDetails,
    setCommunityDetails,
    communityInsights,
    setCommunityInsights,
    suggestedQueries,
    setSuggestedQueries,
    isLoadingCommunities,
    setIsLoadingCommunities,
    isLoadingInsights,
    setIsLoadingInsights,
    isLoadingSuggestions,
    setIsLoadingSuggestions,

    // Data loading state
    isLoadingData,
    setIsLoadingData,
    loadError,
    setLoadError,

    // Performance monitoring
    fps,
    setFps,
    lastQueryTime,
    setLastQueryTime,

    // Focused view data
    focusedNodes,
    focusedEdges,
    communityFocus,

    // AI Service
    aiService,

    // Graph context value
    graphContextValue,
  };
};

export default useKnowledgeGraphState;
