import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import * as d3 from 'd3';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useDebounce } from '../hooks/useDebounce';
import { Node, Link, FilterState, ClusterData } from '../types/network-types';
import { LayoutEngine } from '../util/layoutAlgorithms';


interface TooltipState {
  node: Node;
  x: number;
  y: number;
}

interface SafeForceGraphProps {
  nodes: Node[];
  links: Link[];
  width: number;
  height: number;
  onNodeClick?: (node: Node) => void;
  filters?: FilterState;
  onFilterChange?: (filters: FilterState) => void;
  UserStore?: any;
}

interface LayoutOptions {
  type: 'force' | 'radial' | 'hierarchical' | 'cluster' | 'compact';
  settings: {
    nodeSpacing: number;
    clusterPadding: number;
    gravitationalForce: number;
    centeringForce: number;
  };
}

interface ViewportState {
  scale: number;
  translateX: number;
  translateY: number;
  fitToScreen: boolean;
  transform?: d3.ZoomTransform;
}

interface ClusterInfo {
  id: string;
  nodes: Node[];
  center: { x: number; y: number };
  radius: number;
  color: string;
}

interface HierarchyNode {
  id: string;
  children?: HierarchyNode[];
}

const GraphContainer = styled.div<{ isFullScreen: boolean }>`
  width: 100%;
  height: ${props => props.isFullScreen ? '100%' : '100%'};
  position: ${props => props.isFullScreen ? 'fixed' : 'relative'};
  top: ${props => props.isFullScreen ? '0' : 'auto'};
  left: ${props => props.isFullScreen ? '0' : 'auto'};
  right: ${props => props.isFullScreen ? '0' : 'auto'};
  bottom: ${props => props.isFullScreen ? '0' : 'auto'};
  background: white;
  border-radius: ${props => props.isFullScreen ? '0' : '16px'};
  box-shadow: ${props => props.isFullScreen ? 'none' : '0 8px 32px rgba(0, 0, 0, 0.08)'};
  overflow: hidden;
  z-index: ${props => props.isFullScreen ? '9999' : '1'};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
  ${props => props.isFullScreen && `
    height: 100vh;
    height: 100dvh; /* For mobile browsers with dynamic viewport height */
    margin: 0;
    padding: 0;
    border: none;
    max-width: none;
    max-height: none;
  `}

  /* Ensure proper sizing in Safari and mobile browsers */
  @supports (-webkit-touch-callout: none) {
    height: ${props => props.isFullScreen ? '-webkit-fill-available' : '800px'};
  }

  /* Handle orientation changes on mobile */
  @media screen and (orientation: landscape) and (max-height: 500px) {
    height: ${props => props.isFullScreen ? '100vh' : '800px'};
    overflow-y: ${props => props.isFullScreen ? 'auto' : 'hidden'};
  }
`;
const SVG = styled.svg<{ isFullScreen: boolean }>`
  width: ${props => props.isFullScreen ? '100vw' : '100%'};
  height: ${props => props.isFullScreen ? '100vh' : '100%'};
  display: block;
`;

const FiltersPanel = styled(motion.div)<{ isMinimized: boolean }>`
  position: absolute;
  left: 20px;
  top: 20px;
  background: white;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  width: ${props => props.isMinimized ? 'auto' : '250px'};
  z-index: 1000;
  
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

const FilterGroup = styled.div`
  margin-bottom: 20px;

  h4 {
    margin: 0 0 12px 0;
    font-size: 14px;
    color: #666;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const FiltersPanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  user-select: none;
`;

const MinimizeButton = styled(motion.button)`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  
  &:hover {
    color: #333;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ControlsContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1001; // Increase z-index to ensure visibility
  pointer-events: auto; // Ensure clicks are registered
`;

const ControlGroup = styled.div`
  display: flex;
  gap: 8px;
  background: white;
  padding: 8px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  pointer-events: auto; // Ensure clicks are registered
`;

const ControlButton = styled(motion.button)`
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 8px;
  background: #f5f5f7;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  pointer-events: auto;

  svg {
    width: 24px;
    height: 24px;
    fill: #1d1d1f;
  }

  &:hover {
    background: #e5e5e7;
  }

  &:active {
    background: #d5d5d7;
  }
`;

const NavigationOverlay = styled(motion.div)`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 16px;
  background: rgba(255, 255, 255, 0.95);
  padding: 12px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 1000;
`;

const MiniMap = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 200px;
  height: 150px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 1000;
`;

const SmartLayoutButton = styled(ControlButton)<{ active?: boolean }>`
  background: ${props => props.active ? '#0071e3' : '#f5f5f7'};
  color: ${props => props.active ? 'white' : '#1d1d1f'};
  width: auto;
  padding: 0 16px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const NodeTooltip = styled(motion.div)`
  position: fixed;
  background: white;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  pointer-events: none;
  z-index: 1000;
`;

const FocusedNodeView = styled(motion.div)`
  position: absolute;
  right: 20px;
  top: 80px; // Increased from 20px to 80px to clear the fullscreen button
  background: white;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  width: 500px;
  z-index: 1000;
  max-height: calc(80% - 80px); // Adjust max-height to account for new top position
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: purple;
    border-radius: 4px;
    
    &:hover {
      background: #666;
    }
  }

  // Ensure content is properly spaced
  > div {
    margin-bottom: 16px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  > * {
    padding-right: 4px;
  }
`;

const ExitFullScreenButton = styled(motion.button)`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background: rgba(0, 0, 0, 0.9);
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const FullScreenOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  z-index: 9998;
`;


const CONNECTION_STRENGTH = {
  'Strong': 3,
  'Regular': 2,
  'Weak': 1
} as const;


function cross(o: [number, number], a: [number, number], b: [number, number]): number {
  return (a[0] - o[0]) * (b[1] - o[1]) - (a[1] - o[1]) * (b[0] - o[0]);
}

function convexHull(points: [number, number][]): [number, number][] {
  if (points.length <= 3) return points;

  // Sort points by x-coord (and y-coord when x-coords are equal)
  points.sort((a, b) => a[0] === b[0] ? a[1] - b[1] : a[0] - b[0]);

  // Build lower hull
  const lower: [number, number][] = [];
  for (let i = 0; i < points.length; i++) {
    while (
      lower.length >= 2 && 
      cross(lower[lower.length - 2], lower[lower.length - 1], points[i]) <= 0
    ) {
      lower.pop();
    }
    lower.push(points[i]);
  }

  // Build upper hull
  const upper: [number, number][] = [];
  for (let i = points.length - 1; i >= 0; i--) {
    while (
      upper.length >= 2 && 
      cross(upper[upper.length - 2], upper[upper.length - 1], points[i]) <= 0
    ) {
      upper.pop();
    }
    upper.push(points[i]);
  }

  upper.pop();
  lower.pop();
  return [...lower, ...upper];
}

function generateCommunityPath(nodes: Node[], padding: number = 30): string {
  if (nodes.length < 3) return '';

  // Get points for convex hull
  const points: [number, number][] = nodes
    .filter(n => n.x != null && n.y != null)
    .map(n => [n.x!, n.y!]);

  // Calculate convex hull
  const hull = convexHull(points);
  
  // Add padding to hull points
  const centroid = hull.reduce(
    (acc, [x, y]) => ({ x: acc.x + x / hull.length, y: acc.y + y / hull.length }),
    { x: 0, y: 0 }
  );

  const paddedHull = hull.map(([x, y]) => {
    const dx = x - centroid.x;
    const dy = y - centroid.y;
    const dist = Math.sqrt(dx * dx + dy * dy);
    return [
      x + (dx / dist) * padding,
      y + (dy / dist) * padding
    ] as [number, number];
  });

  // Generate SVG path
  return `M ${paddedHull[0][0]},${paddedHull[0][1]} ${
    paddedHull.slice(1).map(([x, y]) => `L ${x},${y}`).join(' ')
  } Z`;
}

function getStrongestLinks(links: Link[]): Link[] {
  const connectionMap = new Map<string, Link>();
  
  links.forEach(link => {
    const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
    const targetId = typeof link.target === 'string' ? link.target : link.target.id;
    
    // Create a consistent key for the connection regardless of direction
    const connectionKey = [sourceId, targetId].sort().join('->');
    
    const existingLink = connectionMap.get(connectionKey);
    if (!existingLink || 
        CONNECTION_STRENGTH[link.type] > CONNECTION_STRENGTH[existingLink.type]) {
      connectionMap.set(connectionKey, link);
    }
  });
  
  return Array.from(connectionMap.values());
}

const NetworkAnalysis = {
  calculateClusters(nodes: Node[], links: Link[]): ClusterInfo[] {
    // Create a map of community ID to nodes
    const communityMap = new Map<number, Set<string>>();
    
    // First pass: collect nodes for each community
    links.forEach(link => {
      const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
      const targetId = typeof link.target === 'string' ? link.target : link.target.id;
      
      // Only add to communities if nodes are in the same community
      if (link.in_same_community) {
        (link.communities || []).forEach(communityId => {
          if (!communityMap.has(communityId)) {
            communityMap.set(communityId, new Set());
          }
          communityMap.get(communityId)!.add(sourceId);
          communityMap.get(communityId)!.add(targetId);
        });
      }
    });

    // Convert to ClusterInfo format with positioning
    return Array.from(communityMap.entries()).map(([communityId, nodeIds]) => {
      const communityNodes = nodes.filter(node => nodeIds.has(node.id));
      const center = this.calculateClusterCenter(communityNodes);
      
      return {
        id: `community-${communityId}`,
        nodes: communityNodes,
        center,
        radius: this.calculateClusterRadius(communityNodes, center),
        color: d3.schemeCategory10[communityId % 10]
      };
    });
  },

  calculateClusterCenter(nodes: Node[]): { x: number; y: number } {
    if (nodes.length === 0) return { x: 0, y: 0 };

    const sum = nodes.reduce((acc, node) => ({
      x: acc.x + (node.x || 0),
      y: acc.y + (node.y || 0)
    }), { x: 0, y: 0 });

    return {
      x: sum.x / nodes.length,
      y: sum.y / nodes.length
    };
  },

  calculateClusterRadius(nodes: Node[], center: { x: number; y: number }): number {
    if (nodes.length === 0) return 50;

    const maxDistance = Math.max(
      50,
      ...nodes.map(node => 
        Math.sqrt(
          Math.pow((node.x || 0) - center.x, 2) + 
          Math.pow((node.y || 0) - center.y, 2)
        )
      )
    );

    // Add padding based on number of nodes
    return maxDistance + Math.min(50, nodes.length * 2);
  },

  getNodeCommunities(nodeId: string, links: Link[]): number[] {
    const communities = new Set<number>();
    
    links.forEach(link => {
      const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
      const targetId = typeof link.target === 'string' ? link.target : link.target.id;
      
      if (sourceId === nodeId || targetId === nodeId) {
        (link.communities || []).forEach(communityId => communities.add(communityId));
      }
    });

    return Array.from(communities);
  },

  calculateCommunityMetrics(links: Link[]): {
    totalCommunities: number;
    averageSize: number;
    cohesionScores: Map<number, number>;
  } {
    const communities = new Map<number, Set<string>>();
    const communityConnections = new Map<number, number>();

    // Build community maps
    links.forEach(link => {
      const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
      const targetId = typeof link.target === 'string' ? link.target : link.target.id;

      (link.communities || []).forEach(communityId => {
        if (!communities.has(communityId)) {
          communities.set(communityId, new Set());
          communityConnections.set(communityId, 0);
        }

        communities.get(communityId)!.add(sourceId);
        communities.get(communityId)!.add(targetId);

        if (link.in_same_community) {
          communityConnections.set(
            communityId,
            (communityConnections.get(communityId) || 0) + 1
          );
        }
      });
    });

    // Calculate cohesion scores
    const cohesionScores = new Map<number, number>();
    communities.forEach((members, communityId) => {
      const size = members.size;
      const maxPossibleConnections = (size * (size - 1)) / 2;
      const actualConnections = communityConnections.get(communityId) || 0;
      
      cohesionScores.set(
        communityId,
        maxPossibleConnections > 0 ? actualConnections / maxPossibleConnections : 0
      );
    });

    // Calculate average community size
    const sizes = Array.from(communities.values()).map(c => c.size);
    const averageSize = sizes.length > 0 
      ? sizes.reduce((a, b) => a + b, 0) / sizes.length 
      : 0;

    return {
      totalCommunities: communities.size,
      averageSize,
      cohesionScores
    };
  },

  // Helper method to check if nodes share any communities
  sharesCommunity(node1Id: string, node2Id: string, links: Link[]): boolean {
    const link = links.find(l => {
      const sourceId = typeof l.source === 'string' ? l.source : l.source.id;
      const targetId = typeof l.target === 'string' ? l.target : l.target.id;
      return ((sourceId === node1Id && targetId === node2Id) ||
              (sourceId === node2Id && targetId === node1Id)) &&
             l.in_same_community;
    });
    return !!link;
  },

  // Get all nodes in a specific community
  getNodesInCommunity(communityId: number, links: Link[]): Set<string> {
    const nodeSet = new Set<string>();
    
    links.forEach(link => {
      if (link.communities?.includes(communityId) && link.in_same_community) {
        const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
        const targetId = typeof link.target === 'string' ? link.target : link.target.id;
        nodeSet.add(sourceId);
        nodeSet.add(targetId);
      }
    });

    return nodeSet;
  },

  // Calculate community density
  calculateCommunityDensity(communityId: number, links: Link[]): number {
    const nodesInCommunity = this.getNodesInCommunity(communityId, links);
    const n = nodesInCommunity.size;
    
    if (n <= 1) return 0;

    const maxPossibleConnections = (n * (n - 1)) / 2;
    const actualConnections = links.filter(link => 
      link.communities?.includes(communityId) && 
      link.in_same_community
    ).length;

    return actualConnections / maxPossibleConnections;
  }
};


const SmartLayout = {
  applyCompactLayout(nodes: Node[], links: Link[], width: number, height: number) {
    const clusters = NetworkAnalysis.calculateClusters(nodes, links);
    
    // Create force simulation
    const simulation = d3.forceSimulation(nodes)
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('charge', d3.forceManyBody().strength(-1000))
      .force('collision', d3.forceCollide().radius(50))
      .force('link', d3.forceLink(links).id((d: any) => d.id).distance(80))
      .force('cluster', this.forceCluster(clusters))
      .stop();

    // Run simulation
    for (let i = 0; i < 300; ++i) simulation.tick();
    
    return { nodes, links, clusters };
  },

  forceCluster(clusters: ClusterInfo[]) {
    return (alpha: number) => {
      clusters.forEach(cluster => {
        cluster.nodes.forEach(node => {
          const k = 0.1 * alpha;
          node.vx = (node.vx || 0) + (cluster.center.x - (node.x || 0)) * k;
          node.vy = (node.vy || 0) + (cluster.center.y - (node.y || 0)) * k;
        });
      });
    };
  },

  optimizeViewport(nodes: Node[], width: number, height: number): ViewportState {
    const bounds = this.calculateBounds(nodes);
    const padding = 50;
    
    const scale = Math.min(
      width / (bounds.maxX - bounds.minX + 2 * padding),
      height / (bounds.maxY - bounds.minY + 2 * padding)
    );

    return {
      scale: Math.min(scale, 2), // Limit max zoom
      translateX: (width - (bounds.maxX + bounds.minX) * scale) / 2,
      translateY: (height - (bounds.maxY + bounds.minY) * scale) / 2,
      fitToScreen: true
    };
  },

  calculateBounds(nodes: Node[]) {
    if (nodes.length === 0) {
      return { minX: 0, maxX: 0, minY: 0, maxY: 0 };
    }

    return nodes.reduce((bounds, node) => ({
      minX: Math.min(bounds.minX, node.x || 0),
      maxX: Math.max(bounds.maxX, node.x || 0),
      minY: Math.min(bounds.minY, node.y || 0),
      maxY: Math.max(bounds.maxY, node.y || 0)
    }), { 
      minX: Infinity, 
      maxX: -Infinity, 
      minY: Infinity, 
      maxY: -Infinity 
    });
  }
};

const EXCLUDED_DOMAINS = [
  '@resource.calendar.google.com',
  '@group.calendar.google.com'
] as const;

const isCalendarResource = (email: string): boolean => {
  return EXCLUDED_DOMAINS.some(domain => email.endsWith(domain));
};

const SafeForceGraph: React.FC<SafeForceGraphProps> = ({
  nodes,
  links,
  width,
  height,
  onNodeClick,
  UserStore
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const minimapRef = useRef<SVGSVGElement>(null);
  const [zoom, setZoom] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [focusedNode, setFocusedNode] = useState<Node | null>(null);
  const [tooltipNode, setTooltipNode] = useState<TooltipState | null>(null);
  const [horizontalLayout, setHorizontalLayout] = useState(true);
  const [layoutEngine] = useState(() => new LayoutEngine(width, height));
  const [selectedNodes, setSelectedNodes] = useState<Set<string>>(new Set());
  const [draggedNode, setDraggedNode] = useState<Node | null>(null);
  const zoomBehavior = useRef<d3.ZoomBehavior<SVGSVGElement, unknown>>();
  const [isFiltersPanelMinimized, setIsFiltersPanelMinimized] = useState(false);
  const userEmails = UserStore?.userEmails;

   useEffect(() => {
    layoutEngine.setHorizontalLayout(horizontalLayout);
  }, [horizontalLayout, layoutEngine]);


  
  const [viewportState, setViewportState] = useState<ViewportState>({
    scale: 1,
    translateX: 0,
    translateY: 0,
    fitToScreen: false
  });

  const [layoutOptions, setLayoutOptions] = useState<LayoutOptions>({
    type: 'hierarchical',
    settings: {
      nodeSpacing: 100,
      clusterPadding: 20,
      gravitationalForce: 100,
      centeringForce: 0.5
    }
  });

  const [filters, setFilters] = useState<FilterState>({
    layout: 'hierarchical',
    minConnections: 0,
    connectionTypes: ['Strong', 'Regular', 'Weak'],
    searchTerm: '',
    dateRange: [new Date(), new Date()]
  });

  const [clusters, setClusters] = useState<ClusterData[]>([]);
  const debouncedSearchTerm = useDebounce(filters.searchTerm, 300);

  // Memoized simulation setup
  const simulation = useMemo(() => {
    return d3.forceSimulation<Node>()
      .force("link", d3.forceLink<Node, Link>().id(d => d.id).distance(100))
      .force("charge", d3.forceManyBody().strength(-500))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force("collision", d3.forceCollide().radius(30))
      .force("x", horizontalLayout ? 
        d3.forceX(width / 2).strength(0.1) :
        d3.forceX(width / 2).strength(0.05))
      .force("y", horizontalLayout ?
        d3.forceY(height / 2).strength(0.05) :
        d3.forceY(height / 2).strength(0.1));
  }, [width, height, horizontalLayout]);


  const filteredDataset = useMemo(() => {
    // Filter out calendar resource nodes
    const validNodes = nodes.filter(node => !isCalendarResource(node.id));
    
    // Only keep links between valid nodes
    const validLinks = links.filter(link => {
      const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
      const targetId = typeof link.target === 'string' ? link.target : link.target.id;
      
      return !isCalendarResource(sourceId) && !isCalendarResource(targetId);
    });

    return { validNodes, validLinks };
  }, [nodes, links]);


const applyFilters = useCallback(() => {
    // Helper function to check if a node ID represents a user email
    const isUserEmail = (nodeId: string): boolean => {
        return userEmails.some(email => 
            nodeId.toLowerCase() === email.toLowerCase()
        );
    };

    // Helper function to normalize text for searching
    const normalizeText = (text: string): string => {
        return text.toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
            .trim();
    };

    // Split search terms and normalize them
    const searchTerms = normalizeText(debouncedSearchTerm)
        .split(/\s+/)
        .filter(term => term.length > 0);

    // Enhanced matching function that supports partial matches
    const matchesTerms = (text: string, terms: string[]): boolean => {
        if (terms.length === 0) return true;
        
        const normalizedText = normalizeText(text);
        
        return terms.every(term => {
            // Special handling for email domain searches
            if (term.includes('@') || term.includes('.')) {
                return normalizedText.includes(term);
            }
            
            // For regular searches, split the text into parts and check each
            const textParts = normalizedText.split(/[@.\s]+/);
            const termParts = term.split(/[@.\s]+/).filter(Boolean);
            
            return termParts.every(part => 
                textParts.some(textPart => 
                    textPart.includes(part) ||
                    levenshteinDistance(part, textPart) <= Math.min(2, Math.floor(textPart.length / 3))
                )
            );
        });
    };

    // Helper function to calculate Levenshtein distance for fuzzy matching
    const levenshteinDistance = (a: string, b: string): number => {
        if (a.length === 0) return b.length;
        if (b.length === 0) return a.length;

        const matrix = Array(b.length + 1).fill(null).map(() => 
            Array(a.length + 1).fill(null)
        );

        for (let i = 0; i <= a.length; i++) matrix[0][i] = i;
        for (let j = 0; j <= b.length; j++) matrix[j][0] = j;

        for (let j = 1; j <= b.length; j++) {
            for (let i = 1; i <= a.length; i++) {
                const cost = a[i - 1] === b[j - 1] ? 0 : 1;
                matrix[j][i] = Math.min(
                    matrix[j][i - 1] + 1,
                    matrix[j - 1][i] + 1,
                    matrix[j - 1][i - 1] + cost
                );
            }
        }

        return matrix[b.length][a.length];
    };

    // Enhanced topic matching that considers partial matches
    const hasMatchingTopics = (link: Link): boolean => {
        if (searchTerms.length === 0) return true;
        if (!link.topics?.length) return false;

        // Sort topics by score and count to prioritize more significant topics
        const sortedTopics = [...link.topics].sort((a, b) => 
            (b.score * b.count) - (a.score * a.count)
        );

        // Create weighted topic text where higher scored topics appear multiple times
        const topicText = sortedTopics.map(topic => {
            // Repeat important topics based on their score/count
            const weight = Math.ceil((topic.score * topic.count) / 10);
            return Array(Math.min(weight, 3)).fill(topic.word).join(' ');
        }).join(' ');

        return matchesTerms(topicText, searchTerms);
    };

    if (searchTerms.length === 0) {
        // If no search terms, apply basic filtering
        const filteredNodes = filteredDataset.validNodes.filter(node => {
            // Filter out user email nodes
            if (isUserEmail(node.id)) return false;

            const nodeConnections = filteredDataset.validLinks.filter(link => {
                const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
                const targetId = typeof link.target === 'string' ? link.target : link.target.id;
                return (sourceId === node.id || targetId === node.id) && 
                       filters.connectionTypes.includes(link.type);
            }).length;
            return nodeConnections >= filters.minConnections;
        });

        const filteredLinks = getStrongestLinks(filteredDataset.validLinks.filter(link => {
            const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
            const targetId = typeof link.target === 'string' ? link.target : link.target.id;
            // Filter out links connected to user email nodes
            if (isUserEmail(sourceId) || isUserEmail(targetId)) return false;

            return filteredNodes.some(n => n.id === sourceId) &&
                   filteredNodes.some(n => n.id === targetId) &&
                   filters.connectionTypes.includes(link.type);
        }));

        return {
            filteredNodes,
            filteredLinks,
            directMatchNodes: new Set<string>(),
            topicMatchLinks: new Set<string>()
        };
    }

    // Find nodes that directly match search terms (either by email or connected link topics)
    const directMatchNodeIds = new Set<string>();
    const nodeToMatchingTopicLinks = new Map<string, Set<Link>>();

    // First pass: find nodes matching by email/ID
    filteredDataset.validNodes.forEach(node => {
        // Skip user email nodes
        if (isUserEmail(node.id)) return;

        if (matchesTerms(node.id, searchTerms)) {
            directMatchNodeIds.add(node.id);
        }
    });

    // Second pass: find nodes matching by their connected links' topics
    filteredDataset.validLinks.forEach(link => {
        const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
        const targetId = typeof link.target === 'string' ? link.target : link.target.id;

        // Skip links connected to user email nodes
        if (isUserEmail(sourceId) || isUserEmail(targetId)) return;

        if (hasMatchingTopics(link)) {
            directMatchNodeIds.add(sourceId);
            directMatchNodeIds.add(targetId);

            // Store the matching topic link for each node
            if (!nodeToMatchingTopicLinks.has(sourceId)) {
                nodeToMatchingTopicLinks.set(sourceId, new Set());
            }
            if (!nodeToMatchingTopicLinks.has(targetId)) {
                nodeToMatchingTopicLinks.set(targetId, new Set());
            }
            nodeToMatchingTopicLinks.get(sourceId)!.add(link);
            nodeToMatchingTopicLinks.get(targetId)!.add(link);
        }
    });

    // Get one-hop connections only from directly matching nodes
    const oneHopNodesMap = new Map<string, Node>();
    const oneHopLinks = new Set<Link>();

    // Process links for directly matched nodes
    filteredDataset.validLinks.forEach(link => {
        const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
        const targetId = typeof link.target === 'string' ? link.target : link.target.id;

        // Skip links connected to user email nodes
        if (isUserEmail(sourceId) || isUserEmail(targetId)) return;

        // Check if exactly one end of the link is a direct match
        const sourceIsDirectMatch = directMatchNodeIds.has(sourceId);
        const targetIsDirectMatch = directMatchNodeIds.has(targetId);

        if ((sourceIsDirectMatch || targetIsDirectMatch) && filters.connectionTypes.includes(link.type)) {
            oneHopLinks.add(link);

            // Add the non-matching node to our one-hop nodes
            if (sourceIsDirectMatch && !directMatchNodeIds.has(targetId)) {
                const targetNode = filteredDataset.validNodes.find(n => n.id === targetId);
                if (targetNode && !isUserEmail(targetNode.id)) oneHopNodesMap.set(targetId, targetNode);
            } else if (targetIsDirectMatch && !directMatchNodeIds.has(sourceId)) {
                const sourceNode = filteredDataset.validNodes.find(n => n.id === sourceId);
                if (sourceNode && !isUserEmail(sourceNode.id)) oneHopNodesMap.set(sourceId, sourceNode);
            }
        }
    });

    // Get the direct match nodes
    const directMatchNodes = filteredDataset.validNodes.filter(node => 
        directMatchNodeIds.has(node.id) && !isUserEmail(node.id)
    );

    // Combine direct match nodes and one-hop nodes
    const filteredNodes = [...directMatchNodes, ...Array.from(oneHopNodesMap.values())];
    const filteredLinks = Array.from(oneHopLinks);

    // Create the topic match links set
    const topicMatchLinks = new Set(
        Array.from(nodeToMatchingTopicLinks.values())
            .flatMap(links => Array.from(links))
            .map(link => {
                const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
                const targetId = typeof link.target === 'string' ? link.target : link.target.id;
                return `${sourceId}-${targetId}`;
            })
    );

    return {
        filteredNodes,
        filteredLinks,
        directMatchNodes: directMatchNodeIds,
        topicMatchLinks
    };
}, [filteredDataset, filters, debouncedSearchTerm]);

  const applyLayout = useCallback(async () => {
    if (!svgRef.current) return;

    const { filteredNodes, filteredLinks } = applyFilters();
    
    // Use the enhanced layout engine
    const result = await layoutEngine.organizeFilteredLayout(
      filteredNodes,
      filteredLinks,
      nodes,
      links,
      layoutOptions.settings
    );

    // Update visual properties for filtered vs unfiltered nodes
    const nodeElements = d3.select(svgRef.current)
      .selectAll<SVGGElement, Node>('.node')
      .data(result.nodes, (d: any) => d.id);

    nodeElements.transition()
      .duration(750)
      .attr('transform', d => `translate(${d.x},${d.y})`)
      .style('opacity', d => 
        filteredNodes.some(fn => fn.id === d.id) ? 1 : 0.3
      );

    // Update link positions and opacity
    const linkElements = d3.select(svgRef.current)
      .selectAll<SVGLineElement, Link>('.link')
      .data(result.links);

    linkElements.transition()
      .duration(750)
      .attr('x1', d => (typeof d.source === 'string' ? d.source : d.source.x) || 0)
      .attr('y1', d => (typeof d.source === 'string' ? d.source : d.source.y) || 0)
      .attr('x2', d => (typeof d.target === 'string' ? d.target : d.target.x) || 0)
      .attr('y2', d => (typeof d.target === 'string' ? d.target : d.target.y) || 0)
      .style('opacity', d => {
        const sourceId = typeof d.source === 'string' ? d.source : d.source.id;
        const targetId = typeof d.target === 'string' ? d.target : d.target.id;
        return filteredLinks.some(fl => 
          (typeof fl.source === 'string' ? fl.source : fl.source.id) === sourceId &&
          (typeof fl.target === 'string' ? fl.target : fl.target.id) === targetId
        ) ? 0.6 : 0.1;
      });

    if (result.clusters) {
      setClusters(result.clusters);
    }

    updateMinimap();
  }, [layoutOptions, applyFilters, layoutEngine, nodes, links]);

  const [rotationAngle, setRotationAngle] = useState(0);


  const toggleHorizontalLayout = useCallback(() => {
    setHorizontalLayout(prev => !prev);
    layoutEngine.setHorizontalLayout(!horizontalLayout);
    applyLayout();
  }, [horizontalLayout, layoutEngine, applyLayout]);


  const handleNodeFocus = useCallback((node: Node) => {
    setFocusedNode(node);
    
    if (!svgRef.current) return;

    const connections = filteredDataset.validLinks.filter(l => {
      const sourceId = typeof l.source === 'string' ? l.source : l.source.id;
      const targetId = typeof l.target === 'string' ? l.target : l.target.id;
      return sourceId === node.id || targetId === node.id;
    });

    const svg = d3.select(svgRef.current);
    svg.selectAll('.node')
      .transition()
      .duration(300)
      .style('opacity', (d: any) => {
        const nodeId = d.id;
        return connections.some(c => {
          const sourceId = typeof c.source === 'string' ? c.source : c.source.id;
          const targetId = typeof c.target === 'string' ? c.target : c.target.id;
          return sourceId === nodeId || targetId === nodeId;
        }) ? 1 : 0.2;
      });

    svg.selectAll('.link')
      .transition()
      .duration(300)
      .style('opacity', (d: any) => connections.includes(d) ? 0.6 : 0.1);
  }, [filteredDataset.validLinks]);

  const handleZoom = useCallback((event: d3.D3ZoomEvent<SVGSVGElement, any>) => {
    if (!svgRef.current) return;
    
    const newZoom = event.transform.k;
    setZoom(newZoom);
    
    const container = d3.select(svgRef.current).select('g');
    container.attr('transform', event.transform.toString());
    
    setViewportState(prev => ({
      ...prev,
      scale: newZoom,
      translateX: event.transform.x,
      translateY: event.transform.y,
      transform: event.transform // Store the transform
    }));
    
    updateMinimap();
  }, []);

  const initializeZoom = useCallback(() => {
    if (!svgRef.current) return;

    zoomBehavior.current = d3.zoom<SVGSVGElement, unknown>()
      .scaleExtent([0.2, 2])
      .on('zoom', handleZoom);

    d3.select(svgRef.current)
      .call(zoomBehavior.current);

    // If we have a stored transform, apply it
    if (viewportState.transform) {
      d3.select(svgRef.current)
        .call(zoomBehavior.current.transform, viewportState.transform);
    } else {
      d3.select(svgRef.current)
        .call(zoomBehavior.current.transform, d3.zoomIdentity);
    }
  }, [handleZoom, viewportState.transform]);

  const updateMinimap = useCallback(() => {
    if (!minimapRef.current || !svgRef.current) return;

    const minimap = d3.select(minimapRef.current);
    minimap.selectAll("*").remove();

    const { filteredNodes, filteredLinks } = applyFilters();
    const bounds = SmartLayout.calculateBounds(filteredNodes);
    
    const minimapWidth = 200;
    const minimapHeight = 150;
    const padding = 10;
    
    const scale = Math.min(
      (minimapWidth - 2 * padding) / (bounds.maxX - bounds.minX || 1),
      (minimapHeight - 2 * padding) / (bounds.maxY - bounds.minY || 1)
    );

    const minimapContainer = minimap.append("g")
      .attr("transform", `translate(${padding},${padding})`);

    // Draw links
    minimapContainer.selectAll("line")
      .data(filteredLinks)
      .join("line")
      .attr("x1", d => {
        const sourceX = typeof d.source === 'string' ? 0 : (d.source as Node).x ?? 0;
        return ((sourceX - bounds.minX) * scale);
      })
      .attr("y1", d => {
        const sourceY = typeof d.source === 'string' ? 0 : (d.source as Node).y ?? 0;
        return ((sourceY - bounds.minY) * scale);
      })
      .attr("x2", d => {
        const targetX = typeof d.target === 'string' ? 0 : (d.target as Node).x ?? 0;
        return ((targetX - bounds.minX) * scale);
      })
      .attr("y2", d => {
        const targetY = typeof d.target === 'string' ? 0 : (d.target as Node).y ?? 0;
        return ((targetY - bounds.minY) * scale);
      })
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6);

    // Draw nodes
    minimapContainer.selectAll("circle")
      .data(filteredNodes)
      .join("circle")
      .attr("cx", d => ((d.x ?? 0) - bounds.minX) * scale)
      .attr("cy", d => ((d.y ?? 0) - bounds.minY) * scale)
      .attr("r", 2)
      .attr("fill", (d, i) => d3.schemeCategory10[i % 10]);

    // Draw viewport rectangle
    const viewportRect = minimapContainer.append("rect")
      .attr("class", "viewport")
      .attr("stroke", "#00000033")
      .attr("fill", "none")
      .attr("stroke-width", 1);

    const updateViewportRect = () => {
      if (!svgRef.current) return;
      const svgBounds = svgRef.current.getBoundingClientRect();
      const transform = d3.zoomTransform(svgRef.current);
      
      viewportRect
        .attr("x", -transform.x * scale / transform.k)
        .attr("y", -transform.y * scale / transform.k)
        .attr("width", svgBounds.width * scale / transform.k)
        .attr("height", svgBounds.height * scale / transform.k);
    };

    updateViewportRect();
  }, [applyFilters]);

  // Initialize graph
  useEffect(() => {
    if (!svgRef.current) return;

    const { filteredNodes, filteredLinks } = applyFilters();

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const container = svg.append("g");

    if (viewportState.transform) {
      container.attr('transform', viewportState.transform.toString());
    }

    initializeZoom();

    // Draw cluster backgrounds if in cluster mode
    if (filters.layout === 'cluster' || filters.layout === 'compact') {
      container.selectAll<SVGCircleElement, ClusterInfo>(".clusters circle")
        .data(clusters)
        .join("circle")
        .attr("cx", d => d.center.x)
        .attr("cy", d => d.center.y)
        .attr("r", d => d.radius)
        .attr("fill", d => d.color)
        .attr("fill-opacity", 0.1)
        .attr("stroke", d => d.color)
        .attr("stroke-opacity", 0.3)
        .attr("stroke-width", 1);
    }

    // Create links
    const link = container.append("g")
    .attr("class", "links")
    .selectAll("line")
    .data(filteredLinks)
    .join("line")
    .attr("class", "link")
    .attr("stroke", d => {
        switch (d.type) {
            case 'Strong': return '#4FB4C1';
            case 'Regular': return '#C97083';
            default: return '#BF8E54';
        }
    })
    .attr("stroke-opacity", d => {
        switch (d.type) {
            case 'Strong': return 1.0;    // 100% opacity
            case 'Regular': return 0.5;   // 75% opacity
            default: return 0.33;          // 33% opacity
        }
    })
    .attr("stroke-width", d => {
        switch (d.type) {
            case 'Strong': return 3;
            case 'Regular': return 2;
            default: return 1;
        }
    });

    // Create nodes

    const node = container.append("g")
      .attr("class", "nodes")
      .selectAll("g")
      .data(filteredNodes)
      .join("g")
      .attr("class", "node")
      .call(drag(simulation) as any);

    node.append("circle")
      .attr("r", d => Math.max(6, Math.sqrt(d.value) * 2))
      .attr("fill", (d, i) => d3.schemeCategory10[i % 10])
      .attr("stroke", "#fff")
      .attr("stroke-width", 2)
      .attr("class", "node-circle");

    // Add circles to nodes
    node.append("circle")
      .attr("r", d => Math.max(6, Math.sqrt(d.value) * 2))
      .attr("fill", (d, i) => d3.schemeCategory10[i % 10])
      .attr("stroke", "#fff")
      .attr("stroke-width", 2);

    // Add labels to nodes
    node.append("text")
      .text(d => d.id.split('@')[0])
      .attr("x", 8)
      .attr("y", 4)
      .style("font-size", "12px")
      .style("fill", "#333")
      .style("pointer-events", "none");

    // Set up node events
    node
        .on("click", (event, d) => {
          event.stopPropagation(); // Prevent the click from bubbling up
          handleNodeFocus(d);
          onNodeClick?.(d);
        })
        .on("mouseover", (event, d) => {
          event.stopPropagation(); // Prevent the mouseover from bubbling up
          setTooltipNode({
            node: d,
            x: event.pageX,
            y: event.pageY
          });
        })
        .on("mouseout", (event) => {
          event.stopPropagation(); // Prevent the mouseout from bubbling up
          setTooltipNode(null);
        });

    // Update simulation
    simulation
      .nodes(filteredNodes)
      .force("link", d3.forceLink(filteredLinks)
        .id((d: any) => d.id)
        .distance(100))
      .on("tick", () => {
        link
          .attr("x1", d => (typeof d.source === 'string' ? d.source : (d.source as any).x))
          .attr("y1", d => (typeof d.source === 'string' ? d.source : (d.source as any).y))
          .attr("x2", d => (typeof d.target === 'string' ? d.target : (d.target as any).x))
          .attr("y2", d => (typeof d.target === 'string' ? d.target : (d.target as any).y));

        node.attr("transform", d => `translate(${d.x},${d.y})`);

        if (filters.layout === 'cluster' || filters.layout === 'compact') {
          container.selectAll<SVGCircleElement, ClusterInfo>(".clusters circle")
            .attr("cx", d => d.center.x)
            .attr("cy", d => d.center.y);
        }
      });

    simulation.alpha(1).restart();
    updateMinimap();

    return () => {
      simulation.stop();
    };
  }, [
    filteredDataset.validNodes,
    filteredDataset.validLinks,
    filters,
    width,
    height,
    simulation,
    onNodeClick,
    handleNodeFocus,
    updateMinimap,
    applyFilters,
    initializeZoom,
    clusters
  ]);


  // Drag behavior implementation
  const drag = useCallback((simulation: d3.Simulation<Node, undefined>) => {
    function dragstarted(event: any) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
      setDraggedNode(event.subject);
    }

    function dragged(event: any) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
      updateMinimap();
    }

    function dragended(event: any) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
      setDraggedNode(null);
      updateMinimap();
    }

    return d3.drag<any, Node>()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  }, [updateMinimap]);

  // Handle layout changes
  const handleLayoutChange = useCallback((newLayout: LayoutOptions['type']) => {
    setLayoutOptions(prev => ({ ...prev, type: newLayout }));
    applyLayout();
  }, [applyLayout]);

  // Handle full screen toggle
  const handleFullScreenToggle = useCallback(() => {
    setIsFullScreen(prev => !prev);
    setZoom(1);
    
    if (simulation) {
      simulation.alpha(1).restart();
      simulation.force("center", d3.forceCenter(
        isFullScreen ? width / 2 : window.innerWidth / 2,
        isFullScreen ? height / 2 : window.innerHeight / 2
      ));
    }
  }, [isFullScreen, simulation, width, height]);

  // Handle zoom controls
  const handleZoomControl = useCallback((factor: number) => {
    if (!svgRef.current || !zoomBehavior.current) return;
    
    const newZoom = Math.min(Math.max(0.2, factor), 2);
    const transform = d3.zoomIdentity.scale(newZoom);
    
    d3.select(svgRef.current)
      .transition()
      .duration(500)
      .call(zoomBehavior.current.transform, transform);

    setZoom(newZoom);
    setViewportState(prev => ({
      ...prev,
      scale: newZoom,
      transform
    }));
  }, []);

  // Reset focus
  const resetFocus = useCallback(() => {
    setFocusedNode(null);
    if (!svgRef.current) return;
    
    const svg = d3.select(svgRef.current);
    svg.selectAll('.node, .link')
      .transition()
      .duration(300)
      .style('opacity', null);
  }, []);

  useEffect(() => {
    return () => {
      zoomBehavior.current = undefined;
    };
  }, []);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (isFullScreen) {
          setIsFullScreen(false);
        } else if (focusedNode) {
          resetFocus();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [isFullScreen, focusedNode, resetFocus]);

  useEffect(() => {
      const handleWindowClick = () => {
        setTooltipNode(null);
      };

      window.addEventListener('click', handleWindowClick);
      
      return () => {
        window.removeEventListener('click', handleWindowClick);
      };
    }, []);

  // Cleanup
  useEffect(() => {
    return () => {
      layoutEngine.cleanup();
      simulation.stop();
    };
  }, [layoutEngine, simulation]);

  return (
    <>
      {isFullScreen && (
        <FullScreenOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        />
      )}
      
       <GraphContainer
          isFullScreen={isFullScreen}
          as={motion.div}
          layout
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          className="ph-no-capture" // don't capture in PH
        >
        <FiltersPanel
          initial={{ x: -300 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          isMinimized={isFiltersPanelMinimized}
        >
          <FiltersPanelHeader onClick={() => setIsFiltersPanelMinimized(!isFiltersPanelMinimized)}>
            <h3 style={{ margin: 0, fontSize: '16px', color: '#333' }}>Filters</h3>
            <MinimizeButton
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {isFiltersPanelMinimized ? (
                <svg viewBox="0 0 24 24" fill="currentColor">
                  <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
                </svg>
              ) : (
                <svg viewBox="0 0 24 24" fill="currentColor">
                  <path d="M19 13H5v-2h14v2z"/>
                </svg>
              )}
            </MinimizeButton>
          </FiltersPanelHeader>

          <AnimatePresence>
            {!isFiltersPanelMinimized && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
              >
                <FilterGroup>
                  <h4>Search</h4>
                  <input
                    type="text"
                    value={filters.searchTerm}
                    onChange={(e) => setFilters(prev => ({ ...prev, searchTerm: e.target.value }))}
                    placeholder="Search nodes..."
                    style={{
                      width: '100%',
                      padding: '8px',
                      borderRadius: '8px',
                      border: '1px solid #ddd'
                    }}
                  />
                </FilterGroup>

                <FilterGroup>
                  <h4>Connection Types</h4>
                  <div style={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: '8px' 
                  }}>
                    {(['Strong', 'Regular', 'Weak'] as const).map(type => (
                      <label
                        key={type}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          cursor: 'pointer',
                          userSelect: 'none'
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={filters.connectionTypes.includes(type)}
                          onChange={(e) => {
                            setFilters(prev => ({
                              ...prev,
                              connectionTypes: e.target.checked
                                ? [...prev.connectionTypes, type]
                                : prev.connectionTypes.filter(t => t !== type)
                            }));
                          }}
                          style={{ margin: 0 }}
                        />
                        <div style={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: '8px' 
                        }}>
                          <div
                            style={{
                              width: '16px',
                              height: '2px',
                              background: type === 'Strong' ? '#5DBEA0' 
                                       : type === 'Regular' ? '#B17175' 
                                       : '#9CAB62',
                              opacity: type === 'Strong' ? 1.0 
                                      : type === 'Regular' ? 0.75 
                                      : 0.5
                            }}
                          />
                          {type}
                        </div>
                      </label>
                    ))}
                  </div>
                </FilterGroup>

                <FilterGroup>
                  <h4>Minimum Connections</h4>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={filters.minConnections}
                    onChange={(e) => setFilters(prev => ({ 
                      ...prev, 
                      minConnections: parseInt(e.target.value) 
                    }))}
                    style={{ width: '100%' }}
                  />
                  <div style={{ textAlign: 'center', fontSize: '12px', color: '#666' }}>
                    {filters.minConnections}
                  </div>
                </FilterGroup>
              </motion.div>
            )}
          </AnimatePresence>
        </FiltersPanel>

        <ControlsContainer>
          <ControlGroup>
            <ControlButton
              onClick={toggleHorizontalLayout}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              title={horizontalLayout ? "Switch to vertical layout" : "Switch to horizontal layout"}
            >
              <svg 
                viewBox="0 0 24 24" 
                width="24" 
                height="24"
              >
                <path
                  fill="currentColor"
                  d="M12 3C16.97 3 21 7.03 21 12C21 13.57 20.61 15.03 19.93 16.3L18.31 14.68C18.75 13.84 19 12.95 19 12C19 8.13 15.87 5 12 5L12 8L8 4L12 0L12 3ZM12 21C7.03 21 3 16.97 3 12C3 10.43 3.39 8.97 4.07 7.7L5.69 9.32C5.25 10.16 5 11.05 5 12C5 15.87 8.13 19 12 19L12 16L16 20L12 24L12 21Z"
                />
              </svg>
            </ControlButton>
          </ControlGroup>
        </ControlsContainer>

        

        <AnimatePresence>
          {tooltipNode && (
            <NodeTooltip
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              style={{
                left: tooltipNode.x + 10,
                top: tooltipNode.y + 10
              }}
              onClick={(e) => e.stopPropagation()} // Prevent clicks on the tooltip from closing it
            >
              <div style={{ fontWeight: 'bold' }}>{tooltipNode.node.id}</div>
              <div>Connections: {links.filter(l => {
                const sourceId = typeof l.source === 'string' ? l.source : l.source.id;
                const targetId = typeof l.target === 'string' ? l.target : l.target.id;
                return sourceId === tooltipNode.node.id || targetId === tooltipNode.node.id;
              }).length}</div>
            </NodeTooltip>
          )}

          {focusedNode && (
    <FocusedNodeView
        initial={{ opacity: 0, x: 300 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 300 }}
    >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
            <h3 style={{ margin: 0 }}>{focusedNode.id}</h3>
            <ControlButton onClick={resetFocus}>
                <svg viewBox="0 0 24 24">
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                </svg>
            </ControlButton>
        </div>
        <div>
            <h4>Connections</h4>
            <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                {getStrongestLinks(
                    links.filter(l => {
                        const sourceId = typeof l.source === 'string' ? l.source : l.source.id;
                        const targetId = typeof l.target === 'string' ? l.target : l.target.id;
                        return sourceId === focusedNode.id || targetId === focusedNode.id;
                    })
                ).map((link, index) => {
                    const sourceId = typeof link.source === 'string' ? link.source : link.source.id;
                    const targetId = typeof link.target === 'string' ? link.target : link.target.id;
                    const connectedId = sourceId === focusedNode.id ? targetId : sourceId;
                    
                    // Create a unique key using both node IDs and the connection type
                    const linkKey = `${sourceId}-${targetId}-${link.type}-${index}`;
                    
                    return (
                        <li 
                            key={linkKey}
                            style={{ 
                                marginBottom: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}
                        >
                            
                            <span>{connectedId}</span>
                            <span style={{ color: '#666', fontSize: '0.9em' }}>
                                ({link.type})
                            </span>
                            
                        </li>
                    );
                })}
            </ul>
        </div>
    </FocusedNodeView>
)}
        </AnimatePresence>

        <SVG
          ref={svgRef}
          isFullScreen={isFullScreen}
          width={isFullScreen ? window.innerWidth : width}
          height={isFullScreen ? window.innerHeight : height}
        />
      </GraphContainer>
    </>
  );
};

export default inject("UserStore")(observer(SafeForceGraph));