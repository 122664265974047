import React, { useState, useEffect } from 'react';
import { FiChevronLeft, FiChevronRight, FiCpu, FiFilter, FiSearch, FiClock, FiCalendar } from 'react-icons/fi';
import { LeftSidebar as LeftSidebarStyled, CollapsedPanelControls, LeftPanelCollapseButton } from '../styles/KnowledgeGraphExplorerStyles';
import { ControlPanel } from '../panels/ControlPanel';
import { EnhancedExplorationPanel } from '../panels/EnhancedExplorationPanel';
import { SuggestedQueriesPanel } from '../panels/SuggestedQueriesPanel';
import { EnhancedSuggestedQueriesPanel, SuggestionsFloatingButton } from '../panels/EnhancedSuggestedQueriesPanel';
import { VoiceCommandButton } from '../common/VoiceCommandButton';
import { ExpandableSearchBar } from '../common/ExpandableSearchBar';

interface LeftSidebarProps {
  isCollapsed: boolean;
  onCollapseToggle: () => void;
  darkMode: boolean;
  activeTab: 'explore' | 'insights' | 'suggestions';
  onTabChange: (tab: 'explore' | 'insights' | 'suggestions') => void;
  onSearch: (query: string) => void;
  onAdvancedSearch: (query: string) => void;
  onFilter: (filters: any) => void;
  onVoiceCommand: (command: string) => void;
  isProcessingVoice: boolean;
  maxHops?: number;
  onMaxHopsChange?: (hops: number) => void;
  communityDetails?: {
    names: Record<string, string>;
    nodeCounts: Record<string, number>;
    communityMap: Record<string, number>;
  };
  suggestedQueries?: any[];
  isLoadingCommunities?: boolean;
  isLoadingInsights?: boolean;
  isLoadingSuggestions?: boolean;
  entityTypes?: string[];
  relationshipTypes?: string[];
  onHighlightCommunity?: (communityId: string) => void;
  historyEntryCount?: number;
  onShowSearchHistory?: () => void;
}

const LeftSidebar: React.FC<LeftSidebarProps> = ({
  isCollapsed,
  onCollapseToggle,
  darkMode,
  activeTab,
  onTabChange,
  onSearch,
  onAdvancedSearch,
  onFilter,
  onVoiceCommand,
  isProcessingVoice,
  maxHops,
  onMaxHopsChange,
  communityDetails,
  suggestedQueries,
  isLoadingCommunities,
  isLoadingInsights,
  isLoadingSuggestions,
  entityTypes,
  relationshipTypes,
  onHighlightCommunity,
  historyEntryCount = 0,
  onShowSearchHistory
}) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const [showSuggestionsModal, setShowSuggestionsModal] = useState(false);
  
  // Determine if we have suggestions to show
  const hasSuggestions = Array.isArray(suggestedQueries) && 
    suggestedQueries.filter(s => !s?._dataStatus).length > 0;

  // Sync internal state with props
  useEffect(() => {
    setCollapsed(isCollapsed);
  }, [isCollapsed]);

  // Handle collapse with transition protection
  const handleCollapseToggle = () => {
    setIsTransitioning(true);
    
    // Call parent handler
    onCollapseToggle();
    
    // Allow time for transition to complete
    setTimeout(() => {
      setIsTransitioning(false);
    }, 350); // 350ms to ensure it completes after 300ms transition
  };
  
  // Toggle suggestions modal
  const toggleSuggestionsModal = () => {
    setShowSuggestionsModal(!showSuggestionsModal);
  };

  return (
    <>
      <LeftSidebarStyled 
        $collapsed={collapsed}
        style={isTransitioning ? { pointerEvents: 'none' } : undefined}
      >
        {collapsed ? (
          <CollapsedPanelControls $darkMode={darkMode}>
            <ExpandableSearchBar 
              onSearch={onSearch}
              onAdvancedSearch={onAdvancedSearch}
              darkMode={darkMode}
            />
            
            <span title="Expand panels" onClick={handleCollapseToggle}>
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="9" y1="3" x2="9" y2="21"></line>
              </svg>
            </span>
            
            <span title="Insights">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
                onClick={() => onTabChange('insights')}
                style={{color: activeTab === 'insights' ? (darkMode ? '#825AFF' : '#5D3FD3') : undefined}}
              >
                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                <polyline points="3.29 7 12 12 20.71 7"></polyline>
                <line x1="12" y1="22" x2="12" y2="12"></line>
              </svg>
            </span>
            
            <span title="Calendar Suggestions">
              <FiCalendar 
                size={20} 
                onClick={toggleSuggestionsModal} 
                style={{
                  color: activeTab === 'suggestions' ? (darkMode ? '#825AFF' : '#5D3FD3') : (darkMode ? '#F1F5F9' : '#334155')
                }}
              />
            </span>
            
            {/* Voice Command Button */}
            <span title="Voice Command">
              <VoiceCommandButton
                onCommand={onVoiceCommand}
                isProcessing={isProcessingVoice}
                darkMode={darkMode}
                isCollapsed={true}
              />
            </span>
            
            {/* Search History Button */}
            {historyEntryCount > 0 && onShowSearchHistory && (
              <span title="Search History" onClick={onShowSearchHistory} style={{ position: 'relative' }}>
                <FiClock size={20} style={{color: darkMode ? '#F1F5F9' : '#334155'}} />
                {historyEntryCount > 0 && (
                  <span style={{
                    position: 'absolute',
                    top: '-5px',
                    right: '-5px',
                    backgroundColor: darkMode ? '#3B82F6' : '#2563EB',
                    color: 'white',
                    borderRadius: '12px',
                    minWidth: '18px',
                    height: '18px',
                    fontSize: '10px',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 5px'
                  }}>
                    {historyEntryCount > 99 ? '99+' : historyEntryCount}
                  </span>
                )}
              </span>
            )}
            
            {/* Theme toggle */}
            <span title={darkMode ? "Light mode" : "Dark mode"} onClick={() => {
              // Toggle dark mode (action expected to be propagated to parent)
              document.dispatchEvent(new CustomEvent('toggleDarkMode', {
                detail: { newMode: !darkMode }
              }));
            }}>
              {darkMode ? (
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                  width="24"
                  height="24"
                >
                  <circle cx="12" cy="12" r="5"></circle>
                </svg>
              ) : (
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                  width="24"
                  height="24"
                >
                  <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                </svg>
              )}
            </span>
          </CollapsedPanelControls>
        ) : (
          <>
            <div className="custom-tabs">
              <div className="tab-buttons">
                <button 
                  className={activeTab === 'explore' ? 'active' : ''} 
                  onClick={() => onTabChange('explore')}
                >
                  <FiFilter /> Explore
                </button>
                <button 
                  className={activeTab === 'insights' ? 'active' : ''} 
                  onClick={() => onTabChange('insights')}
                >
                  <FiCpu /> Insights
                </button>
                <button 
                  className={activeTab === 'suggestions' ? 'active' : ''} 
                  onClick={() => onTabChange('suggestions')}
                >
                  <FiCalendar /> Calendar Insights
                </button>
              </div>
            </div>
            
            {activeTab === 'explore' && (
              <ControlPanel 
                onSearch={onSearch}
                onAdvancedSearch={onAdvancedSearch}
                onFilter={onFilter}
                onVoiceCommand={onVoiceCommand}
                maxHops={maxHops || 1}
                onMaxHopsChange={onMaxHopsChange || (() => {})}
                isProcessingVoice={isProcessingVoice}
                darkMode={darkMode}
                onLayoutChange={() => {}}
                onToggleDarkMode={() => {}}
                nodeTypes={(window as any).__allNodeTypes || entityTypes || []}
                edgeTypes={relationshipTypes || []}
                selectedNodeTypes={entityTypes || []}
                selectedEdgeTypes={relationshipTypes || []}
              />
            )}
            
            {activeTab === 'insights' && (
              <EnhancedExplorationPanel 
                darkMode={darkMode}
                onSearch={onAdvancedSearch}
                onApplyFilter={onFilter}
              />
            )}
            
            {activeTab === 'suggestions' && (
              <EnhancedSuggestedQueriesPanel
                darkMode={darkMode}
                suggestions={suggestedQueries || []}
                isLoading={isLoadingSuggestions || false}
                onApplyFilter={onFilter}
                onSearch={onAdvancedSearch}
              />
            )}
          </>
        )}
        
        <LeftPanelCollapseButton 
          onClick={handleCollapseToggle}
          $darkMode={darkMode}
          $isCollapsed={collapsed}
        >
          {collapsed ? <FiChevronRight /> : <FiChevronLeft />}
        </LeftPanelCollapseButton>
      </LeftSidebarStyled>
      
      {/* Floating Calendar Suggestions Button in Collapsed Mode */}
      {collapsed && hasSuggestions && (
        <SuggestionsFloatingButton 
          $darkMode={darkMode}
          onClick={toggleSuggestionsModal}
          $hasNotification={hasSuggestions}
          title="Calendar Insights"
        >
          <FiCalendar size={24} />
        </SuggestionsFloatingButton>
      )}
      
      {/* Suggestions Modal */}
      {showSuggestionsModal && (
        <EnhancedSuggestedQueriesPanel
          darkMode={darkMode}
          suggestions={suggestedQueries || []}
          isLoading={isLoadingSuggestions || false}
          onApplyFilter={onFilter}
          onSearch={onAdvancedSearch}
          isModalView={true}
          onClose={toggleSuggestionsModal}
        />
      )}
    </>
  );
};

export default LeftSidebar;