import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiClock, FiTrash2, FiChevronRight, FiX } from 'react-icons/fi';
import { SearchHistoryEntry } from '../types/types';
import { searchHistoryService } from '../services/SearchHistoryService';
import Logger from '../../../util/Logger';

interface SearchHistoryPanelProps {
  darkMode: boolean;
  onSelectEntry: (entry: SearchHistoryEntry) => void;
  onClose: () => void;
  isVisible: boolean;
  historyEntries?: SearchHistoryEntry[];
  refreshEntries?: () => void;
}

const HistoryPanelContainer = styled.div<{ $darkMode: boolean; $isVisible: boolean }>`
  position: absolute;
  top: 20px;
  left: ${props => props.$isVisible ? '20px' : '-350px'};
  width: 320px;
  max-height: 80vh;
  background-color: ${props => props.$darkMode ? '#1E293B' : '#FFFFFF'};
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, ${props => props.$darkMode ? 0.5 : 0.2});
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow: hidden;
  transition: left 0.3s ease, opacity 0.3s ease;
  opacity: ${props => props.$isVisible ? 1 : 0};
  pointer-events: ${props => props.$isVisible ? 'auto' : 'none'};
  border: 1px solid ${props => props.$darkMode ? '#334155' : '#E2E8F0'};
`;

const HistoryHeader = styled.div<{ $darkMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${props => props.$darkMode ? '#334155' : '#E2E8F0'};
  background-color: ${props => props.$darkMode ? '#1E293B' : '#F8FAFC'};
`;

const HistoryTitle = styled.h3<{ $darkMode: boolean }>`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: ${props => props.$darkMode ? '#F8FAFC' : '#1E293B'};
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 8px;
  }
`;

const CloseButton = styled.button<{ $darkMode: boolean }>`
  background: none;
  border: none;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: ${props => props.$darkMode ? '#334155' : '#F1F5F9'};
    color: ${props => props.$darkMode ? '#F1F5F9' : '#0F172A'};
  }
`;

const HistoryList = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  padding: 8px 0;
`;

const NoHistoryMessage = styled.div<{ $darkMode: boolean }>`
  padding: 20px;
  text-align: center;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  font-style: italic;
`;

const HistoryItem = styled.div<{ $darkMode: boolean }>`
  padding: 12px 16px;
  border-bottom: 1px solid ${props => props.$darkMode ? '#334155' : '#F1F5F9'};
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  
  &:hover {
    background-color: ${props => props.$darkMode ? '#334155' : '#F8FAFC'};
    
    .history-item-actions {
      opacity: 1;
    }
  }
  
  &:last-child {
    border-bottom: none;
  }
`;

const HistoryItemQuery = styled.div<{ $darkMode: boolean }>`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.$darkMode ? '#F1F5F9' : '#1E293B'};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4;
`;

const HistoryItemSummary = styled.div<{ $darkMode: boolean }>`
  font-size: 12px;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const HistoryItemTime = styled.div<{ $darkMode: boolean }>`
  font-size: 11px;
  color: ${props => props.$darkMode ? '#64748B' : '#94A3B8'};
  margin-top: 4px;
`;

const HistoryItemActions = styled.div<{ $darkMode: boolean }>`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  opacity: 0;
  transition: opacity 0.2s ease;
  background-color: ${props => props.$darkMode ? 'rgba(30, 41, 59, 0.8)' : 'rgba(248, 250, 252, 0.8)'};
  border-radius: 4px;
  padding: 2px;
`;

const HistoryItemAction = styled.button<{ $darkMode: boolean }>`
  background: none;
  border: none;
  color: ${props => props.$darkMode ? '#94A3B8' : '#64748B'};
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: ${props => props.$darkMode ? '#334155' : '#E2E8F0'};
    color: ${props => props.$darkMode ? '#F1F5F9' : '#0F172A'};
  }
`;

const HistoryFooter = styled.div<{ $darkMode: boolean }>`
  padding: 12px 16px;
  border-top: 1px solid ${props => props.$darkMode ? '#334155' : '#E2E8F0'};
  display: flex;
  justify-content: flex-end;
`;

const ClearHistoryButton = styled.button<{ $darkMode: boolean }>`
  background-color: ${props => props.$darkMode ? '#475569' : '#F1F5F9'};
  color: ${props => props.$darkMode ? '#F1F5F9' : '#334155'};
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: ${props => props.$darkMode ? '#334155' : '#E2E8F0'};
  }
  
  svg {
    font-size: 14px;
  }
`;

export const SearchHistoryPanel: React.FC<SearchHistoryPanelProps> = ({
  darkMode,
  onSelectEntry,
  onClose,
  isVisible,
  historyEntries: providedEntries,
  refreshEntries
}) => {
  const [internalHistoryEntries, setInternalHistoryEntries] = useState<SearchHistoryEntry[]>([]);
  
  // Use the provided entries if available, otherwise use internal state
  const historyEntries = providedEntries || internalHistoryEntries;
  
  // Format time string from timestamp
  const formatTime = (timestamp: number): string => {
    const date = new Date(timestamp);
    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();
    
    // Format for today vs. other days
    if (isToday) {
      return `Today at ${date.toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' })}`;
    } else {
      return date.toLocaleDateString(undefined, { 
        month: 'short', 
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
      });
    }
  };
  
  // Load history entries when component mounts or when isVisible changes
  useEffect(() => {
    // Only load/refresh entries when the panel becomes visible
    if (isVisible) {
      if (!providedEntries && loadHistoryEntries) {
        loadHistoryEntries();
      } else if (refreshEntries) {
        // Use the refresh function if available
        refreshEntries();
      }
    }
  }, [isVisible]); // Only depend on visibility to prevent infinite loops
  
  // Function to load entries from service
  const loadHistoryEntries = () => {
    try {
      const entries = searchHistoryService.getEntries();
      // Only log in development mode to reduce noise
      if (process.env.NODE_ENV === 'development') {
        console.log(`[Panel] Loaded ${entries.length} search history entries`);
      }
      
      setInternalHistoryEntries(entries);
    } catch (error) {
      Logger.error('Error loading search history entries:', error);
    }
  };
  
  // Handle click on history item
  const handleSelectEntry = (entry: SearchHistoryEntry) => {
    console.log(`[Panel] Selected entry: ${entry.id}`, {
      query: entry.query,
      nodeCount: entry.filteredNodes?.length || 0,
      timestamp: new Date(entry.timestamp).toLocaleString()
    });
    
    // Make sure we have the latest entry from the service
    const freshEntry = searchHistoryService.getEntryById(entry.id);
    if (freshEntry) {
      console.log(`[Panel] Using fresh entry from service`);
      onSelectEntry(freshEntry);
    } else {
      console.log(`[Panel] Using entry from local state`);
      onSelectEntry(entry);
    }
    
    onClose(); // Close the panel after selection
  };
  
  // Handle deleting an entry
  const handleDeleteEntry = (event: React.MouseEvent, entryId: string) => {
    event.stopPropagation(); // Prevent triggering the parent click
    
    try {
      searchHistoryService.deleteEntry(entryId);
      
      // Update the entries - either reload or refresh based on mode
      if (providedEntries && refreshEntries) {
        refreshEntries(); // Use provided refresh function if available
      } else {
        loadHistoryEntries(); // Otherwise reload directly
      }
      
      console.log(`Deleted entry ${entryId}`);
    } catch (error) {
      Logger.error('Error deleting search history entry:', error);
    }
  };
  
  // Handle clearing all history
  const handleClearHistory = () => {
    try {
      searchHistoryService.clearHistory();
      
      // Update the entries - either reload or refresh based on mode
      if (providedEntries && refreshEntries) {
        refreshEntries(); // Use provided refresh function if available
      } else {
        setInternalHistoryEntries([]); // Clear internal entries
      }
      
      console.log(`Cleared all history entries`);
    } catch (error) {
      Logger.error('Error clearing search history:', error);
    }
  };
  
  // Truncate text to specified length
  const truncateText = (text: string, maxLength: number): string => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  };
  
  return (
    <HistoryPanelContainer $darkMode={darkMode} $isVisible={isVisible}>
      <HistoryHeader $darkMode={darkMode}>
        <HistoryTitle $darkMode={darkMode}>
          <FiClock /> Search History
        </HistoryTitle>
        <CloseButton $darkMode={darkMode} onClick={onClose}>
          <FiX size={18} />
        </CloseButton>
      </HistoryHeader>
      
      <HistoryList>
        {historyEntries.length === 0 ? (
          <NoHistoryMessage $darkMode={darkMode}>
            No search history yet
          </NoHistoryMessage>
        ) : (
          historyEntries.map((entry, index) => (
            <HistoryItem 
              key={entry.id} 
              $darkMode={darkMode}
              data-index={index}
              data-id={entry.id}
              onClick={() => handleSelectEntry(entry)}
            >
              <HistoryItemQuery $darkMode={darkMode}>
                {truncateText(entry.query, 100)}
              </HistoryItemQuery>
              
              <HistoryItemSummary $darkMode={darkMode}>
                {truncateText(entry.humanSummary, 80)}
              </HistoryItemSummary>
              
              <HistoryItemTime $darkMode={darkMode}>
                {formatTime(entry.timestamp)}
              </HistoryItemTime>
              
              <HistoryItemActions 
                className="history-item-actions"
                $darkMode={darkMode}
              >
                <HistoryItemAction 
                  $darkMode={darkMode}
                  onClick={(e) => handleDeleteEntry(e, entry.id)}
                  title="Delete from history"
                >
                  <FiTrash2 size={14} />
                </HistoryItemAction>
                
                <HistoryItemAction 
                  $darkMode={darkMode}
                  onClick={() => handleSelectEntry(entry)}
                  title="Load this search"
                >
                  <FiChevronRight size={14} />
                </HistoryItemAction>
              </HistoryItemActions>
            </HistoryItem>
          ))
        )}
      </HistoryList>
      
      {historyEntries.length > 0 && (
        <HistoryFooter $darkMode={darkMode}>
          <ClearHistoryButton 
            $darkMode={darkMode}
            onClick={handleClearHistory}
          >
            <FiTrash2 /> Clear History
          </ClearHistoryButton>
        </HistoryFooter>
      )}
    </HistoryPanelContainer>
  );
};

export default SearchHistoryPanel;