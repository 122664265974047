// Main component
import KnowledgeGraphExplorer from './KnowledgeGraphExplorer';

// Component sub-parts
export { default as LeftSidebar } from './components/LeftSidebar';
export { default as RightSidebar } from './components/RightSidebar';
export { default as GraphDisplay } from './components/GraphDisplay';

// Hooks
export { useKnowledgeGraphState } from './hooks/state/useKnowledgeGraphState';

// Utilities and configuration
export { default as themeConfig, getTheme } from './config/themeConfig';

// Types re-export
export * from './types/types';

// Default export for backward compatibility
export { KnowledgeGraphExplorer };
export default KnowledgeGraphExplorer;
