import React from 'react';
import L from "leaflet";

import { Text, H4Text, LINK } from '../../../components/common/Typography';
import Button from '../../../components/common/Button';
import MatchComparisonBar from './bar/MatchComparisonBar';
import {
    StarIcon,
    LocationIcon,
    GlobeIcon
} from 'components/common/Icon';

import '../../../index.scss';
import '../../../brand/index.scss'
import './ResultCardBase.scss';

const MAP_LOCATION_COLOR = '#EF6820';

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3
});

const CheckMark = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="#EEEBFD" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.2471 6.15835L8.28042 11.9167L6.69709 10.225C6.40542 9.95002 5.94709 9.93335 5.61375 10.1667C5.28875 10.4084 5.19709 10.8334 5.39709 11.175L7.27209 14.225C7.45542 14.5084 7.77209 14.6834 8.13042 14.6834C8.47209 14.6834 8.79709 14.5084 8.98042 14.225C9.28042 13.8334 15.0054 7.00835 15.0054 7.00835C15.7554 6.24169 14.8471 5.56669 14.2471 6.15002V6.15835Z" fill="#7F56D9" />
    </svg>
);

type ResultCardProps = {
    info?: {
        index: number;
        numPeople?: number;
        numBags?: number;
        numDoors?: number;
        numBeds?: number;
    }

    ranking?: {
        allOptions?: any[];
        score?: any;
        ranking_reasoning?: string;
    }

    title?: {
        title: string;
        stars?: number;
        subTitle?: string;
        location?: string;
        website?: string;
    }

    hero?: {
        companyName?: string;
        rating?: number;
        reviews?: number;
    }

    flightLegs?: any;

    gps_coordinates?: {
        lat: number;
        lng: number;
    }

    details?: {
        startLabel?: string;
        startTime?: string;
        startLocation?: string;
        endLabel?: string;
        endTime?: string;
        endLocation?: string;
    }

    cta?: {
        formatPrice?: boolean;

        ctaPrice: number | string;
        booking_url: string | string[];

        ctaSub?: string;
        ctaText?: string;
    }

    features?: string[];

    renderMapOnly?: boolean;
}

type ResultCardState = {
    map: any;
}

class ResultCardBase extends React.Component<ResultCardProps, ResultCardState> {
    mapRef: React.RefObject<HTMLDivElement>;

    constructor(props: ResultCardProps) {
        super(props);

        this.state = {
            map: null
        };

        this.mapRef = React.createRef();
    }

    componentDidMount(): void {
        this.drawMap();
    }

    drawMap = () => {
        const { gps_coordinates } = this.props;
        const lat = gps_coordinates?.lat;
        const lng = gps_coordinates?.lng;

        if (!this.state.map && lat && lng && this.mapRef.current) {
            const zoomScale = 16;

            const initializedMap = L.map(this.mapRef.current, {
                zoomControl: false,
                attributionControl: false,
            }).setView([lat, lng], zoomScale);

            const tileLayer = L.tileLayer(
                "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ).addTo(initializedMap);

            tileLayer.getContainer().style.filter = "grayscale(100%)";

            // @ts-ignore
            L.svg({ clickable: true }).addTo(initializedMap);

            L.circleMarker([lat, lng], {
                color: MAP_LOCATION_COLOR,
                fillColor: MAP_LOCATION_COLOR,
                fillOpacity: 0.5,
                radius: 13
            }).addTo(initializedMap);

            this.setState({ map: initializedMap });
        }
    };

    renderIconBar = () => {
        const {
            numPeople,
            numBags,
            numBeds,
            numDoors,
            index
        } = this.props?.info;

        if (!numPeople && !numBags && !numBeds && !numDoors && index > 0) {
            return <></>;
        }

        return (
            <div className="adapter-result-card--content--icon-bar">
                <div className="justify-start items-center gap-4 flex">

                    {numPeople && (
                        <div className="justify-start items-center gap-1.5 flex">
                            <div className="w-5 h-5 px-[3.33px] py-[2.50px] justify-center items-center flex overflow-hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M16.6666 17.5C16.6666 16.337 16.6666 15.7555 16.5231 15.2824C16.1999 14.217 15.3662 13.3834 14.3009 13.0602C13.8277 12.9167 13.2462 12.9167 12.0832 12.9167H7.91659C6.75362 12.9167 6.17213 12.9167 5.69897 13.0602C4.63363 13.3834 3.79995 14.217 3.47678 15.2824C3.33325 15.7555 3.33325 16.337 3.33325 17.5M13.7499 6.25C13.7499 8.32107 12.071 10 9.99992 10C7.92885 10 6.24992 8.32107 6.24992 6.25C6.24992 4.17893 7.92885 2.5 9.99992 2.5C12.071 2.5 13.7499 4.17893 13.7499 6.25Z" stroke="#5C6070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="leading-tight">
                                <Text fontSize='s'>{numPeople}</Text>
                            </div>
                        </div>
                    )}

                    {numBags && (
                        <div className="justify-start items-center gap-1.5 flex">
                            <div className="w-5 h-5 px-[1.67px] py-[2.50px] justify-center items-center flex overflow-hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M6.66675 17.5V5.83333C6.66675 5.05836 6.66675 4.67087 6.75193 4.35295C6.9831 3.49022 7.65697 2.81635 8.5197 2.58519C8.83762 2.5 9.22511 2.5 10.0001 2.5C10.7751 2.5 11.1625 2.5 11.4805 2.58519C12.3432 2.81635 13.0171 3.49022 13.2482 4.35295C13.3334 4.67087 13.3334 5.05836 13.3334 5.83333V11.25M13.3334 14.5833V17.5M5.66675 17.5H14.3334C15.7335 17.5 16.4336 17.5 16.9684 17.2275C17.4388 16.9878 17.8212 16.6054 18.0609 16.135C18.3334 15.6002 18.3334 14.9001 18.3334 13.5V9.83333C18.3334 8.4332 18.3334 7.73314 18.0609 7.19836C17.8212 6.72795 17.4388 6.3455 16.9684 6.10582C16.4336 5.83333 15.7335 5.83333 14.3334 5.83333H5.66675C4.26662 5.83333 3.56655 5.83333 3.03177 6.10582C2.56137 6.3455 2.17892 6.72795 1.93923 7.19836C1.66675 7.73314 1.66675 8.4332 1.66675 9.83333V13.5C1.66675 14.9001 1.66675 15.6002 1.93923 16.135C2.17892 16.6054 2.56137 16.9878 3.03177 17.2275C3.56655 17.5 4.26662 17.5 5.66675 17.5ZM10.6667 14.5833H14.7501C14.9834 14.5833 15.1001 14.5833 15.1892 14.5379C15.2676 14.498 15.3314 14.4342 15.3713 14.3558C15.4167 14.2667 15.4167 14.15 15.4167 13.9167V11.9167C15.4167 11.6833 15.4167 11.5666 15.3713 11.4775C15.3314 11.3991 15.2676 11.3354 15.1892 11.2954C15.1001 11.25 14.9834 11.25 14.7501 11.25H10.6667C10.4334 11.25 10.3167 11.25 10.2276 11.2954C10.1492 11.3354 10.0854 11.3991 10.0455 11.4775C10.0001 11.5666 10.0001 11.6833 10.0001 11.9167V13.9167C10.0001 14.15 10.0001 14.2667 10.0455 14.3558C10.0854 14.4342 10.1492 14.498 10.2276 14.5379C10.3167 14.5833 10.4334 14.5833 10.6667 14.5833Z" stroke="#5C6070" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="leading-tight">
                                <Text fontSize='s'>{numBags}</Text>
                            </div>
                        </div>
                    )}

                    {numDoors && (
                        <div className="justify-start items-center gap-1.5 flex">
                            <div className="w-5 h-5 px-px py-0.5 justify-center items-center flex overflow-hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M16.1108 1.8349H16.111C16.9205 1.83565 17.6962 2.1574 18.2686 2.72965C18.8409 3.30186 19.1633 4.07757 19.164 4.88724V4.88738V15.1146V15.1148C19.1633 15.9238 18.8409 16.7003 18.2685 17.272C17.6963 17.8442 16.9206 18.1666 16.111 18.1673H16.1109H5.59819H5.59777V18.0173C5.34497 18.018 5.10578 17.902 4.94894 17.7029L16.1108 1.8349ZM16.1108 1.8349H8.98482H16.1108ZM6.57396 4.22574L6.57424 4.22538C7.1516 3.48149 8.04072 3.04751 8.98245 3.05092H8.983L16.1106 3.05092C17.1245 3.05224 17.9461 3.87334 17.9474 4.88691V9.39171H2.53894L6.57396 4.22574ZM16.1108 16.9486H5.89299C5.9402 16.6829 5.9643 16.4131 5.9649 16.1429L5.9649 16.1422C5.96268 15.0105 5.55364 13.918 4.8117 13.0635L4.81165 13.0634C4.09823 12.2426 3.12296 11.6947 2.05336 11.5111V10.6077H17.9478V15.1119V15.1619C17.9203 16.1527 17.1086 16.948 16.1108 16.9486ZM15.427 11.9017H13.951C13.6145 11.9017 13.3427 12.1745 13.3427 12.51C13.3427 12.8463 13.6147 13.1183 13.951 13.1183H15.4277C15.7644 13.1183 16.0353 12.846 16.0353 12.51C16.0353 12.1745 15.7635 11.9017 15.427 11.9017Z" fill="#5C6070" stroke="#5C6070" strokeWidth="0.3" />
                                </svg>
                            </div>
                            <div className="leading-tight">
                                <Text fontSize='s'>{numDoors}</Text>
                            </div>
                        </div>
                    )}

                    {numBeds && (
                        <div className="justify-start items-center gap-1.5 flex">
                            <div className="w-5 h-5 pr-[0.83px] pt-[4.17px] pb-[2.50px] justify-center items-center flex overflow-hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M18.5022 7.37147C18.1354 7.37147 17.8378 7.66563 17.8378 8.02817V9.77512H7.81493V8.8294C7.81493 7.58354 6.78972 6.57033 5.52925 6.57033H1.32888V4.82338C1.32888 4.46083 1.03126 4.16667 0.66444 4.16667C0.297625 4.16667 0 4.46083 0 4.82338V16.8433C0 17.2058 0.297625 17.5 0.66444 17.5C1.03126 17.5 1.32888 17.2058 1.32888 16.8433V15.0963L17.8378 15.0955V16.8424C17.8378 17.205 18.1354 17.4992 18.5022 17.4992C18.869 17.4992 19.1667 17.205 19.1667 16.8424V8.02813C19.1667 7.66558 18.869 7.37147 18.5022 7.37147ZM5.52925 7.88365C6.05699 7.88365 6.48611 8.30778 6.48611 8.82938V9.7751H1.32895V7.8828L5.52925 7.88365ZM1.32888 13.782V11.0884H17.8378V13.782H1.32888Z" fill="#5C6070" />
                                </svg>
                            </div>
                            <div className="leading-tight">
                                <Text fontSize='s'>{numBeds}</Text>
                            </div>
                        </div>
                    )}
                </div>

                <div />

                <div className="justify-end items-center gap-3 flex">
                    {index === 0 && (
                        <div className="px-3 py-1 bg-[#f9f5ff] rounded-full border border-[#e9d7fe] justify-start items-center flex">
                            <div className="text-center leading-[18px]">
                                <Text fontSize='s' style={{ color: '#6840c6' }}>Best Match</Text>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderTitleElements = () => {
        const { title, subTitle, location, website, stars = 0 } = this.props?.title;

        const starEls = [];

        for (let i = 0; i < stars; i++) {
            starEls.push(<StarIcon key={i} title="Star" />);
        }

        return (
            <div className="adapter-result-card--content--title-elements flex-col justify-start items-start gap-2 inline-flex">
                <div className="leading-[30px]">
                    <Text>{title}</Text>
                </div>

                {subTitle && (
                    <div className="justify-start items-start gap-2 inline-flex subtitle">
                        <div className="leading-tight">
                            <Text fontSize='s' style={{ color: '#73788c' }}>{subTitle}</Text>
                        </div>
                    </div>
                )}

                {stars > 0 && (
                    <div className="justify-start items-start gap-2 inline-flex stars">
                        <div className="h-5 justify-start items-center inline-flex">
                            {starEls}
                        </div>
                        <div className="leading-tight">
                            <Text fontSize='s' style={{ color: '#73788c' }}>{stars} star hotel</Text>
                        </div>
                    </div>
                )}

                {location && (
                    <div className="justify-start items-start gap-1 inline-flex">
                        <LocationIcon title="Location" />
                        <div className="leading-tight">
                            <LINK href={`https://google.com/maps/place/${encodeURIComponent(location)}`} target='_blank'>
                                <Text fontSize='s' style={{ color: '#73788c' }}>{location}</Text>
                            </LINK>
                        </div>
                    </div>
                )}

                {website && (
                    <div className="justify-start items-start gap-1 inline-flex">
                        <GlobeIcon title="Website" />
                        <div className="leading-tight">
                            <LINK href={website} target='_blank'>
                                <Text fontSize='s' style={{ color: '#73788c' }}>Visit Website</Text>
                            </LINK>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderHeroElements = () => {
        const { companyName, rating, reviews } = this.props?.hero;
        const { gps_coordinates } = this.props;

        return (
            <div className="adapter-result-card--content--hero-elements flex-col justify-start items-stretch gap-3 inline-flex">
                <div className="flex-col justify-start items-start gap-3 flex">
                    <div className="justify-start items-center gap-4 inline-flex">

                        {companyName && (
                            <div className="justify-start items-center gap-2 flex">
                                <div className="w-8 h-8 bg-[#c8c9d0] rounded-full" />
                                <div className="leading-tight">
                                    <Text fontSize='s' style={{ color: '#73788c' }}>{companyName}</Text>
                                </div>
                            </div>
                        )}

                        {(rating || reviews) && (
                            <div className="justify-start items-center gap-1 flex">
                                <StarIcon title="Rating" />
                                <div className="leading-tight">
                                    <Text fontSize='s' style={{ color: '#73788c' }}>
                                        {rating}
                                        {reviews && <> ({reviews} reviews)</>}
                                    </Text>
                                </div>
                            </div>
                        )}

                    </div>
                </div>

                {gps_coordinates && (
                    <div className="adapter-result-card--content--hero-map">
                        <div ref={this.mapRef} style={{ height: '300px', flex: 1 }}></div>
                    </div>
                )}
            </div>
        );
    }

    renderDetails = () => {
        const {
            startLabel = 'Pick-up',
            startTime,
            startLocation,
            endLabel = 'Drop-off',
            endTime,
            endLocation
        } = this.props?.details;

        if (!startTime && !endTime) {
            return <></>;
        }

        return (
            <div className="adapter-result-card--content--details justify-start items-start gap-6 inline-flex">
                <div className="grow shrink basis-0 rounded-md flex-col justify-start items-start gap-1.5 inline-flex">
                    <div className="leading-[18px]">
                        <Text fontSize='xs' style={{ color: '#73788c' }}>{startLabel}</Text>
                    </div>
                    <div className="leading-tight">
                        <Text fontSize='s' style={{ color: '#0b0c0e' }}>{startTime}</Text>
                    </div>

                    {startLocation && (
                        <div className="leading-[18px]">
                            <Text fontSize='xs' style={{ color: '#73788c' }}>
                                {startLocation}
                            </Text>
                        </div>
                    )}
                </div>

                <div className="grow shrink basis-0 rounded-md flex-col justify-start items-start gap-1.5 inline-flex">
                    <div className="leading-[18px]">
                        <Text fontSize='xs' style={{ color: '#73788c' }}>{endLabel}</Text>
                    </div>
                    <div className="leading-tight">
                        <Text fontSize='s' style={{ color: '#0b0c0e' }}>{endTime}</Text>
                    </div>

                    {endLocation && (
                        <div className="leading-[18px]">
                            <Text fontSize='xs' style={{ color: '#73788c' }}>
                                {endLocation}
                            </Text>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderCTA = () => {
        const {
            ctaPrice,
            ctaSub,
            ctaText = 'Book Now',
            booking_url,
            formatPrice = true
        } = this.props?.cta;

        let price = ctaPrice;

        if (formatPrice) {
            // @ts-ignore
            price = USDollar.format(ctaPrice);
        }

        return (
            <div className="adapter-result-card--content--CTA px-4 py-3 bg-[#f1f1f4] rounded-md justify-start items-center gap-6 inline-flex">
                <div className="flex-col justify-center items-center inline-flex">
                    <div className="leading-loose">
                        <H4Text fontSize='xs' cls='text-nowrap whitespace-nowrap'>{price}</H4Text>
                    </div>
                    <div className="leading-[18px]">
                        <Text fontSize='xs' style={{ color: '#73788c' }}>{ctaSub}</Text>
                    </div>
                </div>

                {!Array.isArray(booking_url) && (
                    <Button
                        size="2xl"
                        onClick={() => window.open(booking_url, '_blank')}
                        style={{ width: '100%' }}
                    >
                        {ctaText}
                    </Button>
                )}

                {Array.isArray(booking_url) && (
                    <div className="flex flex-col w-full gap-4">
                        {booking_url.map((url, index) => (
                            <Button
                                key={index}
                                size="2xl"
                                disabled={!url}
                                onClick={() => window.open(url, '_blank')}
                                style={{ width: '100%' }}
                            >
                                {booking_url.length > 1 ? `${ctaText} ${index + 1}` : ctaText}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    renderFeatures = () => {
        const { features = [] } = this.props;

        if (!features || features.length === 0) {
            return <></>;
        }

        const els = [];

        for (let i = 0; i < features.length; i++) {
            els.push(
                <div key={i} className="grow shrink basis-0 h-5 justify-start items-start gap-1.5 flex">
                    <div className="w-5 h-5 bg-[#eeebfd] rounded-full justify-center items-center flex overflow-hidden">
                        <CheckMark />
                    </div>
                    <div className="leading-tight">
                        <Text fontSize='s' style={{ color: '#5c6070' }}>{features[i]}</Text>
                    </div>
                </div>
            );
        }

        return (
            <div className="adapter-result-card--content--features flex-col justify-start items-start gap-3 inline-flex">
                {els}
            </div>
        );
    }

    renderRankingReason = () => {
        const { ranking_reasoning } = this.props?.ranking;

        if (!ranking_reasoning) {
            return <></>;
        }

        return (
            <div className="option-ranking-reason">
                <Text>Details:</Text>
                <Text fontSize='xs'>{ranking_reasoning}</Text>
            </div>);
    }

    render() {
        const { info, title, hero, details, cta, features, ranking, flightLegs } = this.props;

        return (
            <div className='adapter-result-card'>
                <div className='adapter-result-card--content'>
                    {info && this.renderIconBar()}
                    {title && this.renderTitleElements()}

                    {hero && this.renderHeroElements()}
                    {flightLegs}

                    {details && this.renderDetails()}
                    {cta && this.renderCTA()}
                    {features && this.renderFeatures()}

                    <div className="h-[0px] border border-[#c8c9d0]"></div>

                    {ranking?.allOptions && ranking?.allOptions?.length > 1 && ranking?.score && (
                        <MatchComparisonBar score={ranking?.score} allScores={ranking?.allOptions} />
                    )}

                    {ranking?.ranking_reasoning && this.renderRankingReason()}
                </div>
            </div>
        );
    }
}

export default ResultCardBase;