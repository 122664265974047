import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";
import { AnimatePresence, motion } from 'framer-motion';

import withPostHog from "hooks/withPosthog";
import API from "util/API";

import { AtlasLoader } from "components/common/Loading/AtlasLoader";
import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from 'components/common/BasePage';
import { CategoryList } from '../components/CategoryList';
import { TileTypeList } from '../components/TileTypeList';
import { PreferenceList } from '../components/PreferenceList';
import { PreferenceDetail } from '../components/PreferenceDetail/index';
import { DossierView } from 'components/DossierView';
import { DossierLoader } from "components/common/Loading/DossierLoader";
import { NetworkCard } from 'components/NetworkCard';
import Feedback from "components/common/Feedback/Feedback";
import { Breadcrumb } from '../components/Breadcrumb';
import { Error as DisplayError } from '../components/Error';
import { ContentContainer, BackButton, Section } from '../components/StyledComponents';
import { pageTransition } from '../constants';
import { sampleData } from '../data';
import { AtlasPageProps } from '../types';
import { getPostHog } from "util/posthogWrapper";

import { useAtlasData } from '../hooks/useAtlasData';
import { useAtlasNavigation } from '../hooks/useAtlasNavigation';
import { usePreferenceManagement } from '../hooks/usePreferenceManagement';
import { useAnalysis } from '../hooks/useAnalysis';

import {
    determineTileType,
    getTileTypeName,
    getTileTypeIcon,
} from 'constants/CategoryMappings';

import '../index.scss';

const AtlasPage: React.FC<AtlasPageProps> = ({ AtlasStore }) => {
    const {
        atlasCache,
        lastUpdated,
        isLoadingPreferences,
        retryCount,
        error: dataError,
        shouldFetchCategory,
        fetchAtlasData,
        updatePreference,
        setError
    } = useAtlasData({ AtlasStore });

    const {
        selectedCategory,
        selectedTileType,
        selectedPreference,
        selectedCategoryType,
        breadcrumbs,
        navigationHistory,
        handleBack,
        handleBreadcrumbClick,
        updateNavigation
    } = useAtlasNavigation();

    const {
        getCurrentPreferences,
        getPreferenceHistory,
        getPreferenceUnderstanding,
    } = usePreferenceManagement({
        atlasCache,
        selectedCategory,
        selectedTileType,
        updatePreference
    });

    const {
        analysisData,
        isLoading: isLoadingAnalysis,
        fetchAnalysis,
        refreshAnalysis
    } = useAnalysis();

    const [isLoading, setIsLoading] = React.useState(false);
    const [networkData, setNetworkData] = React.useState<any>(null);

    useEffect(() => {
        loadInitialData();
    }, []);

    const loadInitialData = async () => {
        try {
            setIsLoading(true);
            try {
                const posthog = await getPostHog();
                if (posthog.isFeatureEnabled('atlas-graph')) {
                    if (!sampleData.categories.find(el => el.type === 'Network')) {
                        sampleData.categories.push({
                            id: '7',
                            type: 'Network',
                            name: 'Network',
                            icon: '🔄',
                            description: 'Collaboration Analysis',
                            tileTypes: [
                                { id: 'cohesion', name: 'Network Cohesion', icon: '🔗' },
                                { id: 'meetings', name: 'Meeting Patterns', icon: '📅' },
                                { id: 'collaboration', name: 'Collaboration Strength', icon: '👥' }
                            ]
                        });
                    }
                }
            } catch (e) {
                console.log("Error waiting on posthog. Won't enable atlas-graph", e);
            }

            const categories = sampleData.categories
                .filter(c => !['Dossier', 'Memories', 'Network'].includes(c.type))
                .map(c => c.type);

            const results = await Promise.allSettled(
                categories
                    .filter(category => shouldFetchCategory(category))
                    .map(async category => {
                        try {
                            const data = await fetchAtlasData(category, true);
                            return { status: 'fulfilled', category, data };
                        } catch (error) {
                            console.error(`Failed to load category ${category}:`, error);
                            return { status: 'rejected', category, reason: error };
                        }
                    })
            );

            const errors = results
                .filter(result => result.status === 'rejected')
                .map(result => ({
                    category: (result as any).category,
                    error: (result as any).reason
                }));

            if (errors.length > 0) {
                console.error(`Failed to load ${errors.length} categories:`, errors);
                toast.error(`Failed to load ${errors.length} categories`);
            }

        } catch (error) {
            console.error('Critical failure in loadInitialData:', error);
            setError('Failed to load initial data');
            toast.error('Failed to load initial data. Please refresh the page or try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCategorySelect = async (id: string) => {
        try {
            setIsLoading(true);
            const category = sampleData.categories.find(c => c.id === id);
            if (!category) throw new Error('Category not found');

            if (category.type === 'Dossier') {
                try {
                    const analysisData = await fetchAnalysis();
                    updateNavigation(id, 'analysis', 'dossier-analysis', 'Dossier', category.name);
                    return;
                } catch (error) {
                    console.error('Failed to load Dossier analysis:', error);
                    toast.error('Failed to load analysis data');
                    return;
                }
            }

            if (category.type === 'Memories') {
                try {
                    await AtlasStore?.fetchMemories();
                    updateNavigation(id, null, null, 'Memories', category.name);
                } catch (error) {
                    console.error('Failed to load Memories:', error);
                    toast.error('Failed to load memories');
                }
                return;
            }

            if (category.type === 'Network') {
                try {
                    const response = await API.post('/api/query', {
                        fn: 'queryNetworkCohesion'
                    });

                    if (!response || response.status !== 200) {
                        throw new Error('Failed to fetch network data');
                    }

                    const networkData = await response.json();
                    if (!networkData?.connections || !networkData?.statistics) {
                        throw new Error('Invalid network data structure received');
                    }

                    setNetworkData(networkData);
                    updateNavigation(id, 'cohesion', null, 'Network', category.name);
                    return;
                } catch (error) {
                    console.error('Failed to load network analysis:', error);
                    toast.error('Failed to load network analysis');
                    return;
                }
            }

            try {
                if (shouldFetchCategory(category.type)) {
                    await fetchAtlasData(category.type);
                }
                updateNavigation(id, null, null, category.type, category.name);
            } catch (error) {
                console.error(`Failed to load category ${category.type}:`, error);
                toast.error(`Failed to load ${category.name}`);
            }

        } catch (error) {
            console.error('Unexpected error in handleCategorySelect:', error);
            toast.error(error instanceof Error ? error.message : 'An unexpected error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    const handleTileTypeSelect = async (typeId: string) => {
        try {
            setIsLoading(true);
            const category = sampleData.categories.find(c => c.id === selectedCategory);
            const tileType = getCurrentTileTypes().find(t => t.id === typeId);

            if (!tileType) throw new Error('Tile type not found');

            if (category?.type === 'Memories') {
                // No need to fetch again as we already have all memories
            } else if (category && shouldFetchCategory(category.type)) {
                await fetchAtlasData(category.type);
            }

            updateNavigation(selectedCategory, typeId, null, selectedCategoryType, tileType.name);
        } catch (error) {
            console.error('Failed to load tile type:', error);
            toast.error(error instanceof Error ? error.message : 'Failed to load tile type');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePreferenceSelect = async (id: string) => {
        try {
            setIsLoading(true);
            const preference = getCurrentPreferences().find(p => p.id === id);
            if (!preference) throw new Error('Preference not found');

            if (id === 'dossier-analysis') {
                try {
                    const analysisData = await fetchAnalysis();
                    updateNavigation(selectedCategory, selectedTileType, id, selectedCategoryType, 'Analysis Details');
                    return;
                } catch (error) {
                    console.error('Failed to load Dossier analysis details:', error);
                    toast.error('Failed to load analysis details');
                    return;
                }
            }

            updateNavigation(selectedCategory, selectedTileType, id, selectedCategoryType, preference.name);
        } catch (error) {
            console.error('Failed to load preference details:', error);
            toast.error(error instanceof Error ? error.message : 'Failed to load preference details');
        } finally {
            setIsLoading(false);
        }
    };

    const getCurrentTileTypes = () => {
        const category = sampleData.categories.find(
            c => c.id === selectedCategory
        );

        if (category?.type === 'Memories') {
            const memories = AtlasStore?.memories || [];
            const categoryMap = new Map();

            memories.forEach(memory => {
                memory.categories?.forEach(category => {
                    const rawCategory = category.replace('Category.', '');
                    if (!categoryMap.has(rawCategory)) {
                        const icon = (() => {
                            switch (rawCategory) {
                                case 'Travel': return '✈️';
                                case 'Social': return '👥';
                                case 'Personal': return '👤';
                                case 'Flights': return '🛩️';
                                case 'Dining': return '🍽️';
                                case 'Lodging': return '🏨';
                                default: return 'X';
                            }
                        })();

                        categoryMap.set(rawCategory, {
                            id: rawCategory,
                            name: rawCategory,
                            icon,
                            count: 0
                        });
                    }
                    categoryMap.get(rawCategory).count++;
                });
            });

            return Array.from(categoryMap.values())
                .sort((a, b) => b.count - a.count)
                .map(item => ({
                    id: item.id,
                    name: `${item.name}`,
                    icon: item.icon,
                    count: item.count
                }));
        }

        if (category?.type) {
            const cachedData = atlasCache[category.type]?.data;
            if (cachedData) {
                const tileTypes = new Map();
                cachedData.preferences.forEach(pref => {
                    const tileType = determineTileType(pref.name);
                    if (!tileTypes.has(tileType)) {
                        tileTypes.set(tileType, {
                            id: tileType,
                            name: getTileTypeName(tileType),
                            icon: getTileTypeIcon(tileType),
                            count: 0
                        });
                    }
                    tileTypes.get(tileType).count++;
                });
                return Array.from(tileTypes.values());
            }
        }

        return category?.tileTypes || [];
    };

    const handleRetry = () => {
        const category = sampleData.categories.find(c => c.id === selectedCategory);
        if (category?.type === 'Travel' || category?.type === 'Dining') {
            fetchAtlasData(category.type);
        }
    };

    if (dataError) {
        return (
            <ContentContainer>
                <Breadcrumb
                    state={{
                        selectedCategory,
                        selectedTileType,
                        selectedPreference,
                        selectedCategoryType,
                        breadcrumbs,
                        navigationHistory,
                        isLoading: false,
                        isLoadingPreferences: false,
                        isLoadingMemories: false,
                        retryCount: 0,
                        atlasCache: {},
                        memories: [],
                        error: null,
                        lastUpdated: {}
                    }}
                    onBreadcrumbClick={handleBreadcrumbClick}
                />
                <DisplayError error={dataError} onRetry={handleRetry} />
            </ContentContainer>
        );
    }

    return (
        <AuthenticatedPage>
            <BasePage>
                <ContentContainer>
                    <Breadcrumb
                        state={{
                            selectedCategory,
                            selectedTileType,
                            selectedPreference,
                            selectedCategoryType,
                            breadcrumbs,
                            navigationHistory,
                            isLoading,
                            isLoadingPreferences,
                            isLoadingMemories: AtlasStore?.isLoadingMemories || false,
                            retryCount,
                            atlasCache,
                            memories: AtlasStore?.memories || [],
                            error: null,
                            lastUpdated
                        }}
                        onBreadcrumbClick={handleBreadcrumbClick}
                    />

                    {breadcrumbs.length > 0 && (
                        <BackButton
                            onClick={handleBack}
                            whileHover={{ x: -4 }}
                            whileTap={{ scale: 0.98 }}
                        >
                            Back to {breadcrumbs[breadcrumbs.length - 2] || 'Categories'}
                        </BackButton>
                    )}

                    <AnimatePresence mode="wait">
                        {(isLoading || isLoadingPreferences || isLoadingAnalysis) ? (
                            selectedCategory === '6' ? (
                                <DossierLoader />
                            ) : (
                                <AtlasLoader />
                            )
                        ) : (
                            <Section
                                key={`view-${selectedCategory}-${selectedTileType}-${selectedPreference}`}
                                {...pageTransition}
                                className='Adapter-Atlas-Index'
                            >
                                {!selectedCategory && (
                                    <CategoryList
                                        categories={sampleData.categories}
                                        onCategorySelect={handleCategorySelect}
                                        maxColumns={4}
                                        minTileSize={300}
                                        selectedCategory={selectedCategory}
                                    />
                                )}

                                {selectedCategory && selectedCategoryType === 'Network' && (
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <NetworkCard
                                            data={networkData}
                                            title="Network Cohesion Analysis"
                                        />
                                    </motion.div>
                                )}

                                {selectedCategory && !selectedTileType && selectedCategoryType !== 'Network' && (
                                    <TileTypeList
                                        categoryType={selectedCategory === '5' ? 'Memories' : selectedCategoryType}
                                        tileTypes={getCurrentTileTypes()}
                                        atlasData={Object.keys(atlasCache).reduce((acc, key) => ({
                                            ...acc,
                                            [key]: {
                                                category: key,
                                                preferences: atlasCache[key]?.data?.preferences || [],
                                                suggestedText: atlasCache[key]?.data?.suggestedText || ''
                                            }
                                        }), {})}
                                        onTileTypeSelect={handleTileTypeSelect}
                                    />
                                )}

                                {selectedCategory && selectedTileType && !selectedPreference && selectedCategoryType !== 'Network' && (
                                    <PreferenceList
                                        preferences={getCurrentPreferences()}
                                        onPreferenceSelect={handlePreferenceSelect}
                                        maxColumns={4}
                                        minTileSize={300}
                                    />
                                )}

                                {selectedPreference && selectedCategoryType !== 'Network' && (
                                    selectedPreference === 'dossier-analysis' ? (
                                        <DossierView
                                            analysis={analysisData}
                                            onRefresh={refreshAnalysis}
                                        />
                                    ) : (
                                        <>
                                            <PreferenceDetail
                                                preference={getCurrentPreferences().find(
                                                    p => p.id === selectedPreference
                                                )!}
                                                // @ts-ignore
                                                history={getPreferenceHistory(selectedPreference)}
                                                understanding={getPreferenceUnderstanding(selectedPreference)}
                                                onBack={handleBack}
                                            />
                                            <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                                                <Feedback
                                                    style={{
                                                        border: '1px solid #E4E4E7',
                                                        margin: '16px 24px',
                                                        borderRadius: '4px',
                                                        display: 'inline-flex',
                                                        justifyContent: 'end',
                                                        float: 'right'
                                                    }}
                                                    text="Is this correct and useful?"
                                                    responseKey={'atlas::PreferenceDetail'}
                                                    metadata={{ selectedPreference }}
                                                />
                                            </div>
                                        </>
                                    )
                                )}
                            </Section>
                        )}
                    </AnimatePresence>
                </ContentContainer>
            </BasePage>
        </AuthenticatedPage>
    );
};

export default withPostHog(inject("AtlasStore")(observer(AtlasPage))); 