// src/components/NodeVisualizer.tsx
import React, { useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { NodeData, NodePhase } from '../types'; // Make sure NodePhase is defined/exported
import NodeDot from './NodeDot';
import './NodeVisualizer.css';

interface NodeVisualizerProps {
  nodes: NodeData[];
}

// Helper Functions (determinePhase, contemplatingPositions) - Keep as before
function determinePhase(nodes: NodeData[]): NodePhase {
    const statuses = new Set(nodes.map(n => n.status));
    if (statuses.has('adapted')) return 'ADAPTED';
    // Trigger contemplating view as soon as the first contemplating node appears
    if (statuses.has('contemplating') || statuses.has('contemplation-complete')) return 'CONTEMPLATING';
    return 'INITIAL_GRID';
}

// Make sure this array accurately reflects the 22 dot positions from the video frame 0:07
const contemplatingPositions = [
    // Top row (4 dots)
    { top: 0, left: 60 }, { top: 0, left: 90 }, { top: 0, left: 120 }, { top: 0, left: 150 },
    // Middle row 1 (9 dots total)
    { top: 30, left: 0 }, { top: 30, left: 30 }, { top: 30, left: 60 },
    { top: 30, left: 90 }, { top: 30, left: 120 }, { top: 30, left: 150 },
    { top: 30, left: 180 }, { top: 30, left: 210 }, { top: 30, left: 240 },
     // Middle row 2 (9 dots total)
     { top: 60, left: 0 }, { top: 60, left: 30 }, { top: 60, left: 60 },
    { top: 60, left: 90 }, { top: 60, left: 120 }, { top: 60, left: 150 },
    { top: 60, left: 180 }, { top: 60, left: 210 }, { top: 60, left: 240 },
    // Tail (2 dots)
    { top: 90, left: 90 }, { top: 90, left: 120 }
]; // Should have exactly 22 entries if CONTEMPLATING_NODE_COUNT is 22


const NodeVisualizer: React.FC<NodeVisualizerProps> = ({ nodes }) => {
  const phase = useMemo(() => determinePhase(nodes), [nodes]);

  // Filter nodes based on the current visual phase
  const visibleNodes = useMemo(() => {
    switch (phase) {
      case 'INITIAL_GRID':
        // Show all nodes still processing or just completed
        return nodes.filter(n => n.status === 'processing' || n.status === 'processing-complete');
      case 'CONTEMPLATING':
        // Show only nodes actively in the contemplating stages
        return nodes.filter(n => n.status === 'contemplating' || n.status === 'contemplation-complete');
      case 'ADAPTED':
        // Show only adapted nodes, sorted by score
        return nodes
          .filter(n => n.status === 'adapted')
          .sort((a, b) => (b.score ?? 0) - (a.score ?? 0));
      default:
        return [];
    }
  }, [nodes, phase]); // Re-filter when nodes or the determined phase change

  const topAdaptedNodeId = phase === 'ADAPTED' && visibleNodes.length > 0 ? visibleNodes[0].id : null;

  const renderLayout = () => {
    switch (phase) {
      case 'INITIAL_GRID':
        return (
          // Key ensures AnimatePresence detects layout change
          <motion.div layout className="grid-layout" key="grid">
            {/* AnimatePresence is crucial here */}
            <AnimatePresence>
              {visibleNodes.map((node) => (
                <NodeDot key={node.id} node={node} phase={phase} />
              ))}
            </AnimatePresence>
          </motion.div>
        );

      case 'CONTEMPLATING':
         // Map visible contemplating nodes to the predefined positions
         // Make sure contemplatingPositions has enough slots
         const contemplatingNodesToRender = visibleNodes.slice(0, contemplatingPositions.length);
        return (
          <motion.div layout className="contemplating-layout" key="contemplating">
            <AnimatePresence>
              {contemplatingNodesToRender.map((node, index) => (
                <NodeDot
                  key={node.id}
                  node={node}
                  phase={phase}
                  // Apply the absolute position style for this layout
                  style={contemplatingPositions[index]}
                />
              ))}
            </AnimatePresence>
          </motion.div>
        );

      case 'ADAPTED':
        return (
          <motion.div layout className="adapted-layout" key="adapted">
            <AnimatePresence>
              {visibleNodes.map((node) => (
                <NodeDot
                  key={node.id}
                  node={node}
                  phase={phase}
                  isTopAdapted={node.id === topAdaptedNodeId}
                />
              ))}
            </AnimatePresence>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="visualizer-container">
      {/* Using AnimatePresence here helps transition the containers themselves if needed,
          but the inner AnimatePresence instances handle the dots.
          'sync' mode might be slightly better for coordinating simultaneous exits/entries.
      */}
      <AnimatePresence mode="sync">
        {renderLayout()}
      </AnimatePresence>
    </div>
  );
};

export default NodeVisualizer;