import React, { ChangeEvent, FormEvent } from "react";
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";

import BaseForm from "components/forms/BaseForm";
import Card from "components/common/Card";
import { H4Text, Text } from "components/common/Typography";
import withPostHog from "hooks/withPosthog";
import { isValidOnboardingDate } from 'util/Time';
import { SettingsLayout } from "./SettingsLayout";

import "../../brand/index.scss";

interface ProfilePageProps {
    UserStore?: any;
    userEvents?: any;
}

interface ProfilePageState {
    first_name: string | undefined;
    last_name: string | undefined;
    phone: string | undefined;
    email: string | undefined;
    homeAddress: string | undefined;
    dateOfBirth: string | undefined;
}

class ProfilePage extends React.Component<ProfilePageProps, ProfilePageState> {
    state = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        homeAddress: '',
        dateOfBirth: ''
    };

    componentDidMount(): void {
        const { UserStore } = this.props;

        UserStore
            .loadUser()
            .then((user) => {
                this.setState({
                    first_name: user.first_name || '',
                    last_name: user.last_name || '',
                    phone: user.phone || '',
                    email: user.email || '',
                    homeAddress: user.metadata.homeAddress || '',
                    dateOfBirth: user.metadata.dateOfBirth || ''
                });
            })
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        let newValue = value;

        if (name === 'dateOfBirth') {
            // only let the user keep numeric input, we'll handle the rest
            newValue = value.replace(/[^0-9]/g, '').substring(0, 8);
            const len = newValue.length;
            let formatted = "";

            if (len > 0) {
                formatted += newValue.slice(0, Math.min(2, len)); // Up to 2 digits for MM
                if (len > 2) {
                    formatted += "-" + newValue.slice(2, Math.min(4, len)); // Up to 2 digits for DD
                    if (len > 4) {
                        formatted += "-" + newValue.slice(4, Math.min(8, len)); // Up to 4 digits for YYYY
                    }
                }
            }

            newValue = formatted;
        }

        // @ts-ignore
        this.setState({ [name]: newValue });
    };

    onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        const { UserStore } = this.props;
        const { email, first_name, last_name, phone, homeAddress, dateOfBirth } = this.state;

        event.preventDefault();

        UserStore.updateUser({
            // @ts-ignore
            email: email.toLowerCase(),
            first_name,
            last_name,
            phone,
            metadata: { homeAddress, dateOfBirth }
        })
            .catch(() => {
                toast.error("Oops! Something went wrong, please try again.", { icon: false });
            });
    };

    render() {
        const { UserStore } = this.props;
        const { email, phone, first_name, last_name, homeAddress, dateOfBirth } = this.state;

        const hasDobError = !isValidOnboardingDate(dateOfBirth);

        const config = [
            {
                labelText: "Email Address",
                fieldId: "email",
                type: "email",
                value: email,
                disabled: true,
            },
            {
                labelText: "First Name*",
                fieldId: "first_name",
                type: "text",
                value: first_name,
                required: true,
                errorText: first_name.length === 0 ? "First name is required" : undefined
            },
            {
                labelText: "Last Name*",
                fieldId: "last_name",
                type: "text",
                value: last_name,
                required: true,
                errorText: last_name.length === 0 ? "Last name is required" : undefined
            },
            {
                labelText: "Phone*",
                fieldId: "phone",
                type: "text",
                value: phone,
                required: true,
                errorText: phone.length === 0 ? "Phone number is required" : undefined
            },
            {
                labelText: "Home Address*",
                fieldId: "homeAddress",
                type: "address",
                value: homeAddress,
                required: true,
                errorText: homeAddress.length === 0 ? "Home address is required" : undefined
            },
            {
                labelText: "Birth Date*",
                fieldId: "dateOfBirth",
                type: "dob",
                value: dateOfBirth,
                required: true,
                errorText: hasDobError ? "DOB is required in MM-DD-YYYY format" : undefined
            },
        ];

        return (
            <SettingsLayout>
                <div style={{ width: '80vw', maxWidth: '600px', alignSelf: 'center' }}>
                    <H4Text fontSize="xs">
                        Personal Info
                    </H4Text>

                    <Text>Update your personal details here.</Text>

                    <Card>
                        <BaseForm
                            config={config}
                            isLoading={UserStore.isLoading}
                            buttonText="Save Changes"
                            onChange={this.onChangeHandler}
                            onSubmit={this.onSubmitHandler}
                            style={{ minWidth: '50%' }}
                        />
                    </Card>
                </div>

                <div className="flex-1"></div>
            </SettingsLayout>
        );
    }
}

export default withPostHog(inject("UserStore")(observer(ProfilePage)));
