// src/services/STTService.ts
import Logger from 'util/Logger';

/**
 * Speech-to-Text service using ElevenLabs API via backend API
 * Falls back to Web Speech API if backend is unavailable
 */
export class STTService {
  private isInitialized: boolean = false;
  private backendUrl: string;
  private useServerSideSpeechRecognition: boolean = true;
  private recognizeBlob: any = null;
  private recordStream: MediaStream | null = null;
  private mediaRecorder: MediaRecorder | null = null;
  private recordedChunks: Blob[] = [];
  private isRecording: boolean = false;
  private hasUserInteracted: boolean = false;
  private listener: ((text: string) => void) | null = null;
  private errorListener: ((error: Error) => void) | null = null;
  private shouldRetryWithWebSpeech: boolean = true;
  
  // Auto-silence detection
  private audioContext: AudioContext | null = null;
  private analyser: AnalyserNode | null = null;
  private silenceDetectionTimer: number | null = null;
  private volumeDataArray: Uint8Array | null = null;
  private lastAudioLevel: number = 0;
  private silenceStart: number | null = null;
  private isSilent: boolean = false;
  private silenceThreshold: number = 10; // Threshold for silence detection (0-255)
  private silenceDuration: number = 500; // Duration of silence before stopping (ms)
  private minRecordingTime: number = 1000; // Minimum recording time (ms)
  private recordingStartTime: number = 0;
  
  constructor() {
    // Configure backend URL with dynamic detection
    // Import service config here to ensure it's evaluated at runtime
    const serviceConfig = require('./ServiceConfig').default;
    
    this.backendUrl = serviceConfig.sttApiUrl;
    Logger.log(`STTService initialized with backend URL: ${this.backendUrl}`);
    
    // Initialize when in browser context
    if (typeof window !== 'undefined') {
      this.setupUserInteractionListeners();
      
      // Load recognizeBlob if available
      if ((window as any).recognizeBlob) {
        this.recognizeBlob = (window as any).recognizeBlob;
      }
      
      // Start initialization process
      this.initialize().catch(error => {
        Logger.error('Error initializing STT service:', error);
      });
    } else {
      Logger.warn('STTService initialized outside browser context, some features may not work');
    }
  }
  
  /**
   * Set up event listeners to track user interactions
   * This is important for Mobile Safari which requires user interaction before using microphone
   */
  private setupUserInteractionListeners(): void {
    if (typeof window === 'undefined') return;
    
    const userInteractionEvents = ['click', 'touchend', 'keydown'];
    
    const handleUserInteraction = () => {
      if (!this.hasUserInteracted) {
        this.hasUserInteracted = true;
        Logger.log('User interaction detected for speech recognition');
      }
    };
    
    userInteractionEvents.forEach(eventType => {
      window.addEventListener(eventType, handleUserInteraction, { once: false });
    });
  }
  
  /**
   * Initialize the STT service and check for backend availability
   */
  public async initialize(): Promise<void> {
    if (this.isInitialized) return;
    
    // Check if the backend STT service is available
    try {
      Logger.log(`Checking STT backend service availability at: ${this.backendUrl}/health`);
      
      const response = await fetch(`${this.backendUrl}/health`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        cache: 'no-cache'
      });
      
      if (response.ok) {
        const healthData = await response.json();
        Logger.log('STT backend service is available:', healthData);
        this.useServerSideSpeechRecognition = true;
        Logger.log('Using ElevenLabs STT service from backend');
      } else {
        const errorText = await response.text();
        Logger.warn('STT backend service returned an error, falling back to Web Speech API');
        Logger.warn(`Error details: ${response.status} - ${errorText}`);
        this.useServerSideSpeechRecognition = false;
      }
    } catch (error) {
      Logger.warn('STT backend service not available, falling back to Web Speech API');
      Logger.warn('Error details:', error);
      this.useServerSideSpeechRecognition = false;
    }
    
    this.isInitialized = true;
    return Promise.resolve();
  }
  
  /**
   * Start recording audio for speech recognition
   * @param onText Callback function that will be called with the recognized text
   * @param onError Callback function that will be called on error
   */
  public async startRecording(
    onText: (text: string) => void,
    onError: (error: Error) => void
  ): Promise<void> {
    // Make sure service is initialized
    if (!this.isInitialized) {
      await this.initialize();
    }
    
    // Store callbacks
    this.listener = onText;
    this.errorListener = onError;
    
    if (this.isRecording) {
      Logger.warn('Already recording, stopping previous session first');
      await this.stopRecording();
    }
    
    // Reset recorded chunks
    this.recordedChunks = [];
    
    try {
      Logger.log('Requesting microphone access');
      
      // Request microphone access
      this.recordStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });
      
      // Initialize audio analyzer for silence detection
      this.initAudioAnalyzer(this.recordStream);
      
      // Create media recorder with best available format
      const mimeType = this.getPreferredMimeType();
      Logger.log(`Using MIME type: ${mimeType}`);
      
      this.mediaRecorder = new MediaRecorder(this.recordStream, {
        mimeType,
        audioBitsPerSecond: 128000
      });
      
      // Set up data handling
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
        }
      };
      
      // Start recording
      this.mediaRecorder.start(100); // Collect data every 100ms
      this.isRecording = true;
      
      // Start silence detection
      this.startSilenceDetection();
      
      Logger.log('Recording started with auto silence detection');
    } catch (error) {
      Logger.error('Error starting recording:', error);
      
      // Call error handler
      if (this.errorListener) {
        this.errorListener(error instanceof Error 
          ? error 
          : new Error('Failed to start recording')
        );
      }
      
      // Clean up if we failed to start
      this.releaseMediaResources();
    }
    
    return Promise.resolve();
  }
  
  /**
   * Stop recording audio and process it for speech recognition
   */
  public async stopRecording(): Promise<void> {
    if (!this.isRecording || !this.mediaRecorder) {
      Logger.warn('Not recording, nothing to stop');
      return Promise.resolve();
    }
    
    Logger.log('Stopping recording');
    
    // Create a promise to handle the data when recording stops
    const dataPromise = new Promise<Blob>((resolve) => {
      if (!this.mediaRecorder) return resolve(new Blob([]));
      
      this.mediaRecorder.onstop = async () => {
        // Combine recorded chunks into a single blob
        const audioBlob = new Blob(this.recordedChunks, {
          type: this.mediaRecorder?.mimeType || 'audio/webm'
        });
        
        resolve(audioBlob);
      };
      
      // Stop the recording
      this.mediaRecorder.stop();
      this.isRecording = false;
    });
    
    try {
      // Wait for recording data
      const audioBlob = await dataPromise;
      
      // Process the audio
      Logger.log(`Recorded audio blob size: ${audioBlob.size} bytes`);
      
      if (audioBlob.size > 0) {
        // Attempt speech recognition with server first, then web speech api as fallback
        await this.processAudioBlob(audioBlob);
      } else {
        Logger.warn('Recorded audio is empty');
        if (this.errorListener) {
          this.errorListener(new Error('Recorded audio is empty'));
        }
      }
    } catch (error) {
      Logger.error('Error processing recording:', error);
      if (this.errorListener) {
        this.errorListener(error instanceof Error 
          ? error 
          : new Error('Failed to process recording')
        );
      }
    } finally {
      // Clean up
      this.releaseMediaResources();
    }
    
    return Promise.resolve();
  }
  
  /**
   * Release media resources (microphone access, etc.)
   */
  private releaseMediaResources(): void {
    // Stop silence detection
    this.stopSilenceDetection();
    
    if (this.mediaRecorder) {
      try {
        if (this.mediaRecorder.state !== 'inactive') {
          this.mediaRecorder.stop();
        }
      } catch (e) {
        // Ignore errors during cleanup
      }
      this.mediaRecorder = null;
    }
    
    if (this.recordStream) {
      this.recordStream.getTracks().forEach(track => track.stop());
      this.recordStream = null;
    }
    
    // Clean up audio context resources
    if (this.analyser) {
      try {
        this.analyser.disconnect();
      } catch (e) {
        // Ignore errors during cleanup
      }
      this.analyser = null;
    }
    
    this.isRecording = false;
    this.silenceStart = null;
    this.isSilent = false;
  }
  
  /**
   * Initialize audio context and analyzer for silence detection
   */
  private initAudioAnalyzer(stream: MediaStream): void {
    try {
      // Create audio context
      const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
      this.audioContext = new AudioContext();
      
      // Create analyzer
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = 256;
      this.analyser.smoothingTimeConstant = 0.5;
      
      // Connect stream to analyzer
      const source = this.audioContext.createMediaStreamSource(stream);
      source.connect(this.analyser);
      
      // Create data array for volume analysis
      const bufferLength = this.analyser.frequencyBinCount;
      this.volumeDataArray = new Uint8Array(bufferLength);
      
      Logger.log('Audio analyzer initialized for silence detection');
    } catch (error) {
      Logger.error('Error initializing audio analyzer:', error);
    }
  }
  
  /**
   * Start silence detection
   */
  private startSilenceDetection(): void {
    if (!this.analyser || !this.volumeDataArray) {
      Logger.warn('Cannot start silence detection: analyzer not initialized');
      return;
    }
    
    // Record starting time
    this.recordingStartTime = Date.now();
    this.silenceStart = null;
    this.isSilent = false;
    
    // Start polling for audio levels
    const checkSilence = () => {
      if (!this.isRecording || !this.analyser || !this.volumeDataArray) return;
      
      // Get current volume levels
      this.analyser.getByteFrequencyData(this.volumeDataArray);
      
      // Calculate average volume
      let sum = 0;
      for (let i = 0; i < this.volumeDataArray.length; i++) {
        sum += this.volumeDataArray[i];
      }
      const avgVolume = sum / this.volumeDataArray.length;
      
      // Update last audio level with some smoothing
      this.lastAudioLevel = this.lastAudioLevel * 0.7 + avgVolume * 0.3;
      
      // Check if current level is below threshold (silence)
      const currentlySilent = this.lastAudioLevel < this.silenceThreshold;
      
      // Don't consider silence until minimum recording time has passed
      const recordingTime = Date.now() - this.recordingStartTime;
      if (recordingTime < this.minRecordingTime) {
        this.silenceStart = null;
        this.isSilent = false;
      } else if (currentlySilent && !this.isSilent) {
        // Transition from sound to silence
        this.silenceStart = Date.now();
        this.isSilent = true;
        Logger.log('Silence detected, waiting for duration threshold');
        
        // Dispatch custom event for UI to show silence progress
        window.dispatchEvent(new CustomEvent('silence:start'));
      } else if (!currentlySilent && this.isSilent) {
        // Transition from silence to sound
        this.silenceStart = null;
        this.isSilent = false;
        Logger.log('Sound detected, resetting silence timer');
        
        // Dispatch custom event for UI to hide silence progress
        window.dispatchEvent(new CustomEvent('silence:end'));
      } else if (currentlySilent && this.isSilent && this.silenceStart) {
        // Check if silence has lasted long enough
        const silenceTime = Date.now() - this.silenceStart;
        if (silenceTime >= this.silenceDuration) {
          Logger.log(`Silence duration threshold reached (${silenceTime}ms), stopping recording`);
          this.stopSilenceDetection();
          this.stopRecording();
          return;
        }
      }
      
      // Continue checking
      this.silenceDetectionTimer = window.setTimeout(checkSilence, 100);
    };
    
    // Start checking
    this.silenceDetectionTimer = window.setTimeout(checkSilence, 100);
    Logger.log('Silence detection started');
  }
  
  /**
   * Stop silence detection
   */
  private stopSilenceDetection(): void {
    if (this.silenceDetectionTimer !== null) {
      window.clearTimeout(this.silenceDetectionTimer);
      this.silenceDetectionTimer = null;
    }
  }
  
  /**
   * Get the preferred MIME type for recording based on browser support
   */
  private getPreferredMimeType(): string {
    const options = [
      'audio/webm;codecs=opus',
      'audio/webm',
      'audio/mp4',
      'audio/ogg;codecs=opus',
      'audio/ogg'
    ];
    
    for (const option of options) {
      if (MediaRecorder.isTypeSupported(option)) {
        return option;
      }
    }
    
    // Default fallback
    return '';
  }
  
  /**
   * Get file extension from mime type
   */
  private getFileExtensionFromMimeType(mimeType: string): string {
    if (mimeType.includes('webm')) return 'webm';
    if (mimeType.includes('mp4')) return 'mp4';
    if (mimeType.includes('mp3')) return 'mp3';
    if (mimeType.includes('ogg')) return 'ogg';
    if (mimeType.includes('wav')) return 'wav';
    return 'webm'; // default
  }
  
  /**
   * Process audio blob using server-side or local methods
   * @param audioBlob The recorded audio blob
   */
  private async processAudioBlob(audioBlob: Blob): Promise<void> {
    // Try server-side STT first if available
    if (this.useServerSideSpeechRecognition) {
      try {
        const text = await this.recognizeWithServer(audioBlob);
        
        if (text && this.listener) {
          this.listener(text);
          return;
        }
      } catch (error) {
        Logger.error('Error with server-side speech recognition:', error);
        // Fall back to web speech if server fails and retries are allowed
        if (!this.shouldRetryWithWebSpeech) {
          throw error;
        }
      }
    }
    
    // Fall back to Web Speech API or recognizeBlob
    try {
      const text = await this.recognizeWithWebApis(audioBlob);
      
      if (text && this.listener) {
        this.listener(text);
      } else {
        throw new Error('No text recognized');
      }
    } catch (error) {
      Logger.error('Error with fallback speech recognition:', error);
      throw error;
    }
  }
  
  /**
   * Use backend STT service to recognize speech
   * @param audioBlob Audio blob to transcribe
   * @returns Recognized text
   */
  private async recognizeWithServer(audioBlob: Blob): Promise<string> {
    try {
      Logger.log('Recognizing speech with ElevenLabs API');
      
      const formData = new FormData();
      
      // Get file extension from MIME type
      const fileExtension = this.getFileExtensionFromMimeType(audioBlob.type);
      
      // Add the audio file to form data
      formData.append('file', audioBlob, `recording.${fileExtension}`);
      
      // Send to backend
      const response = await fetch(`${this.backendUrl}/transcribe`, {
        method: 'POST',
        body: formData
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Backend STT error (${response.status}): ${errorText}`);
      }
      
      const data = await response.json();
      
      if (!data.success || !data.text) {
        throw new Error('Invalid response from STT backend');
      }
      
      Logger.log(`Speech recognized${data.cacheHit ? ' (from cache)' : ''}: "${data.text}"`);
      
      return data.text;
    } catch (error) {
      Logger.error('Error using backend STT:', error);
      throw error;
    }
  }
  
  /**
   * Use Web Speech API or other browser APIs for speech recognition
   * @param audioBlob Audio blob to transcribe
   * @returns Recognized text
   */
  private async recognizeWithWebApis(audioBlob: Blob): Promise<string> {
    // First try the recognizeBlob function if available (for advanced processing)
    if (this.recognizeBlob) {
      try {
        Logger.log('Attempting to recognize speech with recognizeBlob');
        const text = await this.recognizeBlob(audioBlob);
        if (text) {
          Logger.log(`Speech recognized with recognizeBlob: "${text}"`);
          return text;
        }
      } catch (error) {
        Logger.error('Error recognizing speech with recognizeBlob:', error);
        // Continue to try other methods
      }
    }
    
    // Fall back to Web Speech API via audio element
    return new Promise<string>((resolve, reject) => {
      try {
        Logger.log('Falling back to Web Speech API for recognition');
        
        // Check for speech recognition support
        const SpeechRecognition = (window as any).SpeechRecognition || 
                                (window as any).webkitSpeechRecognition;
        
        if (!SpeechRecognition) {
          return reject(new Error('Speech recognition not supported in this browser'));
        }
        
        // Create audio element to play the recording
        const audio = new Audio();
        const audioUrl = URL.createObjectURL(audioBlob);
        audio.src = audioUrl;
        
        const recognition = new SpeechRecognition();
        recognition.lang = 'en-US';
        recognition.continuous = true;
        recognition.interimResults = false;
        
        // Set up recognition handlers
        let finalTranscript = '';
        
        recognition.onresult = (event: any) => {
          for (let i = event.resultIndex; i < event.results.length; i++) {
            if (event.results[i].isFinal) {
              finalTranscript += event.results[i][0].transcript + ' ';
            }
          }
        };
        
        recognition.onerror = (event: any) => {
          Logger.error('Speech recognition error:', event.error);
          recognition.stop();
          URL.revokeObjectURL(audioUrl);
          reject(new Error(`Recognition error: ${event.error}`));
        };
        
        recognition.onend = () => {
          URL.revokeObjectURL(audioUrl);
          if (finalTranscript) {
            Logger.log(`Speech recognized with Web Speech API: "${finalTranscript}"`);
            resolve(finalTranscript.trim());
          } else {
            reject(new Error('No speech recognized'));
          }
        };
        
        // Start playback and recognition together
        audio.onplay = () => {
          recognition.start();
        };
        
        audio.onended = () => {
          recognition.stop();
        };
        
        // Handle errors during playback
        audio.onerror = (e) => {
          recognition.stop();
          URL.revokeObjectURL(audioUrl);
          reject(new Error('Error playing audio for recognition'));
        };
        
        // Start playback
        audio.play().catch(error => {
          URL.revokeObjectURL(audioUrl);
          reject(error);
        });
        
        // Add a timeout in case recognition doesn't end properly
        setTimeout(() => {
          if (recognition) {
            try {
              recognition.stop();
            } catch (e) {
              // Ignore errors during stop
            }
            
            if (!finalTranscript) {
              reject(new Error('Recognition timed out'));
            }
          }
        }, 15000);
      } catch (error) {
        reject(error);
      }
    });
  }
}

// Create a singleton instance
export const sttService = new STTService();

// Make the service available globally for components that need to access it directly
if (typeof window !== 'undefined') {
  (window as any).sttService = sttService;
}