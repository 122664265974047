import { toast } from "react-toastify";
import { sub, startOfDay } from "date-fns";

import API from "./API";

import {
  PERSONAL,
  PURCHASES,
  SOCIAL,
  TRAVEL,
  ALL_TIME,
  SIX_MONTHS,
  ONE_YEAR,
  THREE_YEARS,
} from "constants/Scopes";

export const gmail_options = [
  { label: PERSONAL, value: PERSONAL },
  { label: PURCHASES, value: PURCHASES },
  { label: SOCIAL, value: SOCIAL },
  { label: TRAVEL, value: TRAVEL }
];

export const time_options = [
  { label: ALL_TIME, value: ALL_TIME },
  { label: SIX_MONTHS, value: SIX_MONTHS },
  { label: ONE_YEAR, value: ONE_YEAR },
  { label: THREE_YEARS, value: THREE_YEARS }
];

export default async function runGoogleOAuthFlow(requestBody: any) {
  try {
    const GOOGLE_OAUTH_URL = '/api/google-oauth2/';
    const res = await API.post(GOOGLE_OAUTH_URL, requestBody);
    const data = await res.json();

    if (res.status === 200 && typeof window !== "undefined") {
      // ENG-41: On Native iOS, use the JS/WebKit bridge
      // to let the Swift codebase handle this.
      if (window.webkit?.messageHandlers?.googleOAuthRedirect) {
        window?.webkit?.messageHandlers?.googleOAuthRedirect?.postMessage({
          "operation": "google-oauth-redirect",
          "url": data.authorization_url
        });
      } else {
        window.location.replace(data.authorization_url);
      }
    } else {
      toast.error("Something went wrong, please try again.", { icon: false });
      console.error(
        `Fetch on ${GOOGLE_OAUTH_URL} returned status ${res.status}: ${res.statusText}`,
      );
    }
  } catch (e: any) {
    toast.error("Something went wrong, please try again.", { icon: false });
  }
}

export const getGoogleConfigs = (gmailCategoryState: string[], gmailTimeState: string = '',): [string[], number] => {
  let allowedGmailCategories: string[] = [];
  let gmailStartingAfter: number = 1;

  if (gmailCategoryState.length > 0) {
    // verify the categories requested are valid
    allowedGmailCategories = [PERSONAL, PURCHASES, SOCIAL, TRAVEL].filter(v => gmailCategoryState.includes(v));

    if (gmailTimeState !== ALL_TIME) {
      let now = new Date();

      if (gmailTimeState === SIX_MONTHS) {
        now = sub(now, { months: 6 });
      } else if (gmailTimeState === ONE_YEAR) {
        now = sub(now, { months: 12 });
      }
      // 3 Years
      else {
        now = sub(now, { months: 36 });
      }

      gmailStartingAfter = Math.floor(
        startOfDay(now).getTime() / 1000,
      );
    }
  }

  return [ allowedGmailCategories, gmailStartingAfter ];
};