import React from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';
import withPostHog from "hooks/withPosthog";

import { Text } from '../Typography';
import { EVENTS } from 'pages/support';

import { APPLICATION_BUILD_NUMBER } from '../../../constants';

import './Feedback.scss';

type FeedbackProps = {
    text: string;
    metadata: any;
    responseKey: string;
    style?: any;
    userEvents?: any;
    ux?: 'thumbs' | 'faces';
    UserStore?: any;
    isComprehension?: boolean;
}

type FeedbackState = {
    votedUp: boolean;
    votedDown: boolean;
    votedMid: boolean;
}

class Feedback extends React.Component<FeedbackProps, FeedbackState> {
    state = {
        votedUp: false,
        votedDown: false,
        votedMid: false
    };

    sendPromptFeedback = (feedback = true) => {
        const { responseKey, metadata, userEvents, UserStore, isComprehension = false } = this.props;
        const details = {
            APPLICATION_BUILD_NUMBER,
            userId: UserStore?.user?.id,
            url: window.location.href,
            ...metadata
        };

        if (userEvents) {
            userEvents?.posthog.capture('feedback-' + responseKey, {
                responseKey,
                feedback,
                metadata: details
            });
        }


        UserStore.sendQuickFeedback(
            responseKey,
            feedback,
            details,
            isComprehension
        );

        if (feedback) {
            toast.success(
                "Thank you for your feedback!",
                { theme: 'dark', position: 'bottom-right' }
            );
        }
    }

    voteUp = () => {
        this.sendPromptFeedback(true);
        this.setState({ votedDown: false, votedUp: true, votedMid: false });
    }

    voteMid = () => {
        this.sendPromptFeedback(null);
        this.setState({ votedDown: false, votedUp: false, votedMid: true });
    }

    voteDown = () => {
        this.sendPromptFeedback(false);
        this.setState({ votedUp: false, votedMid: false, votedDown: true }, () => {
            // @ts-ignore
            ADAPTER.publishEvent(EVENTS.openFeedback);
        });
    }

    renderThumbs = () => {
        const { votedUp, votedDown } = this.state;

        return (
            <div className='flex flex-row gap-4'>
                <div onClick={this.voteUp} style={{ cursor: 'pointer' }}>
                    <svg
                        className="shrink-0 w-5 h-5 relative overflow-visible"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.83366 18.3332V9.1665M1.66699 10.8332V16.6665C1.66699 17.587 2.41318 18.3332 3.33366 18.3332H14.5222C15.7561 18.3332 16.8055 17.4329 16.9931 16.2133L17.8906 10.38C18.1235 8.86558 16.9518 7.49984 15.4196 7.49984H12.5003C12.0401 7.49984 11.667 7.12674 11.667 6.6665V3.72137C11.667 2.5865 10.747 1.6665 9.61213 1.6665C9.34144 1.6665 9.09614 1.82592 8.98621 2.07327L6.05361 8.67162C5.91986 8.97256 5.62142 9.1665 5.2921 9.1665H3.33366C2.41318 9.1665 1.66699 9.9127 1.66699 10.8332Z"
                            stroke={votedUp ? '#7549F2' : '#5C6070'}
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>

                <div onClick={this.voteDown} style={{ cursor: 'pointer' }}>
                    <svg
                        className="shrink-0 w-5 h-5 relative overflow-visible"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.1665 1.6665V10.8332M18.3332 8.1665V4.33317C18.3332 3.39975 18.3332 2.93304 18.1516 2.57652C17.9918 2.26292 17.7368 2.00795 17.4232 1.84816C17.0667 1.6665 16.6 1.6665 15.6665 1.6665H6.76489C5.54699 1.6665 4.93804 1.6665 4.4462 1.88937C4.01271 2.08579 3.6443 2.40186 3.38425 2.80043C3.08919 3.25267 2.99659 3.85453 2.8114 5.05827L2.37551 7.89161C2.13125 9.47926 2.00912 10.2731 2.24472 10.8908C2.4515 11.4329 2.84042 11.8862 3.34482 12.1731C3.91949 12.4998 4.72266 12.4998 6.32899 12.4998H6.99988C7.46659 12.4998 7.69995 12.4998 7.87821 12.5907C8.03501 12.6706 8.16249 12.798 8.24239 12.9548C8.33321 13.1331 8.33321 13.3665 8.33321 13.8332V16.2783C8.33321 17.4132 9.25321 18.3332 10.3881 18.3332C10.6588 18.3332 10.9041 18.1738 11.014 17.9264L13.8146 11.625C13.942 11.3384 14.0057 11.1951 14.1064 11.09C14.1954 10.9971 14.3047 10.9261 14.4257 10.8825C14.5626 10.8332 14.7194 10.8332 15.033 10.8332H15.6665C16.6 10.8332 17.0667 10.8332 17.4232 10.6515C17.7368 10.4917 17.9918 10.2368 18.1516 9.92316C18.3332 9.56664 18.3332 9.09993 18.3332 8.1665Z"
                            stroke={votedDown ? '#7549F2' : '#5C6070'}
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </div>
        );
    }

    renderFaces = () => {
        const { votedUp, votedDown, votedMid } = this.state;

        return (
            <div className='flex flex-row gap-4'>
                <div onClick={this.voteUp} style={{ cursor: 'pointer' }}>
                    <svg
                        className="shrink-0 w-5 h-5 relative overflow-visible"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_7187_263)">
                            <path
                                d="M6.66699 11.6665C6.66699 11.6665 7.91699 13.3332 10.0003 13.3332C12.0837 13.3332 13.3337 11.6665 13.3337 11.6665M12.5003 7.49984H12.5087M7.50033 7.49984H7.50866M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984ZM12.917 7.49984C12.917 7.72996 12.7304 7.9165 12.5003 7.9165C12.2702 7.9165 12.0837 7.72996 12.0837 7.49984C12.0837 7.26972 12.2702 7.08317 12.5003 7.08317C12.7304 7.08317 12.917 7.26972 12.917 7.49984ZM7.91699 7.49984C7.91699 7.72996 7.73044 7.9165 7.50033 7.9165C7.27021 7.9165 7.08366 7.72996 7.08366 7.49984C7.08366 7.26972 7.27021 7.08317 7.50033 7.08317C7.73044 7.08317 7.91699 7.26972 7.91699 7.49984Z"
                                stroke={votedUp ? '#7549F2' : '#5C6070'}
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_7187_263">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>

                <div onClick={this.voteMid} style={{ cursor: 'pointer' }}>
                    <svg
                        className="shrink-0 w-5 h-5 relative overflow-visible"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_7187_1128)">
                            <path
                                d="M6.66699 12.4998H13.3337M12.5003 7.49984H12.5087M7.50033 7.49984H7.50866M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984ZM12.917 7.49984C12.917 7.72996 12.7304 7.9165 12.5003 7.9165C12.2702 7.9165 12.0837 7.72996 12.0837 7.49984C12.0837 7.26972 12.2702 7.08317 12.5003 7.08317C12.7304 7.08317 12.917 7.26972 12.917 7.49984ZM7.91699 7.49984C7.91699 7.72996 7.73044 7.9165 7.50033 7.9165C7.27021 7.9165 7.08366 7.72996 7.08366 7.49984C7.08366 7.26972 7.27021 7.08317 7.50033 7.08317C7.73044 7.08317 7.91699 7.26972 7.91699 7.49984Z"
                                stroke={votedMid ? '#7549F2' : '#5C6070'}
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_7187_1128">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>

                <div onClick={this.voteDown} style={{ cursor: 'pointer' }}>
                    <svg
                        className="shrink-0 w-5 h-5 relative overflow-visible"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_7187_1156)">
                            <path
                                d="M13.3337 13.3332C13.3337 13.3332 12.0837 11.6665 10.0003 11.6665C7.91699 11.6665 6.66699 13.3332 6.66699 13.3332M12.5003 7.49984H12.5087M7.50033 7.49984H7.50866M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984ZM12.917 7.49984C12.917 7.72996 12.7304 7.9165 12.5003 7.9165C12.2702 7.9165 12.0837 7.72996 12.0837 7.49984C12.0837 7.26972 12.2702 7.08317 12.5003 7.08317C12.7304 7.08317 12.917 7.26972 12.917 7.49984ZM7.91699 7.49984C7.91699 7.72996 7.73044 7.9165 7.50033 7.9165C7.27021 7.9165 7.08366 7.72996 7.08366 7.49984C7.08366 7.26972 7.27021 7.08317 7.50033 7.08317C7.73044 7.08317 7.91699 7.26972 7.91699 7.49984Z"
                                stroke={votedDown ? '#7549F2' : '#5C6070'}
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_7187_1156">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        );
    }

    render() {
        const { text, style = {}, ux = 'thumbs' } = this.props;

        return (
            <div className="adapter-feedback gap-4 p-2" style={style}>
                <div className="">
                    <Text style={{ color: '#5C6070' }}>{text}</Text>
                </div>

                {ux === 'thumbs' && this.renderThumbs()}

                {ux === 'faces' && this.renderFaces()}
            </div>
        );
    }
}

export default withPostHog(inject("UserStore")(observer(Feedback)));