import { useMemo } from 'react';
import { NodeData, EdgeData } from '../types/types';

interface InitialViewResult {
  focusedNodes: NodeData[];
  focusedEdges: EdgeData[];
  communityFocus?: string;
}

export function useInitialView(
  nodes: NodeData[],
  edges: EdgeData[],
  communities: Record<string, string>
): InitialViewResult {
  return useMemo(() => {
    // If we have few nodes, return everything
    if (nodes.length <= 15) {
      return {
        focusedNodes: nodes,
        focusedEdges: edges
      };
    }
    
    // If we have communities, focus on the largest one
    if (Object.keys(communities).length > 0) {
      // Get community members
      const communityMembers: Record<string, string[]> = {};
      Object.entries(communities).forEach(([nodeId, communityName]) => {
        if (!communityMembers[communityName]) {
          communityMembers[communityName] = [];
        }
        communityMembers[communityName].push(nodeId);
      });
      
      // Sort communities by size
      const sortedCommunities = Object.entries(communityMembers)
        .sort(([, membersA], [, membersB]) => membersB.length - membersA.length);
      
      // Get the largest community
      if (sortedCommunities.length > 0) {
        const [largestCommunity, memberIds] = sortedCommunities[0];
        
        // Get nodes in this community
        const communityNodes = nodes.filter(node => memberIds.includes(node.id));
        
        // Get edges between these nodes
        const communityEdges = edges.filter(edge => {
          const sourceId = typeof edge.source === 'object' && edge.source !== null 
            ? (edge.source as { id: string }).id 
            : edge.source as string;
            
          const targetId = typeof edge.target === 'object' && edge.target !== null 
            ? (edge.target as { id: string }).id 
            : edge.target as string;
            
          return memberIds.includes(sourceId) && memberIds.includes(targetId);
        });
        
        return {
          focusedNodes: communityNodes,
          focusedEdges: communityEdges,
          communityFocus: largestCommunity
        };
      }
    }
    
    // If no community approach works, focus on the most important nodes
    const importantNodes = [...nodes]
      .sort((a, b) => (b.importance || 1) - (a.importance || 1))
      .slice(0, 12);
    
    const importantNodeIds = new Set(importantNodes.map(n => n.id));
    
    // Get edges between these nodes
    const relevantEdges = edges.filter(edge => {
      const sourceId = typeof edge.source === 'object' && edge.source !== null 
        ? (edge.source as { id: string }).id 
        : edge.source as string;
        
      const targetId = typeof edge.target === 'object' && edge.target !== null 
        ? (edge.target as { id: string }).id 
        : edge.target as string;
        
      return importantNodeIds.has(sourceId) && importantNodeIds.has(targetId);
    });
    
    return {
      focusedNodes: importantNodes,
      focusedEdges: relevantEdges
    };
  }, [nodes, edges, communities]);
}